import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate, useLocation } from "react-router";
import Login from "../containers/Login/Login";
import Signup from "../containers/Signup/SignupComp";
import ForgotPassword from "../containers/ForgotPassword/ForgotPassword";
import { LoadableComp } from "./SidebarPages/XComponents";
import LayoutConfig from "./LayoutConfig";
import InvitationSignUp from "../containers/InvitationSignUp/InvitationSignUp";
import FieldUsersLayout from "./pages/ScheduleProgress/FieldUsersLayout";
import { useAuth } from "../hooks/useAuth";
import InternalChat from "./InternalChat/InternalChat";
import { VirtualAssistant } from "./commonComponents";
import { useRedux } from "./SidebarPages/hooks";

const RouterConfig = ({ childProps }) => {
  const [routesElements, setRoutesElements] = useState({});
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { preferences: pref } = useSelector((state) => state.preferences);
  const navigate = useNavigate();
  const [openChatbot] = useRedux("chatbot", false);

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated && userConfiguration) {
      if (userConfiguration?.isForceToResetPassword) {
        navigate("/resetPassword");
      }
      setRoutesElements(
        (userConfiguration?.routeConfig || [])?.reduce(
          (prev, curr) => ({ ...prev, [curr.path]: curr }),
          {}
        )
      );
    }
  }, [isAuthenticated, userConfiguration]);

  return (
    <LoadableComp loading={isAuthenticated === null} className="fullWidth">
      {isAuthenticated ? (
        <LoadableComp
          loading={!!!isAuthenticated || !!!pref}
          className="fullWidth"
        >
          {pref && (
            <>
              {userConfiguration?.groupName === "Foreman" ? (
                <FieldUsersLayout props={childProps} />
              ) : window.location.pathname === "/internalChat" ? (
                <InternalChat />
              ) : (
                <>
                  <LayoutConfig
                    props={childProps}
                    pref={pref}
                    routesElements={routesElements}
                  />
                  {/* <AppNotificationsConfig /> */}
                  {openChatbot && <VirtualAssistant />}
                </>
              )}
            </>
          )}
        </LoadableComp>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/forgotpassword"
            element={<ForgotPassword props={childProps} />}
          />
          <Route path="/register" element={<Signup />} />
          <Route path="/invitation/:code" element={<InvitationSignUp />} />
        </Routes>
      )}
    </LoadableComp>
  );
};

export default RouterConfig;
