import moment from "moment";

export function updateTodoDetailsInConfigs(configs) {
  if (typeof configs === "object" && configs !== null) {
    return updateDetails(configs);
  }

  return configs;
}

function updateDetails(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "string") {
        obj[key] = { hide: "", isDefault: "" };
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        updateDetails(obj[key]);
      }
    }
  }
  return obj;
}

export const categoriesOptionsForDeviceManagement = [
  { title: "Leads", path: "/leads" },
  { title: "Opportunities", path: "/opportunities" },
  { title: "Contacts", path: "/contacts" },
  { title: "Projects", path: "/projects" },
  { title: "Scheduling", path: "/scheduling" },
  { title: "Permit Drawings", path: "/permitdrawings" },
  { title: "Clients", path: "/accounts" },
  { title: "Subcontractors", path: "/subcontractors" },
  { title: "Bills", path: "" },
  { title: "Vendors", path: "/vendors" },
  { title: "Inspections", path: "/inspections" },
  { title: "Tasks", path: "/tasksManager" },
  { title: "To Do", path: "/todos" },
  { title: "Notes", path: "/notes" },
  { title: "Fleets", path: "/fleets/overview" },
  { title: "Dispatches", path: "/fleets/overview?tab=Dispatch" },
  { title: "Fleets Violations", path: "/fleets/overview?tab=Violations" },
  { title: "Driver", path: "/fleets/overview?tab=Driver" },
  { title: "Vendors", path: "/fleets/overview?tab=Vendors" },
  { title: "Activity", path: "/fleets/overview?tab=Activities" },
  { title: "Documentations", path: "/fleets/overview?tab=Documentations" },
  { title: "Fleet Incident", path: "/fleets/overview?tab=Incident" },
  { title: "Fleets Inspections", path: "/fleetInspections" },
  { title: "Defects", path: "/fleetDefects" },
  { title: "Work Orders", path: "/workOrders" },
  { title: "Schedule Maintenance", path: "/scheduleMaintenance" },
  { title: "Fleet Inventory", path: "/fleetsInventory" },
  { title: "Property Damage Incident", path: "/incidents" },
  { title: "Personal Injury Incident", path: "incidents?tab=Personal+Injury" },
  { title: "Fleet Incident", path: "/incidents?tab=Vehicle+Damage" },
  { title: "Other Incident", path: "/incidents?tab=Other+Trade+Incident" },
  { title: "Property Damage", path: "/safety" },
  { title: "Personal Injury", path: "/safety?tab=Personal+Injury" },
  { title: "Vehicle Damage", path: "/safety?tab=Vehicle+Damage" },
  { title: "Other Trade Incident", path: "/safety?tab=Other+Trade+Incident" },
  { title: "Safety Inspection", path: "/safetyInspections" },
  { title: "Claims", path: "/claims" },
  { title: "Hearings", path: "/hearings" },
  { title: "Schedule Of Values", path: "/accounting" },
  { title: "Requisition", path: "/accounting?tab=Requisition" },
  { title: "Rentals", path: "/accounting?tab=Rentals" },
  { title: "Invoice", path: "/accounting?tab=Invoice" },
  { title: "Receive Payments", path: "/accounting?tab=Receive+Payments" },
  { title: "Estimations", path: "/estimations" },
  { title: "Charge", path: "/accounting?tab=Charges" },
];

export const defaultColumnDefsBasePage = (columnDefs) => {
  const findAFieldToSetAsDefault = columnDefs.filter((col) => {
    return (
      col?.hasOwnProperty("link") ||
      lowerCaseTrim(col?.field)?.includes("status") ||
      lowerCaseTrim(col?.headerName)?.includes("status") ||
      lowerCaseTrim(col?.field)?.includes("notes") ||
      lowerCaseTrim(col?.headerName)?.includes("notes") ||
      lowerCaseTrim(col?.headerName)?.includes("team") ||
      lowerCaseTrim(col?.field)?.includes("team")
    );
  });

  return findAFieldToSetAsDefault && findAFieldToSetAsDefault.length
    ? findAFieldToSetAsDefault
    : [];
};

export const createColumnDefsFromDeviceConfigs = (
  deviceManagementConfigs,
  sourceColumnDefs
) => {
  const columnDefs = deviceManagementConfigs
    .map((dynamicColDef) => {
      const colDef = sourceColumnDefs.find((col) => {
        return (
          col?.headerName?.trim()?.toLocaleLowerCase().trim() ===
            dynamicColDef?.headerName?.trim()?.toLocaleLowerCase().trim() ||
          col?.field?.trim()?.toLocaleLowerCase().trim() ===
            dynamicColDef?.field?.trim()?.toLocaleLowerCase().trim()
        );
      });

      if (colDef) {
        return {
          ...colDef,
          hide: dynamicColDef?.hide,
        };
      }

      return dynamicColDef;
    })
    .filter(Boolean);

  const enhancedCols = columnDefs.map((col) => ({
    ...col,
    valueFormatter: ({ value }) => {
      if (checkIfItsDate(value)) {
        if (!checkDateFormat(value)) {
          return enhanceDateFormat(value);
        }
      }
      return value;
    },
  }));

  const defaultColumnDefs = defaultColumnDefsBasePage(sourceColumnDefs);

  const merged = [...enhancedCols, ...defaultColumnDefs];

  const uniqueValues = merged.filter(
    (value, index, self) =>
      index === self?.findIndex((t) => t?.headerName === value?.headerName)
  );

  const orderedColumns =
    uniqueValues && uniqueValues?.length
      ? uniqueValues?.sort((a, b) => {
          const aHasLink = a?.hasOwnProperty("link");
          const bHasLink = b?.hasOwnProperty("link");
          if (aHasLink && !bHasLink) return -1;
          if (!aHasLink && bHasLink) return 1;
          return 0;
        })
      : [];

  return orderedColumns;
};

function checkDateFormat(value) {
  const regex = /^(0[1-9]|1[0-2])\/([0-2][1-9]|3[0-1])\/\d{4}$/;
  return value ? regex.test(value) : false;
}

function enhanceDateFormat(value) {
  if (value) {
    const date = new Date(value);
    return moment(date).format("MM/DD/YYYY");
  } else {
    return "";
  }
}

function checkIfItsDate(value) {
  if (value) {
    const date = new Date(value);
    if (!isNaN(date.getTime())) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

function lowerCaseTrim(value) {
  return value ? value.toLowerCase().trim() : "";
}

export function compareObjectsClones(toCloneFrom, toBeCloned) {
  const differences = [];

  for (const key in toCloneFrom) {
    if (toCloneFrom.hasOwnProperty(key)) {
      if (toBeCloned.hasOwnProperty(key)) {
        const isSame =
          JSON.stringify(toCloneFrom[key]) === JSON.stringify(toBeCloned[key]);
        differences.push({
          key,
          status: isSame ? "exists" : "missing",
        });
      } else {
        differences.push({
          key,
          status: "missing",
        });
      }
    }
  }
  return differences;
}
