import { Select } from "antd";
import "./Multiple.scss";
import { DropDownArrow } from "../../../Fleet/components/InputComponent/assets";

const FormItemMultiple = ({
  value = [],
  form,
  name,
  options = [],
  disabled,
  customOptions = [],
  onChange: changeHandler = () => {},
  isDarkMode,
}) => {
  return (
    <div className="EditDropdown">
      <Select
        mode={customOptions.length !== 0 ? "multiple" : "tags"}
        popupClassName={`EditDropDownClassName ${isDarkMode && "darkDropDown"}`}
        suffixIcon={<DropDownArrow />}
        className="EditDropdownComponent"
        defaultValue={
          customOptions.length !== 0
            ? value.length !== 0
              ? value?.map((el) => el?.id)
              : []
            : value
        }
        disabled={disabled}
        onChange={(e, option) => {
          changeHandler();
          form.setFieldsValue({
            [name]:
              customOptions.length !== 0
                ? option.map((data) => ({
                    ...data,
                    email: data.label,
                    id: data.value,
                  }))
                : e.length === 0
                ? [] // TODO: check if it breaks anything
                : e,
          });
        }}
        {...(customOptions.length !== 0 ? { options: customOptions } : {})}
      >
        {options.map((e, i) => {
          return (
            <Select.Option key={i} value={e}>
              {e}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default FormItemMultiple;
