import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider, Flex, Select, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

import { WithTooltip } from "../../../commonComponents";
import { handleSelectCategory } from "./actions/handle-select-category";
import { getInitialCategoriesData } from "./actions/get-initial-data";
import { InputComponent } from "../../../SidebarPages/Fleet/components/index";

import {
  addedMissingCategories,
  addedMissingTopicCategories,
  getIcon,
} from "./helpers";

import {
  handleLastSearches,
  localStorageHistory,
} from "./actions/handle-last-searches";

import "./GlobalSearch.scss";

export const GlobalSearch = memo(() => {
  const { topicCategories = [] } = useSelector(
    (state) => state.topicCategories
  );
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [categories, setCategories] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [initialValue, setInitialValue] = useState();
  const [categoriesData, setCategoriesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isHidden = useMediaQuery({ maxWidth: 1024 });

  // this will get fetched on the mount and user can search through these categories
  const initialCategories = [
    "leads",
    "opportunities",
    "contacts",
    "clients",
    "estimations",
    "projects",
  ];

  // holds the last searches / redirects users made so it will show as first options all the time
  const lastSearches =
    JSON.parse(localStorage.getItem(localStorageHistory)) || [];

  // it navigates the user to the record it clicked
  const handleNavigate = (linkTo, props) => {
    const proceedProps = typeof props === "string" ? { props } : props;
    setSelectedCategory();
    setInitialValue(null);

    if (linkTo) {
      navigate(linkTo, { state: { ...proceedProps } });
    }
  };

  const hasGlobalSearch = [
    ...(userConfiguration?.routeConfig?.map(({ title }) => title) ?? []),
    ...addedMissingCategories,
  ];

  const proceedTopicCategories = [
    ...topicCategories,
    ...addedMissingTopicCategories,
  ].reduce((acc, sample) => {
    const existingIndex = acc.findIndex(
      (item) => item.categoryName === sample.categoryName
    );

    if (existingIndex === -1) {
      acc.push(sample);
    } else {
      const existingItem = acc[existingIndex];

      if (!existingItem.newRowName && sample.newRowName) {
        acc[existingIndex] = sample;
      }
    }

    return acc;
  }, []);

  const categoriesWithGlobalSearch = proceedTopicCategories.filter(
    ({ categoryName, apiName, rowName, primaryKey }) => {
      if (categoryName === "Clients" || categoryName === "Subcontractors") {
        return (
          hasGlobalSearch.includes("Accounts") &&
          apiName &&
          rowName &&
          primaryKey
        );
      } else {
        return (
          hasGlobalSearch.includes(categoryName) &&
          apiName &&
          rowName &&
          primaryKey
        );
      }
    }
  );

  const onCategorySelect = (value) =>
    handleSelectCategory(
      value,
      categoriesData,
      setCategoriesData,
      setSelectedCategory,
      categoriesWithGlobalSearch,
      setLoading
    );

  // options that the user will see first, which are the initial categories fetched on the mount and alo the last searches
  const initialDataOptions = categoriesData?.map((data) => {
    const category = Object.keys(data)[0];
    const Icon = getIcon(category);

    return {
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {Icon && <Icon fill={isDarkMode ? "#fff" : "#000"} />}
          <p
            style={{
              padding: 0,
              margin: 0,
            }}
          >
            {category}
          </p>
        </div>
      ),
      options: [...Object.values(data)[0]],
    };
  });

  // when user select a specific category these options will be rendered by the input search
  const selectedCategoryOptions = categoriesData
    ?.filter((data) => {
      const category = Object.keys(data)[0];
      return category === "Last Searches" || category === selectedCategory;
    })
    .map((data) => {
      const category = Object.keys(data)[0];
      const Icon = getIcon(category);

      return {
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {Icon && <Icon fill={isDarkMode ? "#fff" : "#000"} />}
            <p
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              {category}
            </p>
          </div>
        ),
        options: [...Object.values(data)[0]],
      };
    });

  // this is for last search options to render them in a specific way
  const optionRender = (option) => {
    if (option.data.isLastSearch) {
      return (
        <Flex vertical gap="small">
          <p
            style={{
              opacity: "0.5",
              fontSize: 12,
              padding: 0,
              margin: 0,
            }}
          >
            {option.data.categoryName}
          </p>
          <span
            role="img"
            aria-label={option.data.label}
            style={{ padding: 0, margin: 0 }}
          >
            {option.data.label}
          </span>
        </Flex>
      );
    }

    return (
      <Space>
        <span role="label" aria-label={option.data.label}>
          {option.data.label}
        </span>
      </Space>
    );
  };

  useEffect(() => {
    getInitialCategoriesData(
      initialCategories,
      categoriesWithGlobalSearch,
      setCategoriesData,
      lastSearches,
      setLoading
    );
  }, []);

  useEffect(() => {
    setSelectedCategory();
    setInitialValue();
  }, [isHidden]);

  useEffect(() => {
    setCategories(
      categoriesWithGlobalSearch.map(({ categoryName }) => categoryName)
    );
  }, []);

  const customOptions = selectedCategory
    ? [...selectedCategoryOptions]
    : [...initialDataOptions];

  return (
    <div className="global-search-container" data-testid="global-search">
      <WithTooltip
        tooltipCategory="HEADER"
        tooltipKey={loading ? "search-disabled" : "search"}
      >
        <div
          className={`search-input-container ${isDarkMode ? "dark-mode" : ""}`}
        >
          <SearchOutlined className="search-icon" />
          <InputComponent
            {...{
              initialValue,
              selectClassName: "search-input",
              type: "select",
              disabled: loading,
              customOptions,
              optionRender,
              showSearch: true,
              placeholder: `Search for ${
                selectedCategory ? selectedCategory : "Projects, tasks etc..."
              }`,
              dropdownClassName: `search-dropDown ${
                isDarkMode && "search-dropDown-dark darkDropDown"
              }`,
              onSelect: (values, { linkTo, label, categoryName, ...rest }) => {
                const locationProps =
                  categoryName === "Notes"
                    ? {
                        notesOpen: true,
                        commentId: values,
                        category: categoryName,
                      }
                    : categoryName === "Reports" || categoryName === "Crews"
                    ? label.replace("|", "").trim()
                    : values;

                handleLastSearches(
                  categoryName,
                  rest,
                  label,
                  linkTo,
                  setCategoriesData
                );
                handleNavigate(linkTo, locationProps);
              },
              popupMatchSelectWidth: true,
            }}
            showArrow={false}
            onClear={() => {
              setInitialValue(null);
            }}
            dataTestid="global-search-input"
          />
        </div>
      </WithTooltip>

      {!isHidden && (
        <>
          <Divider
            type="vertical"
            className={`divider ${isDarkMode ? "dark-mode" : ""}`}
          />
          <InputComponent
            dataTestid="select-category"
            type="select"
            dropdownClassName={isDarkMode && "darkDropDown"}
            className="searchFilter-select"
            placeholder="Select category..."
            showSearch
            onChange={(val) => {
              setSelectedCategory(val);
              setInitialValue(null);
            }}
            onClear={() => setSelectedCategory()}
            onSelect={(value) => onCategorySelect(value)}
            initialValue={selectedCategory}
            options={categories?.map((categoryName, i) => (
              <Select.Option key={i} value={categoryName}>
                {categoryName}
              </Select.Option>
            ))}
          />
        </>
      )}
    </div>
  );
});
