import { state } from "../../../../../pages/Settings/settingsComponents/Roles/RolesData";
import {
  deleteObjKey,
  layoutRemoveFunction,
  removeArrayIndexes,
} from "../../../../utils";
import "./ActionComponent.scss";
import API from "@aws-amplify/api";
import "./ActionComponent.scss";
import { widgetOptionsMapping } from "../../../../Dashboard/components/Entity/EntityData";
import { preferences } from "../../../../../../actions";
import EditCardTitle from "./EditCardTitle";
import { Popover } from "antd";
import { removeCardFromSession } from "../../../../Dashboard/utils/dashboardSession";

const ActionComponent = ({ allowHide = true, ...e }) => {
  const { preferences, setChanged = () => {} } = e;

  const url = window.location.pathname.split("/")[1];

  function blurCard() {
    document.getElementById(e.id).classList.remove("active");
    document.getElementById(e.id).classList.add("blur");
    document.getElementById("blur" + e.id).classList.add("blur");
    let tmp = preferences?.widgetConfiguration?.[url];
    tmp?.forEach((card) => {
      if (e?.id === card?.id) {
        Object.assign(card, { blur: true });
      }
    });
    e?.setCardFilterPreferences(tmp);
  }

  const dashboardCheck = url === "dashboard" || url === "accounting";
  const dynamicHide = !dashboardCheck
    ? {
        keyword: "Hide",
        onClick: () => {
          document.getElementById(e.id).classList.remove("active");
          document.getElementById(e.id).classList.add("out");
          setChanged(true);
          setTimeout(async () => {
            e.dataView.find((el) => el.id === e.id).hidden = true;
            e.setDataView(state(e.dataView));
          }, 300);
        },
      }
    : {
        keyword: "Hide",
        onClick: () => {
          document.getElementById(e.id).classList.remove("active");
          document.getElementById(e.id).classList.add("out");
          document.getElementById("blur" + e.id).classList.remove("blur");
          //this returns the new hidden array
          const newHidden = [
            ...e?.hiddenCards,
            e?.rowData?.find((a) => a?.id === e?.id),
          ];
          //this returns the new layout
          const newLayout = Object.keys(e?.layout)?.reduce(
            (acc, curr) => ({
              ...acc,
              [curr]: e?.layout[curr].filter((a) => a?.i !== e.id),
            }),
            {}
          );

          //this return the new rowData
          const newRowData = e?.rowData.filter((a) => a?.id !== e?.id);

          e?.setHiddenCards(newHidden);
          //this timeout is used for the animation to appear before the card is removed from the dom
          setTimeout(async () => {
            e?.setLayout(newLayout);
            e?.setRowData(newRowData);
          }, 300);

          //this updates the new data on db
          API.patch("preferences", "/preferences", {
            body: {
              widgetConfiguration: {
                ...e?.preferences?.widgetConfiguration,
                [url]: newRowData,
              },
            },
          }).catch((e) => console.error(e));
          API.patch("preferences", "/preferences", {
            body: {
              widgetHiddenConfiguration: {
                ...e?.preferences?.widgetHiddenConfiguration,
                [url]: newHidden,
              },
            },
          }).catch((e) => console.error(e));
          API.patch("preferences", "/preferences", {
            body: {
              widgetLayout: {
                ...e?.preferences?.widgetLayout,
                [url]: newLayout,
              },
            },
          }).catch((e) => console.error(e));
        },
      };
  const ACTION_DATA = [
    !dashboardCheck && {
      keyword: "Edit",
      onClick: () => {
        e.setEditMode(true);
        if (e?.disableDrag) {
          e.disableDrag(true);
        }
      },
    },
    allowHide && dynamicHide,
    dashboardCheck && {
      keyword: "Filter",
      onClick: () => {
        e.setIsModalVisible(true);
      },
    },
    dashboardCheck && {
      keyword: "Blur",
      onClick: () => {
        // document.getElementById(e.id).classList.remove("active");
        // document.getElementById(e.id).classList.add("blur");
        // document.getElementById("blur" + e.id).classList.add("blur");
        blurCard();
      },
    },
    dashboardCheck && {
      keyword: "Remove",

      onClick: () => {
        document.getElementById(e.id).classList.remove("active");
        document.getElementById(e.id).classList.add("out");
        document.getElementById("blur" + e.id).classList.remove("blur");

        const selectedCard = e?.rowData?.find((a) => a?.id === e?.id);
        e?.setCardFilterPreferences((prev) =>
          prev?.filter((el) => el?.id !== e?.id)
        );
        const table = selectedCard?.BodyComponentParams?.table;
        const field = selectedCard?.BodyComponentParams?.field;
        const chartType = widgetOptionsMapping[selectedCard?.type];
        // const field =
        // selectedCard?.type === "Progress"
        //   ? selectedCard.BodyComponentParams?.field
        //   : selectedCard.BodyComponentParams?.secondaryField

        //this enables card to add widget modal on state and on db
        const newWidgetRepetition = {
          ...e?.widgetRepetition,
          [table]: {
            ...e?.widgetRepetition?.[table],
            [field]: {
              ...e?.widgetRepetition?.[table]?.[field],
              [chartType]: false,
            },
          },
        };

        e?.setWidgetRepetition(newWidgetRepetition);
        //enables cards handle in DB
        API.patch("preferences", "/preferences", {
          body: {
            widgetRepetition: {
              ...e?.preferences?.widgetRepetition,
              [url]: newWidgetRepetition,
            },
          },
        }).catch((e) => console.error(e));

        setTimeout(async () => {
          const newLayout = layoutRemoveFunction(e.layout, e?.id);
          const newWidgetConfiguration = removeArrayIndexes(
            e?.rowData,
            e?.rowData?.findIndex((a) => a?.id === e?.id)
          );

          const newStates = deleteObjKey(e?.statesData, e?.id);

          //widgetConfiguration change on state and db
          e?.setRowData(newWidgetConfiguration);
          API.patch("preferences", "/preferences", {
            body: {
              widgetConfiguration: {
                ...e.preferences?.widgetConfiguration,
                [url]: newWidgetConfiguration,
              },
            },
          }).catch((e) => console.error(e));

          removeCardFromSession(e?.id, table);
          //layout change on state and db

          e.setLayout(newLayout);
          API.patch("preferences", "/preferences", {
            body: {
              widgetLayout: {
                ...(e?.preferences?.widgetLayout || {}),
                [url]: newLayout,
              },
            },
          }).catch((e) => console.error(e));

          // change card states on db and on localStates
          e?.setStatesData((prev) => {
            if (prev?.[e?.id]) {
              return deleteObjKey(prev, e?.id);
            }
          });
          e?.setDynamicStates((prev) => {
            if (prev?.[e?.id]) {
              return deleteObjKey(prev, e?.id);
            }
          });
          e?.setUpdatedDynamicStates((prev) => {
            if (prev?.[e?.id]) {
              return deleteObjKey(prev, e?.id);
            }
          });
          API.patch("preferences", "/preferences", {
            body: {
              defaultConfiguration: {
                ...preferences.defaultConfiguration,
                [url]: newStates,
              },
            },
          }).catch((e) => console.error(e));
          e.setPreferences((prev) => {
            prev["widgetConfiguration"] = {
              ...(e?.preferences?.widgetConfiguration || {}),
              [url]: newWidgetConfiguration,
            };
            prev["widgetRepetition"] = {
              ...(e?.preferences?.widgetConfiguration || {}),
              [url]: newWidgetRepetition,
            };
            prev["widgetLayout"] = {
              ...(e?.preferences?.widgetLayout || {}),
              [url]: newLayout,
            };
            prev["defaultConfiguration"] = {
              ...preferences.defaultConfiguration,
              [url]: newStates,
            };
            return { ...prev };
          });
        }, 300);
      },
    },
  ].filter(Boolean);
  let stepperValue =
    document
      ?.getElementsByClassName(
        "ant-steps-item ant-steps-item-process ant-steps-item-active"
      )[0]
      ?.getElementsByClassName("ant-steps-item-container")[0]
      ?.getElementsByClassName("ant-steps-item-content")[0]
      ?.getElementsByClassName("ant-steps-item-title")[0].innerHTML ||
    undefined;
  return (
    <div className="ActionContent" onClick={() => {}}>
      {ACTION_DATA.filter(({ keyword }) => {
        if (
          e.editable === false ||
          (stepperValue === "Finished" && e.type === "ProgressBar") ||
          (stepperValue === "Approved" && e.type === "ProgressBar") ||
          (stepperValue === "On Hold" && e.type === "ProgressBar") ||
          (stepperValue === "Paused" && e.type === "ProgressBar") ||
          (stepperValue === "Ready To Start" && e.type === "ProgressBar")
        ) {
          return keyword !== "Edit";
        } else return true;
      })
        .filter(({ keyword }) => (!e.filter ? keyword !== "Filter" : true))
        .map(({ keyword, onClick }, index) => (
          // <span key={index} className="ActionElement" {...{ keyword, onClick }}>
          //   {keyword === "Edit" ? <EditCardTitle {...{ index, e }} /> : keyword}
          // </span>
          <span key={index} className="ActionElement" {...{ keyword, onClick }}>
            {keyword}
          </span>
        ))}
      {/* {this is used to show the editCardTitle functionality} */}
      {url === "dashboard" && !e.editingCardTitle ? (
        <Popover
          overlayClassName={`editCardTitlePopover ${
            e.isDarkMode && "editCardTitlePopoverDark"
          }`}
          overlayStyle={{ borderRadius: 50 }}
          placement="right"
          trigger={"hover"}
          content={() => <EditCardTitle {...{ e }} />}
        >
          <span className="ActionElement" style={{ cursor: "pointer" }}>
            Edit Title
          </span>
        </Popover>
      ) : (
        ""
      )}
      {url === "dashboard" && (
        // <Popover
        //   overlayClassName={`editCardTitlePopover ${
        //     e.isDarkMode && "editCardTitlePopoverDark"
        //   }`}
        //   overlayStyle={{ borderRadius: 50 }}
        //   placement="right"
        //   trigger={"click"}
        //   content={() => (
        //     <ChangeChartType
        //       {...{
        //         rowData,
        //         id,
        //         updatedDynamicStates,
        //         setRowData,
        //         type,
        //         widgetRepetition,
        //         tables: e?.tables,
        //         programFields: e?.programFields,
        //         setWidgetRepetition,
        //         preferences,
        //         layout,
        //         setLayout,
        //         setPreferences,
        //       }}
        //     />
        //   )}
        // >
        <span
          {...{
            className: "ActionElement",
            style: { cursor: "pointer" },
            onClick: () => {
              e?.setChartTypeModal(true);
            },
          }}
        >
          Change Chart
        </span>
        // </Popover>
      )}
    </div>
  );
};
export default ActionComponent;
