import { useState } from "react";
import { Button, Dropdown, Menu, Modal, Drawer, Tooltip } from "antd";
import { handleCopyServiceToClipboard } from "../../../tools/controllers";
import { MondayButton } from "../../../../../../commonComponents";
import AddServiceNote from "./AddServiceNote/AddServiceNote";
import "./ServiceActions.scss";
import RentalCalculator from "../../rentalCalculator/RentalCalculator";
import {
  ChainScopeIcon,
  CollapseIcon,
  ExpandMoreIcon,
} from "../../../../../../../icons/index";
import ChainScopeTakeOff from "./ChainScopeTakeOff/ChainScopeTakeOff";
import { useRedux } from "../../../../../hooks/useRedux";
import { CopyIcon } from "../../../../../DynamicView/src";
import { OptionsIcon } from "../../../../../Communication/assets";
import PLIAddonPriceScheme from "./PLIAddonPriceScheme/PLIAddonPriceScheme";
import RentalTermsTO from "./RentalTermsTO/RentalTermsTO";
import useTakeOffAccessRights from "../../../tools/controllers/useTakeOffAccessRights";

const onMenuClick = (e) => {
  console.log("click", e);
};

const items = [
  {
    key: "1",
    label: "1st item",
  },
  {
    key: "2",
    label: "2nd item",
  },
  {
    key: "3",
    label: "3rd item",
  },
];
export default function ServiceActions(props) {
  const {
    service,
    // isWritable,
    fullscreenizeService,
    fullScreenService,
    deFullscreenize,
    handleModifyOption,
    serviceIndex,
    collapseAllElevations,
    expandAllElevations,
    updateStateAndSave,
    gridData,
    agGridTheme,
    priceSchemesData,
    handleSave,
  } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isWritable] = useRedux("estimationsIsWritable");
  const { canSeeRentals = false, canViewPrice = false } =
    useTakeOffAccessRights("Take-Off");

  const addNoteForService = (note) => {
    if (isWritable) {
      const newData = gridData?.find((s) => {
        if (s?.serviceId === service?.serviceId) {
          s.note = note ?? "";
        }
      });
      console.log("new grid data", gridData);
      updateStateAndSave(gridData);
    }
  };

  console.log("currentService", service);
  // function to save dara
  return (
    <div className="service-actions">
      {" "}
      {/* <div className="btn-holder-service-actions">
        {" "}
        <Button
          className="open-cpanel-service"
          type="primary"
          onClick={() => setOpenDrawer(true)}
        >
          Control Panel
        </Button>
      </div> */}
      {/* <Drawer
        title="Control Panel"
        placement={"right"}
        closable={false}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        // key={placement}
      > */}
      <div className="service-control-btns">
        {!!service?.isScope && (
          <ChainScopeTakeOff
            {...{
              gridData,
              selectedScope: service,
              updateStateAndSave,
            }}
          />
        )}
        {!!service &&
          service?.serviceId !== 3 &&
          service?.serviceId?.toString() !== "38" &&
          canSeeRentals &&
          service?.isScope !== true && (
            <RentalCalculator
              {...{
                service: service,
                serviceIndex: service?.serviceId,
                gridData,
                updateStateAndSave,
              }}
            />
          )}
        <AddServiceNote
          {...{
            service: service,
            addNoteForService,
            isWritable,
            themeType:
              agGridTheme === "ag-theme-balham-dark" ? "dark" : "light",
          }}
        />{" "}
        {/* <span className="vDivider" /> */}
        {canSeeRentals && (
          <RentalTermsTO
            {...{
              service,
            }}
          />
        )}
        {fullScreenService === null ? (
          <Tooltip title="Full Screen">
            <Button
              onClick={() => {
                fullscreenizeService(service.serviceId);
              }}
              style={
                {
                  //  marginRight: 10
                }
              }
            >
              {/* <Fullscreen /> */}
              Focus Mode
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Exit Full Screen">
            <Button
              onClick={() => deFullscreenize(service.serviceId)}
              style={
                {
                  // marginRight: 10
                }
              }
            >
              {/* <FullscreenExit /> */}
              Exit Focus Mode
            </Button>
          </Tooltip>
        )}
        {!service.isScope && (
          <Dropdown
            disabled={!isWritable}
            overlay={
              <Menu forceSubMenuRender={true}>
                <Menu.Item
                  onClick={() => {
                    handleModifyOption(serviceIndex, null, "new", []);
                  }}
                >
                  {service?.serviceOptions?.length > 0
                    ? "New Alternate"
                    : "New Primary"}
                </Menu.Item>
                <Menu.Divider />
                {service.serviceOptions.map((option, optionIndex) => {
                  return (
                    <Menu.SubMenu
                      key={optionIndex}
                      title={
                        optionIndex === 0
                          ? "Primary"
                          : `Alternate ${optionIndex}`
                      }
                    >
                      <Menu.Item
                        onClick={() => {
                          handleModifyOption(
                            serviceIndex,
                            null,
                            "duplicate",
                            option
                          );
                        }}
                      >
                        Duplicate
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          Modal.confirm({
                            centered: true,
                            title: "Sure to remove this alternate?",
                            okText: "Yes",
                            cancelText: "No",
                            onOk: () => {
                              handleModifyOption(
                                serviceIndex,
                                optionIndex,
                                "remove",
                                null
                              );
                            },
                          });
                        }}
                      >
                        Remove
                      </Menu.Item>
                    </Menu.SubMenu>
                  );
                })}
              </Menu>
            }
          >
            <Button
              style={
                {
                  // marginRight: 10
                }
              }
            >
              {/* <OptionsIcon /> */}
              Alternates...
            </Button>
          </Dropdown>
        )}
        {/* <Tooltip title="Copy To Clipboard"> */}
        <Button
          style={
            {
              //  marginRight: 10
            }
          }
          onClick={() => {
            handleCopyServiceToClipboard(service);
          }}
        >
          {/* <CopyIcon /> */}
          Copy to Clipboard
        </Button>{" "}
        {canViewPrice && (
          <PLIAddonPriceScheme
            {...{ priceSchemesData, service, serviceIndex, handleSave }}
          />
        )}
        {/* </Tooltip> */}
        {/* <span className="vDivider" /> */}
        {/* <Button
          style={{ marginRight: 10 }}
          onClick={() => {
            collapseAllElevations(serviceIndex);
          }}
        >
          Collapse All
        </Button> */}
        {/* <Button
          onClick={() => {
            expandAllElevations(serviceIndex);
          }}
        >
          Expand All
        </Button>{" "} */}
      </div>
      {/* </Drawer> */}
    </div>
  );
}
