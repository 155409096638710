import { useState, useMemo, useEffect } from "react";
import { Button, Modal, message, Alert, Popconfirm, Tooltip } from "antd";
import "./ScaffoldPlanks.scss";
import { AgGridReact } from "ag-grid-react";
import "../../../../../../../components/SidebarPages/BasePage/DynamicBasePage.scss";
import { customFloorSignificance } from "../utils";
import CustomFooter from "../../../../../Projects/Accounting/Applications/ApplicationsTab/ListOfApplications/GridList/CustomFooter";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import {
  getScaffoldPLIColumns,
  initializeRowPLISingleEvery,
} from "./scaffoldPLIAddonColumnDefs.js";
import { MondayButton } from "../../../../../../commonComponents/index";
import { AddIcon } from "../../../../../../Header/components/GoogleEvents/assets/index";
import {
  CalculatePlanks,
  MathActionsIcon,
} from "../../../../../../../icons/index";
import { useRedux } from "../../../../../hooks";
import {
  CloseIconModal,
  DeleteIcon,
  RegenerateIcon,
} from "../../../../../../../assets";
import { SaveIcon } from "../../../../../BasePage/src";
import ScaffoldServicePlanks from "../ScaffoldServicePlanks/ScaffoldServicePlanks";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { getElevationTotal } from "../../../tools/formatters";
import { pliTaxAmountCalculate } from "../../../tools/controllers/CellEditingEvents";
import { updateDocumentTitle } from "../../../../../../../utils";
import CustomFormulaToolTip from "./CustomFormulaToolTip";
import { set } from "lodash";
// PLi Addom for Scaffold Service to calculate additional amount
function ScaffoldPlanks({
  elevation,
  serviceIndex,
  optionIndex,
  elevationIndex,
  serviceId,
}) {
  const [visible, setVisible] = useState(false);
  const [scaffoldPlanksGridAPI, setScaffoldPlanksGridAPI] = useState(null);
  const [priceSheetGridAPI, setPriceSheetGridAPI] = useState(null);
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [dataSrc, setDataSrc] = useState([{}]);
  const [dataChanged, setDataChanged] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [priceSheetDataSrc, setPriceSheetDataSrc] = useState([
    {
      dataKey: "Sqft < 100",
      dataValue: 0,
      dataPPU: 3.5,
      dataPrice: 0,
    },
    {
      dataKey: "Sqft > 100",
      dataValue: 0,
      dataPPU: 3.5,
      dataPrice: 0,
    },
    {
      dataKey: "Full Deck",
      dataValue: 0,
      dataPPU: 26,
      dataPrice: 0,
    },
    {
      dataKey: "Bic Level",
      dataValue: 0,
      dataPPU: 0,
      dataPrice: 0,
    },
    {
      dataKey: "Bic Every Level",
      dataValue: 0,
      dataPPU: 26,
      dataPrice: 0,
    },
    {
      dataKey: "Full Deck Every Level",
      dataValue: 0,
      dataPPU: 0,
      dataPrice: 0,
    },
  ]);
  const [custom, setCustom] = useState(false);

  const totalities = useMemo(() => {
    if (visible) {
      const myResults = {
        "Sqft > 100": 0,
        "Sqft < 100": 0,
        Sqft: 0,
        "Full Deck": 0,
        "Bic Level": 0,
        "Bic Every Level": 0,
        "Full Deck Every Level": 0,
      };
      for (const item of dataSrc) {
        console.log("item", item);
        if (Number(item?.height) > 100) {
          myResults["Sqft > 100"] += Number(item?.sqft || 0);
        } else {
          myResults["Sqft < 100"] += Number(item?.sqft || 0);
        }
        myResults["Sqft"] += Number(item?.sqft || 0);
        myResults["Full Deck"] += Number(item?.fullDeck || 0);
        myResults["Bic Level"] += Number(item?.bicLevel || 0);
        myResults["Bic Every Level"] += Number(item?.bicEveryLevel || 0);
        myResults["Full Deck Every Level"] += Number(
          item?.fullDeckEveryLevel || 0
        );
      }
      console.log("myResults", myResults);
      setPriceSheetDataSrc((prev) => {
        return prev?.map((item) => {
          return {
            ...item,
            dataValue: myResults[item?.dataKey],
          };
        });
      });
      return myResults;
    }
  }, [dataChanged, dataSrc, visible]);

  const genPriceAccordToPlanks = (planksData) => {
    console.log("planksData", planksData);
    let estimatedSum = Array.isArray(planksData?.priceSheetDataSrc)
      ? planksData?.priceSheetDataSrc?.reduce((acc, el) => {
          return acc + forceToNumber(el?.dataTotalPrice?.toFixed(2) ?? 0);
        }, 0)
      : 0;
    let temp = structuredClone(gridData);
    let currElevation =
      temp[serviceIndex]?.serviceOptions?.[optionIndex][elevationIndex];
    currElevation.planks = planksData;
    let pliAddonPrice = currElevation?.items?.reduce((acc, pli) => {
      return acc + forceToNumber(pli?.pliAddonTotal);
    }, 0);
    let priceRatio =
      (forceToNumber(estimatedSum) - pliAddonPrice) /
      getElevationTotal(serviceId, currElevation?.items)?.priceSubtotal;
    console.log("priceRatio", {
      priceRatio,
      estimatedSum,
      currElevation,
      pliAddonPrice,
      total: getElevationTotal(serviceId, currElevation?.items),
    });

    currElevation.items.forEach((pli, tempIdx) => {
      const referencePLI = planksData?.dataSrc?.[tempIdx] ?? {};
      const data = planksData?.priceSheetDataSrc;
      let priceHolder = 0;

      if (Array.isArray(data)) {
        data.forEach((el) => {
          const dataPPU = forceToNumber(el?.dataPPU);
          const qty = referencePLI?.[el?.dataKey?.toLowerCase()] ?? 0; // Assumes keys match lower-case field names
          console.log("qty", { qty, dataPPU, el, referencePLI });
          switch (el?.dataKey) {
            case "Sqft > 100":
              if (referencePLI?.height > 100)
                priceHolder += dataPPU * referencePLI?.sqft;
              break;

            case "Sqft < 100":
              if (referencePLI?.height < 100)
                priceHolder += dataPPU * forceToNumber(referencePLI?.sqft);
              break;

            case "Sqft":
              priceHolder += dataPPU * forceToNumber(referencePLI?.sqft);
              break;

            case "Full Deck":
              priceHolder += dataPPU * forceToNumber(referencePLI?.fullDeck);
              break;

            case "Bic Level":
              priceHolder += dataPPU * forceToNumber(referencePLI?.bicLevel);
              break;
            case "Bic Every Level":
              priceHolder +=
                dataPPU * forceToNumber(referencePLI?.bicEveryLevel);
              break;
            case "Full Deck Every Level":
              priceHolder +=
                dataPPU * forceToNumber(referencePLI?.fullDeckEveryLevel);
              break;

            default:
              console.warn("Unhandled dataKey:", el?.dataKey);
          }
        });
      }

      // Log results for debugging
      console.log("Price Holder for PLI:", { tempIdx, pli, priceHolder });

      // Update PLI properties
      pli.price = priceHolder;
      pli.ppu =
        Math.round((priceHolder / (pli.height * pli.length || 1)) * 100) / 100; // Avoid division by zero
      pli.taxAmount = pliTaxAmountCalculate(pli);
      pli.totalPrice = pli.price + pli.taxAmount;

      // if (pli.lock === false) {
      pli.rent = forceToNumber(
        Math.round(parseFloat(temp[serviceIndex]?.rent ?? 0) * pli.price) / 100
      );
      // }

      pli.lock = true; // Ensure lock is set
      tempIdx++;
    });
    console.log("what i am setting", temp);
    setTakeOffTableData(temp);
  };

  const onSubmit = () => {
    const newData = structuredClone(gridData);
    console.log("newData", newData);

    newData[serviceIndex].serviceOptions[optionIndex][elevationIndex].planks = {
      dataSrc,
      priceSheetDataSrc,
    };
    // console.log(
    //   "newData",
    //   newData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
    // );
    // setTakeOffTableData(newData);
    message.loading("Saving...");
    genPriceAccordToPlanks({
      dataSrc,
      priceSheetDataSrc,
    });
    setTimeout(() => {
      closeModal();
      message.success("Saved", 1);
      message.destroy();
    }, 1000);
    // setTakeOffTableData((prev) => {
    //   return {
    //     ...prev,
    //     items: newData,
    //   };
    // });
    // closeModal();
  };

  const onDeletePlanks = () => {
    const newData = structuredClone(gridData);
    console.log("newData", newData);

    newData[serviceIndex].serviceOptions[optionIndex][elevationIndex].planks =
      {};
    setTakeOffTableData(newData);

    setTimeout(() => {
      closeModal();
      message.success("Saved", 1);
      message.destroy();
    }, 1000);
    // setTakeOffTableData((prev) => {
    //   return {
    //     ...prev,
    //     items: newData,
    //   };
    // });
    // closeModal();
  };

  const onRegenerate = () => {
    const { items = [] } = elevation || {};
    setDataSrc([]);
    items?.forEach((item) => {
      if (item?.height > 0 && item?.length > 0) {
        const { height = 0, length = 0 } = item || {};
        setDataSrc((prev) => {
          return [
            ...prev,
            initializeRowPLISingleEvery({
              height,
              length,
            }),
          ];
        });
      }
    });
    setDataChanged((prev) => !prev);
    setCustom(true);
    message.success("Regenerated", 1);
  };

  const onCustom = () => {
    setCustom(true);
    setDataSrc([
      initializeRowPLISingleEvery({
        height: 0,
        length: 0,
      }),
    ]);
  };

  useEffect(() => {
    if (visible && !!elevation && !custom) {
      if (
        Array.isArray(elevation?.planks?.dataSrc) &&
        elevation?.planks?.dataSrc?.length > 0
      ) {
        setDataSrc(elevation?.planks?.dataSrc);
        setPriceSheetDataSrc(elevation?.planks?.priceSheetDataSrc);
        return;
      } else {
        const { items = [] } = elevation || {};
        setDataSrc([]);
        items?.forEach((item) => {
          if (item?.height > 0 && item?.length > 0) {
            const { height = 0, length = 0 } = item || {};
            setDataSrc((prev) => {
              return [
                ...prev,
                initializeRowPLISingleEvery({
                  height,
                  length,
                }),
              ];
            });
          }
        });
      }
    }
  }, [visible, elevation, custom, dataChanged]);
  // console.log("dataSrc", elevation);

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
  };

  return (
    <div className="scaffold-pliaddon-planks-wrapper">
      <Tooltip title="Calculate Planks">
        <button onClick={() => setVisible(true)} className="iconHolder">
          <CalculatePlanks />
        </button>
      </Tooltip>
      {/* <MondayButton
        onClick={() => setVisible(true)}
        className="mondayButtonBlue"
        Icon={<MathActionsIcon />}
      >
        Planks Calculator{" "}
      </MondayButton> */}

      {visible && (
        <Modal
          title="Scaffold Elevation Planks Calculator"
          afterOpenChange={(event) => {
            event &&
              updateDocumentTitle({
                newTitle: "Scaffold Elevation Planks Calculator",
              });
          }}
          maskClosable={false}
          className="scaffold-pliaddon-planks-modal"
          open={visible}
          width={"100%"}
          onCancel={closeModal}
          onOk={onSubmit}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <MondayButton
                className="mondayButtonRed"
                Icon={<CloseIconModal />}
                onClick={closeModal}
              >
                Close & Don't Save
              </MondayButton>
              <ScaffoldServicePlanks
                {...{
                  serviceIndex,
                }}
              />
              <MondayButton Icon={<SaveIcon />} onClick={onSubmit}>
                Save New Data
              </MondayButton>
            </div>
          }
          centered
        >
          <div className="scaffold-pliaddon-planks-content">
            <div>
              <Alert
                type="info"
                showIcon
                message={
                  "For this price calculator, the dimensions are based on the elevations of the scaffold but they can be edited upon your needs."
                }
                action={
                  <div
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "space-between",
                  //   gap: "10px",
                  // }}
                  >
                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={onRegenerate}
                      okText="Yes"
                      cancelText="No"
                    >
                      {" "}
                      <MondayButton
                        className="mondayButtonBlue"
                        Icon={<RegenerateIcon />}
                        // onClick={onRegenerate}
                      >
                        Recalculate from Take Off
                      </MondayButton>
                    </Popconfirm>{" "}
                    {/* <MondayButton
                      className="mondayButtonRed"
                      hasIcon={false}
                      // Icon={<DeleteIcon />}
                      onClick={onDeletePlanks}
                    >
                      Delete Planks
                    </MondayButton> */}
                  </div>
                }
              />
              <br />
            </div>
            <div className="agGridContainer">
              <div className="dark-ag-theme documentationsTable ag-theme-alpine-dark">
                <AgGridReact
                  headerHeight={50}
                  columnDefs={
                    !!scaffoldPlanksGridAPI
                      ? getScaffoldPLIColumns({
                          setDataSrc,
                          scaffoldPlanksGridAPI,
                        })
                      : []
                  }
                  rowData={dataSrc}
                  enableRangeSelection={true}
                  suppressSizeToFit={true}
                  width={"auto"}
                  tooltipShowDelay={0}
                  tooltipHideDelay={5000}
                  suppressDragLeaveHidesColumns={true}
                  // width={"100%"}
                  enableCellChangeFlash={true}
                  onGridReady={(params) => {
                    setScaffoldPlanksGridAPI(params.api);
                    // params?.api?.setHeaderHeight(50);
                    // setColumnApi(params?.columnApi);
                    // const widths = {
                    //   rowWidth: parseInt(
                    //     document.getElementsByClassName(
                    //       "ag-center-cols-container"
                    //     )[0].offsetWidth
                    //   ),
                    //   parentWidth: parseInt(
                    //     document.getElementsByClassName("ag-body-viewport")[0]
                    //       .offsetWidth
                    //   ),
                    // };

                    // if (widths.rowWidth > widths.parentWidth) {
                    //   params.columnApi.autoSizeAllColumns(false);
                    // } else if (widths.rowWidth < widths.parentWidth) {
                    params.api.sizeColumnsToFit();
                    // }
                  }}
                  onFirstDataRendered={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                  autoSizeAllColumns={true}
                  rowHeight={40}
                  defaultColDef={{
                    enablePivot: true,
                    editable: true,
                    resizable: true,
                    tooltipComponent: CustomFormulaToolTip,
                  }}
                  onCellValueChanged={(params) => {
                    const shouldIAddEmpty = dataSrc?.every(
                      (el) => Object.keys(el)?.length > 12
                    );
                    if (shouldIAddEmpty) {
                      setTimeout(() => {
                        setDataSrc((prev) => [
                          ...prev,
                          {
                            bicType:
                              prev?.[prev.length - 1]?.bicType > 20
                                ? prev[prev.length - 1]?.bicType
                                : 20,
                          },
                        ]);
                      }, 200);
                    } else {
                      setTimeout(() => {
                        setDataChanged((prev) => !prev);
                      }, 200);
                    }
                  }}
                />
                <CustomFooter
                  dataSrc={dataSrc}
                  mathAction={"sum"}
                  style={footerStyle}
                  labelsKeys={[
                    {
                      key: "sqft",
                      name: "Total Sqft",
                      type: "number",
                      mathAction: "sum",
                    },
                    {
                      key: "fullDeck",
                      name: "Total Deck",
                      type: "number",
                      mathAction: "sum",
                    },
                    {
                      key: "bicLevel",
                      name: "Total Bicycle Level",
                      type: "number",
                      mathAction: "sum",
                    },
                    {
                      key: "fullDeckEveryLevel",
                      name: "Total Every Level",
                      type: "number",
                      mathAction: "sum",
                    },
                    {
                      key: "bicEveryLevel",
                      name: "Total Bicycle Every Level",
                      type: "number",
                      mathAction: "sum",
                    },
                  ]}
                />
              </div>
            </div>
            <div>
              <br />
              <br />
              <br />
              <div className="agGridContainer">
                <div className="dark-ag-theme documentationsTable  ag-theme-alpine-dark">
                  <AgGridReact
                    rowData={priceSheetDataSrc}
                    headerHeight={50}
                    columnDefs={[
                      {
                        headerName: "Data",
                        field: "dataKey",
                        // width: 70,
                        cellClass: "ppu-planks-cell",
                        editable: false,
                      },
                      {
                        headerName: "Value",
                        field: "dataValue",
                        // width: 70,
                        editable: false,
                        cellClass: "ppu-planks-cell",
                        type: "rightAligned",
                      },
                      {
                        headerName: "PPU",
                        // editable: false,
                        field: "dataPPU",
                        cellStyle: { textAlign: "right" },
                        // width: 70,
                        editable: true,
                        valueFormatter: (params) => {
                          try {
                            const res = getNumberValOnly(params.value);
                            // setPriceSheetDataSrc((prev) => {
                            //   const newPrev = [...prev];
                            //   const index = newPrev.findIndex(
                            //     (el) => el.dataKey === params?.data?.dataKey
                            //   );
                            //   newPrev[index].dataPPU = res;
                            //   return newPrev;
                            // });
                            return formatCurrency(res);
                          } catch (err) {
                            return 0;
                          }
                        },
                        cellClass: "ppu-planks-cell-price",
                        cellRenderer: "agAnimateShowChangeCellRenderer",
                      },
                      {
                        headerName: "Total Price",
                        // width: 100,
                        editable: false,
                        field: "dataTotalPrice",
                        cellClass: "ppu-planks-cell-price",
                        valueFormatter: (params) => {
                          try {
                            const res = getNumberValOnly(params.value);
                            return formatCurrency(res);
                          } catch (err) {
                            return 0;
                          }
                        },

                        valueGetter: (params) => {
                          try {
                            const PPU = params?.data?.dataPPU || 0;
                            const dataVal = params?.data?.dataValue || 0;
                            const res = getNumberValOnly(PPU * dataVal);
                            params.data.dataTotalPrice = res;

                            // setPriceSheetDataSrc((prev) => {
                            //   const newPrev = [...prev];
                            //   const index = newPrev.findIndex(
                            //     (el) => el.dataKey === params?.data?.dataKey
                            //   );
                            //   newPrev[index].dataTotalPrice = res;
                            //   return newPrev;
                            // });
                            return res;
                          } catch (err) {
                            return 0;
                          }
                        },
                      },
                    ]}
                    enableRangeSelection={true}
                    suppressSizeToFit={true}
                    suppressDragLeaveHidesColumns={true}
                    // autoSizeColumns={true}
                    // width={"100%"}
                    onGridReady={(params) => {
                      setPriceSheetGridAPI(params.api);
                      // params?.api?.setHeaderHeight(50);
                      // setColumnApi(params?.columnApi);
                      // const widths = {
                      //   rowWidth: parseInt(
                      //     document.getElementsByClassName(
                      //       "ag-center-cols-container"
                      //     )[0].offsetWidth
                      //   ),
                      //   parentWidth: parseInt(
                      //     document.getElementsByClassName("ag-body-viewport")[0]
                      //       .offsetWidth
                      //   ),
                      // };

                      // if (widths.rowWidth > widths.parentWidth) {
                      //   params.columnApi.autoSizeAllColumns(false);
                      // } else if (widths.rowWidth < widths.parentWidth) {
                      params.api.sizeColumnsToFit();
                      // }
                    }}
                    width={"auto"}
                    rowHeight={40}
                    defaultColDef={{
                      enablePivot: true,
                      editable: true,
                      resizable: true,
                    }}
                    onCellValueChanged={(params) => {
                      // Update state based on the changed cell
                      setPriceSheetDataSrc((prevData) => {
                        const updatedData = [...prevData];
                        const index = updatedData.findIndex(
                          (row) => row.dataKey === params.data.dataKey
                        );
                        if (index !== -1) {
                          updatedData[index][params.colDef.field] =
                            params.newValue;
                          if (params.colDef.field === "dataPPU") {
                            // Recalculate total price if PPU changes
                            updatedData[index].dataTotalPrice =
                              params.data.dataValue * params.newValue;
                          }
                        }
                        return updatedData;
                      });
                    }}
                    // onCellValueChanged={(params) => {
                    //   console.log("myDatASrc", dataSrc);
                    //   const shouldIAddEmpty = dataSrc?.every(
                    //     (el) => Object.keys(el).length > 10
                    //   );
                    //   if (shouldIAddEmpty) {
                    //     setDataSrc((prev) => [...prev, {}]);
                    //   }
                    // }}
                  />
                  <CustomFooter
                    style={footerStyle}
                    // color={"green"}
                    dataSrc={priceSheetDataSrc?.map((el) => {
                      console.log("el", el);
                      return el;
                    })}
                    mathAction={"sum"}
                    labelsKeys={[
                      {
                        key: "dataTotalPrice",
                        name: "Estimated Sum (Without Tax)",
                        type: "currency",
                        mathAction: "sum",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ScaffoldPlanks;

const getNumberValOnly = (val) => {
  const numInput = Number(val);
  if (isNaN(numInput)) {
    return 0;
  } else {
    if (val === null || val === undefined || val === "") {
      return 0;
    } else {
      return numInput || 0;
    }
  }
};

const footerStyle = {
  backgroundColor: "#1264A3",
};
