import { utils, read } from "xlsx";
import { message, Modal, Upload } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { useCallback, useContext, useMemo, useState } from "react";

import {
  // entriesApiCrud,
  useEntriesApi,
  coordinatesMatch,
  matchSchedulesWithExcelData,
} from "../utils";
import { ExcelDataType } from "../payrollLiveTypes";
import PayrollLiveContext from "../PayrollLiveContext";
import { MondayButton } from "src/components/commonComponents";
import {
  StoreType,
  ProgramFieldType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { DownloadIcon } from "src/components/SidebarPages/BasePage/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { withinRadius } from "../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { findDistanceFromGeofenceSide } from "../../Payroll/Tabs/DEG/components/modalComponents/utils";
import { updateProgramField } from "src/components/pages/Settings/settingsComponents/ClientsPayrollConfig/clientsPayrollConfigUtils";

import "./UploadExcelSchedule.scss";

interface Props {
  open: boolean;
  onCancel: () => void;
}

type GridApi = typeof AgGridReact.prototype.api;
type ColumnApi = typeof AgGridReact.prototype.columnApi;

const defaultColDef = {
  flex: 1,
  filter: true,
  sortable: true,
  editable: false,
  resizable: true,
  enablePivot: true,
  enableRowGroup: true,
};

function UploadExcelSchedule(props: Props) {
  const { open, onCancel } = props;
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const programFields = useSelector(
    (store: StoreType) => store.programFields.programFields
  );
  const {
    jobsites,
    crewTeams,
    schedules,
    degEntries,
    clientCompany,
    clientConfigs,
    employeesReport,
  } = useContext(PayrollLiveContext);

  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>();

  const dispatch = useDispatch();
  const { updateEntries } = useEntriesApi();

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  }, []);

  function onFilterTextChange(e: string) {
    if (gridApi) {
      gridApi.setQuickFilter(e);
    }
  }

  function onFileUpload(event: UploadRequestOption<any> & { file: any }) {
    const selectedClient = clientConfigs.find(
      (el) => el.configId === clientCompany
    );

    let fileReader = new FileReader();
    message.loading({
      key: "uplMsg",
      content: "Uploading file...",
      duration: 0,
    });
    fileReader.addEventListener("load", async () => {
      let { Sheets } = read(fileReader.result, {
        sheets: "Sheet1",
      });

      if (Sheets?.["Sheet1"]) {
        const excelData: Array<ExcelDataType> = utils.sheet_to_json(
          Sheets["Sheet1"]
        );

        const matchedJobs = await matchSchedulesWithExcelData(
          jobsites,
          schedules,
          excelData
        );

        const { matchedEmployees } = coordinatesMatch({
          crewTeams,
          jobs: matchedJobs?.jobsitesIncluded,
          employees: employeesReport,
          schedules: matchedJobs?.schedulesIncluded,
        });

        let degEntriesToUpdate = [];
        degEntries.forEach((el) => {
          const employeeNumber = Number((el?.employeeId || "").split("-")?.[1]);
          const empIndex = matchedEmployees.findIndex(
            (emp) => employeeNumber === Number(emp?.employeeNumber)
          );
          const jobIndex = jobsites.findIndex(
            (el) => el?.projectId === matchedEmployees?.[empIndex]?.projectId
          );

          const jobMatch = jobsites?.[jobIndex];

          if (jobMatch) {
            let distance;
            if (
              jobMatch?.geoFenceInfo?.length ||
              jobMatch?.geofenceInfo?.length
            ) {
              const geoInfo = jobMatch?.geofenceInfo?.length
                ? [{ geoFenceInfo: jobMatch?.geofenceInfo }]
                : jobMatch?.geoFenceInfo;

              distance = findDistanceFromGeofenceSide({
                geofence: geoInfo?.[0]?.geoFenceInfo,
                point: el?.punchCoordinates,
                tolerance: 300,
              })?.distanceInFeet;
            }
            const distanceFromJob = withinRadius(
              jobMatch?.addressPosition,
              el?.punchCoordinates
            );
            if (
              distanceFromJob?.distanceInFeet > distance?.distanceInFeet &&
              !distance?.distanceInFeet
            ) {
              distance = distanceFromJob?.distanceInFeet;
            }

            if (isNaN(distance)) {
              distance = withinRadius(
                jobMatch?.addressPosition,
                el?.punchCoordinates
              )?.distanceInFeet;
            }

            const duration = Math.round(distance / 4.7);
            const updatedEntry = {
              ...el,
              duration,
              distanceFromJob: distance || 0,
              jobsiteId: jobMatch?.jobsiteId,
              activityStatus:
                distance > 300 || !distance ? "Pending" : "Completed",
              jobsiteMatch: {
                jobName: jobMatch?.jobName,
                services: jobMatch?.services,
                jobsiteId: jobMatch?.jobsiteId,
                jobAddress: jobMatch.jobAddress,
                reimbursement: jobMatch?.reimbursement,
              },
            };

            degEntriesToUpdate.push(updatedEntry);
          }
        });

        const field = {
          clientKey: selectedClient?.clientKey,
          clientName: selectedClient?.clientName,
          fileName: event?.file?.name || "Unknown",
          excelData: matchedJobs?.updatedExcelData,
        };

        const updatedFields =
          todayScheduleField?.field?.fieldOptions.findIndex(
            (el) => el?.clientName === selectedClient?.clientName
          ) > -1
            ? todayScheduleField?.field?.fieldOptions.map((el) =>
                el?.clientName === selectedClient?.clientName ? field : el
              )
            : todayScheduleField?.field?.fieldOptions.concat(field);

        updateProgramField({
          dispatch,
          fieldOptions: updatedFields,
          fieldId: todayScheduleField?.field?.fieldId,
          fieldName: todayScheduleField?.field?.fieldName,
        });

        if (degEntriesToUpdate?.length) {
          updateEntries({
            entries: degEntriesToUpdate,
            onErrorCallback: (err) => {
              console.log("Error updating degEntries: ", err);
            },
          });
          // entriesApiCrud({
          //   method: "updateBatch",
          //   body: degEntriesToUpdate,
          // }).catch((err) => {
          //   console.log("Error updating degEntries: ", err);
          // });
        }

        return event.onSuccess(Sheets);
      } else {
        message.error({
          key: "uplMsg",
          content: `Sheet "Sheet1" was not found`,
          duration: 2.2,
        });
        return event.onError({
          message: "Sheet Crew composition was not found",
          name: "Upload Error",
        });
      }
    });
    fileReader.readAsArrayBuffer(event?.file);
  }

  function onFlashRows() {
    gridApi.flashCells({
      rowNodes: gridApi.getRenderedNodes(),
    });
  }

  const todayScheduleField = useMemo(() => {
    if (programFields?.length && clientConfigs && gridApi) {
      let exportedField: ProgramFieldType;

      for (let i = 0; i < programFields.length; i++) {
        const field = programFields[i];
        if (field.fieldName === "Today's Scheduling") {
          exportedField = field;
        }
      }
      programFields.findIndex(
        (field) => field.fieldName === "Today's Scheduling"
      );
      const selectedClient = clientConfigs.find(
        (el) => el.configId === clientCompany
      );
      const companyField =
        exportedField.fieldOptions.find(
          (el) => el?.clientName === selectedClient?.clientName
        ) || {};
      gridApi.setRowData(companyField?.excelData || []);
      setTimeout(() => {
        message.success({
          key: "uplMsg",
          content: "File Uploaded",
          duration: 1.8,
        });
        columnApi.autoSizeAllColumns(false);
        onFlashRows();
      }, 100);
      return {
        companyField,
        field: exportedField,
      };
    }
  }, [programFields, clientConfigs, gridApi]);

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: "Name",
        field: "Name",
      },
      {
        headerName: "ID",
        field: "ID Number",
      },
      {
        headerName: "Role",
        field: "Role",
      },
      {
        headerName: "Crew",
        field: "Crew",
      },
      {
        headerName: "Foreman",
        field: "Foreman",
      },
      {
        headerName: "Location",
        field: "Location",
      },
      {
        headerName: "Department",
        field: "Department",
      },
    ];
  }, []);

  return (
    <Modal
      closable
      centered
      open={open}
      destroyOnClose
      onCancel={onCancel}
      maskClosable={false}
      closeIcon={<XIcon />}
      title="Upload Today's Schedule"
      className={`upload-schedule-modal ${
        darkMode ? "upload-schedule-modal-dark" : ""
      }`}
      footer={[
        <MondayButton
          Icon={<XIcon />}
          onClick={onCancel}
          className="mondayButtonRed"
        >
          Close
        </MondayButton>,
      ]}
    >
      <section className="upload-control-panel" style={{ width: 1200 }}>
        <span className="file-name">
          {todayScheduleField?.companyField?.fileName}
        </span>
        <Upload
          multiple={false}
          accept="xlsx, xls"
          showUploadList={false}
          customRequest={onFileUpload}
        >
          <MondayButton
            Icon={<DownloadIcon />}
            className="mondayButtonBlue upload-btn"
          >
            Upload Today's Schedule
          </MondayButton>
        </Upload>
        <InputComponent
          type="input"
          placeholder="Search..."
          onChange={(e) => onFilterTextChange(e.target.value)}
        />
      </section>
      <section
        className={`table-wrapper ${
          darkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          pagination={true}
          animateRows={true}
          columnDefs={columnDefs}
          paginationPageSize={10}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
        />
      </section>
    </Modal>
  );
}

export default UploadExcelSchedule;
