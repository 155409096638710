import {
  Badge,
  Breadcrumb,
  Button,
  Input,
  Modal,
  Result,
  Spin,
  Tooltip,
  message,
  Avatar,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { FolderIconBlue } from "../../../../../../../../../../../../icons";
import "./DriveNavigate.scss";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../../../../../../../integrations/DriveRequest";
import { GoBackButton } from "../../../../../../../../../../../pages/Settings/settingsComponents/Management/EditUser/components/UserAccessWrites/components";
import {
  BackIcon,
  DriveIcon,
  FileIcon,
} from "../../../../../../../../../../Communication/assets";
import {
  LeftArrow,
  SaveIcon,
} from "../../../../../../../../../../BasePage/src";
import TreeDriveNavigate from "./TreeDriveNavigate/TreeDriveNavigate";
import {
  CheckIconModal,
  CloseIconModal,
} from "../../../../../../../../../../../../assets";
import { useStateContext } from "../../../../../../Context/Context";
import Swal from "sweetalert2";
import { swalToast } from "../../../../../../../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { AddIcon } from "../../../../../../../../../../../Header/components/GoogleEvents/assets";
import CreateNewFolder from "./CreateNewFolder/CreateNewFolder";

const getInitalTreeData = (folderId) => {
  return {
    parentFamily: "CORE STORAGE",
    navHistory: [
      {
        id: folderId,
        title: "CORE STORAGE",
      },
    ],
  };
};

function DriveNavigate({
  folderId = "",
  buttonContent = "Save on Drive",
  buttonClassName = "mondayButtonBlue",
  toolTipTitle = "Save to Drive",
  fileName,
  ButtonIcon = <DriveIcon />,
  darkMode = false,
  onSave = () => {},
  currentFolderId = "",
  saveExportFunc = async () => {}, // The file excel export function
}) {
  const [visible, setVisible] = useState(false);
  const [familyData, setFamilyData] = useState(false);
  const { accessToken = "" } = useSelector((state) => state.accessToken);
  const { getFilesByFolderId, createFolders, getDriveItem, ...rest } = driveApi(
    {
      accessToken,
    }
  );
  const [parentFolderName, setParentFolderName] = useState("CORE STORAGE");
  const [test, setLoading] = useState(true);
  const [filterTerm, setFilterTerm] = useState(null);
  const [treeData, setTreeData] = useState(getInitalTreeData(folderId));
  const currentView = useMemo(() => {
    console.log("family", familyData);
    return Array.isArray(familyData)
      ? familyData
          ?.filter((e) => {
            return !!filterTerm
              ? e?.name?.toLowerCase()?.includes(filterTerm?.toLowerCase())
              : true;
          })
          ?.map((folder, i) => {
            return {
              id: i,
              name: folder?.name,
              type:
                folder?.mimeType === "application/vnd.google-apps.folder"
                  ? "folder"
                  : "file",
              svgIcon:
                folder?.mimeType === "application/vnd.google-apps.folder" ? (
                  <FolderIconBlue />
                ) : (
                  <FileIcon />
                ),
              ...(folder || {}),
            };
          })
      : false;
  }, [familyData, filterTerm]);

  const loading = currentView === false;

  const updateTreeData = (key, val) => {
    setTreeData((prev) => ({ ...prev, [key]: val }));
  };

  const onPickFolder = (currItem, save) => {
    getDriveItem(currItem.id)
      .then((res) => res.json())
      .then((data) => {
        console.log("getDriveItem", data);
        setParentFolderName(data?.name || "CORE STORAGE");
      });
    const attemptedFoldId = currItem.id;
    if (currItem.type === "file") {
      window.open(currItem.webViewLink, "_blank");
    } else {
      setLoading(true);
      getFilesByFolderId(attemptedFoldId)
        .then((res) => res.json())
        .then((data) => {
          console.log("data", data.files);
          setFamilyData(data.files);
          if (save) {
            let arr = [...(treeData.navHistory || []), newItem(currItem)];
            updateTreeData(
              "navHistory",
              arr.filter((item, index) => arr.indexOf(item) === index)
            );
          }
        })
        .finally(() => setLoading(false));
    }
    setFilterTerm(null);
  };

  const newItem = (item) => {
    return {
      id: item.id,
      title: item.name,
      type: item.mimeType,
      onClick: onGoBack,
    };
  };

  const onGoBack = (paramsItem) => {
    setLoading(true);
    try {
      let lastIdx = treeData.navHistory.findIndex(
        (e) => e.id === paramsItem.id
      );
      if (lastIdx !== -1) {
        let arr = treeData.navHistory.slice(0, lastIdx + 1);
        updateTreeData("navHistory", arr);
        getFilesByFolderId(paramsItem.id)
          .then((res) => res.json())
          .then((data) => {
            console.log("data", data.files);
            setFamilyData(data.files);
          })
          .finally(() => setLoading(false));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSearch = (paramsItem) => {
    console.log("paramsItem", { test: paramsItem.target.value, currentView });
    setFilterTerm(paramsItem.target.value);
  };

  const houseKeeping = () => {
    setFamilyData([]);
    setTreeData(getInitalTreeData(folderId));
    setFilterTerm(null);
    setVisible(false);
  };

  const onModalStart = () => {
    setFamilyData([]);
    setTreeData(getInitalTreeData(folderId));
    setFilterTerm(null);
    setVisible(true);
  };

  const checkDuplicates = async (fodlerId, newName) => {
    return await getFilesByFolderId(fodlerId)
      .then((res) => res.json())
      .then((data) => {
        let currentFiles = data.files.find((file) => file.name === newName);
        console.log("currentFiles", { currentFiles, newName, data });
        if (!!currentFiles) {
          return currentFiles;
        }
        return false;
      });
  };

  const onSaveChanges = async () => {
    console.log("onSaveChanges", {
      familyData,
      treeData,
      filterTerm,
      currentView,
    });
    let locationToSend = treeData.navHistory[treeData.navHistory.length - 1].id;
    console.log("locationToSend", locationToSend);

    let hasDuplicates = await checkDuplicates(locationToSend, fileName);
    console.log("hasDuplicates", hasDuplicates);
    if (!hasDuplicates) {
      await saveExportFunc(locationToSend);
      setVisible(false);
    } else {
      console.log("hasDuplicates", hasDuplicates);
      Swal.fire({
        title: "A file with the same name already exists!",
        text: "Would you like to replace it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          message.loading("Attaching file to specific folder...");
          await deleteFiles({
            arrOfFiles: [hasDuplicates],
            driveRequest: rest,
          });
          await saveExportFunc(locationToSend);
          message.destroy();
          setVisible(false);
        } else {
          message.destroy();
          swalToast({
            icon: "info",
            title: "File did NOT save!",
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
          });
        }
      });
    }
  };

  const onCreateNewFolder = async (folderName) => {
    if (!folderName) {
      return false;
    }

    return await createFolders({
      folderNames: [folderName],
      parents: [currentView[currentView.length - 1].id],
    })
      .then((res) => {
        message.success("Folder created successfully!");
        setTimeout(() => {
          getData();
        }, 1000);

        return true;
      })
      .catch((err) => {
        message.error("Folder creation failed!");
        return false;
      });
    // .finally(() => setLoading(false));
  };

  const getData = () => {
    // setLoading(true);
    getFilesByFolderId(folderId)
      .then((res) => res.json())
      .then((data) => {
        console.log("data", data);
        setFamilyData(data.files);
      })
      .finally(() => {
        setLoading(false);
        if (!!currentFolderId) {
          setTimeout(() => {
            onPickFolder({ id: currentFolderId }, false);
          }, 200);
        }
      });
  };

  useEffect(() => {
    getData();
  }, [folderId, accessToken, visible, currentFolderId]);

  return (
    <>
      <Tooltip title={toolTipTitle}>
        <MondayButton
          onClick={onModalStart}
          className={buttonClassName}
          Icon={ButtonIcon}
        >
          {buttonContent}
        </MondayButton>
      </Tooltip>
      {visible && (
        <Modal
          open={visible}
          className={`driveNavigateModal${darkMode ? "Dark" : ""}`}
          title="Navigate Files"
          onCancel={() => setVisible(false)}
          width={1680}
          footer={
            <div className="driveNavigateModalFooter">
              <MondayButton
                className="mondayButtonRed"
                Icon={<CloseIconModal />}
                onClick={() => houseKeeping()}
              >
                Cancel
              </MondayButton>{" "}
              <div className="actionsFolderContain">
                {parentFolderName && (
                  <strong> {`> ${parentFolderName} <`}</strong>
                )}

                {/* <Breadcrumb.Item
                  key={454656}
                  // onClick={() => onGoBack(item)}
                >
                  {parentFolderName && parentFolderName}
                </Breadcrumb.Item> */}
                {/* <Breadcrumb separator=">">
                  {treeData.navHistory?.map((item, index) => {
                    return (
                      <Breadcrumb.Item
                        key={index}
                        onClick={() => onGoBack(item)}
                      >
                        {item.title}
                      </Breadcrumb.Item>
                    );
                  })}
                </Breadcrumb> */}
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <CreateNewFolder onCreateNewFolder={onCreateNewFolder} />
                <MondayButton
                  className="mondayButtonBlue"
                  Icon={<CheckIconModal />}
                  onClick={onSaveChanges}
                >
                  Save Changes
                </MondayButton>
              </div>
            </div>
          }
          centered
        >
          {" "}
          <Spin spinning={loading} fullscreen />{" "}
          {/* <div className="actionsFolderContain">
            <Breadcrumb separator=">">
              {treeData.navHistory?.map((item, index) => {
                return (
                  <Breadcrumb.Item key={index} onClick={() => onGoBack(item)}>
                    {item.title}
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          </div> */}
          <div>
            {" "}
            <br />
            <Input
              value={filterTerm}
              placeholder="Search for files and folders..."
              onChange={onSearch}
            />
            <br />
          </div>
          <div className="treeHolderContainer">
            <div className="treeHolderData">
              <TreeDriveNavigate
                {...{
                  onPickFolder,
                  folderId,
                  loading,
                  currentView,
                  currentFolderId,
                  parentFolderName,
                }}
              />
            </div>

            <div className="folderHolderContainer">
              {currentView.length > 0 ? (
                currentView?.map((item) => {
                  return (
                    <div
                      className="folderHolderWrapper"
                      onClick={() => onPickFolder(item, true)}
                    >
                      <div className="folderHolder">
                        {item?.type === "folder" ? (
                          item.svgIcon
                        ) : (
                          <DocumentVisual item={item} />
                        )}
                      </div>
                      <div
                        style={{
                          width: "150px", // Set the width to control the text width
                          overflow: "hidden",
                          paddingLeft: "10px",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <span>{item.name}</span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="noFilesFoundContainer">
                  {loading !== true && (
                    <Result
                      status="500"
                      title="NO FILES FOUND!"
                      subTitle="Sorry, there is nothing interesting here but you can still save the day by adding some files."
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default DriveNavigate;

const typesOfFiles = {
  "application/vnd.google-apps.spreadsheet": "spreadsheet",
  "application/vnd.google-apps.document": "document",
  "application/vnd.google-apps.presentation": "presentation",
  "application/vnd.google-apps.folder": "folder",
  "application/vnd.google-apps.file": "file",
};

const DocumentVisual = ({ item }) => {
  return (
    <img
      // src={item.thumbnailLink || item.iconLink}
      src={
        "https://upload.wikimedia.org/wikipedia/commons/7/7a/Circle-icons-document.svg"
      }
      width={"150px"}
      height={"140px"}
      // style={{
      //   objectFit: "cover",
      //   borderRadius: "5px",
      //   boxShadow: "0px 0px 5px 0px #000000",
      // }}
    />
  );
};

const deleteFiles = async ({ arrOfFiles = [], driveRequest = {} }) => {
  if (!Array.isArray(arrOfFiles)) {
    return false;
  } else {
    for (const file of arrOfFiles) {
      await driveRequest.deleteDriveItem(file.id);
    }
  }
};
