import { useHoistPricingContext } from "../context";
import { PriceSchemeSelector } from ".";
import { MondayButton } from "../../../../../../../commonComponents";

import { Select, Tooltip } from "antd";
import { Switch } from "antd";
import { ExportPdfIcon } from "../../../../../../../../assets";
import {
  pdfBorderedSection,
  docDefinition,
} from "../../../../../pdfExportOnComponents";
import createPDF, {
  PREVIEW,
} from "../../../../../../../../integrations/createPDF";
import { compareIncluding } from "../../../../../../../SidebarPages/utils";
import {
  FileFilled,
  HistoryOutlined,
  LockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { DropDownArrow } from "../../../../../../../SidebarPages/Fleet/components/InputComponent/assets";
import { usePricingContext } from "../../../../PricingV2/context";
import { useResponsive } from "../../../../../../../../hooks";
import { generateDocDefinition } from "../../../../../../../../utils/generateDocDefinition";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const Header = ({
  setSaveGridChanges,
  isDarkMode,
  getUsedIn,
  setEstimationModal,
}) => {
  const {
    editable,
    setEditable,
    priceSchemes,
    configuration,
    hoistPricingData,
    selectedPriceSchemeID,
    setSelectedPriceSchemeID,
    hoistTypeSwitchChecked,
    setHoistTypeSwitchChecked,
  } = useHoistPricingContext();

  const {
    pricingObject,
    setPricingObject,
    generateNewLog,
    handleShowLogs,
    schemeHeaderRef5,
    schemeHeaderRef6,
    schemeHeaderRef8,
    schemeHeaderRef9,
    schemeHeaderRef10,
  } = usePricingContext();

  const { base64 } = useSelector((state) => state.base64);

  const { defaultPriceSchemeId = "" } = hoistPricingData;

  const { tablet } = useResponsive();

  // Changes the default price scheme
  const onDefaultPriceSchemeChange = (schemeId) => {
    generateNewLog(
      "Edit",
      `Default Schema ${pricingObject.priceSchemesType}`,
      {
        id: pricingObject.defaultPriceSchemeId,
      },
      {
        id: schemeId,
      },
      schemeId
    );

    setPricingObject((prev) => ({ ...prev, defaultPriceSchemeId: schemeId }));
  };

  let hoistSchemePdfData = [
    pdfBorderedSection({
      title: "Hoist Configuration",
      body: configuration,
      type: "hoistConfiguration",
    }),
  ];

  const onGeneratePdf = () => {
    let companyLogo = base64?.find(({ fileName }) =>
      compareIncluding(fileName, "Core Logo Black")
    )?.base64;
    createPDF({
      action: PREVIEW,
      docDefinition: docDefinition(
        `Pricing for service: Hoist Configuration`,
        hoistSchemePdfData,
        companyLogo
      ),
      fileName: `PricingForService/HoistConfiguration`,
    });
  };

  const onGeneratePDF = () => {
    const tableData = [];

    const tableHeaders = ["Name", "Type", "Created At", "Created By"];

    const columnsToInclude = tableHeaders.map((item, index) => index);

    for (const item of configuration) {
      const name = item?.label || "";
      const type = item?.type || "";
      const createdAt = dayjs(item?.createdAt)?.format("MM/DD/YYYY") || "";
      const createdBy = item?.createdBy || "";

      tableData.push([name, type, createdAt, createdBy]);
    }

    const docDefinition = generateDocDefinition(
      base64,
      "Hoist Confguration",
      columnsToInclude,
      tableHeaders,
      tableData
    );

    createPDF({
      action: "open",
      docDefinition,
      title: false,
    });
  };

  const handleEditChange = (checked) => {
    setEditable((prev) => !prev);
    checked === false && setSaveGridChanges(true);
  };

  return (
    <div
      className={`config-card-header ${tablet && "config-card-header-tablet"}`}
    >
      <span className="title">Configuration</span>
      <Switch
        ref={schemeHeaderRef5}
        style={{ backgroundColor: editable ? "#68c142" : "#fe4c4a" }}
        unCheckedChildren={<LockOutlined style={{ fontSize: "20px" }} />}
        checkedChildren={<UnlockOutlined style={{ fontSize: "20px" }} />}
        checked={editable}
        onChange={handleEditChange}
      />
      <Switch
        unCheckedChildren="Single"
        checkedChildren="Dual"
        checked={hoistTypeSwitchChecked}
        onChange={setHoistTypeSwitchChecked}
      />

      <div className="default-pricing-scheme-selector" ref={schemeHeaderRef6}>
        <div className="selector-prefix">Default Scheme:</div>
        <Select
          placeholder="Select..."
          value={defaultPriceSchemeId}
          suffixIcon={<DropDownArrow fill="#fff" />}
          popupClassName={isDarkMode && "darkDropDown"}
          onSelect={onDefaultPriceSchemeChange}
          options={priceSchemes.map(({ name, id }) => ({
            label: name,
            value: id,
          }))}
        />
      </div>

      <PriceSchemeSelector
        {...{
          pricingObject,
          setPricingObject,
          generateNewLog,
          priceSchemes,
          value: selectedPriceSchemeID,
          onChange: setSelectedPriceSchemeID,
          isDarkMode,
        }}
      />

      <div ref={schemeHeaderRef8}>
        <Tooltip title="Logs">
          <MondayButton
            className="mondayButtonBlue"
            id="newConfigButton"
            onClick={() =>
              handleShowLogs(`Schema ${pricingObject.priceSchemesType}`)
            }
            Icon={<HistoryOutlined width={18} />}
          />
        </Tooltip>
      </div>

      <div ref={schemeHeaderRef9}>
        <Tooltip title="Used In">
          <MondayButton
            className="mondayButtonBlue"
            Icon={<FileFilled width={18} />}
            onClick={() => {
              setEstimationModal(true);
              getUsedIn();
            }}
          />
        </Tooltip>
      </div>

      <div ref={schemeHeaderRef10}>
        <Tooltip title="Export To PDF">
          <MondayButton
            className="mondayButtonBlue"
            Icon={<ExportPdfIcon />}
            onClick={onGeneratePDF}
            style={{ marginRight: "10px" }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Header;
