import { Modal } from "antd";
import { API } from "aws-amplify";
import { SearchIcon, XIcon } from "../../../SidebarPages/Communication/assets";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Transfer } from "../../../SidebarPages/DynamicView/editComponents";
import "./DynamicTeamModal.scss";
import { updateDocumentTitle } from "../../../../utils";
import { useSelector } from "react-redux";
import { InputComponent } from "../../../SidebarPages/Fleet/components";
import TeamsList from "./TeamsList";
import { InfoText } from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { onTransferChange } from "./helpers";

function filterUniqueMembers(teams) {
  const uniqueMembers = new Set();
  const tmp = [...teams];

  return tmp.map((team) => {
    const filteredMembers = (team?.members || [])?.filter((member) => {
      if (uniqueMembers.has(member?.identityId)) {
        return false;
      }
      uniqueMembers.add(member?.identityId);
      return true;
    });

    return {
      ...team,
      members: filteredMembers,
    };
  });
}

/**
 * Represents team transfer modal that adds or removes users from a team.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines whether the modal is open or not.
 * @param {function} props.setOpen - A function to set the open state of the modal.
 * @param {Array} props.selectedTeam - The selected team.
 * @param {function} props.setSelectedTeam - A function to set the changed values to selectedTeam.
 * @param {boolean} props.isDarkMode - Determines whether the dark mode is enabled or not.
 * @param {function} props.ClearOptions - A function to clear options.
 * @param {boolean} [props.footerDisabled=false] - Determines whether the footer buttons are disabled or not.
 * @param {Array} [props.proppedAllTeams] - An array of all teams.
 * @param {'teamName' | 'teamId'} props.comparator - The comparator to compare the selected team. If onTeamSelect does not add teamId key to selected team, use 'teamName'.
 * @param {function} [props.onSave=()=>{}] - A function to save the selected team.
 * @returns {JSX.Element} The team transfer modal. If Changes are made it sets the selected team as [{value:'Team',members:[{identityId, cognitoUserId, nameOfUser}],teamId:[]}]
 */
const DynamicTeamModal = ({
  open,
  setOpen,
  selectedTeam,
  setSelectedTeam = () => {},
  ClearOptions,
  footerDisabled = false,
  proppedAllTeams = [],
  onSave = () => {},
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [selectedTeamTmp, setSelectedTeamTmp] = useState(
    filterUniqueMembers(selectedTeam)
  );
  const [allTeams, setAllTeams] = useState([]);

  const comparator = useMemo(() => {
    return selectedTeam?.every((team) => team.hasOwnProperty("teamId"))
      ? "teamId"
      : "teamName";
  }, []);

  const allTeamsObj = useMemo(() => {
    return allTeams.reduce((acc, team) => {
      acc[team.teamId] = team;
      return acc;
    }, {});
  }, [allTeams]);

  const getUsers = useMemo(
    () =>
      userConfiguration?.allUsers?.Items?.filter(
        ({ isSuspended }) => !isSuspended
      ).map((user) => ({
        identityId: user?.identityId,
        nameOfUser: user?.nameOfUser,
        cognitoUserId: user?.cognitoUserId,
        groupName: user?.groupName,
        userName: user?.userName,
        googleDriveFieId: user?.googleDriveFieId,
      })),
    [userConfiguration]
  );

  const allUsersObj = useMemo(() => {
    return getUsers.reduce((acc, user) => {
      acc[user.identityId] = user;
      return acc;
    }, {});
  }, [getUsers]);

  const [searchTeam, setSearchTeam] = useState([]);
  const [activeFilter, setActiveFilter] = useState(false);

  const teamSelectedValues = useMemo(() => {
    if (Array.isArray(selectedTeamTmp) && selectedTeamTmp?.length > 0) {
      let funcArr = [];
      selectedTeamTmp?.map((el) => {
        el?.members?.map((x) => {
          funcArr.push(x?.identityId);
        });
      });
      return funcArr;
    } else {
      return [];
    }
  }, [selectedTeamTmp]);

  function debounceSearch(func, timeout = 150) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const onSearchTeam = useCallback(
    (e) => {
      const substring = e?.target?.value;
      const filteredData = getUsers?.filter((item) =>
        item?.nameOfUser?.toLowerCase().includes(substring.toLowerCase())
      );
      setSearchTeam(filteredData);
      substring !== "" ? setActiveFilter(true) : setActiveFilter(false);
    },
    [getUsers, setSearchTeam, setActiveFilter]
  );

  function getEditedValue(team) {
    setSelectedTeam(selectedTeamTmp || []);
    onSave(selectedTeamTmp || []);
    handleCloseModal();
  }

  const getContent = useMemo(() => {
    const userList = activeFilter ? searchTeam : getUsers;

    return userList?.map((users) => ({
      title: users?.nameOfUser,
      id: users?.identityId,
      role: users?.groupName,
      email: users?.userName,
    }));
  }, [activeFilter, searchTeam, getUsers]);

  const handleCloseModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setOpen(false);
  };

  useEffect(() => {
    if (proppedAllTeams.length > 0) {
      setAllTeams(proppedAllTeams);
    } else {
      (async () => {
        API.get("teams", "/teams").then((res) => {
          setAllTeams(res);
        });
      })();
    }
  }, []);

  return (
    <Modal
      className={`memberTeamModal ${isDarkMode && "memberTeamModalDark"}`}
      title="Configured Team"
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Configured Team" });
      }}
      footer={null}
      open={open}
      closable={true}
      closeIcon={<XIcon />}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      centered={true}
    >
      <div className="memberTeam-container">
        <InfoText
          {...{
            text: "If you're making changes, please do not forget to save them by clicking the button below.",
          }}
        />

        <TeamsList
          {...{ selectedTeamTmp, setSelectedTeamTmp, allTeams, comparator }}
        />

        <div className="editMembers-container">
          <InputComponent
            {...{
              className: "searchEditMembers",
              placeholder: "Search members...",
              allowClear: true,
              prefix: <SearchIcon width={15} height={15} />,
              onChange: debounceSearch((e) => onSearchTeam(e)),
              noFormItem: true,
            }}
          />
          <Transfer
            footerDisabled={footerDisabled}
            params={{
              onTransferChange: (type, userId) => {
                onTransferChange({
                  type,
                  userId,
                  selectedTeamTmp,
                  setSelectedTeamTmp,
                  allUsersObj,
                  allTeams,
                  comparator,
                });
              },
              content: {
                value: teamSelectedValues,
                data: getContent,
                formItemName: "members",
              },
            }}
            getEditedValue={getEditedValue}
            setEditMode={() => {}}
            setCloseTeamModalsOnCancel={() => {
              onSearchTeam({ target: { value: "" } });
              ClearOptions();
            }}
            isDarkMode={isDarkMode}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DynamicTeamModal;
