/**
 * This function return the list of Form fields
 * @typedef
 * @property {{crewName: string, crewId: string, employeeId: string}[]} crews   
 * @property {void} calculateAmount Function that calculates the amount on field changes (workHours, otHours, employeeRate)
 * @property {void} onAmountChange Function that resets (workHours, otHours, employeeRate) fields on amount change
 * @property {Object} selectedRow Selected entry to edit
 * @property {Object[]} selectedNodes Selected nodes to update in mass entry
 
 * 
 * @param {props} param0 
 */
export function fieldsList({
  crews,
  selectedRow,
  selectedNodes,
  onAmountChange,
  calculateAmount,
}) {
  return {
    ["Employees"]: [
      {
        label: "Employees",
        formItemName: "selectedMembers",
        type: "styledmultiselect",
        mode: "multiple",
        placeholder: "Select members...",
        optionFilterProp: "label",
        required: true,
        showSearch: true,
        disabled: selectedRow?.entryId || !!selectedNodes?.length,
        customOptions: crews?.map(({ crewId, crewName, employeeId }) => ({
          value: crewId,
          label: `${crewName} (${employeeId})`,
        })),
      },
    ],
    ["Shift Details"]: [
      {
        label: "Shift Type",
        required: true,
        allowClear: false,
        type: "select",
        formItemName: "punchType",
        placeholder: "Select type...",
        initialValue: "CASH",
        options: ["CASH", "1099"],
      },
      {
        label: "Status",
        required: true,
        allowClear: false,
        type: "select",
        formItemName: "shiftStatus",
        initialValue: "Draft",
        placeholder: "Select status...",
        options: ["Draft", "Pending", "Completed"],
      },
      {
        label: "Selected Date",
        type: "datepicker",
        formItemName: "selectedDate",
        required: true,
      },
      {
        label: "Work Hours",
        required: false,
        allowClear: false,
        type: "input",
        typeNumber: true,
        formItemName: "workHours",
        placeholder: "Set work hours...",
        onChange: () => calculateAmount(),
      },
      {
        label: "Overtime Hours",
        required: false,
        allowClear: false,
        type: "input",
        typeNumber: true,
        formItemName: "otHours",
        placeholder: "Set overtime hours...",
        onChange: () => calculateAmount(),
      },
      {
        label: "Rate",
        required: false,
        allowClear: false,
        type: "input",
        typeNumber: true,
        formItemName: "employeeRate",
        placeholder: "Set rate for employees...",
        onChange: () => calculateAmount(),
      },
      {
        label: "Amount",
        formItemName: "totalOvh",
        required: true,
        type: "input",
        onChange: onAmountChange,
        typeNumber: true,
        allowClear: false,
        placeholder: "Total Amount",
      },
      {
        label: "Reason",
        rows: 3,
        formItemName: "reason",
        required: false,
        type: "textarea",
        allowClear: true,
        style: { height: 100 },
        placeholder: "Reason...",
      },
    ].filter(Boolean),
  };
}
