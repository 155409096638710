import React from "react";
import { useState } from "react";
import { ReportIcon } from "../../../../../../icons";
import { MondayButton } from "../../../../../commonComponents";
import { filterTables } from "../../../../../../../src/utils/filterTables";
import { API } from "aws-amplify";
import InvoiceHistoryReport from "./InvoiceHistoryReport";
import { forceToNumber } from "../../../../../../utils/ActiveReportJsUtils/helpers";
import { message } from "antd";
function InvoiceHistoryReportWrapper({ params }) {
  console.log("InvoiceHistoryReportWrapper", params);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const onGenerateReport = async () => {
    setLoading(true);
    message.loading("Generating Report");
    setOpen(true);
    let { invoiceId = null, projectId = null } = params?.data || {};

    let project = await API.get("projects", `/projects/${projectId}`).then(
      (res) => {
        return res;
      }
    );
    let assocData = {
      invoices: [],
      payments: [],
      credits: [],
    };
    let tempHolder = [];
    if (!invoiceId || !projectId) {
      console.error("Invoice Id or Project Id is required to generate report");
      return;
    } else {
      let payments = await filterTables(
        "payments",
        "accountId",
        project?.accountId
      ).then((res) => {
        console.log("onGenerateReport", res);
        if (Array.isArray(res)) {
          res?.forEach((payment) => {
            payment.invoices?.forEach((invoice) => {
              if (invoice.invoiceId?.toString() === invoiceId?.toString()) {
                console.log("payment", payment);
                tempHolder.push({
                  type: "payment",
                  amount: forceToNumber(invoice?.invoicePaidAmount),
                  memo: payment?.paymentMemo,
                  date: payment?.paymentDate,
                  referenceNumber: payment?.qbReferenceNumber,
                });
              }
            });
          });
        }
      });
      let charges = await filterTables(
        "charges",
        "projectId",
        project?.projectId
      ).then((res) => {
        console.log("chargesssss", res);
        res?.forEach((charge) => {
          if (charge?.chargeType === "Credit Memo") {
            charge?.chargeItems?.forEach((chargeItem) => {
              if (chargeItem?.invoiceId?.toString() === invoiceId?.toString()) {
                tempHolder.push({
                  type: "credit",
                  amount: forceToNumber(chargeItem?.creditAmount * -1),
                  memo: charge?.chargeMemo,
                  date: charge?.createdAt,
                });
              }
            });
          }
        });
      });
    }
    setData(tempHolder?.sort((a, b) => a.date - b.date));
    console.log("onGenerateReport", assocData);
    setLoading(false);
    message.destroy();
  };
  return (
    <div>
      <MondayButton
        className="mondayButtonBlue"
        Icon={<ReportIcon />}
        onClick={onGenerateReport}
      >
        Generate Report
      </MondayButton>
      {open && !loading && (
        <InvoiceHistoryReport
          open={open}
          {...{ setOpen, data, invoice: params?.data }}
        />
      )}
    </div>
  );
}

export default InvoiceHistoryReportWrapper;
