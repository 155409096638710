import React, { useEffect, useState, Suspense } from "react";
import { useSelector } from "react-redux";

import lazyRetry from "../../../utils/LazyRetry";
import { keys } from "../../pages/Settings/settingsComponents/Roles/RolesData";
import { formatCurrency, onDateFilter, onFilter } from "../utils";
import { LoadableComp } from "../XComponents";
import { dateFilterOptions } from "../XComponents/ControlPanel/datas";
import { useDeviceType } from "../../../hooks";
import { filterTables, updateDocumentTitle } from "../../../utils";
import { getStatus } from "./utilities/functions";
import {
  createColumnDefsFromDeviceConfigs,
  defaultColumnDefsBasePage,
} from "../../pages/Settings/settingsComponents/DeviceManagementV2/utils";
// import { statusKeys } from "./BasePageData";
// import DynamicBasePage from "./DynamicBasePage";
const DynamicBasePage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "DynamicBasePage" */ "./DynamicBasePage"),
    "DynamicBasePage"
  )
);

const BasePage = ({
  title,
  rowData = [],
  exportData = false,
  filtersObject = [],
  onPaginationChanged = () => {},
  columnDefs = [],
  panelObject = {},
  rowSelection = "single",
  videoCategory,
  fleetName = false,
  driverName = false,
  defaultExcelExportParams = false,
  getSelected = () => {},
  agGridDefs = {},
  isTab = false,
  collapseKeys = [],
  onRowClicked = () => {},
  onRowDoubleClicked = () => {},
  setConflicts = () => {},
  setOpenProgressLogs = () => {},
  getChartingOptions = false,
  customNew = false,
  hasFilter = true,
  hasNew = true,
  hasStatus = true,
  detailsCell = false,
  exportGridToExcel = false,
  formatCellValueHandler,
  getGridApi = () => {},
  customstyle = "",
  filterContainClassName = "",
  sumKey = false,
  onSelectionChanged = () => {},
  getRowStyle = () => {},
  optionButton,
  getGridApiForParent = () => {},
  paginationPageSize = 10,
  changesModalVisible = false,
  setChangesModalVisible = () => {},
  setDaysOverview = () => {},
  setViewCrews = () => {},
  isEditable = true,
  videoLinks,
  customFooterData = null,
  customHeaderData = null,
  context = {},
  fetchAllLogsButton = false,
  getFiltersData = () => {},
  deleteSelectedRowsParams = {
    buttonVisibility: false,
    onDeleteSelectedRows: () => {},
  },
  suppressRowClickSelection = false,
  suppressPaginationPanel = false,
  panelFilter = false, //if we have two types of status panels ex.NotesBasePage
  setPanelFilter = () => {},
  customStatusPanel = false,
  fullScreenOptions = true,
  hasLive = true,
  customSort = false,
  hasGalleryItems = false,
  Conflicts = {},
  statuses = {},
  searchTableHandler,
  allowFetchSearch,
  filterSearchData = () => {},
  getGridRef = () => {},
  onDebounceChange,
  paginationAutoPageSize = true,
  reloadGrid = () => {},
  reloadButton,
  setRowData = () => {},
  customToggle = false,
  duplicateStatusColorKey = false,
  warningObj = {},
  rowId,
  additionalStatusPanels = [],
  hasFetchSearchFromDb = false,
  fetchAllHandler,
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
  setCustomFiltersData = () => {},
  tooltipCategory,
}) => {
  const [clickedValue, setClickedValue] = useState();
  const [gridApi, setGridApi] = useState();
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [newColumnDefs, setNewColumnDefs] = useState();
  const [statusPanel, setStatusPanel] = useState({});
  const [deviceManagementConfigs, setDeviceManagementConfigs] = useState();

  // for example in notes we have two types of status panels so we get from programfields only the selected one
  function getStatusPanelData() {
    let tmpTitle = !duplicateStatusColorKey ? title : duplicateStatusColorKey;

    if (!customStatusPanel) {
      if (panelFilter?.visible || panelFilter?.visibleOne) {
        //visible is to display switch visibleOne is to get statuses when we have default switch example Leads/TaskBasePage

        return programFields?.find(
          ({ fieldName }) => fieldName === "Status colors"
        )?.fieldOptions[tmpTitle][panelObject?.key];
      }
      return programFields?.find(
        ({ fieldName }) => fieldName === "Status colors"
      )?.fieldOptions[
        tmpTitle === "Violations" ? "Fleets Violations" : tmpTitle
      ];
    } else {
      return customStatusPanel;
    }
  }

  // for example in notes we have two types of status panels so we get from programfields only the selected one
  const statusPanelData = getStatusPanelData();

  // added these variables to handle tabs columnDefs(to dont be included in device management)
  const pathName = window.location.pathname;
  // const pattern = /^(\/\w+\/[^\/\d]*|\/?\w+)$/;
  // const matches = pattern.test(pathName); // .test tests for a match in a string

  // title's converter
  pathName === "/fleetInspections" ? (title = "Fleets Inspections") : title;

  // Define a function to calculate the value based on your conditions
  function calculateValue(statusName) {
    return (
      rowData?.filter((e) => {
        if (panelObject?.key === "createdAt" && !customSort) {
          return (
            parseInt(e[panelObject?.key]) >=
              dateFilterOptions[statusName][0].valueOf() &&
            parseInt(e[panelObject?.key]) <=
              dateFilterOptions[statusName][1].valueOf()
          );
        } else {
          if (typeof e?.[panelObject?.key] === "string") {
            return (
              e?.[panelObject?.key]?.toLowerCase() === statusName?.toLowerCase()
            );
          } else {
            return getStatus({ title, statusName, record: e });
          }
        }
      })?.length || 0
    );
  }

  // Define a function to format the value based on sumKey
  function formatValue(statusName) {
    const filteredData =
      rowData?.filter((e) => e[panelObject?.key] === statusName) || [];
    const sum = filteredData.reduce(
      (acc, curr) => acc + (curr[sumKey.key] || 0),
      0
    );

    return sumKey.type === "currency" ? formatCurrency(sum) : sum;
  }

  let data = statusPanelData?.map(({ statusName, statusColor }) => {
    const hasStatus = statusName in statuses;
    const value = hasStatus ? (
      <span style={{ fontSize: 40 }}>{statuses[statusName]}</span>
    ) : sumKey ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <span style={{ fontSize: 40 }}>{calculateValue(statusName)}</span>
        <span style={{ fontSize: 16 }}>{formatValue(statusName)}</span>
      </div>
    ) : (
      calculateValue(statusName)
    );

    return {
      statusName,
      statusColor,
      value,
    };
  });

  // This section of code, from line 166 to line 256, handles the configuration and filtering of column definitions for the current device based on settings defined in the Device Management section.
  const currentDevice = useDeviceType(); // First, it retrieves the current device type using useDeviceType().
  const currentDepartment = userConfiguration?.departmentName;
  const currentRoleByDepartment = userConfiguration?.groupName;

  useEffect(() => {
    title &&
      updateDocumentTitle({ [isTab ? "newSubTitle" : "newTitle"]: title }); // set Title of Tab

    return () => {
      title && updateDocumentTitle(); // reset document title to "Lead Manager";
    };
  }, []);
  const [loadingColumnsConfig, setLoadingColumnsConfig] = useState(false);

  const fetchDeviceConfigs = async () => {
    setLoadingColumnsConfig(true);
    if (
      currentDevice &&
      currentDepartment &&
      currentRoleByDepartment &&
      title
    ) {
      try {
        const res = await filterTables(
          "deviceConfiguration",
          "categoryName",
          title
        );
        setLoadingColumnsConfig(false);
        if (res && res.length > 0) {
          const response = res[0];
          const findedConfigs = response
            ? response?.configs?.[currentDevice]?.[currentDepartment]?.[
                currentRoleByDepartment
              ]
            : null;
          const toReturn =
            findedConfigs && Object.keys(findedConfigs).length
              ? response?.configColumns?.map((row) => {
                  const addedRows = findedConfigs[row?.field];
                  if (addedRows) {
                    return {
                      ...row,
                      ...addedRows,
                    };
                  }
                })
              : undefined;
          setDeviceManagementConfigs(toReturn);
        } else {
          setDeviceManagementConfigs();
        }
      } catch (err) {
        console.error("Error fetching device configurations:", err);
        setLoadingColumnsConfig(false);
      }
    } else {
      setDeviceManagementConfigs();
      setLoadingColumnsConfig(false);
    }
  };

  useEffect(() => {
    fetchDeviceConfigs();
  }, [title, currentDevice, currentDepartment, currentRoleByDepartment]);

  useEffect(() => {
    const sourceColumnDefs =
      typeof columnDefs === "function" ? columnDefs() : columnDefs;

    const updatedColumnDefs = sourceColumnDefs;
    // const updatedColumnDefs =
    //   deviceManagementConfigs && deviceManagementConfigs.length
    //     ? createColumnDefsFromDeviceConfigs(
    //         deviceManagementConfigs,
    //         sourceColumnDefs
    //       )
    //     : defaultColumnDefsBasePage(sourceColumnDefs);

    if (updatedColumnDefs.length !== 0 && filtersObject?.length !== 0) {
      if (keys(panelObject)?.length !== 0) {
        // updatedColumnDefs.filter((column) => !!column?.children).length > 0
        //   ? (updatedColumnDefs.find(
        //       (column) =>
        //         !!column?.children &&
        //         column?.children?.find(
        //           ({ field }) => field === panelObject?.key
        //         )
        //     ).filter =
        //       panelObject?.key === "createdAt"
        //         ? "agDateColumnFilter"
        //         : "agTextColumnFilter")
        //   : ((
        //       updatedColumnDefs.find(
        //         ({ field }) => field === panelObject?.key
        //       ) || {}
        //     ).filter =
        //       panelObject?.key === "createdAt"
        //         ? "agDateColumnFilter"
        //         : "agTextColumnFilter");

        const filterType =
          panelObject?.key === "createdAt"
            ? "agDateColumnFilter"
            : "agTextColumnFilter";

        setNewColumnDefs(
          updatedColumnDefs?.filter((column) => !!column?.children)?.length > 0
            ? updatedColumnDefs?.map((column) => {
                const hasChildren = column?.children?.length > 0;
                const findedChildrenCol = column?.children?.find(
                  ({ field }) => field === panelObject?.key
                );
                return hasChildren && findedChildrenCol
                  ? { ...column, filter: filterType }
                  : column;
              })
            : updatedColumnDefs?.map((col) =>
                col?.field === panelObject?.key
                  ? { ...col, filter: filterType }
                  : col
              )
        );
      }
    } else if (filtersObject?.length === 0) {
      // setNewColumnDefs(updatedColumnDefs);
    }
    setNewColumnDefs(updatedColumnDefs);
  }, [
    columnDefs,
    currentDevice,
    currentDepartment,
    currentRoleByDepartment,
    title,
    deviceManagementConfigs,
  ]);

  let onClick = (e) => {
    if (Object.keys(statuses).length > 0) {
      gridRef.showLoadingOverlay();
      searchTableHandler(e, true);
      setClickedValue(e);
    } else {
      if (panelObject?.key === "createdAt") {
        setClickedValue(dateFilterOptions[e]);
        if (filtersObject?.length === 0 || filtersObject === undefined) {
          onDateFilter({
            gridApi,
            column: panelObject?.key,
            dateArray: dateFilterOptions[e],
          });
        }
      } else {
        setClickedValue(e);
        if (filtersObject?.length === 0 || filtersObject === undefined) {
          onFilter({
            gridApi,
            column: panelObject?.key,
            current: e,
            type: "equal",
          });
          setCustomFiltersData((prev) => ({
            ...prev,
            [panelObject?.key]: [e],
          }));
        }
      }
    }
  };

  useEffect(() => {
    setStatusPanel({ data, onClick });
  }, [programFields, JSON.stringify(panelFilter)]);

  const onRowDataUpdated = (e) => {
    let newList = [];
    e.api.forEachNode((node) => !!node.data && newList.push(node.data));
    newList = newList.filter(Boolean);
    setStatusPanel({
      data: statusPanelData?.map(({ statusName, statusColor }) => {
        return {
          statusName,
          statusColor,
          value: sumKey ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: 40 }}>
                {newList?.filter((e) => {
                  if (panelObject?.key === "createdAt") {
                    return (
                      parseInt(e[panelObject?.key]) >=
                        dateFilterOptions[statusName][0].valueOf() &&
                      parseInt(e[panelObject?.key]) <=
                        dateFilterOptions[statusName][1].valueOf()
                    );
                  } else if (title === "Vendors") {
                    return (
                      statusName?.toLowerCase() ===
                      e?.vendorStatus?.toLowerCase()
                    );
                  } else {
                    return (
                      e[panelObject?.key]?.toLowerCase() ===
                      statusName?.toLowerCase()
                    );
                  }
                })?.length || 0}
              </span>
              <span style={{ fontSize: 16 }}>
                {sumKey.type === "currency"
                  ? formatCurrency(
                      newList
                        ?.filter((e) => e[panelObject?.key] === statusName)
                        ?.reduce((acc, curr) => {
                          return (acc = acc + curr[sumKey.key] || 0);
                        }, 0) || 0
                    )
                  : newList
                      ?.filter((e) => e[panelObject?.key] === statusName)
                      ?.reduce((acc, curr) => {
                        return (acc = acc + curr[sumKey.key] || 0);
                      }, 0) || 0}
              </span>
            </div>
          ) : (
            newList?.filter((e) => {
              if (panelObject?.key === "createdAt") {
                return (
                  parseInt(e[panelObject?.key]) >=
                    dateFilterOptions[statusName][0].valueOf() &&
                  parseInt(e[panelObject?.key]) <=
                    dateFilterOptions[statusName][1].valueOf()
                );
              } else {
                if (typeof e?.[panelObject?.key] === "string") {
                  return !!panelFilter?.visible
                    ? e[panelObject?.key]?.toLowerCase() ===
                        statusName?.toLowerCase()
                    : e[panelObject?.key]?.toLowerCase() ===
                        statusName?.toLowerCase();
                } else {
                  return getStatus({ title, statusName, record: e });
                }
              }
            })?.length || 0
          ),
        };
      }),
      onClick,
    });
  };

  return (
    <LoadableComp loading={newColumnDefs === undefined || loadingColumnsConfig}>
      <Suspense fallback={<LoadableComp loading={true} />}>
        <DynamicBasePage
          key={newColumnDefs}
          {...{
            Conflicts,
            onRowDataUpdated,
            title,
            customSort,
            statusPanel,
            filtersObject,
            rowData,
            videoCategory,
            exportData,
            fleetName,
            driverName,
            formatCellValueHandler,
            suppressRowClickSelection,
            columnDefs: newColumnDefs,
            clickedValue,
            setClickedValue,
            panelObject,
            exportGridToExcel,
            getRowStyle,
            rowSelection,
            getSelected,
            agGridDefs,
            isTab,
            onRowClicked,
            defaultExcelExportParams,
            fetchAllLogsButton,
            onPaginationChanged,
            onRowDoubleClicked,
            customNew,
            hasFilter,
            hasNew,
            additionalStatusPanels,
            videoLinks,
            hasStatus,
            detailsCell,
            collapseKeys,
            getGridApi: (grid) => {
              getGridApiForParent(grid);
              setGridApi(grid);
              getGridApi(grid);
            },
            getGridRef,
            customstyle,
            filterContainClassName,
            onSelectionChangedOption: onSelectionChanged,
            optionButton,
            getFiltersData,
            paginationPageSize,
            changesModalVisible,
            setChangesModalVisible,
            setDaysOverview,
            setViewCrews,
            isEditable,
            customFooterData,
            customHeaderData,
            context,
            deleteSelectedRowsParams,
            suppressPaginationPanel,
            panelFilter,
            setPanelFilter,
            customStatusPanel,
            fullScreenOptions,
            hasLive,
            hasGalleryItems,
            setConflicts,
            setOpenProgressLogs,
            getChartingOptions,
            searchTableHandler,
            allowFetchSearch,
            filterSearchData,
            onDebounceChange,
            paginationAutoPageSize,
            reloadGrid,
            reloadButton,
            setRowData,
            customToggle,
            warningObj,
            rowId,
            hasFetchSearchFromDb,
            fetchAllHandler,
            nextStepHandler,
            handleSavedRecord,
            tooltipCategory,
          }}
        />
      </Suspense>
    </LoadableComp>
  );
};
export default BasePage;
