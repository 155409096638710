import { useContext, useEffect, useState } from "react";
import { PlaySquareFilled, RedoOutlined } from "@ant-design/icons";
import { VideoTutorialIcon } from "../../assets/svg";
import NewCategories from "../NewCategories/NewCategories";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import { message, Tooltip } from "antd";
import ResetModal from "../ResetModal/ResetModal";

const UnfinishedVideos = () => {
  const { playedVideos, setActivePrimaryCat, setSubCatVideos } =
    useContext(VideoTutorialContext);

  const [openUnfinishedVideos, setOpenUnfinishedVideos] = useState(false);
  const [videosToRemove, setVideosToRemove] = useState([]);
  const [showReset, setShowReset] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const areAllSelected = playedVideos.length === videosToRemove.length;

  useEffect(() => {
    playedVideos.length === 0 && setOpenUnfinishedVideos(false);
  }, [playedVideos]);

  const handleClick = () => {
    if (playedVideos.length === 0) return message.warning("No Video to show");

    setOpenUnfinishedVideos((prev) => !prev);
    setActivePrimaryCat();
    setSubCatVideos();
  };

  const handleRemove = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="unfinishedTutorialsCard-container">
      <div
        data-testid="unfinishedTutorialsCard"
        className={`unfinishedTutorialsCard ${
          openUnfinishedVideos ? "active" : ""
        }`}
        onMouseEnter={() => setShowReset(true)}
        onMouseLeave={() => setShowReset(false)}
        onClick={handleClick}
      >
        <PlaySquareFilled className="video-icon" />
        <p className="text">
          Continue Watching ({playedVideos.length}) tutorials
        </p>
        {!!showReset && playedVideos.length ? (
          <Tooltip title="Reset videos">
            <div
              className="delete-container"
              onClick={(e) => {
                e.stopPropagation();
                handleRemove();
              }}
            >
              <RedoOutlined
                className="delete-btn"
                style={{ fontSize: "20px", color: "#fe4c4a" }}
              />
            </div>
          </Tooltip>
        ) : null}
        <VideoTutorialIcon className="videoPlayerIcon" />
      </div>
      {openUnfinishedVideos && (
        <NewCategories newVideos={playedVideos} isUnfinishedVideo={true} />
      )}
      <ResetModal
        {...{
          setVideosToRemove,
          array: playedVideos,
          areAllSelected,
          videosToRemove,
          isModalOpen,
          setIsModalOpen,
          modalTitle: "Reset Continue Watching Tutorials",
          newOrFav: "playedVideos",
        }}
      />
    </div>
  );
};

export default UnfinishedVideos;
