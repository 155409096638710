import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

import styles from "./CollapsePanel.module.css";

const CollapsePanel = ({ children, header }) => {
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      className={styles.collapse}
    >
      <Collapse.Panel header={header} key="1">
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapsePanel;
