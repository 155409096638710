import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchData } from "../../../../../Fleet/utils";
import { Select, message } from "antd";
import { DropdownIcon } from "../../../../../BasePage/src";
import { getAccessRights } from "../../../../../../../utils";
import { AvatarImages, MondayButton } from "../../../../../../commonComponents";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";

export const TaskUsersInEdit = ({ updatedTask, setUpdatedTask }) => {
  const { taskTopic = "", taskAssignedTo = [] } = updatedTask;

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { userGroups } = useSelector((state) => state.userGroups);

  const [assignedToModal, setAssignedToModal] = useState(false);
  const [userDepartments, setUserDepartments] = useState([]); //save departments of progra
  const [selectedDepartment, setSelectedDepartment] = useState([]);

  const [assignedTo, setAssignedTo] = useState(taskAssignedTo || []);

  const usersWithAccess = userConfiguration?.allUsers?.Items?.filter((user) =>
    selectedDepartment?.includes(user.departmentName)
  ).filter(
    (user) =>
      (!user?.isSuspended &&
        getAccessRights({
          userConfiguration: user,
          title:
            taskTopic === "Schedule of values"
              ? "Schedule of Values/View"
              : taskTopic,
        }).routeConfig?.read === true) ||
      taskTopic?.toLowerCase() === "general"
  );

  useEffect(() => {
    !!assignedToModal &&
      setUserDepartments([
        ...new Set(userGroups.map((group) => group.departmentName)),
      ]);

    setSelectedDepartment([
      ...(new Set(
        taskAssignedTo?.map(
          (user) =>
            userConfiguration?.allUsers?.Items?.find(
              ({ cognitoUserId }) => cognitoUserId === user.cognitoUserId
            )?.departmentName
        )
      ) || []),
    ]);
  }, [assignedToModal, userGroups]);

  //Remove or add users for a task
  const changeAssignTo = () => {
    setAssignedToModal((prev) => !prev);
    setUpdatedTask({ ...updatedTask, taskAssignedTo: assignedTo });
  };

  return (
    <div className="task-users-edit-section">
      <div className="users-section">
        <div style={{ display: "flex", alignItems: "center" }}>
          <AvatarImages
            {...{
              maxCount: 6,
              cognitoUserIds: taskAssignedTo.map(
                ({ cognitoUserId }) => cognitoUserId
              ),
            }}
          />
        </div>
      </div>
      {!!userConfiguration?.tasksConfig?.canAssignToOthers && (
        <div className="detailsModal__collaborators">
          <h4>Assigned to:</h4>
          <h3
            style={{ cursor: "pointer", marginLeft: 10 }}
            onClick={() => {
              setAssignedToModal((prev) => !prev);
            }}
          >
            Add members
          </h3>
          {assignedToModal && (
            <div
              style={{
                flexDirection: "column",
                alignItems: "flex-start",
              }}
              className="detailsModal__collaborators__modal"
            >
              <div className="task-users">
                <span className="modal__multiSelect department-select">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    value={selectedDepartment}
                    placeholder="Select department"
                    onChange={(e) =>
                      e?.length === 0
                        ? message.error(
                            "You should select at least one department"
                          )
                        : setSelectedDepartment(e)
                    }
                    suffixIcon={<DropdownIcon />}
                    popupMatchSelectWidth={false}
                    options={userDepartments.map((dep) => ({
                      label: dep,
                      value: dep,
                    }))}
                  />
                </span>
              </div>

              {selectedDepartment.length > 0 && (
                <Select
                  mode="multiple"
                  value={assignedTo.map((user, i) => ({
                    key: i,
                    ...user,
                    label: user.nameOfUser,
                    value: user.nameOfUser,
                  }))}
                  onChange={(e, selectedUsers) => {
                    if (e?.length === 0) {
                      message.error("You should select at least one user");
                    } else {
                      const matchingUsers =
                        userConfiguration.allUsers.Items.filter(
                          ({ cognitoUserId }) =>
                            selectedUsers.some(
                              ({ cognitoId }) => cognitoId === cognitoUserId
                            )
                        );

                      setAssignedTo(
                        matchingUsers.map((user) => ({
                          cognitoUserId: user.cognitoUserId,
                          identityId: user.identityId,
                          nameOfUser: user.nameOfUser,
                          userName: user.userName,
                        }))
                      );
                    }
                  }}
                  suffixIcon={<DropdownIcon />}
                >
                  {usersWithAccess.map((value, i) => (
                    <Select.Option
                      key={i}
                      value={value.nameOfUser}
                      cognitoId={value.cognitoUserId}
                    >
                      {value.nameOfUser}
                    </Select.Option>
                  ))}
                </Select>
              )}
              <MondayButton Icon={<TickIcon />} onClick={changeAssignTo}>
                Done
              </MondayButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
