import { useState, useEffect, useMemo, useCallback } from "react";
import {
  Collapse,
  message,
  Modal,
  Tooltip,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import { EditIcon } from "../../DynamicView/src/index";
import { API } from "aws-amplify";
import { useRedux } from "../../hooks/useRedux";
import _ from "lodash";
import { forceToNumber } from "../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import useTakeOffAccessRights from "./tools/controllers/useTakeOffAccessRights";
import { CollapseIcon } from "../../../../icons";

const ServiceAddon = ({
  service,
  serviceIndex,
  pricingData,
  getAddonsCaption,
  handleChangePricingData,
  agGridTheme,
  // gridData,
  updateStateAndSave,
  unitMeasurement,
}) => {
  // const [rowData, setRowData] = useState(
  //   gridData[serviceIndex]?.serviceAddons || []
  // );
  const [isWritable = false] = useRedux("estimationsIsWritable");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState();
  const [selectedAddon, setSelectedAddon] = useState();
  // const [unitMeasurement, setUnitMeasurement] = useState();
  const [isReadOnly, setIsReadOnly] = useState([]);
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [customAddonTax, setCustomAddonTax] = useState(false);
  let customStyles;
  let serviceID = service.serviceId;
  // const rowData = gridData[serviceIndex]?.serviceAddons || [];
  const { canAddOrRemoveAddons = false, canViewPrice = false } =
    useTakeOffAccessRights("Take-Off");
  // Filter the pricing data to find addons associated with the service ID
  let filteredAddons =
    pricingData
      ?.find((item) => Number(item.serviceId) === Number(service.serviceId))
      ?.pricingObject?.addonsList.filter(
        (item) => item.addonType === "service addon"
      ) ?? [];

  const rowData = useMemo(() => {
    const temp = _.cloneDeep(gridData[serviceIndex]?.serviceAddons || []);
    if (Array.isArray(temp) && temp.length > 0) {
      console.log("tempRowData", temp);
    }

    return temp;
  }, [gridData, unitMeasurement]);

  console.log("rowData222", pricingData);

  const selectedOptions = useMemo(() => {
    const temp = rowData?.map((item) => item.name);
    console.log("selectedOptions", temp);
    return Array.isArray(temp) ? temp : [];
  }, [rowData]);

  // Dropdown options

  const options = useMemo(() => {
    const temp = filteredAddons
      // ?.filter((e) => !rowData?.some((s) => s.id === e.id))
      .map((item) => (
        <Select.Option key={item.id} value={item.name}>
          {item?.name}
        </Select.Option>
      ));
    return Array.isArray(temp) ? temp : [];
  }, [rowData, filteredAddons]);

  const saveDataInDB = async (temp) => {
    if (!isWritable) {
      message.error("Please turn on edit mode.");
      return;
    }
    const myData = _.cloneDeep(gridData);
    myData[serviceIndex].serviceAddons = Array.isArray(temp) ? temp : [];
    console.log("myData", myData);
    // setTakeOffTableData(myData);
    updateStateAndSave(myData);
    message.success("Service Addons Updated");
  };

  const handleSelectChange = async (item) => {
    if (!isWritable) {
      message.error("Please turn on edit mode.");
      return;
    }
    const temp = item
      ?.map((i) => {
        let tempRow = rowData?.find((e) => e.name === i);
        if (!!tempRow) {
          return tempRow;
        } else return filteredAddons?.find((row) => row?.name === i);
      })
      .filter((element) => element);
    console.log("Temp: ", temp);
    let savedData = _.cloneDeep(temp);
    applyFormulas(savedData);
    saveDataInDB(savedData);
  };

  // function to edit items of Card
  const handleEdit = (item) => {
    if (!isWritable) {
      message.error("Please turn on edit mode.");
      return;
    }
    setSelectedAddon(item);
    setModalDescription(item.description);
    setModalVisible(true);
  };

  const applyFormulas = (savedData) => {
    console.log("savedData222", savedData);
    if (Array.isArray(savedData)) {
      savedData?.forEach((i) => {
        let values = i?.values || [];
        console.log("values", savedData);
        if (Array.isArray(values)) {
          let hasTotalPrice = values.find(
            (item) => item?.name === "Total Price"
          );
          console.log("hasTotalPrice", hasTotalPrice, "values", values);
          if (!!hasTotalPrice) {
            let hasFormula = !!hasTotalPrice?.formula || false;
            if (hasFormula) {
              if (Array.isArray(hasTotalPrice?.formula)) {
                i.totalPrice =
                  applyFormulaFromArray(
                    hasTotalPrice?.formula,
                    values,
                    "totalPrice"
                  ) || 0;
                hasTotalPrice.defaultValue = i.totalPrice;
              } else {
                i.totalPrice =
                  getValueGivenStringFormula(hasTotalPrice?.formula, values) ||
                  0;
                hasTotalPrice.defaultValue = i.totalPrice;
              }
              // i.totalPrice = applyFormulaFromArray(
              //   hasTotalPrice?.formula,
              //   values,
              //   "totalPrice"
              // );
            } else {
              console.log("else has total", hasTotalPrice);
              i.totalPrice = forceToNumber(hasTotalPrice?.defaultValue) || 0;
            }
          }
        }
        if (
          service?.taxRate > 0 &&
          customAddonTax &&
          selectedAddon?.id === i?.id
        ) {
          let taxAmount = forceToNumber(i?.totalPrice * service?.taxRate);
          i.includedTax = {
            name: "Included Tax",
            taxRate: service?.taxRate,
            taxAmount: taxAmount,
            priceWithoutTax: i?.totalPrice,
          };
          i.totalPrice += forceToNumber(taxAmount || 0);
          // i.totalPrice +=.toFixed(2);
        } else {
          if (selectedAddon?.id === i?.id) {
            i.includedTax = {
              name: "Included Tax",
              taxRate: service?.taxRate,
              taxAmount: 0,
              priceWithoutTax: i?.totalPrice,
            };
          }
        }
        console.log("i.totalPrice", { iii: i?.totalPrice, service });
      });
    }
  };

  const handleModalOk = () => {
    if (!isWritable) {
      message.error("Please turn on edit mode.");
      return;
    }
    // If there is style on description remove it then update Pricing Data.
    const temp = _.cloneDeep(gridData);
    const savedData = _.cloneDeep(rowData);
    const toEdit = selectedAddon;
    let formatedDescription = modalDescription.replace(/style=\".*?\"/g, "");
    toEdit.description = formatedDescription;
    const idx = savedData.findIndex((item) => item.id === toEdit.id);
    savedData[idx] = toEdit;
    applyFormulas(savedData);
    // if (Array.isArray(savedData)) {
    //   savedData?.forEach((i) => {
    //     let values = i?.values || [];
    //     message.info(1);
    //     console.log("values", savedData);
    //     if (Array.isArray(values)) {
    //       let hasTotalPrice = values.find(
    //         (item) => item?.name === "Total Price"
    //       );
    //       console.log("hasTotalPrice", hasTotalPrice, "values", values);
    //       if (!!hasTotalPrice) {
    //         message.info(2);
    //         let hasFormula = !!hasTotalPrice?.formula || false;
    //         if (hasFormula) {
    //           i.totalPrice = getValueGivenStringFormula(
    //             hasTotalPrice?.formula,
    //             values
    //           );
    //         }
    //       }
    //     }
    //   });
    // }
    temp[serviceIndex].serviceAddons = savedData || [];
    console.log("this is what i am saving", { temp, savedData });
    saveDataInDB(savedData);
    // handleChangePricingData(serviceID, toEdit);
    // setModalVisible(false);
    // setSelectedAddon(null);
    // setRowData((prev) =>
    //   prev.map((item) => (item.id === selectedAddon.id ? selectedAddon : item))
    // );
    // setModalVisible(false);
  };

  const handleModalCancel = (e) => {
    setModalVisible(false);
  };

  const handleInputChange = (e, type) => {
    if (!isWritable) {
      message.error("Please turn on edit mode.");
      return;
    }
    // Check if input type is "number" and if the value is not a number or less than 0
    if (type === "number" && isNaN(e.target.value)) {
      message.error("The number must be greater equal or greater than 0");
      return;
    }

    // update the state with new value
    setSelectedAddon((prev) => ({
      ...prev,
      values: [
        ...prev.values.map((item) =>
          item.name !== e.target.name
            ? item
            : { ...item, defaultValue: e.target.value }
        ),
      ],
    }));
  };

  const handleDescriptionChange = (value) => {
    if (!isWritable) {
      message.error("Please turn on edit mode.");
      return;
    }
    setModalDescription(value);
    setSelectedAddon((prev) => ({
      ...prev,
      description: value,
    }));
  };

  const isResultFromFormula = (
    values,
    valueObj,
    unitMeasurement,
    textColor = false
  ) => {
    const exists = unitMeasurement?.find(
      ({ unitName = "" }) => unitName === valueObj.name
    );

    if (!exists) {
      return textColor ? "" : valueObj.name;
    }

    const op1 = values.find(({ name }) => name === exists.column1);
    const op2 = values.find(({ name }) => name === exists.column2);

    return textColor
      ? op1?.defaultValue && op2?.defaultValue
        ? "#7ABDFF"
        : ""
      : op1?.defaultValue && op2?.defaultValue
      ? exists.formula
      : valueObj.name;
  };

  const returnBaseOnTheme = (color1, color2) => {
    if (agGridTheme === "ag-theme-balham-dark") {
      return color1;
    } else {
      return color2;
    }
  };

  // useEffect(() => {
  //   API.get("unitMeasurement", "/unitMeasurement").then((res) =>
  //     setUnitMeasurement(
  //       res.filter(
  //         ({ typeOfWork, addonType }) =>
  //           Number(typeOfWork) === serviceID && addonType === "service addon"
  //       )
  //     )
  //   );
  // }, []);

  // useEffect(() => {
  //   setIsReadOnly([]);
  //   rowData?.map((item) => {
  //     item.values.map((value) => {
  //       getCarditem(
  //         value,
  //         item.name,
  //         item.values,
  //         unitMeasurement,
  //         true,
  //         item.id
  //       );
  //     });
  //   });
  // }, [rowData]);

  return (
    <div className="addonsContainer" style={{ height: "100%" }}>
      <Collapse
        expandIcon={({ isActive }) => (
          <div
            className="dropdownIconContainer"
            // onClick={!isActive ? handleCollapseChange : null}
            style={{
              // color: "#fff",
              // fill: "#fff",
              // backgroundColor: isActive
              //   ? "#5b5b5b"
              //   : "#1264A3",
              // background: "red",
              // width: "32px",
              // height: "32px",
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              // borderRadius: "50%",
            }}
          >
            {!isActive ? (
              <CollapseIcon
                style={{
                  transform: "rotate(-90deg)",
                }}
              />
            ) : (
              <CollapseIcon />
            )}
          </div>
        )}
      >
        <Collapse.Panel
          key={0}
          header={
            <div className="addonsHeader">
              {getAddonsCaption(
                rowData,
                isReadOnly?.filter((row) => row.name.toLowerCase() === "price")
              )}
            </div>
          }
        >
          <div className="dataEntryGrid" style={{ height: "100%" }}>
            {filteredAddons && options.length > 0 ? (
              <div
                className={`select-addons${
                  agGridTheme === "ag-theme-balham-dark" ? "-dark" : ""
                }`}
                style={{ padding: "20px", marginBottom: "10px" }}
              >
                <Select
                  disabled={!isWritable || !canAddOrRemoveAddons}
                  mode="multiple"
                  // options={options}
                  value={selectedOptions}
                  onChange={(item) => handleSelectChange(item)}
                  style={{
                    width: "100%",
                  }}
                  dropdownStyle={
                    agGridTheme === "ag-theme-balham-dark"
                      ? {
                          backgroundColor: "#1f202e",
                          color: "white",
                        }
                      : { color: "auto" }
                  }
                  popupClassName={`select-addons-dropdown${
                    agGridTheme === "ag-theme-balham-dark" ? "-dark" : ""
                  }`}
                >
                  {options}
                </Select>
                <div className={returnBaseOnTheme("body-dark", "body-bright")}>
                  {rowData?.map((item) => (
                    <div className="card-component">
                      <div className="card-header">
                        <h1 className="card-title">{item.name}</h1>
                        <EditIcon
                          className="edit-button"
                          disabled={!isWritable || !canAddOrRemoveAddons}
                          onClick={() => handleEdit(item)}
                        />
                      </div>
                      <div className="card-columns">
                        {item?.values
                          ?.filter((filterEl) => {
                            let cond =
                              filterEl?.name
                                ?.toLowerCase()
                                ?.includes("price") ||
                              filterEl?.name?.toLowerCase()?.includes("rent") ||
                              filterEl?.name?.toLowerCase()?.includes("rate") ||
                              filterEl?.name?.toLowerCase()?.includes("ppu") ||
                              filterEl?.name?.toLowerCase()?.includes("unit") ||
                              filterEl?.name?.toLowerCase()?.includes("tax");
                            console.log("filterEl", {
                              filterEl,
                              cond,
                              name: filterEl?.name,
                            });
                            return canViewPrice ? true : !cond;
                          })
                          ?.map(
                            (value, index) =>
                              value.name.toLowerCase() !== "name" && (
                                <p key={index} className="card-values">
                                  <Tooltip
                                    overlayInnerStyle={{
                                      color: returnBaseOnTheme(
                                        "#272727",
                                        "#fff"
                                      ),
                                    }}
                                    title={isResultFromFormula(
                                      item.values,
                                      value,
                                      unitMeasurement
                                    )}
                                    color={returnBaseOnTheme(
                                      "#F5F5F7",
                                      "#323338"
                                    )}
                                  >
                                    <span
                                      style={{
                                        color: isResultFromFormula(
                                          item.values,
                                          value,
                                          unitMeasurement,
                                          true
                                        ),
                                      }}
                                    >
                                      {value.name}
                                    </span>
                                    : {value?.defaultValue || ""}
                                    {/* {getCarditem(
                                    value,
                                    item.name,
                                    item.values,
                                    unitMeasurement
                                  )} */}
                                  </Tooltip>
                                </p>
                              )
                          )}{" "}
                        {canViewPrice && (
                          <p key={item?.name} className="card-values">
                            <Tooltip
                              overlayInnerStyle={{
                                color: returnBaseOnTheme("#272727", "#fff"),
                              }}
                              title={"Tax"}
                              color={returnBaseOnTheme("#F5F5F7", "#323338")}
                            >
                              <span
                              // style={{
                              //   color: isResultFromFormula(
                              //     item.values,
                              //     value,
                              //     unitMeasurement,
                              //     true
                              //   ),
                              // }}
                              >
                                Tax Amount
                              </span>
                              : {getTaxAmountCard(item, service)}
                              {/* {getCarditem(
                            value,
                            item.name,
                            item.values,
                            unitMeasurement
                          )} */}
                            </Tooltip>
                          </p>
                        )}
                      </div>
                      <div className="card-description">
                        Description:{" "}
                        <p className="description-text">
                          {item.description ? parse(item.description) : ""}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <h1 className="no-data">No data to show</h1>
            )}
            <Modal
              title={`Edit ${selectedAddon?.name}`}
              open={modalVisible}
              onOk={handleModalOk}
              onCancel={handleModalCancel}
              className={returnBaseOnTheme(
                "editModal-dark",
                "editModal-bright"
              )}
            >
              <div className="modal-container">
                {selectedAddon?.values
                  .filter((column) => {
                    return canViewPrice
                      ? column.name.toLowerCase() !== "name"
                      : !column.name.toLowerCase().includes("price") &&
                          !column.name.toLowerCase().includes("rent") &&
                          !column.name.toLowerCase().includes("rate") &&
                          !column.name.toLowerCase().includes("unit") &&
                          !column.name.toLowerCase().includes("tax") &&
                          column.name.toLowerCase() !== "name";
                  })
                  .map((item) => {
                    console.log("selectedAddon", { selectedAddon, item });
                    const isReadOnlyItem = isReadOnly?.find(
                      (value) =>
                        value.name === item.name &&
                        value.id === selectedAddon.id
                    );

                    let hasFormula = !!item?.formula;
                    let val = "";
                    if (hasFormula) {
                      if (Array.isArray(item?.formula)) {
                        val = applyFormulaFromArray(
                          item?.formula,
                          selectedAddon.values
                        );
                      } else {
                        val = getValueGivenStringFormula(
                          item.formula,
                          selectedAddon.values
                        );
                      }
                    }

                    return (
                      <>
                        <label>
                          {item.name}
                          {hasFormula ? (
                            <span style={{ color: "#71CF48" }}>
                              {` = ${
                                Array.isArray(item?.formula) &&
                                item?.formula.length > 0
                                  ? item?.formula.join(" ")
                                  : item?.formula
                              }`}
                            </span>
                          ) : (
                            ":"
                          )}
                        </label>
                        <input
                          name={item.name}
                          type={item.type === "number" ? "number" : "text"}
                          value={
                            hasFormula
                              ? val
                              : item.name.toLowerCase() === "name"
                              ? selectedAddon?.name
                              : isReadOnlyItem
                              ? isReadOnlyItem.value
                              : item.defaultValue || ""
                          }
                          disabled={!!item?.formula}
                          onChange={(e) => handleInputChange(e, item.type)}
                          readOnly={
                            item.name.toLowerCase() === "name" || isReadOnlyItem
                          }
                        />
                      </>
                    );
                  })}
                <ReactQuill
                  className={
                    agGridTheme === "ag-theme-balham-dark"
                      ? "reactQuillServiceAddon__dark"
                      : "reactQuillServiceAddon"
                  }
                  placeholder="Type here..."
                  theme="snow"
                  value={modalDescription}
                  onChange={handleDescriptionChange}
                />
                <br />
                <Switch
                  unCheckedChildren={
                    <div className="switchTittleContainerRentals">
                      No Custom Tax
                    </div>
                  }
                  checkedChildren={
                    <div className="switchTittleContainerRentals">Taxable</div>
                  }
                  style={{ width: "120px" }}
                  className="rentalsAutoSaveSwitchContainer"
                  onChange={(checked) => setCustomAddonTax(checked)}
                  checked={customAddonTax}
                />
              </div>
            </Modal>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default ServiceAddon;

export const getValueGivenStringFormula = (formula, temp) => {
  const valuesMap = {};
  const formulaString = structuredClone(formula);
  const data = structuredClone(temp);
  for (const { defaultValue = 0, name = "" } of data) {
    let value = defaultValue;

    valuesMap[name] = forceToNumber(value);
  }

  const formulaWithValues = formulaString.replace(
    /([a-zA-Z\s]+)/g,
    (match, p1) => {
      const value = valuesMap[p1.trim()];
      if (value === undefined) {
        return "";
        // throw new Error(`Variable ${p1} used in formula is not defined`);
      } else return value;
    }
  );
  console.log("formulaWithValues", { formulaWithValues, valuesMap, formula });
  try {
    const result = eval(formulaWithValues);
    console.log(forceToNumber(result).toFixed(2)); // Output: 39
    return result;
  } catch (e) {
    console.error(e.message);
    return 0;
  }
};

export const applyFormulaFromArray = (
  formulaInArr,
  objWithValues,
  keyToAssign
) => {
  console.log("test1", {
    formulaInArr,
    objWithValues,
    keyToAssign,
  });
  let formulaToEval = "";
  const objToExplore = structuredClone(objWithValues);
  if (Array.isArray(formulaInArr)) {
    formulaInArr?.forEach((item) => {
      if (item !== "+" && item !== "-" && item !== "*" && item !== "/") {
        const findItem = objToExplore?.find(
          (obj) => obj?.name?.toLowerCase() === item?.toLowerCase()
        );
        if (!!findItem && forceToNumber(findItem?.defaultValue || 0) !== 0) {
          formulaToEval += forceToNumber(findItem?.defaultValue || 0);
        } else {
          if (typeof parseFloat(item) === "number") formulaToEval += item;
        }
      } else {
        formulaToEval += item;
      }
    });
  }
  if (formulaToEval.length > 0) {
    try {
      let res = forceToNumber(eval(formulaToEval));
      return res;
    } catch (e) {
      const temp =
        objWithValues?.find(
          (el) => el.name.toLowerCase() === keyToAssign?.toLowerCase()
        )?.defaultValue || 0;
      return temp;
    }
    return res;
  } else {
    return 0;
  }
};

const getTaxAmountCard = (item, service) => {
  return forceToNumber(item?.includedTax?.taxAmount || 0).toFixed(2);
};
