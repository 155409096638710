import React, {
  useRef,
  useMemo,
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { Popover, message, Tooltip } from "antd";
import { HomeFilled, SearchOutlined } from "@ant-design/icons";

import {
  isYard,
  getFence,
  houseAssign,
  isEnteredAlert,
  FleetsLiveContext,
  findPaginationSize,
  loadLivePreference,
  saveLocalPreferences,
  findGeofenceCenterCoordinate,
  getTripsFromActualActivities,
} from "../../utils";
import { columnDefs, columnKeys, gridFilters } from "./data";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { RangePopover } from "./components";
import { MondayButton, WarningModal } from "src/components/commonComponents";
import PayrollLayout from "src/components/pages/Payroll/Layout/PayrollLayout";
import { ExcelIconWhite } from "src/assets";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { RadiusPicker } from "../LiveAuditView/components/AuditController/components";

import "../LiveLocationsView/LiveLocationsView.scss";

/**
 * @typedef LatLng
 * @property {number} lat
 * @property {number} lng
 */

/**
 * @typedef MatchDriver
 * @property {string} name
 * @property {string} [userId]
 * @property {string} [driverId]
 * @property {string} [employeeId]
 */

/**
 * @typedef ChangeLog
 * @property {string} value
 * @property {string} field
 * @property {string} [eventId]
 * @property {boolean} discarded
 * @property {number[]} [eventRange]
 */

/**
 * @typedef VehicleData
 * @property {number} date
 * @property {string} notes
 * @property {string} fleetId
 * @property {string} location
 * @property {number} [toTime]
 * @property {string} routeType
 * @property {string} fleetName
 * @property {number} [clockIn]
 * @property {string} [eventId]
 * @property {LatLng} [position]
 * @property {number} [clockOut]
 * @property {number} [fromTime]
 * @property {MatchDriver} driver
 * @property {string} [toLocation]
 * @property {boolean} fromDispatch
 * @property {string} [fromLocation]
 * @property {number[]} [eventRange]
 * @property {MatchDriver} assignedDriver
 * @property {"GEOFENCE ENTER"|"GEOFENCE EXIT"|"STOP"|"IDLE"} type
 */

const DATE_KEYS = ["Date", "From Time", "To Time", "Clock In", "Clock Out"];

function AfterHoursView() {
  //#region HOOKS
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const {
    fleet,
    drivers,
    allStops,
    geofences,
    shiftsData,
    afterHours,
    isWritable,
    globalAudits,
    selectedDate,
    changeMenuKey,
    broadcastUpdates,
    getAfterHoursData,
    actualTripsForDate,
    externalUpdateAudit,
    actualTripsForTruck,
    plannedTripsForDate,
    plannedTripsForTruck,
  } = useContext(FleetsLiveContext);

  const [gridApi, setGridApi] = useState();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [discardWarning, setDiscardWarning] = useState();
  const [assignAddressPopover, setAssignAddressPopover] = useState(false);
  const [selectedRange, setSelectedRange] = useState(
    /** @type {number[]} */ ([
      Number(loadLivePreference("afterHoursFromTime")) || 0,
      Number(loadLivePreference("afterHoursToTime")) || Infinity,
    ])
  );

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const gridContainerRef = useRef(null);

  const formattedData = useMemo(() => {
    //#region FORMATTED DATA
    /** @type {VehicleData[]} */
    let vehicleData = [];

    let truckTrips;
    let truckPlan;

    const selectedDateEnd = dayjsNY(selectedDate).endOf("day").valueOf();
    const selectedDateStart = dayjsNY(selectedDate).startOf("day").valueOf();

    if (selectedDateStart === dayjsNY().startOf("day").valueOf()) {
      truckTrips = actualTripsForTruck;
      truckPlan = plannedTripsForTruck;
    } else {
      truckTrips = actualTripsForDate;
      truckPlan = plannedTripsForDate;
    }

    for (const fleetId in truckTrips) {
      const vehicle = fleet.find(({ fleetId: id }) => id === fleetId);
      if (!vehicle) {
        continue;
      }

      let assignedDriver;
      let userObject;

      if (vehicle?.assignedDriver) {
        assignedDriver = drivers.find(
          ({ driverId }) => driverId === vehicle.assignedDriver
        );

        if (assignedDriver?.driverSub) {
          userObject = (userConfiguration?.allUsers?.Items || []).find(
            ({ cognitoUserId }) => cognitoUserId === assignedDriver?.driverSub
          );
        }
      }

      const fenceActivityForVehicle = getTripsFromActualActivities(
        truckTrips[fleetId]
      );

      const stopsForVehicle = (allStops?.[fleetId] || []).filter(
        ({ beginDate, endDate }) => {
          return selectedDateStart <= endDate && selectedDateEnd >= beginDate;
        }
      );

      /** @type {VehicleData[]} */
      const tmpData = [];
      const dateStart = loadLivePreference("liveSelectedDate");
      const dateEnd = dayjsNY(loadLivePreference("liveSelectedDate"))
        .endOf("day")
        .valueOf();

      for (const activity of fenceActivityForVehicle) {
        if (
          activity?.alertDateTime < dateStart ||
          activity?.alertDateTime > dateEnd
        ) {
          continue;
        }

        /** @type {ChangeLog[]} */
        const changesForRecord = (
          globalAudits?.["alertChanges"]?.[fleetId]?.["afterHoursChanges"] || []
        ).filter(({ eventId }) => eventId && eventId === activity?.alertUUID);

        if (changesForRecord?.find(({ discarded }) => discarded)) {
          continue;
        }

        const notes =
          changesForRecord.find(({ field }) => field === "notes")?.value || "";
        const routeType =
          changesForRecord.find(({ field }) => field === "routeType")?.value ||
          "";
        const driverId =
          changesForRecord.find(({ field }) => field === "driver")?.value || "";

        let driver = {
          userId: userObject?.cognitoUserId,
          driverId: assignedDriver?.driverId,
          employeeId: assignedDriver?.employeeId,
          name: userObject?.nameOfUser || assignedDriver?.driverName,
        };

        if (driverId) {
          const d = drivers?.find(({ driverId: id }) => id === driverId);
          driver["driverId"] = driverId;
          driver["name"] = d?.driverName;
          driver["employeeId"] = d?.employeeId;
          driver["userId"] = d?.driverSub;
        }

        const relatedFence = geofences?.[getFence(activity?.fenceName)];

        tmpData.push({
          notes,
          driver,
          fleetId,
          fromDispatch: false,
          location: activity.fenceName,
          fleetName: vehicle.fleetName,
          eventId: activity?.alertUUID,
          date: activity?.alertDateTime,
          routeType: routeType || "Other Location",
          deviceSerialNumber: vehicle?.deviceSerialNumber,
          position: relatedFence
            ? findGeofenceCenterCoordinate(relatedFence.points)
            : undefined,
          type: isEnteredAlert(activity.alertCode)
            ? "GEOFENCE ENTER"
            : "GEOFENCE EXIT",
          assignedDriver: {
            userId: userObject?.cognitoUserId,
            driverId: assignedDriver?.driverId,
            employeeId: assignedDriver?.employeeId,
            name: userObject?.nameOfUser || assignedDriver?.driverName,
          },
        });
      }

      for (const stop of stopsForVehicle) {
        if (!stop["address"]) {
          continue;
        }

        if (stop?.endDate < dateStart || stop?.beginDate > dateEnd) {
          continue;
        }

        /** @type {ChangeLog[]} */
        const changesForRecord = (
          globalAudits?.["alertChanges"]?.[fleetId]?.["afterHoursChanges"] || []
        ).filter(
          ({ eventRange }) =>
            eventRange?.length &&
            eventRange?.[0] === stop?.beginDate &&
            eventRange?.[1] === stop?.endDate
        );

        if (changesForRecord?.find(({ discarded }) => discarded)) {
          continue;
        }

        const notes =
          changesForRecord.find(({ field }) => field === "notes")?.value || "";
        const routeType =
          changesForRecord.find(({ field }) => field === "routeType")?.value ||
          "";
        const driverId =
          changesForRecord.find(({ field }) => field === "driver")?.value || "";

        let driver = {
          userId: userObject?.cognitoUserId,
          driverId: assignedDriver?.driverId,
          employeeId: assignedDriver?.employeeId,
          name: userObject?.nameOfUser || assignedDriver?.driverName,
        };

        if (driverId) {
          const d = drivers?.find(({ driverId: id }) => id === driverId);
          driver["driverId"] = driverId;
          driver["name"] = d?.driverName;
          driver["employeeId"] = d?.employeeId;
          driver["userId"] = d?.driverSub;
        }

        tmpData.push({
          notes,
          driver,
          fleetId,
          fromDispatch: false,
          date: stop?.beginDate,
          toTime: stop?.endDate,
          location: stop.address,
          position: stop?.position,
          fromTime: stop?.beginDate,
          fleetName: vehicle.fleetName,
          routeType: routeType || "Other Location",
          eventRange: [stop.beginDate, stop.endDate],
          deviceSerialNumber: vehicle?.deviceSerialNumber,
          assignedDriver: {
            userId: userObject?.cognitoUserId,
            driverId: assignedDriver?.driverId,
            employeeId: assignedDriver?.employeeId,
            name: userObject?.nameOfUser || assignedDriver?.driverName,
          },
          type: (stop?.stopType || "")?.toLowerCase()?.includes("idling")
            ? "IDLE"
            : "STOP",
        });
      }

      for (const alert of afterHours["data"]?.[fleetId]?.["alerts"] || []) {
        /** @type {ChangeLog[]} */
        const changesForRecord = (
          globalAudits?.["alertChanges"]?.[fleetId]?.["afterHoursChanges"] || []
        ).filter(({ eventId }) => eventId && eventId === alert?.alertUUID);

        if (changesForRecord?.find(({ discarded }) => discarded)) {
          continue;
        }

        const notes =
          changesForRecord.find(({ field }) => field === "notes")?.value || "";
        const routeType =
          changesForRecord.find(({ field }) => field === "routeType")?.value ||
          "";
        const driverId =
          changesForRecord.find(({ field }) => field === "driver")?.value || "";

        let driver = {
          userId: userObject?.cognitoUserId,
          driverId: assignedDriver?.driverId,
          employeeId: assignedDriver?.employeeId,
          name: userObject?.nameOfUser || assignedDriver?.driverName,
        };

        if (driverId) {
          const d = drivers?.find(({ driverId: id }) => id === driverId);
          driver["driverId"] = driverId;
          driver["name"] = d?.driverName;
          driver["employeeId"] = d?.employeeId;
          driver["userId"] = d?.driverSub;
        }

        const relatedFence = geofences?.[getFence(alert?.fenceName)];

        tmpData.push({
          notes,
          driver,
          fleetId,
          fromDispatch: false,
          location: alert.fenceName,
          fleetName: vehicle.fleetName,
          eventId: alert?.alertUUID,
          date: alert?.alertDateTime,
          routeType: routeType || "Other Location",
          deviceSerialNumber: vehicle?.deviceSerialNumber,
          position: relatedFence
            ? findGeofenceCenterCoordinate(relatedFence.points)
            : undefined,
          type: isEnteredAlert(alert.alertCode)
            ? "GEOFENCE ENTER"
            : "GEOFENCE EXIT",
          assignedDriver: {
            userId: userObject?.cognitoUserId,
            driverId: assignedDriver?.driverId,
            employeeId: assignedDriver?.employeeId,
            name: userObject?.nameOfUser || assignedDriver?.driverName,
          },
        });
      }

      for (const stop of afterHours["data"]?.[fleetId]?.["stops"] || []) {
        if (!stop["address"]) {
          continue;
        }

        /** @type {ChangeLog[]} */
        const changesForRecord = (
          globalAudits?.["alertChanges"]?.[fleetId]?.["afterHoursChanges"] || []
        ).filter(
          ({ eventRange }) =>
            eventRange?.length &&
            eventRange?.[0] === stop?.beginDate &&
            eventRange?.[1] === stop?.endDate
        );

        if (changesForRecord?.find(({ discarded }) => discarded)) {
          continue;
        }

        const notes =
          changesForRecord.find(({ field }) => field === "notes")?.value || "";
        const routeType =
          changesForRecord.find(({ field }) => field === "routeType")?.value ||
          "";
        const driverId =
          changesForRecord.find(({ field }) => field === "driver")?.value || "";

        let driver = {
          userId: userObject?.cognitoUserId,
          driverId: assignedDriver?.driverId,
          employeeId: assignedDriver?.employeeId,
          name: userObject?.nameOfUser || assignedDriver?.driverName,
        };

        if (driverId) {
          const d = drivers?.find(({ driverId: id }) => id === driverId);
          driver["driverId"] = driverId;
          driver["name"] = d?.driverName;
          driver["employeeId"] = d?.employeeId;
          driver["userId"] = d?.driverSub;
        }

        tmpData.push({
          notes,
          driver,
          fleetId,
          position: stop?.latitude
            ? {
                lat: stop?.latitude,
                lng: stop?.longitude,
              }
            : undefined,
          fromDispatch: false,
          date: stop?.beginDate,
          toTime: stop?.endDate,
          location: stop.address,
          fromTime: stop?.beginDate,
          fleetName: vehicle.fleetName,
          routeType: routeType || "Other Location",
          eventRange: [stop.beginDate, stop.endDate],
          deviceSerialNumber: vehicle?.deviceSerialNumber,
          assignedDriver: {
            userId: userObject?.cognitoUserId,
            driverId: assignedDriver?.driverId,
            employeeId: assignedDriver?.employeeId,
            name: userObject?.nameOfUser || assignedDriver?.driverName,
          },
          type: (stop?.stopType || "")?.toLowerCase()?.includes("idling")
            ? "IDLE"
            : "STOP",
        });
      }

      let driverObject = {};

      for (const activity of truckPlan?.[fleetId] || []) {
        const elementsToChange = tmpData.filter(
          ({ date }) =>
            date >=
              dayjsNY(
                activity?.actualDepart || activity?.startingTime
              ).valueOf() &&
            date <=
              dayjsNY(
                activity?.actualArrive || activity?.timeAtLocation
              ).valueOf()
        );

        if (driverObject?.driverId !== activity?.driverId) {
          driverObject = drivers?.find(
            ({ driverId }) => driverId === activity?.driverId
          );
        }

        elementsToChange.forEach((e) => {
          e.fromDispatch = true;
          e.fromLocation = activity.pickUpLocation;
          e.toLocation = activity.dropOffLocation;
          e.driver = {
            name: driverObject?.driverName,
            userId: driverObject?.driverSub,
            driverId: driverObject?.driverId,
            employeeId: driverObject?.employeeId,
          };

          if (activity?.scheduleId || activity?.projectId) {
            e.routeType = "Job";
          } else if (isYard(activity?.dropOffLocation)) {
            e.routeType = "Yard";
          }
        });
      }

      tmpData.sort((a, b) => a.date - b.date);
      vehicleData = vehicleData.concat(tmpData);
    }

    vehicleData.forEach((data) => {
      const { driver } = data;
      if (driver.employeeId) {
        const shiftForDriver = shiftsData?.[driver?.employeeId]?.[0];

        if (shiftForDriver) {
          data.clockIn = shiftForDriver.inTimestamp;
          data.clockOut = shiftForDriver.outTimestamp;
        }
      }
    });

    return vehicleData;
  }, [
    fleet,
    drivers,
    allStops,
    geofences,
    isWritable,
    shiftsData,
    afterHours,
    selectedDate,
    globalAudits,
    actualTripsForDate,
    plannedTripsForDate,
    actualTripsForTruck,
    plannedTripsForTruck,
  ]);

  const rowData = useMemo(() => {
    //#region ROW DATA
    if (!selectedRange.length) {
      return formattedData;
    }

    return formattedData.filter(
      ({ date }) =>
        (selectedRange[0] || 0) <= date &&
        date <= (selectedRange[1] || Infinity)
    );
  }, [formattedData, selectedRange]);

  const addressesField = useMemo(() => {
    //#region ADDRESSES FIELD
    if (!programFields) {
      return {
        fieldName: "Home Addresses",
        fieldId: null,
        fieldOptions: {},
      };
    }

    return programFields.find(
      ({ fieldName }) => fieldName === "Home Addresses"
    );
  }, [programFields]);

  useEffect(() => {
    //#region NON EDITABLE GRID
    if (gridApi) {
      setTimeout(() => {
        gridApi.redrawRows();
      }, 0);
    }
  }, [isWritable, gridApi]);

  const postChanges = useCallback(
    async (alertChangesBody) => {
      //#region POST CHANGES
      message.loading({
        content: "Saving changes...",
        key: "afterHoursSave",
        duration: 0,
      });

      let audit = undefined;
      if (!globalAudits?.auditId) {
        await API.post("fleetAudits", "/fleetAudits", {
          body: {
            auditDate: dayjsNY(selectedDate).startOf("day").format(),
            ...alertChangesBody,
          },
        }).then((res) => {
          audit = res;
        });
      } else {
        await API.put("fleetAudits", `/fleetAudits/${globalAudits?.auditId}`, {
          body: {
            ...alertChangesBody,
          },
        }).then(() => {
          audit = {
            ...globalAudits,
            ...alertChangesBody,
          };
        });
      }

      message.success({
        content: "Saved successfully",
        key: "afterHoursSave",
      });

      broadcastUpdates({ request: "audit-change", audit });
      externalUpdateAudit(audit);
    },
    [globalAudits, selectedDate]
  );

  const onCellValueChanged = useCallback(
    //#region OM CELL VALUE CHANGED
    async (event) => {
      if (event.source !== "edit") {
        return;
      }

      /** @type {VehicleData} */
      const data = event.data;

      const value = event.newValue;
      const oldValue = event.oldValue;
      const fleetId = data.fleetId;
      const field = event.column.colId;
      const isStopEvent = Boolean(data?.eventRange);

      let auditsForVehicle = globalAudits?.["alertChanges"]?.[fleetId];
      if (!auditsForVehicle) {
        auditsForVehicle = {
          changed: {},
          discarded: [],
          linkedStops: [],
          stopReasons: [],
          timeChanges: {},
          createdAlerts: {},
          discardedStops: [],
          idleViewChanges: [],
          afterHoursChanges: [],
          auditedActivities: [],
          auditStatus: "PENDING",
        };
      }

      /** @type {ChangeLog} */
      const thisChangeLog = {
        field,
        discarded: false,
        value: value?.driverId || value,
        eventId: !isStopEvent && data?.eventId,
        eventRange: isStopEvent && data?.eventRange,
      };

      /** @type {Array<ChangeLog>} */
      const existingChanges = auditsForVehicle?.["afterHoursChanges"] || [];

      let logIndex = -1;

      if (isStopEvent) {
        logIndex = existingChanges.findIndex(
          ({ eventRange, field: f }) =>
            eventRange?.length &&
            eventRange?.[0] === thisChangeLog["eventRange"]?.[0] &&
            eventRange?.[1] === thisChangeLog["eventRange"]?.[1] &&
            f === field
        );
      } else {
        logIndex = existingChanges.findIndex(
          ({ eventId, field: f }) =>
            eventId && eventId === thisChangeLog?.["eventId"] && f === field
        );
      }

      if (logIndex === -1) {
        existingChanges.splice(existingChanges.length, 0, thisChangeLog);
      } else {
        if (
          thisChangeLog.field === "routeType" &&
          thisChangeLog.value === "Other Location"
        ) {
          existingChanges.splice(logIndex, 1);
        } else {
          existingChanges.splice(logIndex, 1, thisChangeLog);
        }
      }

      auditsForVehicle["afterHoursChanges"] = [...existingChanges];

      const tmpNewAlertChanges = {
        alertChanges: {
          ...(globalAudits?.["alertChanges"] || {}),
          [fleetId]: auditsForVehicle,
        },
      };

      try {
        await postChanges(tmpNewAlertChanges);
      } catch (err) {
        message.error({
          content: "Something went wrong while saving changes",
          key: "afterHoursSave",
        });
        console.log("Error saving audit: ", err);
        event.node.updateData({ ...data, field: oldValue });
      }
    },
    [globalAudits, postChanges]
  );

  useEffect(() => {
    //#region GET AFTER HOUR DATA
    getAfterHoursData();
  }, [fleet, selectedDate, afterHours]);

  function onVehicleClick(data) {
    //#region ON VEHICLE CLICK
    saveLocalPreferences({
      auditExternalRedirect: {
        fleetId: data.fleetId,
        fleetName: data.fleetName,
      },
    });
    changeMenuKey("AUDIT");
  }

  /**
   * @param {VehicleData} data
   * @param {boolean} [confirmed=false]
   */
  async function onDiscard(data, confirmed = false) {
    //#region ON DISCARD
    if (!confirmed) {
      setDiscardWarning(data);
      return;
    }

    const fleetId = data.fleetId;
    const isStopEvent = Boolean(data?.eventRange);

    /** @type {ChangeLog} */
    const thisChangeLog = {
      field: null,
      value: null,
      discarded: true,
      eventId: !isStopEvent && data?.eventId,
      eventRange: isStopEvent && data?.eventRange,
    };

    let auditsForVehicle = globalAudits?.["alertChanges"]?.[fleetId];
    if (!auditsForVehicle) {
      auditsForVehicle = {
        changed: {},
        discarded: [],
        linkedStops: [],
        stopReasons: [],
        timeChanges: {},
        createdAlerts: {},
        discardedStops: [],
        idleViewChanges: [],
        afterHoursChanges: [],
        auditedActivities: [],
        auditStatus: "PENDING",
      };
    }

    const existingChanges = (
      auditsForVehicle["afterHoursChanges"] || []
    ).filter(({ eventId, eventRange }) => {
      if (isStopEvent) {
        return (
          eventRange?.[0] !== data?.eventRange?.[0] ||
          eventRange?.[1] !== data?.eventRange?.[1]
        );
      } else {
        return eventId !== data?.eventId;
      }
    });

    existingChanges.splice(existingChanges?.length, 0, thisChangeLog);

    auditsForVehicle["afterHoursChanges"] = [...existingChanges];

    const tmpNewAlertChanges = {
      alertChanges: {
        ...(globalAudits?.["alertChanges"] || {}),
        [fleetId]: auditsForVehicle,
      },
    };

    try {
      await postChanges(tmpNewAlertChanges);
    } catch (err) {
      message.error({
        content: "Something went wrong while saving changes",
        key: "afterHoursSave",
      });
      console.log("Error saving audit: ", err);
    }
  }

  function onRangeSelect(range) {
    //#region ON RANGE SELECT
    setSelectedRange(range);
    setPopoverOpen(false);
  }

  function excelExportHandler() {
    //#region EXCEL EXPORT
    if (!gridApi) {
      return;
    }

    gridApi.exportDataAsExcel({
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;

        if (DATE_KEYS.includes(headerName)) {
          if (value) {
            return dayjsNY(value).format("MMM DD, YYYY hh:mm a");
          }

          return "";
        } else if (headerName === "From Dispatch") {
          return value ? 1 : 0;
        } else if (headerName === "Driver") {
          return value?.name || "";
        }
        return value === undefined ? "" : `${value}`;
      },
    });
  }

  /**
   * Function that assigns events automatically to house address events
   * @param {Object} [values]
   * @param {string} values.searchRadius
   * @param {"foot"|"mile"} unit
   */
  async function onRadiusConfirm(values) {
    //#region ON RADIUS CONFIRM
    let distanceInFeet;
    if (values) {
      distanceInFeet = +values.searchRadius;
      if (values.unit === "mile") {
        distanceInFeet = +values.searchRadius * 5280;
      }
    }

    const newAudits = houseAssign({
      rowData,
      globalAudits,
      distanceInFeet,
      clearEntries: !values,
      addressesField: addressesField.fieldOptions,
    });

    try {
      await postChanges({ alertChanges: newAudits["alertChanges"] });
    } catch (err) {
      message.error({
        content: "Something went wrong while saving changes",
        key: "afterHoursSave",
      });
      console.log("Error assigning houses: ", err);
    }

    setAssignAddressPopover(false);
  }

  //#region JSX
  return (
    <Fragment>
      <div
        className={`live-locations-view ${
          isDarkMode ? "live-locations-dark" : ""
        }`}
        id="after-hour-grid"
        ref={gridContainerRef}
      >
        <div
          className={`main-payroll-view ${
            isDarkMode && "main-payroll-view-dark"
          }`}
        >
          <PayrollLayout
            {...{
              rowData,
              title: "",
              columnDefs,
              gridFilters,
              hasNew: false,
              rowHeight: 38,
              headerHeight: 40,
              onCellValueChanged,
              getGridApi: setGridApi,
              suppressCellFocus: true,
              suppressDoubleClickEdit: true,
              enterNavigatesVertically: false,
              paginationPageSize: findPaginationSize(gridContainerRef, 38),
              context: {
                onDiscard,
                isWritable,
                onVehicleClick,
              },
              additionalGridProps: {
                defaultExcelExportParams: {
                  columnKeys,
                  sheetName: "After Hours",
                  fileName: `After Hours Report - ${dayjsNY(
                    selectedDate
                  ).format("MM/DD/YYYY")}`,
                },
                excelStyles: [
                  {
                    id: "checkbox-font",
                    dataType: "Boolean",
                  },
                ],
              },
              additionalActionsButtons: [
                <Tooltip
                  title="Match with home addresses"
                  placement="bottom"
                  key="pickHousePopover"
                >
                  <Popover
                    trigger={"click"}
                    placement="bottom"
                    open={assignAddressPopover}
                    content={<RadiusPicker onConfirm={onRadiusConfirm} />}
                    overlayClassName={`stop-modal-popover-content${
                      isDarkMode ? " popover-dark" : ""
                    }`}
                  >
                    <MondayButton
                      Icon={<HomeFilled />}
                      className="mondayButtonBlue"
                      onClick={() => {
                        setAssignAddressPopover(!assignAddressPopover);
                        if (popoverOpen) {
                          setPopoverOpen(false);
                        }
                      }}
                    >
                      {null}
                    </MondayButton>
                  </Popover>
                </Tooltip>,
                <Tooltip
                  title="Datetime filter"
                  key="pickRangePopover"
                  placement="bottom"
                >
                  <Popover
                    trigger={"click"}
                    placement="bottom"
                    open={popoverOpen}
                    content={<RangePopover onRangeSelect={onRangeSelect} />}
                    overlayClassName={`stop-modal-popover-content${
                      isDarkMode ? " popover-dark" : ""
                    }`}
                  >
                    <MondayButton
                      Icon={<SearchOutlined />}
                      className="mondayButtonBlue"
                      onClick={() => {
                        setPopoverOpen(!popoverOpen);
                        if (assignAddressPopover) {
                          setAssignAddressPopover(false);
                        }
                      }}
                    >
                      {null}
                    </MondayButton>
                  </Popover>
                </Tooltip>,
                <Tooltip
                  title="Excel Export"
                  placement="bottom"
                  key="excelExport"
                >
                  <MondayButton
                    Icon={<ExcelIconWhite />}
                    className="mondayButtonBlue"
                    onClick={() => {
                      excelExportHandler();
                    }}
                  >
                    {null}
                  </MondayButton>
                </Tooltip>,
              ],
            }}
          />
        </div>
      </div>
      {discardWarning && (
        <WarningModal
          visible={!!discardWarning}
          setVisible={setDiscardWarning}
          darkMode={isDarkMode}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body" style={{ textAlign: "center" }}>
            <span>
              <WarningTriangle />
            </span>
            <p>Are you sure you want to discard this activity?</p>
            <div className="buttons">
              <MondayButton
                Icon={<XIcon />}
                className="mondayButtonRed"
                onClick={() => {
                  setDiscardWarning();
                }}
              >
                No
              </MondayButton>
              <MondayButton
                Icon={<TickIcon />}
                onClick={() => {
                  onDiscard(discardWarning, true);
                  setDiscardWarning(false);
                }}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </Fragment>
  );
}

export default AfterHoursView;
