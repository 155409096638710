import { uniqBy } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { unstable_batchedUpdates } from "react-dom";

import {
  EntryType,
  ScheduleType,
  CrewTeamType,
  ExcelDataType,
  EmployeeReportType,
} from "../payrollLiveTypes";
import { getJobDistance } from "./getJobDistance";
import { coordinatesMatch } from "./coordinatesMatch";
import { matchSchedulesWithExcelData } from "./matchSchedulesWithExcelData";
import {
  JobsiteType,
  GeofenceType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { withinRadius } from "../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { findDistanceFromGeofenceSide } from "../../Payroll/Tabs/DEG/components/modalComponents/utils";

type Props = {
  jobsites: Array<JobsiteType>;
  crewTeams: Array<CrewTeamType>;
  todaySchedules: Array<ScheduleType>;
  defaultExcelData: Array<ExcelDataType>;
  employeesReport: Array<EmployeeReportType>;
  setDegEntries: Dispatch<SetStateAction<Array<EntryType>>>;
  setSchedules: Dispatch<SetStateAction<Array<ScheduleType>>>;
  setEmployeesReport: Dispatch<SetStateAction<Array<EmployeeReportType>>>;
};

export function updateSourceData({
  jobsites,
  crewTeams,
  setSchedules,
  setDegEntries,
  todaySchedules,
  employeesReport,
  defaultExcelData,
  setEmployeesReport,
}: Props): void {
  matchSchedulesWithExcelData(jobsites, todaySchedules, defaultExcelData).then(
    ({ jobsitesIncluded, schedulesIncluded }) => {
      const { jobsitesMatch, matchedEmployees } = coordinatesMatch({
        crewTeams,
        jobs: jobsitesIncluded,
        employees: employeesReport,
        schedules: uniqBy(todaySchedules, (e) => e?.projectId),
      });

      const projectsIncluded = uniqBy(
        jobsitesMatch,
        (job: JobsiteType) => job?.projectId
      );

      const schedulesIncludedd = todaySchedules.map((sch) => sch.projectId);
      unstable_batchedUpdates(() => {
        const newSchedulesList = todaySchedules.concat(
          projectsIncluded.filter(
            (jb: ScheduleType) => !schedulesIncludedd.includes(jb.projectId)
          ) as Array<ScheduleType>
        );
        setSchedules(newSchedulesList);

        setEmployeesReport(
          employeesReport.map((emp) => {
            if (emp?.liveStatus === "No Punch") {
              return emp;
            }
            const empIndex = matchedEmployees.findIndex(
              (el) => el?.employeeNumber == emp?.employeeNumber
            );
            const scheduleMatch = todaySchedules.findIndex(
              (el) => el?.projectId === matchedEmployees?.[empIndex]?.projectId
            );

            let closestJob;
            for (let j = 0; j < newSchedulesList.length; j++) {
              const job = newSchedulesList[j];
              const geoFence =
                (job as unknown as JobsiteType)?.geofenceInfo?.[0]
                  ?.geoFenceInfo ||
                job?.geoFenceInfo?.[0]?.geoFenceInfo ||
                job?.geoFenceInfo;

              const range = getJobDistance({
                point: emp?.punchCoordinates,
                jobCoordinates: job.addressPosition,
                geoFence: geoFence as GeofenceType[],
              });

              if ((closestJob?.distance || 99999) > range?.distanceInFeet) {
                closestJob = {
                  ...job,
                  distance: range?.distanceInFeet,
                };
              }
            }

            if (empIndex > -1) {
              if (scheduleMatch > -1) {
                if (
                  matchedEmployees?.[empIndex]?.distance <= 300 &&
                  closestJob?.distance > matchedEmployees?.[empIndex]?.distance
                ) {
                  return matchedEmployees[empIndex];
                } else if (
                  matchedEmployees?.[empIndex]?.distance >= 300 &&
                  closestJob?.distance <
                    matchedEmployees?.[empIndex]?.distance &&
                  closestJob?.distance <= 400
                ) {
                  return {
                    ...matchedEmployees[empIndex],
                    distance: closestJob?.distance,
                    jobsiteId: closestJob?.jobsiteId,
                    projectId: closestJob?.projectId,
                    color:
                      emp?.liveStatus === "In" ||
                      emp?.liveStatus === "Unscheduled"
                        ? "#7FB5B5"
                        : null,
                    liveStatus:
                      emp?.liveStatus === "In" ||
                      emp?.liveStatus === "Don't Match"
                        ? "Unscheduled"
                        : matchedEmployees[empIndex]?.liveStatus,
                  };
                } else {
                  return matchedEmployees[empIndex];
                }
              } else {
                if (
                  closestJob?.projectId ===
                  matchedEmployees?.[empIndex]?.projectId
                ) {
                  return {
                    ...matchedEmployees?.[empIndex],
                    jobsiteId: closestJob?.jobsiteId,
                    color:
                      closestJob?.distance > 300 && emp?.liveStatus !== "Out"
                        ? "#e9c466"
                        : null,
                    liveStatus:
                      closestJob?.distance > 300 && emp?.liveStatus !== "Out"
                        ? "Don't Match"
                        : emp?.liveStatus,
                  };
                } else {
                  if (
                    matchedEmployees?.[empIndex]?.distance <= 300 &&
                    closestJob?.distance >
                      matchedEmployees?.[empIndex]?.distance
                  ) {
                    return matchedEmployees[empIndex];
                  } else if (
                    matchedEmployees?.[empIndex]?.distance >= 300 &&
                    closestJob?.distance <
                      matchedEmployees?.[empIndex]?.distance &&
                    closestJob?.distance <= 400
                  ) {
                    return {
                      ...matchedEmployees[empIndex],
                      distance: closestJob?.distance,
                      jobsiteId: closestJob?.jobsiteId,
                      projectId: closestJob?.projectId,
                      color:
                        emp?.liveStatus === "In" ||
                        emp?.liveStatus === "Unscheduled"
                          ? "#7FB5B5"
                          : null,
                      liveStatus:
                        emp?.liveStatus === "In" ||
                        emp?.liveStatus === "Don't Match"
                          ? "Unscheduled"
                          : matchedEmployees[empIndex]?.liveStatus,
                    };
                  } else {
                    return matchedEmployees[empIndex];
                  }
                }
              }
            } else {
              let foreman = false;
              let teamIndex = crewTeams.findIndex((el) => {
                const isForeman =
                  el?.crewForeman?.employeeId === emp?.employeeId;

                const isMember =
                  el.crewMembers.findIndex(
                    (mem) => mem?.employeeId === emp?.employeeId
                  ) > -1;

                if (isForeman) {
                  foreman = isForeman;
                }
                return isForeman || isMember;
              });

              const inTodaysSchedule = schedulesIncluded.some(
                (sch) =>
                  sch?.projectId === closestJob?.projectId &&
                  (sch?.employeesAssigned || []).findIndex(
                    (el) => el?.["ID Number"] === Number(emp?.employeeNumber)
                  ) > -1
              );

              const inTodaysJobsites = jobsitesIncluded.some(
                (job) =>
                  job?.projectId === closestJob?.projectId &&
                  (job?.employeesAssigned || []).findIndex(
                    (el) => el?.["ID Number"] === Number(emp?.employeeNumber)
                  ) > -1
              );

              const dontMatchCondition =
                (emp?.liveStatus !== "Out" && closestJob?.distance > 300) ||
                !closestJob?.distance;

              if (inTodaysSchedule || inTodaysJobsites) {
                return {
                  ...emp,
                  isForeman: foreman,
                  distance: closestJob?.distance,
                  jobsiteId: closestJob?.jobsiteId,
                  projectId: closestJob?.projectId,
                  crewTeamId: crewTeams?.[teamIndex]?.crewTeamId,
                  crewTeamName:
                    crewTeams?.[teamIndex]?.crewTeamName ||
                    emp?.crewTeamName ||
                    "No Team",
                  color: dontMatchCondition ? "#e9c466" : null,
                  liveStatus: dontMatchCondition
                    ? "Don't Match"
                    : emp?.liveStatus,
                };
              } else {
                return {
                  ...emp,
                  isForeman: foreman,
                  distance: closestJob?.distance,
                  jobsiteId: closestJob?.jobsiteId,
                  projectId: closestJob?.projectId,
                  crewTeamId: crewTeams?.[teamIndex]?.crewTeamId,
                  crewTeamName:
                    crewTeams?.[teamIndex]?.crewTeamName ||
                    emp?.crewTeamName ||
                    "No Team",
                  color: dontMatchCondition
                    ? "#e9c466"
                    : closestJob?.distance < 300
                    ? "#7FB5B5"
                    : null,
                  liveStatus: dontMatchCondition
                    ? "Don't Match"
                    : closestJob?.distance < 300
                    ? "Unscheduled"
                    : emp?.liveStatus,
                };
              }
            }
          })
        );

        setDegEntries((prev) => {
          let newState: Array<EntryType> = [];
          prev.forEach((el) => {
            const employeeNumber = Number(
              (el?.employeeId || "").split("-")?.[1]
            );
            const empIndex = matchedEmployees.findIndex(
              (emp) => employeeNumber === Number(emp?.employeeNumber)
            );
            const jobIndex = jobsites.findIndex(
              (el) => el?.projectId === matchedEmployees?.[empIndex]?.projectId
            );

            const jobMatch = jobsites?.[jobIndex];

            if (jobMatch) {
              let distance;
              if (
                jobMatch?.geoFenceInfo?.length ||
                jobMatch?.geofenceInfo?.length
              ) {
                const geoInfo = jobMatch?.geoFenceInfo || [
                  { geoFenceInfo: jobMatch?.geofenceInfo },
                ];
                distance = findDistanceFromGeofenceSide({
                  geofence: geoInfo[0]?.geoFenceInfo,
                  point: el?.punchCoordinates,
                  tolerance: 300,
                })?.distanceInFeet;
              } else {
                distance = withinRadius(
                  jobMatch.addressPosition,
                  el.punchCoordinates
                )?.distanceInFeet;
              }

              const duration = Math.round(distance / 4.7);
              const updatedEntry = {
                ...el,
                jobsiteId: jobMatch?.jobsiteId,
                distanceFromJob: distance || 0,
                duration,
                activityStatus: (distance > 300 || !distance
                  ? "Pending"
                  : "Completed") as "Completed" | "Draft" | "Pending",
                jobsiteMatch: {
                  jobName: jobMatch?.jobName,
                  services: jobMatch?.services,
                  jobsiteId: jobMatch?.jobsiteId,
                  jobAddress: jobMatch.jobAddress,
                  reimbursement: jobMatch?.reimbursement,
                },
              };

              newState.push(updatedEntry);
            } else {
              newState.push(el);
            }
          });

          return newState;
        });
      });
    }
  );
}
