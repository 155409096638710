import { useState } from "react";
import { Popover } from "antd";
import { useSelector } from "react-redux";
import { MoreOutlined } from "@ant-design/icons";
import { useResponsive } from "../../../hooks";
import "./ResponsiveHeaderButtons.scss";

const ResponsiveHeaderButtons = ({ headerButtons }) => {
  const [open, setOpen] = useState(false);

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { mobile } = useResponsive();

  const content = () => (
    <div className="mobile-header-buttons">{headerButtons(setOpen)}</div>
  );

  return (
    <>
      {!mobile ? (
        headerButtons()
      ) : (
        <Popover
          content={content}
          trigger="click"
          open={open}
          onOpenChange={(newOpen) => setOpen(newOpen)}
          overlayInnerStyle={{
            backgroundColor: isDarkMode ? "#2a2b3a" : "white",
            color: isDarkMode ? "#fff" : "#000",
          }}
        >
          <MoreOutlined
            style={{
              fontSize: "24px",
              fontWeight: "700",
              color: isDarkMode ? "#fff" : "black",
            }}
          />
        </Popover>
      )}
    </>
  );
};

export default ResponsiveHeaderButtons;
