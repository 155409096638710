//@ts-check
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import { API } from "aws-amplify";

import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../utils";
import { NewIcon } from "../../../BasePage/src";
import { fetchData } from "../../../Fleet/utils";
import { bodyObject, fieldsJson } from "./utils/addItemHelpers";
import Button from "../../../../commonComponents/Buttons/Button";
import { useInitialBillContext } from "../context/InitialBillProvider";
import { RenderDynamicComponents } from "../../../../Header/forms/Components";
import { BorderedTextCard, NormalSizedModal } from "../../../Fleet/components";
import { footerButtons } from "../../components/NewVendorModal/utils/footerData";

import "./AddItem.scss";
import { addBillCategory } from "../../../../pages/Settings/settingsComponents/BillsConfiguration/BillItems/utils/addBillCategory";

const FIELD_NAME = "Bill Categories";

const AddItemModal = ({ visible, setVisible }) => {
  const { programFields = [] } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();
  const [services, setServices] = useState([]);
  const [name, setName] = useState("");
  const inputRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!!services.length)
      fetchData("serviceDefinitions").then((data) => setServices(data));
  }, []);

  const { setBillItems } = useInitialBillContext();

  const billCategoriesOptions =
    programFields
      .find((prog) => prog.fieldName === FIELD_NAME)
      ?.fieldOptions.map((item) => ({
        label: item.name,
        value: item.name,
      })) || [];

  const billCategories = programFields.find(
    (prog) => prog.fieldName === FIELD_NAME
  )?.fieldOptions;

  const onSave = async () => {
    try {
      await form.validateFields();
      showLoadingMsg({ content: `Saving item. Please wait.` });
      setIsSaving(true);

      const item = bodyObject({ form });
      await API.post("billItems", "/billItems", {
        body: item,
      }).then((result) => {
        setBillItems((prev) => [...prev, result]);
        showSuccessMsg({ content: `Item saved successfully` });
        setVisible(false);
      });
      setIsSaving(false);
    } catch (error) {
      showErrorMsg({
        content: "All required fields must be filled in before submitting.",
      });
    }
  };

  const onCancel = () => setVisible(false);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  // Add new Bill Category
  const addItem = async (e) => {
    e.preventDefault();

    await addBillCategory({
      billCategories,
      categories: billCategoriesOptions.map((item) => item.value),
      name,
      setName,
      userConfiguration,
      dispatch,
    });
  };

  const inputFields = fieldsJson(
    billCategoriesOptions,
    services,
    inputRef,
    name,
    onNameChange,
    addItem
  );

  return (
    <NormalSizedModal
      visible={visible}
      onCancel={onCancel}
      title="Add New Item"
      newFooterDesign={footerButtons({ onSave, onCancel, isSaving })}
      centered
    >
      <Form form={form}>
        <BorderedTextCard
          className="new-add-item-container"
          childrenClassName="new-add-item-text-card"
          title="Item Information"
        >
          {RenderDynamicComponents(inputFields)}
        </BorderedTextCard>
      </Form>
    </NormalSizedModal>
  );
};

const AddItem = () => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        className="add-new-bill-item-button"
        data-testid="add-new-bill-item"
      >
        <NewIcon /> Add item
      </Button>

      {visible && <AddItemModal {...{ visible, setVisible }} />}
    </>
  );
};

export default AddItem;
