import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { Modal, Slider, Form, message } from "antd";
import { useContext, useMemo, useRef, useState } from "react";

import { formatDurationDeg } from "../utils";
import DegModalContext from "../DegModalContext";
import {
  XIcon,
  TrashIcon,
} from "../../../../../../../SidebarPages/Communication/assets";
import { useEntriesApi } from "../../../../../../PayrollLive/utils";
import { MondayButton } from "../../../../../../../commonComponents";
import { EyeIcon } from "../../../../../../../SidebarPages/DynamicView/src";
import { TickIcon } from "../../../../../../Settings/settingsComponents/Roles/src";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { withinRadius } from "../../../../Activity/components/payrollActivityModalData";

import "./RadiusToleranceModal.scss";

/**
 * @typedef JobsiteMatch
 * @property {string} jobName
 * @property {string} jobsiteId
 * @property {string} jobAddress
 * @property {boolean} reimbursement
 * @property {string} googleSheetLink
 * @property {Array<string>} services
 */

/**
 * @typedef Entry
 * @property {string} degId
 * @property {string} crewId
 * @property {string} company
 * @property {string} entryId
 * @property {Date} punchDate
 * @property {Date} punchTime
 * @property {number} duration
 * @property {number} totalOvh
 * @property {string} uploadId
 * @property {number} createdAt
 * @property {string} jobsiteId
 * @property {string} employeeId
 * @property {string} uploadName
 * @property {string} payrollType
 * @property {number} employeeRate
 * @property {string} punchLocation
 * @property {number} punchTimeStamp
 * @property {number} distanceFromJob
 * @property {string} employeeFullName
 * @property {JobsiteMatch} jobsiteMatch
 * @property {Array<string>} serviceOptions
 * @property {"Hourly" | "Salary"} salaryType
 * @property {{lat: number, lng: number}} punchCoordinates
 * @property {"ID" | "OD" | "IL" | "OL" | "HR"} punchType
 * @property {"Draft" | "Pending" | "Completed"} activityStatus
 */

const defaultColumnDefs = {
  flex: 1,
  filter: true,
  sortable: true,
  editable: false,
  resizable: true,
  enablePivot: true,
  enableRowGroup: true,
  enableColResize: true,
};

const RadiusToleranceModal = ({
  open,
  onCancel,
  setRowData,
  setEditEntryData,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    form,
    jobsites,
    degGridApi,
    //  addEntryAction, addActionToRegister
    addActionToRegister,
  } = useContext(DegModalContext);

  const [jobsitesFound, setJobsitesFound] = useState([]);
  const [editedEntries, setEditedEntries] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [showJobsites, setShowJobsites] = useState(false);

  const inputRef = useRef(null);

  const { updateEntries, loading } = useEntriesApi();

  const maxRadius = Form.useWatch("maxRadius", form);
  const radiusRange = Form.useWatch("radiusRange", form);
  const isRangeSlider = Form.useWatch("isRangeSlider", form);

  /**
   * Finds the closest jobsite with entry punchCoordinates
   * @param {Entry} data
   */
  function getClosest(data) {
    const position = data.punchCoordinates;

    if (!position?.lat || !position?.lng) {
      return;
    }

    let closestJob = undefined;

    for (let i = 0; i < jobsites.length; i++) {
      const job = jobsites[i];

      let jobWithin;
      if (isRangeSlider) {
        const startWithin = withinRadius(
          job.addressPosition,
          position,
          radiusRange[0]
        );
        const endWithin = withinRadius(
          job?.addressPosition,
          position,
          radiusRange[1]
        );
        jobWithin = {
          distanceInFeet: endWithin.distanceInFeet,
          withinRange:
            startWithin.distanceInFeet >= radiusRange[0] &&
            endWithin.withinRange,
        };
      } else {
        jobWithin = withinRadius(job.addressPosition, position, radiusRange);
      }

      const isInsideToleratedRange = jobWithin?.withinRange;

      const distanceIsSmallerThanPreviousFind =
        !!jobWithin?.distanceInFeet &&
        jobWithin?.distanceInFeet < (closestJob?.distance || Infinity);

      if (isInsideToleratedRange && distanceIsSmallerThanPreviousFind) {
        closestJob = { ...job, distance: jobWithin?.distanceInFeet };
      }
    }

    return closestJob;
  }

  function updateJobsiteMatching() {
    /**
     * @type {Array<Entry>}
     */
    let updatedMatchingRows = [];
    let editActions = [];

    degGridApi.forEachNodeAfterFilter(({ data }) => {
      if (!data?.jobsiteId) {
        const jobMatch = getClosest(data);

        if (jobMatch?.jobsiteId) {
          const searchRadius = Array.isArray(radiusRange)
            ? Number(radiusRange[1])
            : Number(radiusRange);

          const updatedRow = {
            ...data,
            searchRadius,
            jobsiteId: jobMatch?.jobsiteId || "",
            distanceFromJob: jobMatch?.distance || 0,
            duration: !!jobMatch?.distance ? jobMatch?.distance / 3.5 : 0,
            jobsiteMatch: {
              jobName: jobMatch?.jobName || "",
              services: jobMatch?.services || [],
              jobsiteId: jobMatch?.jobsiteId || "",
              jobAddress: jobMatch?.jobAddress || "",
              reimbursement: !!jobMatch?.reimbursement,
              googleSheetLink: jobMatch?.googleSheetLink || "",
            },
          };
          updatedMatchingRows.push(updatedRow);
          editActions.push({
            prev: data,
            type: "edit",
            curr: updatedRow,
          });
        }
      }
    });

    if (updatedMatchingRows.length) {
      setEditedEntries(editActions);
      setJobsitesFound(updatedMatchingRows);
    }
  }

  function onSaveChanges() {
    message.loading({
      duration: 0,
      key: "onJobMatchSave",
      content: "Loading...",
    });
    if (jobsitesFound.length) {
      updateEntries({
        entries: jobsitesFound,
        onSuccessCallback: () => {
          message.success({
            duration: 3,
            key: "onJobMatchSave",
            content: "Entries jobs updated successfully",
          });
          setRowData((prev) => {
            return prev.map((pv) => {
              let foundIndex = jobsitesFound.findIndex(
                (el) => el?.entryId === pv?.entryId
              );
              if (foundIndex > -1) {
                return jobsitesFound[foundIndex];
              } else {
                return pv;
              }
            });
          });
          addActionToRegister({ type: "edit", editActions: editedEntries });
          onCancel();
        },
        onErrorCallback: (err) => {
          console.log("err: ", err);
          message.error({
            duration: 3,
            key: "onJobMatchSave",
            content: "There was a problem updating entries.",
          });
        },
      });

      // addEntryAction({ type: "edit", entry: jobsitesFound });
    } else {
      message.warning({
        duration: 3,
        key: "onJobMatchSave",
        content: "There are not jobsite matching for the selected radius.",
      });
    }
  }

  const rangeDisplay = useMemo(() => {
    if (Array.isArray(radiusRange)) {
      const [rangeStart, rangeEnd] = radiusRange;
      const start =
        rangeStart >= 5280
          ? parseFloat(rangeStart / 5280).toFixed(2) + " miles"
          : rangeStart + " ft";
      const end =
        rangeEnd >= 5280
          ? parseFloat(rangeEnd / 5280).toFixed(2) + " miles"
          : rangeEnd + " ft";
      return (
        <div style={{ display: "flex", gap: 20 }}>
          <span className="radius-range">{start}</span>
          <span className="radius-range">{end}</span>
        </div>
      );
    } else {
      const range =
        radiusRange >= 5280
          ? parseFloat(radiusRange / 5280).toFixed(2) + " miles"
          : radiusRange + " ft";
      return <div className="radius-range">{range}</div>;
    }
  }, [radiusRange]);

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: "Employee Full Name",
        field: "employeeFullName",
        minWidth: 220,
      },
      {
        headerName: "Employee Id",
        field: "employeeId",
        minWidth: 220,
      },
      {
        headerName: "Jobsite Match",
        field: "jobsiteMatch",
        minWidth: 300,
        cellRenderer: ({ value }) => {
          return value?.jobAddress;
        },
      },
      {
        headerName: "Distance",
        field: "distanceFromJob",
        cellRenderer: ({ value }) => {
          return value >= 5280
            ? `${parseFloat(value / 5280).toFixed(2)} miles`
            : (!!value ? value : "0") + ` ft`;
        },
      },
      {
        headerName: "Duration",
        field: "duration",
        cellRenderer: ({ value }) => {
          return formatDurationDeg(value, "seconds").text;
        },
      },
      {
        headerName: "Status",
        field: "activityStatus",
      },
      {
        headerName: "Punch Type",
        field: "punchType",
      },
      {
        headerName: "Remove",
        cellRenderer: ({ data }) => {
          const entryId = data.entryId;
          return (
            <MondayButton
              className="mondayButtonRed"
              Icon={<TrashIcon />}
              onClick={() =>
                setJobsitesFound((prev) =>
                  prev.filter((el) => el.entryId !== entryId)
                )
              }
            >
              {""}
            </MondayButton>
          );
        },
      },
      {
        headerName: "Show",
        cellRenderer: ({ data }) => {
          return (
            <MondayButton
              className="mondayButtonBlue"
              Icon={<EyeIcon />}
              onClick={() => setEditEntryData(data)}
            >
              {""}
            </MondayButton>
          );
        },
      },
    ];
  }, []);

  return (
    <Modal
      open={open}
      destroyOnClose
      centered={true}
      closable={false}
      title={`RadiusToleranceModal`}
      className={`radius-tolerance-modal ${
        isDarkMode ? "radius-tolerance-modal-dark" : ""
      }`}
      footer={[
        <MondayButton
          Icon={<XIcon />}
          disabled={loading}
          onClick={onCancel}
          className="mondayButtonRed"
        >
          Cancel
        </MondayButton>,
        <MondayButton
          disabled={loading}
          onClick={onSaveChanges}
          Icon={<TickIcon width={17} height={17} />}
        >
          Save Changes
        </MondayButton>,
      ]}
    >
      <Form
        form={form}
        initialValues={{
          maxRadius: 1000,
          radiusRange: 300,
        }}
      >
        <Form.Item name="radiusRange" className="slider">
          {isRangeSlider ? (
            <Slider
              min={0}
              max={maxRadius}
              defaultValue={[10, 300]}
              range={{ draggableTrack: true }}
            />
          ) : (
            <Slider min={300} max={maxRadius} defaultValue={300} />
          )}
        </Form.Item>
        <div className="marks">
          <div className="min-range">{isRangeSlider ? "0" : "300"} ft</div>

          {rangeDisplay}

          <div style={!inputVisible ? { display: "none" } : {}}>
            <InputComponent
              min={300}
              type="number"
              suffix={"ft"}
              controls={false}
              typeNumber={true}
              inputRef={inputRef}
              formItemName={"maxRadius"}
              onPressEnter={() => setInputVisible(false)}
              onDoubleClick={() => setInputVisible(false)}
            />
          </div>
          <div
            className="max-range"
            onDoubleClick={() => {
              setInputVisible(true);
              setTimeout(() => {
                inputRef?.current?.focus();
              }, 0);
            }}
            style={inputVisible ? { display: "none" } : {}}
          >
            {maxRadius} ft
          </div>
        </div>
        <div className="form-footer">
          <InputComponent
            type="checkbox"
            label={"Range"}
            formItemName={"isRangeSlider"}
            onChange={(e) => {
              if (e.target.checked) {
                form.setFieldValue("radiusRange", [0, radiusRange]);
              } else {
                form.setFieldValue("radiusRange", radiusRange[1]);
              }
            }}
          />
          <MondayButton
            className="mondayButtonBlue"
            onClick={updateJobsiteMatching}
            Icon={<TickIcon width={17} height={17} />}
          >
            Apply Radius
          </MondayButton>
          {jobsitesFound?.length ? (
            <span
              className="job-found"
              onClick={() => setShowJobsites((prev) => !prev)}
            >
              Jobsites Found {jobsitesFound?.length}
            </span>
          ) : null}
        </div>
      </Form>
      <div
        className={`jobsites-found-grid ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
        style={jobsitesFound?.length && showJobsites ? {} : { display: "none" }}
      >
        <AgGridReact
          pagination={true}
          paginationPageSize={9}
          rowData={jobsitesFound}
          columnDefs={columnDefs}
          defaultColDef={defaultColumnDefs}
        />
      </div>
    </Modal>
  );
};

export default RadiusToleranceModal;
