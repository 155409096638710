import { Button, Collapse } from "antd";
import React from "react";
import { PlusIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { Plus } from "../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons";
import "./MissingDaysView.scss";
import { CollapseArrow } from "../../../../../../assets";
import dayjs from "dayjs";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { PlusOneIcon } from "../../../../../../icons";
import { PlusOutlined } from "@ant-design/icons";
/**
 * Between days, if there are dates available for creation, here will be some shortcuts to create them
 * */
export const MissingDatesView = ({ missingDaysDates, dayIndex, onAddDay }) => {
  const defaultActiveKey = missingDaysDates?.length < 4 ? ["1"] : [];

  return (
    <div className="collapse-of-missing-days">
      <Collapse
        defaultActiveKey={defaultActiveKey}
        className="missing-days-collapse"
        ghost
        expandIcon={({ isActive }) => (
          <CollapseArrow
            style={{
              transform: isActive ? "rotate(0deg)" : "rotate(-90deg)",
            }}
            height={12}
            width={12}
          />
        )}
      >
        <Collapse.Panel
          header={`${missingDaysDates?.length}  ${
            missingDaysDates?.length === 1 ? "day" : "days"
          } can be added`}
          key="1"
        >
          <div className="missingDays-container-panel">
            {missingDaysDates?.map((d) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "0.5rem",
                    borderRadius: 5,
                    color: "#ffffff",
                  }}
                  onClick={(e) => {
                    onAddDay(
                      dayIndex,
                      dayjsNY(d?.suggestedStartDate)
                        .set("hour", 7)
                        .set("minute", 0),
                      dayjsNY(d?.suggestedEndDate)
                        .set("hour", 16)
                        .set("minute", 0)
                    );
                  }}
                  className={
                    "add-day-div " + (d?.weekend === true ? "weekend" : "")
                  }
                >
                  <Button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: d?.weekend
                        ? "#f8a09f"
                        : "#b1e29e 0% 0% no-repeat padding-box",
                      color: "#ffffff",
                      width: 32,
                      height: 32,
                      border: "none",
                    }}
                  >
                    <PlusOutlined />
                  </Button>
                  {d?.text}
                </div>
              );
            })}
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};
