import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../../DEG/components/modalComponents/utils/cellFunctions";

export const shiftFields = (data, isIncludeAmountChecked) => [
  { name: "Employee Name", value: data?.employeeFullName },
  { name: "Employee Id", value: data?.employeeId },
  { name: "Employee Role", value: data?.employeeRole },
  {
    name: "Rate",
    value: isIncludeAmountChecked
      ? `$${data?.employeeRate?.toFixed(2)}`
      : "******",
  },
  { name: "Shift Type", value: data?.shiftType },
  {
    name: "Shift Date",
    value: dayjsNY(data?.punchDate).format(DEG_DATE_FORMAT),
  },
  { name: "Jobsite", value: data?.jobsiteAddress },
  { name: "Scope Of Work", value: data?.sow?.join(", ") },
  {
    name: "Shift Start",
    value: dayjsNY(data?.firstClockIn).format(DEG_TIME_FORMAT),
  },
  {
    name: "Shift End",
    value: dayjsNY(data?.clockOut).format(DEG_TIME_FORMAT),
  },
  {
    name: "Break Start",
    value: dayjsNY(data?.lunchStart).format(DEG_TIME_FORMAT),
  },
  {
    name: "Break End",
    value: dayjsNY(
      data?.lunchStart + (data?.workHours + data?.overtimeHours) * 36000
    ).format(DEG_TIME_FORMAT),
  },
  {
    name: "Work Hours",
    value: `${(data?.workHours + data?.overtimeHours)?.toFixed(2)} hr`,
  },
  {
    name: "Regular Amount",
    value: isIncludeAmountChecked
      ? `$${data?.regAmount?.toFixed(2)}`
      : "******",
  },
  { name: "Overtime Hours", value: `${data?.overtimeHours?.toFixed(2)} hr` },
  {
    name: "Overtime Amount",
    value: isIncludeAmountChecked ? `$${data?.otAmount?.toFixed(2)}` : "******",
  },
  {
    name: "Total Work Hours",
    value: `${(data?.workHours + data?.overtimeHours)?.toFixed(2)} hr`,
  },
  {
    name: "Total Amount",
    value: isIncludeAmountChecked ? `$${data?.total?.toFixed(2)}` : "******",
  },
];
