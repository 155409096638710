import { Table, Collapse } from "antd";
import React from "react";
import { currencyFormatter } from "../../../../../../../../utils/currencyFormatter";
import { forceToNumber } from "../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

function HoistAddons({ elevation, service, darkMode = false }) {
  console.log("elevation", { service });

  return (
    <div>
      <Collapse>
        <Collapse.Panel
          header={
            <span
              style={{
                color: "#F40009",
              }}
            >
              Be mindful, there are extra costs associated with this hoist which
              can be seen upon expanding this section.
            </span>
          }
          key="1"
        >
          <Table
            columns={columns}
            dataSource={getData(service)}
            bordered
            pagination={{ hideOnSinglePage: true }}
          />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default HoistAddons;

const columns = [
  {
    title: "No.",
    dataIndex: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
  },

  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Total Price",
    className: "column-money",
    dataIndex: "money",
    align: "right",
  },
];

const getData = (service) => {
  return service?.serviceAddons?.map((addon, idx) => {
    return {
      id: idx + 1,
      name: addon?.name || "N/A",
      description: addon?.addonDescription,
      money: currencyFormatter(forceToNumber(addon?.totalPrice)),
    };
  });
};
