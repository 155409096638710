import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "src/components/pages/Settings/settingsComponents/AllUsers/components/UsernameRenderer/UsernameRenderer.scss";
import { driveApi } from "../../../../../integrations/DriveRequest";
import { SuspendedUserIcon } from "../../../../../assets";
import { Avatar } from "antd";
import { UserSessionModal } from "../../../../pages/Settings/settingsComponents/AllUsers/components";
import { loginStatusColors } from "../../../../pages/Settings/settingsComponents/AllUsers/allUsersData";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const avatarStyle = {
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  fontWeight: 600,
  fontSize: "12px",
};

const SessionUserListItem = ({ data = {}, value, className = "" }) => {
  const {
    nameOfUser,
    googleDriveFileId,
    isSuspended,
    onlineStatus,
    lastSeen,
    idleStatus,
    departmentName,
  } = data;

  const [userSessionModalVisible, setUserSessionModalVisible] = useState(false);
  const [currentFile, setCurrentFile] = useState({});

  const { preferences } = useSelector((state) => state.preferences);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { getImageSrc } = driveApi({ accessToken });

  const statusColor = isSuspended
    ? loginStatusColors.suspended
    : idleStatus?.isIdle
    ? loginStatusColors.idle
    : !!onlineStatus
    ? loginStatusColors.loggedIn
    : loginStatusColors.loggedOut;

  const statusName = isSuspended ? (
    <span className="suspended-user-status">
      Suspended <SuspendedUserIcon fill="#F04F4E" />
    </span>
  ) : idleStatus?.isIdle ? (
    "Idle"
  ) : onlineStatus ? (
    "Online"
  ) : (
    "Offline"
  );

  useEffect(() => {
    //setCurrentFile only if we have googleDriveFileId
    if (googleDriveFileId && accessToken)
      getImageSrc(googleDriveFileId).then((image) =>
        setCurrentFile({ ...image })
      );
    else setCurrentFile(null);
  }, [googleDriveFileId, accessToken]);

  function getInitials(nameOfUser) {
    return (
      nameOfUser
        ?.split(" ")
        ?.map((n) => n?.[0])
        ?.join("")
        ?.toUpperCase() || "AA"
    );
  }

  return (
    <>
      <div
        onClick={() => {
          setUserSessionModalVisible(true);
        }}
        className={`usernameRenderer ${className}`}
      >
        <span className="usernameRendererLeft">
          <Avatar
            shape="circle"
            src={currentFile?.src}
            gap={4}
            style={{
              backgroundColor: `${
                userConfiguration?.nameOfUser === nameOfUser
                  ? preferences.preferences?.profileColor || "#4895ef"
                  : isDarkMode
                  ? "#2a2b3a"
                  : "#F8F8FA"
              }`,
              color: isDarkMode ? "#fff" : "#323338",
              ...avatarStyle,
            }}
          >
            {getInitials(nameOfUser)}
          </Avatar>
          <div className="statusDot" style={{ backgroundColor: statusColor }} />
          <div className="username">
            {value}
            {statusName !== "Online" &&
              statusName !== "Idle" &&
              lastSeen !== "now" &&
              !isSuspended && (
                <span
                  style={{ marginLeft: 10, fontSize: 12, color: "#acacac" }}
                >
                  ({`${!!lastSeen ? dayjs(lastSeen).fromNow() : "Never"}`})
                </span>
              )}
            {statusName === "Idle" && idleStatus?.isIdle && (
              <span
                style={{
                  marginLeft: 10,
                  fontSize: 12,
                  color: "#acacac",
                }}
              >
                {!!idleStatus?.idleTime
                  ? dayjs(idleStatus?.idleTime).fromNow()
                  : null}
              </span>
            )}
          </div>
        </span>
        <span className="username-groupname">{departmentName}</span>
        <span className="username-groupname">
          {!!lastSeen
            ? lastSeen === "now" || statusName === "Online"
              ? "Online now"
              : dayjs(lastSeen).format("MM/DD/YYYY - hh:mm A")
            : ""}
        </span>
        <div
          className="user-status"
          style={{
            color:
              statusName === "Offline" || statusName === "Idle"
                ? isDarkMode
                  ? "#acacca"
                  : "#323338"
                : statusColor,
          }}
        >
          {statusName}
        </div>
      </div>
      {!!userSessionModalVisible && (
        <UserSessionModal
          {...{
            visible: userSessionModalVisible,
            setVisible: setUserSessionModalVisible,
            user: data,
            ws: true,
          }}
        />
      )}
    </>
  );
};

export default SessionUserListItem;
