import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";

import {
  getLiveActivity,
  FleetsLiveContext,
  findPaginationSize,
  loadLivePreference,
} from "../../utils";
import { columnDefs, gridFilters, columnKeys } from "./gridData";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { MondayButton } from "src/components/commonComponents";
import PayrollLayout from "../../../../../pages/Payroll/Layout/PayrollLayout";
import { ExcelIconWhite } from "src/assets";

import "./LiveLocationsView.scss";

function LiveLocationsView() {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    geofences,
    locations,
    globalAudits,
    auditsForDate,
    actualTripsForTruck,
    plannedTripsForTruck,
  } = useContext(FleetsLiveContext);

  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState();

  useEffect(() => {
    let tmp = [];
    for (const vehicle of locations) {
      let todayFormat = dayjsNY(
        loadLivePreference("liveSelectedDate")
      ).format();

      let auditToConsider = {};
      if (globalAudits?.auditDate === todayFormat) {
        auditToConsider = structuredClone(
          globalAudits?.alertChanges?.[vehicle?.fleetId]
        );
      } else {
        auditToConsider = structuredClone(
          auditsForDate?.[todayFormat]?.alertChanges?.[vehicle?.fleetId] || {}
        );
      }

      tmp.push(
        getLiveActivity({
          geofences,
          planForTruck: plannedTripsForTruck?.[vehicle?.fleetId] || [],
          actualActivities: actualTripsForTruck?.[vehicle?.fleetId] || {},
          auditsForTruck: auditToConsider,
        })
      );
    }
    setRowData(tmp?.filter((e) => Object.keys(e).length));
  }, [
    geofences,
    locations,
    globalAudits,
    auditsForDate,
    actualTripsForTruck,
    plannedTripsForTruck,
  ]);

  function excelExportHandler() {
    if (!gridApi) {
      return;
    }

    gridApi.exportDataAsExcel({
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;

        if (headerName.includes("Actual") || headerName.includes("Planned")) {
          return dayjsNY(value).format("MMM DD, YYYY hh:mm a");
        }

        return value === undefined ? "" : `${value}`;
      },
    });
  }

  return (
    <div
      className={`live-locations-view ${
        isDarkMode ? "live-locations-dark" : ""
      }`}
      id="main-live-view"
    >
      <div
        className={`main-payroll-view ${
          isDarkMode && "main-payroll-view-dark"
        }`}
      >
        <PayrollLayout
          {...{
            rowData,
            title: "",
            columnDefs,
            gridFilters,
            rowHeight: 38,
            hasNew: false,
            headerHeight: 40,
            suppressDoubleClickEdit: true,
            paginationPageSize: findPaginationSize("main-live-view", 38),
            getGridApi: setGridApi,
            additionalGridProps: {
              defaultExcelExportParams: {
                columnKeys,
                sheetName: "Live Locations",
                fileName: `Live Locations Report - ${dayjsNY().format(
                  "MM/DD/YYYY"
                )}`,
              },
            },
            additionalActionsButtons: [
              <Tooltip
                title="Excel Export"
                placement="bottom"
                key="excelExport"
              >
                <MondayButton
                  Icon={<ExcelIconWhite />}
                  className="mondayButtonBlue"
                  onClick={() => {
                    excelExportHandler();
                  }}
                >
                  {null}
                </MondayButton>
              </Tooltip>,
            ],
          }}
        />
      </div>
    </div>
  );
}

export default LiveLocationsView;
