import { Form, Select, Tooltip, Tour, message } from "antd";
import {
  BorderedTextCard,
  InputComponent,
  NormalSizedModal,
} from "../../../SidebarPages/Fleet/components";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";

import { RenderDynamicComponents } from "../Components";
import "./HearingsModal.scss";
import {
  MondayButton,
  ProgressComponent,
  RedWarningModal,
  Stepper,
} from "../../../commonComponents";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import { fetchData, filterTables, lazyFetch } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { PlusIcon } from "../../../../assets";
import { DocUploader } from "../../../SidebarPages/Documentation/View/components/DocumentationModal/components";
import { driveApi } from "../../../../integrations/DriveRequest";
import {
  useEditLogs,
  useProgressComponent,
  useUploadedFiles,
} from "../../../../hooks";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import { filterSchedulesOnDate } from "../../../SidebarPages/Safety/SafetyModal/utils/filterSchedulesOnDate";
import { getNewLabelHTML } from "../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/routeFunctions";
import CustomModalHeader from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import DynamicTeamModal from "../DynamicTeamModal/DynamicTeamModal";
import ReactQuillRichText from "../../../commonComponents/ReactQuillRichText/ReactQuillRichText";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Avatars } from "../../../commonComponents/DynamicAvatars/Avatars";
import {
  hearingDynamicInputFields,
  hearingFooterButtons,
  hearingGuideHelper,
  saveHearingFunction,
} from "./helpers";
import { DynamicAvatars } from "../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { SmallLoadableComp } from "../../../Sidebars/components";
import { SettingIcon } from "../../subcomponents";
import { TransferIconNew } from "../../../SidebarPages/DynamicView/src";
import SortFields from "../../../pages/Settings/settingsComponents/SafetyDynamicForms/SortFields/SortFields";
import { saveSortedFields } from "../../../SidebarPages/Safety/SafetyModal/helpers";
import { cloneDeep } from "lodash";
import { getSubcontractors } from "../../../SidebarPages/Subcontractors/SubcontractorsBasePage";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function HearingsModal({
  visible,
  setVisible,
  category,
  selectedClaim,
  isNextStep,
  setPreModalVisible,
}) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
    hideProgress,
  } = useProgressComponent({ categoryName: "Hearings", actionType: "Create" });

  const [changes, setChanges] = useState(false);
  const [selectedClaimData, setSelectedClaimData] = useState({});
  const [allHearings, setAllHearings] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [hearingStatus, setHearingStatus] = useState("Draft");
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [divClickable, setDivClickable] = useState(true);
  const [nameDisabled, setNameDisabled] = useState(false);

  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;

  const [projects, setProjects] = useState([]);
  const [schedulesForDate, setSchedulesForDate] = useState([]);
  const [addressSelectionInfo, setAddressSelectionInfo] = useState({});

  const [mentions, setMentions] = useState([]);
  const [mentionIds, setMentionIds] = useState([]);
  const [notes, setNotes] = useState("");
  const [assignedTo, setAssignedTo] = useState([]);

  const { setFieldsValue, getFieldsValue, resetFields, getFieldError } = form;

  const { preferences } = useSelector((state) => state.preferences);
  const { loggedOnTasks } = useSelector((state) => state.loggedOnTasks);
  const { programFields } = useSelector((state) => state.programFields);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { saveAddedLogs } = useEditLogs();

  const [tourOpen, setTourOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [allTeams, setAllTeam] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newSortFields, setNewSortFields] = useState([]);
  const [openSort, setOpenSort] = useState(false);
  const [projectExecutives, setProjectExecutives] = useState([]);
  const [subcontractors, setSubcontractors] = useState([]);
  const [crews, setCrews] = useState([]);

  const employerField = Form.useWatch("employer", form);
  const hearingAddressField = Form.useWatch("hearingAddress", form);

  const driveFunctions = useCreateDriveFolders("Hearings");

  const driveRequest = driveApi({ accessToken });

  //avoid me as user & get users with access to Notes
  const usersWithAccess = useMemo(
    () =>
      userConfiguration?.allUsers?.Items?.filter((person) => {
        return person.cognitoUserId !== userConfiguration.cognitoUserId;
      })
        .filter(
          (person) =>
            !!person.routeConfig?.find(({ title, write }) => {
              return title === "Hearings" && write === true;
            })
        )
        .map((user, index) => ({
          id: user.cognitoUserId || user.identityId,
          value: user.nameOfUser,
          key: index,
        })),
    [userConfiguration]
  );

  const dynamicFields = useMemo(() => {
    let hearingsFields = [];
    if (newSortFields.length > 0) {
      return newSortFields;
    } else {
      const fieldsIndx = programFields?.findIndex(
        (el) => el.fieldName === "Safety Dynamic Fields"
      );
      if (fieldsIndx > -1) {
        hearingsFields = cloneDeep(
          programFields?.[fieldsIndx]?.fieldOptions?.Hearings || []
        );
      }
      return hearingsFields;
    }
  }, [programFields, newSortFields]);

  const modalTitle = useMemo(() => {
    if (
      !!selectedClaim?.type &&
      selectedClaim?.type !== "Worker Compensation"
    ) {
      return "Deposition";
    } else {
      return "Hearing";
    }
  }, [selectedClaim?.type]);

  const onLocationChange = useCallback(
    (val, option) => {
      let selectedAddress = val?.trim();
      let type = option?.type;

      if (type === "schedule") {
        let selectedSchedule = schedulesForDate?.find(
          (el) => el?.scheduleAddress === selectedAddress
        );

        if (!!selectedSchedule) {
          setAddressSelectionInfo({
            scheduleId: selectedSchedule?.scheduleId,
            projectId: "",
            accountId: "",
          });
        }
      } else if (type === "project") {
        let selectedProject = projects?.find(
          (el) => el?.projectName === selectedAddress
        );
        form.setFieldValue(
          "projectExecutive",
          selectedProject?.projectExecutive
        );
        form.setFieldValue(
          "clientAtTimeOfAccident",
          selectedProject?.accountName
        );

        if (!!selectedProject) {
          setAddressSelectionInfo({
            projectId: selectedProject?.projectId,
            accountId: selectedProject?.accountId,
            scheduleId: "",
          });
        }
      } else {
        setAddressSelectionInfo({
          projectId: "",
          scheduleId: "",
          accountId: "",
        });
      }
    },
    [schedulesForDate, projects]
  );

  const onTeamSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data?.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onTeamDeselect = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeam((prev) => prev?.filter((el) => el.value !== teamName));
    if (form.getFieldValue("hearingTeam").length < 1) {
      setSelectedTeam([]);
    }
  };

  const openHearingTeamModal = () => {
    setOpenTeamModal(true);
  };

  const ClearOptions = () => {
    setOpenTeamModal(false);
  };

  //region FIELDS
  const populatedInputFields = useMemo(
    () =>
      hearingDynamicInputFields({
        dynamicFields,
        nameDisabled,
        projects,
        onLocationChange,
        schedulesForDate,
        category,
        claimDate: !!selectedClaimData?.claimObject?.claimDate
          ? selectedClaimData?.claimObject?.claimDate
          : null,
        hearingTeam: allTeams?.map((team) => ({
          label: team.teamName,
          value: team.teamName,
          members: team.members?.map(
            ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
              identityId,
              nameOfUser,
              cognitoUserId,
            })
          ),
        })),
        onTeamSelect,
        onTeamDeselect,
        form,
        isDarkMode,
        modalTitle,
        // selectedTeam,
        projectExecutives,
        subcontractors,
        employerField,
        hearingAddressField,
        crews,
      }),
    [
      dynamicFields,
      nameDisabled,
      projects,
      onLocationChange,
      schedulesForDate,
      category,
      selectedClaimData,
      allTeams,
      form,
      isDarkMode,
      modalTitle,
      // selectedTeam,
      employerField,
      hearingAddressField,
    ]
  );

  const hasCompletedHearing = useCallback(
    (claimId) =>
      (allHearings || [])?.some(
        (hearing) =>
          hearing?.claimId === claimId && hearing?.hearingStatus === "Completed"
      ) || false,
    [allHearings]
  );

  //region SAVE HEARING
  const onHearingSave = async () => {
    await saveHearingFunction({
      getFieldsValue,
      formattedFiles,
      addressSelectionInfo,
      userConfiguration,
      selectedTeam: [],
      updateProgressStatus,
      setSaving,
      notes,
      mentions,
      mentionIds,
      assignedTo,
      preferences,
      loggedOnTasks,
      driveFunctions,
      selectedClaim,
      selectedClaimData,
      setCancelModalVisible,
      hearingStatus,
      category,
      setVisibleCreationProgress,
      programFields,
      dispatch,
      hideProgress,
      hasCompletedHearing,
      modalTitle,
      crews,
    });
  };

  const resetForm = () => {
    Promise.allSettled(
      filesToBeDeleted.map(async (id) => await driveRequest.deleteDriveItem(id))
    ).then(() => setFilesToBeDeleted([]));
    resetFields();
    setChanges(false);
    setVisible(false);
    setUploadedFiles([]);
  };
  const hearingStatusHandler = (status) => {
    setHearingStatus(status);
    setChanges(true);
  };

  //open cancel modal
  const onCancel = async () => {
    setCancelModalVisible(true);
  };
  //discard changes and close modal
  const onCloseWarningModal = async () => {
    driveFunctions.parentFolderId && driveFunctions.delete();
    setCancelModalVisible(false);
    setVisible(false);
    resetForm();
  };

  useEffect(() => {
    Promise.allSettled([
      API.get("hearings", "/hearings"),
      API.get("claims", `/claims/${selectedClaim?.id}`),
      API.get("teams", "/teams"),
      lazyFetch({
        tableName: "projects",
        listOfKeys: [
          "projectId",
          "projectName",
          "accountId",
          "accountName",
          "createdAt",
          "teamsConfiguration",
          "projectLatitude",
          "projectLongitude",
          "projectManager",
          "projectExecutive",
        ],
      }),
      lazyFetch({
        tableName: "scheduling",
        listOfKeys: ["scheduleAddress", "scheduleId", "scheduleDays"],
      }),
      fetchData({ endpoint: "projectExecutives" }),
      getSubcontractors(),
      fetchData({
        endpoint: "crews",
        queryStringParams: {
          queryStringParameters: {
            keysToInclude: [
              JSON.stringify([
                "crewId",
                "crewName",
                "foreman",
                "members",
                "createdAt",
              ]),
            ],
            filters: JSON.stringify([
              {
                conditions: [
                  {
                    columnType: "string",
                    column: "crewStatus",
                    formula: "is",
                    value: "Active",
                  },
                ],
              },
            ]),
          },
        },
      }),
    ])
      .then(
        ([
          { value: hearingsRes },
          { value: claimsRes },
          { value: teamsRes },
          { value: projectsRes },
          { value: schedulingRes },
          { value: projectExecutivesRes },
          { value: subcontractorsRes },
          { value: crewsRes },
        ]) => {
          setSelectedClaimData(claimsRes);
          setProjects(projectsRes);
          setSchedulesForDate(
            filterSchedulesOnDate(schedulingRes, dayjsNY())?.schedulesForDay ||
              []
          );
          setProjectExecutives(projectExecutivesRes);
          setAllHearings(hearingsRes);
          setAllTeam(teamsRes);
          const subcontractorsArr = subcontractorsRes.map(
            ({ accountName }) => accountName
          );
          setSubcontractors(subcontractorsArr);
          setCrews(crewsRes);
        }
      )
      .catch((err) => {
        console.log("hearing fetch error", err);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(selectedClaimData || {}).length > 0) {
      // setSelectedTeam(selectedClaimData?.teamsConfiguration || []);

      const { claimObject } = selectedClaimData;
      let tmpClaimObject = { ...claimObject };
      delete tmpClaimObject.assignedTo;
      delete tmpClaimObject.time;
      delete tmpClaimObject.googleDriveUpload;
      form.setFieldsValue({
        ...tmpClaimObject,
        // hearingTeam: selectedClaimData?.teamsConfiguration?.map(
        //   (el) => el?.value
        // ),
      });
    }
  }, [selectedClaimData]);

  //Function that called on submit text from rich text
  const getMentions = useCallback(
    (content, delta, editor) => {
      const mentions = editor.getContents(content);
      //this removes first mention from array

      if (!delta.ops[1]) {
        setMentions([]);
        setMentionIds([]);
      } // this removes mentions from array but not the first one

      if (!!delta.ops[1]?.delete) {
        setMentions([]);
        setMentionIds([]);
      }

      mentions.ops.forEach((text) => {
        let mention = text.insert?.mention?.value;
        let cognitoId = text.insert?.mention?.id || "";

        if (
          mention !== undefined && //it's necessary!!!
          usersWithAccess.find(
            (user) => user.id === (cognitoId || identityId)
          ) !== undefined
        ) {
          // Getting all mentions and push them into array for broadcast notification validation
          setMentions((prev) => [...new Set([...prev, mention])]);
          setMentionIds((prev) => [...new Set([...prev, cognitoId])]);
        }
      });
    },
    [usersWithAccess]
  );

  const getValue = useCallback(
    (content, delta, source, editor) => {
      setChanges(true);
      setNotes(content);
      getMentions(content, delta, editor);
    },
    [getMentions]
  );

  //get statuses
  useEffect(() => {
    const fieldObj = programFields?.filter(
      (item) => item?.fieldName === "Status colors"
    )?.[0]?.fieldOptions?.Hearings;
    setStatuses(fieldObj?.hearingStatus || []);
  }, [programFields]);

  const createFolder = useCallback(
    async function (parentFolderId) {
      const { folders } = await driveFunctions.create({
        parentFolderName: `${form.getFieldValue(
          "representative"
        )} ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`,
        parentId: parentFolderId,
      });
      setNameDisabled(true);
      setCancelModalVisible(false);
      setDivClickable(false);
      setTimeout(() => {
        uploaderButton.click();
      }, 1000);
    },
    [form, driveFunctions]
  );

  const uploaderButton = document.querySelector(".uploaderButton");
  const onContinueToUpload = async () => {
    if (selectedClaimData?.googleDriveFolderIds?.hearingObject) {
      form
        .validateFields(["representative"])
        .then(async () => {
          createFolder(selectedClaimData?.googleDriveFolderIds?.hearingObject);
        })
        .catch(() => {
          setCancelModalVisible(false);
        });
    } else {
      await driveRequest
        .getFolderIdOrCreate({
          name: "Hearing",
          parents: [selectedClaimData?.googleDriveFolderIds?.claimObject],
        })
        .then(async (res) => {
          await API.put("claims", `/claims/${selectedClaim.id}`, {
            body: {
              googleDriveFolderIds: {
                ...selectedClaimData?.googleDriveFolderIds,
                hearingObject: res,
              },
            },
          }).then(async () => {
            await createFolder(res);
          });
        });
    }
  };

  const warningModalYESHandler = () => {
    cancelModalVisible === "doc"
      ? onContinueToUpload()
      : cancelModalVisible === "completedHearing"
      ? setCancelModalVisible(false)
      : onCloseWarningModal();
    setChanges(false);
  };

  const filterUsersByAccess = useMemo(
    () =>
      userConfiguration?.allUsers?.Items.reduce(
        (acc, user) =>
          !!user?.routeConfig?.find(
            ({ title, write }) => title === "Safety" && write === true
          ) &&
          // user.cognitoUserId !== userConfiguration.cognitoUserId &&
          !user.isSuspended
            ? [
                ...acc,
                {
                  cognitoUserId: user?.cognitoUserId,
                  nameOfUser: user?.nameOfUser,
                  identityId: user?.identityId,
                  userName: user?.userName,
                },
              ]
            : acc,
        []
      ),
    [userConfiguration]
  );

  const tourSteps = useMemo(
    () =>
      hearingGuideHelper({
        programFields,
        isDarkMode,
      }),
    [programFields, isDarkMode]
  );

  return (
    <>
      <NormalSizedModal
        {...{
          title: (
            <CustomModalHeader
              {...{
                title: `${modalTitle} - ${category}`,
                onClick: () => {
                  tourSteps.length > 0
                    ? setTourOpen(true)
                    : message.info("No guide available for this form");
                },
                headerInfo: () =>
                  (userConfiguration?.groupName === "Admin" ||
                    userConfiguration?.groupName === "Super Admin") && (
                    <>
                      <Tooltip title="Add New Fields">
                        <SettingIcon
                          className="settingsIcon"
                          onClick={() =>
                            window.open(
                              `${window.location.origin}/settings/hearings`
                            )
                          }
                        />
                      </Tooltip>
                      <Tooltip title="Sort Fields">
                        <TransferIconNew
                          {...{
                            className: "sortFieldsIcon",
                            onClick: () => {
                              setOpenSort(true);
                            },
                          }}
                        />
                      </Tooltip>
                    </>
                  ),
              }}
            />
          ),
          className: `hearingsModal ${isDarkMode && "hearingsModalDark"}`,
          centered: true,

          visible,
          setVisible,
          closable: true,
          onClose: () => {
            if (changes) {
              onCancel();
            } else {
              onCloseWarningModal();
            }
          },
          newFooterDesign: hearingFooterButtons({
            onCancel: () => {
              if (changes) {
                onCancel();
              } else {
                onCloseWarningModal();
              }
            },
            onSave: () => {
              form.validateFields().then(() => onHearingSave());
            },
            suffix: <TickIcon width={17} height={17} />,
            saving,
          }),
        }}
      >
        <Form
          {...{
            form,
            onFieldsChange: () => {
              setChanges(true);
            },
            initialValues: {
              claimNumber: selectedClaim?.name,
            },
          }}
        >
          <div className="modalContent">
            <Stepper
              {...{
                currentStep,
                setCurrentStep,
                stepperClassName: "formStepper",
                steps:
                  statuses?.map(({ statusName = "" }) => ({
                    title: statusName,
                    disabled: false,
                  })) || [],
                statusesColors: statuses || [],
                stepRenderer: false,
                requestStatus: hearingStatusHandler,
              }}
            />
            <SmallLoadableComp {...{ loading }}>
              <div className="bodyContent">
                <div className="uploaderSection">
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        zIndex: divClickable ? 10 : 0,
                        cursor: divClickable ? "pointer" : "default",
                      }}
                      onClick={() => {
                        divClickable && setCancelModalVisible("doc");
                      }}
                    ></div>
                    <DocUploader
                      {...{
                        uploadedFiles,
                        onPickerSuccess,
                        onDelete,
                        // disabled: !driveFunctions.parentFolderId,
                        onChange() {
                          setChanges(true);
                        },
                        folderId: driveFunctions.parentFolderId,
                        checkWarnings: false,
                        responsiveWidth: 1600,
                      }}
                    />
                  </div>
                  {!!selectedClaimData &&
                    selectedClaimData?.claimObject?.googleDriveUpload?.length >
                      0 && (
                      <div className="safetyFilesContainer">
                        <div className="safetyFilesTitle">
                          <hr className="safetyFilesTitleLine" />
                          <span
                            style={{ color: isDarkMode ? "#fff" : "#000" }}
                            className="safetyFilesTitleText"
                          >
                            Claim Files
                          </span>
                          <hr className="safetyFilesTitleLine" />
                        </div>
                        <DocUploader
                          {...{
                            uploadedFiles:
                              selectedClaimData?.claimObject?.googleDriveUpload,
                            onlyUploadedFiles: true,
                            checkWarnings: false,
                            responsiveWidth: 1600,
                            disabled: true,
                          }}
                        />
                      </div>
                    )}
                </div>

                <div className="detailsWrapper">
                  <BorderedTextCard
                    {...{
                      title: "Details",
                      className: "detailsCard",
                      isDarkMode,
                    }}
                  >
                    {RenderDynamicComponents(populatedInputFields, { form })}
                    <div className="hearingsAvatarsDiv">
                      {!!selectedTeam?.length && (
                        <MondayButton
                          style={{
                            paddingRight: 15,
                            margin: 0,
                            alignSelf: "center",
                          }}
                          {...{ Icon: <PlusIcon /> }}
                          onClick={openHearingTeamModal}
                        >
                          Members
                        </MondayButton>
                      )}
                      <div className="hearingsAvatars">
                        <DynamicAvatars
                          {...{
                            title: `New Hearing Team - ${category}`,
                            isDarkMode: isDarkMode,
                            members: selectedTeam.reduce(
                              (acc, { members }) => [...acc, ...members],
                              []
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <InputComponent
                      mode="multiple"
                      required
                      showSearch
                      label="Assign To"
                      placeholder="Select some users"
                      dropdownClassName={isDarkMode && "darkDropDown"}
                      type="select"
                      formItemName="assignedTo"
                      onChange={(value, option) =>
                        setAssignedTo(
                          option.map((el) => ({
                            nameOfUser: el.label,
                            identityId: el.identityId,
                            userName: el.userName,
                            cognitoUserId: el.cognitoUserId,
                          }))
                        )
                      }
                      options={filterUsersByAccess?.map(
                        (
                          { cognitoUserId, nameOfUser, identityId, userName },
                          i
                        ) => (
                          <Select.Option
                            key={i}
                            label={nameOfUser}
                            value={nameOfUser}
                            identityId={identityId}
                            cognitoUserId={cognitoUserId}
                            userName={userName}
                          />
                        )
                      )}
                      className="assignedToSelect"
                      maxTagCount={2}
                      maxTagPlaceholder={(e) => {
                        return (
                          <Tooltip
                            title={e.map(({ label }) => label).join(", ")}
                          >
                            <span>{`+ ${e.length} more`}</span>
                          </Tooltip>
                        );
                      }}
                    />
                  </BorderedTextCard>
                  <BorderedTextCard
                    title={"Notes"}
                    className={`notesCard`}
                    isDarkMode={isDarkMode}
                  >
                    <ReactQuillRichText
                      {...{
                        placeholder: "Type here...",
                        name: "notes",
                        wrapperClassName: "HearingsNotes",
                        value: notes,
                        className: "hearings_input",
                        disabled: false,
                        userConfiguration,
                        getValue,
                        defaultUsers: usersWithAccess,
                      }}
                    />
                  </BorderedTextCard>
                </div>
              </div>
            </SmallLoadableComp>
          </div>
        </Form>
        {!!cancelModalVisible && (
          <RedWarningModal
            {...{
              visible: !!cancelModalVisible,
              onCancel: () => setCancelModalVisible(false),
              titleText:
                cancelModalVisible === "doc"
                  ? "Create Hearing Folder"
                  : cancelModalVisible === "completedHearing"
                  ? "Completed Hearing Warning"
                  : "Cancel Hearing",
              confirmModal:
                cancelModalVisible === "doc" ||
                cancelModalVisible === "completedHearing",
              footerProps: {
                onConfirm: warningModalYESHandler,
              },
            }}
          >
            {cancelModalVisible === "doc" ? (
              <p>
                If you want to continue uploading Representative can not be
                changed anymore!
              </p>
            ) : cancelModalVisible === "completedHearing" ? (
              <p>
                Another Hearing has status <b>Completed</b> for this Claim.
              </p>
            ) : (
              <p>Are you sure you want to cancel? </p>
            )}
          </RedWarningModal>
        )}
        {tourOpen && (
          <Tour
            open={tourOpen}
            onClose={() => setTourOpen(false)}
            steps={tourSteps}
            mask={{ color: "#2a2b3a71" }}
          />
        )}
        {openTeamModal && (
          <DynamicTeamModal
            {...{
              open: openTeamModal,
              setOpen: setOpenTeamModal,
              selectedTeam,
              ClearOptions,
              setSelectedTeam,
              proppedAllTeams: allTeams,
              onSave: (res) => {
                form.setFieldValue("hearingTeam", res || []);
              },
            }}
          />
        )}

        {openSort && (
          <SortFields
            {...{
              visible: openSort,
              setVisible: setOpenSort,
              rowData: dynamicFields,
              onFinish: (res, newLog) => {
                saveSortedFields({
                  res,
                  newLog,
                  setNewSortFields,
                  programFields,
                  category: "Hearings",
                  saveAddedLogs,
                  fieldName: "Safety Dynamic Fields",
                });
              },
              isDarkMode,
              fieldKey: "claimCategory",
              title: "Hearings",
              proppedCategory: category,
            }}
          />
        )}

        {visibleCreationProgress && creationProgresses && (
          <ProgressComponent
            {...{
              categoryName: "Hearings",
              actionType: "Create",
              visibleCreationProgress,
              creationProgresses,
              closeModal: () => {
                const { hearingId } = visibleCreationProgress;
                resetForm();
                if (!!hearingId && !!!isNextStep) {
                  navigate(`/hearings/${hearingId}`);
                  setPreModalVisible(false);
                }
              },
            }}
          />
        )}
      </NormalSizedModal>
    </>
  );
}

export default HearingsModal;
