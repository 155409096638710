import { message } from "antd";
import {
  getItemID,
  serviceColorCodes,
} from "../../../../../../helpers/constants/forProject";
import * as FileSaver from "file-saver";
import moment from "moment-timezone";
import axios from "axios";
import { checkIfServiceIsHoist } from "../../models/Service";
import { API } from "aws-amplify";
import { b64toBlob } from "../../../../../ProposalBuilder/components/ProposalPages/MergePdfs/MergePdfs";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import Swal from "sweetalert2";

export async function exportXLSX({
  takeOffObject: data,
  proposedTypeOfWork,
  withPricing = false,
  takeOffColumns,
  saveInDrive = false,
  accessToken,
  folderId,
  customName,
}) {
  console.log("exportCLAS", {
    takeOffObject: data,
    proposedTypeOfWork,
    withPricing,
    takeOffColumns,
  });
  const workbook = {
    logo: data.logo,
    jobSiteAddress: data.jobSiteAddress,
    versionName: data.versionName,
    status: data.status,
    services: data.services
      // ?.filter((e) => e?.isScope !== true)
      .map((service) => ({
        ...service,
        label: service.label,
        additionalRentalTerms: service?.additionalRentalTerms,
        mainColor:
          proposedTypeOfWork.find((el) => el.workName === service.label)
            ?.colorCode || "#fff",
        isTaxable: service.isTaxable ? "Yes" : "No",
        serviceAddons: service.serviceAddons,
        totalPrice: checkIfServiceIsHoist(service)
          ? service.serviceOptions[0].reduce(
              (prev, curr) => prev + curr?.totalPrice,
              0
            )
          : service.serviceOptions[0].reduce(
              (prev, option) =>
                prev +
                option.items.reduce((prev, item) => prev + item?.totalPrice, 0),
              0
            ),
        totalRent: checkIfServiceIsHoist(service)
          ? service.serviceOptions[0].reduce(
              (prev, curr) => prev + curr?.rentalsTotal,
              0
            )
          : service.serviceOptions[0].reduce(
              (prev, curr) =>
                prev + curr.items.reduce((a, b) => a + b?.rent, 0),
              0
            ),
        serviceOptions: service.serviceOptions[0].map((option) => ({
          elevationLabel: option.elevationLabel,
          items:
            option.items?.map((el) => ({
              ...el,
              taxRate: el?.taxRate * 100,
            })) || [],
          pricing: checkIfServiceIsHoist(service)
            ? option?.autoScheme
            : option?.pricing || {},
          // option.items.map((item) => {
          //   return Object.keys(takeOffColumns[service.label])
          //     .filter((key) =>
          //       withPricing
          //         ? true
          //         : takeOffColumns[service.label][key].show !== withPricing
          //     )
          //     .reduce(
          //       (prev, key) => ({
          //         ...prev,
          //         [takeOffColumns[service.label][key].label]:
          //           key === "description" || key === "note"
          //             ? item[key]?.note?.replace(/<[^>]+>/g, "")
          //             : typeof item[key] === "boolean"
          //             ? item[key]
          //               ? "Yes"
          //               : "No"
          //             : item[key],
          //       }),
          //       {}
          //     );
          // }),
        })),
      })),
  };
  const fileName = `${data.jobSiteAddress} - ${data.versionName}.xlsx`;
  const hideLoad = message.loading("Exporting to Excel...", 0);
  await API.post("exportTakeoff", "/exportTakeoff", {
    body: {
      ...workbook,
    },
  }).then(async (response) => {
    if (!accessToken) {
      console.log("response", response);
      let blob = b64toBlob(
        response.body,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      FileSaver.saveAs(blob, fileName);
      hideLoad();
      message.success("Export successful.");
    } else {
      const loading = message.loading("Uploading to Google Drive...", 0);
      const driveRequest = driveApi({ accessToken });

      await driveRequest
        .uploadFile(
          response.body,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
          {
            name: customName || fileName,
            parents: [folderId],
            //   mimeType:
            mimeType: "application/vnd.google-apps.spreadsheet",
          },
          true
        )
        .then((response) => {
          message.success("Export successful.");
          Swal.fire({
            title: "Success",
            text: "Current Take off Import successful",
            icon: "success",
          });
        })
        .finally(() => {
          message.destroy();
        });
    }
  });

  return;
  // // const url = "http://10.0.0.85:8080/api/export/xlsx";
  // const url =
  //   "https://leadmanager-express-backend.ue.r.appspot.com/api/export/xlsx";
  // // "https://leadmanager-express-backend.ue.r.appspot.com/api/export/xlsx";
  // // process.env.NODE_ENV === "development"
  // // "http://localhost:8080/api/export/xlsx";
  // //   : "https://leadmanager-express-backend.ue.r.appspot.com/api/export/xlsx";
  // console.log("exporting to XLSX", data);
  // const workbook = {
  //   logo: data.logo,
  //   jobSiteAddress: data.jobSiteAddress,
  //   versionName: data.versionName,
  //   status: data.status,
  //   services: data.services
  //     // ?.filter((e) => e?.isScope !== true)
  //     .map((service) => ({
  //       ...service,
  //       label: service.label,
  //       additionalRentalTerms: service?.additionalRentalTerms,
  //       mainColor:
  //         proposedTypeOfWork.find((el) => el.workName === service.label)
  //           ?.colorCode || "#fff",
  //       isTaxable: service.isTaxable ? "Yes" : "No",
  //       serviceAddons: service.serviceAddons,
  //       totalPrice: checkIfServiceIsHoist(service)
  //         ? service.serviceOptions[0].reduce(
  //             (prev, curr) => prev + curr?.totalPrice,
  //             0
  //           )
  //         : service.serviceOptions[0].reduce(
  //             (prev, option) =>
  //               prev +
  //               option.items.reduce((prev, item) => prev + item?.totalPrice, 0),
  //             0
  //           ),
  //       totalRent: checkIfServiceIsHoist(service)
  //         ? service.serviceOptions[0].reduce(
  //             (prev, curr) => prev + curr?.rentalsTotal,
  //             0
  //           )
  //         : service.serviceOptions[0].reduce(
  //             (prev, curr) =>
  //               prev + curr.items.reduce((a, b) => a + b?.rent, 0),
  //             0
  //           ),
  //       serviceOptions: service.serviceOptions[0].map((option) => ({
  //         elevationLabel: option.elevationLabel,
  //         items:
  //           option.items?.map((el) => ({
  //             ...el,
  //             taxRate: el?.taxRate * 100,
  //           })) || [],
  //         pricing: option?.pricing || {},
  //         // option.items.map((item) => {
  //         //   return Object.keys(takeOffColumns[service.label])
  //         //     .filter((key) =>
  //         //       withPricing
  //         //         ? true
  //         //         : takeOffColumns[service.label][key].show !== withPricing
  //         //     )
  //         //     .reduce(
  //         //       (prev, key) => ({
  //         //         ...prev,
  //         //         [takeOffColumns[service.label][key].label]:
  //         //           key === "description" || key === "note"
  //         //             ? item[key]?.note?.replace(/<[^>]+>/g, "")
  //         //             : typeof item[key] === "boolean"
  //         //             ? item[key]
  //         //               ? "Yes"
  //         //               : "No"
  //         //             : item[key],
  //         //       }),
  //         //       {}
  //         //     );
  //         // }),
  //       })),
  //     })),
  // };
  // console.log("workbook", workbook);
  // const hideLoading = message.loading("Exporting...", 0);
  // await axios({
  //   method: "post",
  //   url,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   responseType: "blob",
  //   data: JSON.stringify({
  //     takeOffData: workbook,
  //     apiKey: "a32e6d3b-d3ff-40a4-a54d-2136fe29e8cd",
  //   }),
  // })
  //   .then((response) => {
  //     hideLoading();
  //     FileSaver.saveAs(response.data, fileName);
  //     message.success("Exported successfully!");
  //   })
  //   .catch((error) => {
  //     hideLoading();
  //     console.log("errorExporti", error);
  //     message.error("Export failed!");
  //   });
}
const serviceTakeOffExcelConfig = {
  // Sidewalk Shed
  1: [
    {
      key: "id",
      name: "PLI",
      formula: "none",
    },
    {
      key: "length",
      name: "Length",
      formula: "none",
    },
    {
      key: "height",
      name: "Height",
      formula: "none",
    },
    {
      key: "width",
      name: "Width",
      formula: "none",
    },
    {
      key: "ppu",
      name: "Price Per Unit",
      formula: "none",
    },
    {
      key: "rent",
      name: "Rent",
      formula: "none",
    },
    {
      key: "rent",
      name: "PLI",
      formula: "none",
    },
    {
      key: "price",
      name: "price",
      formula: "none",
    },
    {
      key: "taxRate",
      name: "Tax Rate",
      formula: "none",
    },
    {
      key: "taxAmount",
      name: "Tax Amount",
      formula: "none",
    },
    {
      key: "pliAddonTotal",
      name: "PLI Add-On Total",
      formula: "none",
    },
    {
      key: "totalPrice",
      name: "Total Price",
      formula: "none",
    },
  ],
  // Scaffold
  2: [
    {
      key: "id",
      name: "PLI",
      formula: "none",
    },
    {
      key: "length",
      name: "Length",
      formula: "none",
    },
    {
      key: "height",
      name: "Height",
      formula: "none",
    },
    {
      key: "width",
      name: "Width",
      formula: "none",
    },
    {
      key: "ppu",
      name: "Price Per Unit",
      formula: "none",
    },
    {
      key: "rent",
      name: "Rent",
      formula: "none",
    },
    {
      key: "rent",
      name: "PLI",
      formula: "none",
    },
    {
      key: "price",
      name: "price",
      formula: "none",
    },
    {
      key: "taxRate",
      name: "Tax Rate",
      formula: "none",
    },
    {
      key: "taxAmount",
      name: "Tax Amount",
      formula: "none",
    },
    {
      key: "pliAddonTotal",
      name: "PLI Add-On Total",
      formula: "none",
    },
    {
      key: "totalPrice",
      name: "Total Price",
      formula: "none",
    },
  ],
  // 6 Fence
  6: [
    {
      key: "id",
      name: "PLI",
      formula: "none",
    },
    {
      key: "length",
      name: "Length",
      formula: "none",
    },
    {
      key: "height",
      name: "Height",
      formula: "none",
    },
    {
      key: "width",
      name: "Width",
      formula: "none",
    },
    {
      key: "ppu",
      name: "Price Per Unit",
      formula: "none",
    },
    {
      key: "rent",
      name: "Rent",
      formula: "none",
    },
    {
      key: "rent",
      name: "PLI",
      formula: "none",
    },
    {
      key: "price",
      name: "price",
      formula: "none",
    },
    {
      key: "taxRate",
      name: "Tax Rate",
      formula: "none",
    },
    {
      key: "taxAmount",
      name: "Tax Amount",
      formula: "none",
    },
    {
      key: "pliAddonTotal",
      name: "PLI Add-On Total",
      formula: "none",
    },
    {
      key: "totalPrice",
      name: "Total Price",
      formula: "none",
    },
  ],
};
