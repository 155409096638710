import { Popover } from "antd";
import { groupBy } from "lodash";
import { useSelector } from "react-redux";
import { useContext, useMemo } from "react";

import PayrollLiveContext from "../../PayrollLiveContext";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { JobsiteType } from "src/components/SidebarPages/FleetMaintenanceView/types";

import "./JobsiteSidebarItem.scss";

const statusColors = {
  In: "#00a464",
  Out: "#787677",
  ["No Punch"]: "#f04f4e",
  ["Don't Match"]: "#e9c466",
};

function JobsiteSidebarItem(props: JobsiteType) {
  const { projectId, jobsiteId, jobAddress, payrollType, addressPosition } =
    props;
  const { employeesReport, filters, setFilters, mapRef } =
    useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  function onJobsiteClick() {
    let coordinateMarkers = [addressPosition];
    let matchedEmployees = [];

    for (let i = 0; i < employeesReport.length; i++) {
      const emp = employeesReport[i];
      if (emp?.projectId === projectId) {
        coordinateMarkers.push(emp.punchCoordinates);
        matchedEmployees.push(emp.employeeNumber);
      }
    }
    if (filters.schedules.includes(projectId)) {
      setFilters((prev) => ({
        ...prev,
        schedules: prev.schedules.filter((id) => id !== projectId),
        employeeNumber: prev.employeeNumber.filter(
          (nr) => !matchedEmployees.includes(nr)
        ),
      }));
      mapRef.current.fitBoundsToMarkers([]);
    } else {
      setFilters((prev) => ({
        ...prev,
        schedules: prev.schedules.concat(projectId),
        employeeNumber: prev.employeeNumber.concat(matchedEmployees),
      }));
      mapRef.current.fitBoundsToMarkers(coordinateMarkers);
    }
  }

  const employeesIncluded = useMemo(() => {
    return groupBy(
      employeesReport.filter((el) => el?.jobsiteId === jobsiteId),
      (el) => el?.crewTeamName
    );
  }, [employeesReport]);

  return (
    <section
      className={`jobsite-sidebar-item ${
        darkMode ? "jobsite-sidebar-item-dark" : ""
      } ${filters.schedules.includes(projectId) ? "active" : ""}`}
      // key={jobsiteId}
      onClick={onJobsiteClick}
    >
      <div className="jobsite-data">
        <label>Jobsite</label>
      </div>
      <div className="jobsite-address">
        <label>{jobAddress}</label>
      </div>

      <div className="jobsite-data">
        <label>Payroll Type:</label>
        <span>{payrollType}</span>
      </div>

      <div className="jobsite-data">
        <label>Employees on Job:</label>
        <div>
          {employeesReport.filter((el) => el?.projectId === projectId)
            ?.length || 0}
        </div>
      </div>

      <div className="jobsite-data" style={{ flexDirection: "column" }}>
        <label>Teams Included:</label>
        <div>
          {Object.keys(employeesIncluded).flatMap((teamName) => {
            return (
              <div style={{ display: "flex", gap: 10 }}>
                <label>{teamName}</label>
                <Popover
                  trigger="hover"
                  title={
                    <div className="sidebar-popover-header">
                      Employees Included
                    </div>
                  }
                  content={
                    <div className="sidebar-popover-content">
                      {employeesIncluded?.[teamName].map((el) => (
                        <div className="employee-included">
                          <span
                            className="status-dot"
                            style={{
                              backgroundColor:
                                statusColors?.[el?.liveStatus] || "#ccc",
                            }}
                          ></span>
                          <span>{el?.employeeName}</span>
                        </div>
                      ))}
                    </div>
                  }
                >
                  {/* <div>({employeesIncluded?.[teamName]?.length})</div> */}
                  <div>({employeesIncluded?.[teamName]?.length})</div>
                </Popover>
                {/* <Tooltip
                  title={employeesIncluded?.[teamName]
                    .map((el) => el.employeeName)
                    .join(", ")}
                >
                  <div>({employeesIncluded?.[teamName]?.length})</div>
                </Tooltip> */}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default JobsiteSidebarItem;
