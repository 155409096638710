import "./CustomCheckBox.scss";
import { Checkbox, Tooltip } from "antd";
import { useSelector } from "react-redux";
import WithTooltip from "../WithTooltip/WithTooltip";
import { SuspendedUserIcon } from "../../../assets";

const CustomCheckBox = ({
  name,
  checked,
  indeterminate,
  disabled,
  onChange,
  style = {},
  textStyle = {},
  tooltipCategory,
  tooltipKey,
  suspendedText = false,
  ...props
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <WithTooltip tooltipCategory={tooltipCategory} tooltipKey={tooltipKey}>
      <div
        className={`custom-checkbox-wrapper ${
          isDarkMode && "custom-checkbox-wrapper-dark"
        }`}
        style={style}
      >
        <Checkbox
          id={name}
          key={name}
          indeterminate={indeterminate}
          disabled={disabled}
          onChange={onChange}
          checked={checked}
          {...props}
        >
          {suspendedText ? (
            <Tooltip title="Suspended">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <span style={textStyle}>{name}</span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SuspendedUserIcon fill="#F04F4E" />
                </div>
              </div>
            </Tooltip>
          ) : (
            <span style={textStyle}>{name}</span>
          )}
        </Checkbox>
      </div>
    </WithTooltip>
  );
};

export default CustomCheckBox;
