import { useContext } from "react";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import { getIconComponent } from "../../utils/getIconComponent";
import "./SingleCard.scss";
import ExportActions from "../../ExportActions";

const SingleCard = ({ item, category = true }) => {
  const {
    activePrimaryCat,
    setActivePrimaryCat,
    subCatVideos,
    setSubCatVideos,
    isDarkMode,
  } = useContext(VideoTutorialContext);

  const IconComponent = getIconComponent(item?.icon);

  const handleCardClick = () => {
    if (activePrimaryCat === item?.id) {
      setActivePrimaryCat();
    } else {
      setActivePrimaryCat(item?.id);
    }
    setSubCatVideos();
  };

  const handleSubCatClick = () => {
    if (!!subCatVideos > 0 && subCatVideos[0]?.id === item?.videos[0]?.id) {
      setSubCatVideos();
    } else {
      setSubCatVideos(item?.videos);
    }
  };

  return category ? (
    <div
      className={`card-container 
        ${isDarkMode && "singleCard-containerDark"}
        ${item.id === activePrimaryCat && "activeCategory"}`}
      onClick={handleCardClick}
    >
      {item?.icon?.includes("http") ? (
        <img src={item?.icon} alt="" />
      ) : (
        IconComponent && (
          <IconComponent
            className={`page-icon ${
              item?.icon === "SalesWhite"
                ? !isDarkMode
                  ? "sales"
                  : "salesDarkMode"
                : isDarkMode
                ? "otherDark"
                : "other"
            }`}
          />
        )
      )}
      <span className="name">{item?.categoryName}</span>
      <span className="total">({item?.subCategories?.length})</span>
    </div>
  ) : (
    <div
      className={`card-container 
        ${isDarkMode && "singleCard-containerDark"}
      ${
        !!subCatVideos && subCatVideos[0]?.id === item.videos[0]?.id
          ? "activeCategory"
          : ""
      }
      `}
    >
      <div
        style={{
          flexGrow: "1",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span className="name" onClick={handleSubCatClick}>
          {item?.subCategoryName}
        </span>
        <span className="total">({item?.videos?.length})</span>
      </div>
      <div>
        <ExportActions {...{ item }} />
      </div>
    </div>
  );
};

export default SingleCard;
