import { Button, Tour, message, Tooltip, Badge } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ScheduleCrews } from "./ScheduleCrews";
import { ScheduleFormSettings } from "./ScheduleFormSettings";
import "./HelperButtons.scss";
import { NotesDrawer } from "../NotesDrawer/NotesDrawer";

import { MondayButton } from "../../../../../commonComponents";
import {
  ClosedEyeIcon,
  HelpTourIcon,
  MinusIcon,
  OpenedEyeIcon,
  PlusIcon,
} from "../../../../../../assets";
import { useMultipleRefs } from "../../../../../../hooks/useMultipleRefs";

/**
 * These are buttons on the top of schedule days. They can perform various actions. At the time of writing we have these buttons:
 * - Show/Hide schedule days (this is to make more space to work with plis)
 * - Preview - Displays a printable preview in a modal of plis ordered by days. See ScheduleDaysPreview component
 * - Open logs drawer - A drawer with all notifications that user has got while working with plis. See ScheduleFormLogs component
 * - Project calendar - All days that are scheduled for this project (in this or other schedules for this project). See ProjectCalendar component
 * - Open notes. Opens a drawer with all kind of notes. Schedule days notes, pli notes and general notes. See NotesDrawer
 * - Open settings. Open a modal with some settings for future use. See ScheduleFormSettings
 * - Add and Remove buttons (+ and -). They add or remove day in the end of schedule days.
 * */
export const HelperButtons = forwardRef(function HelperButtons(
  {
    unappliedLogs,
    setUnappliedLogs,
    setAbleToSave,
    setPreview,
    scheduleAddress,
    onAddDay,
    onRemoveDay,
    scheduleDays,
    typeOfWork,
    scheduleId,
    scheduleNotes,
    notesChanged,
    notesAccessRights,
    visibility,
    setVisibility,
    editMode,
    setTourOpen,
    setToBeScheduled,
    toBeScheduled,
    isWritable,
    isDarkMode,
    updateProgressOfAllSchedule,
    scheduleCrews,
    newCrewsUpdate,
  },
  ref
) {
  const [
    hideRef,
    previewRef,
    notificationRef,
    oldDaysRef,
    notesRef,
    settingsRef,
    addDaysRef,
    removeDaysRef,
  ] = useMultipleRefs(8);

  const Eye = !visibility ? <ClosedEyeIcon /> : <OpenedEyeIcon />;

  const styleIcon = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 32,
    height: 32,
    background: "#1264a3",
    borderRadius: 5,
    color: "#fff",
    cursor: "pointer",
  };

  const addNewScheduleDay = () => {
    onAddDay(scheduleDays?.length);
    setVisibility(true);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        getHelperButtonsRefs() {
          return {
            hideRef,
            previewRef,
            notificationRef,
            oldDaysRef,
            notesRef,
            settingsRef,
            addDaysRef,
            removeDaysRef,
          };
        },
      };
    },
    []
  );

  // setIsWritable(true);

  return (
    <div className="navbar-buttons-schedule">
      <div className="buttons-schedule-structure">
        <div style={styleIcon} ref={hideRef}>
          <Tooltip title={visibility ? "Hide Days" : "Show Days"}>
            <div
              onClick={() => {
                setVisibility((m) => !m);
              }}
            >
              {Eye}
            </div>
          </Tooltip>
        </div>
        <div ref={previewRef}>
          <MondayButton
            // disabled={true}
            onClick={(e) => {
              setPreview(true);
              // handlePreviewInfo(e);
            }}
            Icon={<OpenedEyeIcon />}
            className="secondaryBtn"
          >
            Days Preview
          </MondayButton>
        </div>
        {/* <div style={styleIcon} ref={notificationRef}>
          <ScheduleNotification
            onClick={() => {
              // setWorkLogDrawerOpen(!workLogDrawerOpen);
            }}
          />
          <ScheduleFormLogs
            {...{ workLogDrawerOpen, workLogMessages }}
            onClose={() => {
              setWorkLogDrawerOpen(!workLogDrawerOpen);
            }}
          />
        </div> */}
        <div style={styleIcon} ref={oldDaysRef} disabled={true}>
          <ScheduleCrews
            {...{
              scheduleAddress,
              scheduleCrews,
              newCrewsUpdate,
            }}
          />
        </div>
        <div style={styleIcon} ref={notesRef}>
          <NotesDrawer
            {...{
              scheduleId,
              scheduleNotes,
              scheduleAddress,
              notesChanged,
              notesAccessRights,
              isDarkMode,
            }}
          />
        </div>
        <Badge
          count={
            Object.values(toBeScheduled || {})
              .flat()
              ?.filter((ser) => !!ser?.isNotAppliedForProgress)?.length
          }
        >
          <div style={styleIcon} ref={settingsRef}>
            <ScheduleFormSettings
              {...{
                setToBeScheduled,
                toBeScheduled,
                isDarkMode,
                setAbleToSave,
                updateProgressOfAllSchedule,
                editMode,
                unappliedLogs,
                setUnappliedLogs,
              }}
            />
          </div>
        </Badge>

        <Tooltip title={"Remove last day"} placement="top" arrow>
          {/* <div ref={removeDaysRef}> */}
          <div
            ref={removeDaysRef}
            danger
            type="primary"
            className="remove-day-schedule"
            onClick={() => {
              onRemoveDay(scheduleDays?.[scheduleDays?.length - 1]?.id);
              setAbleToSave(true);
            }}
            disabled={editMode ? !isWritable : false}
          >
            <MinusIcon
              style={{
                height: 17,
                width: 17,
              }}
            />
          </div>
          {/* </div> */}
        </Tooltip>
        <Tooltip title="Add day" placement="top" arrow>
          <div
            ref={addDaysRef}
            type="primary"
            className="add-day-btn-schedule"
            onClick={addNewScheduleDay}
            disabled={editMode ? !isWritable : false}
          >
            {/* <Plus /> */}
            <PlusIcon
              style={{
                height: 17,
                width: 17,
              }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Show Tour">
          <div
            style={styleIcon}
            onClick={
              !isWritable && editMode ? () => {} : () => setTourOpen(true)
            }
          >
            <HelpTourIcon />
          </div>
        </Tooltip>
      </div>
    </div>
  );
});
