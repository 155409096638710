import { CalendarIcon, ClockIcon } from "../../FilterView/FilterIcons";
import { BarFullFilterFunctions } from "./BarChartFullData";
import { Menu, Dropdown, Button } from "antd";
import { DatePicker } from "antd";
import { useEffect } from "react";
import {
  datePickerProgressHandler,
  dateRangeDateProgressHandler,
  filterOptions,
} from "../filtersData";
import dayjs from "dayjs";
import { getRangePresets } from "src/components/SidebarPages/Fleet/components/InputComponent/utils";

const BarChartFull = ({
  params,
  id,
  dynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  setRowData,
  filterStates,
  setFilterStates,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const table = params?.table;
  const fields = params?.field;
  //return the filtered data for the specific card type
  const data = BarFullFilterFunctions({
    updatedDynamicStates,
    dynamicStates,
    id,
  });
  //this updates displayed data for the card that is filtered
  useEffect(() => {
    setRowData((prev) =>
      prev.map((a = {}) => {
        const { BodyComponentParams = {} } = a;
        const { datasets = [] } = BodyComponentParams;
        const [dataSetObject = {}] = datasets;
        return a?.id !== id
          ? a
          : {
              ...a,
              BodyComponentParams: {
                ...BodyComponentParams,
                datasets: [
                  {
                    ...dataSetObject,
                    data: data[table]()[fields](),
                  },
                ],
              },
            };
      })
    );
  }, [updatedDynamicStates]);

  return (
    <>
      <div className="filterComponents">
        <div className="tittle">Filter date</div>
        <div className="filterContainer">
          <div className="leftSideContainer">
            <div className="tittleContainer">Filter time</div>
            <div className="tittleContainer">Filter range</div>
            <div className="tittleContainer">Filter date</div>
          </div>
          <div className="rightSideContainer">
            <Dropdown
              menu={{
                style: { borderRadius: "10px" },
                items: filterOptions(
                  setUpdatedDynamicStates,
                  updatedDynamicStates,
                  id,
                  dynamicStates,
                  setFilterStates,
                  table
                ).map((a, index) => ({
                  key: index,
                  label: a?.value,
                  onClick: () => {
                    a?.filter();
                    a?.buttonName();
                  },
                })),
              }}
              placement="bottomCenter"
              suffixIcon={<CalendarIcon />}
              className="filter-dropdown"
              arrow
            >
              <Button>
                {filterStates?.filterByTime}
                <ClockIcon />
              </Button>
            </Dropdown>

            <DatePicker.RangePicker
              // value={filterStates?.filterByRange}
              value={[
                dayjs(filterStates?.filterByRange?.[0]) || "",
                dayjs(filterStates?.filterByRange?.[1]) || "",
              ]}
              allowClear={false}
              format="MM/DD/YYYY"
              suffixIcon={<CalendarIcon />}
              presets={getRangePresets()}
              onChange={(e) =>
                dateRangeDateProgressHandler(
                  e,
                  setUpdatedDynamicStates,
                  updatedDynamicStates,
                  dynamicStates,
                  id,
                  setFilterStates
                )
              }
              className="filter-by-range-picker"
              popupClassName={`filter-by-date-picker-dropdown ${
                isDarkMode && "dateDropDownDark"
              }`}
            />
            <DatePicker
              value={filterStates?.filterByDay}
              allowClear={false}
              format="MM/DD/YYYY"
              suffixIcon={<CalendarIcon />}
              onChange={(e) =>
                datePickerProgressHandler(
                  e,
                  setUpdatedDynamicStates,
                  updatedDynamicStates,
                  dynamicStates,
                  id,
                  setFilterStates
                )
              }
              className="filter-by-date-picker"
              popupClassName={`filter-by-date-picker-dropdown ${
                isDarkMode && "dateDropDownDark"
              }`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BarChartFull;
