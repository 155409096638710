import { notification, message, Dropdown } from "antd";

import { DropdownIcon } from "../../../../../../../../icons";
import { MondayButton } from "../../../../../../../commonComponents";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../../../Settings/settingsComponents/Roles/src";
import {
  getCloseIcon,
  getClickPhrase,
  warningNotificationTitle,
} from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/userNotifications";
import { getLabelHTML } from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/routeFields";

export function employeesField({ globalDisabled, employeeFieldTitle, crews }) {
  return [
    {
      label: employeeFieldTitle,
      formItemName: "selectedMembers",
      type: "styledmultiselect",
      mode: "multiple",
      placeholder: "Select members...",
      dataTestid: "selectedMembers",
      optionFilterProp: "label",
      required: true,
      showSearch: true,
      disabled: globalDisabled,
      customOptions: crews?.map(({ crewId, crewName, employeeId }) => ({
        value: crewId,
        label: `${crewName} (${employeeId})`,
      })),
    },
  ];
}

export function getEditFields({
  mode,
  form,
  sowList,
  crews = [],
  selectedDate,
  jobsites = [],
  shiftsGridApi,
  shiftsConflicts,
  dateRangeCreation,
  matchedJobsite = {},
  regularShift = true,
  lunchIncluded = true,
  nextDayStatuses = {},
  globalDisabled = false,
  onTypeChange = () => {},
  onDateSelect = () => {},
  onTimeSelect = () => {},
  onLeaveSelect = () => {},
  onJobsiteSelect = () => {},
  showAllServices = () => {},
  setNextDayStatuses = () => {},
}) {
  const massEntryEmployeeDisable = mode === "MASS_ENTRY" && shiftsConflicts;

  const massEntryDateDisable =
    mode === "MASS_ENTRY" &&
    findDuplicate(
      shiftsGridApi.getSelectedNodes().map(({ data }) => data?.employeeId)
    ).hasDuplicates;

  const massEntryHasHR =
    mode === "MASS_ENTRY" &&
    shiftsGridApi.getSelectedNodes().find(({ data }) => {
      return data?.shiftType === "HR Shift";
    });

  const jobsiteRequired = mode !== "MASS_ENTRY";
  // ||
  // (mode === "MASS_ENTRY" &&
  //   shiftsGridApi
  //     .getSelectedNodes()
  //     .find(({ data }) => data.shiftType === "HR Shift"));

  const hrCategories = [
    "Unpaid Time Off",
    "Paid Time Of",
    "Vacation",
    "Sick",
    "Holiday",
    "Bereavement",
    "Personal",
    "Regular",
    "Overtime",
    "NYC Covid Leave",
  ];

  return {
    ["Shift Status"]: [
      mode === "MASS_ENTRY" && {
        label: "Employees",
        formItemName: "selectedMembers",
        dataTestid: "selectedMembers",
        type: "select",
        mode: "single",
        placeholder: "Select members...",
        optionFilterProp: "label",
        required: false,
        showSearch: true,
        disabled: massEntryEmployeeDisable || globalDisabled,
        customOptions: crews?.map(({ crewId, crewName, employeeId }) => ({
          value: crewId,
          label: `${crewName} (${employeeId})`,
        })),
      },
      {
        label: "Shift Type",
        required: mode !== "MASS_ENTRY",
        allowClear: false,
        formItemName: "shiftType",
        dataTestid: "shiftType",
        type: "select",
        disabled: globalDisabled,
        options: ["Regular Shift", "HR Shift"],
        onSelect: onTypeChange,
      },
      {
        label: "Shift Status",
        required: mode !== "MASS_ENTRY",
        allowClear: false,
        type: "select",
        disabled: globalDisabled,
        formItemName: "shiftStatus",
        dataTestid: "shiftStatus",
        options: ["Draft", "Pending", "Completed"],
      },
      mode === "NEW" && {
        label: "Create on Date Range",
        type: "checkbox",
        disabled: globalDisabled,
        formItemName: "dateRangeCreation",
        dataTestid: "dateRangeCreation",
      },
    ].filter(Boolean),
    ["Shift Date & Time"]: [
      dateRangeCreation
        ? {
            label: "Shift Date Range",
            formItemName: "punchDateRange",
            dataTestid: "punchDateRange",
            type: "rangepicker",
            required: mode === "NEW",
            allowClear: false,
            disabled: globalDisabled,
            // onChange: onDateSelect,
          }
        : {
            label: "Shift Date",
            formItemName: "punchDate",
            dataTestid: "punchDate",
            type: "datepicker",
            required: mode !== "MASS_ENTRY",
            allowClear: false,
            disabled: globalDisabled || massEntryDateDisable,
            onChange: onDateSelect,
          },
      regularShift && {
        label: "Lunch Break",
        formItemName: "lunchIncluded",
        dataTestid: "lunchIncluded",
        type: "checkbox",
      },
      {
        form,
        label: getNextDayTimeLabel(
          "Clock In",
          nextDayStatuses["clockIn"],
          mode !== "MASS_ENTRY"
        ),
        formItemName: "clockIn",
        required: false,
        // type: "timepicker",
        selectedDate,
        type: "customTimeInput",
        allowClear: false,
        disabled: globalDisabled || !regularShift,
        rules: [
          {
            required: mode !== "MASS_ENTRY",
            message: "Clock In is required!",
          },
        ],
        onChange(e) {
          onTimeSelect(e, "clockIn");
        },
      },
      {
        form,
        label: getNextDayTimeLabel(
          "Clock Out",
          nextDayStatuses["clockOut"],
          mode !== "MASS_ENTRY"
        ),
        formItemName: "clockOut",
        required: false,
        // type: "timepicker",
        selectedDate,
        type: "customTimeInput",
        allowClear: false,
        disabled: globalDisabled || !regularShift,
        rules: [
          {
            required: mode !== "MASS_ENTRY",
            message: "Clock Out is required!",
          },
        ],
        onChange(e) {
          onTimeSelect(e, "clockOut");
        },
      },
      lunchIncluded &&
        regularShift && {
          form,
          label: getNextDayTimeLabel(
            "Lunch Start",
            nextDayStatuses["lunchStart"],
            mode !== "MASS_ENTRY" || massEntryHasHR
          ),
          formItemName: "lunchStart",
          required: false,
          // type: "timepicker",
          selectedDate,
          type: "customTimeInput",
          allowClear: false,
          disabled: globalDisabled,
          rules: [
            {
              required: mode !== "MASS_ENTRY" || massEntryHasHR,
              message: "Lunch Start is required!",
            },
          ],
          onChange(e) {
            onTimeSelect(e, "lunchStart");
          },
        },
      lunchIncluded &&
        regularShift && {
          form,
          label: getNextDayTimeLabel(
            "Lunch End",
            nextDayStatuses["lunchEnd"],
            mode !== "MASS_ENTRY" || massEntryHasHR
          ),
          formItemName: "lunchEnd",
          required: false,
          // type: "timepicker",
          selectedDate,
          type: "customTimeInput",
          allowClear: false,
          disabled: globalDisabled,
          rules: [
            {
              required: mode !== "MASS_ENTRY" || massEntryHasHR,
              message: "Lunch End is required!",
            },
          ],
          onChange(e) {
            onTimeSelect(e, "lunchEnd");
          },
        },
      {
        label: "Standard Shift",
        type: "checkbox",
        dataTestid: "standardShift",
        disabled: globalDisabled,
        onChange: (e) => {
          const setStandardShift = e.target.checked;
          let date = selectedDate;
          if (!date) {
            date = dayjsNY();
          }

          if (setStandardShift) {
            form.setFieldValue("clockIn", date.set("hour", 7).set("minute", 0));
            form.setFieldValue(
              "clockOut",
              date.set("hour", 15).set("minute", 0)
            );
            if (form.getFieldValue("lunchIncluded")) {
              form.setFieldValue(
                "lunchStart",
                date.set("hour", 12).set("minute", 0)
              );
              form.setFieldValue(
                "lunchEnd",
                date.set("hour", 13).set("minute", 0)
              );
              form.setFieldValue(
                "clockOut",
                date.set("hour", 16).set("minute", 0)
              );
            }
          } else {
            form.setFieldValue("clockIn", null);
            form.setFieldValue("clockOut", null);
            if (form.getFieldValue("lunchIncluded")) {
              form.setFieldValue("lunchStart", null);
              form.setFieldValue("lunchEnd", null);
            }
          }

          setNextDayStatuses((prev) => {
            let t = { ...prev };
            for (const key in t) {
              t[key] = false;
            }
            return t;
          });
        },
      },
    ].filter(Boolean),
    ["Shift Address"]: [
      {
        label: getLabelHTML(
          matchedJobsite?.payrollType ? `(${matchedJobsite?.payrollType})` : "",
          "Jobsite",
          undefined,
          jobsiteRequired
        ),
        formItemName: "jobsite",
        dataTestid: "jobsite",
        disabled: globalDisabled,
        type: "select",
        getPopupContainer: document.body,
        required: false,
        showSearch: true,
        allowClear: false,
        onSelect: onJobsiteSelect,
        placeholder: "Select a Jobsite...",
        style: { minWidth: "300px" },
        rules: [
          {
            required: jobsiteRequired,
            message: "Jobsite is required!",
          },
        ],
        customOptions: jobsites?.map(
          ({ jobAddress, jobName, jobsiteId, accountName }, i) => ({
            label: `${jobName} - ${accountName?.accountName || accountName || ""}`,
            key: `${jobAddress}${i}`,
            value: jobsiteId,
          })
        ),
      },
      mode !== "MASS_ENTRY" && {
        label: "Rate",
        formItemName: "rate",
        dataTestid: "rate",
        type: "input",
        typeNumber: true,
        placeholder: "Set rate...",
        suffixIcon: "$",
      },
      {
        label: "SOW",
        disabled: globalDisabled,
        options: sowList || [],
        getPopupContainer: document.body,
        type: "select",
        dataTestid: "sow",
        mode: "multiple",
        placeholder: "Select a SOW...",
        formItemName: "sow",
        dropdownRender: (menu) => {
          return (
            <main>
              {menu}
              <section
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MondayButton
                  className="mondayButtonBlue"
                  hasIcon={false}
                  onClick={showAllServices}
                >
                  Show all Services
                </MondayButton>
              </section>
            </main>
          );
        },
        // required: mode !== "MASS_ENTRY",
      },
    ].filter(Boolean),
    ["Leave Reason"]: [
      {
        label: "Leave Reason",
        formItemName: "reason",
        dataTestid: "reason",
        disabled: globalDisabled,
        required: mode !== "MASS_ENTRY",
        type: "select",
        allowClear: false,
        disabled: globalDisabled,
        options: hrCategories,
        onSelect: onLeaveSelect,
        getPopupContainer: document.body,
      },
    ].filter(Boolean),
  };
}

export function getFooterButtons({
  mode,
  items,
  loading,
  isDarkMode,
  employeeMatch,
  globalDisabled,
  onSave = () => {},
  onCancel = () => {},
  onShowLogs = () => {},
}) {
  return [
    <MondayButton
      {...{
        Icon: <XIcon />,
        className: "mondayButtonRed",
        onClick: onCancel,
        disabled: globalDisabled,
        ["data-testid"]: "close-shift-modal-btn",
      }}
    >
      Cancel
    </MondayButton>,
    mode === "EDIT" && (
      <Dropdown
        overlayClassName={`layout-card-dropdown${
          isDarkMode ? " layout-dropdown-dark" : ""
        }`}
        menu={{
          items,
          onClick: onShowLogs,
        }}
      >
        <MondayButton
          className="mondayButtonBlue"
          Icon={<DropdownIcon fill="#fff" />}
        >
          Show Logs
        </MondayButton>
      </Dropdown>
    ),
    <MondayButton
      {...{
        Icon: <TickIcon width={17} height={17} />,
        onClick: onSave,
        disabled:
          (mode === "EDIT"
            ? globalDisabled || !employeeMatch
            : globalDisabled) || loading,
      }}
    >
      Save
    </MondayButton>,
  ];
}

export const employeeKeys = [
  "employeeFullName",
  "employeeId",
  "company",
  "employeeRole",
  "crewTeamName",
  "crewForeman",
  "salaryType",
  "employeeRate",
];

export function getSections({ regularShift }) {
  if (regularShift) {
    return ["Shift Status", "Shift Date & Time", "Shift Address"];
  }
  return ["Shift Status", "Shift Date & Time", "Leave Reason"];
}

export function camelToTitle(str) {
  if (!str || typeof str !== "string") return "";

  let res = str.replace(/([A-Z])/g, " $1");
  return res.charAt(0).toUpperCase() + res.slice(1);
}

export function findTeamForEmployee(crewTeams, crewId, employeeId = "") {
  return crewTeams.find((team) => {
    const parsedEmployeeId = `${employeeId.split("-")[0]}-${parseFloat(
      employeeId.split("-")[1]
    )}`;
    let foremanMatch;

    if (team?.crewForeman?.crewId || team?.crewForeman?.employeeId) {
      foremanMatch =
      team?.crewForeman?.crewId === crewId ||
      team?.crewForeman?.employeeId === employeeId ||
      team?.crewForeman?.employeeId === parsedEmployeeId;
    }

    const membersMatch =
      team?.crewMembers?.some((member) => member?.crewId === crewId) ||
      team?.crewMembers?.some((member) => member?.employeeId === employeeId) ||
      team?.crewMembers?.some(
        (member) => member?.employeeId === parsedEmployeeId
      );
    return foremanMatch || membersMatch;
  });
}

export function shiftNextDayWarning({
  onConfirm = () => {},
  onDecline = () => {},
}) {
  let key = `${Math.random}`;
  return notification.warning({
    message: warningNotificationTitle(null, true),
    key,
    duration: 0,
    placement: "bottomLeft",
    closeIcon: getCloseIcon(() => {
      notification.destroy(key);
      onDecline();
    }),
    description: (
      <>
        <b>Careful, you have chosen conflicting times for the shift</b>
        <p>
          If you choose to continue, this time will be passed on to the next
          date
        </p>
        {getClickPhrase({
          handler() {
            notification.destroy(key);
            onConfirm();
          },
        })}
      </>
    ),
  });
}

function getNextDayTimeLabel(label, condition, required) {
  if (condition) {
    return getLabelHTML("(Next Date)", label, undefined, required);
  }

  return getLabelHTML("", label, undefined, required);
}

export function checkShiftTimeConflicts({ form, picker, time }) {
  /**
   * after we finish setting all the shift time values with the
   * correct times, we want to make sure we don't have any time conflicts
   * we need to get the picker names that are relative to the selected picker
   */
  let prevPickerFields = [];
  let nextPickerFields = [];
  let pickersToClear = {};

  switch (picker) {
    case "clockIn":
      nextPickerFields = ["lunchStart", "lunchEnd", "clockOut"];
      break;
    case "clockOut":
      prevPickerFields = ["clockIn", "lunchStart", "lunchEnd"];
      break;
    case "lunchStart":
      prevPickerFields = ["clockIn"];
      nextPickerFields = ["lunchEnd", "clockOut"];
      break;
    case "lunchEnd":
      prevPickerFields = ["clockIn", "lunchStart"];
      nextPickerFields = ["clockOut"];
      break;
  }

  for (const field of prevPickerFields) {
    let timeToCheck = form.getFieldValue(field);
    if (timeToCheck) {
      timeToCheck = dayjsNY(timeToCheck).valueOf();
      if (timeToCheck > time) {
        pickersToClear[field] = null;
      }
    }
  }

  for (const field of nextPickerFields) {
    let timeToCheck = form.getFieldValue(field);
    if (timeToCheck) {
      timeToCheck = dayjsNY(timeToCheck).valueOf();
      if (timeToCheck < time) {
        pickersToClear[field] = null;
      }
    }
  }

  let objKeys = Object.keys(pickersToClear);
  if (objKeys.length) {
    form.setFieldsValue(pickersToClear);
    let plural = objKeys.length !== 1;

    message.warning({
      content: `${objKeys.length} time ${plural ? "field" : "fields"} ${
        plural ? "were" : "was"
      } cleared to not make time conflicts in the shift`,
      duration: 4,
    });
  }
}

export function findDuplicate(array) {
  let duplicateElements = [];
  for (const el of array) {
    if (array.indexOf(el) !== array.lastIndexOf(el)) {
      duplicateElements.push(el);
    }
  }
  return { hasDuplicates: !!duplicateElements.length, duplicateElements };
}
