import "./EstimationPicker.scss";

import { Radio, Space, Checkbox, Tooltip } from "antd";
import { ChangeOrderIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { useSelector } from "react-redux";
import { getApprovedRentTotal } from "../../../../../../../Estimations/DataEntryGrid/subcomponents/ServiceHeader/ServicePrices/ServicePrices";
import { getRawServiceTotals } from "../../../../../../../Estimations/DataEntryGrid/tools/formatters/totals";
import { forceToNumber } from "../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { currencyFormater } from "../../../../../../../../../utils/currencyFormater";

const EstimationPicker = ({
  estimations,
  chosenData,
  setChosenData,
  setFormChanged = () => {},
}) => {
  const { Group: CheckboxGroup } = Checkbox;

  const estimationsData = !!estimations?.length
    ? estimations
        ?.filter((el) => el?.estSTATUS === "Approved")
        ?.map(
          ({
            estimationId,
            isChangeOrder,
            services = [],
            estimationNumber = "",
            quickbooksNumber = "",
          }) => ({
            estimationId,
            isChangeOrder,
            services,
            estimationNumber: quickbooksNumber || estimationNumber,
          })
        )
    : [];

  const services = !!chosenData?.selectedId
    ? estimations
        ?.find(({ estimationId }) => estimationId === chosenData?.selectedId)
        ?.services?.map((service) => {
          const { label } = service;
          let isHoist = checkIfServiceIsHoist(service);
          let rentalAmount = isHoist
            ? getRawServiceTotals(service)
            : getApprovedRentTotal(service);
          console.log("rentalAmount", rentalAmount);
          return {
            label: `${label} --- Default Rent: ${currencyFormater(
              isHoist ? rentalAmount?.rentTotal : rentalAmount?.approvedRent
            )}`,
            value: `${label}|${chosenData?.selectedId}`,
            // disabled: isScope,
          };
        })
    : [];

  console.log("estimationsData", estimations);

  return (
    <div className="estimationPickerMainContainerRental">
      <div className="estimationPickerContainer">
        <div className="tittle">Pick Estimation</div>
        <div>
          {!!estimationsData?.length ? (
            <Radio.Group
              value={chosenData?.selectedId}
              onChange={(e) => {
                setFormChanged(true);
                setChosenData((previousData) => ({
                  ...previousData,
                  selectedId: e.target.value,
                  service: [],
                }));
              }}
            >
              <Space direction="vertical">
                {estimationsData?.map(
                  (
                    {
                      estimationId,
                      isChangeOrder,
                      services,
                      estimationNumber = false,
                      quickbooksNumber = false,
                    },
                    index
                  ) => (
                    <div className="holderRentalEstimateOpt">
                      <Radio
                        key={estimationId}
                        value={estimationId}
                        style={{
                          width: "720px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                          }}
                        >
                          Estimation #{quickbooksNumber || estimationNumber}
                          {isChangeOrder && (
                            <Tooltip title="Change Order">
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ChangeOrderIcon />
                              </div>
                            </Tooltip>
                          )}
                          {" - "}
                          {getEstimationQuickDesc({
                            structuredDataArr: services,
                          }) || ""}{" "}
                        </div>
                      </Radio>
                    </div>
                  )
                )}
              </Space>
            </Radio.Group>
          ) : (
            <p className="no-schedule-of-values">No Estimations</p>
          )}
        </div>
      </div>
      <div className="servicePickerContainer">
        <div className="tittle">Pick Services</div>
        {!!services?.length ? (
          <CheckboxGroup
            style={{
              width: "720px",
            }}
            options={services}
            onChange={(value) =>
              setChosenData((previousData) => ({
                ...previousData,
                service: value,
              }))
            }
            value={chosenData?.service}
          />
        ) : (
          <p className="no-schedule-of-values">No Services</p>
        )}
      </div>
    </div>
  );
};

export default EstimationPicker;
export const getEstimationQuickDesc = ({ structuredDataArr = [] }) => {
  const { proposedTypeOfWork } = useSelector(
    (state) => state.proposedTypeOfWork
  );
  let res = false;

  if (Array.isArray(structuredDataArr)) {
    let minStart,
      maxEnd = 0;
    structuredDataArr?.forEach((el, i) => {
      if (i === 0) res = "";
      const temp =
        proposedTypeOfWork?.find((p) => p?.workName === el?.label)?.idCode +
        `${i === structuredDataArr?.length - 1 ? "" : ", "}`;
      console.log("temp", temp);
      if (el?.isScope) {
        res += `OS, `;
      } else res += !!temp ? temp : el?.label;
    });
    res =
      !!res && res !== undefined ? (
        res
      ) : (
        <strong style={{ color: "red" }}>
          No services/all already included!
        </strong>
      );
    console.log("my res", res);
    return res !== "undefined" ? res : "OS";
  }
  console.log("estimation quick description", res);
  return res;
};
const checkIfServiceIsHoist = (service) => {
  return service?.serviceId?.toString() === "3" || service?.isHoist === true;
};
