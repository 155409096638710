import { Radio, Space, Checkbox } from "antd";
import "./SchedulingPicker.scss";
import { useState } from "react";
import { groupBy } from "../../../../../../../Accounting/components/utilities";

const SchedulingPicker = ({
  chosenData,
  setChosenData,
  schedulingData,
  setFormChanged = () => {},
  isDarkMode,
}) => {
  const { Group: CheckboxGroup } = Checkbox;

  const [estimationPicked, setEstimationPicked] = useState(false);
  const services =
    !!chosenData?.selectedId && !!estimationPicked
      ? schedulingData
          ?.find(
            ({ scheduleName }) =>
              parseFloat(scheduleName) === chosenData?.selectedId
          )
          ?.toBeScheduled?.map((el) => ({
            label: el?.label,
            value: `${el?.serviceId}-${
              el?.quickbooksNumber || el?.estimationNumber
            }`,
            ...el,
          }))
          .filter((el) =>
            Array.isArray(estimationPicked)
              ? estimationPicked.includes(
                  el?.quickbooksNumber || el?.estimationNumber
                )
              : false
          )
      : // ?.reduce((newUnique, current) => {
        //   if (!newUnique.some((x) => x.value == current.value)) {
        //     newUnique.push(current);
        //   }
        //   return newUnique;
        // }, [])
        [];

  const estimations = [
    ...new Set(
      schedulingData
        ?.find(
          ({ scheduleName }) =>
            parseFloat(scheduleName) === chosenData?.selectedId
        )
        ?.toBeScheduled?.map(
          (el) => el?.quickbooksNumber || el?.estimationNumber
        ) || []
    ),
  ].map((el) => ({
    label: `Estimation #${el || "N/A"}`,
    value: el || "N/A",
  }));

  console.log("chosen data", estimations);

  return (
    <div
      className={`schedulingPickerMainContainer ${
        isDarkMode && "schedulingPickerMainContainerDark"
      }`}
    >
      <div className="schedulingPickerContainer">
        <div className="tittle">Pick Schedule</div>
        {!!schedulingData?.length ? (
          <Radio.Group
            value={chosenData?.selectedId}
            onChange={(e) => {
              setFormChanged(true);
              setChosenData((previousData) => ({
                ...previousData,
                selectedId: e.target.value,
                service: [],
              }));
            }}
          >
            <Space direction="vertical">
              {schedulingData?.map(({ scheduleName, typeOfWork }) => {
                console.log({ scheduleName, typeOfWork });
                return (
                  <div className="holder">
                    <Radio key={scheduleName} value={parseFloat(scheduleName)}>
                      <div className="typeOfWorkContainer">
                        {scheduleName} -
                        <div
                          className={
                            typeOfWork === "Installation"
                              ? "installation"
                              : "removal"
                          }
                        >
                          ({typeOfWork})
                        </div>
                      </div>
                    </Radio>
                  </div>
                );
              })}
            </Space>
          </Radio.Group>
        ) : (
          <p className="no-schedule-of-values">No Schedule</p>
        )}
      </div>
      <div className="servicePickerContainer">
        <div className="tittle">Pick Estimation</div>
        <CheckboxGroup
          value={estimationPicked}
          options={estimations}
          onChange={(value) => {
            console.log("value", value);
            if (Array.isArray(value) && value.length > 0) {
              setEstimationPicked([]);
              setEstimationPicked([value?.[value?.length - 1]]);
            } else {
              setEstimationPicked(value);
            }
          }}
        />
      </div>
      <div className="servicePickerContainer">
        <div className="tittle">Pick Services</div>
        {!!services?.length ? (
          <CheckboxGroup
            value={chosenData?.service}
            options={services}
            onChange={(value) =>
              setChosenData((previousData) => ({
                ...previousData,
                service: value,
              }))
            }
          />
        ) : (
          <p className="no-schedule-of-values">No Services</p>
        )}
      </div>
    </div>
  );
};

export default SchedulingPicker;
