import {
  getTeamCost,
  getWeeksTotal,
  getCostDispersion,
  overheadDistribution,
} from "../../DEG/components/modalComponents/utils";

export const initialChartsLayout = (
  employeePaymentRateAccess = {},
  jobsiteRateAccess = {}
) => {
  return [
    {
      cardKey: "Employees Regular Hours",
      chartData: {
        chartType: "Vertical",
        datasets: [
          {
            backgroundColor: [],
            borderColor: [],
            borderWidth: 2,
            data: [],
            label: "Employees Regular Hours Regular Hours",
            labels: [],
            selectedDataOption: "Regular Hours",
            showDataFor: "Employees",
            type: "bar",
          },
        ],
        labels: [],
        showDataFor: "Employees",
      },
      h: 13,
      i: "Employees Regular Hours",
      minH: 10,
      minW: 3,
      moved: false,
      static: false,
      title: "Employees Regular Hours",
      w: 6,
      x: 0,
      y: 0,
    },
    {
      cardKey: "Jobsites Regular Hours",
      chartData: {
        chartType: "Doughnut",
        datasets: [
          {
            backgroundColor: [],
            borderColor: [],
            borderWidth: 2,
            data: [],
            label: "Jobsites Regular Hours Regular Hours",
            labels: [],
            selectedDataOption: "Regular Hours",
            showDataFor: "Jobsites",
            type: "doughnut",
          },
        ],
        labels: [],
        showDataFor: "Jobsites",
      },
      h: 13,
      i: "Jobsites Regular Hours",
      minH: 10,
      minW: 3,
      moved: false,
      static: false,
      title: "Jobsites Regular Hours",
      w: 6,
      x: 6,
      y: 0,
    },
    employeePaymentRateAccess?.read && {
      cardKey: "Employees Regular Amount",
      chartData: {
        chartType: "Line",
        datasets: [
          {
            backgroundColor: [],
            borderColor: [],
            borderWidth: 2,
            data: [],
            label: "Employees Amount Regular Amount",
            selectedDataOption: "Regular Amount",
            labels: [],
            showDataFor: "Employees",
            type: "line",
          },
        ],
        labels: [],
        showDataFor: "Employees",
      },
      h: 13,
      i: "Employees Regular Amount",
      minH: 10,
      minW: 3,
      moved: false,
      static: false,
      title: "Employees Regular Amount",
      w: 6,
      x: 0,
      y: 13,
    },
    jobsiteRateAccess?.read && {
      cardKey: "Jobsites Regular Amount",
      chartData: {
        chartType: "Vertical",
        datasets: [
          {
            backgroundColor: [],
            borderColor: [],
            borderWidth: 2,
            data: [],
            label: "Jobsites Amount Regular Amount",
            selectedDataOption: "Regular Amount",
            labels: [],
            showDataFor: "Jobsites",
            type: "bar",
          },
        ],
        labels: [],
        showDataFor: "Jobsites",
      },
      h: 13,
      i: "Jobsites Regular Amount",
      minH: 10,
      minW: 3,
      moved: false,
      static: false,
      title: "Jobsites Regular Amount",
      w: 6,
      x: 6,
      y: 13,
    },
    employeePaymentRateAccess?.read && {
      cardKey: "Employees Overhead Amount/Hours",
      chartData: {
        chartType: "Doughnut",
        datasets: [
          {
            backgroundColor: [],
            borderColor: [],
            borderWidth: 2,
            data: [],
            label: "Employees Overhead Amount/Hours Overhead Hours",
            labels: [],
            selectedDataOption: "Overhead Hours",
            showDataFor: "Employees",
            type: "doughnut",
          },
          {
            backgroundColor: [],
            borderColor: [],
            borderWidth: 2,
            data: [],
            label: "Employees Overhead Amount/Hours Overhead Amount",
            labels: [],
            selectedDataOption: "Overhead Amount",
            showDataFor: "Employees",
            type: "pie",
          },
        ],
        labels: [],
        showDataFor: "Employees",
      },
      h: 13,
      i: "Employees Overhead Amount/Hours",
      minH: 10,
      minW: 3,
      moved: false,
      static: false,
      title: "Employees Overhead Amount/Hours",
      w: 6,
      x: 0,
      y: 26,
    },
    jobsiteRateAccess?.read && {
      cardKey: "Jobsites Total Amount",
      chartData: {
        chartType: "Pie",
        datasets: [
          {
            backgroundColor: [],
            borderColor: [],
            borderWidth: 2,
            data: [],
            label: "Jobsites Total Amount Ovh + Reg Amount",
            labels: [],
            selectedDataOption: "Ovh + Reg Amount",
            showDataFor: "Jobsites",
            type: "pie",
          },
        ],
        labels: [],
        showDataFor: "Jobsites",
      },
      h: 13,
      i: "Jobsites Total Amount",
      minH: 10,
      minW: 3,
      moved: false,
      static: false,
      title: "Jobsites Total Amount",
      w: 6,
      x: 6,
      y: 26,
    },
  ].filter(Boolean);
};

export function getDataSets({ jobsites, crews, analytics, analyticsProp }) {
  const employees = _.groupBy(
    crews.filter(
      (crew) => !!analyticsProp?.employeesHoursPerDay?.[crew.employeeId]
    ),
    ({ employeeId }) => employeeId
  );

  const costPerJobsite = getCostDispersion({
    analytics: analyticsProp,
    jobsites,
  });

  const employeesWeekTotals = getWeeksTotal({
    workerList: Object.keys(employees),
    allEntries: analyticsProp?.employeesHoursPerDay || {},
  });

  const ovhDistributed = overheadDistribution({
    rowData: analytics,
    analytics: analyticsProp,
    jobsites,
    crews,
  });

  const teamAnalytics = getTeamCost({
    crews,
    jobsites,
    entries: analytics,
    analytics: analyticsProp,
  });

  let teamLabels = [];
  let jobsiteLabels = [];
  let employeesLabels = [];

  const employeeCardsData = {
    employeesTotalAmount: [],
    employeesWorkHours: [],
    employeesOverheadAmount: [],
    employeesOverheadHours: [],
    employeesRegWithOvhAmount: [],
    employeesRegWithOvhHours: [],
    employeesOvertime: [],
    employeesOvertimeHours: [],
  };

  const jobsiteCardsData = {
    totalJobsiteAmount: [],
    totalJobsiteWorkHours: [],
    jobsiteRegWithOvhAmount: [],
    jobsiteRegWithOvhHours: [],
    jobsiteOvertime: [],
    jobsiteOvertimeHours: [],
  };

  const teamCardsData = {
    teamOvertimeAmount: [],
    teamOvertimeHours: [],
    teamOverheadAmount: [],
    teamOverheadHours: [],
    teamRegularAmount: [],
    teamRegularHours: [],
    teamTotalAmount: [],
    teamTotalHours: [],
  };

  // GET Employees card data
  for (const empId in employeesWeekTotals) {
    const weeks = employeesWeekTotals[empId];

    employeesLabels.push(employees?.[empId][0]?.crewName);

    let tmpTotal = 0;
    let tmpOvhTotal = 0;
    let tmpOvhHours = 0;
    let tmpWorkHours = 0;
    let tmpOvertimeTotal = 0;
    let tmpOvertimeHours = 0;
    let tmpOvhWithRegHours = 0;
    let tmpOvhWithRegAmount = 0;

    for (const nr in weeks) {
      const week = weeks[nr];
      const ovhDistributedWeek = ovhDistributed.employeeTotals[empId][nr];

      tmpTotal = tmpTotal + week?.totalRegAmount || 0;
      tmpOvhTotal = tmpOvhTotal + week?.totalOvhAmount || 0;
      tmpWorkHours = tmpWorkHours + week?.totalWorkHours || 0;
      tmpOvhHours = tmpOvhHours + week?.totalOvhHours || 0;
      tmpOvertimeTotal = tmpOvertimeTotal + week?.weekTypesOvertime || 0;
      tmpOvertimeHours = tmpOvertimeHours + week?.weeksTypeOvertimeHours || 0;

      for (const day in ovhDistributedWeek) {
        const dayTotals = ovhDistributedWeek[day];
        tmpOvhWithRegAmount =
          tmpOvhWithRegAmount + dayTotals?.totalDayAmount || 0;
        tmpOvhWithRegHours =
          tmpOvhWithRegHours + dayTotals?.totalWorkHours || 0;
      }
    }
    Object.assign(employeeCardsData, {
      employeesTotalAmount:
        employeeCardsData.employeesTotalAmount.concat(tmpTotal),
      employeesOverheadAmount:
        employeeCardsData.employeesOverheadAmount.concat(tmpOvhTotal),
      employeesWorkHours:
        employeeCardsData.employeesWorkHours.concat(tmpWorkHours),
      employeesOverheadHours:
        employeeCardsData.employeesOverheadHours.concat(tmpOvhHours),
      employeesRegWithOvhAmount:
        employeeCardsData.employeesRegWithOvhAmount.concat(tmpOvhWithRegAmount),
      employeesRegWithOvhHours:
        employeeCardsData.employeesRegWithOvhHours.concat(tmpOvhWithRegHours),
      employeesOvertime:
        employeeCardsData.employeesOvertime.concat(tmpOvertimeTotal),
      employeesOvertimeHours:
        employeeCardsData.employeesOvertimeHours.concat(tmpOvertimeHours),
    });
  }

  // GET Jobsites card data
  for (const jobsiteData of costPerJobsite) {
    jobsiteLabels.push(jobsiteData.jobsiteName);
    Object.assign(jobsiteCardsData, {
      totalJobsiteAmount: jobsiteCardsData.totalJobsiteAmount.concat(
        jobsiteData.total
      ),
      totalJobsiteWorkHours: jobsiteCardsData.totalJobsiteWorkHours.concat(
        jobsiteData.totalWorkHours
      ),
      jobsiteOvertime: jobsiteCardsData.jobsiteOvertime.concat(
        jobsiteData.totalOvertime
      ),
      jobsiteOvertimeHours: jobsiteCardsData.jobsiteOvertimeHours.concat(
        jobsiteData.totalOvertimeHours
      ),
    });
  }

  for (const jobAddress of jobsiteLabels) {
    const jobWeeks = ovhDistributed.jobsiteTotals[jobAddress];
    let tmpOvhWithRegAmount = 0;
    let tmpOvhWithRegHours = 0;

    for (const weekNr in jobWeeks) {
      const week = jobWeeks[weekNr];
      for (const dayNr in week) {
        const day = week[dayNr];
        tmpOvhWithRegAmount += day?.totalDayAmount || 0;
        tmpOvhWithRegHours += day?.totalWorkHours || 0;
      }
    }
    Object.assign(jobsiteCardsData, {
      jobsiteRegWithOvhAmount:
        jobsiteCardsData.jobsiteRegWithOvhAmount.concat(tmpOvhWithRegAmount),
      jobsiteRegWithOvhHours:
        jobsiteCardsData.jobsiteRegWithOvhHours.concat(tmpOvhWithRegHours),
    });
  }

  // Get teams card data
  for (const teamName in teamAnalytics) {
    const teamData = teamAnalytics[teamName];
    teamLabels.push(teamName);
    Object.assign(teamCardsData, {
      teamOvertimeAmount: teamCardsData.teamOvertimeAmount.concat(
        teamData?.otAmount || 0
      ),
      teamOvertimeHours: teamCardsData.teamOverheadHours.concat(
        teamData?.overtimeHours || 0
      ),
      teamOverheadAmount: teamCardsData.teamOvertimeAmount.concat(
        teamData?.ovhAmount || 0
      ),
      teamOverheadHours: teamCardsData.teamOvertimeHours.concat(
        teamData?.ovhHours || 0
      ),
      teamRegularAmount: teamCardsData.teamRegularAmount.concat(
        teamData?.regAmount || 0
      ),
      teamRegularHours: teamCardsData.teamRegularHours.concat(
        teamData?.regHours || 0
      ),
      teamTotalAmount: teamCardsData.teamTotalAmount.concat(
        teamData?.totalAmount || 0
      ),
      teamTotalHours: teamCardsData.teamTotalHours.concat(
        teamData?.totalHours || 0
      ),
    });
  }

  return {
    teamLabels,
    teamCardsData,
    jobsiteLabels,
    employeesLabels,
    jobsiteCardsData,
    employeeCardsData,
  };
}
