import { useContext, useEffect, useState } from "react";
import { VideoTutorialIcon } from "../../assets/svg";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import NewCategories from "../NewCategories/NewCategories";
import { RedoOutlined, StarFilled } from "@ant-design/icons";
import { message, Tooltip } from "antd";
import ResetModal from "../ResetModal/ResetModal";

const FavouritesCard = () => {
  const { favourites } = useContext(VideoTutorialContext);

  const [openFavVideos, setOpenFavVideos] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videosToRemove, setVideosToRemove] = useState([]);

  const areAllSelected = favourites.length === videosToRemove.length;

  useEffect(() => {
    favourites.length === 0 && setOpenFavVideos(false);
  }, [favourites]);

  const handleClick = () => {
    if (favourites.length === 0) return message.warning("No Video to show");
    setOpenFavVideos((prev) => !prev);
  };

  const handleRemove = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div
        className={`favTutorialsCard ${openFavVideos ? "active" : ""}`}
        onMouseEnter={() => setShowReset(true)}
        onMouseLeave={() => setShowReset(false)}
        onClick={handleClick}
      >
        <StarFilled className="star-icon" />
        <p className="text">
          Click to see your ({favourites.length}) favorite tutorials
        </p>
        {!!showReset && favourites.length ? (
          <Tooltip title="Reset videos">
            <div
              className="delete-container"
              onClick={(e) => {
                e.stopPropagation();
                handleRemove();
              }}
            >
              <RedoOutlined
                className="delete-btn"
                style={{ fontSize: "20px", color: "#fe4c4a" }}
              />
            </div>
          </Tooltip>
        ) : null}
        <VideoTutorialIcon className="videoPlayerIcon" />
      </div>
      {openFavVideos && (
        <NewCategories newVideos={favourites} isFavourite={true} />
      )}
      <ResetModal
        {...{
          setVideosToRemove,
          array: favourites,
          areAllSelected,
          videosToRemove,
          isModalOpen,
          setIsModalOpen,
          modalTitle: "Reset Favorite Tutorials",
          newOrFav: "favourites",
        }}
      />
    </>
  );
};

export default FavouritesCard;
