import "./SessionForceLogout.scss";

const SessionForceLogout = ({ node, onClick, data }) => {
  return (
    <div
      id={node?.rowIndex === 0 ? "forceLogoutButton" : ""}
      style={{ color: "#1264A3", fontWeight: 600, cursor: "pointer" }}
      onClick={() => onClick?.(data)}
    >
      Force Log Out
    </div>
  );
};

export default SessionForceLogout;
