import { Input, Form, DatePicker } from "antd";
import { testKey } from "../DateTimeInput/helpers";
import "./QBDateInput.scss";
import { parseDatePredict, validateDatePredict } from "./helpers";
import { dayjsNY } from "../../../../../components/DateComponents/contants/DayjsNY";
import dayjs from "dayjs";

/**
 * @param {function} customOnChange - it is executed alongside the default onChange handler
 * @param {function} customValidator - it overwrites the default validator it must include a form instance and case for submit that will return a Promise.reject() if the form is invalid and Promise.resolve() if the form is valid
 * @param {string} formItemName - it is used to append a custom string to the form item name example {"dateInputTaskStartDate"}
 * @param {boolean} required - it is used to determine if the form item is required by default it is set to true
 * @param {object} form - it is the form instance that is passed from the parent component
 * @param {boolean} hasCustomValidator - it is used to determine if the form item has a custom validator by default it is set to false
 * @param {boolean} showLabel - it is used to determine if the form item has a label by default it is set to true
 * @param {string} validTime  - it is used to determine if the time is valid by default it is set to "future" it can also be set to "past" or 'all' for no validation
 * @param {boolean} hasDatePicker - it is used to determine if the form item has an antd date picker component built into it or not by default it is set to true
 * @param {function} disabledDate - it is used to disable certain dates on the antd date picker component
 * @param {string} customClassName - it is used to append a custom class name to the form item
 * @param {Date | Timestamp | Array} validationDate - can be a timestamp, date moment object to signify the date that will be compared for validations,
 * in the case of { Array } it will be an array of [start, end, type] where type can be "in" or "out" in meaning that the date should be in the range
 * and out meaning that the date should be out of the range
 */
const QBDateInput = ({
  form,
  required = true,
  isDarkMode = false,
  formItemName = "",
  customClassName = "",
  customOnChange = () => {},
  hasDatePicker = true,
  disabledDate = null,
  validTime = "future",
  defaultValue = null,
  validationDate = null,
  disabled = false,
}) => {
  function onKeyDownHandler(key, value, e, validTime) {
    if (!testKey(key) && key !== "Enter") {
      e.preventDefault();
      return;
    }
    if (key === "Enter") {
      e.preventDefault();
      parseDatePredict({
        value,
        form,
        formItemName,
        customOnChange,
        validTime,
        validationDate,
      });
    }
  }

  return (
    <div
      className={`labeled-qb-input ${customClassName} ${
        isDarkMode && "labeled-qb-input-dark"
      }`}
    >
      {/* {!!showLabel && (
        <div className="qb-label">
          {!!required && <span style={{ color: "red" }}>*</span>}
          {label}
        </div>
      )} */}
      <div className="qb-input">
        <Form.Item
          {...{
            form: form,
            name: formItemName,
            validateTrigger: "onSubmit",
            rules: [
              {
                validator: () => {
                  const value = form.getFieldValue(formItemName);
                  if (value) {
                    return validateDatePredict({
                      value,
                      form,
                      formItemName,
                      customOnChange,
                      validTime,
                      validationDate,
                    });
                  } else {
                    if (required) {
                      return validateDatePredict({
                        value: "",
                        form,
                        formItemName,
                        customOnChange,
                        validationDate,
                        error: "Date is Required",
                      });
                    }
                    return Promise.resolve();
                  }
                },
              },
            ],
            required: required,
          }}
        >
          <Input
            {...{
              placeholder: "MM/DD/YYYY",
              onBlur: (e) => {
                parseDatePredict({
                  value: e.target.value,
                  form,
                  formItemName,
                  customOnChange,
                  validTime,
                  validationDate,
                });
              },
              onKeyDown: (e) => {
                onKeyDownHandler(e?.key, e?.target?.value, e, validTime);
              },
              autoComplete: "off",
              defaultValue,
              disabled,
            }}
          />
        </Form.Item>
        {!!hasDatePicker && (
          <Form.Item
            {...{
              form,
              name: `datePicker${formItemName}`,
            }}
          >
            <DatePicker
              {...{
                placement: "bottomLeft",
                disabledDate:
                  validTime === "all"
                    ? undefined
                    : (current) => {
                        if (disabledDate) {
                          return disabledDate(current);
                        }

                        if (Array.isArray(validationDate)) {
                          const [start, end, type] = validationDate;
                          let toCompare = dayjsNY(current)
                            .startOf("day")
                            .valueOf();
                          if (type === "in") {
                            return (
                              toCompare < dayjsNY(start).startOf("day") ||
                              toCompare > dayjsNY(end).startOf("day")
                            );
                          }

                          if (type === "out") {
                            return (
                              toCompare > dayjsNY(start).startOf("day") &&
                              toCompare < dayjsNY(end).startOf("day")
                            );
                          }
                        } else {
                          if (validTime === "future") {
                            let toCompare = dayjsNY(current)
                              .endOf("day")
                              .valueOf();
                            let dateToValidate = dayjsNY(
                              validationDate || undefined
                            )
                              .startOf("day")
                              ?.valueOf();

                            return toCompare < dateToValidate;
                          }

                          if (validTime === "past") {
                            let toCompare = dayjsNY(current)
                              .startOf("day")
                              .valueOf();

                            let dateToValidate = dayjsNY(
                              validationDate || undefined
                            )
                              .endOf("day")
                              ?.valueOf();

                            return toCompare > dateToValidate;
                          }
                        }
                      },
                onChange: (e) => {
                  customOnChange(dayjs(e)?.format("MM/DD/YYYY"));
                  form.setFieldValue(
                    formItemName,
                    dayjs(e)?.format("MM/DD/YYYY")
                  );

                  // Trigger revalidation and clear error if valid
                  form.validateFields([formItemName]);
                },
                allowClear: false,
                defaultValue: defaultValue && dayjs(defaultValue, "MM/DD/YYYY"),
                dropdownClassName: isDarkMode && "qb-date-picker-dark",
                disabled,
              }}
            />
          </Form.Item>
        )}
      </div>
      <div className="qb-error" id={`qb-error-text${formItemName}`}></div>
    </div>
  );
};

export default QBDateInput;
