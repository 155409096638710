import React, { useRef } from "react";
import DashboardCard from "../DashboardCard/DashboardCard";
import useAutoScrollCards from "./utils/useAutoScrollCards";
import { useMediaQuery } from "react-responsive";

import "./DashboardCardsPanel.scss";

const DashboardCardsPanel = ({
  cardsData = [],
  totalCount,
  gridApi,
  moving,
  yard,
  atJobsite,
  totalFleet,
  setLocations,
  onClick,
  autoScrollCards = false,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const panelRef = useRef(null);
  useAutoScrollCards(panelRef, autoScrollCards, isMobile);

  return (
    <div className="dashboardCardsPanel" ref={panelRef}>
      {totalCount !== undefined ? (
        <DashboardCard
          status="total"
          count={totalCount}
          gridApi={gridApi}
          color="#4791FF"
          onClick={onClick}
        />
      ) : null}

      {cardsData?.map(({ status, count, color, columnName }, i) => (
        <DashboardCard
          {...{
            key: i,
            status,
            count,
            color,
            columnName,
            gridApi,
            moving,
            yard,
            atJobsite,
            totalFleet,
            setLocations,
            onClick,
          }}
        />
      ))}
    </div>
  );
};

export default DashboardCardsPanel;
