import { Modal } from "antd";
import { useState, useContext } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import {
  crewAnalyticsColumns,
  crewAnalyticsDetails,
} from "./crewAnalyticsData";
import DegModalContext from "../DegModalContext";
import { getDistributionForTeams } from "../utils";
import { updateDocumentTitle } from "../../../../../../../../utils";
import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { InfoIconBlue } from "../../../../../../../Header/forms/Scheduling/Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";

const gridOptions = {
  alwaysShowHorizontalScroll: true,
  alwaysShowVerticalScroll: true,
};

const defaultColDef = {
  resizable: true,
  enableColResize: true,
  enableRowGroup: false,
  sortable: true,
  filter: true,
  flex: 1,
  suppressSizeToFit: true,
  minWidth: 130,
  suppressDragLeaveHidesColumns: true,
};

function CrewAnalytics({ open, onCancel }) {
  const { analytics, isDarkMode, crewTeams } = useContext(DegModalContext);

  const [rowData] = useState(
    getDistributionForTeams({ analytics, teams: crewTeams })
  );

  const handleCancel = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    onCancel();
  };

  return (
    <Modal
      {...{
        open,
        centered: true,
        closable: true,
        title: "Teams Cost",
        closeIcon: <XIcon />,
        onCancel: handleCancel,
        ["data-testid"]: "team-cost-modal",
        className: `costDispersionModal ${
          isDarkMode && "costDispersionModalDark"
        }`,
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "Teams Cost" });
        },
        footer: [
          <MondayButton
            key="1"
            Icon={<XIcon />}
            onClick={handleCancel}
            className="mondayButtonRed"
            data-testid="team-cost-modal-close-btn"
          >
            Close
          </MondayButton>,
        ],
      }}
    >
      <div
        className={`cost-dispersion-grid ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        {!rowData?.length ? (
          <div className="info-dispersion">
            <InfoIconBlue />
            <b>
              There need to be shifts with at least one selected SOW in order to
              view results
            </b>
          </div>
        ) : (
          <></>
        )}
        <AgGridReact
          {...{
            rowData,
            gridOptions,
            defaultColDef,
            pagination: false,
            animateRows: true,
            masterDetail: true,
            detailRowHeight: 350,
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            columnDefs: crewAnalyticsColumns(),
            detailCellRendererParams: crewAnalyticsDetails(),
          }}
        />
      </div>
    </Modal>
  );
}

export default CrewAnalytics;
