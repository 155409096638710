import { fetchData } from "../../components/SidebarPages/Fleet/utils";
import { getNearbyPlacesWithDetails } from "../../components/SidebarPages/Projects/ProjectSafetyReport/helpers";

export async function getHospitalsNearby(recordId) {
  if (!recordId) return [];

  const project = await fetchData("projects", `projects/${recordId}`);
  if (!project) return [];

  const coordinates = {
    lat: project?.projectLatitude,
    lng: project?.projectLongitude,
  };

  const fetchHospitals = async (range) => {
    const nearbyHospitals = await getNearbyPlacesWithDetails(
      coordinates,
      range,
      "hospital"
    );

    if (Array.isArray(nearbyHospitals) && nearbyHospitals.length) {
      return nearbyHospitals.map((hospital) => ({
        ...hospital,
        directionImage: {
          ...hospital?.directionImage,
          imageLink: hospital?.directionImage?.imageLink?.split(",")?.[1] || "",
        },
      }));
    } else {
      return fetchHospitals(range * 2);
    }
  };

  return fetchHospitals(1609);
}
