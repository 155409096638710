import { Form, Tooltip } from "antd";

import {
  XIcon,
  TrashIcon,
} from "../../../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../../../commonComponents";
import { chartFields, datasetFormFields } from "../chartCreationModalData";
import { RenderDynamicComponents } from "../../../../../../../Header/forms/Components";
import { getCardAccess } from "../../../../../../../SidebarPages/HrManagement/utils/getAccessRight";

function ChartCreationForm({ form, setTitle }) {
  const showDataFor = Form.useWatch("showDataFor", form);
  const chartMode = Form.useWatch("chartMode", form);

  /**
   *Updates the field of a specific form element in Form.List
   *
   * @param {string} key formFieldName
   * @param {number} index in form list
   * @param {any} value to pass in field
   */
  function updateFormValue(key, index, value) {
    const datasets = form.getFieldValue("datasets");
    datasets[index] = {
      ...datasets[index],
      [`${key}`]: value,
    };
    form.setFieldValue("datasets", datasets);
  }

  function onShowDataForSelect() {
    const datasets = form.getFieldValue("datasets");
    const mode = form.getFieldValue("chartMode");
    for (let i = 0; i < datasets?.length; i++) {
      updateFormValue(`data`, i, null);
      updateFormValue(`chartType`, i, mode === "Round" ? "Pie" : "Line");
    }
  }

  function onChartModeChange() {
    const datasets = form.getFieldValue("datasets");
    const mode = form.getFieldValue("chartMode");
    for (let i = 0; i < datasets?.length; i++) {
      updateFormValue(`chartType`, i, mode === "Round" ? "Pie" : "Line");
    }
  }

  function removeDataSet(remove, field, index) {
    const formCardToRemove = document.getElementById(`dataset-card-${index}`);
    const animation = [
      {
        opacity: 1,
        transform: "translateY(0px)",
        height: "190.28px",
      },
      {
        opacity: 0,
        transform: "translateY(-90px)",
        height: "0px",
        padding: "0px",
        marginTop: "-10px",
      },
    ];
    const timing = {
      duration: 400,
      transition: "ease",
      rangeStart: "0%",
      rangeEnd: "100%",
    };
    formCardToRemove?.animate?.(animation, timing);
    setTimeout(() => {
      remove(field?.name);
    }, 380);
  }

  const formFields = chartFields({
    setTitle,
    onShowDataForSelect,
    onChartModeChange,
  });

  const employeePaymentRateAccess = getCardAccess(
    "Project Cost",
    "Employees",
    "Payment Rate"
  );
  const jobsiteRateAccess = getCardAccess("Project Cost", "Jobsites", "Rate");

  return (
    <Form
      className="chart-form-container"
      data-testid="chart-form-container"
      form={form}
    >
      <div className="main-form-fields">
        {RenderDynamicComponents(formFields, form)}
      </div>
      <Form.List name={"datasets"} list={["data"]}>
        {(fields, { add, remove }) => {
          return (
            <div className="form-cards-container">
              <div className="add-action-container">
                <MondayButton
                  className="mondayButtonBlue"
                  data-testid="add-data-set-btn"
                  disabled={!showDataFor}
                  onClick={add}
                  Icon={<XIcon style={{ rotate: "45deg" }} />}
                >
                  Add Data Set
                </MondayButton>
              </div>
              <div className="dataset-cards-container">
                {fields.map((field, index) => {
                  return (
                    <div
                      className={`dataset-card`}
                      id={`dataset-card-${index}`}
                      key={`dataset-card-${index}`}
                      data-testid="dataset-card"
                    >
                      <div key={field?.key} className="field-container">
                        {RenderDynamicComponents(
                          datasetFormFields({
                            showDataFor,
                            field,
                            chartMode,
                            form,
                            employeePaymentRateAccess,
                            jobsiteRateAccess,
                          }),
                          form
                        )}
                      </div>
                      <div className="card-actions">
                        <Tooltip title="Remove">
                          <MondayButton
                            onClick={() => removeDataSet(remove, field, index)}
                            className="mondayButtonRed"
                            data-testid={`remove-dataset-btn`}
                            Icon={<TrashIcon />}
                          >
                            Remove
                          </MondayButton>
                        </Tooltip>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      </Form.List>
    </Form>
  );
}

export default ChartCreationForm;
