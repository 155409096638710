import {
  getDefaultEndTime,
  getDefaultStartTime,
  getRandomColor,
  getWeatherForDate,
} from "../helpers/creators";
import { v4 as uuidv4 } from "uuid";
import { DayStatus } from "../Subcomponents/DayStatusChangeModal/DayStatuses";
import ScheduleDayNote from "./ScheduleDayNote";
import { extendTimeForSpecialDays } from "../helpers/calculators";
import getWeatherIcon from "../helpers/getWeatherIcon";
import dayjs from "dayjs";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
// import dayjs from "dayjs-timezone";
/**
 * A day which is used for schedule. ScheduleDay contain a lot of information on what is scheduled in that day for that schedule.
 *
 * @property {number} id
 * @property {number} day
 * @property {Date} startDate
 * @property {[]} crews
 * @property {[]} fleet - dispatches
 * @property {[]} weather
 * @property {string} image
 * @property {string} color
 * @property {DayStatus.Confirmed | DayStatus.Postponed | DayStatus.Canceled | DayStatus.Reconfirmed} status
 * @property {{comesFrom: string|undefined, postponedTo: string|undefined}} endDate
 * @property {ScheduleDayNote[]} notes
 *
 * */

export default class ScheduleDay {
  id;
  day;
  startDate;
  endDate;
  crews = [];
  fleet = [];
  weather = [];
  // scheduled = [];
  image = "";
  color = "";
  status = DayStatus?.Confirmed;
  linkedDays = {
    comesFrom: undefined,
    postponedTo: undefined,
  };
  notes = [];

  constructor(
    day = 1,
    startDate = getDefaultStartTime(),
    endDate = getDefaultEndTime(),
    color = getRandomColor()
  ) {
    this.id = uuidv4();
    this.day = day;
    this.startDate = startDate;
    this.endDate = endDate;
    this.color = color;
    this.desiredCrewNr = 0;
    this.scheduled = [];
  }

  decreaseDayIndex() {
    --this.day;
  }

  increaseDayIndex() {
    ++this.day;
  }

  setWeatherImage() {
    // if (this.weather === undefined)
    //   throw new Error("Can't set image if weather is not defined");
    // this.image = this.weather.map(forecast => forecast.shortForecast)[0]
    // console.log(this.weather[0].shortForecast)
    // this.image = this.weather[0]?.icon
    try {
      const shortForecastIcon = getWeatherIcon(
        this?.weather?.[0]?.shortForecast
      );
      this.image =
        shortForecastIcon === "No icon for this usecase"
          ? this?.weather?.[0]?.icon
          : shortForecastIcon;
    } catch (e) {
      console.error("Weather icon could not be set.", e);
      this.image = this?.weather?.[0]?.icon;
    }
  }

  static getNextDate(date) {
    let nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return new Date(nextDay);
  }

  /**
   * @param lastScheduleDay {ScheduleDay}
   * @param fullWeatherArray {[Object]=}
   * */

  // Adds a new schedule day after the last schedule day (USA TIMEZONE)
  static NextScheduleDay(lastScheduleDay, fullWeatherArray) {
    const lastDayIndex = structuredClone(lastScheduleDay?.day + 1);
    const lastScheduleDayStartDate = dayjsNY(lastScheduleDay?.startDate);
    const lastScheduleDayEndDate = dayjsNY(lastScheduleDay?.endDate);
    const startDate = dayjsNY(lastScheduleDayStartDate)
      .add(dayjsNY(lastScheduleDayStartDate).day() === 5 ? 3 : 1, "day")
      .set({
        hour: 7,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    const endDate = dayjsNY(lastScheduleDayEndDate)
      .add(dayjsNY(lastScheduleDayStartDate).day() === 5 ? 3 : 1, "day")
      .set({
        hour: 16,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

    let nextScheduleDay = new ScheduleDay(lastDayIndex, startDate, endDate);

    // nextScheduleDay.crews = lastScheduleDay.crews
    // nextScheduleDay.fleet = lastScheduleDay.fleet

    if (fullWeatherArray) {
      nextScheduleDay.setWeather(fullWeatherArray);
    }
    return nextScheduleDay;
  }

  setWeather(fullWeatherArray) {
    this.weather = getWeatherForDate(fullWeatherArray, this.startDate);
    this.setWeatherImage();
  }

  static scheduleDayFromObject(scheduleDayObject) {
    const scheduleDay = Object.assign(new ScheduleDay(), scheduleDayObject);
    scheduleDay.startDate = new Date(scheduleDayObject?.startDate);
    scheduleDay.endDate = new Date(scheduleDayObject?.endDate);

    return scheduleDay;
  }

  static scheduleDayArrayFromArrayObject(array_of_scheduleDayObject = []) {
    return array_of_scheduleDayObject?.map((scheduleDayObject) =>
      this.scheduleDayFromObject(scheduleDayObject)
    );
  }

  addNote(text, type, mentionedPeople) {
    this.notes.push(new ScheduleDayNote(text, type, mentionedPeople));
  }

  isNotAvailable() {
    return (
      this.status === DayStatus.Canceled || this.status === DayStatus.Postponed
    );
  }

  /**
   * @return {boolean} - if this day is postponed to another day returns true, otherwise false
   * */
  isPostponed() {
    return this.linkedDays?.postponedTo !== undefined;
  }

  /**
   * @return {boolean} - if this day actually comes from a postponed day
   * */
  comesFromPostponed() {
    return this?.linkedDays?.comesFrom !== undefined;
  }

  /**
   * @param {ScheduleDay[]} scheduleDays - a list with schedule days
   * @return {ScheduleDay|undefined} - returns the scheduleDay when this day has been rescheduled, or undefined if that day does not exist in that list
   * We are supposing that developer first checks if this day is postponed, then find where it is postponed (via isPostponed() for example)
   * */
  findWhereIsPostponed(scheduleDays) {
    return scheduleDays?.find(
      (sch) => sch?.id === this?.linkedDays?.postponedTo
    );
  }

  /**
   * @param {ScheduleDay[]} scheduleDays - a list with schedule days
   * @return {ScheduleDay|undefined} - returns the scheduleDay when this day comes from a postponed day, or undefined if that day does not exist in that list
   * We are supposing that developer first checks if this day comes from another day, then find where it is postponed.
   * */
  findWhereComesFrom(scheduleDays) {
    return scheduleDays?.find((sch) => sch?.id === this?.linkedDays?.comesFrom);
  }

  /**
   * If this day is postponed into another day in the list of schedule days given, remove link from this day.
   * this object is not modified, as it is supposed to be deleted
   * @param {ScheduleDay[]} scheduleDays - a list with schedule days
   * */
  removePostponedTo(scheduleDays) {
    const linkedDay = this.findWhereIsPostponed(scheduleDays);
    if (linkedDay) {
      this.linkedDays.postponedTo = undefined;
    } else {
      throw new Error(
        `Day ${this.day} is not postponed into any of these days given.`
      );
    }
  }

  removeComesFromLink(scheduleDays) {
    const originalDay = this.findWhereComesFrom(scheduleDays);
    if (originalDay) {
      originalDay.linkedDays.postponedTo = undefined;
      this.linkedDays.comesFrom = undefined;
      originalDay.status = DayStatus.Confirmed;
    } else {
      throw new Error(
        `Day ${this.day} is not postponed into any of these days given.`
      );
    }
  }

  /**
   * This modifies postponedScheduleDay
   * @param {ScheduleDay} postponedScheduleDay - the day that this day is postponed to
   * */
  linkWithPostponed(postponedScheduleDay) {
    this.linkedDays.postponedTo = postponedScheduleDay.id;
    postponedScheduleDay.linkedDays.comesFrom = this.id;
    postponedScheduleDay.fleet = this.fleet;
    postponedScheduleDay.crews = this.crews;
    postponedScheduleDay.notes = [];
  }

  /**
   * Creates a new schedule day which is used as the postponed of this day. This modifies this day status and this.linkedDays.postponedTo
   * @param {Date} startDate - given start date or by default this.startDate
   * @param {Date} endDate
   * @param {number=} addedTime - optional. If we add this parameter, this will be the amount of time added to both start date and end date.
   * Usually it is generated by 2 timestamps difference. So if we want to create a new day 5 days later we will set startDate and endDate
   * of the scheduleDay we want and addedTime corresponding to 5 days.
   * @return ScheduleDay - the postponed schedule day
   * */
  createAPostponeDay(
    startDate = this.startDate,
    endDate = this.endDate,
    addedTime,
    { skipSaturday = false, skipSunday = false, skipHoliday = false } = {
      skipSaturday: false,
      skipSunday: false,
      skipHoliday: false,
    }
  ) {
    if (addedTime) {
      startDate = new Date(startDate.getTime() + addedTime);
      endDate = new Date(endDate.getTime() + addedTime);
    }

    // console.log(this.startDate.toLocaleDateString(),startDate.toLocaleDateString());

    [startDate, endDate] = extendTimeForSpecialDays({
      startDate,
      endDate,
      skipSaturday,
      skipSunday,
      skipHoliday,
    });

    // console.log(this.startDate.toLocaleDateString(), startDate.toLocaleDateString());

    const postponedDayForThisDay = new ScheduleDay(
      this.day,
      startDate,
      endDate,
      this.color
    );

    //link days with each other
    this.linkWithPostponed(postponedDayForThisDay);

    //change status
    this.status = DayStatus.Postponed;
    return postponedDayForThisDay;
  }

  removePostponedDay(dayStatus) {
    this.linkedDays.postponedTo = undefined;
    this.status = dayStatus || DayStatus.Confirmed;
  }

  /**
   * This will shift startDate and endDate by the amount of time determined by addedTime
   * */
  shiftThisDay(addedTime) {
    this.startDate = new Date(this.startDate.getTime() + addedTime);
    this.endDate = new Date(this.endDate.getTime() + addedTime);
  }
}
