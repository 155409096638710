import { freeRentalPeriod } from "../Footer/FooterFunctions";
import "./AutomaticSchedulingPicker.scss";

import { Radio, Space, Checkbox, Tooltip } from "antd";
import { max } from "lodash";
import dayjs from "dayjs";

const AutomaticSchedulingPicker = ({
  chosenData,
  setChosenData,
  schedulingData,
  setFormChanged = () => {},
}) => {
  const { Group: CheckboxGroup } = Checkbox;

  const services = !!chosenData?.selectedId
    ? schedulingData
        ?.find(
          ({ scheduleName }) =>
            parseFloat(scheduleName) === chosenData?.selectedId
        )
        ?.toBeScheduled?.map((el) => ({
          ...el,
          value: el?.label,
          label: `${el?.label}`,
        }))
        ?.reduce((newUnique, current) => {
          if (!newUnique.some((x) => x.value == current.value)) {
            newUnique.push(current);
          }
          return newUnique;
        }, [])
    : [];

  const automaticSchedule = schedulingData.filter(
    (_, index) => index !== schedulingData?.length - 1
  );

  console.log("Services", services);

  const findDate = (scheduleDays) =>
    max(scheduleDays?.map(({ endDate }) => dayjs(endDate)?.valueOf()));

  const freeRentalEndDate = freeRentalPeriod({
    allSchedules: schedulingData,
    offset: 0,
  });

  return (
    <div className="automaticSchedulingPickerContainer">
      <div className="schedulingPickerContainer">
        <div className="tittle">Pick Schedule</div>
        {!!schedulingData?.length ? (
          <Radio.Group
            value={chosenData?.selectedId}
            onChange={(e) => {
              setFormChanged(true);
              setChosenData((previousData) => ({
                ...previousData,
                selectedId: e.target.value,
                service: [],
              }));
            }}
          >
            <Space direction="vertical">
              {automaticSchedule?.map(
                ({ scheduleName, typeOfWork, scheduleDays }, index) => {
                  const {
                    typeOfWork: nextSchedulingTypeOfWork,
                    scheduleName: nextSchedulingScheduleName,
                    scheduleDays: nextSchedulingScheduleDays,
                  } = schedulingData[index + 1];

                  const startDate = findDate(scheduleDays);
                  const endDate = findDate(nextSchedulingScheduleDays);

                  const hasCollisions =
                    freeRentalEndDate > startDate ||
                    freeRentalEndDate > endDate;

                  return (
                    <div className="holder">
                      <Radio
                        key={scheduleName}
                        value={parseFloat(scheduleName)}
                      >
                        <div className="typeOfWorkContainer">
                          <div className="information">
                            From
                            <Tooltip title={typeOfWork}>
                              <div
                                className={
                                  typeOfWork === "Installation"
                                    ? "installation"
                                    : "removal"
                                }
                              >
                                {scheduleName}
                              </div>
                            </Tooltip>
                            to
                            <Tooltip title={nextSchedulingTypeOfWork}>
                              <div
                                className={
                                  nextSchedulingTypeOfWork === "Installation"
                                    ? "installation"
                                    : "removal"
                                }
                              >
                                {nextSchedulingScheduleName}
                              </div>
                            </Tooltip>
                          </div>
                          <Tooltip
                            title={
                              hasCollisions
                                ? "There are collisions between schedule dates and free rental dates"
                                : "There are not collisions between schedule dates and free rental dates"
                            }
                          >
                            <div className="dateContainer">
                              <div
                                className={
                                  hasCollisions
                                    ? "haveCollision"
                                    : "dontHaveCollision"
                                }
                              >
                                {dayjs(startDate)
                                  ?.add(+(index !== 0), "day")
                                  ?.format("MM/DD/YYYY")}{" "}
                                - {dayjs(endDate)?.format("MM/DD/YYYY")}
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </Radio>
                    </div>
                  );
                }
              )}
            </Space>
          </Radio.Group>
        ) : (
          <p className="no-schedule-of-values">No Schedule</p>
        )}
      </div>
      <div className="servicePickerContainer">
        <div className="tittle"> Pick Services </div>
        {!!services?.length ? (
          <CheckboxGroup
            options={services}
            value={chosenData?.service}
            onChange={(value) =>
              setChosenData((previousData) => ({
                ...previousData,
                service: value,
              }))
            }
          />
        ) : (
          <p className="no-schedule-of-values">No Services</p>
        )}
      </div>
    </div>
  );
};

export default AutomaticSchedulingPicker;
