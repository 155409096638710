import { Tooltip } from "antd";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { getDispatchLocationOptions } from "../../RequestFormModal/requestFormModalData";

const claimDynamicInputFields = ({
  dynamicFields,
  category,
  nameDisabled,
  incident,
  allClaims,
  projects,
  onLocationChange,
  schedulesForDate,
  form,
  subcontractors = [],
  claimTeam,
  onTeamSelect,
  onTeamDeselect,
  isDarkMode,
  proppedType,
}) => {
  if (!dynamicFields) return [];

  const newInputFields = dynamicFields
    ?.filter((el) => el?.claimCategory === category)
    ?.map((el) => {
      if (el.formItemName === "claimNumber") {
        el.disabled = nameDisabled;
        el.formItemName === "claimNumber";
        el.rules = [
          {
            validator: (_, value) => {
              const regex = /^[a-zA-Z0-9]+$/;
              if (regex.test(value)) {
                return allClaims.filter((claim) => claim.claimNumber === value)
                  .length === 0
                  ? Promise.resolve()
                  : Promise.reject("Claim Number already exists");
              } else {
                return Promise.reject(
                  "Claim Number must contain only letters and numbers"
                );
              }
            },
          },
        ];
      } else if (el.formItemName === "claimType") {
        el.disabled = proppedType ? true : false;
      } else if (el.formItemName === "typeOfIncident") {
        el.disabled = true;
        // } else if (el.formItemName === "assignedTo") {
        //   el.type = "select";
        //   el.options = users;
        //   el.showSearch = true;
      } else if (el.formItemName === "claimAddress") {
        el.groupedOptions = getDispatchLocationOptions(
          schedulesForDate,
          projects
        );
        el.onSelect = onLocationChange;
        el.showSearch = true;
      } else if (el.formItemName === "claimDate") {
        el = {
          ...el,
          form,
          validTime: "past",
          isDarkMode,
        };
      } else if (el.formItemName === "time") {
        el = {
          ...el,
          form,
          validTime: "past",
          isDarkMode,
          dateField: "claimDate",
        };
      } else if (
        el.formItemName === "isClaimantAnEmployeeOfCoreOrOfSubcontractor"
      ) {
        el.type = "radio";
      }

      return el;
    });

  if (newInputFields?.some((el) => el?.index)) {
    newInputFields.sort((a, b) => a.index - b.index);
  }
  if (
    form.getFieldValue("isClaimantAnEmployeeOfCoreOrOfSubcontractor") ===
    "Subcontractor"
  ) {
    let checkboxIndex = newInputFields?.findIndex(
      ({ formItemName }) =>
        formItemName === "isClaimantAnEmployeeOfCoreOrOfSubcontractor"
    );
    if (checkboxIndex > -1) {
      let conditionalInputField = {
        type: "select",
        options: subcontractors,
        label: "Company Name",
        formItemName: "companyName",
        showSearch: true,
        required: true,
      };
      newInputFields.splice(checkboxIndex + 1, 0, conditionalInputField);
    }
  } else {
    let conditionalIndex = newInputFields?.findIndex(
      ({ formItemName }) => formItemName === "chooseCompanyName"
    );
    if (conditionalIndex > -1) {
      newInputFields.splice(conditionalIndex, 1);
    }
  }

  if (
    form.getFieldValue("isClaimantAnEmployeeOfCoreOrOfSubcontractor") ===
    "Other"
  ) {
    let checkboxIndex = newInputFields?.findIndex(
      ({ formItemName }) =>
        formItemName === "isClaimantAnEmployeeOfCoreOrOfSubcontractor"
    );
    if (checkboxIndex > -1) {
      let conditionalInputField = {
        type: "input",
        label: "Other Employer Name",
        formItemName: "otherEmployerName",
        // required: true,
      };
      newInputFields.splice(checkboxIndex + 1, 0, conditionalInputField);
    } else {
      newInputFields.push(conditionalInputField);
    }
  }

  return [
    category !== "General" &&
      !!incident?.name && {
        formItemName: "safetyRecord",
        label: "Safety Record",
        className: "defaultInputComponent",
        disabled: true,
        defaultValue: incident?.name,
        type: "input",
        required: false,
      },
    ...newInputFields,
    // {
    //   label: "Team",
    //   formItemName: "claimTeam",
    //   placeholder: "Select team",
    //   type: "select",
    //   customOptions: claimTeam,
    //   mode: "multiple",
    //   onSelect: onTeamSelect,
    //   onDeselect: onTeamDeselect,
    //   required: true,
    //   allowClear: false,
    //   maxTagCount: 2,
    //   maxTagPlaceholder: (e) => {
    //     return (
    //       <Tooltip title={e.map(({ label }) => label).join(", ")}>
    //         <span>{`+ ${e.length} more`}</span>
    //       </Tooltip>
    //     );
    //   },
    // },
  ].filter(Boolean);
};

export default claimDynamicInputFields;
