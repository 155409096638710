import { Select, Button } from "antd";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useRedux } from "../../../../hooks";

import { onOptionClickHandler } from "../../utilities";
import { DropDownArrow } from "../../../../Fleet/components/InputComponent/assets";

import "./AutoComplete.scss";
import { useInitialInvoiceContext } from "../context/InvoiceInitialContext";
import { useCreateInvoiceContext } from "../context/InvoiceModalContext";

const NewIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.361"
    height="19.361"
    viewBox="0 0 19.361 19.361"
    {...{ props }}
  >
    <path
      {...{ props }}
      id="Path_6620"
      data-name="Path 6620"
      d="M22.978,16.064H16.064v6.915a1.383,1.383,0,1,1-2.766,0V16.064H6.383a1.383,1.383,0,1,1,0-2.766H13.3V6.383a1.383,1.383,0,1,1,2.766,0V13.3h6.915a1.383,1.383,0,1,1,0,2.766Z"
      transform="translate(-5 -5)"
      fill="#71CF48"
    />
  </svg>
);

//Used in the label of the drop down
const rowStyle = {
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  display: "flex",
};

//Used in options of the select drop down
const Label = (el) => (
  <div className={"menuItemWrapper"}>
    <div className={"menuItemContainer"}>
      <div className={"column"}>
        <div style={rowStyle}>
          <div>{el.name}</div> <div>{el.data.groupName}</div>
        </div>
      </div>
    </div>
  </div>
);

// Helper function to map product/service data to options format
const mapServiceOption = (el, index) => ({
  label: Label(el),
  value: el.name,
  index: index,
  group: el?.data?.groupName,
  el,
});

// Helper function to check if a string contains another string, case insensitively
const containsIgnoreCase = (string1, string2) =>
  string1.toUpperCase().includes(string2.toUpperCase());

// Helper function to generate the options array based on the group type
const generateOptions = (list, searchValue) => {
  if (searchValue) {
    list = list.filter(
      (el) =>
        containsIgnoreCase(el.name, searchValue) ||
        containsIgnoreCase(el.description, searchValue)
    );
  }
  return list.map(mapServiceOption);
};

export default ({ value, data }) => {
  const { invoiceData } = useInitialInvoiceContext();
  const {
    products: rowData,
    setProducts: setRowData,
    productsList,
  } = useCreateInvoiceContext();

  const [options, setOptions] = useState();
  const [newServiceName, setNewServiceName] = useState();
  const [isWritable] = useRedux("invoiceIsWritable");
  const disabled = !isWritable && invoiceData;
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const rowDataNames = rowData.map((el) => el.serviceId);
  const groupsOfService = {};
  rowData.forEach((el) => (groupsOfService[el.serviceId] = el.group));

  const filteredProductsList = productsList.filter((el) => {
    return !(
      rowDataNames.includes(el.serviceId) &&
      groupsOfService[el.serviceId] === el.data.groupName
    );
  });

  const extraServices = serviceDefinitions.map((service, index) => ({
    id: filteredProductsList.length + index + 1,
    serviceId: Number(service?.serviceId || service?.id),
    name: service.serviceName,
    description: "",
    amount: 0,
    taxAmount: 0,
    amountPercentage: 100,
    tax: false,
    isExtraService: true,
    group: "Extra Service",
    data: { groupName: "Extra Service" },
    total: 0,
  }));

  useEffect(() => {
    const list =
      data?.group !== "Extra Service" ? filteredProductsList : extraServices;
    setOptions(generateOptions(list));
  }, [productsList, rowData]);

  const onSearch = (value) => {
    const list =
      data?.group !== "Extra Service" ? filteredProductsList : extraServices;
    setOptions(generateOptions(list, value));
  };

  const onInput = (value) => {
    setNewServiceName(value?.target?.value);
  };

  const addNewService = () => {
    setRowData((prev) => {
      const changedData = prev?.find(({ id }) => id === data?.id);
      changedData.name = newServiceName;
      return structuredClone(prev);
    });
  };

  const notFoundContent = (
    <Button
      onClick={addNewService}
      className="addNewInvoiceServiceButton"
      disabled={!!!newServiceName}
    >
      <NewIcon /> Add this service
    </Button>
  );

  return (
    <Select
      suffixIcon={<DropDownArrow />}
      value={value}
      onInput={onInput}
      notFoundContent={notFoundContent}
      className="autoCompleteProduct"
      disabled={disabled}
      name="service"
      showSearch
      placeholder="Select a service"
      optionFilterProp="children"
      popupClassName={isDarkMode && "darkDropDown"}
      onSelect={(value, e) => {
        const selectedElement = e.data.el;
        if (selectedElement.data.type === "applications") {
          const services = productsList?.filter(
            ({ data }) =>
              data?.groupName === e?.data?.selectedElement?.data?.groupName
          );
          return onOptionClickHandler({ services, setRowData });
        }

        onOptionClickHandler({ services: [selectedElement], setRowData });
      }}
      onSearch={onSearch}
    >
      {options?.map((option, i) => (
        <Select.Option key={i} value={option.value} data={option}>
          {option.value}
        </Select.Option>
      ))}
    </Select>
  );
};
