import { message, Modal } from "antd";
import { v4 as uuidv4 } from "uuid";
import "../NewChargeItem/NewChargeItems.scss";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import {
  ElevationAgGrid,
  ModalFooter,
  PDFDocuments,
} from "../NewChargeItem/components";
import { useRedux } from "../../../../../hooks";
import { lengthOfItems, updateData } from "../NewChargeItem/utils";
import ApplyCredit from "../ApplyCreditModal/ApplyCredit";
import { numberToFixed2 } from "../../utils";
import { generateDescription } from "../../datas";
import { Buffer } from "buffer";
import { getLength } from "../../../../../Communication/functions";
import EmailBox from "../../../../../Communication/components/EmailBox/EmailBox";

const OtherModal = ({ project, getCreatedCharge }) => {
  const [chargeToSave, setChargeToSave] = useState();
  const [creditApplied, setCreditApplied] = useRedux("creditApplied", [], true);
  const [remainingCredit, setRemainingCredit] = useState(0);
  const [, setChargeFromVisible] = useRedux("newChargeVisible");
  const [appliedCredit, setApliedCredit] = useState(0);
  const [, setChargeToView] = useRedux("chargeToView");
  const [objectData, setVisible] = useRedux("chargeItemsModal");
  const [email, setEmail] = useState(false);
  const [updatedCreditApplied, setUpdatedCreditApplied] = useState([]);
  const [currentCharge, setCurrentCharge] = useRedux("charge");
  const [allCharges] = useRedux("rowData");
  const [rowData, setRowData] = useState();
  const [pdf, setPdf] = useState();
  useEffect(() => {
    if (currentCharge && Object.keys(currentCharge)?.length !== 0) {
      setChargeToSave(currentCharge);
      setCreditApplied(
        currentCharge?.chargeItems
          ?.flatMap(({ serviceOptions }) =>
            serviceOptions?.flatMap(({ items }) => items)
          )
          ?.filter(Boolean)
          ?.flatMap(({ creditFrom }) => creditFrom)
      );
      setUpdatedCreditApplied(
        currentCharge?.chargeItems
          ?.flatMap(({ serviceOptions }) =>
            serviceOptions?.flatMap(({ items }) => items)
          )
          ?.filter(Boolean)
          ?.flatMap(({ creditFrom }) => creditFrom)
      );
      setRemainingCredit(0);
    }
  }, [currentCharge]);
  useEffect(() => {
    API.get("serviceDefinitions", "/serviceDefinitions").then((res) => {
      setRowData(res.filter(({ isScope }) => isScope));
    });
  }, []);

  const setCreditFrom = (value, remaining) => {
    let newData = updateData(remaining, creditApplied);
    setUpdatedCreditApplied(newData);
    let creditFrom = [];
    let indexToStop = newData.length;
    newData.forEach((element, i) => {
      if (i < indexToStop) {
        if (element.applyAmount > parseFloat(value)) {
          creditFrom.push({ ...element, applyAmount: parseFloat(value) });
          setRemainingCredit((prev) => prev - parseFloat(value));
          indexToStop = i;
        } else {
          creditFrom.push({ ...element });
          value -= parseFloat(element.applyAmount);
          setRemainingCredit((prev) => prev - parseFloat(element.applyAmount));
        }
      }
    });
    return creditFrom;
  };

  const chargeItems = chargeToSave?.chargeItems?.map((e) => {
    const { visible, serviceOptions = [], ...rest } = e;
    return {
      ...rest,
      serviceOptions,
      price: serviceOptions?.reduce(
        (a, { items }) =>
          numberToFixed2(
            (a += items?.reduce(
              (acc, { selectedValue = 0, taxAmount }) =>
                numberToFixed2((acc += selectedValue + taxAmount)),
              0
            ))
          ),
        0
      ),
      serviceDescription: generateDescription(serviceOptions, "Other"),
    };
  });
  const showConfirm = () => {
    Modal.confirm({
      title: "Do you want to send charge with email?",
      onOk: () => {
        PDFDocuments({
          chargeItems,
          project,
          getBase64: (e) => {
            setPdf({
              id: uuidv4(),
              name: "Charge",
              type: "application/pdf",
              size: getLength(
                Buffer.from(
                  `data:application/pdf;base64,${e}`.substring(
                    `data:application/pdf;base64,${e}`.indexOf(",") + 1
                  )
                )
              ),
              blob: `data:application/pdf;base64,${e}`,
              status: 400,
            });
            setEmail(true);
          },
        });
      },
      onCancel() {
        removePreviusDataOnDestroy();
      },
    });
  };
  const removePreviusDataOnDestroy = () => {
    setChargeToSave([]);
    setVisible(false);
    setChargeFromVisible(false);
    setChargeToView(false);
    setCurrentCharge(false);
  };
  const saveCharge = () => {
    /**
     * calculate charge Amount
     */
    let chargeAmount = chargeItems?.reduce(
      (acc, { price }) => numberToFixed2((acc += price)),
      0
    );
    if (chargeAmount < 0) {
      message.info(
        "You can not create a charge with negative value. Please check the balance before continue."
      );
    } else {
      API[currentCharge ? "put" : "post"](
        "charges",
        `/charges${currentCharge ? `/${currentCharge?.chargeId}` : ""}`,
        {
          body: {
            chargeItems,
            chargeType:
              objectData.type === "Charge" ? "Regular" : "Credit Memo",
            categoryFrom: "Other",
            chargeAmount,
            projectAddress: project.projectAddress,
            remainingAmount:
              objectData.type === "Charge"
                ? "N/A"
                : parseFloat(
                    currentCharge?.chargeAmount ||
                      0 - currentCharge?.appliedAmount ||
                      0
                  ),
            projectId: project.projectId,
          },
        }
      )
        .then((res) => {
          const { parent = {}, ...rest } = res;
          getCreatedCharge(
            currentCharge
              ? {
                  ...currentCharge,
                  chargeItems,
                  chargeAmount,
                }
              : rest
          );
          message.success("Charge save succesfully");
          showConfirm();
        })
        .catch((e) => {
          message.success("Something happend");
          removePreviusDataOnDestroy();
        });
    }
  };

  return (
    <Modal
      open={objectData}
      title={
        objectData.type === "Charge"
          ? currentCharge
            ? `Edit charge no. ${currentCharge.chargeNumber}`
            : "Add new charge item"
          : currentCharge
          ? `Edit credit item no. ${currentCharge.chargeNumber}`
          : "Add new credit memo item"
      }
      className="newChargeItemModal"
      onCancel={removePreviusDataOnDestroy}
      footer={
        <ModalFooter
          {...{
            sendEmail: () => {
              PDFDocuments({
                chargeItems,
                project,
                getBase64: (e) => {
                  setPdf({
                    id: uuidv4(),
                    name: "Charge",
                    type: "application/pdf",
                    size: getLength(
                      Buffer.from(
                        `data:application/pdf;base64,${e}`.substring(
                          `data:application/pdf;base64,${e}`.indexOf(",") + 1
                        )
                      )
                    ),
                    blob: `data:application/pdf;base64,${e}`,
                    status: 400,
                  });
                  setEmail(true);
                },
              });
            },
            saveCharge,
            removePreviusDataOnDestroy,
          }}
        />
      }
    >
      <div className="newChargeItemContainer">
        <ElevationAgGrid
          {...{
            remainingCredit,
            project,
            setRemainingCredit,
            item: { items: rowData },
            setChargeToSave,
            chargeToSave,
            setApliedCredit,
            updatedCreditApplied,
            setUpdatedCreditApplied,
            saveSelectionsChanges: (e) => {
              setChargeToSave((prev) => ({
                ...prev,
                chargeItems: [
                  ...(prev?.chargeItems?.filter(
                    ({ label }) => label !== "Other Scopes"
                  ) || []),
                  {
                    label: "Other Scopes",
                    serviceOptions: [
                      ...(prev?.chargeItems
                        ?.find(({ label }) => label === "Other Scopes")
                        ?.serviceOptions?.flat(1)
                        ?.filter(
                          ({ elevationLabel }) =>
                            elevationLabel !== "Other Scopes"
                        ) || []),
                      {
                        elevationLabel: "Other Scopes",
                        items: e.api
                          .getModel()
                          .gridOptionsWrapper.gridOptions.rowData.filter(
                            ({ price }) => price
                          )
                          ?.map((data) => {
                            return {
                              ...data,
                              selectedValue: data?.totalPrice,
                            };
                          }),
                      },
                    ],
                  },
                ].filter(
                  ({ serviceOptions }) =>
                    lengthOfItems(serviceOptions, "items", true) !== 0
                ),
              }));
            },
            saveDeselectioChanges: () => {},
          }}
        />
      </div>
      {allCharges && (
        <ApplyCredit
          getAppliedCredit={(e) => {
            if (e) {
              setUpdatedCreditApplied(e);
              setCreditApplied(e);
              setRemainingCredit(
                e?.reduce((acc, curr) => {
                  acc = acc + curr.applyAmount;
                  return acc;
                }, 0) - appliedCredit
              );
            }
          }}
        />
      )}
      {email && (
        <EmailBox
          isFull={true}
          onClose={() => {
            setEmail(false);
            removePreviusDataOnDestroy();
          }}
          setEmails={() => {}}
          emails={[]}
          rowData={{}}
          isFromCharge
          onSave={() => {
            setEmail(false);
            removePreviusDataOnDestroy();
          }}
          chargePdf={[pdf]}
        />
      )}
    </Modal>
  );
};
export default OtherModal;
