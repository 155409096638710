import React, { useMemo } from "react";
import { DocUploader } from "../../../Documentation/View/components/DocumentationModal/components";
import { WarningTriangle } from "../../../DynamicView/src";
import { useSelector } from "react-redux";

/**
 * Renders a section for uploading safety files.
 *
 * @param {string} accessToken - The access token for authentication.
 * @param {string} [objectKey=""] - The object key.
 * @param {Object} [rowObject={}] - The row object.
 * @param {Array} [uploadedFiles=[]] - The array of uploaded files.
 * @param {Object} [driveFunctions={}] - The drive functions.
 * @param {Function} [onDelete=() => {}] - The function to handle file deletion.
 * @param {boolean} [divClickable=false] - Indicates if the div is clickable.
 * @param {Function} [setChanges=() => {}] - The function to set changes.
 * @param {string} [folderDriveKeyName=""] - The folder drive key name.
 * @param {boolean} [disabledAllFields=false] - Indicates if all fields are disabled.
 * @param {Function} onPickerSuccess - The function to handle picker success.
 * @param {Function} [setCancelModalVisible=() => {}] - The function to set the visibility of the cancel modal.
 * @param {boolean} driveFolder - Indicates if the drive folder exists.
 * @param {boolean} hasMissingFolders - Indicates if there are missing drive folders.
 * @param {Function} setAddDriveModal - The function to set the add drive modal.
 * @param {number} setDocumentationModalFiles - State for setting the list of Files that will be added at the Documentation Modal
 * @param {boolean} isCreateDocumentEnabled = false - Params to make the create Documentation icon visible or not
 * @returns {JSX.Element} The rendered component.
 */
function SafetyUploaderSection({
  accessToken,
  objectKey = "",
  rowObject = {},
  uploadedFiles = [],
  driveFunctions = {},
  onDelete = () => {},
  divClickable = false,
  setChanges = () => {},
  folderDriveKeyName = "",
  disabledAllFields = false,
  onPickerSuccess,
  setCancelModalVisible = () => {},
  driveFolder,
  hasMissingFolders,
  setAddDriveModal,
  setDocumentationModalFiles,
  isCreateDocumentEnabled = false,
  handleOnFileUploadSuccess = () => {},
}) {
  return (
    <div className="uploaderSection">
      <div
        style={{
          position: "relative",
        }}
      >
        {(driveFolder === false || hasMissingFolders) && (
          <div
            onClick={() => {
              setAddDriveModal(true);
            }}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              color: "#FE4C4A",
              fontWeight: "600",
              fontSize: "14px",
              gap: 10,
              marginBottom: 20,
            }}
            className="warningMessage"
          >
            <WarningTriangle fill="#FE4C4A" height={15} width={17} />
            <span>Missing Drive Folders</span>
          </div>
        )}
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: divClickable ? 10 : -1,
          }}
          onClick={() => divClickable && setCancelModalVisible("Upload")}
        />
        <DocUploader
          {...{
            uploadedFiles,
            onPickerSuccess({ uploadedFiles = [], updatedFiles = [] }) {
              if (!!updatedFiles?.length) {
                onPickerSuccess({ uploadedFiles, updatedFiles });
              } else {
                setChanges(true);
                onPickerSuccess({ uploadedFiles });
                handleOnFileUploadSuccess(uploadedFiles);
              }
            },
            onDelete: (id) => {
              setChanges(true);
              onDelete(id);
            },
            disabled:
              (!driveFolder && !driveFunctions.parentFolderId) ||
              disabledAllFields ||
              !driveFolder,
            onChange() {},
            folderId: driveFolder || driveFunctions.parentFolderId,
            responsiveWidth: 1400,
            isCreateDocumentEnabled,
            setDocumentationModalFiles,
          }}
        />
      </div>
      {!!rowObject?.[objectKey]?.incidentGoogleDriveUpload &&
        rowObject?.[objectKey]?.incidentGoogleDriveUpload?.length > 0 && (
          <div className="safetyFilesContainer">
            <div className="safetyFilesTitle">
              <hr className="safetyFilesTitleLine" />
              <span className="safetyFilesTitleText">Incident Files</span>
              <hr className="safetyFilesTitleLine" />
            </div>
            <DocUploader
              {...{
                uploadedFiles:
                  rowObject?.[objectKey]?.incidentGoogleDriveUpload,
                onlyUploadedFiles: true,
                checkWarnings: false,
                responsiveWidth: 1400,
                disabled: true,
                setDocumentationModalFiles,
              }}
            />
          </div>
        )}
    </div>
  );
}

export default SafetyUploaderSection;
