import React, {
  useRef,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import { message, Select } from "antd";
import { useSelector } from "react-redux";

import DegModalContext from "../DegModalContext";
import { DropdownIcon } from "../../../../../../../../icons";
import { useEntriesApi } from "../../../../../../PayrollLive/utils";

const STATUS_LIST = ["Draft", "Pending", "Completed"];

const StatusGridEditor = forwardRef(
  ({ value, stopEditing, api, data }, ref) => {
    const { isDarkMode } = useSelector((state) => state.darkMode);
    const {
      rowData,
      setRowData,
      emptyData,
      incorrectData,
      addActionToRegister,
    } = useContext(DegModalContext);
    const { updateEntries } = useEntriesApi();
    const status = useRef(value);

    useImperativeHandle(
      ref,
      () => {
        return {
          getValue() {
            return status.current;
          },
          isCancelBeforeStart() {
            return false;
          },
        };
      },
      []
    );

    function onSelect(event) {
      message?.loading({
        duration: 0,
        key: "onStatusSelect",
        content: "Loading...",
      });
      if (data?.shiftId) {
        let editActions = [];
        let editedEntries = [];
        const updatedRowData = rowData.map((activity) => {
          if (data?.entries.includes(activity?.entryId)) {
            const editedData = { ...activity, activityStatus: event };
            editedEntries.push(editedData);
            editActions.push({ curr: editedData, prev: activity });
            return editedData;
          } else {
            return activity;
          }
        });

        const currentRow = { ...data, shiftStatus: event };

        updateEntries({
          entries: editedEntries,
          onSuccessCallback: () => {
            status.current = event;
            api.applyTransaction({
              update: [currentRow],
            });
            setRowData(updatedRowData);
            addActionToRegister({
              type: "edit",
              editActions,
            });
            message?.success({
              duration: 3,
              key: "onStatusSelect",
              content: "Status updated successfully",
            });
          },
          onErrorCallback: (err) => {
            console.log("Error updating status: ", err);
            message?.error({
              duration: 3,
              key: "onStatusSelect",
              content: "There was a problem updating status for this shift",
            });
          },
        });
      } else {
        const currentRow = {
          ...data,
          activityStatus: event,
        };
        updateEntries({
          entries: [currentRow],
          onSuccessCallback: () => {
            message?.success({
              duration: 3,
              key: "onStatusSelect",
              content: "Status updated successfully",
            });
            setRowData((prev) =>
              prev.map((el) =>
                el?.entryId === currentRow?.entryId ? currentRow : el
              )
            );
            addActionToRegister({
              type: "edit",
              editActions: [{ curr: currentRow, prev: data }],
            });
          },
          onErrorCallback: (err) => {
            console.log("Error updating status: ", err);
            message?.error({
              duration: 3,
              key: "onStatusSelect",
              content: "There was a problem updating status for this entry",
            });
          },
        });
      }
      stopEditing();
    }

    return (
      <Select
        {...{
          onSelect,
          size: "large",
          autoFocus: true,
          defaultOpen: true,
          defaultValue: value,
          suffixIcon: <DropdownIcon />,
          popupClassName: isDarkMode && "darkDropDown",
          className: `gridDropdownSelect ${isDarkMode && "gridSelectDark"}`,
          onDropdownVisibleChange: (dropdownVisible) => {
            if (!dropdownVisible) {
              stopEditing();
            }
          },
          style: {
            width: "100%",
            height: "100%",
            borderRadius: "0px",
          },
          options: STATUS_LIST.map((opt, key) => ({
            key,
            value: opt,
            label: opt,
            disabled:
              opt === "Completed" &&
              emptyData.concat(incorrectData).includes(data?.entryId),
          })),
        }}
      />
    );
  }
);

StatusGridEditor.displayName = "StatusGridEditor";
StatusGridEditor.propTypes = {
  api: PropTypes.object,
  data: PropTypes.object,
  value: PropTypes.object,
  stopEditing: PropTypes.func,
};

export default StatusGridEditor;
