import RentalPayingTerms from "../../Header/Components/ControlPanel/Components/RentalPayingTerms/RentalPayingTerms";
import ChangeOrderIndicator from "../Service/ServiceHeader/Components/ChangeOrderIndicator/ChangeOrderIndicator";
import RetainageModal from "./ServiceHeader/Components/RetainageModal/RetainageModal";
import { DropdownIcon } from "../../../../../../../BasePage/src";
import ServiceContent from "./ServiceContent/ServiceContent";
import { useStateContext } from "../../../Context/Context";
import ServiceHeader from "./ServiceHeader/ServiceHeader";
import ServiceFooter from "./ServiceFooter/ServiceFooter";
import "./hoist-service.scss";

import { useState } from "react";
import { Collapse } from "antd";
import HoistAddons from "./HoistAddons/HoistAddons";

const HoistService = ({
  service,
  borderColor,
  serviceEvents,
  hoistDetails,
  project,
}) => {
  const [activePanel, setActivePanel] = useState([
    ...(service?.serviceOptions?.[0]?.map(
      (elevation) => elevation.elevationId
    ) || []),
  ]);
  const { darkMode } = useStateContext();
  const { isChangeOrder } = service || {};

  return (
    <div
      className={
        darkMode
          ? "hoistServiceContainerDarkMode"
          : "hoistServiceContainerLightMode"
      }
    >
      <Collapse
        activeKey={activePanel}
        onChange={(e) => setActivePanel(e)}
        className={"scheduleOfValuesCollapseContainerHoist"}
        style={{ boxShadow: `0px 0px 6px 3px ${borderColor}` }}
        expandIcon={({ isActive }) => {
          return (
            <div rotate={isActive ? 90 : 0}>
              <DropdownIcon />
            </div>
          );
        }}
      >
        {!!service?.serviceOptions?.[0]?.length &&
          service?.serviceOptions?.[0]?.map((elevation, index) => (
            <Collapse.Panel
              key={elevation.elevationId}
              header={
                <>
                  <div className="service-list-hoist-title">
                    Hoist, {elevation.elevationLabel} Elevation
                    {index === 0 && <RetainageModal {...{ service }} />}
                    {index === 0 && <RentalPayingTerms {...{ service }} />}
                    {index === 0 && !!isChangeOrder && <ChangeOrderIndicator />}
                  </div>
                </>
              }
            >
              <div
                className="serviceListServiceHoist"
                style={{ boxShadow: `0px 0px 6px 3px ${borderColor}` }}
              >
                <ServiceHeader
                  {...{ service, elevation, borderColor, serviceEvents }}
                />
                <ServiceContent
                  {...{
                    service,
                    elevation,
                    borderColor,
                    serviceEvents,
                    hoistDetails,
                    project,
                  }}
                />
                <ServiceFooter
                  {...{
                    elevation,
                    events: [
                      ...serviceEvents?.listOfEvents,
                      ...(hoistDetails?.[service.estimationId]
                        ?.find(
                          (el) => el?.elevationId === elevation?.elevationId
                        )
                        ?.jumps?.map((jump) => jump.name) || []),
                    ],
                  }}
                />{" "}
                {elevation?.areServiceAddons && (
                  <HoistAddons
                    {...{
                      elevation,
                      service,
                      darkMode,
                    }}
                  />
                )}
              </div>
            </Collapse.Panel>
          ))}
      </Collapse>
    </div>
  );
};

export default HoistService;
