import { CalendarIcon, ClockIcon } from "../../FilterView/FilterIcons";
import { CircleFilterFunctions } from "./circleProgresBarData";
import { Menu, Dropdown, Button, Select } from "antd";
import { DatePicker } from "antd";
import {
  datePickerProgressHandler,
  dateRangeDateProgressHandler,
  filterByProjectManagerHandler,
  filterOptions,
  getDashboardRangePresets,
  pmTables,
} from "../filtersData";
import { DropdownIcon } from "../../../../BasePage/src";
import { compareObjects } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import dayjs from "dayjs";
import { getRangePresets } from "src/components/SidebarPages/Fleet/components/InputComponent/utils";
import ProjectManagerFilter from "../ProjectManagerFilter";
import StatusFilter from "../StatusFilter";
import AddressFilter from "../AddressFilter";
import { useMemo } from "react";

const CircleProgresBar = ({
  params,
  id,
  dynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  setRowData,
  filterStates,
  setFilterStates,
  clearFilterChart,
  tmpFilterStates,
  defaultFilterStates,
}) => {
  const url = window.location.pathname.split("/")[1];

  // const circleProgressPicker = params?.content;
  const table = params?.table;

  const filterValueTurnery = compareObjects(
    tmpFilterStates,
    defaultFilterStates
  );

  const rangeToDisplay = useMemo(() => {
    if (filterValueTurnery) {
      if (Array.isArray(filterStates?.filterByRange)) {
        return [
          dayjs(filterStates?.filterByRange?.[0]),
          dayjs(filterStates?.filterByRange?.[1]),
        ];
      }

      return undefined;
    } else {
      if (Array.isArray(tmpFilterStates?.filterByRange)) {
        return [
          dayjs(tmpFilterStates?.filterByRange?.[0]),
          dayjs(tmpFilterStates?.filterByRange?.[1]),
        ];
      }

      return undefined;
    }
  }, [
    filterStates?.filterByRange,
    tmpFilterStates?.filterByRange,
    filterValueTurnery,
  ]);
  return (
    <>
      <div className="filterComponents">
        <div className="tittle">Filter date</div>
        <div className="filterContainer">
          <div className="leftSideContainer">
            <div className="tittleContainer">Filter time</div>
            <div className="tittleContainer">Filter range</div>
            <div className="tittleContainer">Filter date</div>
          </div>
          <div className="rightSideContainer">
            <Dropdown
              menu={{
                style: { borderRadius: "10px" },
                items: filterOptions(
                  setUpdatedDynamicStates,
                  updatedDynamicStates,
                  id,
                  dynamicStates,
                  setFilterStates,
                  table
                ).map((a, index) => ({
                  key: index,
                  label: a?.value,
                  onClick: () => {
                    a?.filter();
                    a?.buttonName();
                  },
                })),
              }}
              placement="bottomCenter"
              suffixIcon={<CalendarIcon />}
              className="filter-dropdown"
              arrow
            >
              <Button>
                {filterValueTurnery
                  ? filterStates?.filterByTime
                  : tmpFilterStates?.filterByTime}
                <ClockIcon />
              </Button>
            </Dropdown>

            <DatePicker.RangePicker
              // value={
              //   filterValueTurnery
              //     ? filterStates?.filterByRange
              //     : tmpFilterStates?.filterByRange
              // }
              value={rangeToDisplay}
              disabledDate={(current) => {
                return current > dayjs().endOf("day");
              }}
              allowClear={false}
              format="MM/DD/YYYY"
              suffixIcon={<CalendarIcon />}
              presets={
                url === "dashboard"
                  ? getDashboardRangePresets()
                  : getRangePresets()
              }
              onChange={(e) =>
                dateRangeDateProgressHandler(
                  e,
                  setUpdatedDynamicStates,
                  updatedDynamicStates,
                  dynamicStates,
                  id,
                  setFilterStates
                )
              }
              className="filter-by-range-picker"
              popupClassName="filter-by-date-picker-dropdown"
            />

            <DatePicker
              value={
                filterValueTurnery
                  ? filterStates?.filterByDay
                  : tmpFilterStates?.filterByDay
              }
              allowClear={false}
              format="MM/DD/YYYY"
              suffixIcon={<CalendarIcon />}
              onChange={(e) =>
                datePickerProgressHandler(
                  e,
                  setUpdatedDynamicStates,
                  updatedDynamicStates,
                  dynamicStates,
                  id,
                  setFilterStates
                )
              }
              disabledDate={(current) => {
                return current > dayjs().endOf("day");
              }}
              className="filter-by-date-picker"
              popupClassName="filter-by-date-picker-dropdown"
            />
          </div>
        </div>

        <AddressFilter
          {...{
            id,
            table,
            filterStates,
            tmpFilterStates,
            filterValueTurnery,
            setFilterStates,
            dynamicStates,
          }}
        />

        {pmTables?.[table] ? (
          <ProjectManagerFilter
            {...{
              id,
              table,
              filterStates,
              dynamicStates,
              tmpFilterStates,
              filterValueTurnery,
              setFilterStates,
              setUpdatedDynamicStates,
            }}
          />
        ) : null}
        <StatusFilter
          {...{
            table,
            filterStates,
            tmpFilterStates,
            filterValueTurnery,
            setFilterStates,
          }}
        />
      </div>
    </>
  );
};

export default CircleProgresBar;
