import { MinusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import NewBIN from "../components/NewBIN";

/**
 * Creates a binObject with the specified properties.
 *
 * @param {Array} BIN - The BIN array.
 * @param {Function} setBIN - The function to set the BIN.
 * @param {boolean} globalDisabled - Indicates if the binObject is globally disabled.
 * @param {Object} selectedProject - The selected project.
 * @param {Function} setFieldValue - The function to set the field value.
 * @param {boolean} allFieldsDisabled - Indicates if all fields are disabled.
 * @param {Function} onVerifyBinClick - The function to handle the verify BIN click event.
 * @returns {Object} The binObject.
 */
const binObject = ({
  BIN = [],
  setBIN = () => {},
  globalDisabled = false,
  selectedProject = null,
  setFieldValue = () => {},
  allFieldsDisabled = false,
  onVerifyBinClick = () => {},
}) => {
  return {
    label: "BIN",
    customLabel: (
      <div style={{ position: "relative", width: "100%" }}>
        BIN
        <Button
          {...{
            className: "innerInputBtn",
            type: "primary",
            onClick: onVerifyBinClick,
            style: { position: "absolute", right: 0 },
            "data-testid": "verify-bin-button",
          }}
        >
          Verify BIN
        </Button>
      </div>
    ),

    placeholder: `${BIN?.length || ""} BIN`,
    type: "select",
    formItemName: "bin",
    disabled: globalDisabled || allFieldsDisabled,
    typeNumber: true,
    // options: BIN,

    customOptions:
      Array.isArray(BIN) &&
      BIN?.map((el, i) => ({
        label: (
          <>
            {el}{" "}
            <MinusOutlined
              onClick={(e) => {
                setFieldValue("bin", undefined);
                e.stopPropagation();
                setBIN((prev) => prev?.filter((b) => b !== el));
              }}
              style={{
                color: "red",
                position: "absolute",
                right: 0,
                paddingRight: "18px",
                top: "35%",
                display: allFieldsDisabled || globalDisabled ? "none" : "",
              }}
            />
          </>
        ),
        value: el,
      })),

    style: { minWidth: 170, width: 170 },

    dropdownRender: (menu) => (
      <div>
        {menu}
        <Divider style={{ margin: "4px 0" }} />
        {selectedProject && (
          <NewBIN
            {...{
              setBIN,
              BIN,
            }}
          />
        )}
      </div>
    ),
  };
};

export default binObject;
