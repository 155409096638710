import { Button, Modal, Spin, Tooltip, message } from "antd";
import { CrewSelectView } from "./CrewSelectView";
import React, { useEffect, useMemo, useState } from "react";
import { TrucksSelectView } from "./TrucksSelectView";
import _, { set } from "lodash";
import TrucksModal from "./newModals/TrucksModal/TrucksModal";
import ScheduleDispatchModal from "./newModals/TrucksModal/ScheduleDispatchModal";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import CrewsModal from "./newModals/CrewsModal/CrewsModal";
import { requestAllPages } from "../../../../../../utils/paginationFetchData";
import { fetchData } from "../../../../../SidebarPages/Fleet/utils";
import LoadableComp from "../../../../../SidebarPages/XComponents/LoadableComp";
import { useRedux } from "../../../../../SidebarPages/hooks/useRedux";
import { getTrucksTooltipTitle } from "./newModals/TrucksModal/HelpingComponents/utils";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  logicChangeCrews,
  swalToast,
} from "../../SchedulingFirstPage/helperData";
import { lazyFetch } from "../../../../../../utils";
import { API } from "aws-amplify";
// import { API } from "aws-amplify";

/**
 * fleetActivity
 *
 * activityDate: Day we are planning
 * startingTime: time that truck is planned to move. scheduled
 * timeAtLocation: Time that truck arrives in job site. scheduled, can be updated
 * timeScheduled: Time fleet will stay in job site. can be scheduled, can be updated
 * timeExitingTheLocation: Time when truck will leave the location. can be scheduled, can be updated
 * */

/**
 * @param props.type {"crew"|"trucks"} - is this used for crew or trucks selection table
 * */
export const CrewTrucksSelectPopover = (props) => {
  let {
    dayIndex,
    scheduleDays,
    setScheduleDays,
    scheduleDay,
    type,
    scheduleAddress,
    typeOfWork,
    fleetInfo,
    className,
    tip,
    setAbleToSave,
    editMode,
    setToBeScheduled,
    toBeScheduled,
    scheduleId,
    dispatchForSchedule,
    thisProjectSchedules,
    updateScheduleCrews,
    scheduleCrews,
    createdAt,
    newCrewsUpdate,
  } = props;

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);
  const [crewsInfo, setCrewsInfo] = useState({});

  const [inputFieldsPopover, setInputFieldsPopover] = useState(scheduleDays);
  const [visible, set_visible] = useState(false);
  const [trucksTooltip, setTrucksTooltip] = useState("");
  const [length, setLength] = useState(0);
  const [isWritable] = useRedux("isWritableScheduling");
  const [listOfTeams, setListOfTeams] = useState([]);

  const onPopoverVisibilityChanges = (
    visibility,
    confirmed,
    scheduleDay,
    dayIndex,
    type
  ) => {
    if (confirmed === true) {
      const scheduleDays_copy = _.cloneDeep(scheduleDays);
      scheduleDays_copy[dayIndex] = scheduleDay;
      setScheduleDays(scheduleDays_copy);
      swalToast({
        icon: "success",
        title: "Saved successfully!",
        timer: 3500,
        position: "bottom-right",
        showConfirmButton: false,
      });
    }
    set_visible(visibility);
    setAbleToSave(true);
  };

  useEffect(() => {
    //if input fields are changed from somewhere else
    if (!visible) return;
    setInputFieldsPopover(scheduleDays);
    setTrucksTooltip(getTrucksTooltipTitle(scheduleDay["fleet"]));
  }, [scheduleDays]);

  const styledBtn = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 25,
    height: 25,
    background: !className ? "#ffffff" : "#1264a3",
    borderRadius: 5,
    color: className ? "#fff" : "black",
    border: "none",
  };
  const typeLabel =
    type === CREW_FLEET_POPOVER_TYPE.CREWS ? "Crew Members" : "Trucks";
  const startDateFormat = dayjsNY(scheduleDay?.startDate).format(
    "MM/DD/YYYY h:mm a"
  );
  const endDateFormat = dayjsNY(scheduleDay?.endDate).format("h:mm a");
  const label = `${typeLabel} - ${startDateFormat} - ${endDateFormat}`;

  useMemo(() => {
    if (type === CREW_FLEET_POPOVER_TYPE?.CREWS) {
      let crewsNr = 0;
      if (!newCrewsUpdate) {
        crewsNr = scheduleDays?.[dayIndex]?.crews?.length || 0;
      } else {
        crewsNr =
          scheduleCrews?.filter((crew) =>
            crew?.days?.some((day) => day?.dayId === scheduleDay?.id)
          )?.length || 0;
      }
      setLength(crewsNr);
    } else if (type === CREW_FLEET_POPOVER_TYPE.FLEET) {
      let filterDispatches = dispatchForSchedule?.filter(
        (dispatch) =>
          dayjsNY(dispatch.dispatchDate).valueOf() ===
          dayjsNY(scheduleDay.startDate).startOf("day").valueOf()
      );
      setLength(filterDispatches?.length || 0);
    }
  }, [scheduleDays, dispatchForSchedule]);

  useEffect(() => {
    if (!!visible && !crewsInfo?.crews?.length) {
      const fetchData = async () => {
        try {
          const [crews, crewTeams] = await Promise.all([
            lazyFetch({
              tableName: "crews",
              listOfKeys: [
                "crewId",
                "crewName",
                "crewPosition",
                "accountName",
                "crewStatus",
                "foreman",
                "dynamicSelection",
              ],
            }),
            API.get("crewTeams", "/crewTeams"),
          ]);

          const crewActivity = [];
          const crewInfo = { crews: crews || [], crewActivity };

          setCrewsInfo(crewInfo);
          setListOfTeams(crewTeams);

          swalToast({
            icon: "success",
            title: "Crews and teams loaded successfully!",
            timer: 3500,
            position: "bottom-right",
            showConfirmButton: false,
          });
        } catch (err) {
          message.error("Error while loading crews and teams!");
          console.log(err);
        }
      };

      fetchData();
    }
  }, [visible]);

  return (
    <>
      {type === "CREWS" && !crewsInfo?.crews?.length && visible ? (
        <Spin
          spinning={!crewsInfo?.crews?.length}
          fullscreen
          tip="Loading Crews..."
        />
      ) : (
        visible && (
          <LoadableComp loading={false}>
            <Modal
              open={true}
              onCancel={() => {
                set_visible(false);
              }}
              title={label}
              closeIcon={<XIcon />}
              footer={false}
              //   className={type === "CREWS" ? "crewsModalSchedule" : ""}
              className={`crew-fleet-popover ${
                isDarkMode && "crew-fleet-popover-dark"
              }`}
              maskClosable={false}
              centered
              //   width={type === "CREWS" ? 1400 : ""}
            >
              {type === CREW_FLEET_POPOVER_TYPE.CREWS ? (
                <CrewsModal
                  {...{
                    dayIndex,
                    crewsInfo,
                    scheduleDay,
                    scheduleAddress,
                    onPopoverVisibilityChanges,
                    setAbleToSave,
                    setToBeScheduled,
                    toBeScheduled,
                    scheduleId,
                    isDarkMode,
                    thisProjectSchedules,
                    updateScheduleCrews,
                    scheduleCrews,
                    createdAt,
                    newCrewsUpdate,
                    listOfTeams,
                    scheduleDays,
                  }}
                />
              ) : (
                <ScheduleDispatchModal
                  {...{
                    scheduleId,
                    dayIndex,
                    scheduleDay,
                    scheduleAddress,
                    typeOfWork,
                    onPopoverVisibilityChanges,
                    setVisible: set_visible,
                    allFleetActivities: fleetInfo?.fleetActivity,
                    scheduleDays,
                    setScheduleDays,
                    isDarkMode,
                  }}
                />
              )}
            </Modal>
          </LoadableComp>
        )
      )}

      <div>
        <Tooltip title={tip ? "Crews" : trucksTooltip}>
          <Button
            onClick={() => {
              return editMode
                ? isWritable
                  ? set_visible(true)
                  : ""
                : set_visible(true);
            }}
            style={styledBtn}
          >
            {length}
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export const CREW_FLEET_POPOVER_TYPE = { CREWS: "CREWS", FLEET: "FLEET" };
