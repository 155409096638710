function updateReportToolbar(ref, emailButton, designOnClick = () => {}) {
  var designButton = {
    key: "$openDesigner",
    text: "Edit in Designer",
    iconCssClass: "mdi mdi-pencil",
    enabled: true,
    action: designOnClick,
  };
  ref.current?.Viewer.toolbar.addItem(designButton);
  ref.current?.Viewer.toolbar.addItem(emailButton);
  ref.current?.Viewer.toolbar.updateLayout({
    default: [
      "$openDesigner",
      "$split",
      "$navigation",
      "$split",
      "$refresh",
      "$split",
      "$history",
      "$split",
      "$zoom",
      "$fullscreen",
      "$split",
      "$print",
      "$split",
      "$singlepagemode",
      "$continuousmode",
      "$galleymode",
      "$emailButton",
    ],
  });
}

export default updateReportToolbar;
