async function incidentReportFormatter(data, driveRequest) {
  return Promise.all(
    data.map(async (report) => {
      const { googleDriveUpload = [] } = report;
      const driveImages =
        googleDriveUpload?.filter(
          ({ type = "", mimeType = "" }) =>
            type === "photo" || mimeType?.includes("image")
        ) || [];

      let images = [];
      if (driveImages?.length) {
        images = await Promise.all(
          driveImages.map(async (file) => {
            const { id } = file;
            const { src } = await driveRequest?.getImageSrc(id);
            return { ...file, src: src.split(",")[1] };
          })
        );
      }
      return {
        ...report,
        googleDriveUpload: images,
      };
    })
  );
}

export default incidentReportFormatter;
