import { Form, message, Modal, Radio } from "antd";
import "./FolderAccessChanger.scss";
import { XIcon } from "../../../../../../Communication/assets";
import {
  AvatarImage,
  HoverButton,
  MondayButton,
} from "../../../../../../../commonComponents";
import AccessRadioGroup from "../../../../../../../pages/Settings/settingsComponents/Management/EditUser/components/AccessRadioGroup/AccessRadioGroup";
import "src/components/pages/Settings/settingsComponents/Management/EditUser/components/AccessRadioGroup/AccessRadioGroup.scss";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { InputComponent } from "../../../../../../Fleet/components";
import { cloneDeep } from "lodash";
import { TickIcon } from "../../../../../../../InternalChat/assets/icons";
import { driveApi } from "../../../../../../../../integrations/DriveRequest";

function FolderAccessChanger({
  visible,
  setVisible,
  permissionList,
  folderId,
  refreshPermissions,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const driveRequest = driveApi({ accessToken });

  const [newUser, setNewUser] = useState({});
  const [permissionListChanged, setPermissionListChanged] = useState(
    cloneDeep(permissionList)
  );

  const allUsers = userConfiguration?.allUsers?.Items;
  const userObj = allUsers?.reduce((acc, el) => {
    acc[el.userName] = el;
    return acc;
  }, {});
  const [form] = Form.useForm();

  const addNewOptions = useMemo(() => {
    return allUsers
      ?.filter(
        (el) =>
          !!el.userName &&
          !permissionListChanged?.find((p) => p.emailAddress === el.userName)
      )
      .map((el) => ({
        label: el?.nameOfUser,
        value: el?.userName,
      }));
  }, [allUsers, permissionListChanged]);

  let radioOptions = [
    { label: "Reader", value: "reader" },
    { label: "Writer", value: "writer" },
  ];

  async function handleSave() {
    if (!folderId) {
      message.error("Folder ID not found.");
      return;
    }
    const removeUnchanged = permissionListChanged.filter((p) => {
      if (!p.id && p.role === "none") {
        return false;
      }
      const oldP = permissionList.find(
        (p2) => p2.emailAddress === p.emailAddress
      );

      return oldP?.role !== p.role;
    });

    if (!removeUnchanged.length) {
      message.info("No changes made.");
      return;
    }
    await Promise.all(
      removeUnchanged.map((p) => {
        if (p.id && p.role === "none") {
          return driveRequest.deletePermission(folderId, p.id);
        }
        if (!p.id) {
          return driveRequest.share(
            folderId,
            p.role,
            "user",
            p.emailAddress,
            true
          );
        }
        return driveRequest.updatePermission(folderId, p.role, p.id);
      })
    )
      .then(() => {
        message.success("Changes saved successfully.");
        refreshPermissions();
        setVisible(false);
      })
      .catch((error) => {
        console.error({ error });
        message.error("An error occurred while saving changes.");
      });
    console.log({ removeUnchanged });
  }

  return (
    <Modal
      {...{
        open: visible,
        onCancel: () => setVisible(false),
        centered: true,
        title: "Folder Access",
        className: `folderAccessChanger ${
          isDarkMode ? "folderAccessChanger-dark" : ""
        }`,
        footer: (
          <>
            <MondayButton
              {...{
                Icon: <XIcon />,
                onClick: () => setVisible(false),
                className: "mondayButtonRed",
              }}
            >
              Cancel
            </MondayButton>
            <MondayButton
              {...{
                Icon: <TickIcon />,
                onClick: () => handleSave(),
              }}
            >
              Confirm
            </MondayButton>
          </>
        ),
        maskClosable: false,
        closeIcon: <XIcon />,
      }}
    >
      <Form form={form} layout={"horizontal"}>
        {!!permissionListChanged?.length ? (
          permissionListChanged
            ?.filter((el) => el.role !== "owner")
            ?.map((el, i) => {
              const currUser = userObj?.[el?.emailAddress] || {};
              return (
                <AccessRadioGroup
                  key={el?.emailAddress + i}
                  {...{
                    title: currUser?.nameOfUser || el?.emailAddress,
                    isDarkMode,
                    disabled: false,
                    defaultValue: el?.role,
                    onChange: (e) => {
                      setPermissionListChanged((prev) => {
                        const clone = [...prev];
                        return clone.map((p) =>
                          p.emailAddress === el.emailAddress
                            ? { ...p, role: e.target.value }
                            : p
                        );
                      });
                    },
                  }}
                />
              );
            })
        ) : (
          <div>No one has access to this folder yet.</div>
        )}
      </Form>
      <div
        className={`radio-group-wrapper drive-access-radio-group add-new-container ${
          isDarkMode && "radio-group-wrapper-dark"
        }`}
      >
        <InputComponent
          {...{
            type: "select",
            customOptions: addNewOptions,
            placeholder: "Select User",
            style: { width: "50%" },
            onSelect: (e) => {
              console.log(e);
              setNewUser({ ...newUser, emailAddress: e });
            },
            initialValue: newUser?.emailAddress || "",
            noFormItem: true,
          }}
        />

        <div className="ant-form-item add-new-right">
          <Radio.Group
            onChange={(e) => {
              console.log(e);
              setNewUser({ ...newUser, role: e.target.value });
            }}
            value={newUser?.role || ""}
            options={radioOptions}
          />

          <HoverButton
            {...{
              text: "Save",
              disabled: !newUser?.emailAddress || !newUser?.role,
              onClick: () => {
                setPermissionListChanged([...permissionListChanged, newUser]);
                setNewUser({});
              },
              type: "new",
              alwaysShowIcon: true,
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default FolderAccessChanger;
