import ChangeRetenageModal from "./Components/ChangeRetenageModal/ChangeRetenageModal";
import { DropdownIcon } from "../../../../../../../BasePage/src";
import ServiceContent from "./ServiceContent/ServiceContent";
import { useStateContext } from "../../../Context/Context";
import ServiceHeader from "./ServiceHeader/ServiceHeader";
import ServiceFooter from "./ServiceFooter/ServiceFooter";
import "./hoist-service.scss";

import { useSelector } from "react-redux";
import { useState } from "react";
import { Collapse } from "antd";
import ServiceCredit from "../Service/ServiceCredit/ServiceCredit";
import { useRedux } from "../../../../../../../hooks";

const HoistService = ({ service, childKey }) => {
  const [activePanel, setActivePanel] = useState([
    service?.serviceOptions?.[0]?.[0]?.elevationId,
  ]);
  const [{ proposedTypeOfWork }] = useSelector((state) => [
    state.proposedTypeOfWork,
  ]);

  const { darkMode } = useStateContext();
  const { applications } = useSelector((state) => state.applications);

  const color = proposedTypeOfWork.find(
    (a) => a?.workName?.toLowerCase() === service.label.toLowerCase()
  )?.colorCode;

  // console.log("Hoist service", service);

  return (
    <div
      className={
        darkMode ? "hoistDivContainerDarkMode" : "hoistDivContainerLightMode"
      }
      style={{ boxShadow: `0px 0px 6px 3px ${color}` }}
      key={childKey}
    >
      <Collapse
        activeKey={activePanel}
        onChange={(e) => setActivePanel(e)}
        className="serviceListHoistCollapsePanel"
        expandIcon={() => <DropdownIcon />}
      >
        {!!service?.serviceOptions?.[0]?.length &&
          service?.serviceOptions?.[0]?.map((elevation, index) => (
            <Collapse.Panel
              key={elevation.elevationId}
              header={
                <div className="serviceListHoistCollapsePanelTitle">
                  Hoist, {elevation.elevationLabel} Elevation
                  {index === 0 && (
                    <ChangeRetenageModal {...{ service, applications }} />
                  )}
                </div>
              }
            >
              <div
                className="serviceListServiceHoist"
                style={{ boxShadow: `0px 0px 6px 3px ${color}` }}
              >
                <ServiceHeader {...{ service, elevation }} />
                <ServiceContent {...{ service, elevation, color }} />
                <ServiceFooter {...{ elevation }} />
              </div>
            </Collapse.Panel>
          ))}
      </Collapse>
    </div>
  );
};

export default HoistService;
