import { dayjsNY } from "../../../../../components/DateComponents/contants/DayjsNY";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * @param {String} value - The value of the input that has been parsed "11/01/2024"
 * @param {} form - the form instance of antd Form
 * @param {String} formItemName - the custom form item name that is used to set the value of the form
 * @param {Function} customOnChange - the custom onChange function
 * @param {String} error - the error message to display
 * @param {String} validTime - the valid time that can be set, can be "all", "future", "past"
 * @param {Date | Timestamp | Array} validationDate - can be a timestamp, date moment object to signify the date that will be compared for validations,
 * in the case of { Array } it will be an array of [start, end, type] where type can be "in" or "out" in meaning that the date should be in the range
 * and out meaning that the date should be out of the range
 */

//region VDatePredict
export function validateDatePredict({
  value,
  form,
  formItemName,
  customOnChange = () => {},
  error = "",
  validTime = null,
  validationDate = null,
}) {
  let today = dayjsNY().startOf("D").valueOf();
  let inputDate = tzDate(value, "MM/DD/YYYY").startOf("D").valueOf();
  let [month, year] = [value.split("/")[0], value.split("/")[2]];
  let errorText = document?.getElementById(`qb-error-text${formItemName}`);

  let dateToValidate = !!validationDate ? validationDate : today;

  const lastDayOfMonth = new Date(
    !!year ? year : dayjsNY()?.year(),
    month,
    0
  ).getDate();

  if (!!error?.length) {
    !!errorText && (errorText.innerHTML = error);
    form.setFieldValue(formItemName, "");
    return Promise.reject();
  }

  if (parseInt(month) > 12 || parseInt(month) < 1) {
    !!errorText && (errorText.innerHTML = "Month is invalid");
    form.setFieldValue(formItemName, "");
    return Promise.reject();
  }

  if (parseInt(value.split("/")[1]) > lastDayOfMonth) {
    !!errorText && (errorText.innerHTML = "Day is invalid");
    form.setFieldValue(formItemName, "");
    return Promise.reject();
  }

  if (Array.isArray(dateToValidate)) {
    const [start = null, end = null, type = null] = dateToValidate;

    if (type === "in") {
      if (
        inputDate < dayjsNY(start).startOf("D").valueOf() ||
        inputDate > dayjsNY(end).startOf("D").valueOf()
      ) {
        !!errorText && (errorText.innerHTML = "Date is not in range");
        form.setFieldValue(formItemName, "");
        return Promise.reject();
      }
    }

    if (type === "out") {
      if (
        inputDate > dayjsNY(start).startOf("D").valueOf() &&
        inputDate < dayjsNY(end).startOf("D").valueOf()
      ) {
        !!errorText && (errorText.innerHTML = "Date is not in range");
        form.setFieldValue(formItemName, "");
        return Promise.reject();
      }
    }
  } else {
    if (validTime === "all") {
      customOnChange(value) !== false &&
        form.setFieldsValue({
          [formItemName]: value,
          [`datePicker${formItemName}`]: tzDate(value, "MM/DD/YYYY"),
        });
      !!errorText && (errorText.innerHTML = "");
      return Promise.resolve();
    }

    const parsedValidation = dayjsNY(dateToValidate).startOf("D").valueOf();

    if (validTime === "future") {
      if (inputDate < parsedValidation) {
        !!errorText && (errorText.innerHTML = "Date is in the past");
        // form.setFieldValue(formItemName, "");
        return Promise.reject();
      }
    }

    if (validTime === "past") {
      if (inputDate > parsedValidation) {
        !!errorText && (errorText.innerHTML = "Date is in the future");
        form.setFieldValue(formItemName, "");
        return Promise.reject();
      }
    }
  }

  customOnChange(value) !== false &&
    form.setFieldsValue({
      [formItemName]: value,
      [`datePicker${formItemName}`]: tzDate(value, "MM/DD/YYYY"),
    });
  !!errorText && (errorText.innerHTML = "");
  return Promise.resolve();
}

//region PDatePredict
export function parseDatePredict({
  value,
  form,
  formItemName,
  customOnChange = () => {},
  validTime = null,
  validationDate = null,
}) {
  const props = {
    form,
    formItemName,
    customOnChange,
    validTime,
    validationDate,
  };
  const currentYear = dayjsNY().format("YYYY");
  let month;
  let day;
  let year;
  value = value?.split(" ").join("").split("/").join("").trim();
  if (value?.length > 8) {
    validateDatePredict({
      value: "",
      form,
      formItemName,
      error: "Invalid Date",
    });
    return;
  }
  if (value?.length === 8) {
    month = value.slice(0, 2);
    day = value.slice(2, 4);
    year = value.slice(4, 8);
    validateDatePredict({ value: `${month}/${day}/${year}`, ...props });
  } else if (value.length === 7) {
    if (parseInt(value.slice(0, 2)) > 12) {
      month = value.slice(0, 1);
      day = value.slice(1, 3);
      year = value.slice(3, 7);
      validateDatePredict({ value: `0${month}/${day}/${year}`, ...props });
    } else if (parseInt(value.slice(2, 4)) > 31) {
      month = value.slice(0, 2);
      day = value.slice(2, 3);
      year = value.slice(3, 7);
      validateDatePredict({ value: `${month}/0${day}/${year}`, ...props });
    } else {
      month = value.slice(0, 2);
      day = value.slice(2, 4);
      year = value.slice(4, 7);
      validateDatePredict({ value: `${month}/${day}/2${year}`, ...props });
    }
  } else if (value.length === 6) {
    month = value.slice(0, 2);
    day = value.slice(2, 4);
    year = value.slice(4, 6);
    validateDatePredict({ value: `${month}/${day}/20${year}`, ...props });
  } else if (value.length === 5) {
    if (parseInt(value.slice(0, 2)) > 12) {
      month = value.slice(0, 1);
      day = value.slice(1, 3);
      year = value.slice(3, 5);
      validateDatePredict({ value: `0${month}/${day}/20${year}`, ...props });
    } else if (parseInt(value.slice(2, 4)) > 31) {
      month = value.slice(0, 2);
      day = value.slice(2, 3);
      year = value.slice(3, 5);
      validateDatePredict({ value: `${month}/0${day}/20${year}`, ...props });
    } else {
      month = value.slice(0, 2);
      day = value.slice(2, 4);
      year = value.slice(4, 5);
      validateDatePredict({ value: `${month}/${day}/202${year}`, ...props });
    }
  } else if (value.length === 4) {
    month = value.slice(0, 2);
    day = value.slice(2, 4);
    validateDatePredict({ value: `${month}/${day}/${currentYear}`, ...props });
  } else if (value.length === 3) {
    month = value.slice(0, 2);
    day = value.slice(2, 3);

    if (parseInt(month) > 12) {
      month = value.slice(0, 1);
      day = value.slice(1, 3);
      validateDatePredict({
        value: `0${month}/${day}/${currentYear}`,
        ...props,
      });
    } else {
      validateDatePredict({
        value: `${month}/0${day}/${currentYear}`,
        ...props,
      });
    }
  } else if (value.length === 2) {
    const monthDate = value.slice(0, 2);
    validateDatePredict({
      value: `${monthDate}/${monthDate}/${currentYear}`,
      ...props,
    });
  } else if (value.length === 1) {
    const monthDate = value.slice(0, 1);
    validateDatePredict({
      value: `0${monthDate}/0${monthDate}/${currentYear}`,
      ...props,
    });
  } else {
    return;
  }
}

//region VTimePredict
export function validateTimePredict({
  value,
  form,
  formItemName,
  dateField = "",
  error = "",
  customOnChange = () => {},
  validTime = null,
  validationDate = null,
  setUserTime,
}) {
  let errorText = document.getElementById(`qb-time-error-text${formItemName}`);
  let parsedTime = value?.split(" ").join("").split(":").join("").trim();

  if (!!error?.length) {
    !!errorText && (errorText.innerHTML = error);
    form.setFieldValue(formItemName, "");
    return Promise.reject();
  }

  let timeReg = /\d/;
  if (!timeReg.test(parsedTime)) {
    !!errorText && (errorText.innerHTML = "Invalid Time");
    form.setFieldValue(formItemName, "");
    return Promise.reject();
  }
  let minute = parseInt(parsedTime?.slice(2, 4));
  if (minute > 59) {
    !!errorText && (errorText.innerHTML = "Invalid Time");
    form.setFieldValue(formItemName, "");
    return Promise.reject();
  }

  let today = dayjsNY().format("MM/DD/YYYY");
  let inputDate = form.getFieldValue(dateField);

  if (Array.isArray(validationDate)) {
    form.setFieldValue(formItemName, value);
    customOnChange(value);
    !!errorText && (errorText.innerHTML = "");
    return Promise.resolve();
  }
  let dateToValidate = !!validationDate
    ? dayjs(validationDate).valueOf()
    : today;

  if (dateToValidate === inputDate) {
    // Converts the input date and time to a specific timezone, defaulting to New York if not set to user time.
    let time = tzDate(
      `${inputDate} ${value}`,
      "MM/DD/YYYY hh:mm A",
      !!setUserTime
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : "America/New_York"
    )?.valueOf();

    if (validTime === "future") {
      if (Date.now() > time) {
        !!errorText && (errorText.innerHTML = "Cannot set time in the past");
        // form.setFieldValue(formItemName, "");
        return Promise.reject("Cannot set time in the past");
      }
    } else if (validTime === "past") {
      if (Date.now() < time) {
        !!errorText && (errorText.innerHTML = "Cannot set time in the future");
        form.setFieldValue(formItemName, "");
        return Promise.reject();
      }
    }
  }
  form.setFieldValue(formItemName, value);
  customOnChange(value);
  !!errorText && (errorText.innerHTML = "");
  return Promise.resolve();
}

//region PTimePredict
export function parseTimePredict({
  value,
  form,
  dateField,
  formItemName,
  customOnChange,
  validTime = null,
  validationDate = null,
  setUserTime = false,
}) {
  const props = {
    form,
    dateField,
    formItemName,
    customOnChange,
    validTime,
    validationDate,
  };
  // const date = form.getFieldValue(formItemName);
  const time = value?.split(" ").join("").split(":").join("").trim();
  const input = time
    ?.toLowerCase()
    ?.split("a")
    .join("")
    .split("p")
    .join("")
    .split("m")
    .join("")
    .trim();

  const letters = time
    ?.toLowerCase()
    ?.split("")
    ?.filter((el) => el === "a" || el === "p" || el === "m");

  if (letters?.length > 2) {
    validateTimePredict({
      value: "",
      form,
      formItemName,
      error: "Invalid Time",
      setUserTime,
    });
    return;
  }

  if (
    letters?.filter((el) => el === "a").length > 1 ||
    letters?.filter((el) => el === "p").length > 1 ||
    letters?.filter((el) => el === "m").length > 1
  ) {
    validateTimePredict({
      value: "",
      form,
      formItemName,
      error: "Invalid Time",
      setUserTime,
    });
    return;
  }
  const length = input?.length;
  if (length > 4) {
    validateTimePredict({
      value: "",
      form,
      formItemName,
      error: "Invalid Time",
      setUserTime,
    });
    return;
  }
  if (input.split("").every((el) => el === "0")) {
    validateTimePredict({
      ...props,
      value: "12:00 AM",
      setUserTime,
    });
    return;
  }
  if (length > 4) {
    validateTimePredict({
      value: "",
      form,
      formItemName,
      error: "Invalid Time",
      setUserTime,
    });
    return;
  }
  if (
    time?.toLowerCase()?.includes("a") &&
    time?.toLowerCase()?.includes("p")
  ) {
    validateTimePredict({
      value: "",
      ...props,
      error: "Cannot set both AM and PM ",
      setUserTime,
    });
    return;
  }
  if (time?.toLowerCase()?.includes("a")) {
    if (parseInt(input.slice(0, 2)) > 12 || !length) {
      validateTimePredict({
        value: ``,
        form,
        formItemName,
        error: "Invalid Time",
        setUserTime,
      });
      return;
    }
    if (length === 1) {
      validateTimePredict({
        value: `0${input}:00 AM`,
        ...props,
        setUserTime,
      });
    }
    if (length === 2) {
      validateTimePredict({
        value: `${parseInt(input) > 12 ? parseInt(input) - 12 : input}:00 AM`,
        ...props,
        setUserTime,
      });
    }
    if (length === 3) {
      validateTimePredict({
        value: `${
          parseInt(input.slice(0, 2)) > 12
            ? parseInt(input.slice(0, 2)) - 12
            : input.slice(0, 2)
        }:0${input.slice(2, 3)} AM`,
        ...props,
        setUserTime,
      });
    }
    if (length === 4) {
      validateTimePredict({
        value: `${
          parseInt(input.slice(0, 2)) > 12
            ? parseInt(input.slice(0, 2)) - 12
            : input.slice(0, 2)
        }:${input.slice(2, 4)} AM`,
        ...props,
        setUserTime,
      });
    }
    return;
  } else if (time?.toLowerCase()?.includes("p")) {
    if (parseInt(input.slice(0, 2)) > 12 || !length) {
      validateTimePredict({
        value: ``,
        form,
        formItemName,
        error: "Invalid Time",
        setUserTime,
      });
      return;
    }

    if (length === 1) {
      validateTimePredict({
        value: `0${input}:00 PM`,
        ...props,
        setUserTime,
      });
    }
    if (length === 2) {
      validateTimePredict({
        value: `${parseInt(input) > 12 ? parseInt(input) - 12 : input}:00 PM`,
        ...props,
        setUserTime,
      });
    }
    if (length === 3) {
      validateTimePredict({
        value: `${
          parseInt(input.slice(0, 2)) > 12
            ? parseInt(input.slice(0, 2)) - 12
            : input.slice(0, 2)
        }:0${input.slice(2, 3)} PM`,
        ...props,
        setUserTime,
      });
    }
    if (length === 4) {
      validateTimePredict({
        value: `${
          parseInt(input.slice(0, 2)) > 12
            ? parseInt(input.slice(0, 2)) - 12
            : input.slice(0, 2)
        }:${input.slice(2, 4)} PM`,
        ...props,
        setUserTime,
      });
    }
    return;
  }

  const intHour = parseInt(time?.slice(0, 2));

  if (parseInt(time?.slice(0, 2)) > 24) {
    validateTimePredict({
      value: ``,
      form,
      formItemName,
      error: "Invalid Time",
      setUserTime,
    });
    return;
  } else if (time?.length === 1) {
    validateTimePredict({ value: `0${time}:00 AM`, ...props, setUserTime });
  } else if (time?.length === 2) {
    if (intHour === 24) {
      return validateTimePredict({ value: `12:00 AM`, ...props, setUserTime });
    }
    if (intHour > 24) {
      validateTimePredict({
        value: ``,
        form,
        formItemName,
        error: "Invalid Time",
        setUserTime,
      });
      return;
    }

    if (intHour > 12) {
      validateTimePredict({
        value: `${
          intHour - 12 >= 10 ? intHour - 12 : `0${intHour - 12}`
        }:00 PM`,
        ...props,
        setUserTime,
      });
    } else {
      validateTimePredict({ value: `${time}:00 AM`, ...props, setUserTime });
    }
  } else if (time?.length === 3) {
    if (intHour === 24) {
      return validateTimePredict({
        value: `12:${time.slice(2, 3)} AM`,
        ...props,
        setUserTime,
      });
    }

    if (intHour > 12) {
      validateTimePredict({
        value: `${
          intHour - 12 >= 10 ? intHour - 10 : `0${intHour - 10}`
        }:0${time.slice(2, 3)} PM`,
        ...props,
        setUserTime,
      });
    } else {
      validateTimePredict({
        value: `${time?.slice(0, 2)}:0${time.slice(2, 3)} AM`,
        ...props,
        setUserTime,
      });
    }
  } else if (time?.length === 4) {
    const intHour = parseInt(time?.slice(0, 2));
    if (intHour === 24) {
      validateTimePredict({
        value: `12:${time.slice(2, 4)} AM`,
        ...props,
        setUserTime,
      });
    } else if (intHour > 12) {
      validateTimePredict({
        value: `${
          intHour - 12 >= 10 ? intHour - 12 : `0${intHour - 12}`
        }:${time.slice(2, 4)} PM`,
        ...props,
        setUserTime,
      });
    } else {
      validateTimePredict({
        value: `${time?.slice(0, 2)}:${time.slice(2, 4)} AM`,
        ...props,
        setUserTime,
      });
    }
  }
}

/**
 * Function to convert date string to dayjs object in timezone
 * @param {string} date The date string to convert
 * @param {string} format The custom format -default is "MM/DD/YYYY hh:mm A"
 * @param {string} tz The timezone - default is "America/New_York"
 * @returns dayjs object in timezone
 */

//region TZDate
export function tzDate(
  date = "",
  format = "MM/DD/YYYY hh:mm A",
  tz = "America/New_York"
) {
  if (!date?.length) return dayjsNY();
  if (!date?.trim() || typeof date !== "string") return dayjsNY();

  try {
    let d = dayjs.tz(date, format, tz);
    return d;
  } catch {
    return dayjsNY();
  }
}
