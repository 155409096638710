import React, { useEffect, useState } from "react";
import { fetchAllData } from "../../../../../../../../../utils";
import { LoadableComp } from "../../../../../../../XComponents";
import { removeDuplicates } from "../../../../../../Tabs/ScheduleView/AgGridData/utils/functions";
import Rentals from "../Rentals/Rentals";

function PreviousRentals({ fakeApplication }) {
  const [loading, setLoading] = useState(true);
  const [rentals, setRentals] = useState([]);

  const fetchIncludedRentals = async (projectId) => {
    try {
      const response = await fetchAllData({
        endpoint: "includedRentals",
        resultPosition: "includedRentals",
        resultId: "rentalsReqId",
        otherStringParams: {
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "projectId",
                  value: projectId,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      });
      return response || [];
    } catch (error) {
      console.error("Error fetching included rentals:", error);
      return [];
    }
  };

  const processAccumulatedRentals = (accumulatedRentals, rentalsData) => {
    const rentals = [];
    if (accumulatedRentals && Object.keys(accumulatedRentals).length > 0) {
      Object.entries(accumulatedRentals).forEach(([reqNo, rentalGroups]) => {
        Object.entries(rentalGroups).forEach(([rentalNo, services]) => {
          Object.entries(services).forEach(([serviceLabel, details]) => {
            rentals.push({
              ...(rentalsData.find(
                (el) => el?.rentalNumber.toString() === rentalNo.toString()
              ) || {}),
              applicationNo: reqNo,
              rentalNo,
              serviceLabel,
              retainage: details?.retainage,
              thisDistRetainage: details?.thisDistRetainage,
            });
          });
        });
      });
    }
    return rentals;
  };

  const getPreviousRentals = async () => {
    setLoading(true);
    try {
      const projectId = fakeApplication?.projectId;
      const includedRentals = await fetchIncludedRentals(projectId);
      const accumulatedRentals = fakeApplication?.accumulatedRentals || {};
      const processedRentals = processAccumulatedRentals(
        accumulatedRentals,
        includedRentals
      );
      const uniqueRentals = removeDuplicates(processedRentals, "rentalId");
      setRentals(uniqueRentals);
    } catch (error) {
      console.error("Error processing rentals:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPreviousRentals();
  }, []);

  return (
    <div>
      <LoadableComp loading={loading} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {rentals.map((rental) => (
          <Rentals
            key={rental.rentalId}
            rental={rental}
            type="prev"
            accumulatedRentals={fakeApplication?.accumulatedRentals}
            prevRentalsRetainage={
              fakeApplication?.totalities?.prevRentalsRetainage?.[
                `Rental${rental?.rentalNumber}`
              ]
            }
          />
        ))}
      </div>
    </div>
  );
}

export default PreviousRentals;
