import {
  GeofenceType,
  CoordinateType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { withinRadius } from "../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { findDistanceFromGeofenceSide } from "../../Payroll/Tabs/DEG/components/modalComponents/utils";

type PropsType = {
  point: CoordinateType;
  geoFence?: Array<GeofenceType>;
  jobCoordinates: CoordinateType;
};

type RangeType = {
  inRange: boolean;
  distanceInFeet?: number;
};

export function getJobDistance({
  point,
  jobCoordinates,
  geoFence,
}: PropsType): RangeType {
  let range = {
    inRange: false,
    distanceInFeet: undefined,
  };

  if (geoFence?.length) {
    range = findDistanceFromGeofenceSide({
      point,
      geofence: geoFence,
    });
  }

  if (!range?.distanceInFeet) {
    const distanceFromJob = withinRadius(point, jobCoordinates);
    range = {
      inRange: distanceFromJob?.withinRange,
      distanceInFeet: distanceFromJob?.distanceInFeet,
    };
  }

  return range;
}
