import { camelCase } from "lodash";
import { dataSourceReportsFetch } from "../../../../../../utils";
import { attachDataSources, attachParameters } from "../../../utils";
import { formatDataSourceData } from "../../../utils/populateReport";

const populateDataSources = async (
  report,
  categoryParameters,
  accessToken,
  activeFilters = {},
  recordId,
  partitionKeys, // optional for custom data
  customData, // optional,
  fetchedData = {},
  setFetchedData = () => {} // optional
) => {
  const {
    datasources = [],
    reportParameters,
    reportObj,
    categoryName,
  } = report;

  const formatter = formatDataSourceData(accessToken);
  let completedDataSources = 0;
  const totalDataSources = datasources.length;
  let populatedDataSources = [];
  try {
    populatedDataSources = await Promise.all(
      datasources.map(async (name = "") => {
        const currentDataSourcePartitionKey = partitionKeys?.identityId;

        const currentDataSourceFilter = activeFilters?.[camelCase(name)] || {};
        const proppedDataToUse = !!fetchedData?.[name]
          ? fetchedData?.[name]
          : customData
          ? Array.isArray(customData)
            ? customData
            : customData?.[name] || null
          : null;

        //if custom data, do not fetch
        let data = !!proppedDataToUse
          ? proppedDataToUse
          : await dataSourceReportsFetch(
              {
                dataSourceName: camelCase(name),
                categoryName,
              },
              recordId
            );
        completedDataSources++;

        if (window) {
          window.progressInterval &&
            window.progressInterval(completedDataSources, totalDataSources);
        }

        //formatter function (optional)
        const { [name.toLowerCase()]: dataFormatter } = formatter;

        //filters the datasource if we have a filter object or a partition key
        const filteredData =
          (Array.isArray(data) &&
            data?.filter((record) => {
              //if we don't have a value(active filters) returns "true", otherwise compares the values
              if (
                Object.entries(currentDataSourceFilter).every(
                  ([key, value]) => !value || record[key] === value
                )
              ) {
                // if we have a recordId and a partition key
                if (Array.isArray(recordId)) {
                  // if we want to get multiple records, filter rowdata with given array of ids
                  return recordId.includes(
                    record[currentDataSourcePartitionKey]
                  );
                } else {
                  if (currentDataSourcePartitionKey in record) {
                    return (
                      !recordId ||
                      !currentDataSourcePartitionKey ||
                      record[currentDataSourcePartitionKey] === recordId
                    );
                  } else {
                    return true;
                  }
                }
              } else {
                return false;
              }
            })) ||
          [];

        const dataToUse = (await dataFormatter?.(filteredData)) || filteredData;
        setFetchedData((prev) => ({
          ...prev,
          [name]: dataToUse,
        }));
        return {
          name,
          data: dataToUse,
        };
      })
    );
  } catch (e) {
    console.error("populateReport ~ e", { e });
  }
  const attach = attachDataSources(
    reportObj,
    populatedDataSources,
    !!customData
  );
  return attachParameters(attach, reportParameters, categoryParameters);
};

export default populateDataSources;
