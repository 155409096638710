import "./ReminderSelect.scss";
import React, { useState, useContext, useEffect } from "react";
import { Select, Typography } from "antd";
import Time from "../../../../../../../DateComponents/components/Time/Time";
import { InputComponent } from "../../../../../../Fleet/components";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import TimeDateContext from "../../../../../../../DateComponents/TimeDateContext";
import moment from "moment";
import ReminderModal from "../../../components/ReminderModal";
import { DropDownArrow } from "../../../../../../Fleet/components/InputComponent/assets";
import { useSelector } from "react-redux";
moment.tz.setDefault("America/New_York");

const determineWeekendDay = (date) => {
  const day = dayjsNY(date)?.day();
  return day === 6 || day === 0;
};

const ReminderSelect = ({
  value,
  placeholder,
  reminderTime,
  handleReminderTime = () => {},
  setValue = () => {},
  handleReminderDays = () => {},
  reminderType = "Days",
  setReminderType = () => {},
  startDate,
  deadline,
  notifiedBy,
  setNotifiedBy = () => {},
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [reminderDays, setReminderDays] = useState(null);

  const reminderOptions = ["Hours", "Days", "Weeks"].map((option, index) => {
    return (
      <Select.Option key={index} value={option}>
        {option}
      </Select.Option>
    );
  });

  const getNotifiedBy = ["Email", "Notification"].map((option, index) => {
    return (
      <Select.Option key={index} value={option}>
        {option}
      </Select.Option>
    );
  });

  const [visible, setVisible] = useState(false);

  //* Generates the reminder days or return the reminder days from the props
  const displayAllReminderDays = () => {
    if (!!deadline && !!startDate && value >= 1) {
      const parsedReminderTime =
        dayjsNY?.(startDate)?.format()?.split("T")[0] +
        "T" +
        dayjsNY(reminderTime)?.format()?.split("T")[1];

      let time = dayjsNY?.(parsedReminderTime)
        ?.add(reminderType !== "Hours" ? value - 1 : value, reminderType)
        ?.valueOf();

      const now = dayjsNY()?.valueOf();
      const temp = [];

      while (time <= deadline) {
        if (now < time) {
          temp?.push(time);
        }
        time = dayjsNY(time)?.add(value, reminderType)?.valueOf();
      }
      setReminderDays?.(temp);

      handleReminderDays(temp);
      return temp;
    } else if (value === 0) {
      handleReminderDays([]);
      return [];
    } else if (reminderDays?.length > 0) {
      handleReminderDays(reminderDays);
      return reminderDays;
    }
  };

  const { setOwnComponent, setOwnComponent2, hour, minute, amOrpm } =
    useContext(TimeDateContext);

  const onChangeHandler = (e) => {
    handleReminderTime(moment(`${hour}:${minute} ${amOrpm}`, "hh:mm a"));
    setValue(e);
  };

  useEffect(() => {
    handleReminderTime(moment(`${hour}:${minute} ${amOrpm}`, "hh:mm a"));
  }, [hour, minute, amOrpm]);

  useEffect(() => {
    setOwnComponent(
      <div className="task-reminder">
        <div className="input-section">
          <InputComponent
            onKeyDown={(e) => {
              if (e.key === "-" || e.key === "+" || e.key === ".") {
                e.preventDefault();
              }
            }}
            value={value || null}
            onChange={(e) => {
              onChangeHandler(e.target.value);
            }}
            showSearch
            placeholder={placeholder}
            typeNumber={true}
            tooltipCategory="Tasks"
            tooltipKey="remindEvery"
            addonAfter={
              <Select
                className="selectDays"
                value={reminderType}
                suffixIcon={<DropDownArrow />}
                popupClassName={isDarkMode && "darkDropDown"}
                placeholder="Select Days"
                onSelect={(e) => {
                  setReminderType(e);
                }}
              >
                {reminderOptions}
              </Select>
            }
            style={{ maxWidth: "270px" }}
          />
        </div>

        <div className="reminderDays">
          {displayAllReminderDays()?.map((item, index) => {
            const isWeekend = determineWeekendDay(item);
            return (
              <div
                key={index}
                className={
                  !isWeekend ? "messageReminderSet" : "messageReminderError"
                }
              >
                <Typography.Text
                  type={!isWeekend ? "success" : "danger"}
                  style={{ cursor: "pointer" }}
                  onClick={() => setVisible(true)}
                >
                  {dayjsNY(item).format("dddd, MMMM D, YYYY h:mm a")}
                </Typography.Text>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [value, reminderType, startDate.unix(), deadline.unix()]);

  useEffect(() => {
    setOwnComponent2(
      <InputComponent
        type="select"
        mode="multiple"
        label="Get Notified By"
        selectClassName="notifiedBy"
        tooltipCategory="Tasks"
        tooltipKey="notifiedBy"
        dropdownClassName={isDarkMode && "darkDropDown"}
        allowClear={false}
        options={getNotifiedBy}
        initialValue={notifiedBy ?? ["Email", "Notification"]}
        onChange={setNotifiedBy}
      />
    );
  }, [notifiedBy]);

  return (
    <div>
      <Time hideResult={true} hideButtons={true}></Time>
      {!!visible && (
        <ReminderModal
          {...{
            visible,
            setVisible,
            setReminderDays,
            reminderDays,
            deadline,
            startDate,
            reminderValue: value,
            handleReminderDays,
          }}
        />
      )}
    </div>
  );
};

export default ReminderSelect;
