import { message, Tooltip } from "antd";
import moment from "moment";
import { useState } from "react";
import { EditIcon } from "../../../../../../../../../../../../DynamicView/src";
import { parseInTz } from "../../../../../../../../../../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { formatCurrency } from "../../../../../../../../../../../../utils";
import { getWeeksDiff } from "../../columnDefs";
import dayjs from "dayjs";
import { forceToNumber } from "../../../../../../../../../../../../../../utils/ActiveReportJsUtils/helpers";
function TooltipSuggestion({ params, amount }) {
  const [suggestion, setSuggestion] = useState(null);
  const calculateSuggestion = () => {
    const endDate = params.data?.rentalDetails?.endDate
      ? parseInTz(params.data.rentalDetails.endDate).valueOf()
      : null;
    const startDate = params.data?.rentalDetails?.startDate
      ? parseInTz(params.data.rentalDetails.startDate).valueOf()
      : null;
    let daysPerMonth = 28;
    let dailyRate = forceToNumber(params?.data?.rent) / daysPerMonth;
    let tempsuggestion = 0;
    let howManyDays = +dayjs
      ?.duration(
        parseInTz(endDate)?.valueOf() - parseInTz(startDate)?.valueOf()
      )
      ?.asDays()
      ?.toFixed(0);
    howManyDays = howManyDays + 1;
    tempsuggestion = dailyRate * howManyDays;
    setSuggestion(
      forceToNumber(
        tempsuggestion > 0 ? tempsuggestion?.toFixed(2) : tempsuggestion
      )
    );
  };
  return (
    <div>
      {" "}
      <Tooltip
        content={<span>{formatCurrency(suggestion)}</span>}
        title={`Full distribution: ${suggestion}`}
        onOpenChange={(open) => {
          if (!open) {
            message.destroy();
          } else calculateSuggestion();
        }}
        destroyTooltipOnHide={true}
      >
        <div className="rentalEditableCell">
          {" "}
          {formatCurrency(amount || 0)}
          <EditIcon />
        </div>
      </Tooltip>
    </div>
  );
}

export default TooltipSuggestion;
