import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import { Checkbox, message, Modal } from "antd";
import { PlayCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { TickIcon, XIcon } from "../../../../../InternalChat/assets/icons";
import ResponsiveInfo from "../../../../../commonComponents/responsiveInfo/ResponsiveInfo";
import { MondayButton } from "../../../../../commonComponents";
import { updatePreferences } from "../../../../../../actions/preferences";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import { updateProgramFieldByName } from "../../../../../../actions/programFields";
import "./ResetModal.scss";

const ResetModal = ({
  setVideosToRemove,
  array = [],
  areAllSelected = false,
  videosToRemove = [],
  isModalOpen,
  setIsModalOpen,
  modalTitle,
  newOrFav = "new",
  sendEmail = false,
  handleSendEmail = () => {},
}) => {
  const { primaryArray } = useContext(VideoTutorialContext);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  const onCloseModal = () => {
    setVideosToRemove([]);
    setIsModalOpen(false);
    setSearch("");
  };

  const onChange = (value, id) => {
    value.target.checked
      ? setVideosToRemove((prev) => [...prev, id])
      : setVideosToRemove((prev) => prev.filter((item) => item !== id));
  };

  const onVideoAdded = (value, video) => {
    value.target.checked
      ? setVideosToRemove((prev) => [...prev, video])
      : setVideosToRemove((prev) =>
          prev.filter((item) => item.id !== video.id)
        );
  };

  const handleDeletePrefAndFav = (type) => {
    message.loading({ content: "Saving changes...", duration: 0 });

    const preferencesToSave = {
      ...allPreferences.preferences,
      videoPreferences: {
        ...allPreferences.preferences.videoPreferences,
        [type]: allPreferences.preferences.videoPreferences[type].filter(
          (video) =>
            !videosToRemove.includes(
              newOrFav === "favourites" ? video : video.id
            )
        ),
      },
    };

    API.patch("preferences", "/preferences", {
      body: {
        preferences: preferencesToSave,
      },
    })
      .then(() => {
        message.destroy();
        message.success("Changes saved successfully!");
        dispatch(updatePreferences(preferencesToSave));
        onCloseModal();
      })
      .catch((err) => {
        message.destroy();
        console.log({ err });
        message.error("There was an error while saving");
      });
  };

  const handleDeleteNewVideos = () => {
    message.loading({ content: "Saving changes...", duration: 0 });

    const updatedArray = primaryArray.map((category) => ({
      ...category,
      subCategories: category.subCategories.map((subCategory) => ({
        ...subCategory,
        videos: subCategory.videos.map((video) => {
          if (videosToRemove.includes(video.id)) {
            return {
              ...video,
              usersHaveSeen: [
                ...video.usersHaveSeen,
                userConfiguration.identityId,
              ],
            };
          }

          return video;
        }),
      })),
    }));

    try {
      API.patch(
        "programFields",
        `/programFields/7effeb2d-bf57-46e4-83ae-3e871826159d`,
        {
          body: {
            fieldOptions: updatedArray,
          },
        }
      ).then(() => {
        message.destroy();
        message.success("Changes saved successfully!");
        dispatch(
          updateProgramFieldByName({
            fieldName: "Portal Video Tutorials",
            fieldOptions: updatedArray,
          })
        );
        onCloseModal();
      });
    } catch (err) {
      message.destroy();
      console.log({ err });
      message.error("There was an error while saving");
    }
  };

  const handleEmail = () => {
    handleSendEmail();
    setIsModalOpen(false);
    setSearch("");
  };

  return (
    <Modal
      className={`resetContinueWatching-modal ${
        isDarkMode && "resetContinueWatching-modalDark"
      }`}
      title={modalTitle}
      open={isModalOpen}
      onCancel={onCloseModal}
      footer={null}
    >
      <div className="videos-container">
        <div className="buttons">
          <InputComponent
            {...{
              className: "search",
              placeholder: "Search video...",
              type: "input",
              inputAllowClear: true,
              onChange: (event) => setSearch(event.target.value),
              prefix: <SearchOutlined />,
            }}
          />
          <span
            className="select-button deselect"
            onClick={() => setVideosToRemove([])}
          >
            Deselect all
          </span>
          <span
            className="select-button select"
            onClick={() =>
              setVideosToRemove(
                array.map((video) => (sendEmail ? video : video.id))
              )
            }
            style={{
              ...(areAllSelected ? { color: "#1264a3" } : {}),
            }}
          >
            Select all
            {areAllSelected && (
              <TickIcon style={{ fill: "#1264a3", marginLeft: "3px" }} />
            )}
          </span>
        </div>
        <ResponsiveInfo
          text={`Select video(s) you want to ${
            sendEmail ? "send via Email." : "reset."
          }`}
          style={{ margin: "10px 0px" }}
        />
        <div className="videos">
          {array
            .filter((item) =>
              item?.videoName?.toLowerCase()?.includes(search.toLowerCase())
            )
            .map((video) => (
              <div className="video-container" key={video.id}>
                <Checkbox
                  className="videoCheckbox"
                  onChange={(value) =>
                    sendEmail
                      ? onVideoAdded(value, video)
                      : onChange(value, video.id)
                  }
                  checked={
                    sendEmail
                      ? videosToRemove.some((item) => item.id === video.id)
                      : videosToRemove.includes(video.id)
                  }
                />
                <div className="video">
                  <div className="play-icon-container">
                    <PlayCircleOutlined className="play-icon" />
                  </div>
                  <div>
                    <div className="card-text-info">{video?.videoName}</div>
                    <div className="video-bottom">
                      <span className="video-length">
                        Video length: {video?.lengthVideoInMin}
                        min
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="footer">
          <MondayButton
            onClick={onCloseModal}
            Icon={<XIcon />}
            className="mondayButtonRed"
          >
            Cancel Changes
          </MondayButton>
          <MondayButton
            Icon={<TickIcon />}
            onClick={() =>
              sendEmail
                ? handleEmail()
                : newOrFav === "new"
                ? handleDeleteNewVideos()
                : handleDeletePrefAndFav(newOrFav)
            }
            disabled={!videosToRemove.length}
          >
            {sendEmail ? "Confirm" : "Reset Videos"}
          </MondayButton>
        </div>
      </div>
    </Modal>
  );
};

export default ResetModal;
