import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useEditLogs,
  useProgramFields,
  useProgressComponent,
  useUploadedFiles,
} from "../../../../hooks";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { driveApi } from "../../../../integrations/DriveRequest";
import { getDriveFolderKeyName } from "../../utils/getDriveFolderKeyName";
import {
  safetyReducer,
  safetyApiData,
  useSafetyFieldsWatch,
  useSafetyFields,
  ADDRESS_FIELDS,
  NAME_KEY,
  EDIT_ACTION,
  CREATE_ACTION,
  DELETE_ACTION,
  DRIVE_FOLDER_KEY,
  DATE_KEY,
} from "./utils";
import { XIcon } from "../../Communication/assets";
import { Form, Modal, Tooltip, message } from "antd";
import {
  fetchAllData,
  filterTables,
  updateDocumentTitle,
} from "../../../../utils";
import { API } from "aws-amplify";
import {
  changeDateOfIncident as dateOfIncidentHandler,
  createSafety,
  editSafety,
  onChangeAddress as addressChangeHandler,
  populateSafetyForm,
  safetyBody,
  checkSaveDraft,
  onCloseModal,
  safetyEmailNotif,
  deleteSafety,
  onGenerateSafetyPdf,
  handleMissingRecord,
  saveSortedFields,
  processVisualizationImages,
  safetyDataSetsForReport,
} from "./helpers";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import CustomModalHeader, {
  PlayVideoTutorial,
} from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { safetyModalFooter, safetyCustomLogObject } from "./formData";
import { LoadableComp } from "../../XComponents";
import SafetyStatus from "./components/SafetyStatus";
import SafetyFormSection from "./components/SafetyFormSection";
import AskForTaskModal from "../../Fleet/view/pageView/components/SingleTruckIncidents/modals/IncidentModal/AskForTaskModal";
import DynamicTeamModal from "../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import {
  MultiLevelTreeLogs,
  ProgressComponent,
} from "../../../commonComponents";
import SafetyWarningSection from "./components/SafetyWarningSection";
import { createSafetyPdfBody, safetyPdfBody } from "../safetyPdf";
import { filterSchedulesOnDate } from "./utils/filterSchedulesOnDate";
import NewTaskModal from "../../TaskManager/Tasks/TasksModals/NewTask/NewTaskModal";
import InspectionModal from "../../components/AgGridComponents/InspectionModal";
import TasksBasePage from "../../TaskManager/Tasks/TasksBasePage";
import {
  findMissingFolderKeys,
  safetyAndIncidentFolderKeys,
} from "../../DynamicView/Pages/Overview/components/DrivePermission/utils/utils";
import AddDriveFolderModal from "../../DynamicView/Pages/Overview/components/AddDriveFolderModal/AddDriveFolderModal";
import { keys } from "../../../pages/Settings/settingsComponents/Roles/RolesData";
import { SettingIcon } from "../../../Header/subcomponents";
import { toCamelCase } from "../../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
import { DocumentationModal } from "../../Documentation/View/components";
import { TransferIconNew } from "../../DynamicView/src";
import SortFields from "../../../pages/Settings/settingsComponents/SafetyDynamicForms/SortFields/SortFields";
import SafetyExportPreview from "./components/SafetyExportPreview/SafetyExportPreview";
import useHandleAttachments from "../hooks/useHandleAttachments";
import EmailBoxContainer from "../../Communication/components/EmailBox/EmailBoxContainer";
import DynamicReportDesigner from "../../Reports/components/DynamicReportDesigner/DynamicReportDesigner";
import { initialExportData } from "./components/SafetyExportPreview/helpers";
import Report from "../../DynamicView/Pages/Overview/components/Report/Report";
import { useEmailBoxContainer } from "../../Communication/components/EmailBox/providers/EmailBoxContainerProvider";
import EmailBox from "../../Communication/components/EmailBox/EmailBox";

/**
 * Renders a modal component for creating or updating safety applications.
 *
 * @component
 * @param {Object} rowObject - The row object containing safety application data.
 * @param {string} propProjId - The project ID.
 * @param {boolean} visible - The visibility of the modal.
 * @param {string} propAddress - The address.
 * @param {string} safetyCategory - The safety category.
 * @param {Function} setVisible - The function to set the visibility of the modal.
 * @param {Function} refreshTable - The function to refresh the table.
 * @param {Function} nextStepHandler - The function to handle the next step.
 * @param {Function} setVisibleReport - The function to set the visibility of the report.
 * @param {Function} handleSavedRecord - The function to handle saved records.
 * @param {Function} updateIncidentStatus - The function to update the incident status.
 * @returns {JSX.Element} The JSX element representing the NewSafetyModal component.
 */
function NewSafetyModal({
  getSingleSafetyData,
  rowObject,
  propProjId = "",
  visible = false,
  propAddress = "",
  safetyCategory = "",
  refreshTable = () => {},
  nextStepHandler = () => {},
  // setVisibleReport = () => {},
  handleSavedRecord = () => {},
  updateIncidentStatus = () => {},
  setVisible: closeSafety = () => {},
  documentationModalFiles,
  setDocumentationModalFiles,
}) {
  const dispatch = useDispatch();

  const [
    { accessToken },
    { userConfiguration },
    { hotCredentials },
    { programFields },
    { authenticatedUser },
    { base64 },
    { notifications },
    { isDarkMode },
    { preferences },
    { loggedOnTasks },
  ] = useSelector((state) => [
    state.accessToken,
    state.userConfig,
    state.hotCredentials,
    state.programFields,
    state.authenticatedUser,
    state.base64,
    state.notifications,
    state.darkMode,
    state.preferences,
    state.loggedOnTasks,
  ]);

  const {
    safetyApplicationId: defaultId,
    safetyApplicationObject: defaultObj,
  } = rowObject || {};

  const incidentId = useMemo(
    () =>
      rowObject?.incidentId
        ? rowObject?.incidentId
        : rowObject?.safetyApplicationObject?.incidentId || null,
    [rowObject]
  );

  const { ["List of Folders"]: folderList } = useProgramFields();

  const emailHandlers = useEmailBoxContainer();

  const reportEditAccess =
    userConfiguration?.routeConfig
      ?.find((r) => r?.title === "Settings")
      ?.children.find((c) => c?.title === "Report")?.write || false;

  const {
    emailBoxes,
    openEmailBox,
    closeEmailBox,
    selectedDraft,
    setAttachments,
    attachments,
  } = emailHandlers || {};

  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;
  const driveFunctions = useCreateDriveFolders(safetyCategory);
  const driveRequest = driveApi({ accessToken });
  const folderDriveKeyName = getDriveFolderKeyName({
    programFields,
    name: safetyCategory,
  });

  const updateRef = useRef(0);

  const [sign, setSign] = useState();
  const [editLogsVisibility, setEditLogsVisibility] = useState(false);
  const [divClickable, setDivClickable] = useState(true);
  const [witnessCount, setWitnessCount] = useState(1);
  const [witnessSignings, setWitnessSignings] = useState([""]);
  const [askTaskModal, setAskTaskModal] = useState();
  const [mainDisabledField, setMainDisabledField] = useState(
    !!defaultObj?.[DRIVE_FOLDER_KEY?.[safetyCategory]]
  );
  const [changes, setChanges] = useState(!!incidentId && !defaultId);
  const [tasksVisible, setTasksVisible] = useState(false);
  const [taskModalData, setTaskModalData] = useState();
  const [pdfId, setPdfId] = useState();
  const [addressSelectionInfo, setAddressSelectionInfo] = useState({
    projectId: rowObject?.projectId || propProjId || "",
    accountId: rowObject?.accountId || "",
    scheduleId: rowObject?.scheduleId || "",
  });
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [currentStep, setCurrentStep] = useState();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [teamModalVisible, setTeamModalVisible] = useState(false);
  const [videoTutorial, setVideoTutorial] = useState(false);
  const [actionType, setActionType] = useState("Update");
  const [driveFolder, setDriveFolder] = useState(null);
  const [addDriveModal, setAddDriveModal] = useState(false);
  const [form] = Form.useForm();
  const [wordPreview, setWordPreview] = useState(false);
  const [newSortFields, setNewSortFields] = useState([]);
  const [openSort, setOpenSort] = useState(false);
  const [loading, setLoading] = useState(false);
  const [safetyFormData, dispatchSafetyFormData] = useReducer(
    safetyReducer,
    {}
  );
  const [visibleReport, setVisibleReport] = useState(false);
  const [visualizationImages, setVisualizationImages] = useState([]);

  const currentProject = useMemo(() => {
    if (
      !(addressSelectionInfo?.projectId || [])?.length ||
      !addressSelectionInfo?.projectId
    )
      return null;
    return (
      safetyFormData?.projects?.find(
        (el) => el?.projectId === addressSelectionInfo?.projectId
      ) || null
    );
  }, [addressSelectionInfo?.projectId, safetyFormData?.projects]);

  const rowObjectKey = "safetyApplicationObject";

  const dynamicFields = useMemo(() => {
    if (newSortFields.length > 0) {
      return newSortFields;
    } else {
      return (
        programFields?.find((el) => el?.fieldName === "Safety Dynamic Fields")
          ?.fieldOptions?.[safetyCategory] || []
      );
    }
  }, [programFields, newSortFields]);

  const { handleAttachments, handleUploadedFiles } = useHandleAttachments({
    base64,
    accessToken,
    category: safetyCategory,
    driveRequest,
    rowObject,
    driveFunctions,
    folderDriveKeyName,
    dynamicFields,
    selectedTeam,
    crews: safetyFormData?.crews,
    userConfiguration,
    defaultId,
    witnessCount,
    witnessSignings,
    rowObjectKey,
    pdfFileName: `Property Damage Report ${rowObject?.projectName}`,
  });

  const { safetyFields } = useSafetyFields(safetyCategory);

  const fieldsToWatch = useSafetyFieldsWatch(form, safetyCategory);

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
    hideProgress,
  } = useProgressComponent({
    categoryName: "Safety",
    actionType:
      actionType === "Delete" ? "Delete" : !!defaultId ? "Update" : "Create",
  });
  const { saveAddedLogs } = useEditLogs();

  // set props for Link Component
  const popoverProps = {
    incidentId: rowObject?.incidentId,
    projectId: rowObject?.projectId,
    accountId: rowObject?.accountId,
    scheduleId: rowObject?.scheduleId,
  };

  const safetyDirectorSignature = useMemo(() => {
    if (rowObject?.safetyApplicationObject?.safetyDirectorSignature) {
      return rowObject?.safetyApplicationObject?.safetyDirectorSignature;
    } else {
      return (
        programFields?.find((el) => el?.fieldName === "Safety Dynamic Fields")
          ?.fieldOptions?.safetyDirectorSignature || null
      );
    }
  }, [programFields, userConfiguration]);

  const videoTutorialLink = useMemo(() => {
    return programFields
      ?.filter((item) => item?.fieldName === "Portal Video Tutorials")[0]
      ?.fieldOptions?.find((item) => item?.categoryName === "Safety")
      ?.subCategories[0]?.videos.find(
        (item) => item?.videoName === `New ${safetyCategory} Safety`
      )?.videoLink;
  }, [programFields]);

  const disabledAllFields = useMemo(() => {
    return (
      (Array.isArray(safetyFormData?.claims) &&
        !!safetyFormData?.claims?.length &&
        rowObject?.safetyApplicationStatus !== "Draft" &&
        !!defaultId) ||
      rowObject?.safetyApplicationStatus === "Proceeded to Claim"
    );
  }, [rowObject, defaultId, safetyFormData?.claims]);

  const safetyHotCredential = hotCredentials?.find(
    (obj) => obj.credentialId === "16"
  );

  const statusesColors = useMemo(() => {
    return (
      programFields?.find((el) => el?.fieldName === "Statuses Of Categories")
        ?.fieldOptions?.Safety || []
    );
  }, [programFields]);

  const noteAccess = useMemo(
    () =>
      userConfiguration?.routeConfig
        ?.find(({ title }) => title === "Notes/View")
        ?.children?.find((el) => el?.title === safetyCategory),
    [userConfiguration?.routeConfig]
  );

  const windowHandler = useCallback((event) => {
    event.preventDefault();
    event.returnValue = "Are you sure you want to leave the page?";
    return "Are you sure you want to leave the page?";
  }, []);

  const setVisible = (val) => {
    if (documentationModalFiles !== null) {
      getSingleSafetyData();
      window.removeEventListener("beforeunload", windowHandler);
      return;
    }

    window.removeEventListener("beforeunload", windowHandler);
    closeSafety(val);
  };

  const changeDateOfIncident = (date) =>
    dateOfIncidentHandler({
      date,
      form,
      schedules: safetyFormData?.scheduling,
      setSchedulesForDate: (data) => {
        dispatchSafetyFormData({
          type: "SCHEDULES_FOR_DATE",
          payload: { schedulesForDate: data },
        });
      },
      setAddressSelectionInfo,
      addressSelectionInfo,
      addressField: ADDRESS_FIELDS?.[safetyCategory] || "",
    });

  const onChangeAddress = (val, option) =>
    addressChangeHandler({
      val,
      form,
      option,
      projects: safetyFormData?.projects || [],
      schedulesForDate: safetyFormData?.schedulesForDate || [],
      setAddressSelectionInfo,
    });

  const onTeamSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onTeamDeselect = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeam((prev) => prev?.filter((el) => el.value !== teamName));
    if (form.getFieldValue("team").length < 1) {
      setSelectedTeam([]);
    }
  };

  // useEffect(() => {
  //   if (visible) {
  //     if (disabledAllFields) {
  //       message.warning(
  //         "This form is disabled because this Safety Application is being used in one or more Claims and cannot be edited.",
  //         [5]
  //       );
  //     }
  //   }
  // }, [disabledAllFields, visible]);

  const driveKeys = useMemo(() => {
    return safetyAndIncidentFolderKeys(
      "Safety",
      safetyCategory,
      folderDriveKeyName
    );
  }, [JSON.stringify(rowObject?.googleDriveFolderIds)]);

  const hasMissingFolders = useMemo(() => {
    if (updateRef.current === 1) return false;
    const missingKeys = findMissingFolderKeys(
      driveKeys.folderName,
      rowObject?.googleDriveFolderIds,
      folderList
    );
    return missingKeys.length > 0;
  }, [
    rowObject?.googleDriveFolderIds,
    driveKeys.folderName,
    driveFolder,
    updateRef,
  ]);

  //region FIELDS
  const formFields = useMemo(() => {
    if (!dynamicFields?.length) return [];

    return safetyFields({
      mainDisabledField,
      userConfiguration,
      changeDateOfIncident,
      onChangeAddress,
      setChanges,
      disabledAllFields,
      addressSelectionInfo,
      defaultId,
      form,
      propAddress,
      rowObject,
      isDarkMode,
      dynamicFields,
      dispatchSafetyFormData,
      ...fieldsToWatch,
      ...safetyFormData,
    });
  }, [
    mainDisabledField,
    dynamicFields,
    JSON.stringify(safetyFormData),
    JSON.stringify(fieldsToWatch),
  ]);

  //region ON SAVE
  async function onSave(saveDraft = false) {
    if (!sign && !saveDraft) {
      message.error("Please Sign this Safety Report");
      return;
    }

    let allFolders = null;

    if (
      !driveFunctions.parentFolderId &&
      !rowObject?.googleDriveFolderIds?.[folderDriveKeyName] &&
      !!form.getFieldValue(DRIVE_FOLDER_KEY?.[safetyCategory])
    ) {
      try {
        const { folders } = await driveFunctions.create({
          parentFolderName: `${(
            form.getFieldValue(DRIVE_FOLDER_KEY?.[safetyCategory]) || "Draft"
          ).trim()} ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`,
          parentId: safetyHotCredential?.credentialValue,
        });
        allFolders = folders;
      } catch (error) {
        console.error("error: ", error);
        allFolders = null;
      }
    }

    const driveFolderCondition = () => {
      if (rowObject?.googleDriveFolderIds?.[folderDriveKeyName]) {
        return rowObject?.googleDriveFolderIds || {};
      } else if (driveFunctions.parentFolderId) {
        return driveFunctions?.allFolders || {};
      } else {
        return allFolders || {};
      }
    };

    const visualizationImagesWithId = await processVisualizationImages(
      rowObject,
      visualizationImages,
      driveRequest,
      folderDriveKeyName,
      driveFolderCondition,
      filesToBeDeleted
    );

    message.loading({
      content: "Saving...",
      key: "safetyLoading",
      duration: 0,
    });

    const { tmpBody = {} } = safetyBody({
      form,
      sign,
      assignedTo,
      currentStep,
      selectedTeam,
      witnessCount,
      projects: safetyFormData?.projects,
      nameKey: NAME_KEY?.[safetyCategory],
      table: "safety",
      schedules: safetyFormData?.scheduling,
      statusesColors,
      incidentId,
      saveDraft,
      dynamicFields,
      witnessSignings,
      visualizationImages: visualizationImagesWithId,
      // requiredTextArea,
      // witnessStatements,
      userConfiguration,
      // descriptionValues,
      addressSelectionInfo,
      googleDriveFolderIds: driveFolderCondition(),
      formattedFiles,
      crews: safetyFormData?.crews,
      drivers: safetyFormData?.drivers,
      safetyDirectorSignature,
      documentationModalFiles,
    });

    if (!!defaultId) {
      setVisibleCreationProgress(rowObject);
      setCancelModalVisible(false);
      //region EDIT SAFETY
      await editSafety({
        table: "safety",
        tmpBody,
        nameKey: NAME_KEY?.[safetyCategory],
        category: safetyCategory,
        rowObject,
        editAction: EDIT_ACTION?.[safetyCategory],
        authenticatedUser,
        userConfiguration,
        refreshTable,
        updateProgressStatus,
        customLogObj(data) {
          return safetyCustomLogObject?.[safetyCategory]({
            data,
            userConfiguration,
            ...safetyFormData,
          });
        },
        refreshTable,
        saveAddedLogs,
        updateProgressStatus,
      });
    } else {
      setCancelModalVisible(false);

      //region CREATE SAFETY
      await createSafety({
        table: "safety",
        dispatch,
        nameKey: NAME_KEY?.[safetyCategory],
        category: safetyCategory,
        tmpBody,
        loggedOnTasks,
        programFields,
        rowObject,
        incidentId,
        assignedTo,
        preferences,
        createAction: CREATE_ACTION?.[safetyCategory],
        saveDraft,
        driveFunctions,
        userConfiguration,
        refreshTable(res) {
          refreshTable([res].reduce((acc, val) => [...acc, { ...val }], []));
        },
        setAskTaskModal,
        setUploadedFiles,
        updateIncidentStatus,
        setCancelModalVisible,
        handleSavedRecord,
        setVisibleCreationProgress,
        updateProgressStatus,
        hideProgress,
        saveAddedLogs,
      })
        ?.then(
          // (res) => res?.safetyApplicationStatus !== "Draft" &&
          nextStepHandler()
        )
        ?.catch((err) => console.error("Error Creating Safety: ", err));
    }
  }

  async function onContinueToUpload(noPicker = false, callBack = () => {}) {
    const uploaderButton = document.querySelector(".uploaderButton");

    form
      .validateFields([DRIVE_FOLDER_KEY?.[safetyCategory]])
      .then(async () => {
        const { folders } = await driveFunctions.create({
          parentFolderName: `${form
            .getFieldValue(DRIVE_FOLDER_KEY?.[safetyCategory] || "")
            .trim()} ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`,
          parentId: safetyHotCredential?.credentialValue,
        });

        setMainDisabledField(true);
        setCancelModalVisible(false);
        setDriveFolder(folders?.[folderDriveKeyName]);
        setDivClickable(false);
        if (!noPicker) {
          setTimeout(() => {
            if (!!uploaderButton) {
              uploaderButton.click();
            }
          }, 1000);
        }
        callBack();
      })
      .catch((err) => {
        setCancelModalVisible(false);
        const errorField = Object.keys(err?.values || {})?.[0];
        if (!!errorField) {
          const div = document.getElementById(errorField);
          if (!!div) {
            div.scrollIntoView({ behavior: "smooth" });
          }
        }
        message.error("Please complete the required fields to continue");
        console.error("Error uploading: ", err);
      });
  }
  //region ON CLOSE
  async function onClose(param = {}) {
    await onCloseModal({
      form,
      param,
      driveRequest,
      nameKey: NAME_KEY?.[safetyCategory],
      rowObject,
      objectKey: "safetyApplicationObject",
      driveFunctions,
      filesToBeDeleted,
      setVisible,
      folderDriveKeyName,
      setUploadedFiles,
      updateDocumentTitle,
      setFilesToBeDeleted,
      setCancelModalVisible,
      saveAddedLogs,
    }).then(() => dispatchSafetyFormData({ type: "RESET" }));
  }

  function checkForChanges() {
    if (!defaultId) {
      let requiredFields = formFields
        ?.find(({ title }) => title === "General Information")
        ?.fields?.filter(({ required }) => required)
        ?.map(({ formItemName }) => formItemName);
      let fields = form.getFieldsValue(requiredFields);
      if (Object.values(fields).some((value) => !!value)) {
        setCancelModalVisible(true);
      } else {
        onClose();
      }
    } else {
      !!changes ? setCancelModalVisible(true) : onClose();
    }
  }

  function onCancel() {
    if (!!changes || !!driveFunctions.parentFolderId) {
      checkForChanges();
    } else if (!changes && !defaultId) {
      checkForChanges();
    } else {
      onClose();
    }
  }

  function onEmailSave({ e, key, type, setLoading }) {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setEmailBox(false);
    }
  }

  async function sendEmailNotification() {
    await safetyEmailNotif({
      table: "safety",
      category: safetyCategory,
      rowObject,
      defaultId,
      authenticatedUser,
      userConfiguration,
      saveAddedLogs,
    });
  }

  function closeModal() {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
  }

  useEffect(() => {
    if (visible) {
      const apiData = {
        ...safetyApiData?.[safetyCategory],

        // dynamicFields: () =>
        //   filterTables("programFields", "fieldName", "Safety Dynamic Fields"),

        teams: () => API.get("teams", "/teams"),

        // claims: () => filterTables("claims", "claimCategory", "Property Damage"),
      };

      if (!!rowObject) {
        Object.assign(apiData, {
          claims: () => filterTables("claims", "claimCategory", safetyCategory),
        });
      }
      const dataResponses = Object.keys(apiData)?.map(async (key) => {
        let data = await apiData?.[key]();

        const tmpData = Array.isArray(data) ? data : data?.[key];

        if (!!tmpData?.length) {
          // if (key === "dynamicFields") {
          //   return {
          //     [key]: tmpData[0]?.fieldOptions?.[safetyCategory],
          //   };
          // }
          return {
            [key]: tmpData,
          };
        }
      });

      Promise.all([...dataResponses])
        .then(async (responses) => {
          window.addEventListener("beforeunload", windowHandler);

          const dispatchData = responses.reduce(
            (acc, curr) => ({
              ...acc,
              ...curr,
              claims: curr?.claims?.filter(
                ({ safety }) =>
                  safety?.hasOwnProperty("safetyApplicationId") &&
                  safety?.safetyApplicationId === defaultId
              ),
            }),
            {}
          );

          // if (
          //   safetyCategory === "Property Damage" ||
          //   safetyCategory === "Personal Injury"
          // ) {
          //   Object.assign(dispatchData, {
          //     crewTeams,
          //   });
          // }

          if (!!defaultId) {
            await handleMissingRecord({ rowObject, dispatchData });
          }

          dispatchSafetyFormData({
            type: "GET_DATA",
            payload: {
              category: safetyCategory,
              data: dispatchData,
            },
          });

          dispatchSafetyFormData({
            type: "SCHEDULES_FOR_DATE",
            payload: {
              schedulesForDate:
                filterSchedulesOnDate(
                  dispatchData?.scheduling,
                  defaultObj?.[DATE_KEY?.[safetyCategory]] || dayjsNY()
                )?.schedulesForDay || [],
            },
          });

          message.destroy("modalLoading");
        })
        .catch((err) => {
          message.error({
            content: "Something went wrong!",
            key: "modalLoading",
          });
          console.error("Error in NewSafetyModal: ", err);
          closeModal();
        });
    }

    return () => {
      window.removeEventListener("beforeunload", windowHandler);
    };
  }, [visible]);

  useEffect(() => {
    if (!!defaultId) {
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                { column: "recordId", value: defaultId, formula: "is" },
              ],
            },
          ]),
        },
      })
        .then((res) => {
          dispatchSafetyFormData({
            type: "EDIT_LOGS",
            payload: { editLogs: res },
          });
        })
        .catch((error) => {
          console.error("Error fetching logs: ", error);
        });

      // if (!!rowObject?.googleDriveFolderIds?.[folderDriveKeyName]) {
      setDivClickable(false);
      // }

      let { viewLogs = {} } = rowObject;
      viewLogs = {
        ...viewLogs,
        [authenticatedUser?.sub]: {
          lastSeen: Date.now(),
          name: userConfiguration?.nameOfUser,
        },
      };

      API.put("safety", `/safety/${defaultId}`, {
        body: {
          viewLogs,
        },
      })
        .then(() => {
          dispatchSafetyFormData({
            type: "VIEW_LOGS",
            payload: { viewLogs },
          });
        })
        .catch((err) => console.error("Error Updating View Logs: ", err));
    }

    if (!!Object.values(rowObject || {})?.length && dynamicFields) {
      populateSafetyForm({
        form,
        pdfId,
        rowObject,
        statusKey: "safetyApplicationStatus",
        fieldsObject: "safetyApplicationObject",
        dynamicFields,
        setSign,
        statusesColors,
        setPdfId,
        setAssignedTo,
        setCurrentStep,
        setSelectedTeam,
        setWitnessCount,
        setUploadedFiles,
        setWitnessSignings,
        data: safetyFormData,
        // setWitnessStatements,
        // setDescriptionValues,
      });
    }
  }, [rowObject, dynamicFields, defaultId]);

  useEffect(() => {
    if (!defaultId || !accessToken) return;
    else {
      (async () => {
        if (
          !rowObject?.googleDriveFolderIds ||
          keys(rowObject?.googleDriveFolderIds).length === 0
        ) {
          setDriveFolder(false);
        } else {
          setDriveFolder(false);
          try {
            const driveIdKey = driveKeys?.key;
            const driveFolderId = rowObject?.googleDriveFolderIds?.[driveIdKey];
            const driveRes = await driveRequest.getDriveItem(driveFolderId);
            if (driveRes?.ok) {
              const res = await driveRes.json();
              setDriveFolder(res?.id || driveFolderId);
              if (hasMissingFolders) {
              }
            } else {
              setDriveFolder(false);
            }
          } catch (err) {
            console.log("Folder not found: ", err);
            setDriveFolder(false);
          }
        }
      })();
    }
  }, [JSON.stringify(rowObject?.googleDriveFolderIds), accessToken, defaultId]);

  async function generatePdf(
    sorted = false,
    customBody = null,
    includeImages = true
  ) {
    function createBody() {
      if (!!customBody) {
        return customBody();
      } else {
        return createSafetyPdfBody({
          defaultId,
          rowObject,
          formFields: !!sorted ? sorted : formFields,
          witnessCount,
          witnessSignings,
          safetyFormData,
          safetyCategory,
          selectedTeam,
          userConfiguration,
        });
      }
    }
    onGenerateSafetyPdf({
      base64,
      category: safetyCategory,
      rowObject,
      pdfId,
      accessToken,
      driveFunctions,
      defaultObj,
      setPdfId,
      folderDriveKeyName,
      userConfiguration,
      createPdfBody: createBody,
      includeImages,
    });
  }

  async function getInitialExportData() {
    return await initialExportData({
      modalType: "safety",
      rowObject,
      formFields,
      selectedTeam,
      safetyPdfBody,
      safetyCategory,
      witnessCount,
      witnessSignings,
      defaultId,
      safetyFormData,
      userConfiguration,
      accessToken,
      rowObjectKey,
      currentProject,
    });
  }

  //region JSX
  return (
    <Modal
      {...{
        open: visible,
        onCancel,
        className: "safetyDynamicForms",
        closable: true,
        centered: true,
        wrapClassName: `safetyDynamicFormsWrap ${
          isDarkMode && "safetyDynamicFormsWrapDark"
        }`,
        title: (
          <CustomModalHeader
            {...{
              title: `${safetyCategory} Form`,
              headerInfo: () =>
                (userConfiguration?.groupName === "Admin" ||
                  userConfiguration?.groupName === "Super Admin") && (
                  <>
                    <Tooltip title="Add New Fields">
                      <SettingIcon
                        className="settingsIcon"
                        onClick={() =>
                          window.open(
                            `${window.location.origin}/settings/${toCamelCase(
                              safetyCategory
                            )}`
                          )
                        }
                      />
                    </Tooltip>
                    <Tooltip title="Sort Fields">
                      <TransferIconNew
                        {...{
                          className: "sortFieldsIcon",
                          onClick: () => {
                            setOpenSort(true);
                          },
                        }}
                      />
                    </Tooltip>
                  </>
                ),
              onClick() {
                !!videoTutorialLink
                  ? setVideoTutorial(true)
                  : message.info("No video tutorial available for this form");
              },
            }}
          />
        ),
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "Property Damage Form" });
        },
        closeIcon: <XIcon />,
        style: {
          display: dynamicFields?.length ? "" : "none",
        },
        destroyOnClose: true,
        //region FOOTER
        footer: safetyModalFooter({
          onCancel,
          claimsUsingSafety: safetyFormData?.claims || [],
          setTasksVisible,
          changes,
          isDarkMode: isDarkMode,
          defaultId,
          onSave() {
            checkSaveDraft({
              changes,
              form,
              // descriptionValues,
              // requiredTextArea,
              saveFunction: onSave,
              setVisible,
              setCancelModalVisible,
              populatedInputFields: dynamicFields,
              incidentId,
            });
          },
          onDelete() {
            safetyFormData?.claims?.length > 0
              ? message.error({
                  content:
                    "This Safety Report is linked to a Claim. Please delete the Claim first.",
                  key: "messageDeleting",
                })
              : setCancelModalVisible("Delete");
          },
          onShowLogs() {
            setEditLogsVisibility(true);
          },
          hasReport: true,
          onReportView() {
            setVisibleReport(true);
          },
          selectedTeam,
          safetyName: rowObject?.safetyName,
          setWordPreview,
          setOpenSort,
          onGeneratePdf() {
            generatePdf();
          },
          onEmail() {
            handleAttachments(uploadedFiles);
          },
          safetyCategory,
          noteAccess,
          popoverProps,
        }),
        maskClosable: false,
      }}
    >
      <LoadableComp loading={!dynamicFields}>
        <SafetyStatus
          {...{
            form,
            currentStep,
            safetyStatus: rowObject?.safetyApplicationStatus || "Draft",
            isDarkMode,
            statusesColors,
            setChanges,
            setCurrentStep,
            disabledAllFields,
          }}
        />

        <Form
          form={form}
          onFieldsChange={(e) => {
            setChanges(true);
          }}
        >
          <SafetyFormSection
            {...{
              form,
              sign,
              teams: safetyFormData?.teams || [],
              nameKey: NAME_KEY?.[safetyCategory],
              safetyCategory,
              rowObject,
              formFields,
              accessToken,
              witnessCount,
              selectedTeam,
              isDarkMode,
              uploadedFiles,
              driveFunctions,
              onDelete,
              divClickable,
              setSign,
              witnessSignings,
              setChanges,
              // witnessStatements,
              userConfiguration,
              folderDriveKeyName,
              refreshTable,
              onTeamSelect,
              onTeamDeselect,
              setAssignedTo,
              assignedTo,
              disabledAllFields,
              setVisualizationImages,
              visualizationImages,
              onPickerSuccess,
              setWitnessCount,
              setWitnessSignings,
              setTeamModalVisible,
              // setWitnessStatements,
              setCancelModalVisible,
              driveFolder,
              hasMissingFolders,
              setAddDriveModal,
              safetyDirectorSignature,
              setDocumentationModalFiles,
              onContinueToUpload,
              handleOnFileUploadSuccess: async (newFiles) => {
                message.loading(
                  `Attaching ${
                    newFiles?.length > 1 ? "files" : "file"
                  } to email...`
                );
                await handleUploadedFiles(newFiles);
              },
            }}
          />
        </Form>
        {askTaskModal && (
          <AskForTaskModal
            {...{
              title: "Task Request",
              span: "Would you like to create a task for this safety report?",
              askTaskModal,
              createTask() {
                message.loading({
                  content: "Opening Task Form...",
                  key: "taskLoadingFromSafety",
                });

                let tData = structuredClone(askTaskModal);
                setAskTaskModal();
                setTaskModalData(tData);
              },
              cancelTaskModal() {
                setAskTaskModal();
                onClose();
              },
            }}
          />
        )}
        {teamModalVisible && (
          <DynamicTeamModal
            {...{
              open: teamModalVisible,
              setOpen: setTeamModalVisible,
              selectedTeam,
              setSelectedTeam,
              ClearOptions() {
                setTeamModalVisible(false);
              },
              onSave: (data) => {
                form.setFieldsValue({ team: data || [] });
              },
              proppedAllTeams: safetyFormData?.teams || [],
            }}
          />
        )}

        {videoTutorial && (
          <PlayVideoTutorial
            {...{
              visible: videoTutorial,
              setVisible: setVideoTutorial,
              url: videoTutorialLink,
              title: `${safetyCategory} Form Tutorial`,
            }}
          />
        )}

        <div className="safetyEmailBox">
          <EmailBoxContainer
            rowData={rowObject}
            isFromCharge
            proppedTemplateCat="Safety"
          />
        </div>

        {editLogsVisibility && (
          <MultiLevelTreeLogs
            {...{
              visible: editLogsVisibility,
              setVisible: setEditLogsVisibility,
              logsData: safetyFormData?.editLogs,
              title: `${safetyCategory} Logs`,
              viewLogs: safetyFormData?.viewLogs,
            }}
          />
        )}

        {visibleCreationProgress && creationProgresses && (
          <ProgressComponent
            {...{
              categoryName: "Safety",
              actionType:
                actionType === "Delete"
                  ? "Delete"
                  : !!defaultId
                  ? "Update"
                  : "Create",
              visibleCreationProgress,
              creationProgresses,
              closeModal: () => {
                const { deletePostLogsBody = {} } = visibleCreationProgress;
                setVisibleCreationProgress(false);
                actionType === "Delete"
                  ? onClose({ deleteAction: true, deletePostLogsBody })
                  : onClose();
              },
            }}
          />
        )}
        {addDriveModal && (
          <AddDriveFolderModal
            {...{
              open: addDriveModal,
              onCancel: () => setAddDriveModal(false),
              folderData: driveKeys,
              rowData: rowObject,
              setDriveFolder,
              driveRequest,
              hasMissingFolders,
              callBack: () => {
                updateRef.current = 1;
              },
            }}
          />
        )}
        {cancelModalVisible && (
          <SafetyWarningSection
            {...{
              defaultId,
              onSave,
              cancelModal: cancelModalVisible,
              setCancelModal: setCancelModalVisible,
              onClose,
              onContinueToUpload,
              setActionType,
              safetyCategory,
              isDarkMode,
              onDelete() {
                setCancelModalVisible(false);
                setActionType("Delete");
                deleteSafety({
                  dispatch,
                  nameKey: NAME_KEY?.[safetyCategory],
                  category: safetyCategory,
                  rowObject,
                  notifications,
                  notifAction: DELETE_ACTION?.[safetyCategory],
                  authenticatedUser,
                  userConfiguration,
                  refreshTable,
                  setVisibleCreationProgress,
                  updateProgressStatus,
                  saveAddedLogs,
                });
              },
            }}
          />
        )}

        {}

        {!!openSort && (
          <SortFields
            {...{
              visible: !!openSort,
              setVisible: setOpenSort,
              rowData: dynamicFields,
              saveText: openSort === "pdf" ? "Export to PDF" : "Save",
              onFinish: (res, newLog) => {
                if (openSort === "pdf") {
                  const tmpRes = res.reduce((acc, el) => {
                    const existingCategory = acc.find(
                      (item) => item.title === el.category
                    );
                    if (existingCategory) {
                      existingCategory.fields.push(el);
                    } else {
                      acc.push({ title: el.category, fields: [el] });
                    }
                    return acc;
                  }, []);

                  generatePdf(tmpRes);
                } else {
                  saveSortedFields({
                    res,
                    newLog,
                    setNewSortFields,
                    programFields,
                    category: safetyCategory,
                    saveAddedLogs,
                    fieldName: "Safety Dynamic Fields",
                  });
                }
              },
              isDarkMode,
              fieldKey: "category",
              title: "Safety - " + safetyCategory,
              sortCategories: true,
              openPreview: () => setWordPreview(true),
            }}
          />
        )}

        {taskModalData && (
          <NewTaskModal
            {...{
              newTaskModal: !!taskModalData,
              setNewTaskModal() {
                setTaskModalData({});
                setVisible(false);
              },
              customTaskProps: {
                rowData: taskModalData,
                taskTopic: "Safety",
                taskTopicId: taskModalData?.safetyApplicationId,
                taskRelatedTo: taskModalData?.safetyName,
              },
            }}
          />
        )}
        <SafetyExportPreview
          {...{
            wordPreview,
            setWordPreview,
            rowObject,
            safetyCategory,
            currentProject,
            getInitialExportData,
          }}
        />

        {emailBoxes?.length > 0 && (
          <div className="safetyEmailBox">
            <EmailBox
              {...{
                rowData: rowObject,
                onClose: () => {
                  if (attachments.length > 0) setAttachments([]);
                  closeEmailBox(0);
                },
                attachments,
                openEmailBox,
              }}
            />
          </div>
        )}

        {visibleReport ? (
          reportEditAccess ? (
            <DynamicReportDesigner
              {...{
                visible: visibleReport,
                onCancel: () => setVisibleReport(false),
                recordId: rowObject?.safetyApplicationId || "",
                customCategoryName: `${
                  rowObject?.safetyApplicationCategory || "Safety"
                } Report`,
                customPartitionKeys: { identityId: "safetyApplicationId" },
                setVisibleReport,
                sendByEmail: true,
                rowData: rowObject,
                customData: {
                  ["safety " + rowObject?.safetyApplicationCategory]: [
                    rowObject,
                  ],
                  Projects: [currentProject],
                },
              }}
            />
          ) : (
            <Report
              {...{
                isModalVisible: visibleReport,
                onCancel: () => setVisibleReport(false),
                recordId: rowObject?.safetyApplicationId || "",
                customCategoryName: `${
                  rowObject?.safetyApplicationCategory || "Safety"
                } Report`,
                customPartitionKeys: { identityId: "safetyApplicationId" },
                setVisibleReport,
                sendByEmail: true,
              }}
            />
          )
        ) : null}

        {tasksVisible && (
          <InspectionModal
            {...{
              visible: tasksVisible,
              onCancel: () => setTasksVisible(false),
              title: `Tasks for ${rowObject?.safetyName}`,
              width: 1600,
              footer: false,
              closable: true,
              close: <XIcon fill="#333238" />,
              className: `header-tasks-modal-light`,
              transitionName: "linear",
              isDarkMode,
            }}
          >
            <TasksBasePage
              {...{
                customTaskProps: {
                  rowData: rowObject,
                  taskTopic: "Safety",
                  taskTopicId: rowObject?.safetyApplicationId,
                  taskTopicName: rowObject?.safetyName,
                },
              }}
            />
          </InspectionModal>
        )}
        {!!documentationModalFiles && (
          <DocumentationModal
            {...{
              visible: !!documentationModalFiles,
              setVisible: setDocumentationModalFiles,
              viewTitle: rowObject?.safetyName,
              categoryName: "Safety",
              docType: rowObject?.safetyApplicationCategory,
              folderId: driveFolder,
              recordId: rowObject?.safetyApplicationId,
              recordValue: rowObject?.safetyName,
              docStatus: rowObject?.safetyApplicationStatus,
              docStatuses: statusesColors,
              googleDriveUploads: documentationModalFiles,
              setDocumentation: () => {},
              recordName: rowObject?.safetyName,
              record: rowObject,
              accessToken,
              teamsConfiguration: selectedTeam.map((team) => ({
                teamName: team?.value,
                members: team?.members,
              })),
              isModalOpenedFromFileItem: true,
              onSaveParentModal: onSave,
            }}
          />
        )}
      </LoadableComp>
    </Modal>
  );
}

export default NewSafetyModal;
