import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const LockPliInSchedule = (props) => {
  return (
    <div>
      {!!props?.node?.data?.locked ? (
        <LockOutlined
          style={{ color: "red" }}
          onClick={() => {
            props?.unlockPliIfPrevScheduleDeleted(
              props?.serviceId,
              props?.elevationIndex,
              props?.node?.data,
              true
            );
          }}
        />
      ) : (
        <UnlockOutlined
          style={{ color: "green" }}
          onClick={() => {
            props?.unlockPliIfPrevScheduleDeleted(
              props?.serviceId,
              props?.elevationIndex,
              props?.node?.data,
              true
            );
          }}
        />
      )}
    </div>
  );
};

export default LockPliInSchedule;
