import React from "react";
import { Collapse } from "antd";
import { currencyFormatter } from "src/components/SidebarPages/utils/currencyFormatter";
import AdditionalRentalChart from "src/components/SidebarPages/Estimations/DataEntryGrid/subcomponents/rentalCalculator/rentalCalcChart/AdditionalRentalChart";
import { FooterAmountsRental } from "src/components/SidebarPages/Estimations/DataEntryGrid/subcomponents/rentalCalculator/RentalCalculator";
import { CollapseIcon } from "src/icons";
import "./AdditRentalsDetails.scss";

function AdditRentalsDetails(props) {
  const { service = {} } = props;

  return (
    <div className="additrentalsdetailswrapper">
      <Collapse
        className="collapseContentDetails"
        expandIcon={({ isActive }) => (
          <div
            className="dropdownIconContainer"
            style={{
              // color: "#fff",
              // fill: "#fff",
              // backgroundColor: isActive
              //   ? "#5b5b5b"
              //   : "#1264A3",
              // background: "red",
              // width: "32px",
              // height: "32px",
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              // borderRadius: "50%",
            }}
          >
            {!isActive ? (
              <CollapseIcon
                style={{
                  transform: "rotate(-90deg)",
                }}
              />
            ) : (
              <CollapseIcon />
            )}
          </div>
        )}
        style={{ background: "#20212d" }}
      >
        <Collapse.Panel
          className="panelContentDetails"
          style={{ background: "#20212d" }}
          header={
            <div className="addonsHeader elevation-panel-header ag-theme-alpine-dark">
              Additional Rentals - (PRICE{" "}
              {`${currencyFormatter(
                service?.additionalRentalTerms?.newPrice ?? 0
              )}`}
              )
            </div>
          }
        >
          <AdditionalRentalChart
            {...{ amounts: service?.additionalRentalTerms }}
          />
          <br />
          <FooterAmountsRental
            {...{
              inputVals: service?.additionalRentalTerms,
              totalities: service?.additionalRentalTerms,
              //   onEditRental: () => (),
            }}
          />{" "}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default AdditRentalsDetails;
