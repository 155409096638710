const finderMapper = {
  routeConfig: { title: "title", path: "path" },
  sidebarConfig: { title: "title", path: "to" },
  searchConfig: { title: "title", path: "to" },
};

export const getAccessRights = ({ userConfiguration, title, child, path }) => {
  const {
    routeConfig = [],
    sidebarConfig = [],
    searchConfig = [],
  } = userConfiguration || {};

  const accessRightsFinder =
    (category) =>
    ({
      [finderMapper?.[category]?.title]: arrTitle,
      [finderMapper?.[category]?.path]: arrPath,
    }) =>
      !!title
        ? title.toLowerCase() === arrTitle.toLowerCase()
        : path === arrPath;

  const childAccessFinder = (arr = []) =>
    arr.reduce((acc, curr) => {
      const { title: childTitle, children = [] } = curr;

      return !acc && childTitle === child
        ? curr
        : !!children.length
        ? childAccessFinder(children)
        : acc;
    });

  const routeConfigObj = routeConfig.find(accessRightsFinder("routeConfig"));
  const sidebarConfigObj = sidebarConfig.find(
    accessRightsFinder("sidebarConfig")
  );
  const searchConfigObj = searchConfig.find(accessRightsFinder("searchConfig"));

  const childObjConfig =
    !!child && childAccessFinder(routeConfigObj?.children || []);

  return {
    routeConfig: {
      read: !!routeConfigObj,
      ...routeConfigObj,
    },

    sidebarConfig: {
      read: !!sidebarConfigObj,
      ...sidebarConfigObj,
    },

    searchConfig: {
      read: !!searchConfigObj,
      ...searchConfigObj,
    },

    child: {
      read: !!childObjConfig,
      ...childObjConfig,
    },
  };
};
