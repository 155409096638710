import { groupBy } from "lodash";
import { forwardRef, useContext, useImperativeHandle, useMemo } from "react";

import { useResponsive } from "src/hooks";
import { PersonLocationIcon } from "src/assets/icons";
import PayrollLiveContext from "../../PayrollLiveContext";
import { EmployeeReportType } from "../../payrollLiveTypes";

import "./MapStatusCards.scss";

const MapStatusCards = forwardRef((_, ref) => {
  const { filters, setFilters, employeesReport, mapRef } =
    useContext(PayrollLiveContext);

  const { width } = useResponsive();

  const liveStatusTypes = useMemo(() => {
    return groupBy(
      employeesReport,
      // employeesReport.filter(
      //   (el) => el?.color !== "#e9c466" && el?.color !== "#7FB5B5"
      // ),
      (el: EmployeeReportType) => el?.liveStatus
    );
  }, [employeesReport]);

  // console.log("liveStatusTypes: ", liveStatusTypes);

  // const noMatchEmployees = useMemo(() => {
  //   return employeesReport.filter((el) => el?.color === "#e9c466");
  // }, [employeesReport]);

  // const unScheduledMatch = useMemo(() => {
  //   return employeesReport.filter((el) => el?.color === "#7FB5B5");
  // }, [employeesReport]);

  function liveStatusFilter(status: string) {
    let tmpLiveStatusFilters = filters.liveStatus;
    if (tmpLiveStatusFilters.includes(status)) {
      tmpLiveStatusFilters = tmpLiveStatusFilters.filter(
        (sts: string) => sts !== status
      );
    } else {
      tmpLiveStatusFilters = tmpLiveStatusFilters.concat(status);
    }
    let coordinateMarkers = [];
    for (const empStatus in liveStatusTypes) {
      const statusEmployees = liveStatusTypes[empStatus];
      if (tmpLiveStatusFilters.includes(empStatus)) {
        coordinateMarkers = coordinateMarkers.concat(
          statusEmployees.flatMap((emp) =>
            emp?.punchCoordinates?.lat ? emp?.punchCoordinates : []
          )
        );
      }
    }
    setFilters((prev) => ({
      ...prev,
      liveStatus: tmpLiveStatusFilters,
    }));
    if (mapRef.current) {
      mapRef.current.fitBoundsToMarkers(coordinateMarkers);
    }
  }

  useImperativeHandle(ref, () => {
    return {};
  });

  return (
    <section className="map-status-cards">
      {Object.keys(liveStatusTypes).map((key) => {
        return (
          <div
            key={key}
            className={`status-card ${key
              .replaceAll(" ", "")
              .replaceAll("'", "")} ${
              filters.liveStatus.includes(key) ? "active" : ""
            }`}
            onClick={() => liveStatusFilter(key)}
          >
            <span style={{ fontWeight: 600 }}>
              {liveStatusTypes?.[key]?.length}
            </span>
            {` ${key}`}
            {width >= 1180 ? <PersonLocationIcon fill="#fff" /> : null}
          </div>
        );
      })}
      {/* {noMatchEmployees?.length ? (
        <div
          className={`status-card no-match ${
            filters.liveStatus.includes("no-match") ? "active" : ""
          }`}
          onClick={() => liveStatusFilter("no-match")}
        >
          <span style={{ fontWeight: 600 }}>{noMatchEmployees?.length}</span>
          {` ${"Don't Match"}`}
          {width >= 1180 ? <PersonLocationIcon /> : null}
        </div>
      ) : null}
      {unScheduledMatch?.length ? (
        <div
          className={`status-card un-scheduled ${
            filters.liveStatus.includes("un-scheduled") ? "active" : ""
          }`}
          onClick={() => liveStatusFilter("un-scheduled")}
        >
          <span style={{ fontWeight: 600 }}>{unScheduledMatch?.length}</span>
          {` ${"Unscheduled"}`}
          {width >= 1180 ? <PersonLocationIcon /> : null}
        </div>
      ) : null} */}
      <div className="total-employees-count">
        Total Employees on Site{" "}
        <span>
          {
            employeesReport.filter((el) => el?.liveStatus !== "No Punch")
              ?.length
          }
        </span>
      </div>
    </section>
  );
});
export default MapStatusCards;
