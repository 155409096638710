import { googleDriveUploadsLogs } from "../../../../../hooks/useUploadedFiles";
import { getChangedData } from "../../../Accounting/components/utilities";
import dayjs from "dayjs";

/**
 * Creates a log object based on the provided record, previous record, and type.
 *
 * @param {Object} record - The current record object.
 * @param {Object} prevRecord - The previous record object.
 * @param {string} type - The type of log object.
 * @returns {Object} The log object containing information about the record changes.
 */
const createLogObject = (record = {}, prevRecord = {}, type = "") => {
  const logObject = {
    recordId: prevRecord?.todoId,
    recordName: prevRecord?.todoName,
    category: `${type}`,
    actionType: `${type}`,
    topic: prevRecord?.taskRelatedTo,
    currentData: {},
    previousData: {},
    updatedKeys: [],
  };

  const keysToCheck = {
    ...(record.hasOwnProperty("privateOnlyTo") && {
      privateOnlyTo: record.privateOnlyTo,
    }),
    ...(record.hasOwnProperty("recordName") && {
      recordName: record.recordName,
    }),
    ...(record.hasOwnProperty("todoCategory") && {
      todoCategory: record.todoCategory,
    }),
    ...(record.hasOwnProperty("subRecordName") && {
      subRecordName: record.subRecordName,
    }),
    ...(record.hasOwnProperty("todoCategory") && {
      todoCategory: record.todoCategory,
    }),
    ...(record.hasOwnProperty("todoDueDate") && {
      todoDueDate: record.todoDueDate,
    }),
    ...(record.hasOwnProperty("todoName") && { todoName: record.todoName }),
    ...(record.hasOwnProperty("todoDetails") && {
      todoDetails: record.todoDetails,
    }),
  };

  for (let key in keysToCheck) {
    let result;
    if (!!keysToCheck?.[key] && !!prevRecord?.[key]) {
      result = getChangedData(keysToCheck?.[key], prevRecord?.[key]);
    } else {
      continue;
    }
    if (result !== false) {
      Object.assign(logObject.currentData, {
        [key]:
          key === "todoDueDate"
            ? dayjs(result?.curr).format("MM/DD/YY hh:mm")
            : result?.curr,
      });

      Object.assign(logObject.previousData, {
        [key]:
          key === "todoDueDate"
            ? dayjs(result?.prev).format("MM/DD/YY hh:mm")
            : result?.prev,
      });

      logObject.updatedKeys.push(key);
    }
  }

  return logObject;
};

/**
 *
 * @param {Object} record - the record to be saved
 * @param {String} type - the type of action
 * @param {Object} prevRecord - the previous record
 * @param {Function} setRowObject - function to set the row object
 * @param {Array} formattedFiles - the formatted files
 * @param {Function} saveAddedLogs - function to save the added logs
 */
async function postToDoLogs({
  record = null,
  type = "",
  prevRecord = null,
  setRowObject = () => {},
  formattedFiles = [],
  saveAddedLogs = async () => {},
}) {
  if (type === "Edit") {
    let newEditLog = createLogObject(record, prevRecord, type);
    const uploadsLogs = googleDriveUploadsLogs(
      prevRecord?.googleDriveUploads,
      formattedFiles
    );
    if (uploadsLogs) {
      Object.assign(newEditLog, {
        currentData: {
          ...newEditLog.currentData,
          ...uploadsLogs.curr,
        },
        previousData: {
          ...newEditLog.previousData,
          ...uploadsLogs.prev,
        },
        updatedKeys: [...newEditLog.updatedKeys, "googleDriveUploads"],
      });
    }

    if (record.privateOnlyTo !== prevRecord.privateOnlyTo) {
      Object.assign(newEditLog, {
        currentData: {
          ...newEditLog.currentData,
          private: record.privateOnlyTo ? "True" : "False",
        },
        previousData: {
          ...newEditLog.previousData,
          private: prevRecord.privateOnlyTo ? "True" : "False",
        },
      });
    }

    await saveAddedLogs(newEditLog)
      .then((res) => {
        setRowObject(null);
      })
      ?.catch((err) => {
        console.error("Error saving edit log: ", err);
      });
  }
}

export default postToDoLogs;
