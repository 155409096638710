import { Input, Form } from "antd";
import { testKey } from "../DateTimeInput/helpers";
import "./QBDateInput.scss";
import { parseTimePredict, validateTimePredict } from "./helpers";
import { useEffect, useRef } from "react";

/**
 * @param {function} customOnChange - it is executed alongside the default onChange handler
 * @param {function} customValidator - it overwrites the default validator it must include a form instance and case for submit that will return a Promise.reject() if the form is invalid and Promise.resolve() if the form is valid
 * @param {string} formItemName - it is used to append a custom string to the form item name example {"dateInputTaskStartDate"}
 * @param {boolean} required - it is used to determine if the form item is required by default it is set to true
 * @param {object} form - it is the form instance that is passed from the parent component
 * @param {boolean} hasCustomValidator - it is used to determine if the form item has a custom validator by default it is set to false
 * @param {string} validTime  - it is used to determine if the time is valid by default it is set to "future" it can also be set to "past" or 'all' for no validation
 * @param {string} customClassName - it is used to append a custom class name to the form item
 * @param {Date | Timestamp | Array} validationDate - can be a timestamp, date moment object to signify the date that will be compared for validations, in the case that it is an Array
 * there will be no time validation.
 * @param {string} dateField - it is used to determine the date field that will be used to compare the time to
 */

/**
 *
 * ? If you want to set an initial value with form.setFieldsValue() you must format the date to "HH:mm". useEffect will make the initial value to be set correctly
 */

const QBTimeInput = ({
  form,
  required = true,
  isDarkMode = false,
  validTime = "future",
  formItemName = "",
  dateField = "",
  customClassName = "",
  customOnChange = () => {},
  validationDate = null,
  defaultValue = null,
  disabled = false,
  disabledHours,
  disabledMinutes,
  setUserTime,
  label,
}) => {
  const timeRef = useRef(null);
  const watchForm = Form.useWatch(formItemName, form);

  function parseTime(value) {
    parseTimePredict({
      value,
      form,
      formItemName,
      customOnChange,
      dateField,
      validTime,
      validationDate,
      setUserTime,
    });
  }

  function onKeyDownHandler(key, value, e) {
    if (!testKey(key) && ![":", "Enter", "p", "a", "m"]?.includes(key)) {
      e.preventDefault();
      return;
    }

    if (key === "Enter") {
      e.preventDefault();
      parseTime(value);
    }
  }
  useEffect(() => {
    if (
      !timeRef.current &&
      typeof watchForm === "string" &&
      (watchForm || "")?.length > 4
    ) {
      parseTime(watchForm);
      timeRef.current = true;
    }
  }, [watchForm]);

  return (
    <div
      className={`labeled-qb-input ${customClassName} ${
        isDarkMode && "labeled-qb-input-dark"
      }`}
    >
      <div className="qb-time-input">
        <Form.Item
          {...{
            form: form,
            name: formItemName,
            validateTrigger: "onSubmit",
            rules: [
              {
                validator: (_, value) => {
                  if (value) {
                    return validateTimePredict({
                      value,
                      form,
                      formItemName,
                      validTime,
                      dateField,
                      validationDate,
                      setUserTime,
                    });
                  } else {
                    if (false) {
                      return validateTimePredict({
                        value: "",
                        form,
                        dateField,
                        formItemName,
                        error: "Time is required",
                        validTime,
                        validationDate,
                        setUserTime,
                      });
                    }
                    return Promise.resolve();
                  }
                },
              },
            ],
            required: false,
          }}
        >
          <Input
            {...{
              placeholder: "hh:mm AM/PM",
              onBlur: (e) => {
                parseTime(e.target.value);

                if (disabledHours) {
                  return disabledHours(e.target.value);
                }
              },
              onKeyDown: (e) => {
                onKeyDownHandler(e.key, e.target.value, e);
              },
              defaultValue,
              autoComplete: "off",
              disabled,
            }}
          />
        </Form.Item>
        <div
          className="qb-error"
          id={`qb-time-error-text${formItemName}`}
        ></div>
      </div>
    </div>
  );
};

export default QBTimeInput;
