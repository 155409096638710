import { Modal, Table, Tag, Space } from "antd";
import React from "react";
import { CloseIconModal, CloseIconModalHeader } from "../../../../../../assets";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { formatCurrency } from "../../../../utils";
import { currencyFormatter } from "../../../../utils/currencyFormatter";
import "./InvoiceHistoryReport.scss";
function InvoiceHistoryReport({ open, setOpen, data, invoice }) {
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjsNY(date).format("MM/DD/YYYY"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (data) => {
        return data?.includes("payment") ? (
          <Tag color="green">PAYMENT</Tag>
        ) : (
          <Tag color="blue">CREDIT</Tag>
        );
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => currencyFormatter(amount),
    },
    {
      title: "Memo",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "Reference #",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
    },
    // {
    //   title: "Balance",
    //   dataIndex: "balance",
    //   key: "balance",
    //   render: (balance) => `$${balance.toFixed(2)}`,
    // },
  ];

  console.log("InvoiceHistoryReport", invoice);

  return (
    <Modal
      className="invoiceHistoryReportModal"
      title="Invoice History Report"
      open={open}
      width="80%"
      onOk={() => {
        setOpen(false);
      }}
      onCancel={() => {
        setOpen(false);
      }}
      closeIcon={<CloseIconModalHeader />}
      footer={null}
      allowClose={true}
    >
      <div className="invoiceHistoryBodyWrapper">
        <div className="invoiceHistoryReportHeader">
          <div className="headerItem">
            <strong>Billed to:</strong> {invoice?.accountName || "N/A"}
          </div>
          <div className="headerItem">
            <strong> {invoice?.projectName || "N/A"}</strong>
          </div>
          <div className="headerItem">
            <strong>Invoice Date:</strong> {invoice?.invoiceDate || "N/A"}
          </div>
          <div className="headerItem">
            <strong>Status:</strong> {invoice?.invoiceStatus || "N/A"}
          </div>
        </div>

        <div className="invoiceHistoryTableWrapper">
          <Table columns={columns} dataSource={data || []} pagination={false} />
        </div>

        <div className="invoiceHistoryReportFooter">
          <div className="footerItem">
            <strong>Total Amount:</strong>
            {formatCurrency(invoice?.totalInvoiceAmount)}
          </div>
          <div className="footerItem">
            <strong>Balance:</strong> {formatCurrency(invoice?.openBalance)}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default InvoiceHistoryReport;
