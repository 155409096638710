import React from "react";
import { Select } from "antd";

// noinspection JSUnusedGlobalSymbols
class MultipleChoiceRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.node.data[props.column.colId],
    };
    this.editorRef = React.createRef();
  }

  handleChange = (value) => {
    this.setState({ selected: value });
    this.props.node.setDataValue(this.props.column.colId, value);
  };

  // handleBlu

  getValue = () => {
    return this.state.selected;
  };

  isPopup = () => {
    return false;
  };

  setRowHeight = () => {
    if (this.state.selected?.length >= 3) {
      this.props.node.setRowHeight(59);
    } else {
      this.props.node.setRowHeight(32);
    }
    this.props.api.onRowHeightChanged();
  };

  render() {
    this.setRowHeight();
    return (
      <Select
        ref={this.editorRef}
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="Please select"
        onChange={this.handleChange}
        value={this.state.selected}
      >
        <Select.Option value="chimney">Chimney</Select.Option>
        <Select.Option value="skylight">Skylight</Select.Option>
        <Select.Option value="acUnit">AC Unit</Select.Option>
        <Select.Option value="bulkhead">Bulkhead</Select.Option>
        <Select.Option value="darner">Dormer</Select.Option>
        <Select.Option value="mechanical equipment">
          Mechanical Equipment
        </Select.Option>{" "}
        <Select.Option value="antenna">Antenna</Select.Option>
      </Select>
    );
  }
}

export default MultipleChoiceRenderer;
