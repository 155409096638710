import { useContext, useEffect, useState } from "react";
import "./VideoTutorial.scss";
import VideoTutorialContext from "./contexts/VideoTutorialContext";
import { Button, Drawer, Dropdown, Tooltip, message } from "antd";
import CategoriesContainer from "./components/CategoriesContainer/CategoriesContainer";
import { VideoPlayerModal } from "./components/VideoPlayerModal/VideoPlayerModal";
import { fetchAllData, updateDocumentTitle } from "../../../../utils";
import { Modal } from "antd";
import { XIcon } from "../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import { ConfirmationModal, MondayButton } from "../../../commonComponents";
import { API } from "aws-amplify";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import { updatePreferences } from "../../../../actions/preferences";
import { useDispatch } from "react-redux";
import {
  ClearOutlined,
  SettingOutlined,
  UndoOutlined,
} from "@ant-design/icons";

const VideoTutorial = () => {
  const {
    selectedVideoCategory,
    selectedVideoSubCat,
    selectedVideoId,
    primaryArray,
    setPrimaryArray,
    setNewVideos,
    unsavedPrimaryArray,
    setUnsavedPrimaryArray,
    setActivePrimaryCat,
    setFavourites,
    setSubCatVideos,
    visible,
    setVisible,
    setFilterInput,
    isDarkMode,
    customeModal,
    setAllLogs,
    setFilteredLogs,
    setVideoActive,
    setPlayingVideo,
    setPlayedVideos,
    setSubCategoryName,
    setCustomeModal,
  } = useContext(VideoTutorialContext);

  const { programFields = [] } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteType, setDeleteType] = useState("reset");

  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(endDate.getDate() - 7);

  const startDateTimestamp = startDate.getTime();
  const endDateTimestamp = endDate.getTime();

  const videoTutorials =
    programFields.filter(
      (item) => item.fieldName === "Portal Video Tutorials"
    )[0]?.fieldOptions ?? [];

  const { preferences } = allPreferences;

  const dispatch = useDispatch();

  const mergeArrays = (dbArray, preferences) => {
    function mergeSubCategories(dbSubCategories, prefSubCategories) {
      function mergeVideos(dbVideos, prefVideos) {
        const mergedVideos = [];
        const mergedVideos2 = [];

        // Sort videos based on preferences order
        dbVideos.sort((a, b) => {
          const aIndex = prefVideos.findIndex((item) => item.id === a.id);
          const bIndex = prefVideos.findIndex((item) => item.id === b.id);
          return aIndex - bIndex;
        });

        for (const dbVideo of dbVideos) {
          const matchingDbVideo = prefVideos.find(
            (prefVideo) => prefVideo.id === dbVideo.id
          );

          if (matchingDbVideo) {
            let clonedVideo = _.cloneDeep(dbVideo);

            mergedVideos.push(clonedVideo);
          } else {
            mergedVideos2.push(dbVideo);
          }
        }

        return [...mergedVideos, ...mergedVideos2];
      }

      const mergedSubCategories = [];
      const mergedSubCategories2 = [];

      // Sort subCategories based on preferences order
      dbSubCategories.sort((a, b) => {
        const aIndex = prefSubCategories.findIndex((item) => item.id === a.id);
        const bIndex = prefSubCategories.findIndex((item) => item.id === b.id);
        return aIndex - bIndex;
      });

      for (const dbSubCategory of dbSubCategories) {
        const matchingDbSubCategory = prefSubCategories.find(
          (prefSubCat) => prefSubCat.id === dbSubCategory.id
        );
        if (matchingDbSubCategory) {
          mergedSubCategories.push({
            ...dbSubCategory,
            videos: mergeVideos(
              dbSubCategory.videos,
              matchingDbSubCategory.videos
            ),
          });
        } else {
          mergedSubCategories2.push(dbSubCategory);
        }
      }

      return [...mergedSubCategories, ...mergedSubCategories2];
    }

    const mergedArray = [];
    const secondArray = [];

    // Sort dbArray based on preferences order
    dbArray.sort((a, b) => {
      const aIndex = preferences.findIndex((item) => item.id === a.id);
      const bIndex = preferences.findIndex((item) => item.id === b.id);
      return aIndex - bIndex;
    });

    for (const dbItem of dbArray) {
      const prefItem = preferences.find((item) => item.id === dbItem.id);
      if (prefItem) {
        mergedArray.push({
          ...dbItem,
          subCategories: mergeSubCategories(
            dbItem.subCategories,
            prefItem.subCategories
          ),
        });
      } else {
        secondArray.push(dbItem); // Add the dbItem directly if it's not found in preferences
      }
    }

    return [...mergedArray, ...secondArray];
  };

  const getVideoUrl = (primaryArray) => {
    primaryArray.forEach((category) => {
      if (category.id.toString() === selectedVideoCategory) {
        setActivePrimaryCat(category.id);
        category.subCategories.forEach((subCategory) => {
          setSubCategoryName(subCategory.subCategoryName);
          if (
            subCategory.subCategoryName ===
            selectedVideoSubCat.replaceAll("-", " ")
          ) {
            setSubCatVideos(subCategory.videos);
            subCategory.videos.forEach((video) => {
              if (video.id.toString() === selectedVideoId) {
                setVideoActive(video.videoLink);
                setPlayingVideo(video);
                setCustomeModal(false);
              }
            });
          }
        });
      }
    });
  };

  const getNewVideos = (videosArray) => {
    const result = [];

    if (videosArray.length === 0) return [];

    videosArray.forEach((category) => {
      category.subCategories.forEach((subCategory) => {
        subCategory.videos.forEach((video) => {
          if (!video.usersHaveSeen.includes(userConfiguration.identityId)) {
            const newVideo = {
              category: category.categoryName,
              subCategory: subCategory.subCategoryName,
              id: video.id,
              lengthVideoInMin: video.lengthVideoInMin,
              videoLink: video.videoLink,
              videoName: video.videoName,
              usersHaveSeen: video.usersHaveSeen,
              description: video?.description || "",
            };

            result.push(newVideo);
          }
        });
      });
    });

    return result;
  };

  const getVideosFromIdArray = (arrayOfId) => {
    const result = [];

    if (arrayOfId.length === 0) return [];

    videoTutorials.forEach((category) => {
      category.subCategories.forEach((subCategory) => {
        subCategory.videos.forEach((video) => {
          if (arrayOfId.includes(video.id)) {
            result.push({
              category: category.categoryName,
              subCategory: subCategory.subCategoryName,
              id: video.id,
              lengthVideoInMin: video.lengthVideoInMin,
              videoLink: video.videoLink,
              videoName: video.videoName,
              usersHaveSeen: video.usersHaveSeen,
              description: video?.description || "",
            });
          }
        });
      });
    });

    return result;
  };

  const getVideosFromIdObject = (arrayOfObjects) => {
    const result = [];

    if (arrayOfObjects.length === 0) return [];

    videoTutorials.forEach((category) => {
      category.subCategories.forEach((subCategory) => {
        subCategory.videos.forEach((video) => {
          const exists = arrayOfObjects.find((item) => item.id === video.id);

          if (!!exists) {
            result.push({
              category: category.categoryName,
              subCategory: subCategory.subCategoryName,
              id: video.id,
              lengthVideoInMin: video.lengthVideoInMin,
              videoLink: video.videoLink,
              videoName: video.videoName,
              usersHaveSeen: video.usersHaveSeen,
              description: video?.description || "",
              time: exists.time,
            });
          }
        });
      });
    });

    return result;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clonedVideoTutorials = structuredClone(videoTutorials);

        if (!preferences.hasOwnProperty("videoPreferences")) {
          const updatedVideoPreferences = clonedVideoTutorials.map(
            (category) => ({
              ...category,
              subCategories: category.subCategories.map((subCategory) => ({
                ...subCategory,
                videos: subCategory.videos.map((video) => ({ id: video.id })),
              })),
            })
          );

          const preferencesToSave = {
            ...preferences,
            videoPreferences: {
              order: updatedVideoPreferences,
              playedVideos: [],
              favourites: [],
            },
          };

          API.patch("preferences", `/preferences`, {
            body: {
              preferences: preferencesToSave,
            },
          })
            .then(() => {
              dispatch(updatePreferences(preferencesToSave));
            })
            .catch((err) => console.error(err));
        } else {
          const mergedArray = mergeArrays(
            clonedVideoTutorials,
            preferences.videoPreferences.order
          );

          getVideoUrl(mergedArray);
          setPrimaryArray(mergedArray);
          setUnsavedPrimaryArray(mergedArray);

          const updatedNewVideos = getNewVideos(mergedArray);
          const updatedPlayedVideos = getVideosFromIdObject(
            preferences?.videoPreferences?.playedVideos || []
          );
          const updatedFavourites = getVideosFromIdArray(
            preferences?.videoPreferences?.favourites || []
          );

          setNewVideos(updatedNewVideos);
          setPlayedVideos(updatedPlayedVideos);
          setFavourites(updatedFavourites);

          const response = await fetchAllData({
            endpoint: "editLogs",
            resultPosition: "editLogs",
            resultId: "logId",
            otherStringParams: {
              getMaxLimit: "true",
              filters: JSON.stringify([
                {
                  operator: "AND",
                  conditions: [
                    {
                      column: "category",
                      value: "Video Tutorials",
                      formula: "is",
                    },
                    {
                      column: "updatedAt",
                      value: [startDateTimestamp, endDateTimestamp],
                      formula: "is between",
                    },
                  ],
                },
              ]),
            },
          });

          const sortedData = response.sort((a, b) => {
            return b.updatedAt - a.updatedAt;
          });
          setAllLogs(sortedData);
          setFilteredLogs(sortedData.slice(0, 10));

          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, [JSON.stringify(allPreferences?.preferences), programFields, visible]);

  const handleModalCancel = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
    setActivePrimaryCat();
    setSubCatVideos();
    setFilterInput("");
  };

  const handleSave = () => {
    message.loading({ content: "Saving changes...", duration: 0 });

    const updatedOrder = primaryArray.map((category) => ({
      ...category,
      subCategories: category.subCategories.map((subCategory) => ({
        ...subCategory,
        videos: subCategory.videos.map((video) => ({ id: video.id })),
      })),
    }));

    const preferencesToSave = {
      ...allPreferences.preferences,
      videoPreferences: {
        ...allPreferences.preferences.videoPreferences,
        order: updatedOrder,
      },
    };

    setDisabled(true);

    API.patch("preferences", "/preferences", {
      body: {
        preferences: preferencesToSave,
      },
    })
      .then(() => {
        message.destroy();
        message.success("Changes saved successfully!");
        dispatch(updatePreferences(preferencesToSave));
        setUnsavedPrimaryArray(structuredClone(primaryArray));
      })
      .catch((err) => {
        message.destroy();
        message.error("There was an error while saving this");
      });
  };

  const handleCloseVideoTutorials = () => {
    setShowUnsavedChangesModal(false);
    setVisible(false);
  };

  const handleClearOrderPreference = () => {
    setDeleteType("order");
    setOpenConfirmModal(true);
  };

  const clearOrderPreferences = () => {
    message.loading({
      content: "Reseting Order",
      duration: 0,
    });

    const updatedVideoPreferences = videoTutorials.map((category) => ({
      ...category,
      subCategories: category.subCategories.map((subCategory) => ({
        ...subCategory,
        videos: subCategory.videos.map((video) => ({ id: video.id })),
      })),
    }));

    const preferencesToSave = {
      ...preferences,
      videoPreferences: {
        ...preferences.videoPreferences,
        order: updatedVideoPreferences,
      },
    };

    API.patch("preferences", `/preferences`, {
      body: {
        preferences: preferencesToSave,
      },
    })
      .then(() => {
        message.destroy();
        message.success("Reseted Order!");
        dispatch(updatePreferences(preferencesToSave));
      })
      .catch((err) => console.error("Error saving to preferences", err));
  };

  const resetPreferences = () => {
    message.loading({
      content: "Reseting Video Preferences",
      duration: 0,
    });

    const updatedVideoPreferences = videoTutorials.map((category) => ({
      ...category,
      subCategories: category.subCategories.map((subCategory) => ({
        ...subCategory,
        videos: subCategory.videos.map((video) => ({ id: video.id })),
      })),
    }));

    const preferencesToSave = {
      ...preferences,
      videoPreferences: {
        order: updatedVideoPreferences,
        playedVideos: [],
        favourites: [],
      },
    };

    API.patch("preferences", `/preferences`, {
      body: {
        preferences: preferencesToSave,
      },
    })
      .then(() => {
        message.destroy();
        message.success("Reseted Video Preferences successfully!");
        dispatch(updatePreferences(preferencesToSave));
      })
      .catch((err) => console.error("Error saving to preferences", err));
  };

  const items = [
    {
      key: "1",
      label: (
        <Tooltip title="Clear order preference" placement="left">
          <MondayButton
            data-testid="clear-preference"
            className="mondayButtonBlue"
            Icon={<ClearOutlined />}
            onClick={handleClearOrderPreference}
          />
        </Tooltip>
      ),
    },
    {
      key: "2",
      label: (
        <Tooltip title="Reset preference" placement="left">
          <MondayButton
            className="mondayButtonBlue"
            Icon={<UndoOutlined />}
            onClick={() => {
              setDeleteType("reset");
              setOpenConfirmModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Drawer
        rootClassName={`tutorial-modal ${isDarkMode && "tutorial-modal-dark"}`}
        open={visible}
        onClose={() => {
          if (isEqual(primaryArray, unsavedPrimaryArray)) {
            updateDocumentTitle(); // reset document title to "Lead Manager";
            setVisible(false);
            handleModalCancel();
          } else {
            setShowUnsavedChangesModal(true);
          }
        }}
        title={"Video Tutorials"}
        footer={null}
        closable={true}
        mask={false}
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: "Video Tutorial" });
        }}
        extra={
          <Dropdown
            overlayClassName={isDarkMode && "videoTutorial-dropdownDark"}
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <Button shape="circle" icon={<SettingOutlined />} />
          </Dropdown>
        }
      >
        <Modal
          {...{
            open: showUnsavedChangesModal,
            closeIcon: <XIcon />,
            onCancel: () => {
              setShowUnsavedChangesModal(false);
            },
            title: "You have unsaved changes",
            footer: [
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <MondayButton
                  onClick={handleCloseVideoTutorials}
                  Icon={<XIcon />}
                  className="mondayButtonRed"
                >
                  Cancel Changes
                </MondayButton>
                <MondayButton
                  onClick={() => {
                    handleSave();
                    handleCloseVideoTutorials();
                  }}
                  Icon={<TickIcon />}
                >
                  Save Changes
                </MondayButton>
              </div>,
            ],
          }}
        ></Modal>
        <CategoriesContainer
          {...{
            disabled,
            setDisabled,
            handleSave,
            loading,
          }}
        />
        {!customeModal && <VideoPlayerModal />}
      </Drawer>
      {openConfirmModal && (
        <ConfirmationModal
          onConfirm={() =>
            deleteType === "reset"
              ? resetPreferences()
              : clearOrderPreferences()
          }
          visible={openConfirmModal}
          setVisible={setOpenConfirmModal}
          title="Confirm"
          text={`Are you sure you want to ${
            deleteType === "reset" ? "reset" : "clear order"
          } preference?`}
        />
      )}
    </>
  );
};

export default VideoTutorial;
