import { useState } from "react";
import { Flex, Modal } from "antd";
import { useParams } from "react-router-dom";

import { updateBalance } from "./balanceFixer";
import { RetainageIcon } from "../../../../../../../icons/index";
import { MondayButton } from "../../../../../../commonComponents/index";
import { CloseIconModalHeader } from "../../../../../../../assets/index";
import ClientOpenBalanceTab from "../../../../../Accounts/Tabs/Accounting/ClientOpenBalanceTab";
import { AutoFixNormalIcon } from "../../../../../Scheduling/ScheduleChangesModal/assets";

import "./BalanceReport.scss";

export default function BalanceReport({ projectData = {} }) {
  const [visible, setVisible] = useState(false);
  const { id } = useParams();

  const fixBalance = async () => {
    let { projectId = "", accountId = "" } = projectData;
    await updateBalance({ projectId, accountId, showMessage: true });
  };

  //   let { projectId = "", accountId = "" } = projectData;
  //   // message.success(id);
  //   let invoices = []; // invoices
  //   let charges = []; //credits type
  //   let payments = [];
  //   let changes = [];
  //   message.loading("Checking balance...", 0);

  //   await filterTables("invoices", "projectId", id).then((data) => {
  //     invoices = data;
  //   });
  //   await filterTables("charges", "projectId", id).then((data) => {
  //     charges = data?.filter((charge) => charge?.chargeType === "Credit Memo");
  //   });
  //   await filterTables("payments", "accountId", accountId).then((data) => {
  //     payments = data?.filter((payment) =>
  //       payment.receivedFromProjects?.find(
  //         (project) => project.projectId === projectId
  //       )
  //     );
  //   });

  //   for (const invoice of invoices) {
  //     let currentAmount = invoice.amountDue;
  //     let newAmount = 0;
  //     let invoiceAmount = forceToNumber(invoice.totalInvoiceAmount);
  //     let accumulatedPayments = payments.reduce((acc, payment) => {
  //       let invAm = payment?.invoices?.find(
  //         (inv) => inv.invoiceNumber === invoice.invoiceNumber
  //       )?.invoicePaidAmount;
  //       // test.push(payment);
  //       return acc + forceToNumber(invAm);
  //     }, 0);
  //     console.log("accumulatedPayments", accumulatedPayments);
  //     let accumulatedCharges = charges.reduce((acc, charge) => {
  //       let temp = charge?.chargeItems?.find(
  //         (item) => item.invoiceNumber === invoice.invoiceNumber
  //       )?.creditAmount;
  //       return acc + forceToNumber(temp);
  //     }, 0);
  //     newAmount = invoiceAmount - accumulatedPayments - accumulatedCharges;
  //     console.log("newAmount", {
  //       currentAmount,
  //       newAmount,
  //       accumulatedPayments,
  //       accumulatedCharges,
  //       invoiceAmount,
  //     });
  //     if (newAmount !== currentAmount) {
  //       changes.push({
  //         invoiceNumber: invoice.invoiceNumber,
  //         amountDue: currentAmount,
  //         totalInvoiceAmount: invoiceAmount,
  //         newAmount: newAmount,
  //         qbNumber: invoice?.quickBooksNumber,
  //       });
  //       await API.put("invoices", `/invoices/${invoice?.invoiceId}`, {
  //         body: {
  //           amountDue: newAmount,
  //           invoiceStatus: newAmount === 0 ? "Paid" : "Paid",
  //         },
  //       });
  //     }
  //     if (newAmount === invoiceAmount) {
  //       await API.put("invoices", `/invoices/${invoice?.invoiceId}`, {
  //         body: {
  //           invoiceStatus: "Created",
  //           amountDue: newAmount,
  //         },
  //       });
  //     }
  //     invoice.newAmount = newAmount;
  //   }

  //   if (changes.length > 0) {
  //     Swal.fire({
  //       title: "Balance Fixed",
  //       html: `The balance of the invoices has been fixed. <br> <br>`,
  //       icon: "success",
  //       showCancelButton: false,
  //       confirmButtonColor: "#3085d6",
  //       confirmButtonText: "Ok",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         window.location.reload();
  //       }
  //     });
  //   } else {
  //     // message.success("No changes needed");
  //     message.destroy();
  //     message.success("No changes needed");
  //   }
  // };
  return (
    <>
      <Flex className="openBalanceReportContainer">
        <MondayButton
          className="mondayButtonBlue"
          onClick={() => setVisible(true)}
          Icon={<RetainageIcon />}
        >
          Open Balance
        </MondayButton>{" "}
        <MondayButton
          className="mondayButtonRed"
          onClick={fixBalance}
          Icon={<AutoFixNormalIcon />}
        >
          FIX
        </MondayButton>
      </Flex>

      {visible && (
        <Modal
          title={"Open Balance"}
          visible={visible}
          className="openBalanceReportModal"
          maskClosable={true}
          destroyOnClose={true}
          onCancel={() => setVisible(false)}
          centered={true}
          closeIcon={<CloseIconModalHeader />}
          footer={null}
        >
          <ClientOpenBalanceTab id={id} filterBy="projectId" />
        </Modal>
      )}
    </>
  );
}
