import {
  Button,
  Checkbox,
  Divider,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import {
  Add,
  Tick,
} from "../../../../../../../../../../DynamicView/components/FilterView/FilterIcons";
import { Close } from "../../../../../../../../../../DynamicView/src";
import { formatCurrency } from "../../../../../../../../../../utils";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../Context/Context";
import { footerDataGenerator } from "../../../ServiceFooter/serviceFooterFunctions";
import "./CreditEnabler.scss";
import AnimatedNumber from "react-animated-number";
import { applicationHoistFooterDataProvider } from "../../../../HoistService/ServiceFooter/serviceFooterFunctions";
import { addCreditHandler, addCreditHandlerHoist } from "./helpers";
import { useSelector } from "react-redux";
import AccountingConfirmationModal from "../../../../../../../../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal";
import RichTextModalEditor from "../../../../../../../../../../../commonComponents/RichTextModalEditor/RichTextModalEditor";

function CreditEnabler({ service, color, elevation }) {
  const { darkMode, fakeApplication, isWritable } = useStateContext();
  const dispatchContext = useDispatchContext();
  const [isCreditModalVisible, setIsCreditModalVisible] = useState(false);
  const [creditState, setCreditState] = useState(0);
  const [creditAmount, setCreditAmount] = useState(0);
  const [applyAll, setApplyAll] = useState(false);
  const inputRef = useRef(null);
  const [name, setName] = useState("");
  const [eventName, setEventName] = useState("");
  const [serviceDefinitions, { proposedTypeOfWork }] = useSelector((state) => [
    state.serviceDefinitions,
    state.proposedTypeOfWork,
  ]);
  const [services, setServices] = useState([]);
  const [items, setItems] = useState([]);
  const { totalAll } = footerDataGenerator({ service: service }) || {};
  const { totalAllHoist } =
    applicationHoistFooterDataProvider({ elevation }) || {};
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
  const [note, setNote] = useState("");
  // dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication })

  const cleanUpFunc = () => {
    setCreditAmount(0);
    setCreditState(0);
    setApplyAll(false);
    setName("");
    setEventName("");
    setServices([]);
    setItems([]);
    setNote("");
  };

  const onCreditAdd = () => {
    if (creditAmount > 0 && eventName !== "" && !!eventName) {
      if (!!elevation) {
        // Hoist Type
        addCreditHandlerHoist({
          fakeApplication,
          service,
          dispatchContext,
          elevation,
          creditAmount,
          eventName,
          note,
        });
        cleanUpFunc();
        setIsCreditModalVisible(false);
      } else {
        addCreditHandler({
          fakeApplication,
          service,
          dispatchContext,
          creditAmount,
          eventName,
          note,
        });
        setIsCreditModalVisible(false);
        cleanUpFunc();
      }
    } else if (creditAmount < 0) {
      message.error("Credit amount must be greater than 0");
    } else {
      message.error("Check All Inputs");
    }
  };
  //handles the new value that we put on input
  const inputChangerHandler = (e) => {
    let maxAmount = (!!elevation ? totalAllHoist : totalAll) || 0;

    let initialValue = e;

    if (initialValue <= 0) {
      setCreditState(0);
      message.warning("Input less than OR 0!!");
    } else if (initialValue > 100) {
      setCreditState(100);
      setCreditAmount(maxAmount);
      message.warning("Input greater than 100!!");
    } else if (initialValue === 0) {
      setCreditState(0);
      setCreditAmount(0);
    } else {
      if (!!initialValue) {
        setCreditState(initialValue);
        setCreditAmount(((initialValue / 100) * maxAmount).toFixed(2));
      }
    }
  };

  const creditAmountChanger = (e) => {
    let initialValue = e;
    let maxAmount = (!!elevation ? totalAllHoist : totalAll) || 0;
    console.log("maxAmount", e);
    // try {
    // 	initialValue = Function("return " + e?.target?.value)();
    // } catch (e) {
    // 	initialValue = 0;
    // 	message.warning("Invalid input");
    // }

    if (initialValue < 0) {
      setCreditAmount(0);
      message.warning("Input less than 0!!");
    } else if (initialValue > maxAmount) {
      setCreditAmount(maxAmount);
      setCreditState(100);
      message.warning(`Input greater than Max Amount ${maxAmount}!!`);
    } else {
      if (initialValue >= 0) {
        let maxAmount = (!!elevation ? totalAllHoist : totalAll) || 0;
        setCreditAmount(initialValue);
        setCreditState(
          maxAmount > 0 ? ((initialValue * 100) / maxAmount).toFixed(2) : 0
        );
      }
    }
    // setCreditState(0);
  };

  const onChecked = (e) => {
    setApplyAll(e.target.checked);
  };
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    if (name !== "" && name.length > 5) {
      e.preventDefault();
      setItems([...items, name]);
      setName("");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    } else {
      message.error("Please enter a valid name (At least 6 Characters)");
    }
  };
  useEffect(() => {
    if (!!serviceDefinitions && isCreditModalVisible) {
      let temp = ["CREDIT"];
      serviceDefinitions.forEach((serviceDefinition) => {
        if (!!elevation) {
          // Case for Hoist
          if (serviceDefinition?.serviceName === "Hoist") {
            Array.prototype.push.apply(
              temp,
              serviceDefinition?.listOfEvents?.filter((e) =>
                e.includes("CREDIT")
              )
            );
          }
        } else {
          if (serviceDefinition.serviceName === service.label) {
            Array.prototype.push.apply(
              temp,
              serviceDefinition?.listOfEvents?.filter((e) =>
                e.includes("CREDIT")
              )
            );
          }
        }
      });
      if (temp?.length > 0) {
        setItems(temp);
      }
    }
  }, [serviceDefinitions, isCreditModalVisible]);

  return (
    <div>
      {/* <Button onClick={onCreditAdd}>Add Credit +</Button> */}
      <MondayButton
        className={`changeRetainageSOVButton ${
          isWritable ? "" : "buttonNotClickable"
        }`}
        disabled={!isWritable}
        Icon={<span>$</span>}
        onClick={() => setIsCreditModalVisible(true)}
      >
        Add Credit
      </MondayButton>
      <Modal
        wrapClassName={
          darkMode ? "change-credit-modal-dark" : "change-credit-modal-light"
        }
        destroyOnClose={true}
        title={`Add Credit for ${
          !!elevation
            ? service?.label + " > " + elevation?.elevationLabel
            : service?.label
        }`}
        width={690}
        open={isCreditModalVisible}
        onCancel={() => setIsCreditModalVisible(false)}
        footer={
          <div className="serviceFooterRetainageModal">
            <MondayButton
              className="cancelMondayButton"
              Icon={<Close />}
              onClick={() => setIsCreditModalVisible(false)}
            >
              Cancel
            </MondayButton>
            <MondayButton
              className="applyMondayButton"
              Icon={<Tick />}
              disabled={!isWritable || !eventName || !creditAmount}
              //  onClick={onCreditAdd}
              onClick={() => setConfirmModalVisibility(true)}
            >
              Apply
            </MondayButton>
          </div>
        }
        // closeIcon={darkMode ? <Close /> : <Cancel />}
      >
        <div
          className={
            darkMode
              ? "credit-enabled-modal-wrapper-dark"
              : "credit-enabled-modal-wrapper-light"
          }
        >
          <div className="inputable-element ">
            {" "}
            <label className="label-element">Select an event</label>
            <div>
              {" "}
              <Select
                style={{
                  width: 300,
                  marginLeft: 14,
                }}
                showSearch
                value={eventName}
                onSelect={(value) => {
                  setEventName(value);
                }}
                placeholder="Select or create a custom event..."
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Input
                        placeholder="Please enter item"
                        ref={inputRef}
                        value={name}
                        onChange={onNameChange}
                      />
                      <Button type="text" icon={<Add />} onClick={addItem}>
                        Add item
                      </Button>
                    </Space>
                  </>
                )}
              >
                {items.map((item) => (
                  <Select.Option key={item}>{item}</Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="inputable-element">
            {" "}
            <label className="label-element">Pick a percentage </label>{" "}
            <InputNumber
              // className="changeRetainageInput"
              min={0}
              max={100}
              addonBefore="%"
              style={{ backgroundColor: "white", borderRadius: "5px" }}
              placeholder="Enter Credit Percentage"
              value={creditState}
              onChange={inputChangerHandler}
            />{" "}
            {/* <Checkbox value={applyAll} onChange={onChecked} /> Apply to all services */}
          </div>
          <div className="inputable-element">
            {" "}
            <label className="label-element">Pick an amount</label>{" "}
            <InputNumber
              style={{
                width: 200,
                marginLeft: 14,
                backgroundColor: "white",
                borderRadius: "5px",
              }}
              min={0}
              addonBefore="$"
              placeholder="Enter Credit Amount"
              value={creditAmount}
              onChange={creditAmountChanger}
            />{" "}
            <strong className="label-element">
              SCHEDULE OF VALUE:{" "}
              <AnimatedNumber
                value={(!!elevation ? totalAllHoist : totalAll) || 0}
                formatValue={(value) => formatCurrency(value)}
              />
            </strong>
            {/* <Checkbox value={applyAll} onChange={onChecked} /> Apply to all services */}
          </div>{" "}
          <div className="inputable-element">
            <div
              className="descriptionModalContainer"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "145px",
              }}
            >
              <RichTextModalEditor
                {...{
                  darkMode,
                  value: note,
                  onConfirm: (para) => {
                    console.log("new para", para);
                    setNote(para?.newValue || "");
                  },
                }}
              />
            </div>
          </div>
        </div>
        <AccountingConfirmationModal
          {...{
            setVisible: setConfirmModalVisibility,
            visible: confirmModalVisibility,
            onDiscard: () => {},
            onConfirm: onCreditAdd,
            saveButton: true,
            title: "Confirm Credit",
            confirmButtonText: "Apply Credit",
            darkMode,
            text: "Are you sure you want to apply this credit? You can not undo this action.",
          }}
        />
      </Modal>
    </div>
  );
}

export default CreditEnabler;
