import moment from "moment";
import { useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import { Select, InputNumber, Input } from "antd";

import { PaymentWireForm } from "./components";
import { formatCurrency } from "../../../../../../../utils";
import { useRedux } from "../../../../../../../hooks/useRedux";
import { forceToNumber, validateThenSavePayment } from "../../utils/checkers";
import { PaymentMethods, PaymentCheckForm } from "./components";
import { currencyToNumber } from "../PaymentTable/utils/calculators";
import { DropdownIcon } from "../../../../../../../BasePage/src/index";
import { paymentInitialState, updatePaymentAmount } from "../../utils";
import {
  priceInputFormatter,
  priceInputParser,
} from "../../../../../../../Estimations/DataEntryGrid/subcomponents/rentalCalculator/utils";
import { DatePickerMoment } from "../../../../../../../../commonComponents";
import PaymentCardForm from "./components/PaymentCardForm/PaymentCardForm";

import "./PaymentForm.scss";

const PaymentForm = ({
  setSelectedClientProjects,
  projectData,
  paymentData,
  clientBalance,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [fakePayment, setFakePayment] = useRedux("fakePayment");
  const [referenceNumber, setReferenceNumber] = useState(
    fakePayment?.qbReferenceNumber || ""
  );
  const [isWritable] = useRedux("paymentIsWritable", false);
  const [clientInfos = {}] = useRedux("clientInfos");
  const { accounts = [], projects = [] } = clientInfos;
  const isCheck =
    fakePayment.paymentMethod === "Check" ||
    fakePayment.paymentMethod === "e-Check";
  const isWire = fakePayment.paymentMethod === "Wire";
  const isCard = fakePayment.paymentMethod === "Credit Debit";

  const onSelectClientName = (value) => {
    let newFakePayment = structuredClone(paymentInitialState);
    if (projectData || paymentData)
      newFakePayment = structuredClone(fakePayment);

    const account = accounts?.find((el) => el.accountId === value);
    if (account) {
      setFakePayment(
        validateThenSavePayment({
          ...newFakePayment,
          accountName: account?.accountName,
          accountId: account?.accountId,
        })
      );
      const selectedClientProjects =
        projects?.filter(
          (project) => project.accountId === account?.accountId
        ) ?? [];

      setSelectedClientProjects(selectedClientProjects);
    }
  };

  const fakePaymentChanger = (newVal) => {
    setFakePayment(validateThenSavePayment(newVal));
  };

  //If opened from Project
  useEffect(() => {
    if (projectData) onSelectClientName(projectData?.accountId);
  }, [projectData, accounts, projects]);

  //If in Edit Mode
  useEffect(() => {
    if (paymentData) onSelectClientName(paymentData?.[0]?.accountId);
  }, [accounts, projects, paymentData]);

  useEffect(() => {
    if (fakePayment) setReferenceNumber(fakePayment.qbReferenceNumber);
  }, [fakePayment?.qbReferenceNumber]);

  const onPaymentAmountChange = useCallback(
    (event, payAmount) => {
      const value = currencyToNumber(event.target.value);
      // const value = parseFloat(paymentAmountInput) || 0;
      updatePaymentAmount(
        value,
        clientBalance,
        fakePayment,
        fakePaymentChanger
      );
    },
    [fakePayment]
  );

  return (
    <div className="payment-form-container">
      <div className="payment-form-wrapper">
        <div className="payment-form-header">
          <div className="payment-form-title">Payment Details </div>
          {/* <div className="payment-customer-balance">
            {paymentData && "On Date of Creation"} Customer Balance:
            <span>
              {fakePayment?.clientBalance === -0
                ? "$0.00"
                : formatCurrency(fakePayment?.clientBalance)}
            </span>
          </div> */}
        </div>
        <div className="payment-form-body">
          <form className="payment-form">
            <div className="payment-form-elements">
              <div className="payment-form-element">
                <label htmlFor="client">Client Name</label>
                <Select
                  suffixIcon={<DropdownIcon />}
                  className="auto-complete"
                  name="client"
                  showSearch
                  placeholder="Client Name"
                  optionFilterProp="children"
                  popupClassName={isDarkMode && "darkDropDown"}
                  disabled={projectData || paymentData}
                  value={fakePayment?.accountName}
                  onChange={(e) => onSelectClientName(e)}
                >
                  {Array.isArray(accounts) &&
                    accounts?.map((account) => (
                      <Select.Option key={account.accountId}>
                        {account.accountName}
                      </Select.Option>
                    ))}
                </Select>
              </div>
              <div className="payment-form-element">
                <label htmlFor="Payment Amount">Payment Amount</label>
                <InputNumber
                  placeholder="0.00"
                  className="input-form"
                  name="PaymentAmount"
                  controls={false}
                  value={forceToNumber(
                    fakePayment?.appliedAmount?.toFixed(2) || 0
                  )}
                  formatter={priceInputFormatter}
                  parser={priceInputParser}
                  onPressEnter={onPaymentAmountChange}
                  onBlur={onPaymentAmountChange}
                  disabled={paymentData && !isWritable}
                />
              </div>
              <div className="payment-form-element">
                <label htmlFor="Payment Date">Payment Date</label>
                <DatePickerMoment
                  name="Payment Date"
                  className="input-form"
                  required
                  allowClear={false}
                  popupClassName={isDarkMode && "darkDateDropDown"}
                  // disabledDate={(current) =>
                  //   current && current > moment().endOf("day")
                  // }
                  placeholder="Payment Date"
                  value={
                    fakePayment?.paymentDate
                      ? moment(fakePayment?.paymentDate).tz("America/New_York")
                      : undefined
                  }
                  onChange={(value) =>
                    setFakePayment(
                      validateThenSavePayment({
                        ...fakePayment,
                        paymentDate: moment(value)
                          .tz("America/New_York")
                          .valueOf(),
                      })
                    )
                  }
                  format="MM/DD/YYYY"
                  disabled={paymentData && !isWritable}
                />
              </div>
              <div className="payment-form-element">
                <label htmlFor="Reference #">Reference #</label>
                <Input
                  className="input-form"
                  name="qbReferenceNumber"
                  // type="number"
                  value={referenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                  onBlur={(e) =>
                    setFakePayment(
                      validateThenSavePayment({
                        ...fakePayment,
                        qbReferenceNumber: e?.target?.value,
                      })
                    )
                  }
                  disabled={paymentData && !isWritable}
                />
              </div>
            </div>

            <PaymentMethods
              {...{
                selectedPaymentMethod: fakePayment?.paymentMethod,
                state: fakePayment,
                setState: setFakePayment,
                disabled: paymentData && !isWritable,
              }}
            />
            {isCard && <PaymentCardForm {...{ paymentData }} />}
            {isCheck && <PaymentCheckForm {...{ paymentData }} />}
            {isWire && <PaymentWireForm {...{ paymentData }} />}
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
