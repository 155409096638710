import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Collapse, Switch } from "antd";
import CurrencyInput from "react-currency-input-field";
import _ from "lodash";
import { formatNumber } from "chart.js/helpers";
import {
  formatForPricingNumberInput,
  formatForPricingNumberLongNumber,
  roundTo2Digits,
} from "../../../../../pages/Settings/settingsComponents/Pricing/utils/validation";

export const ServiceDocumentation = ({
  service,
  onServiceDocumentationChange,
  agGridTheme,
}) => {
  const { serviceId, documentationAddons } = service;

  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);

  const [documentations, set_documentations] = useState(
    documentationAddons?.length > 0
      ? documentationAddons
      : _.cloneDeep(
          serviceDefinitions.find(
            (sd) => sd.serviceId.toString() === serviceId.toString()
          )?.documentations
        )
  );

  const onValueChange = (name, value, reference) => {
    const documentations_copy = _.cloneDeep(documentations);
    const doc = documentations_copy.find((d) => d.name === name);
    doc[reference] = value;
    doc["totalPrice"] = parseFloat(doc.price) * parseFloat(doc.amount);
    if (isNaN(doc["totalPrice"])) doc["totalPrice"] = 0;

    set_documentations(documentations_copy);
  };

  const handlePriceRentChange = () => {
    onServiceDocumentationChange({
      serviceId,
      documentationAddons: documentations,
    });
  };

  return (
    <div className="addonsContainer" style={{ height: "100%" }}>
      {" "}
      <Collapse>
        <Collapse.Panel
          header={
            <div className="addonsHeader elevation-panel-header">
              Documentations
            </div>
          }
        >
          <div className={"service-documentation-container"}>
            {documentations?.map(({ name, price, amount, totalPrice }) => {
              return (
                <div className={"service-documentation"}>
                  <span>{name}</span>
                  <span className="documentation-input-container">
                    <span className="price-input-label">Price</span>
                    <CurrencyInput
                      id={
                        agGridTheme === "ag-theme-balham-dark"
                          ? "dark-input"
                          : "light-input"
                      }
                      name="price"
                      value={price}
                      onValueChange={(value) =>
                        onValueChange(name, value, "price")
                      }
                      onBlur={handlePriceRentChange}
                      placeholder="Price"
                      prefix={"$"}
                      step={1}
                      decimalScale={2}
                    />
                  </span>
                  <span className="documentation-input-container">
                    <span className="price-input-label">Amount</span>
                    <CurrencyInput
                      id={
                        agGridTheme === "ag-theme-balham-dark"
                          ? "dark-input"
                          : "light-input"
                      }
                      name="amount"
                      value={amount}
                      onValueChange={(value) =>
                        onValueChange(name, value, "amount")
                      }
                      onBlur={handlePriceRentChange}
                      placeholder="Amount"
                      step={1}
                      decimalScale={0}
                    />
                  </span>
                  <span className="documentation-input-container">
                    <span className="price-input-label">
                      Total Price: ${roundTo2Digits(totalPrice || 0)}
                    </span>
                  </span>
                </div>
              );
            })}
          </div>
        </Collapse.Panel>
      </Collapse>{" "}
    </div>
  );
};
