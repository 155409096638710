import { useState } from "react";
import { Button, Modal, Select, message, Alert } from "antd";
import { getPricingPromise } from "../../../../tools/apicalls/pricing";
import { useRedux } from "../../../../../../hooks";
import { generateRedColorByAmount } from "../../../../../../../pages/Settings/settingsComponents/Pricing/utils/colorGenerator";
import "./PLIAddonPriceScheme.scss";
import { MondayButton } from "../../../../../../../commonComponents";
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";

function PLIAddonPriceScheme({
  priceSchemesData,
  service = {},
  serviceIndex,
  handleSave,
}) {
  const [visible, setVisible] = useState(false);
  const [pricesSheet, setPricesSheet] = useState([]);
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [temp, setTempData] = useState(
    gridData?.[serviceIndex]?.PLIAddonScheme?.id ?? null
  );
  const [darkMode] = useRedux("estimationsDarkMode");

  console.log("priceSchemesData", priceSchemesData);

  const onOpen = async () => {
    await getPricingPromise()
      .then((res) => {
        console.log("res", res);
        const myServiceScheme =
          res.filter(
            (el) => el.serviceId.toString() === service.serviceId.toString()
          ) ?? [];
        console.log("myServiceScheme", myServiceScheme);
        if (myServiceScheme[0].pricingObject.priceSchemes.length > 0) {
          setPricesSheet(
            myServiceScheme[0].pricingObject.priceSchemes.filter(
              (el) => el?.type === "PLI"
            )
          );
        }
      })
      .finally(() => {
        setTempData(gridData?.[serviceIndex]?.PLIAddonScheme?.id ?? null);
        setVisible(true);
      });
  };

  const handleChangePriceScheme = () => {
    const value = temp;
    const tempData = structuredClone(gridData);
    const curr = tempData?.[serviceIndex];
    curr.PLIAddonScheme = pricesSheet.find((el) => el.id === value);
    setTakeOffTableData(tempData);
    message.success("Price Scheme Changed");
    handleSave(true, true);
    onCleanUp();
  };

  const onCleanUp = () => {
    setTempData(null);
    setVisible(false);
    setPricesSheet([]);
  };

  return (
    <>
      <Button onClick={onOpen}>Addon Scheme</Button>
      {visible && (
        <Modal
          title="Addon Scheme"
          className={"addonSchemePicker__modal" + (darkMode ? "__dark" : "")}
          open={visible}
          width={pricesSheet?.length > 0 && !!temp ? "100%" : "50%"}
          // onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={
            <div className="addonSchemeModal__footer">
              <MondayButton
                className="mondayButtonRed"
                Icon={<CloseCircleOutlined />}
                onClick={onCleanUp}
              >
                Cancel
              </MondayButton>
              <MondayButton
                Icon={<SaveOutlined />}
                disabled={!temp}
                onClick={handleChangePriceScheme}
              >
                Save & Apply Changes
              </MondayButton>
            </div>
          }
        >
          {pricesSheet?.length > 0 ? (
            <div className="aaddonSchemePicker__container">
              <div className="addonSchemePicker__header">
                <div className="addonSchemePicker__header__actions">
                  <span className="indicator_title">Choose Price Sheet</span>
                  <Select
                    className="selector__priceScheme"
                    onChange={(val) => setTempData(val)}
                    // defaultValue={
                    //   gridData?.[serviceIndex]?.PLIAddonScheme?.id ?? null
                    // }
                    value={temp}
                  >
                    {pricesSheet.map((ps) => (
                      <Select.Option value={ps.id}>{ps.name}</Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="sheet__renderer">
                {!!temp &&
                  renderSheet(pricesSheet?.find((el) => el.id === temp) ?? {})}
              </div>
            </div>
          ) : (
            <Alert message="No configuration" />
          )}
        </Modal>
      )}
    </>
  );
}

export default PLIAddonPriceScheme;

export const renderSheet = (params) => {
  const { x = [], y = [], z = [], showHeatMap = true } = params;
  let min = z?.[0]?.[0],
    max = 0;
  for (const row of z) {
    for (const cell of row) {
      const cellFloat = parseFloat(cell);
      if (cellFloat < min) min = cellFloat;
      if (cellFloat > max) max = cellFloat;
    }
  }

  return (
    <div className="price-sheet-component">
      <div className="price-sheet-width-label">Width</div>
      <div className="price-sheet-height-table-element">
        <div className="price-sheet-height-label">Height</div>
        <div className="sidewalkShedPriceSheetTableContainer">
          <div className="sSPSRow xAxis">
            <div className="sSPSCol blankArea" />
            <div className="sSPSCol aggregateCorner" />
            {x.map((key, xIndex) => (
              <div key={xIndex} className="sSPSCol yAxis">
                <input
                  disabled={true}
                  type="number"
                  className="sSPSInput"
                  min={0}
                  value={key}
                />
              </div>
            ))}
          </div>
          {z.map((z2, yIndex) => {
            return (
              <div key={yIndex} className="sSPSRow">
                <div className="sSPSCol yAxis">
                  <input
                    disabled={true}
                    type="number"
                    className="sSPSInput"
                    min={0}
                    value={y[yIndex]}
                  />
                </div>
                {z2.map((value, xIndex) => {
                  return (
                    <div key={xIndex} className="sSPSCol">
                      <input
                        disabled={true}
                        type="number"
                        className="sSPSInput"
                        min={0}
                        value={value}
                        {...(showHeatMap
                          ? {
                              style: {
                                backgroundColor: generateRedColorByAmount(
                                  min,
                                  max,
                                  value
                                ),
                              },
                            }
                          : {})}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
