import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Select, Tag, Modal } from "antd";
import { antdModalProps, updateDocumentTitle } from "../../../../../utils";
import {
  CustomCheckBox,
  DynamicInputSwitch,
  MondayButton,
  ProgressComponent,
  RichTextEditor,
} from "../../../../commonComponents";
import { InputComponent } from "../../../Fleet/components";
import { ReportsConfigContext } from "../../configuration/ReportsConfigContext";
import { v4 as uuidv4 } from "uuid";
import { createReport } from "../../configuration/actions/wrapperFunctions";
import { views } from "../../configuration/reportsConfigurationData";
import { extractReportParameters } from "../../utils";
import { Tick } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../Communication/assets/index";
import "./NewReportModal.scss";
import { getChangedData } from "../../../Accounting/components/utilities";
import { sampleTemplate, sampleTemplateFixed } from "./defaultTemplate";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { filterPeopleList } from "../../../utils/filterPeopleList";
import { useProgressComponent } from "src/hooks";
import { useEditLogs } from "../../../../../hooks";
import { InfoCircleFilled } from "@ant-design/icons";
import CustomTransfer from "../../../../commonComponents/CustomTransfer/CustomTransfer";

const CLONING_TITLE = "Clone a Report";
const CREATING_TITLE = "Create a New Report";
const SELECTED_CATEGORY_NAME = "BasePage";

const NewReportModal = ({
  visible,
  setVisible,
  cloning = false,
  newEditLog,
  isDarkMode,
  categoryName,
  selectedReportProp = {},
}) => {
  const [form] = Form.useForm();
  const [selectedReport, setSelectedReport] = useState();
  const {
    selectedCategory = selectedReportProp,
    setActiveView,
    curryDispatch,
    setSelectedReport: setReport,
  } = useContext(ReportsConfigContext);

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({ categoryName: "Reports", actionType: "Create" });
  const { saveAddedLogs } = useEditLogs();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { topicCategories } = useSelector((state) => state.topicCategories);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);

  const [users, setUsers] = useState([]);

  const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;

  const [selectedDatasets, setSelectedDatasets] = useState([]);

  const { DataSets = [] } = selectedReport?.reportObj?.definition || {};

  const { datasets, reportsAvailable = [] } = selectedCategory;

  const { validateFields, setFieldsValue, resetFields } = form;

  const options =
    categoryName === SELECTED_CATEGORY_NAME &&
    topicCategories.map((category) => ({
      label: category.categoryName,
      value: category.categoryName,
    }));

  const resetForm = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";

    resetFields();
    setSelectedReport(undefined);
    setVisible(false);
  };

  const onSave = () => {
    const { reportName, description, useInAutomation, fixedReport } =
      form.getFieldsValue();

    const selectedDataSetsToSave = datasets.filter(
      ({ Name, Query: { DataSourceName } }) => {
        return !!selectedDatasets?.includes(`${Name}${DataSourceName}`);
      }
    );

    const selectedTemplate = fixedReport ? sampleTemplateFixed : sampleTemplate;

    selectedTemplate.DataSets.forEach((data) => {
      if (!selectedDataSetsToSave.some(({ Name }) => Name === data.Name)) {
        selectedDataSetsToSave.push(data);
      }
    });

    const datasources = selectedDataSetsToSave.map(
      ({ Query: { DataSourceName } }) => DataSourceName
    );

    const newReport = {
      reportName,
      description,
      datasources,
      useInAutomation,
      reportId: uuidv4(),
      createdAt: Date.now(),
      hasSelectedFavorite: [],
      datasets: selectedDataSetsToSave,
      reportObj: selectedReport?.reportObj
        ? selectedReport?.reportObj
        : { definition: selectedTemplate },
      createdBy: userConfiguration?.nameOfUser,
      thumbnail: selectedReport?.thumbnail,
      reportParameters: !cloning
        ? extractReportParameters({ definition: selectedTemplate }) //extractDatasetParameters(selectedDataSets)
        : extractReportParameters(selectedReport?.reportObj),
    };
    setVisibleCreationProgress(newReport); //show creating proress

    updateProgressStatus({ updatingRecord: "executing" });
    // Add report in DB
    curryDispatch(createReport(selectedCategory, newReport))
      .then(async () => {
        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        });

        // get changes
        let result = getChangedData(newReport, {});
        // simplify the object of "datasets" so it will show only the name not all the unnecessary data that the user will not understand
        if (result.curr.datasets) {
          Object.keys(result.curr.datasets).forEach((key) => {
            result.curr.datasets = {
              ...result.curr.datasets,
              [result.curr.datasets[key].Name]: result.curr.datasets[key].Name,
            };
            delete result.curr.datasets[key];
          });
        }
        // update new log object with current changes
        newEditLog.recordId = newReport.reportId;
        newEditLog.recordName = reportName;
        newEditLog.actionType = "Create";
        newEditLog.currentData = { [reportName]: result.curr };
        newEditLog.previousData = {
          [reportName]: { ...result.prev, datasources: [] }, // set datasources to empty array from "'Does not exist'" to make the comparison between 2 arrays
        };
        newEditLog.updatedKeys.push(reportName);
        // update DB with the new log
        saveAddedLogs(newEditLog);

        setReport(newReport);
        // setActiveView(views.REPORT_CONFIG);

        // await broadcastNotification(
        broadcastNotification(
          "38",
          "onCreateNewReport",
          [
            { common: user },
            { userName: user, currentUser: authenticatedUser?.sub },
          ],
          ""
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
      })
      .catch((error) => {
        console.log({ error });
        updateProgressStatus({ updatingRecord: "hasError" });
      });
  };

  const onReportSelect = (selectedName) => {
    const foundReport = reportsAvailable.find(
      ({ reportName }) => reportName === selectedName
    );

    form.setFieldsValue({ description: foundReport?.description });
    setSelectedReport(foundReport);
  };

  const onCancel = () => {
    resetForm();
  };

  const reportNameValidator = (_, value) =>
    !!value &&
    reportsAvailable.some(
      ({ reportName }) => reportName.toLowerCase() === value.toLowerCase()
    )
      ? Promise.reject(new Error("This report already exists"))
      : Promise.resolve();

  useEffect(() => {
    DataSets.forEach(({ Name, Query: { DataSourceName } }) =>
      setFieldsValue({ [`${Name} ${DataSourceName}`]: true })
    );
  }, [DataSets]);

  useEffect(() => {
    setUsers(filterPeopleList(userConfiguration.allUsers?.Items));
  }, [userConfiguration]);

  return (
    <Modal
      {...{
        ...antdModalProps,
        className: `newReportModal ${isDarkMode && "newReportModalDark"}`,
        open: visible,
        title: cloning ? CLONING_TITLE : CREATING_TITLE,
        afterOpenChange: (event) => {
          event &&
            updateDocumentTitle({
              newTitle: cloning ? CLONING_TITLE : CREATING_TITLE,
            });
        },
        closeIcon: <XIcon />,
        onCancel: () => {
          onCancel();
          setVisible(false);
        },
        footer: [
          <div className="footerContainer">
            <MondayButton
              className="mondayButtonRed"
              Icon={<XIcon />}
              onClick={() => {
                onCancel();
                setVisible(false);
              }}
            >
              Cancel
            </MondayButton>
            <MondayButton
              {...{
                Icon: <Tick width={18} height={18} />,
                onClick: () => validateFields().then(onSave),
              }}
            >
              Create
            </MondayButton>
          </div>,
        ],
      }}
    >
      <div className="reportModal-container">
        <div className="reportModal-info">
          <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
          {!!cloning ? (
            <p className="createReport-text">
              Here you can clone a report by selecting, from a list of available
              datasets, specifying whether the report is for automation or a
              fixed report, and providing a title and description for the
              report.
            </p>
          ) : (
            <p className="createReport-text">
              Here you can create a new report for{" "}
              <b className="reportModal-text">{categoryName}</b> by selecting
              from a list of available datasets, specifying whether the report
              is for automation or a fixed report, and providing a title and
              description for the report.
            </p>
          )}
        </div>
        <Form {...{ form, preserve: false }}>
          <div className="newReportModalContent">
            <div className="inputs">
              {!!cloning && (
                <InputComponent
                  required
                  showSearch
                  {...{
                    label: "Clone From",
                    formItemName: "reportToClone",
                    placeholder: "Select...",
                    className: "newReportModal-inputs",
                    type: "select",
                    onSelect: onReportSelect,
                    options: reportsAvailable
                      .filter(({ reportObj }) => !!reportObj)
                      .map(({ reportId, reportName }) => (
                        <Select.Option
                          {...{
                            key: reportId,
                            value: reportName,
                          }}
                        >
                          {reportName}
                        </Select.Option>
                      )),
                  }}
                />
              )}
              <DynamicInputSwitch
                required
                {...{
                  label: "Report Title",
                  formItemName: "reportName",
                  placeholder: "Name it here...",
                  className: "newReportModal-inputs",
                  rules: [
                    {
                      validator: reportNameValidator,
                    },
                  ],
                  options,
                  canChange: false,
                }}
              />

              {/* <InputComponent
              {...{
                label: "Use in Automation",
                name: "useInAutomation",
                type: "checkbox",
              }}
            /> */}
              <div className="reportModal-checkBoxItem">
                <Form.Item name={"useInAutomation"} valuePropName="checked">
                  <CustomCheckBox
                    className="reportModal-checkBox"
                    name={"Use in Automation"}
                    // onChange={(e) => on}
                  />
                </Form.Item>

                <Form.Item name={"fixedReport"} valuePropName="checked">
                  <CustomCheckBox
                    className="reportModal-checkBox"
                    name={"Fixed Report"}
                    // onChange={(e)=>}
                  />
                </Form.Item>
              </div>
              {/* <InputComponent
              {...{
                label: "Fixed Report",
                name: "fixedReport",
                type: "checkbox",
              }}
            /> */}
              {!!cloning ? (
                <div className="edit-descriptionContainer">
                  <label className="description-label">Description</label>{" "}
                  <RichTextEditor
                    {...{
                      className: "description-textarea",
                      name: "description",
                      isDarkMode,
                    }}
                  />
                </div>
              ) : (
                <div className="descriptionContainer">
                  <label className="description-label">Description</label>{" "}
                  <RichTextEditor
                    {...{
                      className: "description-textarea",
                      name: "description",
                      isDarkMode,
                    }}
                  />
                </div>
              )}
            </div>

            <div className="datasetsContainer">
              <CustomTransfer
                {...{
                  redSectionTitle: `${datasets?.length} Available Datasets`,
                  greenSectionTitle: ` Selected Datasets`,
                  dataSource: datasets?.map(
                    ({ Name, Query: { DataSourceName }, disabled = false }) =>
                      disabled
                        ? ""
                        : {
                            key: Name + DataSourceName,
                            title: Name + DataSourceName,
                          }
                  ),
                  onChange: (e) => {
                    setSelectedDatasets(e);
                  },
                  value: selectedDatasets,
                }}
              />

              {/* {datasets.map(({ Name, Query: { DataSourceName } }) => (
                <AnimatedCheckBox
                  noStyle
                  {...{
                    key: Name + DataSourceName,
                    form,
                    label: (
                      <div className="dataSetLabel">
                        <div>{Name}</div>
                        <Tag className="dataSetLabelTag">{DataSourceName}</Tag>
                      </div>
                    ),
                    tooltipVisible: false,
                    formItemName: `${Name} ${DataSourceName}`,
                    checkBoxProps: {
                      disabled: DataSets.some(
                        ({ Name: name, Query: { DataSourceName: ds } }) =>
                          name === Name && ds === DataSourceName
                      ),
                    },
                    primaryStyle: { backgroundColor: "#1264A3" },
                    secondaryStyle: {
                      backgroundColor: "#0D558B",
                      border: "1px solid #4394D2",
                    },
                  }}
                />
              ))} */}
            </div>
          </div>
        </Form>
      </div>

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Reports",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
              setActiveView(views.REPORT_CONFIG);
              resetForm(); // reset form inputs
            },
          }}
        />
      )}
    </Modal>
  );
};

export default NewReportModal;
