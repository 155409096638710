import React from "react";
import { AgGridReact } from "ag-grid-react";
// import { LicenseManager } from "ag-grid-enterprise";
import { Button, Typography } from "antd";
// import { agGridTrialKey } from "../../../../../../../../../helpers/constants/forProject";
import rtfEditor from "../../../../cellRenderers/rtfEditor";
import rtfEditorAddon from "../../../../cellRenderers/rtfEditorAddon";
import getNewId from "../../../../../tools/polyfillers/getNewId";
import {
  dimensionFormatterAddons,
  evaluateMathExpressionAddons,
} from "../../../../../tools/formatters/evaluators";

// LicenseManager.setLicenseKey(agGridTrialKey);

export default class SidewalkShedSpansDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colDefs: [
        {
          headerName: "#",
          field: "id",
          maxWidth: 40,
          editable: false,
          cellClass: "id-column",
        },
        {
          headerName: "L",
          field: "length",
          valueParser: evaluateMathExpressionAddons,
          valueFormatter: dimensionFormatterAddons,
          cellClassRules: {
            "invalid-price": (params) => {
              let value = parseFloat(params?.value);
              return isNaN(value) || value === Infinity || value <= 0;
            },
          },
        },
        {
          headerName: "H",
          field: "height",
          editable: false,
          valueParser: evaluateMathExpressionAddons,
          valueFormatter: dimensionFormatterAddons,
          cellClassRules: {
            "invalid-price": (params) => {
              let value = parseFloat(params?.value);
              return isNaN(value) || value === Infinity || value <= 0;
            },
          },
          valueGetter: () => {
            return props.node.parent.data.height ?? undefined;
          },
        },
        {
          headerName: "W",
          field: "width",
          valueParser: evaluateMathExpressionAddons,
          valueFormatter: dimensionFormatterAddons,
          cellClassRules: {
            "invalid-price": (params) => {
              let value = parseFloat(params?.value);
              return isNaN(value) || value === Infinity || value <= 0;
            },
          },
        },
        {
          headerName: "Description",
          field: "description",
          width: 150,
          // cellEditor: 'rtfEditor',
          // cellRenderer: (params) => {
          //   return params.value ? `${params.value}` : '-';
          // },
          editable: true,
        },
        {
          headerName: "Note",
          field: "note",
          width: 150,
          // cellEditor: 'rtfEditor',
          // cellRenderer: (params) => {
          //   return params.value ? `${params.value}` : '-';
          // },
          editable: true,
        },
      ],
      defaultColDef: {
        // minWidth: 100,
        flex: 1,
        editable: true,
        filter: true,
        sortable: true,
        resizable: true,
        autoHeight: true,
        enablePivot: true,
        enableRowGroup: true,
        cellClass: "cell-wrap-text",
        valueFormatter: (params) => {
          if (params.value) {
            return params.value;
          } else {
            return "-";
          }
        },
      },
      rowData: [],
      getRowNodeId: (data) => {
        return data.id;
      },
      frameworkComponents: {
        rtfEditor: rtfEditor,
        rtfEditorAddon: rtfEditorAddon,
      },
    };
    this.state.rowId = props.node.id;
    this.state.masterGridApi = props.api;
  }

  componentDidMount() {
    // let highestId = getNewId(this.props.data.span);
    this.setState({
      rowData: this.props.data.span,
    });
  }

  onGridReady = (params) => {
    this.state.masterGridApi.addDetailGridInfo(this.state.rowId, {
      id: this.state.rowId,
      api: params.api,
      columnApi: params.columnApi,
    });
    params.api.setDomLayout("autoHeight");
    // params.api.sizeColumnsToFit();
    // window.addEventListener('resize', () => {
    //   params.api.sizeColumnsToFit();
    // });
  };

  componentWillUnmount = () => {
    this.state.masterGridApi.removeDetailGridInfo(this.state.rowId);
  };

  getRowHeight = (params) => {
    if (params.node && params.node.detail) {
      const offset = 105;
      const allDetailRowHeight =
        params.data.span.length *
        params.api.getSizesForCurrentTheme().rowHeight;
      const gridSizes = params.api.getSizesForCurrentTheme();
      return allDetailRowHeight + gridSizes.headerHeight + offset;
    }
  };

  // called when a cell editing has stopped. used to resize rows when not in focus
  cellEditingStopped = (params) => {
    this.resizeGridRows(params.api);
  };

  // called when columns are resized either manually or automatically
  columnResized = (params) => {
    this.resizeGridRows(params.api);
  };

  // resizes grid row heights
  resizeGridRows = (gridApi) => {
    gridApi.resetRowHeights();
  };

  cellEditStarted = (params) => {
    if (params.node.lastChild) {
      let rowData = [...this.state.rowData];

      let allFilled = true;
      for (const span of rowData) {
        let height = parseFloat(span.height);
        let width = parseFloat(span.width);
        let length = parseFloat(span.length);
        if (isNaN(height) || isNaN(width) || isNaN(length)) {
          allFilled = false;
          break;
        }
      }

      if (allFilled) {
        let highestId = getNewId(rowData);
        this.setState({
          rowData: this.state.rowData.concat({ id: highestId + 1 }),
        });
      }
    }
  };

  saveData = () => {
    let detailData = [];
    this.state.rowData.forEach((span) => {
      if (Object.keys(span).length > 1) {
        detailData.push(span);
      }
    });
    this.props.saveSidewalkShedSpanData(this.props.data.id, detailData);
  };

  render() {
    return (
      <div className="sidewalkShedDetailContainer">
        <Typography.Title level={4}>Spans:</Typography.Title>
        {/* <Button
          type="primary"
          style={{ marginLeft: 15 }}
          onClick={this.saveData}
        >
          Save
        </Button> */}
        <div className={`ag-theme-alpine-dark`} style={{ width: "100%" }}>
          <AgGridReact
            id="detailGrid"
            autoHeight={true}
            animateRows={true}
            immutableData={true}
            // enableFillHandle={true}
            embedFullWidthRows={true}
            // enableRangeSelection={true}
            rowData={this.state.rowData}
            enableCellChangeFlash={true}
            undoRedoCellEditingLimit={50}
            onGridReady={this.onGridReady}
            columnDefs={this.state.colDefs}
            getRowHeight={this.getRowHeight}
            onColumnResized={this.columnResized}
            getRowNodeId={this.state.getRowNodeId}
            defaultColDef={this.state.defaultColDef}
            // onCellValueChanged={this.cellValueChanged}
            onCellEditingStarted={this.cellEditStarted}
            onCellEditingStopped={this.cellEditingStopped}
            frameworkComponents={this.state.frameworkComponents}
            suppressDragLeaveHidesColumns={true}
          />
        </div>
      </div>
    );
  }
}
