import "./DependencyInEdit.scss";
import { useRef, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { XIcon } from "../../../../../../../Communication/assets";
import AddDependency from "./AddDependency";

const DependencyInEdit = ({
  updatedTask = {},
  setUpdatedTask,
  rowData = [],
}) => {
  const popoverRef = useRef();

  const { taskId, taskDependency = {} } = updatedTask;

  const [showDependency, setShowDependency] = useState(false);

  const [blockedTask, setBlockedTask] = useState(false);
  const [blockingTask, setBlockingTask] = useState(false);

  const showBlockedBy = () => {
    setBlockedTask((prev) => !prev);
    setShowDependency((prev) => !prev);
  };

  const showBlocking = () => {
    setBlockingTask((prev) => !prev);
    setShowDependency((prev) => !prev);
  };

  const addDependency = (taskId, isBlocked) => {
    const keyToUpdate = isBlocked ? "blockedBy" : "blocking";

    const newDependency = {
      id: taskId,
      title: rowData.filter((row) => row.taskId === taskId)[0]?.taskTitle,
      status: rowData.filter((row) => row.taskId === taskId)[0]?.taskStatus,
    };
    const newRow = {
      ...updatedTask,
      taskDependency: {
        ...taskDependency,
        [keyToUpdate]: [...taskDependency[keyToUpdate], newDependency],
      },
    };
    setUpdatedTask(newRow);
  };

  //Removing dependency
  const deleteDependency = (taskId, isBlocked, taskTitle) => {
    const keyToUpdate = isBlocked ? "blockedBy" : "blocking";

    setUpdatedTask({
      ...updatedTask,
      taskDependency: {
        ...taskDependency,
        [keyToUpdate]: taskDependency[keyToUpdate].filter(
          (dependency) => dependency.id !== taskId
        ),
      },
    });
  };

  useOnclickOutside(
    () => {
      popoverRef.current.classList.add("out");
      if (showDependency) {
        setTimeout(() => {
          setShowDependency(false);
        }, 100);
      }
    },
    { refs: [popoverRef], disabled: !showDependency }
  );

  const tasksWithoutDependency = rowData?.filter(
    (task) =>
      task.taskId !== taskId &&
      taskDependency?.blockedBy?.filter(({ id }) => id === task.taskId)
        .length <= 0 &&
      taskDependency?.blocking?.filter(({ id }) => id === task.taskId).length <=
        0
  );

  return (
    <div className="dependency-wrapper">
      <div className="detailsModal__dependency">
        <button
          onClick={() => setShowDependency((prev) => !prev)}
          className="button__addDependency"
        >
          Add dependency
        </button>
        {showDependency && (
          <div className="dependency-dropdown" ref={popoverRef}>
            <span onClick={showBlockedBy}>This task blocked by</span>
            <span onClick={showBlocking}>This task blocking</span>
          </div>
        )}
      </div>
      <div className="dependency--container-wrapper">
        {(blockedTask || taskDependency?.blockedBy?.length > 0) && (
          <div className="detailsModal__dependency__options">
            <h3>Blocked by</h3>
            {blockedTask && (
              <AddDependency
                tasks={tasksWithoutDependency}
                blocked={true}
                closeAddDependency={() => setBlockedTask(!blockedTask)}
                addDependency={addDependency}
              />
            )}
            <div>
              {taskDependency?.blockedBy?.map((dependency, index) => {
                return (
                  <DependedItem
                    id={dependency.id}
                    key={index}
                    blocked={true}
                    taskTitle={dependency.title}
                    deleteDependency={deleteDependency}
                    value={dependency.title}
                  />
                );
              })}
            </div>
          </div>
        )}
        {(blockingTask || taskDependency?.blocking?.length > 0) && (
          <div className="detailsModal__dependency__options">
            <h3>Blocking</h3>
            {blockingTask && (
              <AddDependency
                tasks={tasksWithoutDependency}
                blocked={false}
                closeAddDependency={() => setBlockingTask(!blockingTask)}
                addDependency={addDependency}
              />
            )}
            <div>
              {taskDependency?.blocking?.map((dependency, index) => {
                return (
                  <DependedItem
                    id={dependency.id}
                    key={index}
                    blocked={false}
                    taskTitle={dependency.title}
                    deleteDependency={deleteDependency}
                    value={dependency.title}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DependedItem = ({ id, value, blocked, taskTitle, deleteDependency }) => {
  return (
    <div key={id} className="detailsModal__dependency__item">
      <h3>{value}</h3>
      <span>
        <XIcon onClick={() => deleteDependency(id, blocked, taskTitle)} />
      </span>
    </div>
  );
};

export default DependencyInEdit;
