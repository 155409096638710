import { API } from "aws-amplify";
import { v4 as uuid } from "uuid";

import { lazyFetch } from "../../../../../../../../utils";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

/**
 * This property filters the result of getCostAnalytics function
 * @typedef filter
 * @property {any} value - the filter value
 * @property {string} key - specified key that will filter the result
 * @property {string} type - type of the column that will filter the result
 *
 * Payroll object
 * @typedef payroll
 * @property {string} degId
 * @property {number} toDate
 * @property {number} fromDate
 * @property {string} degStatus
 */

async function getPaginationData({ filters, getMaxLimit }) {
  let totalData = [];
  let lastKey = undefined;

  do {
    try {
      const result = await API.get("degEntries", "/degEntries", {
        queryStringParameters: {
          withPagination: "true",
          ExclusiveStartKey: lastKey,
          getMaxLimit: `${getMaxLimit}`,
          filters: JSON.stringify(filters),
        },
      });

      if (result?.degEntries) {
        totalData = totalData.concat(result?.degEntries);
        lastKey = result?.LastEvaluatedKey?.entryId;
      }
    } catch (err) {
      return Promise.reject(err);
    }
  } while (lastKey);

  return totalData;
}

/**
 *  Get cost analytics in a specified date range.
 * @param {{company: string, filters:filter[],  listOfPayrolls: payroll[], dateRange: number[] | Date[]}} param
 */
async function getCostAnalytics({
  company,
  filters,
  dateRange,
  listOfPayrolls = [],
}) {
  let entries = [];
  try {
    const [dateStart, dateEnd] = dateRange.map((el, i) =>
      i ? dayjsNY(el).endOf("D").valueOf() : dayjsNY(el).startOf("D").valueOf()
    );

    // Get only Completed payrolls
    let completedPayrolls = [];

    if (listOfPayrolls?.length) {
      completedPayrolls = listOfPayrolls.filter(
        (el) => el?.degStatus === "Completed"
      );
    } else {
      completedPayrolls = await lazyFetch({
        tableName: "deg",
        filterKey: "degStatus",
        filterValue: "Completed",
        listOfKeys: ["degId", "fromDate", "toDate", "degStatus", "companyName"],
      });
    }

    // Filter payrolls by date range
    const payrolls = completedPayrolls.filter((el) => {
      const toDate = dayjsNY(el?.toDate).startOf("D").valueOf();
      const fromDate = dayjsNY(el?.fromDate).startOf("D").valueOf();

      const isBetweenDateRange = dateStart >= fromDate && dateEnd <= toDate;

      const isStartBetweenDateRange =
        dateStart <= fromDate && fromDate <= dateEnd;

      const isEndBetweenDateRange = dateStart <= toDate && toDate <= dateEnd;

      let filterCompany = true;
      if (!!company && !!el?.companyName) {
        filterCompany = company?.el?.companyName === company;
      }

      return (
        (isBetweenDateRange ||
          isEndBetweenDateRange ||
          isStartBetweenDateRange) &&
        filterCompany
      );
    });

    for (let i = 0; i < payrolls.length; i++) {
      let requestFilters = [];
      const payroll = payrolls[i];

      const appliedFilters = {
        conditions: [
          {
            id: uuid(),
            operator: "AND",
            columnType: "date",
            dataType: undefined,
            formula: "is_between",
            column: "punchTimeStamp",
            value: [dateStart, dateEnd],
          },
          {
            formula: "is",
            operator: "AND",
            column: "degId",
            value: payroll?.degId,
          },
          ...(filters || []).map((filter) => ({
            id: uuid(),
            value: filter.value,
            column: filter?.key,
            formula: filter?.formula || "is",
            operator: filter?.operator || "AND",
          })),
        ],
        id: uuid(),
        operator: "AND",
      };

      requestFilters.push(appliedFilters);

      if (company) {
        const companyFilters = {
          id: uuid(),
          operator: "AND",
          conditions: [
            {
              id: uuid(),
              formula: "is",
              value: company,
              operator: "OR",
              column: "company",
              dataType: "string",
              columnType: "string",
            },
            {
              id: uuid(),
              formula: "is",
              value: company,
              operator: "OR",
              dataType: "string",
              columnType: "string",
              column: "companyName",
            },
          ],
        };
        requestFilters.push(companyFilters);
      }

      try {
        const result = await getPaginationData({
          filters: requestFilters,
          getMaxLimit: payrolls?.length <= 5,
        });

        if (result) {
          entries = entries.concat(result || []);
        }
      } catch (fetchError) {
        // i -= 1;
        continue;
      }
    }

    return entries;
  } catch (error) {
    console.log(
      "Error getting costAnalytics: ",
      JSON.stringify(error, null, 4)
    );
    return entries;
  }
}

export default getCostAnalytics;
