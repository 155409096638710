import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ScissorOutlined } from "@ant-design/icons";
import { Form, Modal, Tooltip, message } from "antd";
import { useMemo, useState, useContext, useCallback, useEffect } from "react";

import {
  getDegTotals,
  degFilterHandler,
  groupEntriesInShifts,
  getEmployeeAnalytics,
} from "../utils";
import {
  detailsFields,
  detailsColumnDefs,
  detailsFilterData,
} from "./degDetailsModalData";
import {
  formatCurrency,
  compareIncluding,
} from "../../../../../../../SidebarPages/utils";
import DegModalContext from "../DegModalContext";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import SplitShiftModal from "../SplitShiftModal/SplitShiftModal";
import { updateDocumentTitle } from "../../../../../../../../utils";
import { MondayButton } from "../../../../../../../commonComponents";
import createPDF from "../../../../../../../../integrations/AgGridToPdf";
import { FilterIcon } from "../../../../../../../SidebarPages/BasePage/src";
import { ExcelIconWhite, PdfWhiteIcon } from "../../../../../../../../assets";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { RenderDynamicComponents } from "../../../../../../../Header/forms/Components";

import "./DegDetailsModal.scss";

const sideBar = {
  toolPanels: [
    {
      id: "columns",
      iconKey: "columns",
      labelKey: "columns",
      labelDefault: "Columns",
      toolPanel: "agColumnsToolPanel",
    },
    {
      id: "filters",
      iconKey: "filter",
      labelKey: "filters",
      labelDefault: "Filters",
      toolPanel: "agFiltersToolPanel",
    },
  ],
};

const defaultColDef = {
  flex: 1,
  filter: true,
  minWidth: 130,
  sortable: true,
  resizable: true,
  enableColResize: true,
  enableRowGroup: false,
  suppressSizeToFit: true,
  suppressDragLeaveHidesColumns: true,
};

const DegDetailsModal = ({ open, onCancel }) => {
  const { base64 } = useSelector((state) => state.base64);
  const {
    crews,
    rowData,
    degName,
    jobsites,
    analytics,
    degGridApi,
    isDarkMode,
  } = useContext(DegModalContext);

  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [statistics, setStatistics] = useState({});
  const [fieldsChange, setFieldsChange] = useState(false);
  const [splitShiftModal, setSplitShiftModal] = useState(false);
  const [analyticsState, setAnalyticsState] = useState(analytics);

  const [form] = Form.useForm();

  const { employeeWeekTotals, employeeOverheadShifts, generalOverheadTotal } =
    analyticsState;

  const location = useLocation();

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
    setColumnApi(param.columnApi);
  }, []);

  const groupByShift = useMemo(() => {
    if (analytics && !!crews?.length && !!jobsites?.length) {
      return groupEntriesInShifts({
        crews,
        rowData,
        jobsites,
        analytics: analytics,
      });
    }
  }, [analytics, crews, jobsites, rowData]);

  const listOfEmployees = useMemo(() => {
    if (!!groupByShift?.length) {
      return _.uniqBy(
        groupByShift.map(
          ({ employeeId, employeeFullName, crewId, crewPosition }) => ({
            employeeId,
            employeeFullName,
            crewId,
            employeeRole: crewPosition,
          })
        ),
        ({ employeeId }) => employeeId
      ).filter(({ employeeId }) => !!employeeId);
    }
  }, [groupByShift]);

  const listOfJobsites = useMemo(() => {
    if (!!groupByShift?.length) {
      return _.uniqBy(
        groupByShift.map(({ jobsiteMatch }) => ({
          jobName: jobsiteMatch?.jobName || "",
          jobsiteId: jobsiteMatch?.jobsiteId || "",
          jobAddress: jobsiteMatch?.jobAddress || "",
        })),
        ({ jobsiteId }) => jobsiteId
      ).filter(({ jobsiteId }) => !!jobsiteId);
    }
  }, [groupByShift]);

  const listOfRoles = useMemo(() => {
    if (!!groupByShift?.length) {
      return _.uniqBy(
        groupByShift.map(({ employeeRole }) => ({
          label: employeeRole,
          value: employeeRole,
        })),
        ({ value }) => value
      ).filter(({ value }) => !!value);
    }
  }, [groupByShift]);

  const doesExternalFilterPass = useCallback(
    ({ data }) => {
      const filterFields = form.getFieldsValue();
      let roleCondition = true;
      let timeCondition = true;
      let jobsiteCondition = true;
      let employeeCondition = true;

      if (!!filterFields.employees?.length) {
        employeeCondition = filterFields?.employees?.includes(data?.employeeId);
      }

      if (!!filterFields.employeeRole?.length) {
        roleCondition = filterFields?.employeeRole?.includes(
          data?.employeeRole
        );
      }

      if (!!filterFields.jobsites?.length) {
        jobsiteCondition = filterFields.jobsites.includes(
          data?.jobsiteMatch?.jobsiteId
        );
      }

      if (!!filterFields.timeFrame) {
        let start = dayjsNY(filterFields.timeFrame[0]).startOf("D").valueOf();
        let end = dayjsNY(filterFields.timeFrame[1]).startOf("D").valueOf();

        timeCondition =
          dayjs(data?.punchDate).startOf("D").valueOf() >= start &&
          dayjs(data?.punchDate).startOf("D").valueOf() <= end;
      }

      return (
        employeeCondition && roleCondition && jobsiteCondition && timeCondition
      );
    },
    [fieldsChange]
  );

  const fieldsJSON = useMemo(() => {
    return detailsFields({ listOfEmployees, listOfJobsites, listOfRoles });
  }, [listOfEmployees, listOfJobsites, listOfRoles]);

  function getData() {
    const dataObj = form.getFieldsValue();
    const {
      employees = [],
      employeeRole = [],
      jobsites = [],
      timeFrame = [],
    } = dataObj;

    const filtersData = {
      employeeId: !!employees?.length ? employees : null,
      employeeRole: !!employeeRole?.length ? employeeRole : null,
      jobsiteMatch: !!jobsites.length
        ? listOfJobsites.map(
            ({ jobsiteId, jobName }) => jobsites.includes(jobsiteId) && jobName
          )
        : null,
      firstClockIn: !!timeFrame?.length ? timeFrame : null,
    };

    getEmployeeAnalytics({
      employeeList: employees?.length
        ? employees
        : listOfEmployees.map(({ employeeId }) => employeeId),
      degGridApi,
      analyticsUpdate: setAnalyticsState,
      externalFiltersPass: doesExternalFilterPass,
    });

    degFilterHandler({
      gridApi,
      filtersData,
      propFilterData: detailsFilterData,
    });
  }

  function exportGridToExcel() {
    gridApi.exportDataAsExcel({
      processCellCallback(params) {
        const data = params?.node?.data;
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;
        if (headerName === "Jobsite Match") {
          if (typeof value === "string") {
            return value;
          } else {
            return value?.jobName;
          }
        } else if (headerName === "Employee Full Name") {
          if (!!data?.crewName?.length) {
            return data?.crewName;
          } else {
            return data?.employeeFullName;
          }
        } else if (headerName === "Date") {
          return dayjsNY(value).format("MM/DD/YYYY");
        } else if (headerName === "Clock In" || headerName === "Clock Out") {
          return dayjs(value).format("hh:mm A");
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
  }

  function exportGridToPdf() {
    let rows = [];
    gridApi.forEachNode(({ data }) => {
      Object.assign(data, {
        firstClockIn: dayjsNY(data.firstClockIn).format("MMM/D/YY hh:mm a"),
        clockOut: dayjsNY(data.clockOut).format("MMM/D/YY hh:mm a"),
      });
      rows.push(data);
    });

    return createPDF({
      base64,
      rowData: rows,
      gridApi: gridApi,
      action: "download",
      masterDetail: true,
      gridColumnApi: columnApi,
      historyTab: location?.pathname,
      history: location?.pathname?.split("/")?.[1],
      params: {
        PDF_SELECTED_ROWS_ONLY: false,
        PDF_DOCUMENT_TITLE: degName || "New DEG",
        PDF_LOGO: base64?.find(({ fileName }) =>
          compareIncluding(fileName, "Core Logo Black")
        )?.base64,
      },
    });
  }

  useEffect(() => {
    setStatistics(
      getDegTotals({
        employeeWeekTotals,
        generalOverheadTotal,
        employeeOverheadShifts,
      })
    );
  }, [analyticsState]);

  const handleCancel = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    onCancel();
  };

  return (
    <Modal
      open={open}
      centered={true}
      title="Details View"
      closeIcon={<XIcon />}
      onCancel={handleCancel}
      data-testid="deg-details-modal"
      className={`detailsViewModal ${isDarkMode && "detailsViewModalDark"}`}
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Details View" });
      }}
      footer={[
        <MondayButton
          Icon={<XIcon />}
          key={"close-btn"}
          onClick={handleCancel}
          className="mondayButtonRed"
          data-testid="close-deg-details-modal-btn"
        >
          Close
        </MondayButton>,
      ]}
    >
      <Form form={form}>{RenderDynamicComponents(fieldsJSON, form)}</Form>
      <div className="controlPanel">
        <MondayButton
          Icon={<ScissorOutlined />}
          className="mondayButtonBlue"
          data-testid="open-split-shift-modal-btn"
          onClick={() =>
            !!gridApi?.getSelectedNodes()?.length
              ? setSplitShiftModal(true)
              : message.error("Please select a Shift to split first!")
          }
        >
          Split Shift
        </MondayButton>
        <div className="exportActions">
          <Tooltip title="Export to PDF">
            <MondayButton
              hasIcon={false}
              onClick={exportGridToPdf}
              className="mondayButtonBlue"
            >
              <PdfWhiteIcon width={15} height={15} />
            </MondayButton>
          </Tooltip>

          <Tooltip title="Export to Excel">
            <MondayButton
              hasIcon={false}
              onClick={exportGridToExcel}
              className="mondayButtonBlue"
            >
              <ExcelIconWhite width={15} height={15} />
            </MondayButton>
          </Tooltip>
        </div>
        <MondayButton
          Icon={<FilterIcon />}
          className="mondayButtonBlue"
          onClick={() => {
            setFieldsChange(!fieldsChange);
            getData();
          }}
        >
          Get Details
        </MondayButton>
      </div>
      <div className="overallData">
        <div className="dataContainer">
          <label className="dataLabel">Total Regular Amount</label>
          <p className="data">{formatCurrency(statistics?.totalReg) || "$0"}</p>
        </div>

        <div className="dataContainer">
          <label className="dataLabel">Total Overtime Amount</label>
          <p className="data">{formatCurrency(statistics?.totalOt) || "$0"}</p>
        </div>

        <div className="dataContainer">
          <label className="dataLabel">Total Overhead Amount</label>
          <p className="data">{formatCurrency(statistics?.totalOvh) || "$0"}</p>
        </div>

        <div className="dataContainer">
          <label className="dataLabel">Total Amount</label>
          <p className="data">{formatCurrency(statistics?.total) || "$0"}</p>
        </div>

        <div className="dataContainer">
          <label className="dataLabel">Total Amount With Overhead</label>
          <p className="data">
            {formatCurrency(statistics?.totalWOvh) || "$0"}
          </p>
        </div>
      </div>
      <div
        className={`deg-grid-container ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            sideBar,
            onGridReady,
            defaultColDef,
            pagination: true,
            rowData: groupByShift,
            paginationPageSize: 10,
            rowSelection: "single",
            columnDefs: detailsColumnDefs(),
          }}
        />
      </div>
      {splitShiftModal && (
        <SplitShiftModal
          gridApi={gridApi}
          open={splitShiftModal}
          onCancel={() => setSplitShiftModal(false)}
        />
      )}
    </Modal>
  );
};

export default DegDetailsModal;
