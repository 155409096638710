import { convertSafetyContactsData } from "../../../../../Projects/ProjectSafetyReport/helpers";

function convertSafetyReport(data = []) {
  const dataSections = [];
  if (!Array.isArray(data)) {
    return dataSections;
  }
  const otherData = [...data].filter((section) => section?.other);
  const sections = [...data].filter(
    (section) => !section?.additionalData && !section?.other
  );
  for (const section of sections) {
    const tables = titleBodyHtmlTable(section) || [];
    dataSections.push(...tables);
  }
  if (otherData?.length > 0) {
    const hospitals = convertSafetyContactsData(otherData) || [];
    dataSections.push(...hospitals);
  }
  return dataSections;
}

export default convertSafetyReport;

export function titleBodyHtmlTable(
  object,
  newTitleClassName = "",
  tableWrapperClassName = ""
) {
  const toReturn = [];
  if (!object) {
    return toReturn;
  }
  const { title, body } = object || {};
  let titleClassName = newTitleClassName
    ? newTitleClassName
    : `safety-section-header`;
  const titleFormat = {
    text: {
      paragraphs: [
        {
          text: title,
          bold: true,
          fontSize: 16,
          class: titleClassName,
        },
      ],
    },
  };
  toReturn.push(titleFormat);
  const bodyFormat = {
    class: tableWrapperClassName,
    table: {
      class: "safety-table",
      cols: [],
      rows: (body || []).map(({ key = "", value = "" }) => {
        const strVal = !!value ? "" + value : "";
        if (strVal?.includes("data:image")) {
          return [
            key,
            {
              // image: value,
              width: 100,
              height: 100,
              src: strVal,
              class: "safety-image",
            },
          ];
        } else if (strVal?.includes("isPhoneNumber:")) {
          const newValue = strVal?.split("isPhoneNumber:")?.[1];
          return [
            key,
            {
              link: `tel:${newValue}`,
              text: newValue,
              class: "phone-number",
            },
          ];
        } else {
          return [key, strVal];
        }
      }),
    },
  };
  toReturn.push(bodyFormat);

  return toReturn;
}
