import { MondayButton } from "../../../../../commonComponents";
import "./FilePreviewModal.scss";
import { GDriveIcon } from "../../assets";
import { openInNewTab } from "../../../../../../utils";
import { XIcon } from "../../../../Communication/assets";
import {
  DownloadIcon,
  EmailIcon,
  LeftArrow,
  RightArrow,
} from "../../../../BasePage/src";
import EmailBox from "../../../../Communication/components/EmailBox/EmailBox";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import FileView from "./FileView";
import { useSelector } from "react-redux";
import { mimeTypesConfig } from "../DocumentationModal/components/DocUploader/helpers";

/**
 *
 * @param {boolean} visible - value of the modal visibility
 * @param {function} setVisible - function to set the visibility of the modal
 * @param {object} file - object containing the file details
 * @param {number} downloadProgress - value of the download progress
 * @param {boolean} progressVisible - value of the progress visibility
 * @param {number || false} carouselLength - value of the carousel length
 * @param {function} changeCarouselSlide - function to change the carousel slide. Needs @param {number} - next or prev slide number
 * @returns {JSX.Element} - FilePreviewModal
 */

const FilePreviewModal = ({
  visible,
  setVisible,
  file = {},
  downloadProgress,
  progressVisible,
  carouselLength = false,
  changeCarouselSlide = () => {},
  allowEmail = true,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [emailBox, setEmailBox] = useState(false);
  const {
    id,
    name = "",
    src,
    mimeType,
    url,
    blob,
    index = null,
    size = 0,
    download = false,
  } = file;
  const [downloaded, setDownloaded] = useState(false);

  function formatBytes(bytes) {
    if (bytes === 0) return "0 Bytes";

    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
  }

  function onEmailSave({ e, key, type, setLoading }) {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setEmailBox(false);
    }
  }

  const sendEmail = allowEmail && !location.pathname.includes("foremanProject");

  return (
    <Modal
      {...{
        open: visible,
        setVisible,
        className: `filePreviewModal ${isDarkMode && "filePreviewModalDark"} ${
          !!carouselLength && "carouselFilePreviewModal"
        } ${
          index === 0 && !!carouselLength
            ? "noRightPadding"
            : index === carouselLength - 1 && !!carouselLength
            ? "noLeftPadding"
            : ""
        }`,
        destroyOnClose: true,
        centered: true,
        maskClosable: true,
        title:
          name + `${size ? " | " + formatBytes(size) : ""}` || "File Preview",
        closeIcon: <XIcon />,
        onCancel: () => {
          setEmailBox(false);
          setVisible(false);
        },
        footer: (
          <div className="gDriveButtons">
            <DownloadButton
              {...{
                src,
                id,
                name,
                blob,
                download,
                progressVisible,
                setDownloaded,
                downloaded,
              }}
            />
            <div className="gDriveButtonsRight">
              {/* {!!url && (
                <MondayButton
                  {...{
                    className: "mondayButtonBlue driveIcon",
                    Icon: <GDriveIcon />,
                    onClick: () => openInNewTab(url),
                  }}
                >
                  Open In Drive
                </MondayButton>
              )} */}
              {!!id && sendEmail && (
                <MondayButton
                  {...{
                    className: "mondayButtonBlue",
                    Icon: <EmailIcon />,
                    onClick: () => {
                      setEmailBox(true);
                    },
                  }}
                >
                  Send Email
                </MondayButton>
              )}
            </div>
          </div>
        ),
      }}
    >
      <>
        <div className="filePreviewCarousel">
          <div className="filePreviewLeft">
            {index !== 0 && !!carouselLength ? (
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => {
                  changeCarouselSlide(index - 1);
                }}
                Icon={<LeftArrow fill="#000" />}
              ></MondayButton>
            ) : (
              <div className="filePreviewLeft"></div>
            )}
          </div>
          <div className="filePreviewContent">
            {mimeType !== "application/pdf" &&
            mimeType !== "video/mp4" &&
            !mimeTypesConfig.images.includes(mimeType) &&
            mimeType !== "image/gif" ? (
              <div style={{ textAlign: "center", padding: 20 }}>
                <p>
                  Preview is not available for this file type. Please download
                  it to your PC to view.
                </p>
              </div>
            ) : (
              <FileView
                {...{
                  src,
                  id,
                  downloadProgress,
                  progressVisible,
                  mimeType,
                  blob,
                }}
              />
            )}
          </div>
          <div className="filePreviewRight">
            {index !== carouselLength - 1 && !!carouselLength ? (
              <MondayButton
                onClick={() => {
                  changeCarouselSlide(index + 1);
                }}
                className="mondayButtonBlue"
                Icon={<RightArrow fill="#000" />}
              ></MondayButton>
            ) : (
              <div className="filePreviewRight"></div>
            )}
          </div>
        </div>
        {index !== null && carouselLength ? (
          <div className="filePreviewFooter">
            {`${index + 1} of ${carouselLength}`}
          </div>
        ) : (
          <></>
        )}

        {emailBox && (
          <div className="safetyEmailBox">
            <EmailBox
              {...{
                onClose: () => setEmailBox(false),
                setEmails: () => {},
                rowData: {
                  drafts: [],
                },
                onSave: (e, type, setLoading) => {
                  onEmailSave({ e, type, setLoading });
                },
                isRedirected: [id],
                isFromCharge: true,
                // sendEmailNotification,
                proppedTemplateCat: "Documentation",
              }}
            />
          </div>
        )}
      </>
    </Modal>
  );
};

export default FilePreviewModal;

function DownloadButton({
  src,
  id,
  name,
  blob,
  download,
  progressVisible,
  setDownloaded,
  downloaded,
}) {
  function handleDownload() {
    const aElement = document.createElement("a");
    aElement.setAttribute("href", blob);
    aElement.setAttribute("download", name);
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);
    if (download) {
      setDownloaded(true);
    }
  }

  useEffect(() => {
    if (
      download &&
      !downloaded &&
      (progressVisible === false || progressVisible === undefined)
    ) {
      handleDownload();
    }
  }, [progressVisible, download, downloaded]);

  if (!!blob && blob !== "noSrc") {
    return (
      <MondayButton
        {...{
          className: "mondayButtonBlue",
          Icon: <DownloadIcon />,
          onClick: handleDownload,
        }}
      >
        Download
      </MondayButton>
    );
  } else {
    return (
      <MondayButton
        {...{
          className: "mondayButtonBlue",
          Icon: <DownloadIcon />,
          disabled: !id,
          onClick: () => {
            window.open(
              `https://drive.google.com/u/1/uc?id=${id}&export=download&authuser=0`
            );
          },
        }}
      >
        Download
      </MondayButton>
    );
  }
}
