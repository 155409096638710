import "./ServicesMenu.scss";

import { useEffect, useState } from "react";
import { Button, Flex } from "antd";
import { Link } from "react-router-dom";

import ElevationsModal from "./ElevationsModal";

import Headline from "../../../../commonComponents/Base/Headline";
import { hexToRgba } from "../../../utils";
import { useInspection } from "../../view/InspectionProvider";

const ServicesMenu = ({ form, className = "" }) => {
  const [open, setOpen] = useState(false);
  const [selectedEstimation, setSelectedEstimation] = useState(undefined);

  const {
    inspection,
    isDraft,
    completedServices,
    activeService,
    structuredEstimations,
    setActiveService,
    setCompletedServices,
  } = useInspection();

  const hasServiceQuestions = (services) => {
    return services?.some((element) => !!element?.serviceQuestions?.length);
  };

  const handleView = (estimation) => {
    setSelectedEstimation(estimation);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEstimation(undefined);
  };

  const validateActiveService = (serviceType) => {
    isDraft
      ? form
          .validateFields()
          .then(() => {
            setCompletedServices((prev) => [...prev, activeService?.type]);
            setActiveService(serviceType);
          })
          .catch((e) => {
            setCompletedServices((prev) =>
              prev.filter((type) => type !== activeService?.type)
            );
            setActiveService(serviceType);
          })
      : setActiveService(serviceType);
  };

  useEffect(() => {
    if (!!structuredEstimations?.length) {
      setActiveService({
        type: structuredEstimations[0]?.services[0]?.type,
        estimationId: structuredEstimations[0]?.services[0]?.estimationId,
      });
    }
  }, [inspection]);

  return (
    <div className={`inspectionViewServicesMenu ${className}`.trim()}>
      <div className="header">Services</div>
      <div className="bodyContent">
        {structuredEstimations?.map((estimation, index) =>
          hasServiceQuestions(estimation?.services) ? (
            <div key={index}>
              <div>
                {!!estimation?.estimationId ? (
                  <Link to={`/estimations/${estimation?.estimationId}`}>
                    <Headline level={5}>
                      Estimation{" "}
                      {!!estimation?.estimationNumber
                        ? estimation?.estimationNumber
                        : "#"}
                    </Headline>
                  </Link>
                ) : null}
              </div>
              {estimation?.services?.map((service, index) => (
                <div
                  id={index}
                  className={`servicesMenuItem ${
                    activeService?.type !== service?.type ||
                    activeService?.estimationId !== service?.estimationId
                      ? "notActive"
                      : ""
                  }`.trim()}
                  key={index}
                  onClick={() =>
                    (activeService?.type !== service?.type ||
                      activeService?.estimationId != service?.estimationId) &&
                    validateActiveService({
                      type: service?.type,
                      estimationId: service?.estimationId,
                    })
                  }
                >
                  {!!completedServices.includes(service?.type) && (
                    <div
                      className="completedServiceInspection"
                      style={{ color: service?.backgroundColor }}
                    >
                      COMPLETED
                    </div>
                  )}
                  <div
                    className="markerSquare"
                    style={{ backgroundColor: service?.backgroundColor }}
                  />
                  <Flex
                    className="serviceContainer"
                    gap={4}
                    style={{
                      backgroundColor: hexToRgba(
                        service?.backgroundColor,
                        0.12
                      ),
                    }}
                  >
                    <Flex vertical style={{ flexGrow: 1 }}>
                      <div className="serviceType">{service?.type}</div>
                      <div className="questionsCategoryContainer">
                        {service?.serviceQuestions.map(
                          ({ categoryName }, i) => (
                            <div key={i}>{categoryName}</div>
                          )
                        )}
                      </div>
                    </Flex>
                    <Flex justify="end" align="end">
                      <Button
                        data-testid="view-button"
                        type="primary"
                        onClick={() => handleView(estimation)}
                        disabled={
                          activeService?.type !== service?.type ||
                          activeService?.estimationId !== service?.estimationId
                        }
                      >
                        View
                      </Button>
                    </Flex>
                  </Flex>
                </div>
              ))}
            </div>
          ) : null
        )}
      </div>

      {open && (
        <ElevationsModal
          open={open}
          onClose={handleClose}
          estimation={selectedEstimation}
          activeService={activeService}
        />
      )}
    </div>
  );
};

export default ServicesMenu;
