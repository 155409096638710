import { useEffect, useMemo, useRef, useState } from "react";
import { driveApi } from "../../../../../../../../integrations/DriveRequest";
import { GPicker } from "../../../../../../../../integrations/GPicker";
import { fileIcons } from "../../../../documentationViewData";
import sortBy from "lodash/sortBy";
import "./DocUploader.scss";
import { FilePreviewModal } from "../../..";
import {
  MondayButton,
  RedWarningModal,
} from "../../../../../../../commonComponents";
import { UploadDoc, UploadIcon } from "../../../../assets";
import {
  convertObjectUrlToBlob,
  getImageSrcWithProgress,
  mimeTypeConfigText,
} from "./helpers";
import DocWarningsModal from "../../../DocumentationListCard/components/DocWarningsModal/DocWarningsModal";
import { WarningTriangle } from "../../../../../../DynamicView/src";
import getFilesWarnings from "../../../DocumentationListCard/components/DocWarningsModal/helpers/getFilesWarnings";
import getDocWarnings from "../../../DocumentationListCard/components/DocWarningsModal/helpers/getDocWarnings";
import DocUploaderFile from "./DocUploaderFile/DocUploaderFile";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import useTranslate from "../../../../../../../pages/ScheduleProgress/Language/useTranslate";
import DrawOnPhotoModal from "./DrawOnPhotoModal/DrawOnPhotoModal";
import { SaveIcon } from "../../../../../../BasePage/src";
import onPickerSuccessAdvanced from "./onPickerSuccessAdvanced";
import "./types";

const getConfig = (uploaderConfig) => {
  return {
    maxFileSize: uploaderConfig?.maxFileSize || false,
    allowedFileTypes: uploaderConfig?.allowedFileTypes || false,
    includeSize: uploaderConfig?.includeSize || false,
    singleUpload: uploaderConfig?.singleUpload || false,
    setLoading: uploaderConfig?.setLoading || null,
  };
};

/**
 *
 * @param {DocUploaderTypes} props - The props for MyComponent.
 * @returns {JSX.Element} - The rendered component.
 */
const DocUploader = ({
  documentation = {},
  setDocumentation,
  uploadedFiles = [],
  onDelete = () => {},
  onPickerSuccess = () => {},
  folderId = "",
  disabled = false,
  onChange = () => {},
  onlyUploadedFiles = false,
  checkWarnings = true,
  responsiveWidth = 1025,
  previewModal = "carousel",
  isCreateDocumentEnabled = false,
  setDocumentationModalFiles,
  uploaderConfig = {},
}) => {
  const {
    maxFileSize,
    allowedFileTypes,
    includeSize,
    singleUpload,
    setLoading,
  } = getConfig(uploaderConfig);

  const { t } = useTranslate();
  const { accessToken } = useSelector((state) => state.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const driveRequest = useMemo(() => driveApi({ accessToken }), [accessToken]);
  const { getDriveItem, getFileSize, getParents } = driveRequest;
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [previewModalVisible, setPreviewModalVisible] = useState();
  const [progressVisible, setProgressVisible] = useState(true);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [currentFile, setCurrentFile] = useState({});
  const [tempSrc, setTempSrc] = useState({});
  const [docWarningModalVisible, setDocWarningModalVisible] = useState(false);
  const [uploadsWarnings, setUploadsWarnings] = useState([]);
  const [documentationWarnings, setDocumentationWarnings] = useState([]);

  const makeFileSectionRow = useMediaQuery({
    query: `(max-width: ${responsiveWidth}px)`,
  });
  const containerRef = useRef(null);

  function setCurrFileToState(file) {
    setCurrentFile((prev) => {
      if (prev.src === "noSrc" || file?.error) {
        setTempSrc((previous) => {
          let temp = { ...previous };
          delete temp[prev.id];
          return temp;
        });
        return {
          ...file,
          src: "noSrc",
          blob: undefined,
        };
      } else {
        return file;
      }
    });
  }

  useEffect(() => {
    if (
      uploadedFiles.length > 0 &&
      folderId &&
      checkWarnings &&
      !onlyUploadedFiles &&
      accessToken
    ) {
      (async () => {
        const warnings = await getFilesWarnings(
          uploadedFiles,
          folderId,
          getParents
        );
        setUploadsWarnings(warnings);
      })();
    }
  }, [uploadedFiles, folderId, checkWarnings, accessToken]);

  useEffect(() => {
    if (
      Object.keys(documentation || {})?.length > 0 &&
      isCreateDocumentEnabled
    ) {
      const fetchWarnings = async () => {
        const docWarnings = await getDocWarnings([documentation], getParents);
        setDocumentationWarnings(docWarnings);
      };

      fetchWarnings();
    }
  }, [JSON.stringify(documentation || {})]);

  const fetchFile = async (fileId, progressCallback, currFile = {}) => {
    if (fileId) {
      try {
        let tmpSize = currFile?.size;
        if (!tmpSize) {
          const newSize = await getFileSize(fileId);
          tmpSize = newSize;
          onPickerSuccess({
            updatedFiles: [
              {
                ...currFile,
                size: newSize,
              },
            ],
          });
        }
        const response = await getImageSrcWithProgress(
          fileId,
          progressCallback,
          tmpSize,
          getDriveItem,
          currFile,
          () => {
            setProgressVisible(false);
          }
        );
        setTempSrc({
          ...tempSrc,
          [fileId]: response.blob,
        });

        setCurrFileToState({
          ...response,
          ...currFile,
          size: tmpSize,
        });
      } catch (error) {
        console.error("fetchFile ~ error:", error);
      }
    }
  };

  function calculateProgress(receivedLength, contentLength, file = {}) {
    const progress = Math.floor((receivedLength / contentLength) * 100);
    if (isNaN(progress)) {
      setCurrFileToState({
        ...file,
        src: "noSrc",
      });
      setDownloadProgress(0);
      setProgressVisible(false);
    } else {
      setDownloadProgress(progress);
    }
  }

  useEffect(() => {
    return () => {
      for (let key in tempSrc) {
        URL.revokeObjectURL(tempSrc[key]);
      }
    };
  }, []);

  const areAllFilesDocumented = uploadedFiles.every(
    (file) => file?.documented?.isDocumented
  );

  useEffect(() => {
    let timer;
    if (downloadProgress === 100 || isNaN(downloadProgress)) {
      timer = setTimeout(() => {
        setProgressVisible(false);
        setDownloadProgress(0);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [downloadProgress]);

  function changeCarouselSlide(index) {
    const sortedList = sortBy(uploadedFiles, "lastEditedUtc").reverse();
    if (sortedList?.[index]) {
      handleCurrentFile(sortedList?.[index]?.id, {
        ...sortedList[index],
        index,
      });
    }
  }

  function handleFileWithTempSrc(id, file) {
    setProgressVisible(false);
    setPreviewModalVisible(previewModal);
    setCurrFileToState({ ...file });

    convertObjectUrlToBlob(tempSrc[id])
      .then((res) => {
        setCurrFileToState({ ...res, ...file });
      })
      .catch((e) => {
        console.log(e);
        setCurrFileToState({ ...file });
        fetchFile(id, calculateProgress, file);
      });
  }

  function handleFileWithoutTempSrc(id, file) {
    setPreviewModalVisible(previewModal);
    setProgressVisible(true);
    setCurrFileToState({ ...file });
    fetchFile(id, calculateProgress, file);
  }

  function handleCurrentFile(id, file) {
    if (tempSrc[id]) {
      handleFileWithTempSrc(id, file);
    } else {
      handleFileWithoutTempSrc(id, file);
    }
  }

  function onImageViewerCancel() {
    setCurrentFile({});
    setPreviewModalVisible(false);
    setDownloadProgress(0);
    setProgressVisible(true);
  }

  return (
    <>
      <div
        className={`docUploader ${isDarkMode && "docUploaderDark"} ${
          makeFileSectionRow && "responsiveDocUploader"
        }`}
      >
        {!onlyUploadedFiles && (
          <div className="uploadSection">
            <UploadDoc className="uploaderIcon" />
            <span className="uploaderTitle">
              {t("Please upload your file")}.
            </span>
            <GPicker
              token={accessToken}
              onAuthenticate={() => {}}
              createPicker={(google, oauthToken) => {
                const uploadView = new google.picker.DocsUploadView()
                  .setIncludeFolders(false)
                  .setParent(folderId);
                if (
                  allowedFileTypes &&
                  !!mimeTypeConfigText?.[allowedFileTypes]
                ) {
                  uploadView.setMimeTypes(mimeTypeConfigText[allowedFileTypes]);
                }

                const picker = new window.google.picker.PickerBuilder()
                  .enableFeature(google.picker.Feature.SIMPLE_UPLOAD_ENABLED)
                  .enableFeature(google.picker.Feature.MINE_ONLY)
                  .enableFeature(google.picker.Feature.NAV_HIDDEN)
                  .addView(uploadView)
                  .setOAuthToken(oauthToken)
                  .setDeveloperKey("AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo")
                  .setCallback(async (data) => {
                    if (
                      data.action === google.picker.Action.PICKED &&
                      !!data.docs.length
                    ) {
                      if (!!setLoading) {
                        setLoading(true);
                      }
                      const documents = await onPickerSuccessAdvanced({
                        driveRequest,
                        docs: data.docs,
                        includeSize,
                        maxFileSize,
                      });
                      onChange();
                      onPickerSuccess({
                        uploadedFiles: documents,
                      });
                    }
                  });
                if (!singleUpload) {
                  picker.enableFeature(
                    google.picker.Feature.MULTISELECT_ENABLED
                  );
                }
                picker.build().setVisible(true);
              }}
              disabled={disabled}
            >
              <MondayButton
                {...{
                  //! Don't remove className
                  className: "mondayButtonBlue uploaderButton",
                  disabled,
                  Icon: <UploadIcon />,
                }}
              >
                {t("Choose File")}
              </MondayButton>
            </GPicker>
            {/* <button
              onClick={() => {
                window.open(
                  `https://drive.google.com/drive/folders/${folderId}`
                );
              }}
            >
              open
            </button> */}
          </div>
        )}
        {!!uploadedFiles?.length && (
          <div className="uploadedFilesSection">
            {uploadsWarnings?.length > 0 && (
              <div
                data-testid="open-docWarning-button"
                onClick={() => {
                  setDocWarningModalVisible(true);
                }}
                className="warningMessage"
              >
                <WarningTriangle
                  fill="#FE4C4A"
                  height={15}
                  width={17}
                  style={{ cursor: "pointer" }}
                />
                <span>
                  {+uploadsWarnings?.length + +documentationWarnings?.length}{" "}
                  warning found (click to view)
                </span>
              </div>
            )}
            <div
              ref={containerRef}
              onWheel={(event) => {
                if (makeFileSectionRow) {
                  const delta = Math.max(-1, Math.min(1, event.deltaY));
                  const newScrollLeft =
                    containerRef.current.scrollLeft + delta * 150;
                  containerRef.current.scrollLeft = newScrollLeft;
                  // event.preventDefault();
                }
              }}
              className="uploadedFilesMapping"
            >
              {sortBy(uploadedFiles, "lastEditedUtc")
                .reverse()
                ?.map((file, index) => {
                  const fileExtension = file?.name?.split(".").pop();

                  const fileType = Object.keys(fileIcons).includes(
                    fileExtension
                  )
                    ? fileExtension
                    : "image";
                  return (
                    <DocUploaderFile
                      key={file.id}
                      {...{
                        file,
                        index,
                        handleCurrentFile,
                        setWarningModalVisible,
                        disabled,
                        onlyUploadedFiles,
                        fileType,
                        isCreateDocumentEnabled,
                        setDocumentationModalFiles,
                        tempSrc,
                      }}
                    />
                  );
                })}
            </div>
          </div>
        )}
        {isCreateDocumentEnabled &&
          uploadedFiles?.length > 1 &&
          !areAllFilesDocumented && (
            <MondayButton
              {...{
                className: "mondayButtonBlue",
                disabled,
                Icon: <SaveIcon />,
              }}
              onClick={() => setDocumentationModalFiles(uploadedFiles)}
            ></MondayButton>
          )}
      </div>
      {!!warningModalVisible && (
        <RedWarningModal
          {...{
            visible: !!warningModalVisible,
            onCancel: () => setWarningModalVisible(false),
            titleText: t("Delete Image Warning"),
            deleteModal: true,
            footerProps: {
              onConfirm: () => {
                onDelete(warningModalVisible);
                setWarningModalVisible(false);
              },
              closeText: t("Cancel"),
              saveText: t("Confirm"),
            },
          }}
        >
          <p>{t("Are you sure you want to delete this file?")}</p>
        </RedWarningModal>
      )}
      {previewModalVisible === "carousel" && (
        <FilePreviewModal
          {...{
            visible: previewModalVisible,
            setVisible: onImageViewerCancel,
            file: currentFile,
            downloadProgress,
            progressVisible,
            carouselLength:
              uploadedFiles.length > 1 ? uploadedFiles.length : false,
            changeCarouselSlide,
          }}
        />
      )}
      {previewModalVisible === "drawOnPhoto" && (
        <DrawOnPhotoModal
          {...{
            visible: !!previewModalVisible,
            setVisible: onImageViewerCancel,
            currentFile,
            downloadProgress,
            progressVisible,
            folderId,
            isDarkMode,
            driveRequest,
            onPickerSuccess,
            onDelete,
            onChange,
            disabled,
          }}
        />
      )}
      {docWarningModalVisible && uploadsWarnings && (
        <DocWarningsModal
          {...{
            visible: docWarningModalVisible,
            setVisible: setDocWarningModalVisible,
            docWarnings: uploadsWarnings,
            accessToken,
            setUploadsWarnings,
            callbackFn: () => {},
            proppedCorrectFolderId: folderId,
          }}
        />
      )}
    </>
  );
};

export default DocUploader;
