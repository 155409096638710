import { useState } from "react";
import { Checkbox, message, Modal } from "antd";
import { MondayButton } from "../../../../../commonComponents";
import "./TableFieldModal.scss";
import { useEmailBoxBody } from "../../../../../SidebarPages/Communication/components/EmailBox/providers/EmailBoxBodyProvider";
import { showWarningMsg } from "../../../../../../utils";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../Roles/src";

const TableFieldModal = ({ options, setSelectedTable, getContent }) => {
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const { setEditorContent } = useEmailBoxBody();

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(
      !!list.length &&
        list.length <
          options.options.filter(
            ({ dynamicFieldRenderFor }) => dynamicFieldRenderFor === "single"
          ).length
    );
    setCheckAll(
      list.length ===
        options.options.filter(
          ({ dynamicFieldRenderFor }) => dynamicFieldRenderFor === "single"
        ).length
    );
  };

  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked
        ? options.options
            .filter(
              ({ dynamicFieldRenderFor }) => dynamicFieldRenderFor === "single"
            )
            .map(({ dynamicFieldLabel }) => dynamicFieldLabel)
        : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <Modal
      className={"tableFields-emailTemplateModal"}
      open={options.hasOwnProperty("options")}
      onCancel={() => setSelectedTable([])}
      closeIcon={<XIcon />}
      title="Choose Fields"
      footer={[
        <MondayButton
          className="mondayButtonRed"
          onClick={() => setSelectedTable([])}
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>,
        <MondayButton
          onClick={() => {
            if (checkedList.length === 0) {
              showWarningMsg({
                content: "Please select one or more fields",
              });
              return;
            }

            const tableToInsert = `<table id="${options.label}">
            <tr>
              ${checkedList
                .map((e) => `<th style="border: 1px solid #000">${e}</th>`)
                .join("")}
            </tr>
            <tr>
              ${checkedList
                .map(
                  (e) =>
                    `<td style="border: 1px solid #000">${
                      options.options.find(
                        ({ dynamicFieldLabel }) => dynamicFieldLabel === e
                      ).dynamicFieldValue
                    }</td>`
                )
                .join("")}
            </tr>
          </table>`;

            if (typeof getContent === "function") {
              try {
                getContent(tableToInsert, setEditorContent);
              } catch (error) {
                console.error("Error inserting content:", error);
                message.error({
                  content: "Error inserting content, please try again later.",
                });
              }
            } else {
              options.editor(formattedTableToInsert);
            }

            setSelectedTable([]);
          }}
          Icon={<TickIcon />}
        >
          Save
        </MondayButton>,
      ]}
    >
      <div className="checkAllContainer">
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Check all
        </Checkbox>
      </div>
      <div className="tableFieldsBody">
        <Checkbox.Group
          options={options.options
            .filter(
              ({ dynamicFieldRenderFor }) => dynamicFieldRenderFor === "single"
            )
            .map(({ dynamicFieldLabel }) => dynamicFieldLabel)}
          value={checkedList}
          onChange={onChange}
        />
      </div>
    </Modal>
  );
};
export default TableFieldModal;
