// import { GridApi } from "ag-grid-community";
import _ from "lodash";
import getWeeksTotal from "./getWeeksTotal";
// import { EmployeeType } from "../../../../../PayrollContext";
import getOverheadEntriesAnalytics from "./getOverheadEntriesAnalytics";
// import {
//   EntryType,
//   AnalyticsType,
// } from "../../../../../../PayrollLive/payrollLiveTypes";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

/** @typedef {import("ag-grid-community").GridApi} GridApi */
/** @typedef {import("../../../../../PayrollContext").AnalyticType} AnalyticType */
/** @typedef {import("../../../../../../PayrollLive/payrollLiveTypes").EntryType} EntryType */
/** @typedef {import("../../../../../PayrollContext").EmployeeType} EmployeeType */

/**
 * Function that checks the validity of the employee shifts
 * @param {{employeeList: Array<string>, degGridApi: GridApi<EntryType>, analyticsUpdate: (e:AnalyticsType) => void, externalFiltersPass: object, degRows: Array<EntryType>}} param0
 */
function getEmployeeAnalytics({
  degRows = [],
  degGridApi,
  employeeList,
  analyticsUpdate,
  externalFiltersPass,
}) {
  if (!degGridApi) {
    return;
  }
  let tmpRows = [];
  let hoursPerDay = {};
  let over24HShifts = {};
  let weeksForWorker = {};
  let tmpOverheadRows = [];
  let incorrectShifts = {};
  let notFoundLocations = [];
  let missingLunchEntries = [];
  let shiftsWithMultipleJobsites = [];

  // degGridApi?.forEachNode?.(({ data }) => {
  //   if (!!data?.punchLocation && !data?.jobsiteMatch?.jobsiteId) {
  //     if (!notFoundLocations.includes(data?.punchLocations)) {
  //       notFoundLocations.push(data?.punchLocation);
  //     }
  //   }
  //   if (dataValidator({ data, externalFiltersPass, employeeList })) {
  //     if (["CASH", "1099"].includes(data.punchType)) {
  //       tmpOverheadRows.push(data);
  //     } else {
  //       tmpRows.push(data);
  //       incorrectShifts[data?.employeeId] = [];
  //     }
  //   }
  //   if (data?.missingLunch) {
  //     missingLunchEntries.push(data?.entryId);
  //   }
  // });

  if (employeeList) {
    for (const employeeId of employeeList) {
      incorrectShifts[employeeId] = [];
    }
  }

  if (tmpRows?.length === 0 && !!degRows?.length) {
    for (let i = 0; i < degRows?.length; i++) {
      const data = degRows[i];
      if (!!data?.punchLocation && !data?.jobsiteMatch?.jobsiteId) {
        if (!notFoundLocations.includes(data?.punchLocations)) {
          notFoundLocations.push(data?.punchLocation);
        }
      }
      if (dataValidator({ data, externalFiltersPass, employeeList })) {
        if (["CASH", "1099"].includes(data.punchType)) {
          tmpOverheadRows.push(data);
        } else if (data?.punchType === "No Punch") {
          continue;
        } else {
          tmpRows.push(data);
          incorrectShifts[data?.employeeId] = [];
        }
        // tmpRows.push(data);
        // incorrectShifts[data?.employeeId] = [];
      }
    }
  }

  //we group and sort all the entries for the employees
  let groupedRows = _.groupBy(tmpRows, ({ employeeId }) => employeeId);

  for (const employeeId in groupedRows) {
    groupedRows[employeeId].sort(
      (a, b) => a?.punchTimeStamp - b?.punchTimeStamp
    );

    hoursPerDay[employeeId] = {};
    over24HShifts[employeeId] = [];
  }

  /**
   * Now we want to check the validity of the shifts.
   * We need to check for one of the following activity patterns:
   *  1. ID -> OL -> IL -> OD
   *  2. ID -> OD
   *  3. HR
   * Every punch that breaks the pattern will be put aside as "incorrect"
   */
  for (const employeeId in groupedRows) {
    let entriesForEmployee = groupedRows[employeeId];

    //we need to skip a few entries that match the patters
    let incrementor = 1;

    for (let i = 0; i < entriesForEmployee?.length; i += incrementor) {
      let punchType = entriesForEmployee[i]["punchType"];
      incrementor = 1;

      if (punchType !== "ID") {
        if (punchType === "HR") {
          let reason = entriesForEmployee[i]["reason"] || "";
          let rateToCalc = entriesForEmployee[i]?.employeeRate;
          let entryKey = dayjsNY(entriesForEmployee[i].punchDate).format(
            "MM/DD/YYYY"
          );

          if (reason?.toLowerCase()?.includes("unpaid")) {
            rateToCalc = 0;
          }
          // else if (reason?.toLowerCase()?.includes("sick")) {
          //   rateToCalc = rateToCalc * 0.8;
          // }

          hoursPerDay[employeeId][entryKey] = {
            degId: entriesForEmployee[i]?.degId,
            workHours: 8,
            breakHours: 0,
            overtimeHours: 0,
            payrollType: entriesForEmployee[i]?.payrollType,
            type: "Payroll Overhead",
            firstClockIn: entriesForEmployee[i].punchTimeStamp,
            inJobsiteId: undefined,
            salaryType: entriesForEmployee[i]["salaryType"] || "Hourly",
            employeeRole: entriesForEmployee[i]["employeeRole"],
            reason: entriesForEmployee[i]["reason"],
            outJobsiteId: undefined,
            hrShift: true,
            scheduleId: entriesForEmployee[i]?.scheduleId,
            scheduleAddress: entriesForEmployee[i]?.scheduleAddress,
            scheduleDayId: entriesForEmployee[i]?.scheduleDayId,
            crewTeamName: entriesForEmployee[i]?.crewTeamName,
            crewTeamId: entriesForEmployee[i]?.crewTeamId,
            company: entriesForEmployee[i]?.["company"],
            companyName: entriesForEmployee[i]?.["companyName"],
            crewId: entriesForEmployee[i]["crewId"],
            geofence: entriesForEmployee[i]["geofence"],
            uploadId: entriesForEmployee[i]["uploadId"],
            uploadName: entriesForEmployee[i]["uploadName"],
            punchDate: entriesForEmployee[i]["punchDate"],
            punchTime: entriesForEmployee[i]["punchTime"],
            jobsitesInfo: [
              {
                degId: entriesForEmployee[i]?.degId,
                workHours: 8,
                breakHours: 0,
                overtimeHours: 0,
                inJobsiteId: undefined,
                payrollType: entriesForEmployee[i]?.payrollType,
                firstClockIn: entriesForEmployee[i].punchTimeStamp,
                scheduleId: entriesForEmployee[i]?.scheduleId,
                scheduleAddress: entriesForEmployee[i]?.scheduleAddress,
                scheduleDayId: entriesForEmployee[i]?.scheduleDayId,
                punchCoordinates: undefined,
                sow: [],
                entries: [entriesForEmployee[i]?.entryId],
                activityStatusesArray: [entriesForEmployee[i]?.activityStatus],
                regAmount: rateToCalc * 8,
                employeeRate: rateToCalc,
                otAmount: 0,
                hrShift: true,
              },
            ],
            regAmount: rateToCalc * 8,
            otAmount: 0,
          };
        } else {
          incorrectShifts[employeeId] = (
            incorrectShifts[employeeId] || []
          ).concat(entriesForEmployee[i]?.entryId);
        }
        continue;
      }

      /**
       * at this stage we are sure to be have a punch type of ID
       * now we just need to check the pattern, so we iterate the entries
       */
      if (i !== entriesForEmployee.length - 1) {
        let loopIterations = 2;
        let prevPunch = punchType;

        for (let j = 1; j < loopIterations; j++) {
          let currentEntry = entriesForEmployee[i + j];
          let currentPunch = currentEntry?.punchType;

          if (!currentEntry) {
            break;
          }

          if (prevPunch === "ID") {
            if (currentPunch === "OD") {
              incrementor = 2;
            } else if (currentPunch === "OL") {
              prevPunch = "OL";
              loopIterations = 4;
            } else {
              break;
            }
          } else if (prevPunch === "OL") {
            if (currentPunch === "IL") {
              prevPunch = "IL";
            } else {
              break;
            }
          } else if (prevPunch === "IL") {
            if (currentPunch === "OD") {
              incrementor = 4;
            } else {
              break;
            }
          }
        }

        if (incrementor === 1) {
          incorrectShifts[employeeId] = (
            incorrectShifts[employeeId] || []
          ).concat(entriesForEmployee[i]?.entryId);
          continue;
        } else {
          /**
           * In this case we have found a valid pattern. A valid
           * pattern corresponds to a full shift. The incrementor tells us
           * if the shift contains a break or not.
           */
          let workHours = 0;
          let breakHours = 0;
          let lunchStart = undefined;
          let duration = 0;
          let distanceFromJob = 0;

          if (incrementor === 2) {
            //clock in and out
            workHours =
              (entriesForEmployee[i + 1].punchTimeStamp -
                entriesForEmployee[i].punchTimeStamp) /
              3600000;
            duration = entriesForEmployee[i].duration;
            distanceFromJob = entriesForEmployee[i].distanceFromJob;
          } else {
            //lunch break in between
            workHours =
              (entriesForEmployee[i + 1].punchTimeStamp -
                entriesForEmployee[i].punchTimeStamp) /
              3600000;
            workHours =
              workHours +
              (entriesForEmployee[i + 3].punchTimeStamp -
                entriesForEmployee[i + 2].punchTimeStamp) /
                3600000;
            breakHours =
              (entriesForEmployee[i + 2].punchTimeStamp -
                entriesForEmployee[i + 1].punchTimeStamp) /
              3600000;

            lunchStart = entriesForEmployee[i + 1].punchTimeStamp;
            duration = entriesForEmployee[i].duration;
            distanceFromJob = entriesForEmployee[i].distanceFromJob;
          }

          let entryKey = dayjsNY(entriesForEmployee?.[i]?.punchDate).format(
            "MM/DD/YYYY"
          );
          let activityStatusesArray = [];
          let entries = [];
          for (
            let activityIndex = i;
            activityIndex < i + incrementor;
            activityIndex++
          ) {
            activityStatusesArray = activityStatusesArray.concat(
              entriesForEmployee?.[activityIndex]?.["activityStatus"] || "Draft"
            );
            entries = entries.concat(
              entriesForEmployee?.[activityIndex]?.["entryId"]
            );
          }

          if (hoursPerDay?.[employeeId]?.[entryKey]) {
            let entryForDate = hoursPerDay[employeeId][entryKey];
            let jobsitesInfo = [...entryForDate["jobsitesInfo"]];
            let overtimeHours = 0;
            let type =
              entriesForEmployee[i]?.payrollType === "Certified Payroll" ||
              entriesForEmployee[i]?.payrollType === "Open Shop"
                ? // entriesForEmployee[i]?.payrollType === "Prevailing Wage"
                  "Payroll Overhead"
                : "Direct";
            if (workHours > 8) {
              overtimeHours = workHours - 8;
              workHours = 8;
            }

            let jobIndex = jobsitesInfo?.findIndex(
              ({ inJobsiteId }) =>
                inJobsiteId ===
                entriesForEmployee[i]["jobsiteMatch"]["jobsiteId"]
            );

            if (jobIndex > -1) {
              jobsitesInfo[jobIndex] = {
                degId: entriesForEmployee[i]?.degId,
                workHours: jobsitesInfo[jobIndex]["workHours"] + workHours,
                breakHours: jobsitesInfo[jobIndex]["breakHours"] + breakHours,
                overtimeHours:
                  jobsitesInfo[jobIndex]["overtimeHours"] + overtimeHours,
                inJobsiteId: entriesForEmployee[i]["jobsiteMatch"]["jobsiteId"],
                employeeRate: entriesForEmployee[i]?.employeeRate,
                payrollType: entriesForEmployee[i]?.payrollType,
                activityStatusesArray,
                entries,
                hrShift: false,
                firstClockIn: jobsitesInfo[jobIndex]["firstClockIn"],
                employeeRole: entriesForEmployee[i]["employeeRole"],
                punchCoordinates: entriesForEmployee[i]["punchCoordinates"],
                sow: [
                  ...jobsitesInfo[jobIndex]["sow"],
                  ...(entriesForEmployee[i]["sow"] || []),
                ],
                reimbursement:
                  entriesForEmployee[i]["jobsiteMatch"]?.["reimbursement"],
                regAmount:
                  jobsitesInfo[jobIndex]["regAmount"] +
                  workHours * parseFloat(entriesForEmployee[i]?.employeeRate),
                otAmount:
                  jobsitesInfo[jobIndex]["otAmount"] +
                  overtimeHours * entriesForEmployee[i]?.employeeRate * 1.5,
              };
            } else {
              jobsitesInfo.push({
                degId: entriesForEmployee[i]?.degId,
                workHours,
                breakHours,
                overtimeHours,
                punchCoordinates: entriesForEmployee[i]["punchCoordinates"],
                inJobsiteId: entriesForEmployee[i]["jobsiteMatch"]["jobsiteId"],
                employeeRate: entriesForEmployee[i]?.employeeRate,
                firstClockIn: entriesForEmployee[i].punchTimeStamp,
                payrollType: entriesForEmployee[i]?.payrollType,
                activityStatus: entriesForEmployee[i]["activityStatus"],
                activityStatusesArray,
                employeeRole: entriesForEmployee[i]["employeeRole"],
                sow: entriesForEmployee[i]["sow"] || [],
                hrShift: false,
                entries,
                reimbursement:
                  entriesForEmployee[i]["jobsiteMatch"]?.["reimbursement"],
                regAmount:
                  workHours * parseFloat(entriesForEmployee[i]?.employeeRate),
                otAmount:
                  overtimeHours * entriesForEmployee[i]?.employeeRate * 1.5,
              });
            }
            hoursPerDay[employeeId][entryKey] = {
              // degId: entryForDate?.["degId"],
              firstClockIn: entryForDate["firstClockIn"],
              inJobsiteId: entryForDate["inJobsiteId"],
              salaryType: entriesForEmployee[i]["salaryType"] || "Hourly",
              employeeRole: entriesForEmployee[i]["employeeRole"],
              scheduleId: entriesForEmployee[i]?.scheduleId,
              scheduleAddress: entriesForEmployee[i]?.scheduleAddress,
              scheduleDayId: entriesForEmployee[i]?.scheduleDayId,
              crewTeamName: entriesForEmployee[i]?.crewTeamName,
              crewTeamId: entriesForEmployee[i]?.crewTeamId,
              noJobsiteIncrementor:
                entriesForEmployee[i]?.["noJobsiteIncrementor"] || 0,
              jobsitesInfo,
              outJobsiteId:
                incrementor === 2
                  ? entriesForEmployee[i + 1]["jobsiteMatch"]["jobsiteId"]
                  : entriesForEmployee[i + 3]["jobsiteMatch"]["jobsiteId"],
              workHours: entryForDate["workHours"] + workHours,
              type,
              lunchStart:
                hoursPerDay[employeeId][entryKey]?.lunchStart || lunchStart, // or variable lunchStart when we add lunch time to the shift
              breakHours: entryForDate["breakHours"] + breakHours,
              overtimeHours: entryForDate["overtimeHours"] + overtimeHours,
              company: entriesForEmployee[i]?.["company"],
              companyName: entriesForEmployee[i]?.["companyName"],
              crewId: entriesForEmployee[i]["crewId"],
              geofence: entriesForEmployee[i]["geofence"],
              reason: entriesForEmployee[i]?.["reason"],
              uploadId: entriesForEmployee[i]["uploadId"],
              uploadName: entriesForEmployee[i]["uploadName"],
              punchDate: entriesForEmployee[i]["punchDate"],
              punchTime: entriesForEmployee[i]["punchTime"],
              duration,
              distanceFromJob,
            };
            if (
              hoursPerDay[employeeId][entryKey].inJobsiteId !==
              hoursPerDay[employeeId][entryKey].outJobsiteId
            ) {
              const shiftToPush = {
                ...hoursPerDay[employeeId][entryKey],
                employeeId,
              };
              shiftsWithMultipleJobsites.push(shiftToPush);
            }
          } else {
            let overtimeHours = 0;
            let type =
              entriesForEmployee[i]?.payrollType === "Certified Payroll" ||
              entriesForEmployee[i]?.payrollType === "Open Shop"
                ? // entriesForEmployee[i]?.payrollType === "Prevailing Wage"
                  "Payroll Overhead"
                : "Direct";
            if (workHours > 8) {
              overtimeHours = workHours - 8;
              workHours = 8;
            }

            hoursPerDay[employeeId][entryKey] = {
              // degId: entriesForEmployee[i]?.degId,
              workHours,
              breakHours,
              overtimeHours,
              type,
              scheduleId: entriesForEmployee[i]?.scheduleId,
              scheduleAddress: entriesForEmployee[i]?.scheduleAddress,
              scheduleDayId: entriesForEmployee[i]?.scheduleDayId,
              crewTeamName: entriesForEmployee[i]?.crewTeamName,
              crewTeamId: entriesForEmployee[i]?.crewTeamId,
              firstClockIn: entriesForEmployee[i].punchTimeStamp,
              inJobsiteId: entriesForEmployee[i]["jobsiteMatch"]["jobsiteId"],
              salaryType: entriesForEmployee[i]["salaryType"] || "Hourly",
              company: entriesForEmployee[i]?.["company"],
              companyName: entriesForEmployee[i]?.["companyName"],
              crewId: entriesForEmployee[i]["crewId"],
              geofence: entriesForEmployee[i]["geofence"],
              uploadId: entriesForEmployee[i]["uploadId"],
              uploadName: entriesForEmployee[i]["uploadName"],
              punchDate: entriesForEmployee[i]["punchDate"],
              reason: entriesForEmployee[i]?.["reason"],
              punchTime: entriesForEmployee[i]["punchTime"],
              jobsitesInfo: [
                {
                  degId: entriesForEmployee[i]?.degId,
                  workHours,
                  breakHours,
                  overtimeHours,
                  inJobsiteId:
                    entriesForEmployee[i]["jobsiteMatch"]["jobsiteId"],
                  firstClockIn: entriesForEmployee[i].punchTimeStamp,
                  punchCoordinates: entriesForEmployee[i]["punchCoordinates"],
                  payrollType: entriesForEmployee[i]?.payrollType,
                  sow: entriesForEmployee[i]["sow"] || [],
                  employeeRate: entriesForEmployee[i]?.employeeRate,
                  activityStatusesArray,
                  employeeRole: entriesForEmployee[i]["employeeRole"],
                  hrShift: false,
                  entries,
                  reimbursement:
                    entriesForEmployee[i]["jobsiteMatch"]?.["reimbursement"],
                  regAmount:
                    workHours * parseFloat(entriesForEmployee[i]?.employeeRate),
                  otAmount:
                    overtimeHours * entriesForEmployee[i]?.employeeRate * 1.5,
                },
              ],
              employeeRole: entriesForEmployee[i]["employeeRole"],
              hrShift: false,
              lunchStart,
              outJobsiteId:
                incrementor === 2
                  ? entriesForEmployee?.[i + 1]?.["jobsiteMatch"]?.["jobsiteId"]
                  : entriesForEmployee?.[i + 3]?.["jobsiteMatch"]?.[
                      "jobsiteId"
                    ],
              duration,
              distanceFromJob,
            };
            if (
              hoursPerDay[employeeId][entryKey].inJobsiteId !==
              hoursPerDay[employeeId][entryKey].outJobsiteId
            ) {
              const shiftToPush = {
                ...hoursPerDay[employeeId][entryKey],
                employeeId,
              };
              shiftsWithMultipleJobsites.push(shiftToPush);
            }
          }

          if (hoursPerDay[employeeId][entryKey]["workHours"] >= 24) {
            over24HShifts[employeeId] = [
              ...over24HShifts[employeeId],
              entryKey,
            ];
          }
        }
      } else {
        //the end of the array, this means that we have a ID shift as the last entry
        incorrectShifts[employeeId] = (
          incorrectShifts[employeeId] || []
        ).concat(entriesForEmployee[i]?.entryId);
      }
    }
  }

  const employeeOverheadShifts = getOverheadEntriesAnalytics({
    degRows: tmpOverheadRows,
  });
  const workerList = _.uniq(
    Object.keys(hoursPerDay || {}).concat(
      Object.keys(employeeOverheadShifts || {})
    )
  );

  let generalOverheadTotal = 0;
  tmpOverheadRows.forEach((data) => {
    if (!data?.punchDate) {
      generalOverheadTotal += Number(data?.totalOvh);
    }
  });

  weeksForWorker = getWeeksTotal({
    workerList,
    allEntries: hoursPerDay,
    employeeOverheadShifts,
  });

  analyticsUpdate({
    employeesHoursPerDay: hoursPerDay,
    employeeIncorrectShifts: incorrectShifts,
    employeeOver24HShift: over24HShifts,
    employeeWeekTotals: weeksForWorker,
    employeeNotFoundLocations: notFoundLocations,
    shiftsWithMultipleJobsites: shiftsWithMultipleJobsites,
    generalOverheadTotal,
    employeeOverheadShifts,
    missingLunchEntries,
    employeeList,
  });
}

function dataValidator({ data, externalFiltersPass, employeeList }) {
  let pushCondition = false;
  if (["CASH", "1099"].includes(data?.punchType)) {
    return true;
  }
  if (
    !!data?.punchDate &&
    !!data?.punchTime &&
    !!data?.punchType &&
    !!data?.employeeId
  ) {
    if (employeeList) {
      if (employeeList?.includes(data?.employeeId)) {
        if (externalFiltersPass) {
          pushCondition = externalFiltersPass({ data });
        } else {
          pushCondition = true;
        }
      }
    } else {
      if (externalFiltersPass) {
        pushCondition = externalFiltersPass({ data });
      } else {
        pushCondition = true;
      }
    }
  }
  return pushCondition;
}

export default getEmployeeAnalytics;
