import { useContext, useEffect, useState } from "react";
import { VideoTutorialIcon } from "../../assets/svg";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import NewCategories from "../NewCategories/NewCategories";
import { Tooltip } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import ResetModal from "../ResetModal/ResetModal";

const NewCard = () => {
  const { newVideos, setActivePrimaryCat, setSubCatVideos } =
    useContext(VideoTutorialContext);

  const [openNewCategory, setOpenNewCategory] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [videosToRemove, setVideosToRemove] = useState([]);

  const areAllSelected = newVideos.length === videosToRemove.length;

  useEffect(() => {
    newVideos.length === 0 && setOpenNewCategory(false);
  }, [newVideos]);

  const handleClick = () => {
    setOpenNewCategory((prev) => !prev);
    setActivePrimaryCat();
    setSubCatVideos();
  };

  const handleRemove = () => {
    setIsModalOpen(true);
  };

  return (
    <div>
      <div
        data-testid="newTutorialsCard"
        className={`newTutorialsCard ${openNewCategory ? "active" : ""}`}
        onMouseEnter={() => setShowReset(true)}
        onMouseLeave={() => setShowReset(false)}
        onClick={handleClick}
      >
        <span className="new-text"> NEW </span>
        <p className="text">
          Click to see our ({newVideos.length}) new tutorials
        </p>
        {!!showReset && newVideos.length ? (
          <Tooltip title="Reset videos">
            <div
              className="delete-container"
              onClick={(e) => {
                e.stopPropagation();
                handleRemove();
              }}
            >
              <RedoOutlined
                className="delete-btn"
                style={{ fontSize: "20px", color: "#fe4c4a" }}
              />
            </div>
          </Tooltip>
        ) : null}
        <VideoTutorialIcon className="videoPlayerIcon" />
      </div>
      {openNewCategory && (
        <NewCategories newVideos={newVideos} isNewVideo={true} />
      )}
      <ResetModal
        {...{
          setVideosToRemove,
          array: newVideos,
          areAllSelected,
          videosToRemove,
          isModalOpen,
          setIsModalOpen,
          modalTitle: "Reset New Tutorials",
          newOrFav: "new",
        }}
      />
    </div>
  );
};

export default NewCard;
