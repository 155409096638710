import React from "react";

import { MondayButton } from "../../../../../commonComponents";
import { LogsIcon } from "../../../../../SidebarPages/DynamicView/src";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../Settings/settingsComponents/Roles/src";

export const GOOGLE_API_KEY = "AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo";

/**
 * This function returns the distance between 2 positions in feet or in miles and
 * if this distance is within the tolerated distance in feet
 *
 * @typedef coordinates
 * @property {number} lat
 * @property {number} lng
 *
 *
 * @param {coordinates} jobsitePosition
 * @param {coordinates} employeePosition
 * @param {number} toleratedDistanceInFeet
 *
 */
export function withinRadius(
  jobsitePosition,
  employeePosition,
  toleratedDistanceInFeet = 300 // default distance toleration in feet measure
) {
  let earthRadius = 6371;
  let deg2rad = (n) => {
    return Math.tan(n * (Math.PI / 180));
  };

  let dLat = deg2rad(employeePosition?.lat - jobsitePosition?.lat);
  let dLon = deg2rad(employeePosition?.lng - jobsitePosition?.lng);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(jobsitePosition?.lat)) *
      Math.cos(deg2rad(employeePosition?.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  let c = 2 * Math.asin(Math.sqrt(a));
  let distanceInFeet = earthRadius * c * 3280.84; // multiply by 3280.84 to convert from Km to feet
  let distanceInMile = earthRadius * c * 0.621371; // multiply by 0.621371 to convert from Km to mile

  return {
    withinRange: distanceInFeet <= toleratedDistanceInFeet,
    distanceInFeet: parseFloat(distanceInFeet.toFixed(2)),
    distanceInMile: parseFloat(distanceInMile.toFixed(2)),
  };
}

export function footerButtons({
  onCancel = () => {},
  onSave = () => {},
  onShowLogs = () => {},
  // onDelete = () => {},
  editMode = false,
  allowEdit,
}) {
  return [
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        {/* {editMode && (
          <MondayButton
            onClick={onDelete}
            Icon={<TrashIcon />}
            className="mondayButtonRed"
          >
            Delete
          </MondayButton>
        )} */}
        {editMode && (
          <MondayButton
            onClick={onShowLogs}
            key={"show-logs"}
            Icon={<LogsIcon />}
            className="mondayButtonBlue"
          >
            Show Logs
          </MondayButton>
        )}
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        <MondayButton
          {...{
            Icon: <XIcon />,
            className: "mondayButtonRed",
            key: "cancel-btn",
            onClick: onCancel,
          }}
        >
          Cancel
        </MondayButton>
        <MondayButton
          {...{
            Icon: <TickIcon width={17} height={17} />,
            key: "save-btn",
            disabled: !allowEdit,
            onClick: onSave,
          }}
        >
          Save
        </MondayButton>
      </div>
    </>,
  ];
}

const punchTypeOptions = ["ID", "OD", "IL", "OL", "HR"];
const hrCategories = [
  "Unpaid Time Off",
  "Paid Time Of",
  "Vacation",
  "Sick",
  "Holiday",
  "Bereavement",
  "Personal",
  "Regular",
  "Overtime",
  "NYC Covid Leave",
];

export function activityModalFields({
  form,
  jobsites,
  position,
  emptyData,
  punchType,
  massEntry,
  crews = [],
  sowList = [],
  incorrectData,
  nameEditAllow,
  getCoordinates,
  companies = [],
  showAllServices,
  selectedRow = {},
  selectedNodes = [],
  selectedJobsite = {},
  selectedEmployee = {},
  onNameSelect = () => {},
  onJobsiteSelect = () => {},
  onPunchTypeSelect = () => {},
  onClearJobsiteChange = () => {},
  onClearPositionChange = () => {},
  degAccessRights = { children: [] },
}) {
  const fieldAccesses = {};
  const punchTypeValidation =
    selectedNodes.filter(
      (node) => node?.punchType === selectedNodes[0]?.punchType
    ).length !== selectedNodes.length;

  const emptyNodes =
    selectedNodes.filter(({ crewId }) => !!crewId).length !== 0;

  const isOverheadShift = selectedNodes.some((data) =>
    ["CASH", "1099"].includes(data?.punchType)
  );

  const unCompleteEntries = emptyData.concat(incorrectData);

  const statusOptions = ["Draft", "Pending", "Completed"];

  const empCoordinates = position?.coordinates?.lat
    ? position?.coordinates
    : selectedRow?.punchCoordinates;

  for (let i = 0; i < degAccessRights?.children?.length; i++) {
    const accessRight = degAccessRights?.children?.[i];
    Object.assign(fieldAccesses, { [accessRight?.title]: accessRight?.write });
  }

  return [
    {
      label: "Employee Full Name",
      formItemName: "employeeFullName",
      dataTestid: "employeeFullName",
      placeholder: "Employee Name...",
      type: "select",
      onSelect: onNameSelect,
      showSearch: true,
      disabled: !nameEditAllow || !!selectedRow?.employeeFullName?.length,
      popupMatchSelectWidth: false,
      getPopUpContainer: document.body,
      customOptions: crews.map(({ crewName, crewId }, key) => ({
        value: crewId,
        label: crewName,
        key,
      })),
    },
    {
      label: "Role",
      placeholder: "Employee role...",
      formItemName: "employeeRole",
      dataTestid: "employeeRole",
      type: "input",
      disabled: false,
    },
    {
      label: "Company",
      placeholder: "Company...",
      formItemName: "companyName",
      dataTestid: "companyName",
      type: "select",
      popupMatchSelectWidth: false,
      getPopUpContainer: document.body,
      disabled: true,
      customOptions:
        Array.isArray(companies) &&
        companies.map(({ accountId, accountName }, index) => ({
          key: index,
          value: accountId,
          label: accountName,
        })),
    },
    {
      label:
        selectedJobsite?.payrollType === "Prevailing Wage"
          ? `Rate (Prevailing Wage) (default ${selectedEmployee?.employeeRate})`
          : "Rate (default)",
      formItemName: "employeeRate",
      dataTestid: "employeeRate",
      disabled: punchType !== "ID" || !fieldAccesses?.["Rate"],
      type: "input",
      typeNumber: true,
      placeholder: "Rate...",
    },
    {
      label: "Punch Date",
      formItemName: "punchDate",
      dataTestid: "punchDate",
      placeholder: "Punch date...",
      type: "datePicker",
      disabled: massEntry ? false : selectedNodes?.length ? emptyNodes : false,
      // typeof selectedRow?.punchDate === "object",
      allowClear: true,
      getPopUpContainer: document.body,
    },
    {
      form,
      label: "Punch Time",
      formItemName: "punchTime",
      dataTestid: "punchTime",
      disabled: punchType === "HR" || punchTypeValidation,
      placeholder: "Punch time...",
      type: "customTimeInput",
    },
    {
      label: "Punch Type",
      formItemName: "punchType",
      dataTestid: "punchType",
      placeholder: "Punch type...",
      type: "select",
      onSelect: onPunchTypeSelect,
      customOptions: (isOverheadShift
        ? punchTypeOptions.concat(["CASH", "1099"])
        : punchTypeOptions
      ).map((opt, key) => ({
        key,
        value: opt,
        label: opt,
      })),
    },
    // !punchLocationAddresses?.length
    //   ?
    punchType !== "HR" && {
      label: "Punch Location",
      formItemName: "punchLocation",
      dataTestid: "punchLocation",
      type: "placesAutocomplete",
      onSelect: getCoordinates,
      onClear: onClearPositionChange,
      placeholder: "Punch Location...",
      getPopUpContainer: document.body,
    },
    punchType === "HR" && {
      label: "Reason",
      formItemName: "reason",
      dataTestid: "reason",
      type: "select",
      placeholder: "Select reason...",
      getPopUpContainer: document.body,
      customOptions: hrCategories.map((opt, key) => ({
        key,
        value: opt,
        label: opt,
      })),
    },
    isOverheadShift && {
      label: "Amount",
      formItemName: "totalOvh",
      dataTestid: "totalOvh",
      type: "input",
      typeNumber: true,
      placeholder: "Amount...",
      allowClear: true,
      onChange: (e) => form.setFieldValue("totalOvh", Number(e?.target?.value)),
    },
    // {
    //   label: "Distance",
    //   formItemName: "distance",
    //   type: "input",
    //   typeNumber: true,
    //   placeholder: "Distance...",
    //   addonAfter: "ft.",
    //   // value: distance,
    //   disabled: true,
    // },
    punchType !== "HR" && {
      label: "Jobsite Match",
      formItemName: "jobsiteMatch",
      dataTestid: "jobsiteMatch",
      type: "select",
      placeholder: "Jobsite...",
      // initialValue: selectedJobsite?.jobsiteId,
      onSelect: onJobsiteSelect,
      onClear: onClearJobsiteChange,
      getPopUpContainer: document.body,
      filterOption: (input, option) => {
        return (option?.label?.key || "")
          .toLowerCase()
          .includes((input || "").toLowerCase());
      },
      customOptions: jobsites
        .sort((a, b) => {
          const positionA = a?.addressPosition?.lat
            ? a?.addressPosition
            : { lat: 0, lng: 0 };
          const positionB = b?.addressPosition?.lat
            ? b?.addressPosition
            : { lat: 0, lng: 0 };

          const distanceA = withinRadius(
            positionA,
            empCoordinates
          )?.distanceInMile;
          const distanceB = withinRadius(
            positionB,
            empCoordinates
          )?.distanceInMile;

          return distanceA - distanceB;
        })
        .map(({ jobName, jobsiteId, addressPosition, accountName }) => ({
          key: jobsiteId,
          value: jobsiteId,
          label: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
              }}
              key={jobName} // key used for search options
            >
              <div>
                {jobName} - {accountName?.accountName || accountName || ""}
              </div>
              {(position?.coordinates?.lat ||
                selectedRow?.punchCoordinates?.lat) && (
                <div
                  style={{
                    color: "grey",
                  }}
                >
                  (
                  {
                    withinRadius(
                      addressPosition,
                      position?.coordinates || selectedRow?.punchCoordinates
                    )?.distanceInMile
                  }
                  ) Miles
                </div>
              )}
            </div>
          ),
          // `${jobName} | (${position?.coordinates? withinRadius(addressPosition, position?.coordinates)?.distanceInMile: 0} miles)`,
        })),
    },
    punchType !== "HR" && {
      label: "SOW",
      placeholder: "SOW...",
      type: "select",
      formItemName: "sow",
      dataTestid: "sow",
      mode: "multiple",
      // disabled: !sowList?.length,
      customOptions: sowList?.map?.((opt, index) => ({
        key: index,
        value: opt,
        label: opt,
      })),
      dropdownRender: (menu) => {
        return (
          <main>
            {menu}
            <section
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MondayButton
                className="mondayButtonBlue"
                data-testid="show-all-services-btn"
                hasIcon={false}
                onClick={showAllServices}
              >
                Show all Services
              </MondayButton>
            </section>
          </main>
        );
      },
    },
    {
      label: "Status",
      placeholder: "Select status...",
      type: "select",
      formItemName: "activityStatus",
      dataTestid: "activityStatus",
      customOptions: statusOptions.map((opt, key) => ({
        key,
        value: opt,
        label: opt,
        disabled: massEntry
          ? false
          : (opt === "Completed" &&
              unCompleteEntries.includes(selectedRow?.entryId)) ||
            (opt === "Completed" &&
              selectedNodes.some(({ entryId }) =>
                unCompleteEntries.includes(entryId)
              )),
      })),
    },
    isOverheadShift && {
      label: "Reason",
      formItemName: "reason",
      dataTestid: "reason",
      type: "textarea",
      placeholder: "Reason...",
      allowClear: true,
      style: { height: 100, marginBottom: 20 },
    },
  ].filter(Boolean);
}
