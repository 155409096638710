import { NO_JOB_NAME } from "./cellFunctions";
import getCostDispersion from "./getCostDispersion";
import groupEntriesInShifts from "./groupEntriesInShifts";
import getEmployeeAnalytics from "./getEmployeeAnalytics";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

/**
 * Function called when the DEG is going to be saved as completed.
 * The hours and the cost from the No Jobsite matches needs to
 * be distributed into all of the jobsites
 */
function distributeNoJobsiteCost({
  crews,
  rowData,
  jobsites,
  analytics,
  removeEntries,
  updateEntries,
  // addEntryAction,
}) {
  let editedEntries = [];
  const noJobsiteEntries = rowData.filter(
    ({ jobsiteMatch }) => jobsiteMatch?.jobName === NO_JOB_NAME
  );

  if (!noJobsiteEntries.length) {
    return rowData;
  }

  let updatedRowData = rowData.filter(
    ({ jobsiteMatch }) => jobsiteMatch?.jobName !== NO_JOB_NAME
  );

  let analyticsForNoJob;
  getEmployeeAnalytics({
    degGridApi: {},
    employeeList: undefined,
    analyticsUpdate(param) {
      analyticsForNoJob = param;
    },
    degRows: noJobsiteEntries,
  });

  const allShiftsWithoutNoJob = groupEntriesInShifts({
    analytics,
    crews,
    jobsites,
    rowData,
  }).filter(({ jobsiteMatch }) => jobsiteMatch?.jobName !== NO_JOB_NAME);

  const groupedShifts = _.groupBy(allShiftsWithoutNoJob, ({ punchTime }) =>
    punchTime ? dayjsNY(punchTime).format("MM/DD/YYYY") : null
  );

  const noJobsiteTotals = getCostDispersion({
    analytics: analyticsForNoJob,
    jobsites,
  });

  const noJobDates = new Set();
  for (const employeeId in analyticsForNoJob["employeesHoursPerDay"] || {}) {
    const hourPerEmployee =
      analyticsForNoJob["employeesHoursPerDay"][employeeId];

    for (const date in hourPerEmployee) {
      noJobDates.add(date);
    }
  }

  for (const date of noJobDates.values()) {
    const shiftsForDate = groupedShifts?.[date] || [];

    const totalHoursForDay = shiftsForDate.reduce(
      (acc, val) =>
        (acc += val?.totalOvh ? 0 : val?.workHours + val?.overtimeHours),
      0
    );

    for (const shift of shiftsForDate) {
      const { entries, overtimeHours, workHours, totalOvh } = shift;
      if (totalOvh) {
        continue;
      }

      let idEntryIndex = -1;
      for (const id of entries) {
        idEntryIndex = updatedRowData.findIndex(
          (updatedEntry) =>
            updatedEntry?.entryId === id && updatedEntry?.punchType === "ID"
        );

        if (idEntryIndex !== -1) {
          break;
        }
      }

      const workRatio = (overtimeHours + workHours) / totalHoursForDay;

      updatedRowData[idEntryIndex] = {
        ...updatedRowData[idEntryIndex],
        noJobsiteIncrementor: workRatio * noJobsiteTotals[0]["total"],
      };
      editedEntries.push(updatedRowData[idEntryIndex]);
    }
  }

  Promise.allSettled(
    [
      !!editedEntries?.length && updateEntries({ entries: editedEntries }),
      !!noJobsiteEntries?.length &&
        removeEntries({ entries: noJobsiteEntries }),
    ].filter(Boolean)
  ).catch((error) => console.log("Error distributing no job cost: ", error));

  // addEntryAction({ type: "edit", entry: editedEntries });
  // addEntryAction({ type: "remove", entry: noJobsiteEntries });

  return updatedRowData;
}

export default distributeNoJobsiteCost;
