import { useContext } from "react";
import "./SubCategoriesContainer.scss";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import SingleCard from "../SingleCard/SingleCard";
import { InfoCircleFilled } from "@ant-design/icons";
import VideosContainer from "../VideosContainer/VideosContainer";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const SubCategoriesContainer = ({
  setDisabled,
  draggableVideos,
  setDraggableVideos,
}) => {
  const {
    primaryArray,
    setPrimaryArray,
    activePrimaryCat,
    sumVideo,
    isDarkMode,
    subCatVideos,
  } = useContext(VideoTutorialContext);

  const totalVideos = primaryArray
    .find((item) => item.id === activePrimaryCat)
    .subCategories.reduce((acc, category) => {
      return acc + category.videos.length;
    }, 0);
  const dragEndHandler = (result) => {
    const tmpPrimaryArray = primaryArray.find(
      (item) => item.id === activePrimaryCat
    ).subCategories;
    if (!result.destination) return;
    const items = Array.from(tmpPrimaryArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const tmpDraggableCategories = primaryArray.map((item) => {
      if (item?.id === activePrimaryCat) {
        return { ...item, subCategories: items };
      } else {
        return item;
      }
    });
    setPrimaryArray(tmpDraggableCategories);
    setDisabled(false);
  };
  return (
    <div
      className={`subCategoriesContainer-card ${
        isDarkMode && "subCategoriesContainer-cardDark"
      }`}
    >
      <div className="header">
        <InfoCircleFilled
          style={{
            fontSize: "17px",
            color: "#1264A3",
          }}
        />
        <span style={{ fontSize: "13px" }}>
          The total airtime of the <b>{totalVideos}</b> videos is :
          <b> {sumVideo}</b> min{" "}
        </span>
      </div>
      <div className="cards">
        {!subCatVideos ? (
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided?.droppableProps} ref={provided?.innerRef}>
                  {primaryArray
                    .find((item) => item.id === activePrimaryCat)
                    .subCategories.map((subCat, i) => (
                      <div key={i}>
                        <Draggable
                          className="dragon"
                          draggableId={`draggable-${i}`}
                          index={i}
                          key={i}
                        >
                          {(provided) => (
                            <div
                              {...provided?.draggableProps}
                              {...provided?.dragHandleProps}
                              ref={provided?.innerRef}
                            >
                              <SingleCard
                                {...{
                                  item: subCat,
                                  category: false,
                                  setDisabled: setDisabled,
                                }}
                              />
                              {subCat?.videos?.[0]?.id ===
                                subCatVideos?.[0]?.id &&
                                subCatVideos && (
                                  <VideosContainer
                                    setDisabled={setDisabled}
                                    draggableVideos={draggableVideos}
                                    setDraggableVideos={setDraggableVideos}
                                  />
                                )}
                            </div>
                          )}
                        </Draggable>
                      </div>
                    ))}
                  {provided?.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div>
            {primaryArray
              .find((item) => item.id === activePrimaryCat)
              .subCategories.map((subCat) => (
                <div key={subCat.id}>
                  <SingleCard
                    {...{
                      item: subCat,
                      category: false,
                      setDisabled: setDisabled,
                    }}
                  />
                  {subCat?.videos?.[0]?.id === subCatVideos?.[0]?.id &&
                    subCatVideos && (
                      <VideosContainer
                        setDisabled={setDisabled}
                        draggableVideos={draggableVideos}
                        setDraggableVideos={setDraggableVideos}
                        subCatId={subCat.id}
                      />
                    )}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubCategoriesContainer;
