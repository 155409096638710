import dayjs from "dayjs";
import {
  GridApi,
  RowNode,
  ColumnApi,
  PostSortRowsParams,
} from "ag-grid-community";
import { API } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { ReloadOutlined } from "@ant-design/icons";
import { Form, message, Segmented, Tooltip } from "antd";
import { useState, useContext, useMemo, useCallback, useEffect } from "react";

import {
  gridFilters,
  useEntriesApi,
  entryColumnDefs,
  shiftColumnDefs,
  shiftGridFilters,
  getWeekDateRange,
  // entriesApiCrud,
} from "../utils";
import { PlusIcon } from "src/assets";
import { useResponsive } from "src/hooks";
import EntryModal from "../modals/EntryModal";
import PayrollLiveContext from "../PayrollLiveContext";
import { EntryType, ShiftEntry } from "../payrollLiveTypes";
import PayrollLayout from "../../Payroll/Layout/PayrollLayout";
import {
  StoreType,
  EmployeeType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { MondayButton, WarningModal } from "src/components/commonComponents";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { gridCustomOverlayLoading } from "src/components/pages/Payroll/Tabs/DEG/components/modalComponents/utils";
// import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { CrewsHeader } from "src/components/pages/Settings/settingsComponents/Crews/Components/CrewsHeader/CrewsHeader";

import "./LiveDegPayroll.scss";
import "../../Payroll/Payroll.scss";

dayjs.extend(weekOfYear);

type NewEmpModalType = {
  employeeId?: string;
  accountName?: string;
  employeeNumber?: string;
  employeeFullName?: string;
};

const defaultColumnDefinition = {
  flex: 1,
  filter: true,
  sortable: true,
  editable: false,
  resizable: true,
  enablePivot: true,
  enableRowGroup: true,
  enableColResize: true,
  groupHeaderHeight: 300,
};

const segmentOptions = [
  { label: "Entries", value: "entryId" },
  { label: "Shifts", value: "shiftId" },
];
function LiveDegPayroll() {
  const {
    jobsites,
    crewTeams,
    degEntries,
    accessRight,
    shiftEntries,
    setDegEntries,
    clientConfigs,
    clientCompany,
    selectedWeekDeg,
    controlPanelForm,
    setEmployeesReport,
    setSelectedWeekDeg,
    setProgramEmployees,
  } = useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const userConfiguration = useSelector(
    (store: StoreType) => store.userConfig.userConfiguration
  );

  const initialTableType =
    (sessionStorage.getItem("tableType") as "entryId" | "shiftId") || "entryId";

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [columnApi, setColumnApi] = useState<undefined | ColumnApi>();
  // const [payrollDisabled, setPayrollDisabled] = useState<boolean>(false);
  const [approveDisabled, setApproveDisabled] = useState<boolean>(false);
  const [entryModal, setEntryModal] = useState<boolean | EntryType>(false);
  const [deleteWarning, setDeleteWarning] = useState<EntryType | undefined>();
  const [tableType, setTableType] = useState<"entryId" | "shiftId">(
    initialTableType
  );
  const [gridApi, setGridApi] = useState<
    undefined | GridApi<EntryType | ShiftEntry>
  >();
  const [newEmpModal, setNewEmpModal] = useState<boolean | NewEmpModalType>(
    false
  );

  const { height } = useResponsive();
  const { getEntries, updateEntries, removeEntries, postEntries } =
    useEntriesApi();
  const selectedDate = Form.useWatch("selectedDate", controlPanelForm);

  const onGridReady = useCallback(
    (api: GridApi) => {
      if (api) {
        setGridApi(api);
        api.setGetRowId((param) => param?.data?.[initialTableType]);
        api.setColumnDefs(
          initialTableType === "shiftId" ? shiftsColDefs : entriesColDefs
        );
      }
    },
    [degEntries]
  );

  const getColumnApi = useCallback((colApi: ColumnApi) => {
    setColumnApi(colApi);
    colApi.autoSizeAllColumns();
  }, []);

  const isRowSelectable = useCallback(
    (e: RowNode<EntryType> | RowNode<ShiftEntry>) => {
      if (tableType === "entryId") {
        return (e as RowNode<EntryType>)?.data?.activityStatus !== "Completed";
      }
      if (tableType === "shiftId") {
        return (e as RowNode<ShiftEntry>)?.data?.shiftStatus !== "Completed";
      }
    },
    [tableType]
  );

  const postSortRows = useCallback(function (
    params: PostSortRowsParams<EntryType & { lastFetch: number }>
  ) {
    let rowNodes = params?.nodes;
    for (let i = 0; i < rowNodes.length - 1; i++) {
      for (let j = 0; j < rowNodes.length - 1 - i; j++) {
        if (
          rowNodes?.[j]?.data?.lastFetch < rowNodes?.[j + 1]?.data?.lastFetch
        ) {
          let temp = rowNodes?.[j];
          rowNodes[j] = rowNodes?.[j + 1];
          rowNodes[j + 1] = temp;
        }
      }
    }
  },
  []);

  function onEditClick(data: EntryType) {
    setEntryModal(data);
  }

  function onSaveCallback(data: EntryType) {
    const selectedJob = jobsites.find(
      (job) => job.jobsiteId === data?.jobsiteId
    );

    message.loading({ key: "entryCrud", content: "Saving...", duration: 0 });
    if (data?.entryId) {
      updateEntries({
        entries: [data],
        onSuccessCallback: () => {
          gridApi.applyTransaction({ update: [data] });
          setDegEntries((prev) =>
            prev.map((el) => (el?.entryId === data?.entryId ? data : el))
          );
          setEmployeesReport((prev) =>
            prev.map((el) =>
              Number(el?.employeeNumber) ===
              Number(data?.employeeId?.replace("GMNY Construction-", "").trim())
                ? { ...el, projectId: selectedJob?.projectId }
                : el
            )
          );
          message.success({
            key: "entryCrud",
            content: "Entry edited successfully",
            duration: 2,
          });
        },
        onErrorCallback: () => {
          message.error({
            key: "entryCrud",
            content: "There was a problem editing this entry",
            duration: 2,
          });
        },
      });
    } else {
      Object.assign(data, { entryId: uuid() });

      postEntries({
        entries: [data],
        onSuccessCallback: () => {
          gridApi.applyTransaction({ add: [data], addIndex: 0 });
          setDegEntries((prev) => [data].concat(prev));
          message.success({
            key: "entryCrud",
            content: "Entry created successfully",
            duration: 3,
          });
        },
        onErrorCallback: (error) => {
          console.log("Error creating entry: ", error);
          message.error({
            key: "entryCrud",
            content: "There was a problem creating this entry",
            duration: 3,
          });
        },
      });
    }
  }

  function onDelete(data: EntryType, api?: GridApi<EntryType>) {
    const tableApi = api || gridApi;
    message.loading({ key: "entryCrud", content: "Removing...", duration: 0 });
    removeEntries({
      entries: [data],
      onSuccessCallback: () => {
        tableApi.applyTransaction({ remove: [data] });
        setDegEntries((prev) =>
          prev.filter((el) => el.entryId !== data?.entryId)
        );
        setEmployeesReport((prev) =>
          prev.filter(
            (el) =>
              dayjsNY(el.punchTime).valueOf() !== data?.punchTimeStamp &&
              Number(el.employeeNumber) !==
                Number(data?.employeeId.replace("GMNY Construction-", ""))
          )
        );
        message.success({
          key: "entryCrud",
          content: "Entry deleted successfully",
          duration: 3,
        });
      },
      onErrorCallback: (error) => {
        console.log("Error deleting Entry: ", error);
        message.error({
          key: "entryCrud",
          content: "There was a problem deleting this entry",
          duration: 3,
        });
      },
    });
  }

  function onFlashRows() {
    gridApi.flashCells({
      rowNodes: gridApi.getRenderedNodes(),
    });
  }

  function onRefreshTable() {
    const selectedClient = clientConfigs.find(
      (el) => el.configId === controlPanelForm.getFieldValue("clientCompany")
    );
    const appliedFilter = [
      {
        conditions: [
          {
            id: uuid(),
            formula: "is",
            operator: "AND",
            column: "company",
            dataType: "string",
            columnType: "string",
            value: selectedClient?.clientName,
          },
          {
            id: uuid(),
            formula: "is",
            operator: "AND",
            column: "degId",
            dataType: "string",
            columnType: "string",
            value: selectedWeekDeg?.degId,
          },
          {
            id: uuid(),
            operator: "AND",
            dataType: "number",
            columnType: "number",
            column: "punchTimeStamp",
            value: selectedDate.valueOf(),
            formula: "is_greater_than_or_equal_to",
          },
        ],
        id: uuid(),
        operator: "AND",
      },
    ];
    const queryStringParameters = {
      getMaxLimit: "true",
      withPagination: "true",
      ExclusiveStartKey: undefined,
      filters: JSON.stringify(appliedFilter),
    };
    setRefreshing(true);
    message.loading({
      duration: 0,
      key: "onRefreshTable",
      content: "Refreshing...",
    });
    getEntries({
      queryStringParameters,
      onSuccessCallback: (res) => {
        setDegEntries(res?.degEntries || []);
        if (tableType === "entryId") {
          gridApi.setRowData(res?.degEntries || []);
        }
        message.success({
          duration: 3,
          content: "Refreshed",
          key: "onRefreshTable",
        });
        setRefreshing(false);
        onFlashRows();
      },
      onErrorCallback: (err) => {
        message.error({
          duration: 4,
          key: "onRefreshTable",
          content: "Network error",
        });
        setRefreshing(false);
        console.log("Error refreshing table: ", err);
      },
    });
  }

  function onNewEmployeeCreation(newEmp: EmployeeType) {
    setProgramEmployees((prev) => prev.concat(newEmp));
  }

  function sleep(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  function toggleEntries() {
    if (tableType === "entryId") {
      gridApi.setRowData([]);
      gridApi.setGetRowId((param) => param?.data?.shiftId);
      gridApi.setRowData(shiftEntries);
      gridApi.setColumnDefs(shiftsColDefs);
      setTableType("shiftId");
      sessionStorage.setItem("tableType", "shiftId");
      setTimeout(() => {
        columnApi.autoSizeAllColumns(false);
      }, 100);
    } else {
      gridApi.setRowData([]);
      gridApi.setGetRowId((param) => param?.data?.entryId);
      gridApi.setRowData(degEntries);
      gridApi.setColumnDefs(entriesColDefs);
      setTableType("entryId");
      sessionStorage.setItem("tableType", "entryId");
      setTimeout(() => {
        columnApi.autoSizeAllColumns(false);
      }, 100);
    }
  }

  function approveSelectedEntries() {
    if (!gridApi) {
      return;
    }
    message.loading({
      duration: 0,
      key: "approveEntries",
      content: "Approving...",
    });
    setApproveDisabled(true);
    const rows = gridApi.getSelectedRows();
    if (rows?.length) {
      let approvedRows: Array<EntryType> = [];
      if (tableType === "entryId") {
        approvedRows = (rows as Array<EntryType>).map((el) => ({
          ...el,
          activityStatus: "Completed",
        }));
      } else if (tableType === "shiftId") {
        for (let i = 0; i < rows.length; i++) {
          const shiftRow = rows[i] as ShiftEntry;
          for (let j = 0; j < degEntries.length; j++) {
            const entryRow = degEntries[j];
            if ((shiftRow?.entries || []).includes(entryRow?.entryId)) {
              approvedRows.push({ ...entryRow, activityStatus: "Completed" });
            }
          }
        }
      }
      updateEntries({
        entries: approvedRows as Array<EntryType>,
        onSuccessCallback: () => {
          message.success({
            duration: 2,
            key: "approveEntries",
            content: "Entries approved successfully!",
          });
          if (tableType === "entryId") {
            gridApi.applyTransaction({ update: approvedRows });
          }
          if (tableType === "shiftId") {
            gridApi.applyTransaction({
              update: rows.map((el) => ({ ...el, shiftStatus: "Completed" })),
            });
          }
        },
        onErrorCallback: (err) => {
          console.log("Error approving entries: ", err);
          message.error({
            duration: 2,
            key: "approveEntries",
            content: "There was a problem approving this entries",
          });
        },
      }).finally(() => setApproveDisabled(false));
    } else {
      message.warning({
        duration: 2,
        key: "approveEntries",
        content: "There are no rows selected to Approve.",
      });
      setApproveDisabled(false);
    }
  }

  async function postPayroll() {
    message.loading({
      duration: 0,
      key: "payrollPosting",
      content: "Generating Payroll data...",
    });
    const selectedClient = clientConfigs.find(
      (el) => el.configId === controlPanelForm.getFieldValue("clientCompany")
    );
    // setPayrollDisabled(true);
    const weekDateRange = getWeekDateRange(selectedDate);

    // const appliedFilter = [
    //   {
    //     conditions: [
    //       {
    //         id: uuid(),
    //         operator: "AND",
    //         column: "fromDate",
    //         dataType: "number",
    //         columnType: "number",
    //         value: weekDateRange[0].valueOf(),
    //         formula: "is_greater_than_or_equal_to",
    //       },
    //       {
    //         id: uuid(),
    //         operator: "AND",
    //         formula: "is_not",
    //         dataType: "string",
    //         value: "Completed",
    //         column: "degStatus",
    //         columnType: "string",
    //       },
    //       {
    //         id: uuid(),
    //         formula: "is",
    //         operator: "AND",
    //         dataType: "string",
    //         columnType: "string",
    //         column: "companyName",
    //         value: selectedClient.clientName,
    //       },
    //     ],
    //     id: uuid(),
    //     operator: "AND",
    //   },
    // ];

    // const degRes = await API.get("deg", "/deg", {
    //   queryStringParameters: {
    //     getMaxLimit: "true",
    //     withPagination: "true",
    //     ExclusiveStartKey: undefined,
    //     filters: JSON.stringify(appliedFilter),
    //   },
    // });

    let degId = selectedWeekDeg?.degId || uuid();
    const weekNr = selectedDate.week();
    let postChunks = [];

    let bodyObj = {
      degId,
      degName: `Week ${weekNr} Date(${selectedDate?.format("MM/DD/YYYY")})`,
      degStatus: "Draft",
      overtimeNames: [],
      fromDate: weekDateRange[0].valueOf(),
      toDate: weekDateRange[1].valueOf(),
      createdBy: {
        nameOfUser: userConfiguration.nameOfUser,
        identityId: userConfiguration.identityId,
      },
    };

    if (selectedWeekDeg) {
      // const degData = degRes?.deg?.find?.(
      //   (el) => el.toDate <= weekDateRange[1].valueOf()
      // );
      if (selectedWeekDeg?.degStatus === "Completed") {
        message.warning({
          duration: 2,
          key: "payrollPosting",
          content: "This payroll already exists in the program.",
        });
        // setPayrollDisabled(false);
        return;
      }
      degId = selectedWeekDeg?.degId;
      Object.assign(bodyObj, selectedWeekDeg);
    }

    const entries = degEntries.flatMap((e) =>
      !!e?.entryId
        ? {
            ...e,
            activityStatus:
              e?.activityStatus === "Draft" ? "Completed" : e?.activityStatus,
            degId,
          }
        : []
    );
    for (let i = 0; i < entries?.length; i += 500) {
      postChunks.push(entries.slice(i, i + 500));
    }

    try {
      // Initial data of the DEG body
      if (!selectedWeekDeg?.degId) {
        const newDeg = await API.post("deg", "/deg", {
          body: bodyObj,
        });
        setSelectedWeekDeg(newDeg);
      } else {
        await API.put("deg", `/deg/${degId}`, {
          body: {
            fromDate: weekDateRange[0].valueOf(),
            toDate: weekDateRange[1].valueOf(),
          },
        });
      }

      for (let i = 0; i < postChunks?.length; i++) {
        await sleep(1000);
        await API.post("degEntries", "/degEntries", {
          body: {
            entries: postChunks?.[i],
          },
        });

        await sleep(1000);
        await API.del("employeeReports", "/employeeReports/delete", {
          body: {
            entries: (postChunks?.[i] || []).map(
              (el: EntryType) => el?.entryId
            ),
          },
        });
      }
      setDegEntries([]);
      message.success({
        duration: 2,
        key: "payrollPosting",
        content: "Payroll generated successfully!",
      });
    } catch (error) {
      console.log("Error creating payroll: ", error);
      // setPayrollDisabled(false);
      message.success({
        duration: 2,
        key: "payrollPosting",
        content: "Payroll generated successfully!",
      });
    }
  }

  const paginationPageSize = useMemo(() => {
    return Math.floor((height - 300) / 38);
  }, [height]);

  const entriesColDefs = useMemo(() => {
    return entryColumnDefs({
      accessRight,
      onEditClick,
      setNewEmpModal,
      onDelete: setDeleteWarning,
    });
  }, [gridApi, tableType, accessRight]);

  const shiftsColDefs = useMemo(() => {
    return shiftColumnDefs({ accessRight });
  }, [gridApi, tableType, accessRight]);

  useEffect(() => {
    if (gridApi && columnApi) {
      const storageTableType = sessionStorage.getItem("tableType");
      const selectedCompany = (clientConfigs || [])?.find(
        (el) => el?.configId === clientCompany
      );

      const rowNode1 = gridApi.getDisplayedRowAtIndex(0);
      const momentCompany = rowNode1?.data?.companyName;

      if (
        momentCompany !== selectedCompany?.clientName &&
        (selectedCompany?.clientName === degEntries?.[0]?.companyName ||
          selectedCompany?.clientName === shiftEntries?.[0]?.companyName)
      ) {
        gridApi.setRowData(
          storageTableType === "shiftId" ? shiftEntries : degEntries
        );
        setTimeout(() => {
          columnApi.autoSizeAllColumns(false);
          onFlashRows();
        }, 100);
      }
    }
  }, [clientCompany, gridApi, columnApi, degEntries, shiftEntries]);

  useEffect(() => {
    const rowLength = gridApi?.getDisplayedRowCount?.() || 0;
    if (
      (!!gridApi && !!columnApi && degEntries?.length && !rowLength) ||
      (degEntries?.length !== rowLength && !!gridApi)
    ) {
      const storageTableType = sessionStorage.getItem("tableType");
      gridApi.setRowData(
        storageTableType === "shiftId" ? shiftEntries : degEntries
      );
      setTimeout(() => {
        columnApi.autoSizeAllColumns(false);
      }, 100);
    }
  }, [gridApi, columnApi, degEntries, shiftEntries]);

  return (
    <section
      className={`live-deg-payroll main-payroll-view ${
        darkMode ? "main-payroll-view-dark live-deg-payroll-dark" : ""
      }`}
    >
      <PayrollLayout
        hasNew={false}
        title="Live DEG"
        suppressDoubleClickEdit
        getGridApi={onGridReady}
        getColumnApi={getColumnApi}
        postSortRows={postSortRows}
        isRowSelectable={isRowSelectable}
        paginationPageSize={paginationPageSize}
        context={{ crewTeams, degEntries, darkMode }}
        defaultColumnDefinition={defaultColumnDefinition}
        overlayLoadingTemplate={gridCustomOverlayLoading()}
        gridFilters={tableType === "entryId" ? gridFilters : shiftGridFilters}
        onColumnRowGroupChanged={(e: any) =>
          e?.columnApi?.autoSizeAllColumns(false)
        }
        customActionButtons={[
          <Segmented
            value={tableType}
            onChange={toggleEntries}
            options={segmentOptions}
            className="table-type-segment"
            data-testid="table-type-segment"
          />,
          <Tooltip title="Refresh table">
            <MondayButton
              hasIcon={false}
              disabled={refreshing}
              onClick={onRefreshTable}
              className={darkMode ? "mondayButtonBlue" : "mondayButtonWhite"}
            >
              <ReloadOutlined />
            </MondayButton>
          </Tooltip>,
          accessRight?.write ? (
            <MondayButton
              Icon={<PlusIcon />}
              disabled={approveDisabled}
              onClick={approveSelectedEntries}
              className={approveSelectedEntries ? "mondayButtonBlue" : ""}
            >
              Approve Entries
            </MondayButton>
          ) : null,
          // <MondayButton
          //   Icon={<TickIcon />}
          //   onClick={postPayroll}
          //   disabled={
          //     !degEntries?.length ||
          //     parseInTz().startOf("d").valueOf() ===
          //       dayjsNY(degEntries?.[0]?.punchDate).valueOf() ||
          //     payrollDisabled
          //   }
          // >
          //   Post DEG
          // </MondayButton>,
        ]}
      />
      {entryModal ? (
        <EntryModal
          open={!!entryModal}
          onDeleteCallback={onDelete}
          onSaveCallback={onSaveCallback}
          editData={entryModal as EntryType}
          onCancel={() => setEntryModal(false)}
        />
      ) : null}
      {!!newEmpModal ? (
        <CrewsHeader
          isNextStep={false}
          visible={!!newEmpModal}
          defaultData={newEmpModal}
          setVisible={setNewEmpModal}
          refreshTable={onNewEmployeeCreation}
        />
      ) : null}
      {!!deleteWarning ? (
        <WarningModal
          closable={true}
          title="Warning Message"
          visible={!!deleteWarning}
          setVisible={setDeleteWarning}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>Are you sure to delete this report?</p>
            <div className="buttons">
              <MondayButton
                Icon={<XIcon />}
                className="mondayButtonRed"
                onClick={() => setDeleteWarning(undefined)}
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  onDelete(deleteWarning, gridApi as GridApi<EntryType>);
                  setDeleteWarning(undefined);
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      ) : null}
    </section>
  );
}

export default LiveDegPayroll;
