import { Alert, Modal, Select } from "antd";
import { InputComponent } from "../../../SidebarPages/Fleet/components";
import { antdModalProps } from "../../../../utils/antdModalProps";
import { useSelector } from "react-redux";
import { MondayButton } from "../../../commonComponents";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import { useEffect, useState } from "react";
import ClaimsModal from "./ClaimsModal";
import {
  filterTables,
  lazyFetch,
  updateDocumentTitle,
} from "../../../../utils";
import IndividualLoader from "../../../IndividualLoader";
import { PlusIcon } from "../../../../assets";
import "./PreClaimsModal.scss";
import SafetyModal from "../../../SidebarPages/Safety/SafetyModal/SafetyModal";
import { safetyFiltering } from "./helpers";
const PreClaimsModal = ({
  visible,
  setVisible,
  projectId,
  category,
  isNextStep,
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
  fromHearing = false,
  proppedType,
}) => {
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [claimModalVisible, setClaimModalVisible] = useState(false);
  const [fieldsOfCategory, setFieldsOfCategory] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [loading, setLoading] = useState(false);
  const [safetyModalVisible, setSafetyModalVisible] = useState(false);
  const claimCategories = programFields.find(
    ({ fieldName }) => fieldName === "Claim Categories"
  ).fieldOptions;

  const onSelectCategory = (e) => {
    setSelectedCategory(e);
    setSelectedField(null);
  };

  useEffect(() => {
    setFieldsOfCategory([]);
    !!selectedCategory &&
      (async () => {
        try {
          setLoading(true);
          const res = await filterTables(
            "safety",
            "safetyApplicationCategory",
            selectedCategory
          );
          const safety = safetyFiltering(res, selectedCategory, projectId);
          setFieldsOfCategory(safety);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      })();
  }, [selectedCategory]);

  return (
    <>
      <Modal
        {...{
          ...antdModalProps,
          title: "New Claim Modal",
          afterOpenChange: (event) => {
            event && updateDocumentTitle({ newTitle: "New Claim Modal" });
          },
          open: visible,
          className: `pre-claim-modal ${isDarkMode && "pre-claim-modal-dark"}`,
          onCancel: () => {
            updateDocumentTitle(); // reset document title to "Lead Manager";
            setVisible(false);
            setSelectedCategory(null);
          },
        }}
      >
        <div className="claim-modal-content">
          <InputComponent
            required
            showSearch
            dataTestid="claim-category-select"
            label="Category"
            placeholder="Select a category"
            dropdownClassName={isDarkMode && "darkDropDown"}
            type="select"
            initialValue={selectedCategory}
            options={claimCategories?.map(({ categoryName, categoryId }) => (
              <Select.Option key={categoryId} value={categoryName}>
                {categoryName}
              </Select.Option>
            ))}
            onSelect={(e) => onSelectCategory(e)}
            onClear={() => {
              setSelectedCategory(null);
            }}
          />
          {selectedCategory && selectedCategory !== "General" ? (
            fieldsOfCategory?.length ? (
              <InputComponent
                showSearch
                dataTestid="safety-select"
                label="Safety"
                // style={{ width: "100%" }}
                placeholder="Select an Safety"
                dropdownClassName={isDarkMode && "darkDropDown"}
                type="select"
                customOptions={fieldsOfCategory?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                  incident: { id, name },
                }))}
                initialValue={selectedField?.id}
                dropdownRender={(menu) => (
                  <div className="safetyDropDown">
                    {menu}
                    <div
                      className="addSafety"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 10,
                        paddingBottom: 5,
                      }}
                    >
                      <MondayButton
                        onClick={() => {
                          setSafetyModalVisible(true);
                        }}
                        className="mondayButtonBlue"
                        Icon={<PlusIcon />}
                      >
                        Create New Safety
                      </MondayButton>
                    </div>
                  </div>
                )}
                onSelect={(e, data) => setSelectedField(data.incident)}
                onClear={() => {
                  setSelectedField(null);
                }}
              />
            ) : loading ? (
              <div className="loader">
                <IndividualLoader />
              </div>
            ) : (
              <div
                className="noClaimsDiv"
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Alert
                  style={{ height: "32px", width: "100%" }}
                  message={`No Safety for this category!`}
                  type="error"
                  showIcon
                  // closable
                />
                <MondayButton
                  onClick={() => {
                    setSafetyModalVisible(true);
                  }}
                  className="mondayButtonBlue"
                  Icon={<PlusIcon />}
                >
                  Create New Safety
                </MondayButton>
              </div>
            )
          ) : (
            ""
          )}
          <div className="modalFooter">
            <MondayButton
              {...{
                "data-testid": "open-claim-button",
                Icon: <TickIcon />,
                onClick: () => {
                  setClaimModalVisible(true);
                },
                disabled: !selectedCategory,
                tooltipCategory: "Claims",
                tooltipKey: "open",
              }}
            >
              Open Claim Modal
            </MondayButton>
          </div>
        </div>
      </Modal>
      {claimModalVisible && selectedCategory && (
        <ClaimsModal
          {...{
            visible: claimModalVisible,
            setVisible: setClaimModalVisible,
            category: selectedCategory,
            incident: selectedField,
            isNextStep,
            nextStepHandler,
            fromHearing,
            proppedType,
            handleSavedRecord,
          }}
        />
      )}
      {safetyModalVisible && selectedCategory && (
        <SafetyModal
          {...{
            setEdit: setSafetyModalVisible,
            category: selectedCategory,
            isNextStep,
            refreshTable: (arr) => {
              const safety = safetyFiltering(arr, selectedCategory, projectId);
              setFieldsOfCategory((prev) => [...prev, safety[0]]);
              setSelectedField(safety[0]);
            },
          }}
        />
      )}
    </>
  );
};
export default PreClaimsModal;
