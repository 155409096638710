import { Divider, Select } from "antd";
import { formatDate } from "../../../helpers/creators";
import React from "react";
import dayjs from "dayjs";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";

export const DaySelectOption = ({ scheduleDay, isDarkMode }) => {
  // console.log("option isDarkMode: ", isDarkMode);
  return (
    <Select.Option
      value={scheduleDay?.id}
      label={
        <span
          style={
            isDarkMode
              ? {
                  color: `${scheduleDay?.color}`,
                  fontWeight: "bold",
                  backgroundColor: "#20212d",
                  padding: 2,
                  borderRadius: 5,
                }
              : { color: `${scheduleDay?.color}`, fontWeight: "bold" }
          }
        >
          {"D" + scheduleDay?.day}
        </span>
      }
      key={scheduleDay?.id}
      title={
        "D" +
        scheduleDay?.day +
        " " +
        dayjs(scheduleDay?.startDate).format("MM/DD/YYYY")
      }
    >
      <span
        style={
          isDarkMode
            ? {
                color: `${scheduleDay?.color}`,
                fontWeight: "bold",
                backgroundColor: "#20212d",
                padding: 2,
                borderRadius: 5,
              }
            : { color: `${scheduleDay?.color}` }
        }
      >
        D{scheduleDay?.day}
      </span>
      <Divider type="vertical" />
      <span role="img">
        {`${dayjs(scheduleDay?.startDate).format("MM/DD/YYYY")} ${dayjsNY(
          scheduleDay?.startDate
        ).format("h:mm a")} - ${dayjsNY(scheduleDay?.endDate).format(
          "h:mm a"
        )}`}
      </span>{" "}
      {/* <Divider type="vertical" /> */}
    </Select.Option>
  );
};
