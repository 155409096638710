import { Progress } from "antd";
import { useSelector } from "react-redux";
import { forwardRef, useState, useImperativeHandle } from "react";

import "./UploadProgress.scss";

/**
 * @typedef RefCurrentHandle
 * @property {() => number} getPercentage
 * @property {(value: number) => void} addPercentage
 * @property {(value: number) => void} setPercentage
 *
 * @typedef RefHandle
 * @property {RefCurrentHandle} current
 */

const UploadProgress = forwardRef(
  /**
   * @param {Object} props
   * @param {object} [props.strokeColor] Color of filled percentage
   * @param {string} [props.trailColor] Color of unfilled percentage
   * @param {React.RefAttributes<RefCurrentHandle>} ref Component reference
   * @param {boolean} [props.showPercent] Whether to show the progress percentage
   * @param {"line"|"circle"|"dashboard"} [props.type] Type of progress (line | circle | dashboard)
   * @returns {JSX.Element}
   */
  ({ strokeColor, trailColor, type, showPercent = true }, ref) => {
    const { isDarkMode } = useSelector((state) => state.darkMode);
    const [percent, setPercent] = useState(0);

    /**
     * Add the passed value to the percentage
     * @param {number} value
     */
    function addPercentage(value) {
      setPercent((prev) => prev + value);
    }

    /**
     * Set percentage equal to the passed value
     * @param {number} value
     */
    function setPercentage(value) {
      setPercent(value);
    }

    /**
     * Retrieves the percentage number
     * @returns {number}
     */
    function getPercentage() {
      return percent;
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          addPercentage,
          setPercentage,
          getPercentage,
        };
      },
      [percent]
    );

    return (
      <section
        data-testid="upload-progress-container"
        className={`upload-progress-container ${
          isDarkMode && "upload-progress-container-dark"
        } ${!showPercent ? "no-percent-text" : ""}`}
      >
        <Progress
          type={type || "line"}
          data-testid="upload-progress"
          percent={Math.round(percent)}
          trailColor={trailColor || (isDarkMode ? "#12131b" : "#f8f8fa")}
          strokeColor={strokeColor || { "0%": "#108ee9", "100%": "#87d068" }}
        />
      </section>
    );
  }
);

export default UploadProgress;
