import { Select } from "antd";
import "./MultipleAutoComplete.scss";
import { uniq } from "lodash";
import { DropDownArrow } from "../../../Fleet/components/InputComponent/assets";

const FormItemMultipleAutoComplete = ({
  value,
  form,
  name,
  options = [],
  disabled,
  placeholder,
  style,
  isDarkMode,
}) => {
  return (
    <div
      className={`MultipleAutoComplete ${
        isDarkMode && "MultipleAutoCompleteDark"
      }`}
    >
      <Select
        showSearch
        placeholder={placeholder}
        mode="multiple"
        popupClassName={`EditMultipleDropDownClassName ${style} ${
          isDarkMode && "darkDropDown"
        }`}
        suffixIcon={<DropDownArrow />}
        className={`EditDropdownComponent  ${style}`}
        value={value || []}
        disabled={disabled}
        onChange={(e, i) => {
          if (e.length !== 0) {
            form.setFieldsValue({ [name]: e });
          } else {
            form.setFieldsValue({ [name]: undefined });
          }
        }}
      >
        {uniq(options).map((e, i) => {
          return (
            <Select.Option key={i} value={e}>
              {e}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};
export default FormItemMultipleAutoComplete;
