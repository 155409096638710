import { useContext, useState } from "react";
import "./CategoriesContainer.scss";
import SingleCard from "../SingleCard/SingleCard";
import NewCard from "../NewCard/NewCard";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import { HistoryOutlined, SearchOutlined } from "@ant-design/icons";
import FilteredVideos from "../FilteredVideos/FilteredVideos";
import SubCategoriesContainer from "../SubCategoriesContainer/SubCategoriesContainer";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { isEqual } from "lodash";
import UserInfoModal from "../UserInfoModal/UserInfoModal";
import { useSelector } from "react-redux";
import FavouritesCard from "../FavouritesCard/FavouritesCard";
import LogsCard from "../LogsCard/LogsCard";
import UnfinishedVideos from "../UnfinishedVideos/UnfinishedVideos";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";

const CategoriesContainer = ({
  disabled,
  setDisabled,
  handleSave,
  loading,
}) => {
  const {
    primaryArray,
    setPrimaryArray,
    unsavedPrimaryArray,
    filterInput,
    setFilterInput,
    allVideos,
    activePrimaryCat,
    subCatVideos,
  } = useContext(VideoTutorialContext);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [filteredVideos, setFilteredVideos] = useState();
  const [draggableCategories, setDraggableCategories] = useState([]);
  const [draggableVideos, setDraggableVideos] = useState(subCatVideos);
  const [selectUserModal, setSelectUserModal] = useState(false);

  const handleInputChange = (e) => {
    setFilterInput(e.target.value);
    setFilteredVideos(
      allVideos.filter((video) =>
        video.videoName.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const dragEndHandler = (result) => {
    if (!result.destination) return;
    const items = Array.from(primaryArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setPrimaryArray(() => [...items]);
    setDisabled(false);
  };

  return (
    <div
      className={`categoriesContainer-card ${
        isDarkMode && "categoriesContainer-cardDark"
      }`}
    >
      <div className="search-container">
        <InputComponent
          placeholder="Search tutorial..."
          allowClear
          size="large"
          value={filterInput}
          onChange={(e) => handleInputChange(e)}
          className="customSearchNew"
          prefix={<SearchOutlined />}
        />
        {filterInput.length > 2 && <FilteredVideos videos={filteredVideos} />}
      </div>
      <div className="allCards">
        <NewCard />
        <UnfinishedVideos />
        <FavouritesCard />
        {!activePrimaryCat ? (
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided?.droppableProps}
                  ref={provided?.innerRef}
                  className="categories"
                >
                  {primaryArray.map((item, i) => {
                    return (
                      <Draggable
                        draggableId={`draggable-${i}`}
                        index={i}
                        key={i}
                      >
                        {(provided) => (
                          <div
                            {...provided?.draggableProps}
                            {...provided?.dragHandleProps}
                            ref={provided?.innerRef}
                            className="categoriesCards-container"
                          >
                            <SingleCard key={item.id} item={item} />
                            {activePrimaryCat === item?.id && (
                              <SubCategoriesContainer
                                setDraggableVideos={setDraggableCategories}
                                draggableVideos={draggableCategories}
                                setDisabled={setDisabled}
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className="categories">
            {primaryArray.map((item, index) => {
              return (
                <div className="categoriesCards-container" key={index}>
                  <SingleCard key={item.id} item={item} />
                  {activePrimaryCat === item?.id && (
                    <SubCategoriesContainer
                      setDisabled={setDisabled}
                      setDraggableVideos={setDraggableVideos}
                      draggableVideos={draggableVideos}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {userConfiguration.groupName === "Admin" && (
        <LogsCard loading={loading} />
      )}
      <div className="buttons">
        {userConfiguration.groupName === "Admin" && (
          <MondayButton
            className={"mondayButtonBlue"}
            Icon={<HistoryOutlined />}
            onClick={() => setSelectUserModal(true)}
          >
            User Data
          </MondayButton>
        )}
        <MondayButton
          className={disabled ? "mondayButtonGray" : "mondayButtonGreen"}
          Icon={<TickIcon />}
          onClick={handleSave}
          disabled={isEqual(primaryArray, unsavedPrimaryArray)}
        >
          Save Changes
        </MondayButton>
        {selectUserModal && (
          <UserInfoModal {...{ selectUserModal, setSelectUserModal }} />
        )}
      </div>
    </div>
  );
};

export default CategoriesContainer;
