import moment from "moment";
import { message } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { InfoCircleFilled, WarningFilled } from "@ant-design/icons";

import { DocumentationModal } from "../../..";
import { useQuery } from "../../../../../../../../hooks";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { DropDownArrow } from "../../../../../../Fleet/components/InputComponent/assets";

import "./DocumentationCard.scss";

const DocumentationCard = ({
  original = false,
  recordFolderId,
  renewal = false,
  revised = false,
  categoryConfiguration,
  setDocumentation,
  viewTitle,
  isEditable,
  docConfiguration,
  documentation,
  record,
  allDocumentation = [],
  isDragging,
  ...rest
}) => {
  let query = useQuery();
  const navigate = useNavigate();
  let path = window.location.pathname;
  let queryDocId = query.get("docId");

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { docType, docStatus, googleDriveUploads = [] } = rest;

  const [showChildren, setShowChildren] = useState(false);
  const [editingDoc, setEditingDoc] = useState(rest);

  const filesUploaded = googleDriveUploads.length;

  const { DOBWorkName, docId, dobSource, recordName } = rest;
  const [documentationModalVisible, setDocumentationModalVisible] =
    useState(false);

  useEffect(() => {
    if (
      !!queryDocId &&
      (queryDocId === docId ||
        (rest?.docChildren || [])?.some((doc) => doc?.docId === queryDocId))
    ) {
      openDocumentation(queryDocId);
    }
  }, [queryDocId]);

  // const { docStatuses = [] } =
  //   categoryConfiguration?.documentationsAvailable?.find?.(
  //     ({ docName }) => docName === docType
  //   ) || {};

  const docStatuses = useMemo(() => {
    return docConfiguration
      ?.find(({ categoryName }) => categoryName === rest?.categoryName)
      ?.documentationsAvailable?.find(({ docName }) => docName === docType)
      ?.docStatuses;
  }, [docConfiguration, rest?.categoryName, docType]);

  const docExpDate = useMemo(() => {
    const expFieldDate = rest.docObject.find(
      (el) => (el?.label || "")?.toLowerCase() === "expiration date"
    )?.value;
    if (rest?.expirationDate) {
      return dayjsNY(rest?.expirationDate);
    } else if (expFieldDate) {
      return dayjsNY(expFieldDate);
    } else {
      return null;
    }
  }, [rest]);

  const { expirationDate: configExpirationDate } =
    categoryConfiguration?.documentationsAvailable?.find(
      ({ docName }) => docName === docType
    ) || {};

  const { statusColor: backgroundColor } =
    docStatuses?.find?.(({ statusName }) => statusName === docStatus) || {};

  const TEXT = !!filesUploaded
    ? `${filesUploaded} Files Uploaded`
    : "Missing Files";

  useEffect(() => {
    const doc = document.getElementsByClassName("renewalStamp");

    const parent = document.getElementsByClassName("documentationViewCard")[0];
    if (doc.length > 0) {
      for (let i = 0; i < doc.length; i++) {
        if (doc[i].clientWidth > parent.clientWidth)
          doc[i].style.width = `${parent.clientWidth}px`;
      }
    }
  }, []);

  function isPartOfTeam(team) {
    const userIdentityId = userConfiguration?.identityId;

    return team
      ?.flatMap((item) => item?.members)
      ?.some((member) => member?.identityId === userIdentityId);
  }

  function checkAccess() {
    let toReturn = false;
    let isAdmin = userConfiguration.groupName.toLowerCase().includes("admin");
    if (isAdmin) {
      toReturn = true;
    } else {
      let thisDoc = documentation?.find((doc) => doc?.docId === docId);
      const isInTeam =
        Array.isArray(thisDoc?.teamsConfiguration) &&
        thisDoc?.teamsConfiguration?.length > 0
          ? isPartOfTeam(thisDoc?.teamsConfiguration)
          : false;
      const createdBy =
        typeof thisDoc?.createdBy === "string"
          ? thisDoc?.createdBy === userConfiguration?.nameOfUser
          : thisDoc?.createdBy?.nameOfUser === userConfiguration?.nameOfUser;
      toReturn = isInTeam || createdBy;
    }
    return toReturn;
  }

  function openDocumentation(id = docId) {
    if (isEditable && checkAccess()) {
      const editDocIndex = (documentation || []).findIndex(
        (el) => el?.docId === id
      );
      setEditingDoc(documentation[editDocIndex]);

      setTimeout(() => {
        setDocumentationModalVisible(true);
      }, 1);
      navigate({
        pathname: path,
        search: `?tab=Documentation&docId=${id}`,
      });
    } else {
      if (!isEditable) {
        message.error({
          content: "This document is not editable",
          key: "docAccess",
        });
      }
      if (!checkAccess()) {
        message.error({
          content: "You do not have access to this document",
          key: "docAccess",
        });
      }
      // setDocumentationModalVisible(true);
      // navigate({
      //   pathname: path,
      //   search: `?tab=Documentation&docId=${docId}`,
      // });
    }
  }

  function showChildrenToggle() {
    if (!rest?.docChildren?.length) {
      return;
    }

    const childrenListContainer = document.getElementById(
      `children-list-container-${rest?.docId}`
    );
    const listHeight = (rest?.docChildren?.length || 0) * 65;
    const maxHeight = 200;

    const animation = [
      {
        height: listHeight > maxHeight ? maxHeight : listHeight,
      },
      {
        height: 0,
      },
    ];

    const timing = {
      duration: 300,
      transition: "ease",
      rangeStart: "0%",
      rangeEnd: "100%",
    };

    if (showChildren) {
      childrenListContainer?.animate?.(animation, timing);
      setTimeout(() => {
        setShowChildren(!showChildren);
      }, 280);
    } else {
      setShowChildren(!showChildren);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      key={rest?.docId}
      isDragging={isDragging}
    >
      <div
        style={
          isEditable && checkAccess()
            ? {
                borderRadius: rest?.docChildren?.length
                  ? "10px 10px 0px 0px"
                  : "10px",
              }
            : {
                cursor: "not-allowed",
                borderRadius: rest?.docChildren?.length
                  ? "10px 10px 0px 0px"
                  : "10px",
              }
        }
        className={`documentationViewCard ${
          isDarkMode ? "documentationViewCardDark" : ""
        }`}
        onClick={() => openDocumentation(docId)}
      >
        <span className="docTitle">
          <div className="docType">{docType}</div>
          <div
            // className="docCreationTime"
            style={{
              fontSize: 12,
              fontWeight: 400,
              color: getDayWarning(rest?.categoryName, docExpDate, isDarkMode),
            }}
          >
            {!!docExpDate ? docExpDate.format("MM/DD/YYYY") : ""}
          </div>
          {(!!renewal || !!original || !!DOBWorkName) && (
            <div
              style={
                !!renewal
                  ? { color: "#6BBED1", fontSize: "12px" }
                  : !!revised
                  ? { color: "#6bd1bd", fontSize: "12px" }
                  : !!DOBWorkName
                  ? { color: "#1C0C5B", fontSize: "12px" }
                  : { fontSize: "12px" }
              }
            >
              {!!renewal
                ? "Renewal"
                : !!revised
                ? "Revised"
                : !!DOBWorkName
                ? !!dobSource
                  ? `From DOB - ${dobSource?.dobCategory} ${
                      typeof dobSource?.dobAddress !== "object" &&
                      dobSource?.dobAddress === recordName
                        ? ` - ${dobSource?.dobWorkType}}`
                        : ` - ${dobSource?.dobWorkType} (${dobSource?.dobAddress})`
                    }`
                  : `From DOB - ${DOBWorkName}`
                : "Original"}
            </div>
          )}
        </span>

        <div className="leftSection">
          <div
            className="docStatusLabel"
            style={{
              backgroundColor: backgroundColor || "rgb(236, 222, 124)",
            }}
          >
            <span style={{ color: "#fff" }}>{docStatus}</span>
          </div>
          <span style={{ display: "flex", gap: 5 }}>
            <span
              style={{
                color: !!filesUploaded
                  ? !isDarkMode
                    ? "#323338"
                    : "#fff"
                  : "#FE4C4A",
                fontWeight: "400",
              }}
            >
              {TEXT}
            </span>
            {!!filesUploaded ? (
              <InfoCircleFilled style={{ color: "#1264A3", fontSize: 16 }} />
            ) : (
              <WarningFilled className="warningIcon" />
            )}
          </span>
        </div>
      </div>
      <div
        className={`children-drop-down ${
          isDarkMode ? "children-drop-down-dark" : ""
        }`}
      >
        {showChildren ? (
          <div
            className="children-list-container"
            id={`children-list-container-${rest?.docId}`}
            style={{ maxHeight: rest?.docChildren?.length * 65 }}
          >
            {(rest?.docChildren || []).map((doc) => {
              const statusBg = docStatuses?.find?.(
                ({ statusName }) => statusName === doc?.docStatus
              )?.statusColor;
              return (
                <div
                  className="child-card-container"
                  onClick={() => openDocumentation(doc?.docId)}
                  key={doc?.docId}
                >
                  <div>
                    <div
                      className={`child-doc-type ${
                        !!doc?.renewalId
                          ? "renewal"
                          : !!doc?.revisedId
                          ? "revised"
                          : ""
                      }`}
                    >
                      {doc?.renewalId
                        ? "Renewal"
                        : !!doc?.revisedId
                        ? "Revised"
                        : "Original"}{" "}
                      <div
                        className="docCreationTime"
                        style={{
                          fontWeight: 400,
                          color: getDayWarning(
                            doc?.categoryName,
                            dayjsNY(doc?.expirationDate),
                            isDarkMode
                          ),
                        }}
                      >
                        {doc?.expirationDate &&
                        dayjsNY(doc?.expirationDate).format("MM/DD/YYYY") !==
                          "Invalid Date"
                          ? dayjsNY(doc?.expirationDate).format("MM/DD/YYYY")
                          : ""}
                      </div>
                    </div>

                    <div
                      className={`files-info ${
                        !!doc?.googleDriveUploads?.length ? "" : "warningText"
                      }`}
                    >
                      <b>{doc?.googleDriveUploads?.length}</b> Files Uploaded
                      {!!doc?.googleDriveUploads?.length ? (
                        <InfoCircleFilled
                          style={{ color: "#1264A3", fontSize: 16 }}
                        />
                      ) : (
                        <WarningFilled className="warningIcon" />
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      className="docStatusLabel"
                      style={{
                        backgroundColor: statusBg || "#ecde7c",
                      }}
                    >
                      <span style={{ color: "#fff" }}>{doc?.docStatus}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        {rest?.docChildren?.length ? (
          <div className="drop-down-action" onClick={showChildrenToggle}>
            {/* Show Children ({rest?.docChildren?.length || 0}) */}
            {!showChildren && !!rest?.docChildren?.length ? (
              <div>Open ({rest?.docChildren?.length || 0})</div>
            ) : null}
            <DropDownArrow
              style={showChildren ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
        ) : null}
      </div>
      {!!documentationModalVisible && (
        <DocumentationModal
          {...{
            visible: documentationModalVisible,
            setVisible: setDocumentationModalVisible,
            viewTitle,
            recordFolderId,
            googleDriveUploads,
            setDocumentation,
            docStatuses,
            categoryConfiguration,
            configExpirationDate,
            isOpenedByButton: false,
            docConfiguration,
            documentation,
            record,
            allDocumentation,
            ...editingDoc,
          }}
        />
      )}
    </div>
  );
};

export default DocumentationCard;

const getDayWarning = (category, createdAt, isDarkMode) => {
  if (category === "Fleet" || category === "Drivers") {
    return getColorForDate(createdAt);
  } else {
    return isDarkMode ? "#fff" : "#323338";
  }
};

function getColorForDate(inputDate) {
  if (!inputDate || !inputDate.isValid()) {
    return "red";
  }
  const today = dayjsNY();

  if (inputDate.isBefore(today)) {
    return "red";
  } else if (inputDate.isBefore(today.add(30, "day"))) {
    return "orange";
  } else {
    return "green";
  }
}
