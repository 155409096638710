import { message, Tooltip } from "antd";
import { useContext } from "react";
import { RedoOutlined, UndoOutlined } from "@ant-design/icons";

import DegModalContext from "../../DegModalContext";
import { MondayButton } from "../../../../../../../../commonComponents";
import { useEntriesApi } from "../../../../../../../PayrollLive/utils";

function PayrollUndoRedo() {
  const {
    setRowData,
    degGridApi,
    currentStep,
    entriesActions,
    addEntryAction,
    actionsRegister,
    addActionToRegister,
  } = useContext(DegModalContext);

  const { actions, index } = actionsRegister;

  const { loading, postEntries, updateEntries, removeEntries } =
    useEntriesApi();

  function redoUndoNew(action, actionToUpdate) {
    message.loading({
      duration: 0,
      key: "new",
      content:
        action === "redo" ? "Redoing changes..." : "Reverting changes...",
    });
    if (action === "redo") {
      postEntries({
        entries: actionToUpdate.action,
        onSuccessCallback: () => {
          setRowData((prev) => actionToUpdate.action.concat(prev));
          message.success({
            duration: 3,
            key: "new",
            content: "Changes updated successfully!",
          });
        },
        onErrorCallback: (err) => {
          console.log("Error redoing new entries: ", err);
          message.error({
            duration: 3,
            key: "new",
            content: "There was a problem redoing changes",
          });
        },
      });
    }

    if (action === "undo") {
      removeEntries({
        entries: actionToUpdate.action,
        onSuccessCallback: () => {
          setRowData((prev) =>
            prev.filter((entry) => {
              const elIndex = actionToUpdate?.action?.findIndex(
                (el) => el?.entryId === entry?.entryId
              );
              return elIndex > -1 ? false : true;
            })
          );
          message.success({
            duration: 3,
            key: "new",
            content: "Changes updated successfully!",
          });
        },
        onErrorCallback: (err) => {
          console.log("Error undoing new entries: ", err);
          message.error({
            duration: 3,
            key: "new",
            content: "There was a problem undoing changes",
          });
        },
      });
    }
  }

  function redoUndoEdit(action, actionToUpdate) {
    message.loading({
      duration: 0,
      key: "edit",
      content:
        action === "redo" ? "Redoing changes..." : "Reverting changes...",
    });
    if (action === "redo") {
      const editData = actionToUpdate.action.map(({ curr }) => curr);
      updateEntries({
        entries: editData,
        onSuccessCallback: () => {
          setRowData((prev) =>
            prev.map((entry) => {
              let elIndex = editData.findIndex(
                (el) => el?.entryId === entry?.entryId
              );
              return elIndex > -1 ? editData[elIndex] : entry;
            })
          );
          message.success({
            duration: 3,
            key: "edit",
            content: "Changes updated successfully!",
          });
        },
        onErrorCallback: (err) => {
          console.log("Error redoing edited entries: ", err);
          message.error({
            duration: 3,
            key: "edit",
            content: "There was a problem redoing changes",
          });
        },
      });
    }

    if (action === "undo") {
      const editData = actionToUpdate.action.map(({ prev }) => prev);
      updateEntries({
        entries: editData,
        onSuccessCallback: () => {
          setRowData((prev) =>
            prev.map((entry) => {
              let elIndex = editData.findIndex(
                (el) => el?.entryId === entry?.entryId
              );
              return elIndex > -1 ? editData[elIndex] : entry;
            })
          );
          message.success({
            duration: 3,
            key: "edit",
            content: "Changes updated successfully!",
          });
        },
        onErrorCallback: (err) => {
          console.log("Error undoing edited entries: ", err);
          message.error({
            duration: 3,
            key: "edit",
            content: "There was a problem undoing changes",
          });
        },
      });
    }
  }

  function redoUndoRemove(action, actionToUpdate) {
    message.loading({
      duration: 0,
      key: "remove",
      content:
        action === "redo" ? "Redoing changes..." : "Reverting changes...",
    });
    const { removedEntries } = actionToUpdate.action;

    if (action === "redo") {
      removeEntries({
        entries: removedEntries,
        onSuccessCallback: () => {
          setRowData((prev) =>
            prev.filter((entry) => {
              return (
                removedEntries.findIndex(
                  (el) => el.entryId === entry.entryId
                ) === -1
              );
            })
          );
          message.success({
            duration: 3,
            key: "remove",
            content: "Changes updated successfully!",
          });
        },
        onErrorCallback: (err) => {
          console.log("Error redo removed entries: ", err);
          message.error({
            duration: 3,
            key: "remove",
            content: "There was a problem redoing changes",
          });
        },
      });
    }

    if (action === "undo") {
      postEntries({
        entries: removedEntries,
        onSuccessCallback: () => {
          setRowData((prev) => removedEntries.concat(prev));
          message.success({
            duration: 3,
            key: "remove",
            content: "Changes updated successfully!",
          });
        },
        onErrorCallback: (err) => {
          console.log("Error undo removed entries: ", err);
          message.error({
            duration: 3,
            key: "remove",
            content: "There was a problem undoing changes",
          });
        },
      });
    }
  }

  function redoUndoMassChanges(action, actionToUpdate) {
    message.loading({
      duration: 0,
      key: "massEntry",
      content:
        action === "redo" ? "Redoing changes..." : "Reverting changes...",
    });
    const { newEntries, editedEntries, removedEntries } = actionToUpdate.action;

    let dataToUpdate = Array.isArray(editedEntries)
      ? editedEntries.map((el) => el?.curr)
      : [];
    let prevUpdatedData = Array.isArray(editedEntries)
      ? editedEntries.map((el) => el?.prev)
      : [];

    if (action === "redo") {
      Promise.allSettled(
        [
          newEntries?.length && postEntries({ entries: newEntries }),
          dataToUpdate?.length && updateEntries({ entries: dataToUpdate }),
          removedEntries?.length && removeEntries({ entries: removedEntries }),
        ].filter(Boolean)
      )
        .then(() => {
          setRowData((prev) => {
            return (newEntries || []).concat(
              prev.flatMap((entry) => {
                let entryToUpdate = dataToUpdate.findIndex(
                  (el) => el.entryId === entry.entryId
                );
                let entryToRemove = removedEntries.findIndex(
                  (el) => el.entryId === entry.entryId
                );

                if (entryToRemove > -1) {
                  return [];
                }
                if (entryToUpdate > -1) {
                  return dataToUpdate[entryToUpdate];
                }
                return entry;
              })
            );
          });
          message.success({
            duration: 3,
            key: "massEntry",
            content: "Changes updated successfully!",
          });
        })
        .catch((err) => {
          console.log("err: ", err);
          message.error({
            duration: 3,
            key: "massEntry",
            content: "There was a problem redoing changes",
          });
        });
    }

    if (action === "undo") {
      Promise.allSettled([
        newEntries?.length && removeEntries({ entries: newEntries }),
        removedEntries?.length && postEntries({ entries: removedEntries }),
        prevUpdatedData?.length && updateEntries({ entries: prevUpdatedData }),
      ])
        .then(() => {
          setRowData((prev) => {
            return (removedEntries || []).concat(
              prev.flatMap((entry) => {
                let entryToUpdate = prevUpdatedData.findIndex(
                  (el) => el.entryId === entry.entryId
                );
                let entryToRemove = newEntries.findIndex(
                  (el) => el.entryId === entry.entryId
                );

                if (entryToRemove > -1) {
                  return [];
                }
                if (entryToUpdate > -1) {
                  return prevUpdatedData[entryToUpdate];
                }
                return entry;
              })
            );
          });
          message.success({
            duration: 3,
            key: "massEntry",
            content: "Changes updated successfully!",
          });
        })
        .catch((err) => {
          console.log("err: ", err);
          message.error({
            duration: 3,
            key: "massEntry",
            content: "There was a problem undoing changes",
          });
        });
    }
  }

  const actionFunctions = {
    ["new"]: redoUndoNew,
    ["edit"]: redoUndoEdit,
    ["remove"]: redoUndoRemove,
    ["massChanges"]: redoUndoMassChanges,
  };

  function onRedo() {
    const actionToUpdate = actions?.[index + 1];
    if (actionToUpdate) {
      actionFunctions[actionToUpdate.type]("redo", actionToUpdate);
      addActionToRegister({ type: "redo" });
    }
  }

  function onUndo() {
    const actionToUpdate = actions[index];
    if (actionToUpdate) {
      actionFunctions[actionToUpdate.type]("undo", actionToUpdate);
      addActionToRegister({ type: "undo" });
    }
  }

  return (
    <div
      style={{
        gap: 20,
        display: "flex",
        width: "fit-content",
        alignItems: "center",
      }}
    >
      <Tooltip title="Undo">
        <MondayButton
          onClick={onUndo}
          className="mondayButtonBlue"
          disabled={!actions?.length || index === 0 || loading}
          Icon={<UndoOutlined style={{ marginInlineStart: 0 }} />}
        >
          {""}
        </MondayButton>
      </Tooltip>
      <Tooltip title="Redo">
        <MondayButton
          onClick={onRedo}
          className="mondayButtonBlue"
          Icon={<RedoOutlined style={{ marginInlineStart: 0 }} />}
          disabled={
            !actions?.length || actions?.length === index + 1 || loading
          }
        >
          {""}
        </MondayButton>
      </Tooltip>
    </div>
  );
}

export default PayrollUndoRedo;
