// import { API } from "aws-amplify";
import PropTypes from "prop-types";
// import { Tooltip, message } from "antd";
import React, { useState, useEffect, useContext } from "react";

import {
  columnDefs,
  gridFilters,
  chartingOptions,
  excelColumnsConfig,
} from "./AgGridData";
import { useQuery } from "../../../../../hooks";
import PayrollLayout from "../../Layout/PayrollLayout";
// import { LoadableComp } from "../../../../SidebarPages/XComponents";
import PayrollContext from "../../PayrollContext";

function JobsiteTab({ accessRight }) {
  const queryParam = useQuery();
  const { jobsites: rowData } = useContext(PayrollContext);
  const selectedJobsite = queryParam.get("selectedJobsite");

  // const [rowData, setRowData] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [gridApi, setGridApi] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const paginationPageSize = Math.floor((window.innerHeight - 357) / 38);

  function onEditClick(data) {
    setRowToEdit(data);
    setModalVisible(true);
  }

  useEffect(() => {
    // if (!rowData?.length) {
    //   API.get("jobsites", "/jobsites")
    //     .then((res) => {
    //       setRowData(res);
    //       setLoading(false);
    //     })
    //     .catch((err) => {
    //       console.log("Error", err);
    // setLoading(false);
    //       message.error("There was a problem getting the data!");
    //     });
    // }
    if (selectedJobsite && rowData?.length) {
      onEditClick(
        rowData.find(({ jobsiteId }) => jobsiteId === selectedJobsite)
      );
    }
  }, [selectedJobsite, rowData?.length]);

  return (
    <>
      {/* <LoadableComp loading={loading} componentTestId={"jobsites-tab-loading"}>
        <></>
      </LoadableComp> */}
      <PayrollLayout
        {...{
          rowData,
          rowToEdit,
          gridFilters,
          modalVisible,
          setRowToEdit,
          chartingOptions,
          setModalVisible,
          title: "Jobsites",
          excelColumnsConfig,
          paginationPageSize,
          dataIdKey: "jobsiteId",
          hasNew: accessRight?.write,
          suppressDoubleClickEdit: true,
          columnDefs: columnDefs({
            onEditClick,
            writeAccess: accessRight?.write,
          }),
        }}
      />
    </>
  );
}

JobsiteTab.propTypes = {
  accessRight: {
    write: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.array,
  },
};

export default JobsiteTab;
