import { Dropdown, Select, Tooltip } from "antd";
import { AlignLeftOutlined, SearchOutlined } from "@ant-design/icons";

import {
  EditIcon,
  TasksIconNew,
  ClientsWhite,
  ChainScopeIcon,
  EstimationsWhite,
  SubcontractorsWhite,
} from "../../../../../../../../../icons";
import {
  PdfWhiteIcon,
  PieChartWhite,
  IncidentsIcon,
  ExcelIconWhite,
  LocationRadiusIcon,
} from "../../../../../../../../../assets";
import PayrollUndoRedo from "./PayrollUndoRedo";
import {
  FilterIcon,
  DownloadIcon,
} from "../../../../../../../../SidebarPages/BasePage/src";
import {
  EyeIcon,
  CopyIconWhite,
} from "../../../../../../../../SidebarPages/DynamicView/src";
import {
  XIcon,
  TrashIcon,
} from "../../../../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../../../../commonComponents";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import { AddIcon } from "../../../../../../../../Header/components/GoogleEvents/assets";
import { DropDownArrow } from "../../../../../../../../SidebarPages/Fleet/components/InputComponent/assets";

function ControlHeader({
  loading,
  addData,
  onSearch,
  addShift,
  degStatus,
  isLastStep,
  columnDefs,
  isDarkMode,
  searchInput,
  gridActions,
  detailsOpen,
  serqInputRef,
  editModalData,
  onGeneratePDF,
  duplicateRows,
  setFilterOpen,
  setDetailsOpen,
  showHasFilters,
  setGridActions,
  actionsDisabled,
  setCategoryCost,
  openDetailsView,
  openGridActions,
  setFieldSelected,
  setEditModalData,
  closeGridActions,
  openWarningsModal,
  exportGridToExcel,
  onFilterTextChange,
  openSelectionModal,
  setTeamsCostVisible,
  setMassEntryVisible,
  setUploadModalVisible,
  setCostDispersionModal,
  setJobsiteMatchDetails,
  setRadiusToleranceModal,
  setDeleteWarningVisible,
}) {
  return (
    <div className="deg-actions">
      <div className="headerFiler">
        <MondayButton
          Icon={<FilterIcon />}
          className="mondayButtonBlue"
          data-testid="entries-filter-btn"
          onClick={() => setFilterOpen((prev) => !prev)}
          tooltipKey={"Entries Table Filter"}
        >
          Open Filters
        </MondayButton>
        <InputComponent
          {...{
            onSearch,
            value: searchInput,
            type: "autocopmlete",
            inputRef: serqInputRef,
            formItemName: "inputSearch",
            prefix: <SearchOutlined />,
            dataTestid: "grid-search-input",
            placeholder: "Filter by name...",
            onChange: _.debounce(
              (e) => onFilterTextChange(e.target.value),
              300
            ),
            addonAfter: (
              <Select
                allowClear={true}
                className="select-comp"
                popupMatchSelectWidth={false}
                suffixIcon={<DropDownArrow />}
                placeholder="Select field to filter"
                onClear={() => setFieldSelected(null)}
                popupClassName={isDarkMode && `darkDropDown`}
                onSelect={(e) => {
                  serqInputRef?.current?.focus?.();
                  setFieldSelected(e);
                }}
                options={columnDefs({
                  isLastStep,
                  setEditModalData,
                  editModalData,
                }).map(({ headerName, field }) => ({
                  value: field,
                  label: headerName,
                }))}
              />
            ),
          }}
        />
        <MondayButton
          {...{
            Icon: <IncidentsIcon height={20} width={20} />,
            onClick: openWarningsModal,
            className: "mondayButtonRed",
            ["data-testid"]: "open-warnings-btn",
          }}
        >
          Open Warnings
        </MondayButton>
        <div className="control-buttons">
          {detailsOpen ? (
            <div className="grid-actions">
              <Tooltip title="Close Analytics">
                <MondayButton
                  className="cancelActions"
                  data-testid="close-analytics-btn"
                  onClick={() => setDetailsOpen(false)}
                  Icon={<XIcon fill="#fff" width={15} height={15} />}
                >
                  {""}
                </MondayButton>
              </Tooltip>
              <div className="action-buttons">
                {/* <Tooltip title="Shift Details" placement="right">
                  <MondayButton
                    Icon={<EyeIcon height={14} width={14} fill="white" />}
                    data-testid="shift-details-btn"
                    onClick={() => {
                      setDetailsOpen(false);
                      openDetailsView();
                    }}
                    className="mondayButtonBlue"
                  >
                    {""}
                  </MondayButton>
                </Tooltip> */}
                <Tooltip title="Cost Distribution" placement="right">
                  <MondayButton
                    Icon={
                      <PieChartWhite height={14} width={14} fill={"white"} />
                    }
                    className="mondayButtonBlue"
                    data-testid="cost-distribution-btn"
                    onClick={() => {
                      setDetailsOpen(false);
                      setCostDispersionModal(true);
                    }}
                  >
                    {""}
                  </MondayButton>
                </Tooltip>
                <Tooltip title="Category Cost" placement="right">
                  <MondayButton
                    Icon={
                      <SubcontractorsWhite
                        height={14}
                        width={14}
                        fill="white"
                      />
                    }
                    className="mondayButtonBlue"
                    data-testid="category-cost-btn"
                    onClick={() => {
                      setDetailsOpen(false);
                      setCategoryCost(true);
                    }}
                  >
                    {""}
                  </MondayButton>
                </Tooltip>
                <Tooltip title="Teams Cost" placement="right">
                  <MondayButton
                    Icon={<ClientsWhite height={14} width={14} fill="white" />}
                    className="mondayButtonBlue"
                    data-testid="team-cost-btn"
                    onClick={() => {
                      setDetailsOpen(false);
                      setTeamsCostVisible(true);
                    }}
                  >
                    {""}
                  </MondayButton>
                </Tooltip>
                <Tooltip title="Jobsite Match" placement="right">
                  <MondayButton
                    Icon={
                      <ChainScopeIcon height={14} width={14} fill="white" />
                    }
                    data-testid="jobsite-match-btn"
                    className="mondayButtonBlue"
                    onClick={() => {
                      setDetailsOpen(false);
                      setJobsiteMatchDetails(true);
                    }}
                  >
                    {""}
                  </MondayButton>
                </Tooltip>
              </div>
            </div>
          ) : (
            <div className="actions-trigger">
              <Tooltip title="Analytics">
                <MondayButton
                  className="mondayButtonBlue"
                  data-testid="analytics-toggle-btn"
                  Icon={
                    <EstimationsWhite width={14} height={14} fill="white" />
                  }
                  onClick={() => {
                    setDetailsOpen(true);
                  }}
                >
                  {""}
                </MondayButton>
              </Tooltip>
            </div>
          )}
          <PayrollUndoRedo />
        </div>
      </div>

      <div className="control-buttons">
        <Tooltip title="Export to PDF">
          <MondayButton
            onClick={onGeneratePDF}
            className="mondayButtonBlue"
            Icon={<PdfWhiteIcon width={15} height={15} />}
          >
            {""}
          </MondayButton>
        </Tooltip>
        <Tooltip title="Export to Excel">
          <MondayButton
            className="mondayButtonBlue"
            Icon={<ExcelIconWhite width={15} height={15} />}
            onClick={exportGridToExcel}
          >
            {""}
          </MondayButton>
        </Tooltip>
        <Tooltip title="Upload More Entries">
          <MondayButton
            Icon={<DownloadIcon />}
            disabled={degStatus === "Completed"}
            data-testid="upload-entries-btn"
            className="mondayButtonBlue"
            onClick={() => setUploadModalVisible(true)}
          >
            Upload
          </MondayButton>
        </Tooltip>
        {gridActions ? (
          <div className="grid-actions">
            <Tooltip title="Close Actions">
              <MondayButton
                className="cancelActions"
                data-testid="close-entries-actions-btn"
                Icon={<XIcon fill="#fff" width={15} height={15} />}
                onClick={closeGridActions}
              >
                {""}
              </MondayButton>
            </Tooltip>
            <div className="action-buttons">
              <Tooltip title="Add Row" placement="right">
                <MondayButton
                  disabled={showHasFilters() || loading}
                  data-testid="add-grid-row-btn"
                  Icon={<AddIcon />}
                  onClick={addData}
                >
                  {""}
                </MondayButton>
              </Tooltip>
              <Tooltip title="Mass Entry" placement="right">
                <MondayButton
                  hasIcon={false}
                  className="mondayButtonBlue"
                  data-testid="mass-entry-btn"
                  onClick={() => {
                    setGridActions(false);
                    setMassEntryVisible(true);
                    closeGridActions();
                  }}
                  disabled={actionsDisabled || loading}
                >
                  <AlignLeftOutlined />
                </MondayButton>
              </Tooltip>
              <Tooltip title="Remove Rows" placement="right">
                <MondayButton
                  Icon={<TrashIcon fill="#fff" />}
                  className="mondayButtonRed"
                  data-testid="remove-entries-btn"
                  onClick={() => setDeleteWarningVisible(true)}
                  disabled={actionsDisabled || loading}
                >
                  {""}
                </MondayButton>
              </Tooltip>
              <Tooltip title="Duplicate Rows" placement="right">
                <MondayButton
                  Icon={<CopyIconWhite fill="#fff" />}
                  data-testid="duplicate-entries-btn"
                  className="mondayButtonBlue"
                  disabled={actionsDisabled || loading}
                  onClick={duplicateRows}
                >
                  {""}
                </MondayButton>
              </Tooltip>
            </div>
          </div>
        ) : (
          <div className="actions-trigger">
            <Tooltip title="Edit Rows">
              <MondayButton
                className="mondayButtonBlue"
                data-testid="entries-actions-toggle"
                Icon={<EditIcon width={14} height={14} fill="#fff" />}
                onClick={openGridActions}
                disabled={degStatus === "Completed"}
              >
                {""}
              </MondayButton>
            </Tooltip>
          </div>
        )}
        <Tooltip title="Change radius tolerance for jobsite match">
          <MondayButton
            className="mondayButtonBlue"
            disabled={degStatus === "Completed"}
            onClick={() => setRadiusToleranceModal(true)}
            Icon={<LocationRadiusIcon width={20} height={20} />}
          >
            {""}
          </MondayButton>
        </Tooltip>
        <MondayButton
          className="mondayButtonBlue"
          data-testid="entry-selection-btn"
          Icon={<TasksIconNew />}
          onClick={() => {
            setGridActions(false);
            openSelectionModal();
          }}
        >
          Entry Selection
        </MondayButton>
        <Dropdown
          placement="bottom"
          overlayStyle={{ width: 180 }}
          disabled={degStatus === "Completed"}
          overlayClassName={`layout-card-dropdown${
            isDarkMode ? " layout-dropdown-dark" : ""
          }`}
          menu={{
            items: [
              {
                label: "Add Employee Shift",
                key: "addEmployeeShift",
              },
              {
                label: "Add Team Shift",
                key: "addTeamShift",
              },
              {
                label: "Add Cash/1099 shifts",
                key: "addCashShift",
              },
            ],
            onClick: addShift,
          }}
        >
          <MondayButton
            Icon={<AddIcon />}
            data-testid="add-shift-dropdown"
            disabled={degStatus === "Completed"}
          >
            Add Shift
          </MondayButton>
        </Dropdown>
      </div>
    </div>
  );
}

export default ControlHeader;
