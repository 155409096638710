import { useMemo } from "react";
import "./Teams_Card.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AvatarImage } from "../../../../commonComponents";

//?CARD VIEW
function TeamsCard(props) {
  const navigate = useNavigate();
  const {
    params: { teamsConfiguration },
  } = props;
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { preferences } = useSelector((state) => state.preferences);

  const allUsers = userConfiguration?.allUsers?.Items;

  const uniqueUsers = () => {
    const teamSelectedValues = [];
    teamsConfiguration?.forEach((el) => {
      el?.members?.forEach((x) => {
        teamSelectedValues.push(x);
      });
    });
    let data = teamSelectedValues?.map((item) => {
      const foundUser = allUsers?.find(
        (user) => user?.identityId === item?.identityId
      );
      return { ...item, ...foundUser };
    });
    const uniqueData = data?.filter((item, index, self) => {
      return (
        index === self?.findIndex((t) => t?.identityId === item?.identityId)
      );
    });
    return uniqueData || [];
  };

  const mergeData = useMemo(() => {
    return uniqueUsers() || [];
  }, [teamsConfiguration, allUsers]);

  return (
    <div className="membersContent-container">
      {mergeData?.map((el, index) => {
        const handleRedirect = () => {
          navigate(
            `/settings/allUsers/${el?.departmentName}/${el?.groupName}/${el?.identityId}`
          );
        };
        return (
          <div className="content-membersContent" key={index}>
            <div className="member-content">
              {!!el?.googleDriveFileId ? (
                <div
                  className="ImageContainer"
                  // onClick={handleRedirect}
                >
                  <AvatarImage
                    {...{
                      nameOfUser: el?.nameOfUser,
                      googleDriveFileId: el?.googleDriveFileId,
                      size: 55,
                    }}
                  />
                </div>
              ) : (
                <div
                  className="InitialsContainer"
                  style={{
                    backgroundColor: el?.isSuspended
                      ? "#A9A9A9"
                      : el?.nameOfUser === userConfiguration.nameOfUser
                      ? preferences?.preferences?.profileColor || "#4895ef"
                      : "#F8F8FA",
                  }}
                  // onClick={handleRedirect}
                >
                  <span
                    className="initialsStyle"
                    style={{
                      color:
                        el?.nameOfUser === userConfiguration.nameOfUser
                          ? "#fff"
                          : "#323338",
                    }}
                  >
                    {el?.nameOfUser?.charAt(0)}
                  </span>
                </div>
              )}
              <div className="memberData">
                <div
                  className={`nameContainer ${
                    el?.isSuspended && "suspendedName"
                  }`}
                  // onClick={handleRedirect}
                >
                  {el?.nameOfUser}
                </div>
                <div
                  className={`groupName ${
                    el?.isSuspended && "suspendedGroupName"
                  }`}
                >
                  {el?.groupName}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default TeamsCard;
