import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showInfoMsg } from "../../../../../../utils";
import { Icons } from "../../NotificationData";
import { saveNotification } from "../../utils/save";
import NotificationItem from "../NotificationItem/NotificationItem";
import ActiveItemSkeleton from "../Skeleton/ActiveItemSkeleton";
import CheckboxHandlersActions from "../checkbox-handlers-actions/CheckboxHandlersActions";
import { Empty } from "antd";
import { updateNotifications } from "../../../../../../actions/notificationsActions";
import CommunicationTabView from "../communication-tab-view/CommunicationTabView";
import { useNotificationsDrawer } from "../../providers/NotificationsDrawerProvider";

function NotificationsLayout({
  paginatedNotifications,
  allNotifications,
  setOpen,
  selectedItems,
  setSelectedItems,
  tabTitle,
  activeTab,
  currentPage,
  searchValue,
  filteredEmails,
  setFilteredEmails,
}) {
  const { toggleDrawer } = useNotificationsDrawer();
  const [animatedClassName, setAnimatedClassName] = useState([]);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { notificationsLoading, emailsLoading } = useSelector(
    (state) => state.notifications
  );
  const [allSelected, setAllSelected] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectAllHandler = (title) => {
    let tmp = [];
    setAllSelected(true);

    allNotifications
      ?.filter((el) => {
        if (title === "Hidden") {
          if (el.hidden) {
            return true;
          }
        } else {
          if (!el.hidden) {
            return true;
          }
        }
      })
      .forEach((el) => {
        if (`${title}`.toLowerCase() === "i was mentioned") {
          if (el.action === "onCommentMention") {
            tmp.push(el.id);
          }
        } else if (`${title}`.toLowerCase() === "communication") {
        } else if (`${title}`.toLowerCase() === "assigned to me") {
          const topics = ["onTaskAssignation"];
          if (
            topics.includes(el.action) ||
            (el.action === "onCreation" && el.topic === "5")
          ) {
            tmp.push(el.id);
          }
        } else {
          tmp.push(el.id);
        }
      });
    setSelectedItems(tmp);
  };

  if (notificationsLoading) {
    return <ActiveItemSkeleton />;
  }

  if (emailsLoading && activeTab === "communication") {
    return <ActiveItemSkeleton />;
  }

  return (
    <div className="notifications-container">
      {selectedItems.length > 0 && activeTab !== "communication" && (
        <CheckboxHandlersActions
          dataType="notifications"
          data={allNotifications}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectAllHandler={selectAllHandler}
          tabTitle={tabTitle}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
        />
      )}

      <>
        {activeTab === "communication" ? (
          <CommunicationTabView
            currentPage={currentPage}
            searchValue={searchValue}
            filteredEmails={filteredEmails}
            setFilteredEmails={setFilteredEmails}
          />
        ) : (
          <div>
            <div>
              {paginatedNotifications?.length > 0 ? (
              paginatedNotifications?.map((notification, i) => {
                const {
                  action,
                  seen,
                  hidden,
                  topic,
                  category,
                  url,
                  id,
                  notificationBody: { notificationObject, cognitoUserId },
                } = notification;

                const Icon = Icons?.[category] || Icons?.default;
                const { actionData } = notificationObject;

                const onItemClick = async () => {
                  if (!!url && url !== "/null" && url !== "null") {
                    // setOpen(false);
                    toggleDrawer();

                    if (
                      (topic === "3" && action === "onEstNotesAddition") ||
                      topic === "10" ||
                      action === "onTaskCommentAddition" ||
                      topic === "5" ||
                      topic === "19" ||
                      topic === "11"
                    ) {
                      const { cognitos, ...rest } = actionData[1];
                      navigate(url, { state: rest });
                    } else if (topic === "110") {
                      let recordId = notification?.notificationBody?.recordId;
                      if (!recordId) {
                        return;
                      }
                      const path = window.location.pathname;
                      const queryParams = new URLSearchParams(
                        window.location.search
                      );
                      queryParams.set("sessionUserId", recordId);
                      const newUrl = `${path}?${queryParams.toString()}`;
                      navigate(newUrl);
                    } else {
                      navigate(url);
                    }
                  }

                  if (!seen) {
                    dispatch(
                      updateNotifications({
                        notifications: [id],
                        action: "see",
                      })
                    );

                    await saveNotification({
                      id,
                      body: {
                        cognitoUserId: {
                          ...cognitoUserId,
                          [authenticatedUser.sub]: {
                            ...cognitoUserId[authenticatedUser.sub],
                            hasBeenSeen: true,
                          },
                        },
                        notificationObject,
                      },
                    });
                  }
                };

                const moreActionsContent = (
                  <>
                    <span
                      onClick={() => {
                        dispatch(
                          updateNotifications({
                            notifications: [id],
                            action: !seen ? "mark as read" : "mark as unread",
                          })
                        );

                        saveNotification({
                          id,
                          body: {
                            cognitoUserId: {
                              ...cognitoUserId,
                              [authenticatedUser.sub]: {
                                ...cognitoUserId[authenticatedUser.sub],
                                hasBeenSeen: !seen,
                              },
                            },
                            notificationObject,
                          },
                        });
                        showInfoMsg({
                          content: `Notification has been marked as ${
                            !seen ? "Read" : "Unread"
                          }`,
                        });
                        setSelectedItems([]);
                      }}
                      className="popover-item"
                    >
                      {!seen ? "Mark as Read" : "Mark as Unread"}
                    </span>
                    <span
                      onClick={() => {
                        setAnimatedClassName([
                          hidden ? "moveLeft" : "moveRight",
                          id,
                        ]);
                        setTimeout(() => {
                          setAnimatedClassName([]);
                          dispatch(
                            updateNotifications({
                              notifications: [id],
                              action: "hide",
                            })
                          );
                          saveNotification({
                            id,
                            body: {
                              cognitoUserId: {
                                ...cognitoUserId,
                                [authenticatedUser.sub]: {
                                  ...cognitoUserId[authenticatedUser.sub],
                                  hasBeenHidden: !hidden,
                                },
                              },
                              notificationObject,
                            },
                          });
                          showInfoMsg({
                            content: `Notification has been moved to ${
                              hidden ? "All" : "Hidden"
                            } section`,
                          });
                        }, 500);
                      }}
                      className="popover-item"
                    >
                      {hidden ? "Unhide" : "Hide"}
                    </span>
                  </>
                );

                return (
                  <NotificationItem
                    key={i}
                    item={notification}
                    icon={<Icon />}
                    onItemClick={onItemClick}
                    setAnimatedClassName={setAnimatedClassName}
                    apiName="notifications"
                    path="notifications"
                    animatedClassName={animatedClassName}
                    moreActionsContent={moreActionsContent}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    type="Notification"
                    activeTab={activeTab}
                  />
                );
              }) 
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default NotificationsLayout;
