import { Modal } from "antd";
import { AgGridReact } from "ag-grid-react";
import { useState, useContext } from "react";

import DegModalContext from "../DegModalContext";
import { getRolesCost } from "../utils/getCategoryCost";
import { updateDocumentTitle } from "../../../../../../../../utils";
import { detailColDefs, mainColDefs } from "./categoryCostModalData";
import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";

import "./CategoryCostModal.scss";

const gridOptions = {
  alwaysShowVerticalScroll: true,
  alwaysShowHorizontalScroll: true,
};

const defaultColDef = {
  flex: 1,
  filter: true,
  minWidth: 130,
  sortable: true,
  resizable: true,
  enableColResize: true,
  enableRowGroup: false,
  suppressSizeToFit: true,
  suppressDragLeaveHidesColumns: true,
};

function CategoryCostModal({ open, onCancel }) {
  const { analytics, crews, isDarkMode } = useContext(DegModalContext);

  const [rowData] = useState(getRolesCost({ analytics, crews }));

  return (
    <Modal
      centered
      open={open}
      closable={true}
      title="Category Cost"
      closeIcon={<XIcon />}
      data-testid="category-cost-modal"
      className={`categoryCostModal ${isDarkMode && "categoryCostModalDark"}`}
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Category Cost" });
      }}
      onCancel={() => {
        updateDocumentTitle(); // reset document title to "Lead Manager";
        onCancel();
      }}
      footer={[
        <MondayButton
          key="1"
          Icon={<XIcon />}
          className="mondayButtonRed"
          data-testid="close-category-cost-modal-btn"
          onClick={() => {
            updateDocumentTitle(); // reset document title to "Lead Manager";
            onCancel();
          }}
        >
          Close
        </MondayButton>,
      ]}
    >
      <div
        className={`cost-dispersion-grid ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            rowData,
            gridOptions,
            defaultColDef,
            pagination: false,
            animateRows: true,
            masterDetail: true,
            detailRowHeight: 300,
            rowSelection: "multiple",
            columnDefs: mainColDefs(),
            suppressRowClickSelection: true,
            detailCellRendererParams: detailColDefs(),
          }}
        />
      </div>
    </Modal>
  );
}

export default CategoryCostModal;
