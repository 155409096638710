import moment from "moment";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { message } from "antd";
import { estimateTotalCalculation } from "./estimateAmountCalculator";

export const processInvoices = (invoices, filterDateParam) => {
  if (Array.isArray(invoices)) {
    return invoices
      .map((invoice, idx) => {
        const {
          invoiceId = "",
          invoiceNumber = "",
          invoiceDate = "",
          invoiceStatus = "",
          toDate = "",
          amountDue = 0,
          accountName = "",
          totalInvoiceAmount = 0,
          quickBooksNumber = "",
          projectName = "",
          memos = [],
          projectId = "",
        } = invoice;

        return {
          key: idx + 1,
          type: "Invoice",
          num: invoiceNumber,
          accountName,
          invoiceStatus,
          invoiceDate: moment(invoiceDate).format("MM/DD/YYYY"),
          toDate: moment(toDate).format("MM/DD/YYYY"),
          invoiceId,
          aging:
            forceToNumber(amountDue) !== 0
              ? moment().diff(
                  moment(moment(toDate).format("MM/DD/YYYY"), "MM/DD/YYYY"),
                  "days"
                )
              : "—",
          openBalance: filterDateParam ? 0 : forceToNumber(amountDue),
          totalInvoiceAmount: forceToNumber(totalInvoiceAmount),
          receivedPayment: filterDateParam
            ? 0
            : forceToNumber(totalInvoiceAmount) - forceToNumber(amountDue),
          quickBooksNumber,
          memos: memos,
          projectName,
          projectId,
        };
      })
      .filter((el) => {
        if (filterDateParam) {
          return (
            moment(el.invoiceDate, "MM/DD/YYYY").valueOf() >
              (filterDateParam?.startDate?.valueOf() ?? 0) &&
            moment(el.invoiceDate, "MM/DD/YYYY").valueOf() <
              (filterDateParam?.endDate?.valueOf() ?? moment().valueOf())
          );
        }
        return true;
      })
      .sort((a, b) => {
        /// sort with invoice date
        return moment(a.invoiceDate).isAfter(b.invoiceDate) ? 1 : -1;
      });
  }
};

const filterPaymentsByDate = (payments, filterDateParam) => {
  return payments.filter((payment) => {
    const paymentDate = moment(payment.paymentDate, "MM/DD/YYYY").valueOf();
    return (
      paymentDate > (filterDateParam?.startDate?.valueOf() ?? 0) &&
      paymentDate < (filterDateParam?.endDate?.valueOf() ?? moment().valueOf())
    );
  });
};

const processPaymentInvoices = (
  payments,
  processedInvoices,
  filterDateParam
) => {
  let totalities = { appliedAmount: 0, totalInvoicedPaid: 0 };

  payments.forEach((payment) => {
    (payment.invoices || []).forEach((invoice) => {
      const appInvoice = processedInvoices.find(
        (e) => e.invoiceId === invoice.invoiceId
      );
      if (appInvoice && filterDateParam) {
        appInvoice.receivedPayment += forceToNumber(invoice.invoicePaidAmount);
        appInvoice.appliedAmount += forceToNumber(invoice.invoicePaidAmount);
      }
    });

    const currTotalInvoicedPaid = payment.invoices.reduce(
      (acc, curr) => acc + forceToNumber(curr.invoicePaidAmount),
      0
    );

    totalities.appliedAmount += forceToNumber(payment.appliedAmount);
    totalities.totalInvoicedPaid += currTotalInvoicedPaid;
  });

  return { processedInvoices, totalities };
};

export const retrieveInvoices = async ({
  id,
  filterBy,
  filterDateParam = null,
}) => {
  try {
    const {
      totalEstimates,
      invoices,
      payments,
      servicesRentalsPerEstimation,
      rentals,
      allPayments = [],
      unAppliedCredit = 0,
      totalCredit = 0,
      appliedCredit = 0,
    } = await estimateTotalCalculation(id, filterBy);

    let processedInvoices = processInvoices(invoices, filterDateParam);
    console.log("processedRentals", rentals);
    const filteredPayments = filterDateParam
      ? filterPaymentsByDate(payments, filterDateParam)
      : payments;

    const { processedInvoices: updatedInvoices, totalities } =
      processPaymentInvoices(
        filteredPayments,
        processedInvoices,
        filterDateParam
      );

    if (filterDateParam) {
      const updatedData = updatedInvoices.map((invoice) => ({
        ...invoice,
        openBalance:
          forceToNumber(invoice.totalInvoiceAmount) -
          forceToNumber(invoice.appliedAmount),
      }));
      return { updatedData, totalities };
    }
    totalities.totalRentals =
      rentals?.reduce((acc, curr) => acc + forceToNumber(curr.totalPrice), 0) ||
      0;
    return {
      processedInvoices,
      totalities: {
        ...totalities,
        totalEstimates,
        unAppliedCredit,
        totalCredit,
        appliedCredit,
      },
      servicesRentalsPerEstimation,
      filteredPayments,
      payments,
      allPayments,
    };
  } catch (error) {
    console.error("Error retrieving invoices:", error);
    message.error("Something went wrong!");
  } finally {
    message.destroy();
  }
};
