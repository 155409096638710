import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  extractDataSources,
  extractReportParameters,
  populateReport,
} from "../../../utils";
import reportToBase64 from "./reportToBase64";
import { generatePdfThumbnail } from "../../../../../../utils";
import {
  createReport,
  updateReport,
} from "../../../configuration/actions/wrapperFunctions";
import formatSavingReport from "./formatSavingReport";

async function saveReportHandler({
  newReport,
  oldReport,
  update = true,
  newDetails,
  exportsSettings,
  userConfiguration,
  setReport,
  selectedCategory,
  setReportConfiguration,
}) {
  try {
    const reportToSave = formatSavingReport(cloneDeep(newReport), oldReport);
    let thumbnail = await getThumbnail({
      newReport,
      exportsSettings,
    });
    const mockDispatch = async (action) => {
      if (typeof action === "function") {
        await action(mockDispatch);
      }
    };
    if (update) {
      await mockDispatch(
        updateReport(selectedCategory, {
          ...reportToSave,
          thumbnail,
        })
      );
      setReport({
        ...reportToSave,
        thumbnail,
        isUpdating: true,
      });
      setReportConfiguration((prev) => ({
        ...prev,
        reportsAvailable: (prev?.reportsAvailable || [])?.map((config) =>
          config?.reportId === reportToSave?.reportId
            ? { ...config, ...reportToSave, thumbnail }
            : config
        ),
      }));
    } else {
      const newReportObj = {
        ...reportToSave,
        reportName: newDetails?.name,
        description: newDetails?.description || "",
        useInAutomation: false,
        reportId: uuidv4(),
        createdAt: Date.now(),
        hasSelectedFavorite: [],
        createdBy: userConfiguration?.nameOfUser,
        thumbnail,
      };
      console.log("newReportObj:", { newReportObj });
      await mockDispatch(createReport(selectedCategory, newReportObj));
      setReport({
        ...newReportObj,
        thumbnail,
        isUpdating: true,
      });
      setReportConfiguration((prev) => ({
        ...prev,
        reportsAvailable: [...(prev?.reportsAvailable || []), newReportObj],
      }));
    }
    return Promise.resolve();
  } catch (error) {
    console.error("Error saving report", error);
  }
}

export default saveReportHandler;

async function getThumbnail({ newReport, exportsSettings }) {
  try {
    const { blob, ...rest } = await reportToBase64(newReport, exportsSettings);
    console.log("getThumbnail ~ rest:", { rest, blob });
    if (!blob) {
      throw new Error("Error in generating report blob");
    }

    const buffer = await blob?.data?.arrayBuffer();
    if (!buffer) {
      throw new Error("Error in generating report blob buffer");
    }
    const newThumb = await generatePdfThumbnail(new Uint8Array(buffer));
    if (!newThumb) {
      throw new Error("Error in generating report blob buffer");
    }

    return newThumb;
  } catch (e) {
    console.error("Error in generating thumbnail", e);
    return "";
  }
}
