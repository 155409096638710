import React from "react";

function getRatio(ratio = "1/1") {
  return {
    "4/3": "calc(100% / 4 * 3)",
    "3/4": "calc(100% / 3 * 4)",
    "6/4": "calc(100% / 6 * 4)",
    "4/6": "calc(100% / 4 * 6)",
    "16/9": "calc(100% / 16 * 9)",
    "9/16": "calc(100% / 9 * 16)",
    "21/9": "calc(100% / 21 * 9)",
    "9/21": "calc(100% / 9 * 21)",
    "1/1": "100%",
  }[ratio];
}

export type ImageRatio =
  | "4/3"
  | "3/4"
  | "6/4"
  | "4/6"
  | "16/9"
  | "9/16"
  | "21/9"
  | "9/21"
  | "1/1";

interface AspectRatioProps {
  ratio?: ImageRatio;
  style?: any;
  children?: React.ReactNode;
}

const AspectRatio = ({
  ratio = "1/1",
  style,
  children,
  ...props
}: AspectRatioProps) => {
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        overflow: "hidden",
        ...style,
      }}
      {...props}
    >
      <div
        style={{
          width: "100%",
          display: "block",
          paddingBottom: getRatio(ratio),
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          inset: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default AspectRatio;
