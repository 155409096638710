function getDegTotals({ employeeWeekTotals, generalOverheadTotal = 0 }) {
  let reg = 0,
    ot = 0,
    ovh = 0;
  let totalRegHours = 0,
    totalOtHours = 0,
    totalOvhHours = 0,
    totalReimbursement = 0;

  for (const employeeId in employeeWeekTotals) {
    for (const week in employeeWeekTotals[employeeId]) {
      let weekEntry = employeeWeekTotals[employeeId][week];

      ot = ot + (Number(weekEntry?.otAmountWeekly) || 0);
      reg = reg + (Number(weekEntry?.totalRegAmount) || 0);
      ovh = ovh + (Number(weekEntry?.totalOvhAmount) || 0);

      totalOtHours = totalOtHours + (Number(weekEntry?.otHoursWeekly) || 0);
      totalRegHours = totalRegHours + (Number(weekEntry?.totalRegHours) || 0);
      totalOvhHours = totalOvhHours + (Number(weekEntry?.totalOvhHours) || 0);
      totalReimbursement =
        totalReimbursement + (Number(weekEntry?.reimbursementTotalAmount) || 0);
    }
  }

  return {
    totalOt: ot,
    totalOtHours,
    totalReg: reg,
    totalRegHours,
    totalOvhHours,
    totalReimbursement,
    totalWOvh: reg + ot + ovh,
    total: reg + ot + totalReimbursement,
    totalWorkHours: totalRegHours + totalOtHours,
    totalOvh: ovh + (Number(generalOverheadTotal) || 0),
    totalWorkHoursWOvh: totalRegHours + totalOtHours + totalOvhHours,
  };
}

export default getDegTotals;
