import { datasetsFilter, extractReportParameters } from "../../../utils";

function formatSavingReport(newReport = {}, oldReport = {}) {
  const { isUpdating, ...report } = oldReport;
  const newDataSources =
    newReport?.definition?.DataSources?.filter(datasetsFilter)?.map(
      (el) => el?.Name
    ) || oldReport?.datasources;
  return {
    ...report,
    reportObj: {
      ...newReport,
      definition: {
        ...(newReport?.definition || {}),
        DataSources: newDataSources,
      },
    },
    datasets: newReport?.definition?.DataSets,
    datasources: newDataSources,
    reportParameters: extractReportParameters(newReport),
  };
}

export default formatSavingReport;
