import {
  DIMENSIONS,
  get_ProgressDimensionsByServiceId,
  getPliRemaining,
  percentageCellRender,
  progressPercentage_valueGetter,
  remainingPercentage_valueGetter,
  status_valueGetter,
} from "../../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import capitalizeFirstLetter, {
  getDimensionHeader,
} from "../../../../../SidebarPages/Estimations/DataEntryGrid/tools/polyfillers/capitalizeFirstLetter";
import {
  editableProgressCell,
  scheduleIDCellClassRules,
  totalProgressStyle_byDimension,
} from "../../../DataEntryGrid/tools/columnDefinitions/Styles";
import { PROGRESS_ORIGIN } from "../componentsForPli/PliProgressModal";
import { BREAKDOWN_RENDER_ORIGIN } from "../componentsForPli/Breakdown/breakdown";
import { evaluateMathExpression } from "../../../../../SidebarPages/Estimations/DataEntryGrid/tools/formatters";
import { SERVICES_WITH_SUB_PLI } from "./constants";
import _ from "lodash";
// import {PliHistoryPopover} from "../componentsForPli/PliHistoryPopover";
import { AddonType } from "../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import { extractContent } from "../../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/cellRenderers/rtfEditor/rtfEditor";
import { findJump } from "../../helpers/PLI-setters-getters";
import { getAddonName } from "../../../../../pages/Settings/settingsComponents/Pricing/utils/addonDataFunctions";
import { Button, message, Select, Tooltip } from "antd";
import DescriptionPreview from "../../helpers/DescriptionPreview";
import { serviceHiddenColumns } from "../helper/serviceHiddenColumns";
import { Column } from "ag-grid-enterprise";
import { ProgressStatistics } from "../../../../../SidebarPages/Scheduling/helpers/totals";
import {
  checkIfServiceIsOtherScope,
  colorsText,
  maskNumbers,
} from "../../SchedulingFirstPage/helperData";
import { checkIfServiceIsHoist } from "../../../../../SidebarPages/Estimations/DataEntryGrid/models/Service";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { toOrdinalFloor } from "../../../../../SidebarPages/Estimations/DataEntryGrid/tools/polyfillers";
import { assignServiceColor } from "../../../../../../helpers/constants/forProject";
import { forceToNumber } from "../../../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { LockFilled, UnlockFilled } from "@ant-design/icons";

/**
 * This file have the needed functions to generate column definitions for ag-grid tables in schedule form.
 * The main functions are:
 * 1. getElevationColumns which generate columns for pli tables
 * 2. createSubPLIColumns which generates columns for sub-pli tables
 * 3. scheduleAddonsColumns which is used in addonList which generates columns for addons in addons table in modal
 * 4. getScheduleServiceAddonsColumns which generate columns for service addon tables
 *
 * Usually these functions receives and passes to their helper functions 3 objects. The first object is a list of indexes such as serviceId.
 * The second object are helper variables with will be passed to cell renderers or to make some calculations.
 * The third object are a bunch of functions which will be used by cell renderers
 *
 * */

export const getElevationColumns = (
  {
    service,
    serviceId,
    serviceIndex,
    elevationIndex,
    label,
    elevationLabel,
    typeOfWork,
    editMode,
    setAbleToSave,
    updateAddonsForFloors,
    elevationTotalSqft,
    isWritable,
    updateProgressLogs,
    estimationId,
    elevationId,
    updateBreakdownLogs,
  },
  {
    pricing,
    units,
    serviceDefinitions,
    scheduleDays,
    dayColors,
    typeOfWorkObject,
    gridData,
    userConfiguration,
  },
  {
    concatDays_From_PLI_ToElevation,
    unlockPliIfPrevScheduleDeleted,
    onSubPLIAddRemove,
    onBreakdownSetInPLI,
    updateDays_From_Addons_to_PLI,
    saveDataFromRTDEditor,
    setItemAfterProgress,
    scheduleName,
    isDarkMode,
  }
) => {
  try {
    serviceId = parseInt(serviceId);
    let IS_HOIST = checkIfServiceIsHoist(service); //for hoist we have a little different workaround
    const allColumns = [];

    //get columns from service definitions
    let fromEstimationCols_brute = _.cloneDeep(
      serviceDefinitions?.find(
        (sd) => sd?.serviceId?.toString() === serviceId?.toString()
      )?.columnDefinitions
    );

    // console.log("fromEstimationCols_brute", {
    //   fromEstimationCols_brute,
    //   label: service?.label,
    //   serviceDefinitions,
    // });

    // console.log(fromEstimationCols_brute)
    let fromEstimationCols = [];
    let fromEstimationIntoForSchedule = [];

    for (const c of fromEstimationCols_brute) {
      // console.log("ccccccccccccccccccc", { c });
      // if(serviceId === 15) {
      //   console.log(c)
      // }

      if (
        DIMENSIONS.includes(c?.field?.toLowerCase()) ||
        ["floor"].includes(c?.field?.toLowerCase())
      ) {
        //dimension columns or floor col will be displayed also into for-schedule section
        c.width = c?.field === "floor" ? 100 : 60;
        c.cellStyle = {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontWeight: "bolder",
          color: `${assignServiceColor?.[serviceId] || "green"}`,
        };
        c.cellRendererFramework = (params) => {
          if (params?.node?.group) return "";
          let value =
            params?.value ||
            params?.data?.originalPli?.[c?.field] ||
            params?.data?.originalPli?.originalPli?.[c?.field];
          if (value === undefined) return "-";
          return isNaN(value) ? 0 : parseFloat(value);
        };
        fromEstimationIntoForSchedule.push(c);
      }
      if (serviceHiddenColumns(serviceId)?.includes(c?.field)) continue; //remove pricing cols
      c.valueGetter = (params) => {
        if (params?.node?.group) return "";
        return params?.data?.originalPli?.[params?.column?.colId];
      };
      //these data will be read from originalPli
      c.headerClass = "from-estimation-group";

      if (["note", "description"]?.includes?.(c?.field)) {
        // c.valueGetter = params => {
        //     let htmlString = params?.data?.originalPli[params?.colDef?.field?.split("~")[1]] //parse their html into string
        //     console.log(htmlString)
        //     if(htmlString === undefined) return "-"
        //     return extractContent(htmlString);
        // }
        c.cellRendererFramework = (params) => {
          if (params?.node?.group) return "";
          let htmlString =
            params?.data?.originalPli?.[params?.colDef?.field] ||
            params?.data?.originalPli?.originalPli?.[params?.colDef?.field] ||
            {};
          if (htmlString?.note === undefined) return "-";
          return (
            <DescriptionPreview
              value={maskNumbers(extractContent(htmlString?.note))}
            />
          );
        };
      }
      if (["onFloor"].includes(c?.field)) {
        c.valueFormatter = (params) => {
          if (params?.value) {
            return `${toOrdinalFloor({
              value: params?.value - 1,
            })} - ${toOrdinalFloor(params)}`;
          } else {
            return "-";
          }
        };
      }
      if (["toFloor"].includes(c?.field)) {
        c.cellRenderer = (params) => {
          return !!params?.value
            ? `${parseInt(params?.value)}th floor level`
            : "Ground level";
        };
      }

      if (c?.field === "isJump") {
        c.cellRenderer = (params) => {
          return !!params?.value && params?.value?.jumpWidth
            ? `true (${params?.value?.jumpWidth})`
            : "false";
        };
      }
      if (
        c?.field?.toLowerCase()?.includes("price") ||
        c?.field?.toLowerCase()?.includes("ppu") ||
        c?.field?.toLowerCase()?.includes("rent")
      ) {
        continue;
      }
      fromEstimationCols.push(c);
    }

    /*
     * We have 4 sections: 1. for-schedule 2. for-progress 3. remaining 4. from-estimate (which have the original data)
     * */
    allColumns.push({
      headerName: "FOR SCHEDULE",
      marryChildren: true,
      headerClass: "forschedule-group",
      children: forScheduleColumns(
        {
          service,
          serviceId,
          serviceIndex,
          elevationIndex,
          label,
          elevationLabel,
          typeOfWork,
          editMode,
          updateAddonsForFloors,
          isWritable,
          updateProgressLogs,
          estimationId,
          elevationId,
          updateBreakdownLogs,
        },
        {
          pricing,
          units,
          serviceDefinitions,
          scheduleDays,
          dayColors,
          typeOfWorkObject,
          gridData,
          fromEstimationIntoForSchedule,
          userConfiguration,
        },
        {
          concatDays_From_PLI_ToElevation,
          unlockPliIfPrevScheduleDeleted,
          onSubPLIAddRemove,
          onBreakdownSetInPLI,
          updateDays_From_Addons_to_PLI,
          saveDataFromRTDEditor,
          setItemAfterProgress,
          scheduleName,
          isDarkMode,
        }
      ),
    });
    if (!IS_HOIST && !checkIfServiceIsOtherScope(service)) {
      allColumns.push({
        headerName: "REMAINING",
        marryChildren: true,
        headerClass: "remaining-group",
        children: forRemainingColumns(
          serviceId,
          typeOfWork,
          elevationTotalSqft
        ),
      });
    }
    allColumns.push({
      headerName: "PROGRESS",
      marryChildren: true,
      headerClass: "progress-group",
      children: forProgressColumns(
        {
          service,
          serviceId,
          serviceIndex,
          elevationIndex,
          label,
          elevationLabel,
          editMode,
          setAbleToSave,
          elevationTotalSqft,
          isWritable,
        },
        {
          pricing,
          units,
          serviceDefinitions,
          scheduleDays,
          typeOfWorkObject,
        },
        { setItemAfterProgress, typeOfWork }
      ),
    });

    allColumns.push({
      headerName: "FROM ESTIMATE",
      marryChildren: true,
      headerClass: "from-estimation-group",
      children: fromEstimationCols,
    });

    return allColumns;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const forScheduleColumns = (
  {
    service,
    serviceId,
    serviceIndex,
    elevationIndex,
    label,
    elevationLabel,
    typeOfWork,
    editMode,
    updateAddonsForFloors,
    isWritable,
    updateProgressLogs,
    estimationId,
    elevationId,
    updateBreakdownLogs,
  },
  {
    pricing,
    typeOfWorkObject,
    serviceDefinitions,
    scheduleDays,
    dayColors,
    gridData,
    fromEstimationIntoForSchedule,
    userConfiguration,
  },
  {
    concatDays_From_PLI_ToElevation,
    unlockPliIfPrevScheduleDeleted,
    onSubPLIAddRemove,
    onBreakdownSetInPLI,
    updateDays_From_Addons_to_PLI,
    saveDataFromRTDEditor,
    setItemAfterProgress,
    scheduleName,
    isDarkMode,
  }
) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

  let cols = [];
  let IS_HOIST = checkIfServiceIsHoist(service);

  if (!IS_HOIST)
    //hoist do not need to have ID
    cols.push({
      headerName: "ID",
      field: "id",
      headerClass: "forschedule-group",
      width: 80,
      // cellClass: "id-column",
      cellClassRules: scheduleIDCellClassRules,
    });
  if (!IS_HOIST) {
    cols.push({
      headerName: "Lock",
      field: "locked",
      headerClass: "forschedule-group",
      width: 80,
      cellRenderer: "LockPliInSchedule",
      cellRendererParams: {
        scheduleDays,
        serviceId,
        elevationIndex,
        concatDays_From_PLI_ToElevation,
        unlockPliIfPrevScheduleDeleted,
        origin: "pli",
        typeOfWorkObject,
        editMode,
        isWritable,
      },
    });
  }

  if (IS_HOIST) {
    const service = gridData?.find(
      (s) => s?.serviceId?.toString() === serviceId?.toString()
    );
    const jumps = service?.serviceOptions?.[0]?.[elevationIndex]?.jumps;
    cols.push({
      headerName: "Jump",
      field: "jump",
      resizable: true,
      headerClass: "forschedule-group",
      width: 150,
      cellStyle: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      valueGetter: (params) => {
        if (params?.node?.group) return "";
        let temp = findJump(jumps, params?.data?.floor)?.name;
        params.data.jump = temp;
        return temp || "";
      },
    });
  }

  cols = cols.concat(fromEstimationIntoForSchedule);
  //     console.log(cols)
  for (const d of [d1, d2]) {
    // if (d)
    //   cols.push({
    //     headerName: "L",
    //     // headerTooltip: capitalizeFirstLetter(d) + " to schedule",
    //     field: d,
    //     cellRendererFramework: (params) => {
    //       console.log("QQQQQ", { params: params?.data });
    //       return <div>{parseFloat(params?.data?.originalPli?.length)}</div>;
    //     },
    //     headerClass: "forschedule-group",
    //     width: 61,
    //     // valueFormatter: evaluateMathExpression,
    //     // cellRenderer: "PliHistoryPopover",
    //     // cellRendererParams: { d1, d2, typeOfWorkObject },
    //   });
  }
  if (d2) {
    //if we have services such as scaffold where progress is measured by 2 dimensions
    cols.push({
      headerName: "Sqf.",
      headerTooltip: "Surface to be scheduled",
      field: "surface",
      headerClass: "forschedule-group",
      width: 65,
      cellStyle: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        fontWeight: "bolder",
        color: `${assignServiceColor?.[serviceId] || "green"}`,
      },
      cellRendererFramework: (params) => {
        if (params?.node?.group) return "";
        const toReturn = params?.data?.[d1] * params?.data?.[d2];
        return isNaN(toReturn) ? 0 : forceToNumber(toReturn);
      },
    });
  }

  if (SERVICES_WITH_SUB_PLI.includes(serviceId)) {
    // cols.push({
    //   headerName: "For progress",

    //     return <div></div>;
    //   },
    // });
    cols.push({
      headerName: "Split",
      headerClass: "forschedule-group",
      field: "subPli",
      cellRenderer: "ModifyRenderer",
      width: 130,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
      },
      valueFormatter: (params) => {
        try {
          return "(" + (params?.data?.subPli?.length || 0) + ")";
        } catch (e) {
          console.error(e);
        }
      },
      cellRendererParams: {
        serviceId,
        elevationIndex,
        concatDays_From_PLI_ToElevation,
        onSubPLIAddRemove,
        scheduleDays,
        isWritable,
      },
    });
  }
  // if (IS_HOIST) {
  //   cols.push({
  //     headerName: "Floor Addons",
  //     field: "addons",
  //     cellRenderer: "AddonsForHoist",
  //     headerClass: "forschedule-group",
  //     cellRendererParams: {
  //       serviceId,
  //       serviceIndex,
  //       elevationIndex,
  //       label,
  //       elevationLabel,
  //       typeOfWork,
  //       scheduleDays,
  //       updateAddonsForFloors,
  //     },
  //     width: "fit-content",
  //     height: "100%",
  //     cellStyle: {
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center",
  //     },
  //   });
  // }
  cols.push({
    headerName: "Note",
    headerTooltip: "Note",
    field: "note",
    headerClass: "forschedule-group",
    // columnGroupShow: 'open',
    cellRenderer: "rtfEditorSchedule",
    cellRendererParams: {
      gridData,
      saveDataFromRTDEditor,
      serviceId,
      serviceIndex,
      elevationIndex,
      optionIndex: 0,
      from: "pli",
      userConfiguration,
      isWritable,
    },
    width: 120,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });
  typeOfWork !== "Inspection" &&
    !IS_HOIST &&
    cols.push({
      headerName: "Days in Schedule",
      headerTooltip: "Select days",
      field: "days",
      supressSizeToFit: true,
      minWidth: 300,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
      },
      headerClass: "forschedule-group",
      cellRenderer: "DaysInScheduleMaster",
      cellRendererParams: {
        scheduleDays,
        serviceId,
        elevationIndex,
        concatDays_From_PLI_ToElevation,
        unlockPliIfPrevScheduleDeleted,
        origin: "pli",
        typeOfWorkObject,
        editMode,
        isWritable,
      },
    });

  if (typeOfWork !== "Inspection" && !IS_HOIST) {
    cols.push({
      headerName: "Breakdown",
      field: "breakdownValue",
      cellRenderer: "Breakdown",
      headerClass: "forschedule-group",
      cellRendererParams: {
        serviceDefinitions,
        serviceId,
        serviceIndex,
        optionIndex: 0,
        elevationIndex,
        label,
        elevationLabel,
        typeOfWork,
        scheduleDays,
        dayColors,
        onBreakdownSetInPLI,
        origin: "pli",
        typeOfWorkObject,
        editMode,
        scheduleName,
        isDarkMode,
        isWritable,
        updateProgressLogs,
        estimationId,
        service,
        elevationLabel,
        elevationId,
        updateBreakdownLogs,
      },
      width: 113,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
      },
    });
  } else if (typeOfWork === "Inspection" && !IS_HOIST) {
    cols.push({
      headerName: "Breakdown",
      field: "breakdownValue",
      cellRenderer: "Breakdown",
      headerClass: "forschedule-group",
      cellRendererParams: {
        serviceDefinitions,
        serviceId,
        serviceIndex,
        optionIndex: 0,
        elevationIndex,
        label,
        elevationLabel,
        typeOfWork,
        scheduleDays,
        dayColors,
        onBreakdownSetInPLI,
        origin: "pli",
        typeOfWorkObject,
        scheduleName,
        isDarkMode,
        isWritable,
      },
      width: 113,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    });
  } else {
    // return "";
  }

  // cols.push({
  //   headerName: "Add-ons",
  //   headerClass: "forschedule-group",
  //   field: "addons",
  //   width: 100,
  //   cellStyle: {
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     border: "none",
  //   },
  //   cellRenderer: "AddonsList",
  //   cellRendererParams: {
  //     scheduleDays,
  //     serviceId,
  //     elevationIndex,
  //     pricing,
  //     updateDays_From_Addons_to_PLI,
  //     origin: "pli_addon",
  //     typeOfWorkObject,
  //     setItemAfterProgress,
  //   },
  //   // valueFormatter: params => {try{return "(" + params.data.addons.length + ")"}catch(e){console.log(e)}},
  // });

  return cols;
};

export const forProgressColumns = (
  {
    service,
    serviceId,
    serviceIndex,
    elevationIndex,
    label,
    elevationLabel,
    editMode,
    setAbleToSave,
    elevationTotalSqft,
    isWritable,
  },
  { pricing, units, serviceDefinitions, scheduleDays, typeOfWorkObject },
  { setItemAfterProgress, typeOfWork }
) => {
  const columns = [];
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

  let IS_HOIST = checkIfServiceIsHoist(service);
  !IS_HOIST &&
    columns.push({
      headerName: "Pli Progress %",
      headerTooltip: "Progress of this pli in all days",
      field: "progressByDay",
      cellRenderer: "PliProgressModal",
      headerClass: "progress-group",
      width: 150,

      cellRendererParams: {
        serviceId,
        elevationIndex,
        scheduleDays,
        label,
        elevationLabel,
        type: PROGRESS_ORIGIN.PLI,
        origin: BREAKDOWN_RENDER_ORIGIN.PLI,
        setItemAfterProgress,
        typeOfWorkObject,
        editMode,
        typeOfWork,
        editMode,
        service,
        isWritable,
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
      },
    });
  !IS_HOIST &&
    // columns.push({
    //   headerName: "Working Hours",
    //   field: "usedScheduleDays",
    //   cellRenderer: "ScheduledDaysTime",
    //   width: 130,

    //   cellRendererParams: {
    //     scheduleDays,
    //     serviceId,
    //     setAbleToSave,
    //   },
    //   cellStyle: {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     border: "none",
    //   },
    // });

    !IS_HOIST &&
    columns.push({
      headerName: d2 ? " Pli Progress Sqf." : "Pli Progress Lnf.",
      headerTooltip: "Progress of this pli in all days",
      field: "totalProgress",
      headerClass: "progress-group",
      // cellClassRules: totalProgressStyle(serviceId),
      width: 150,
      cellStyle: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      valueGetter: (params) => {
        if (params?.node?.group) return "";
        return Math.round(params?.data?.totalProgress) || 0;
      },
    });

  IS_HOIST &&
    columns.push({
      headerName: "Floor Progress %",
      field: "totalProgress",
      headerClass: "progress-group",
      width: 100,
      cellStyle: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
    });

  !IS_HOIST &&
    columns.push({
      headerName: "ITEM %",
      headerTooltip: "Progress of this pli in all days in percentage",
      field: "totalProgressPercentage",
      headerClass: "progress-group",
      width: 100,
      cellStyle: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      valueGetter: (params) => {
        if (params?.node?.group) return "";
        const pliProgress = params?.data?.totalProgress || 0;
        const totalElevation = elevationTotalSqft || 0;
        return isNaN(pliProgress / totalElevation)
          ? 0
          : Math.round((pliProgress / totalElevation) * 100);
      },
    });

  columns.push({
    headerName: "Status",
    // cellClassRules: progressStatusCellClassRules(),
    width: 180,
    headerClass: "progress-group",
    cellEditor: "agSelectCellEditor",
    editable: true,
    cellEditorParams: {
      values: ["English", "Spanish", "French", "Portuguese", "(other)"],
    },
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    cellRendererFramework: (params) => {
      if (params?.node?.group) return "";
      const statusText = status_valueGetter(
        params,
        { d1, d2 },
        typeOfWorkObject,
        typeOfWork
      );
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            width: 180,
            fontSize: 16,
            background: colorsText?.[statusText],
          }}
        >
          {statusText}
        </div>
      );
    },
    // cellRendererFramework: (params) => {
    //   const { value, data = {} } = params || {};
    //   const {
    //     [d1]: originalD1,
    //     [d2]: originalD2,
    //     progressByDay = [],
    //     subPli = [],
    //   } = data;

    //   const hasValidDimensions = progressByDay?.every(
    //     ({ [d1]: currentD1, [d2]: currentD2 }) =>
    //       currentD1 <= originalD1 && currentD2 <= originalD2
    //   );

    //   return (
    //     <div>
    //       {!!d1 &&
    //         !!d2 &&
    //         value === typeOfWorkObject?.progressStatuses?.TOTAL_DONE &&
    //         !subPli?.length &&
    //         !hasValidDimensions && (
    //           <Tooltip title="You have installed more than scheduled in one of the dimensions">
    //             <WarningOutlined />
    //           </Tooltip>
    //         )}
    //       <span style={{ color: "#fff" }}>{value}</span>
    //     </div>
    //   );
    // },
  });
  // columns.push({
  //   headerName: "Status",
  //   field: "totalProgressPercentage",
  //   // width: 110,
  //   cellStyle: {
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   },

  //   // cellClassRules: progressStatusCellClassRules(),
  //   cellRendererFramework: (params) => {
  //     if (params?.node?.group) return "";
  //     const value = isNaN(
  //       progressPercentage_valueGetter(params, { d1, d2 }, typeOfWork)
  //     )
  //       ? 0
  //       : progressPercentage_valueGetter(params, { d1, d2 }, typeOfWork);
  //     console.log("valueProgress", { value });
  //     let status = "";
  //     let background = "";
  //     if (isNaN(value)) {
  //       status = "Not Done";
  //       background = "rgb(240, 79, 78)";
  //     } else if (value === 0) {
  //       status = "Not Done";
  //       background = "rgb(240, 79, 78)";
  //     } else if (value > 0 && value < 100) {
  //       status = "Partially Done";
  //       background = "rgb(245, 226, 178)";
  //     } else if (value === 100) {
  //       status = "Done";
  //       background = "rgb(113, 207, 72)";
  //     } else {
  //       status = "Not Done";
  //       background = "rgb(240, 79, 78)";
  //     }
  //     return (
  //       <div
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           color: "#fff",
  //           fontSize: 15,
  //           backgroundColor: background,
  //           width: "100%",
  //           height: "100%",
  //         }}
  //       >
  //         {status}
  //       </div>
  //     );
  //   },
  // });

  return columns;
};

export const forRemainingColumns = (
  serviceId,
  typeOfWork,
  elevationTotalSqft
) => {
  const cols = [];
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

  cols.push({
    headerName: d2 ? "Remaining Pli Sqf." : "Remaining Pli Lnf.",
    headerTooltip: "Undone in this schedule",
    field: "remainingUnits",
    headerClass: "remaining-group",
    valueGetter: (params) => {
      if (params?.node?.group) return "";
      return Math.round(getPliRemaining(params, { d1, d2 })) || 0;
    },
    // cellClassRules: remainingStyle(serviceId),
    width: 160,
    cellStyle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  });

  cols.push({
    headerName: "Remaining Pli %",
    headerTooltip: "Undone in this schedule in percentage",
    field: "remainingPercentage",
    headerClass: "remaining-group",
    valueGetter: (params) => {
      if (params?.node?.group) return "";
      return isNaN(remainingPercentage_valueGetter(params, { d1, d2 }))
        ? 0
        : 100 - progressPercentage_valueGetter(params, { d1, d2 }, typeOfWork);
    },
    valueParser: (params) => (params?.value || 0) + "%",
    // cellClassRules: remainingPercentageStyle,
    width: 160,
    cellStyle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  });
  // cols.push({
  //   headerName: "ITEM %",
  //   headerTooltip: "Progress of this pli in all days in percentage",
  //   field: "totalProgressPercentage",
  //   headerClass: "progress-group",
  //   width: 100,
  //   cellStyle: {
  //     display: "flex",
  //     justifyContent: "flex-start",
  //     alignItems: "center",
  //   },
  //   valueGetter: (params) => {
  //     if (params?.node?.group) return "";
  //     const pliProgress = params?.data?.totalProgress || 0;
  //     const totalElevation = elevationTotalSqft || 0;
  //     // get the remaining percentage of the pli from the total elevation
  //     return isNaN(pliProgress / totalElevation)
  //       ? 0
  //       : Math.round((pliProgress / totalElevation) * 100);

  //   },
  // });

  return cols;
};

export const getCellRendererParams = (
  {
    service,
    serviceId,
    serviceIndex,
    elevationIndex,
    label,
    elevationLabel,
    isWritable,
  },
  { scheduleDays, typeOfWorkObject, frameworkComponents },
  {
    setItemAfterProgress,
    updateDays_From_SubPLI_to_PLI,
    subPliCellValueChanged,
    typeOfWork,
    editMode,
    isDarkMode,
  }
) => ({
  refreshStrategy: "rows",
  getDetailRowData: (params) => {
    return params.successCallback(params?.data?.subPli);
  },
  detailGridOptions: {
    onCellValueChanged: (params) =>
      subPliCellValueChanged(params, serviceId, elevationIndex),
    frameworkComponents: frameworkComponents,
    context: { isDarkMode },
    enableCellChangeFlash: true,
    rowHeight: 42,
    detailRowAutoHeight: true,
    columnDefs: createSubPLIColumns(
      { serviceId, elevationIndex, label, elevationLabel, isWritable },
      { scheduleDays, typeOfWorkObject },
      {
        setItemAfterProgress,
        updateDays_From_SubPLI_to_PLI,
        typeOfWork,
        editMode,
      }
    ),
    defaultColDef: {
      resizable: true,
      enablePivot: true,
      enableColResize: true,
      enableRowGroup: true,
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
    },
  },
});

export const createSubPLIColumns = (
  { serviceId, elevationIndex, label, elevationLabel, isWritable },
  { scheduleDays, typeOfWorkObject },
  { setItemAfterProgress, updateDays_From_SubPLI_to_PLI, typeOfWork, editMode }
) => {
  let columns = [];

  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
  const d1Header = getDimensionHeader(d1),
    d2Header = getDimensionHeader(d2);

  columns.push({
    headerName: "ID",
    field: "id",
    width: 50,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });

  columns.push({
    headerName: "Days",
    field: "days",

    width: "100%",
    cellStyle: {
      paddingLeft: 0,
      width: 340,
    },
    cellRenderer: "DaysInScheduleMaster",
    cellRendererParams: {
      scheduleDays,
      origin: "subPLI",
      updateDays_From_SubPLI_to_PLI,
      serviceId,
      elevationIndex,
      isWritable,
    },
  });

  columns.push({
    headerName: d1Header,
    field: d1,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    editable: true,
    width: 80,
  });
  if (d2) {
    columns.push({
      headerName: d2Header,
      field: d2,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      editable: true,
      cellClassRules: editableProgressCell({}),
      width: 80,
    });
    columns.push({
      headerName: "Sqf.",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      headerTooltip: "Surface to be scheduled",
      field: "surface",
      width: 80,
    });
  }

  columns.push({
    headerName: "Progress",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "progressByDay",
    cellRenderer: "PliProgressModal",
    minWidth: 200,
    headerClass: "progress-group",
    cellRendererParams: {
      serviceId,
      elevationIndex,
      scheduleDays,
      label,
      elevationLabel,
      type: PROGRESS_ORIGIN.SUB_PLI,
      origin: BREAKDOWN_RENDER_ORIGIN.PLI,
      setItemAfterProgress,
      typeOfWorkObject,
      typeOfWork,
      editMode,
      isWritable,
    },
  });

  if (d2 === undefined) {
    columns.push({
      headerName: "Total Progress " + d1Header,
      field: "totalProgress",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      headerClass: "progress-group",
      width: 120,
    });
  }
  // } else {
  //   columns.push({
  //     headerName: "Sqf.",
  //     field: "totalProgress",
  //     cellStyle: {
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center",
  //     },
  //     headerClass: "progress-group",
  //     width: 120,
  //   });
  // }

  // columns.push({
  //   headerName: "Status",
  //   // cellClassRules: progressStatusCellClassRules(),
  //   width: 140,
  //   cellStyle: {
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   },
  //   headerClass: "progress-group",
  //   cellRendererFramework: ({ data }) => {
  //     const checkSerId = d2 === undefined ? data[d1] : data[d1] * data[d2];
  //     if (data?.totalProgress === checkSerId) {
  //       return <div style={{ background: "green" }}>Done</div>;
  //     } else if (data?.totalProgress === 0) {
  //       return <div style={{ background: "red", width: 140 }}>Not Started</div>;
  //     } else if (data?.totalProgress < checkSerId) {
  //       return <div className="status-progress">In Progress</div>;
  //     } else if (data?.totalProgress > checkSerId) {
  //       return <div className="status-progress">Overdue</div>;
  //     } else {
  //       return <div style={{ background: "red", width: 140 }}>Not Started</div>;
  //     }
  //   },
  // });

  columns.push({
    headerName: "Remaining",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerTooltip: "Remaining units from this item",
    headerClass: "remaining-group",
    field: "remaining",
    valueGetter: (params) => {
      return d2 === undefined
        ? params?.data?.[d1] - params?.data?.totalProgress
        : params?.data?.[d1] * params?.data?.[d2] - params?.data?.totalProgress;
    },
    width: 120,
  });
  columns.push({
    headerName: "Rem. %",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerTooltip: "Remaining from this item in percent",
    headerClass: "remaining-group",
    field: "remainingPercentage",
    valueGetter: (params) => {
      return remainingPercentage_valueGetter(params, { d1, d2 });
    },
    cellRenderer: (params) => {
      return percentageCellRender(params);
    },
    width: 100,
    // cellClassRules: remainingPercentageStyle,
  });
  return columns;
};

export const scheduleAddonsColumns = (
  pricing,
  serviceId,
  addonType,
  typeOfWorkObject,
  scheduleDays
) => {
  // console.log(typeOfWorkObject)
  if (pricing === undefined) {
    // console.warning("getAddonsColumns: pricingData === undefined");
    return [];
  }

  const correctPricingObject = pricing?.find(
    (p) => p?.serviceId?.toString() === serviceId?.toString()
  )?.pricingObject;
  if (correctPricingObject) {
    let columns = [];
    columns.push({
      headerName: "ID",
      field: "id",
      width: 45,
      cellClass: "id-column",
      headerClass: "forschedule-group",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    });
    columns.push({
      headerName: "Name",
      field: "name",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      width: 120,
      headerClass: "forschedule-group",
      valueGetter: (params) => {
        return params?.data && getAddonName(params?.data);
      },
    });

    columns.push({
      headerName: "To schedule",
      headerTooltip: "To schedule",
      field: "addon_progress_dimension",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      headerClass: "forschedule-group",
      width: 100,
      cellRenderer: "PliHistoryPopover",
      cellRendererParams: {
        d1: "addon_progress_dimension",
        d2: undefined,
        typeOfWorkObject,
      },
    });

    columns.push({
      headerName: "Days in Schedule",
      supressSizeToFit: true,
      field: "days",
      cellRenderer: "DaysInScheduleMaster",
      headerClass: "forschedule-group",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    });

    columns.push({
      headerName: "Progress",
      headerTooltip: "Progress of this pli in all days",
      field: "progressByDay",
      cellRenderer: "AddonProgressModal",
      headerClass: "progress-group",
      width: 160,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    });
    columns.push({
      headerName: "Total Progress",
      headerTooltip: "Total progress of this pli in all days",
      field: "totalProgress",
      headerClass: "progress-group",
      width: 100,
      cellStyle: { color: "#fff" },
      cellClassRules: totalProgressStyle_byDimension({
        d1: "addon_progress_dimension",
      }),
    });

    for (const cd of correctPricingObject?.addonsColumnDefs) {
      //   console.log(cd)
      if (cd?.addonType === addonType) {
        if (["name", "price", "ppu", "rent"]?.includes(cd?.name?.toLowerCase()))
          continue; //has been added before
        columns.push({
          headerName: cd?.name.toUpperCase(),
          field: cd?.name?.toLowerCase(),
          width: 80,
          headerClass: "from-estimation-group",
        });
      }
    }

    // console.log(correctPricingObject.addonsColumnDefs)

    return columns;
  }
};

export const getScheduleServiceAddonsColumns = (
  service,
  { scheduleDays, pricing, units, typeOfWorkObject },
  {
    concatDays_From_PLI_ToElevation,
    setItemAfterProgress,
    updateDays_from_serviceAddons,
  }
) => {
  const serviceId = service?.serviceId;
  //     console.log(scheduleDays)

  try {
    if (units !== undefined) {
      let columns = scheduleAddonsColumns(
        pricing,
        serviceId,
        AddonType.serviceAddon,
        typeOfWorkObject
      );

      let DaysInScheduleMasterCol = columns?.find(
        (c) => c?.cellRenderer === "DaysInScheduleMaster"
      );
      if (DaysInScheduleMasterCol)
        DaysInScheduleMasterCol.cellRendererParams = {
          scheduleDays,
          serviceId,
          updateDays_from_serviceAddons,
          origin: PROGRESS_ORIGIN.SERVICE_ADDON,
        };

      let AddonProgressModalCol = columns?.find(
        (c) => c?.cellRenderer === "AddonProgressModal"
      );
      if (AddonProgressModalCol)
        AddonProgressModalCol.cellRendererParams = {
          serviceId,
          type: PROGRESS_ORIGIN.SERVICE_ADDON,
          setItemAfterProgress,
          typeOfWorkObject,
          scheduleDays,
        };

      return columns;
    }
  } catch (e) {
    console.error(e);
  }
};
