import { Alert } from "antd";
import React, { useCallback, useState } from "react";
import { useMemo } from "react";
import { CheckIconModal } from "../../../../../../../../../../../assets";
import { filterTables } from "../../../../../../../../../../../utils";
import { MondayButton } from "../../../../../../../../../../commonComponents";
import { dayjsNY } from "../../../../../../../../../../DateComponents/contants/DayjsNY";
import { useRedux } from "../../../../../../../../../hooks";
import { getWeeksDiff } from "../RentalBody/AgGrid/columnDefs";
import { multiHeaderDateInputHandler } from "../RentalHeader/Components/HeaderDateInput/HeaderDateInputFunctions";

function RentalHelper() {
  const [selectedService] = useRedux("rentalsSelectedService");
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [infoNotes, setInfoNotes] = useState({
    text: "Hold on, fetching information from Schedule...",
    value: null,
  });
  const [, setUnchangingFakeRentals] = useRedux("unchangingFakeRentals");
  const [rentalFromSchedule, setRentalFromSchedule] =
    useRedux("rentalFromSchedule");
  const [noWeeksIndic, setNoOfWeeksIndic] = useRedux("noWeeksIndic");

  const fetchProgressItems = useCallback(async () => {
    try {
      if (!fakeRentals?.projectId) return;

      const allItems = await filterTables(
        "progressItems",
        "projectId",
        fakeRentals.projectId
      );

      // Filter items based on criteria
      const filteredItems = allItems.filter(
        ({
          elevationId,
          serviceName,
          estimationId,
          pliProgressPercentage = 0,
        }) =>
          serviceName === selectedService &&
          estimationId === fakeRentals?.estimationId &&
          pliProgressPercentage > 0
      );

      // Sort filtered items by startDate
      const sortedByStartDate = filteredItems.sort(
        (a, b) => dayjsNY(a?.startDate) - dayjsNY(b?.startDate)
      );

      // Find the earliest startDate
      const earliestStartDate = sortedByStartDate?.[0]?.startDate;
      if (!earliestStartDate) return null;

      // Further filter items with the same startDate
      const sameStartDateItems = sortedByStartDate.filter(
        (item) => item.startDate === earliestStartDate
      );

      // Sort these items by progressTimeGiven
      const sortedByProgressTime = sameStartDateItems.sort(
        (a, b) => b.progressTimeGiven - a.progressTimeGiven
      );

      // Return the first item from the final sorted list
      const result = sortedByProgressTime?.[0];
      console.log("Filtered Progress Items:", result);
      return result;
    } catch (error) {
      console.error("Error fetching progress items:", error);
    }
  }, [fakeRentals?.projectId, selectedService, fakeRentals?.estimationId]);

  const selectedServiceData = useMemo(async () => {
    const service = fakeRentals?.services?.find(
      ({ label = "" }) => label === selectedService
    );
    let firstDay = await fetchProgressItems();
    let numberOfWeeksPastInst = service?.rentalPaymentTerms?.range || 0;

    console.log("First Day:", firstDay);
    let newInstallationDate = dayjsNY(firstDay?.startDate)
      .add(numberOfWeeksPastInst, "week")
      .valueOf();
    if (firstDay) {
      setInfoNotes({
        text: `The first day of ${selectedService} is ${dayjsNY(
          firstDay?.startDate
        ).format("MM/DD/YYYY")} and the installation date is ${dayjsNY(
          newInstallationDate
        ).format(
          "MM/DD/YYYY"
        )}, based on the progress items and rental payment terms. ${
          service?.rentalPaymentTerms?.range || "N/A"
        } weeks after the first day is ${dayjsNY(newInstallationDate).format(
          "MM/DD/YYYY"
        )}.`,
        value: newInstallationDate,
      });
    } else {
      setInfoNotes({
        text: "No progress items found for this service.",
        value: null,
      });
    }
    return service;
  }, [selectedService, fakeRentals?.services, fetchProgressItems]);

  const onApply = useCallback(() => {
    const service = fakeRentals?.services?.find(
      ({ label = "" }) => label === selectedService
    );
    multiHeaderDateInputHandler({
      setUnchangingFakeRentals,
      selectedService: selectedService,
      setFakeRentals,
      elevationData: service?.serviceOptions,
      fakeRentals,
      date: [
        dayjsNY(infoNotes.value).valueOf(),
        dayjsNY(infoNotes.value).add(27, "days").valueOf(),
      ],
      fromSchedule: rentalFromSchedule,
    });

    // setNoOfWeeksIndic({
    //   [selectedService]: {
    //     interval: [
    //       dayjsNY(infoNotes.value).valueOf(),
    //       dayjsNY(infoNotes.value).add(28, "days").valueOf(),
    //     ],
    //     value: getWeeksDiff(
    //       dayjsNY(infoNotes.value).add(28, "days").valueOf(),
    //       dayjsNY(infoNotes.value).valueOf()
    //     ),
    //   },
    // });
    console.log("Selected Service Data:", noWeeksIndic);
  }, [selectedServiceData, selectedService, infoNotes.value]);

  return (
    <div
      style={{
        padding: "0 0 10px 0",
      }}
    >
      {" "}
      <Alert
        message={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {infoNotes.text}{" "}
            {infoNotes.value && (
              <MondayButton Icon={<CheckIconModal />} onClick={onApply}>
                Apply Suggestion
              </MondayButton>
            )}
          </div>
        }
        type="info"
        showIcon
      />
    </div>
  );
}

export default RentalHelper;
