import { Input } from "antd";
import { useMemo, useState } from "react";
import RouteCard from "./RouteCard";

const RolePermissions = ({
  selectedTab,
  accessConfiguration,
  setAccessConfiguration,
  searchRouteConfigs = "",
  disabled = false,
}) => {
  const roleData = useMemo(
    () => accessConfiguration[selectedTab],
    [accessConfiguration, selectedTab]
  );

  const routeConfigs = useMemo(
    () =>
      (roleData || []).map((route, key) => {
        const { children, ...rest } = route;
        return {
          ...rest,
          title: route.title,
          key: key.toString(),
          read: route?.read,
          write: route?.write,
          delete: route?.data?.delete,
          checked: false,
          ...(children && {
            children,
          }),
        };
      }),
    [roleData]
  );

  const filteredRouteConfigs = routeConfigs.filter((route) =>
    findChildren(route, searchRouteConfigs)
  );
  function findChildren(route, searchRouteConfigs) {
    if (route.title.toLowerCase().includes(searchRouteConfigs.toLowerCase())) {
      return true;
    }
    if (route.children) {
      return route.children.some((child) =>
        findChildren(child, searchRouteConfigs)
      );
    }
    return false;
  }

  return (
    <div className="collapsedContainer-body">
      <div className="configBody" id="configBody">
        {filteredRouteConfigs.map((route) => (
          <RouteCard
            key={route.key}
            {...{
              route,
              selectedTab,
              accessConfiguration,
              setAccessConfiguration,
              searchRouteConfigs,
              disabled,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default RolePermissions;
