import { Form, Input } from "antd";
import "./OptionsFormList.scss";
import { Close } from "../../../../../Roles/src";
import { Plus } from "../../../../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons/index";
import { MondayButton } from "src/components/commonComponents";

const OptionsFormList = ({ form, formItemName = "fieldOptions" }) => {
  const isDarkMode = false;

  return (
    <Form form={form}>
      <div className="docOptionsContainer">
        <Form.List name={formItemName} initialValue={[""]}>
          {(fields, { add, remove }, { errors }) => (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <MondayButton Icon={<Plus />} onClick={() => add()}>
                  Add Option
                </MondayButton>
              </div>

              {fields.map((field, index) => (
                <div key={field.key} className="optionField">
                  <Form.Item
                    {...field}
                    label={`Option ${index + 1}`}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message:
                          "Please input option value or delete this field.",
                      },
                    ]}
                    className={`labeledInputContainer ${
                      isDarkMode ? "dark-mode" : ""
                    }`.trim()}
                  >
                    <Input
                      placeholder="Type one here..."
                      onPressEnter={() => add()}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  {fields.length > 1 && (
                    <span
                      onClick={() => remove(field.name)}
                      className="fieldOptionButton added"
                    >
                      <Close className="closeSvgIcon" />
                    </span>
                  )}
                </div>
              ))}

              {errors && <div style={{ color: "red" }}>{errors}</div>}
            </>
          )}
        </Form.List>
      </div>
    </Form>
  );
};

export default OptionsFormList;
