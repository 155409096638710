import { Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { DropDownArrow } from "../../../../../../SidebarPages/Fleet/components/InputComponent/assets";
import { API } from "aws-amplify";

const AddonsForHoist = (props) => {
  const {
    data = {},
    serviceId,
    elevationIndex,
    rowIndex,
    updateAddonsForFloors = () => {},
  } = props;
  const { originalPli = {} } = data || {};
  const { addons = [] } = originalPli || {};
  const [pricing, setPricing] = useState([]);

  const addonsData = addons?.map((addon) => {
    return {
      value: addon?.id,
      label: addon?.addonname || addon?.name,
    };
  });

  const addonsOptions = pricing
    ?.find((p) => p?.serviceId?.toString() === serviceId.toString())
    ?.pricingObject?.addonsList?.filter((type) => type?.addonType === "pli");

  // useEffect(() => {
  //   API.get("pricing", "/pricing").then(setPricing).catch(console.log);
  // }, []);

  console.log("pricing", {
    pricing,
    props,
    addonsOptions,
    addonsData,
    pricing,
  });

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Select
        value={addonsData || []}
        placeholder="Addons for this floor"
        mode="multiple"
        onChange={() => message.info("You can't change addons")}
        // onSelect={(value) => {
        //   updateAddonsForFloors(
        //     serviceId,
        //     elevationIndex,
        //     rowIndex,
        //     value,
        //     "add",
        //     addonsOptions
        //   );
        // }}
        // onDeselect={(value) =>
        //   updateAddonsForFloors(
        //     serviceId,
        //     elevationIndex,
        //     rowIndex,
        //     value,
        //     "remove",
        //     []
        //   )
        // }
        style={{
          border: "none",
          overflow: "auto",
          height: "100%",
          minWidth: 250,
        }}
        className="days-in-schedule-cell-select"
        suffixIcon={<DropDownArrow />}
      >
        {/* {addonsOptions?.map((addong) => {
          return (
            <Option value={addong?.id} key={addong?.id}>
              {addong?.addonname || addong?.name}
            </Option>
          );
        })} */}
      </Select>
    </div>
  );
};

export default AddonsForHoist;
