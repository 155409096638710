import {
  CalendarIcon,
  ClockIcon,
  Add,
  ClearIcon,
} from "../../FilterView/FilterIcons";
import { nameHandler } from "../../../../Dashboard/components/Entity/EntityData";
import { StatusFilterFunctions } from "./progressData";
import { Menu, Dropdown, Button, Slider } from "antd";
import { useState, useEffect, useMemo } from "react";
import { uniqWith, isEqual } from "lodash";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import {
  datePickerProgressHandler,
  dateRangeDateProgressHandler,
  filterByAddresHandler,
  filterByProjectManagerHandler,
  filterByStageHandler,
  filterOptions,
  getDashboardRangePresets,
  handlerAddFilter,
  handlerRemoveFilter,
  pmTables,
} from "../filtersData";
import { DropdownIcon } from "../../../../BasePage/src";
import { useSelector } from "react-redux";
import { compareObjects } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { getRangePresets } from "src/components/SidebarPages/Fleet/components/InputComponent/utils";
import ProjectManagerFilter from "../ProjectManagerFilter";
import StatusFilter from "../StatusFilter";
import AddressFilter from "../AddressFilter";
import PercentageFilter from "../PercentageFilter";

const Progress = ({
  params,
  id,
  dynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  setRowData,
  programFields,
  filterStates,
  setFilterStates,
  projectsData,
  clearFilterChart,
  tmpFilterStates,
  defaultFilterStates,
}) => {
  const url = window.location.pathname.split("/")[1];

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [filterByName, setfilterByName] = useState(true);
  const [timePicket, setTimePicket] = useState("Pick range");
  //with this const we check if the second field is a timestamp so we can handle it
  const filterByDate = [
    "registrationExpDate",
    "inspectionExpDate",
    "issue_date",
    "createdAt",
  ];

  //added filter options
  const table = params?.table;
  const fields = params?.field;
  const secondaryField = params?.secondaryField;
  const filterValueTurnery = compareObjects(
    tmpFilterStates,
    defaultFilterStates
  );

  const nameFilterCondition = () => {
    return (
      nameHandler[fields] !== "Geofence" &&
      fields !== "engProgress" &&
      fields !== "archProgress"
    );
  };

  const rangeToDisplay = useMemo(() => {
    if (filterValueTurnery) {
      if (Array.isArray(filterStates?.filterByRange)) {
        return [
          dayjs(filterStates?.filterByRange?.[0]),
          dayjs(filterStates?.filterByRange?.[1]),
        ];
      }

      return undefined;
    } else {
      if (Array.isArray(tmpFilterStates?.filterByRange)) {
        return [
          dayjs(tmpFilterStates?.filterByRange?.[0]),
          dayjs(tmpFilterStates?.filterByRange?.[1]),
        ];
      }

      return undefined;
    }
  }, [
    filterStates?.filterByRange,
    tmpFilterStates?.filterByRange,
    filterValueTurnery,
  ]);

  return (
    <>
      <div className="filterComponents">
        <div className="tittle">Filter date</div>
        <div className="filterContainer">
          <div className="leftSideContainer">
            <div className="tittleContainer">Filter time</div>
            <div className="tittleContainer">Filter range</div>
            <div className="tittleContainer">Filter date</div>
          </div>
          <div className="rightSideContainer">
            <Dropdown
              menu={{
                style: { borderRadius: "10px" },
                items: filterOptions(
                  setUpdatedDynamicStates,
                  updatedDynamicStates,
                  id,
                  dynamicStates,
                  setFilterStates,
                  table
                ).map((a, index) => ({
                  key: index,
                  label: a?.value,
                  onClick: () => {
                    a?.filter();
                    a?.buttonName();
                  },
                })),
              }}
              placement="bottomCenter"
              suffixIcon={<CalendarIcon />}
              className="filter-dropdown"
              arrow
            >
              <Button>
                {filterValueTurnery
                  ? filterStates?.filterByTime
                  : tmpFilterStates?.filterByTime || "Pick Range"}
                <ClockIcon />
              </Button>
            </Dropdown>

            <DatePicker.RangePicker
              // value={
              //   filterValueTurnery
              //     ? filterStates?.filterByRange
              //     : tmpFilterStates?.filterByRange
              // }
              value={rangeToDisplay}
              allowClear={false}
              format="MM/DD/YYYY"
              suffixIcon={<CalendarIcon />}
              presets={
                url === "dashboard"
                  ? getDashboardRangePresets()
                  : getRangePresets()
              }
              onChange={(e) =>
                dateRangeDateProgressHandler(
                  e,
                  setUpdatedDynamicStates,
                  updatedDynamicStates,
                  dynamicStates,
                  id,
                  setFilterStates
                )
              }
              disabledDate={(current) => {
                return current > dayjs().endOf("day");
              }}
              className="filter-by-range-picker"
              popupClassName={`filter-by-date-picker-dropdown ${
                isDarkMode && "dateDropDownDark"
              }`}
            />

            <DatePicker
              value={
                filterValueTurnery
                  ? filterStates?.filterByDay
                  : tmpFilterStates?.filterByDay
              }
              allowClear={false}
              format="MM/DD/YYYY"
              suffixIcon={<CalendarIcon />}
              onChange={(e) =>
                datePickerProgressHandler(
                  e,
                  setUpdatedDynamicStates,
                  updatedDynamicStates,
                  dynamicStates,
                  id,
                  setFilterStates
                )
              }
              disabledDate={(current) => {
                return current > dayjs().endOf("day");
              }}
              className="filter-by-date-picker"
              popupClassName={`filter-by-date-picker-dropdown ${
                isDarkMode && "dateDropDownDark"
              }`}
            />
          </div>
        </div>
      </div>

      {/* =====================================filter by name part===================================================== */}

      {/* <div className="filterComponents">
        <div className="tittle">Filter by name</div>
        <div className="filterContainer">
          <div className="leftSideContainer">
            <div className="tittleContainer">Filter by name</div>
            {nameHandler[fields] !== "Geofence" && (
              <div className="tittleContainer">
                Filter by {nameHandler[fields]}
              </div>
            )}
          </div>
          <div className="rightSideContainer">
            <Select
              popupClassName="dashDropDown"
              placeholder="Pick option"
              suffixIcon={<DropdownIcon />}
              onSelect={(e) =>
                filterByAddresHandler(
                  e,
                  setUpdatedDynamicStates,
                  id,
                  secondaryField,
                  dynamicStates,
                  setFilterStates
                )
              }
              showSearch
              className="status-selector"
              value={
                filterValueTurnery
                  ? filterStates?.filterByName
                  : tmpFilterStates?.filterByName
              }
              options={uniqWith(
                dynamicStates[id]?.map((a, index) => ({
                  label: a[secondaryField],
                  value: a[secondaryField],
                  key: index,
                })),
                isEqual
              )?.filter(Boolean)}
            />
            {nameFilterCondition() ? (
              <Select
                popupClassName="dashDropDown"
                suffixIcon={<DropdownIcon />}
                onSelect={(e) =>
                  filterByStageHandler(
                    e,
                    setUpdatedDynamicStates,
                    id,
                    fields,
                    dynamicStates,
                    setFilterStates
                  )
                }
                value={
                  filterValueTurnery
                    ? filterStates?.filterByStatus
                    : tmpFilterStates?.filterByStatus
                }
                showSearch
                placeholder={timePicket}
                className="status-selector"
                options={uniqWith(
                  (dynamicStates[id] || [])
                    .map((a) => {
                      const fieldValue = a[fields];
                      if (typeof fieldValue === "object") {
                        return {
                          label: fieldValue.length.toString(),
                          value: fieldValue.length.toString(),
                        };
                      } else if (fieldValue !== "") {
                        return {
                          label: filterByDate.find((a) => a === fields)
                            ? dayjs(fieldValue).format("MM/DD/YYYY")
                            : fieldValue,
                          value: fieldValue,
                        };
                      }
                      return null;
                    })
                    .filter(Boolean),
                  isEqual
                )}
              />
            ) : (
              !nameFilterCondition() &&
              nameHandler[fields] !== "Geofence" && (
                <Slider
                  {...{
                    defaultValue:
                      parseInt(
                        filterStates?.filterByStatus?.replace("%", "")
                      ) || 0,
                    tooltip: {
                      visible: true,
                      formatter: (e) => `${e}%`,
                      open: true,
                      placement: "bottom",
                    },
                    step: 5,
                    onChangeComplete: (e) => {
                      setFilterStates({
                        ...filterStates,
                        filterByStatus: `${e || 0}%`,
                        filterByTime: "Pick Range",
                      });

                      return setUpdatedDynamicStates((prev) => ({
                        ...prev,
                        [id]: dynamicStates[id].filter((a) => {
                          return a?.[fields] === e;
                        }),
                      }));
                    },
                  }}
                />
              )
            )}
          </div>
        </div>
      </div> */}

      {nameHandler[fields] !== "Geofence" && (
        <AddressFilter
          {...{
            id,
            table,
            filterStates,
            tmpFilterStates,
            dynamicStates,
            filterValueTurnery,
            setFilterStates,
          }}
        />
      )}

      {!nameFilterCondition() &&
        nameHandler[fields] !== "Geofence" &&
        (fields === "engProgress" || fields === "archProgress") && (
          <PercentageFilter
            {...{
              fields,
              filterStates,
              tmpFilterStates,
              filterValueTurnery,
              setFilterStates,
            }}
          />
        )}

      {pmTables?.[table] ? (
        <ProjectManagerFilter
          {...{
            id,
            table,
            filterStates,
            dynamicStates,
            tmpFilterStates,
            filterValueTurnery,
            setFilterStates,
            setUpdatedDynamicStates,
          }}
        />
      ) : null}

      <StatusFilter
        {...{
          table,
          filterStates,
          tmpFilterStates,
          filterValueTurnery,
          setFilterStates,
        }}
      />
    </>
  );
};

export default Progress;
