import { Button, Input, Modal, Tooltip, message } from "antd";
import { formatFullDate } from "../helpers/creators";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import ScheduleDayNote, {
  ScheduleDayNoteType,
} from "../models/ScheduleDayNote";
import { NotesIcon } from "../../../../SidebarPages/DynamicView/src";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import { MondayButton } from "../../../../commonComponents";
import { Tick } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { useRedux } from "../../../../SidebarPages/hooks/useRedux";
import { updateDocumentTitle } from "../../../../../utils";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { API } from "aws-amplify";

const styledBtn = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 25,
  height: 25,
  background: "#1264a3",
  borderRadius: 5,
  color: "#fff",
  border: "none",
  padding: 4,
};

//button and modal of notes of a schedule day
export const ScheduleDayNotesModal = ({
  scheduleDay,
  notesToAddForScheduleDays,
  updateNotesToAddForScheduleDays,
  editMode,
  setAbleToSave,
  calendarView = false,
  calendarData = {},
  params,
}) => {
  const [noteToAdd, set_noteToAdd] = useState(
    notesToAddForScheduleDays[scheduleDay?.id]?.text || ""
  );
  const [{ authenticatedUser }, { isDarkMode }] = useSelector((state) => [
    state,
    state.darkMode,
  ]);
  const [open, set_open] = useState(false);
  const [isWritable] = useRedux("isWritableScheduling");

  const onConfirm = async () => {
    // if (!noteToAdd) return message.error("Please enter a note");

    const notesToAddForScheduleDays_COPY = _.cloneDeep(
      notesToAddForScheduleDays
    );
    notesToAddForScheduleDays_COPY[scheduleDay?.id] = new ScheduleDayNote(
      noteToAdd,
      ScheduleDayNoteType.General
    );
    notesToAddForScheduleDays_COPY[scheduleDay?.id].createdBy =
      authenticatedUser.authenticatedUser.given_name.concat(
        " ",
        authenticatedUser.authenticatedUser.family_name
      );
    if (!calendarView) {
      updateNotesToAddForScheduleDays(notesToAddForScheduleDays_COPY);
    }

    if (calendarView) {
      const scheduleDayCopy = _.cloneDeep(scheduleDay);
      scheduleDayCopy.notes.push({
        ...new ScheduleDayNote(noteToAdd, ScheduleDayNoteType.General),
        createdBy: authenticatedUser.authenticatedUser.given_name.concat(
          " ",
          authenticatedUser.authenticatedUser.family_name
        ),
      });
      await API.put("scheduling", `/scheduling/${calendarData?.refId}`, {
        body: {
          scheduleDays: calendarData?.scheduleDays?.map((day) => {
            if (day?.id === scheduleDayCopy?.id) {
              return scheduleDayCopy;
            }
            return day;
          }),
        },
      })
        .then((res) => {
          params.node.setData({
            ...params?.data,
            notes: scheduleDayCopy.notes,
          });
          // message.success("Note added successfully!");
        })
        .catch((err) => {
          message.error("Failed to add note");
        });
    }
    updateDocumentTitle(); // reset document title to "Lead Manager"
    set_open(false);
    setAbleToSave(true);
    message.success("Note added successfully!");
  };

  const onCancel = () => {
    set_noteToAdd(notesToAddForScheduleDays[scheduleDay?.id]?.text || "");
    updateDocumentTitle(); // reset document title to "Lead Manager"
    set_open(false);
  };

  useEffect(() => {
    set_noteToAdd(notesToAddForScheduleDays?.[scheduleDay?.id]?.text || "");
  }, [notesToAddForScheduleDays]);

  return (
    <>
      <Tooltip title="Notes">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#fff",
            background: "#1264a3",
            width: 25,
            height: 25,
            borderRadius: 5,
          }}
        >
          <Button
            onClick={() => set_open(true)}
            className={"change-day-status-button"}
            style={styledBtn}
            icon={<NotesIcon />}
          ></Button>
        </div>
      </Tooltip>
      {open && (
        <div style={{ borderRadius: 10 }}>
          <Modal
            centered
            footer={null}
            title={
              <div>
                Change{" "}
                <b style={{ color: scheduleDay?.color }}>
                  Day {scheduleDay?.day}
                </b>{" "}
                note, <i>{formatFullDate(scheduleDay?.startDate)}</i>
              </div>
            }
            afterOpenChange={(event) => {
              event && updateDocumentTitle({ newTitle: "Schedule Day Notes" });
            }}
            open={open}
            className={`day-note-change-modal ${
              isDarkMode && "day-note-change-modal-dark"
            }`}
            width={511}
            onCancel={onCancel}
            closeIcon={<XIcon />}
          >
            <>
              <div className={"notes-container"}>
                {(!calendarView ? scheduleDay : params?.data)?.notes?.map(
                  ({ createdDate, text, createdBy }) => {
                    return (
                      <div>
                        <p>
                          Created: {new Date(createdDate).toLocaleDateString()}{" "}
                          by {createdBy}
                        </p>
                        <p dangerouslySetInnerHTML={{ __html: text }} />
                      </div>
                    );
                  }
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.3rem",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: 22,
                      font: "normal normal 600 16px/22px Open Sans",
                      color: "#323338",
                    }}
                  >
                    General day note
                  </div>
                  <Input.TextArea
                    className="reason-note-change"
                    placeholder="General day note..."
                    value={noteToAdd}
                    onChange={(e) => set_noteToAdd(e.target.value)}
                    disabled={
                      !!calendarView ? false : editMode ? !isWritable : false
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "space-between",
                }}
              >
                <MondayButton
                  onClick={onCancel}
                  {...{ Icon: <XIcon />, className: "mondayButtonRed" }}
                >
                  Cancel
                </MondayButton>
                <MondayButton
                  onClick={onConfirm}
                  {...{ Icon: <Tick width={19} height={19} /> }}
                  disabled={
                    !!calendarView ? false : editMode ? !isWritable : false
                  }
                >
                  Submit
                </MondayButton>
              </div>
            </>
          </Modal>
        </div>
      )}
      {/* <Dialog
        maxWidth={"lg"}
        className={"day-status-change-modal"}
        open={open}
        onClose={onCancel}
      >
        <DialogTitle>
          Change{" "}
          <b style={{ color: scheduleDay.color }}>Day {scheduleDay.day}</b>{" "}
          status <i>{formatFullDate(scheduleDay.startDate)}</i>
        </DialogTitle>
        <DialogContent className={"day-status-change-modal-content"}>
          <div className={"notes-container"}>
            {scheduleDay.notes.map(
              ({ id, createdDate, text, mentionedPeople, type }) => {
                return (
                  <div>
                    <p>Created: {new Date(createdDate).toLocaleDateString()}</p>
                    <p dangerouslySetInnerHTML={{ __html: text }} />
                  </div>
                );
              }
            )}

            <InputComponent
              type="input"
              placeholder="General day note"
              value={noteToAdd}
              onChange={(e) => set_noteToAdd(e.target.value)}
            />
          </div>

          <DialogActions>
            <Button color={"secondary"} onClick={onCancel}>
              Cancel
            </Button>
            <Button color={"primary"} onClick={onConfirm}>
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog> */}
    </>
  );
};
