import { v4 } from "uuid";
import { DeleteElevationIcon } from "../../../../../../../assets";
import { MondayButton } from "../../../../../../commonComponents";
import { AddIcon } from "../../../../../../Header/components/GoogleEvents/assets";
import { TABLE_DATA_COLUMN_HOIST_CALCULATOR } from "./tableData";

export function getTableDataColumns({ removeRow, addRow }) {
  let afterColumns = [
    {
      headerName: "Action",
      field: "action",
      // minWidth: 140,
      editable: false,
      cellRenderer: (params) => {
        return (
          <div
            style={{
              display: "flex",
              gap: 10,
              justifyContent: "space-between",
            }}
          >
            <MondayButton
              // Icon={EditOutline}
              onClick={() => {
                params.api.applyTransaction({ remove: [params?.data] });
                removeRow(params?.data);
                // params.api.set
              }}
              hasIcon={false}
              className="mondayButtonRed"
              style={{ width: "100%" }}
            >
              <div
                style={{
                  marginTop: 5,
                }}
              >
                <DeleteElevationIcon />
              </div>
            </MondayButton>
            <MondayButton
              // Icon={EditOutline}
              hasIcon={false}
              className="mondayButtonGreen"
              style={{ width: "100%" }}
              onClick={() => {
                let rowToAdd = addCustomRow(params);
                // params.api.applyTransaction({ add: [rowToAdd] });
                let newIndex = params.node.rowIndex + 1; // Insert below the current row

                addRow(rowToAdd, newIndex);
                // let rowToAdd = addCustomRow(params);
                // params.api.applyTransaction({
                //   add: [rowToAdd],
                //   addIndex: 2,
                // });
                // addRow(rowToAdd);
              }}
            >
              <AddIcon />
            </MondayButton>
          </div>
        );
      },
    },
  ];
  let table = TABLE_DATA_COLUMN_HOIST_CALCULATOR;
  return [...afterColumns, ...table];
}
function addCustomRow(params) {
  console.log("addCustomRow", params);
  let lastIdx = params.api.getDisplayedRowCount() - 1;
  let lastRow = params.api.getDisplayedRowAtIndex(lastIdx);
  let newRow = structuredClone({ ...lastRow.data });
  newRow.name = "Custom";
  newRow.entityValue = "Custom";
  newRow.ppu = 0;
  newRow.price = 0;
  newRow.rent = 0;
  newRow.taxRate = 0;
  newRow.tax = 0;
  newRow.totalPrice = 0;
  newRow.note = "";
  newRow.units = 1;
  newRow.id = v4();
  newRow.field = "customField_" + newRow.id;
  newRow.isAddon = false;
  newRow.rentMonthly = 0;
  return newRow;
}
