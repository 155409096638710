import { HistoryOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import { Alert, message, Modal } from "antd";
import React, { useMemo, useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { CheckIconModal, CloseIconModal } from "../../../../../../../assets";
import { MondayButton } from "../../../../../../commonComponents";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { SaveIcon } from "../../../../../BasePage/src";
import { useRedux } from "../../../../../hooks";
import CustomFooter from "../../../../../Projects/Accounting/Applications/ApplicationsTab/ListOfApplications/GridList/CustomFooter";
import { FloorDetailsProvider } from "../../HoistPriceCalculatorModal/components/FloorDetails/context/floorDetailsContext";
import FloorDetails from "../../HoistPriceCalculatorModal/components/FloorDetails/FloorDetails";
import TableHoistFloorAddons from "../TableHoistFloorAddons/TableHoistFloorAddons";
import {
  autoFillHoist,
  getPriceScheme,
  mappingOfKeysHoistTable,
} from "./referenceData";
import {
  ColDef,
  ColGroupDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDetailCellRendererParams,
  ModuleRegistry,
  createGrid,
} from "@ag-grid-community/core";
import {
  TABLE_DATA_COLUMN_HOIST_CALCULATOR,
  TABLE_DATA_HOIST_CALCULATOR,
} from "./tableData";
import { getTableDataColumns } from "./getTableDataColumns";
import "./TableHoistStyle.scss";
import { evaluateMathExpression } from "../../../tools/formatters";
// import { formatDimensions } from "../../../tools/exporters/retrieveDOCX";
function TableHoistCalcModal({
  visible,
  setVisible,
  indexes = {},
  service = {},
  updateStateAndSave,
}) {
  const [globalData, setGlobalData] = useState(TABLE_DATA_HOIST_CALCULATOR);
  // const [rowData, setRowData] = useState([]);
  const [priceScheme, setPriceScheme] = useState({});
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [forceReset, setForceReset] = useState(false);
  const [gridApi = {}, setGridApi] = useState({});
  // makes a copy of the original and merges in the new values
  const updateImmutableObject = (original, newValues) => {
    console.log("updateImmutableObject", { original, newValues });

    // Initialize newObject with original or as an empty object if original is null
    const newObject = original ? { ...original } : {};

    // Copy properties from newValues to newObject
    Object.keys(newValues).forEach((key) => {
      newObject[key] = newValues[key];
    });

    return newObject;
  };

  // Tax rate to use when applicable. Defaults to 0 if service is not taxable
  let TAX_RATE = service?.isTaxable ? forceToNumber(service?.taxRate) : 0;

  // When a cell value changes, update accordingly
  const onCellValueChanged = useCallback(
    (params) => {
      const { data = {}, colDef, newValue: originalValue } = params;
      const colField = colDef.field;
      const indexToUpdate = globalData.findIndex((el) => el.id === data.id);

      if (indexToUpdate === -1) {
        console.warn("No matching row found in globalData for update.");
        return;
      }

      console.log("indexToUpdate", { indexToUpdate });
      console.log("colField", colField);

      let newValue = originalValue;

      // Check if newValue is a formula (starts with '=')
      if (typeof newValue === "string" && newValue.startsWith("=")) {
        try {
          newValue = eval(newValue.slice(1)); // Evaluate the expression
        } catch (error) {
          console.error("Error evaluating formula:", error);
          return;
        }
      }

      let itemToUpdate = { ...globalData[indexToUpdate], [colField]: newValue };

      // Handle specific column updates
      switch (colField) {
        case "entityValue":
          if (newValue !== "Custom") {
            const values =
              priceScheme?.[mappingOfKeysHoistTable[data.name]]?.[newValue] ||
              {};
            itemToUpdate = { ...itemToUpdate, ...values };
          } else {
            const customValues =
              priceScheme?.[mappingOfKeysHoistTable[data.name]]?.[newValue];
            itemToUpdate = { ...itemToUpdate, ...customValues };
          }
          break;

        case "rentRate":
          itemToUpdate[colField] = forceToNumber(newValue / 100);
          break;

        case "rentRateAfter":
          itemToUpdate[colField] = forceToNumber(newValue / 100);
          break;

        case "rentMonthly":
          itemToUpdate[colField] = forceToNumber(newValue);
          break;

        case "rentalAfter":
          itemToUpdate[colField] = forceToNumber(newValue);
          break;

        default:
          // No special handling for other columns
          break;
      }

      // Recalculate totals
      const itemUpdated = updateImmutableObject(
        globalData[indexToUpdate],
        calculateTotalPrice(itemToUpdate)
      );

      console.log("itemUpdated", itemUpdated);

      // Apply transaction to update the grid
      params.api.applyTransaction({ update: [itemUpdated] });

      // Update state
      setGlobalData((prev) =>
        prev.map((row, idx) => (idx === indexToUpdate ? itemUpdated : row))
      );
    },
    [globalData, priceScheme]
  );

  // Changes the state of the data and saves it
  const updateStateData = useCallback(
    (toSet, isDraft) => {
      message.loading("Updating Price Scheme", 2);
      const temp = gridData;
      const serviceIndex = indexes.serviceIndex;
      const optionIndex = indexes.optionIndex;
      const elevationIndex = indexes.elevationIndex;
      const elevation =
        temp[serviceIndex].serviceOptions[optionIndex][elevationIndex];
      console.log("updateStateData", { service, elevation });
      // return;
      if (!isDraft) {
        let addonPrice = forceToNumber(
          elevation?.floorAddonsPriceConfig?.totalities?.priceWithoutTax
        );
        let addonRent = forceToNumber(
          elevation?.floorAddonsPriceConfig?.totalities?.rentWithoutTax
        );
        elevation.totalPrice =
          (toSet.TOTAL_PRICE_NoAddons + addonPrice) *
          (forceToNumber(TAX_RATE) + 1);
        elevation.rentalsTotal = toSet.TOTAL_RENT_NoAddons + addonRent;
        elevation.taxAmount =
          (elevation.totalPrice + addonPrice) * forceToNumber(TAX_RATE);
      } else {
        elevation.totalPrice = 0;
        elevation.rentalsTotal = 0;
        elevation.taxAmount = 0;
      }

      temp[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ].autoScheme = {
        // TABLE_DATA_COLUMN_HOIST_CALCULATOR: getTableDataColumns(),
        rowData: globalData,
        TOTALITIES: toSet,
        name: "Hoist Scheme",
        isDraft,
        id: 1,
      };

      console.log("updateStateData", {
        toSet,
        temp,
      });

      updateStateAndSave(structuredClone(temp));
      setVisible(false);
    },
    [globalData, gridData, indexes]
  );

  // Elevation Hoist data
  const elevationHoist = useMemo(() => {
    const options = service?.serviceOptions || [];
    return options[indexes.optionIndex]?.[indexes.elevationIndex] || null;
  }, [indexes?.optionIndex, indexes?.elevationIndex, service]);

  const getNewValues = useCallback(() => {
    // Generate hoist data
    const toSetData = autoFillHoist({
      hoistData: elevationHoist,
      savedData: globalData,
    });

    console.log("getNewValues", { toSetData });

    // Derive price scheme
    const priceScheme =
      getPriceScheme({ isDual: elevationHoist?.type === "dual" })
        ?.TABLE_HOIST_REFERENCE_DATA || {};

    // Update state
    setPriceScheme(priceScheme);
    setGlobalData(toSetData);
    // setRowData(toSetData); // Optionally redundant with setGlobalData
  }, [
    autoFillHoist,
    getPriceScheme,
    elevationHoist,
    // rowData,
    setPriceScheme,
    setGlobalData,
    // setRowData,
  ]);

  const retrieveHoistValues = useCallback(() => {
    console.log("elevationHoist", elevationHoist);

    // Initialize `toSetData` with auto-fill logic
    let toSetData = autoFillHoist({
      hoistData: elevationHoist,
      savedData: globalData,
    });

    console.log("getNewValues", { toSetData });

    // Retrieve price scheme
    const priceScheme =
      getPriceScheme({ isDual: elevationHoist?.type === "dual" })
        ?.TABLE_HOIST_REFERENCE_DATA || {};

    // Check for pre-existing data in `autoScheme` if no force reset
    if (elevationHoist?.autoScheme?.rowData && !forceReset) {
      toSetData = elevationHoist.autoScheme.rowData;
    }

    // Update state
    setPriceScheme(priceScheme);
    setGlobalData(toSetData);
    // setRowData(toSetData);
  }, [
    autoFillHoist,
    getPriceScheme,
    elevationHoist,
    forceReset,
    // rowData,
    setPriceScheme,
    setGlobalData,
    // setRowData,
  ]);

  const removeRow = useCallback((data) => {
    console.log("removeRow", data);
    // rowData.splice(index, 1);
    // setRowData((prev) => prev?.filter((el) => el.id !== data.id));
    setGlobalData((prev) => prev?.filter((el) => el.id !== data.id));
  }, []);

  const addRow = useCallback(
    (data, newIndex) => {
      console.log("addRow", { data, newIndex });

      // // Add data to rowData
      // setRowData((prevRowData) => {
      //   const updatedRowData = prevRowData ? [...prevRowData] : [];
      //   updatedRowData.splice(newIndex, 0, data);
      //   return updatedRowData;
      // });

      // Add data to globalData
      setGlobalData((prevGlobalData) => {
        const updatedGlobalData = prevGlobalData ? [...prevGlobalData] : [];
        updatedGlobalData.splice(newIndex, 0, data);
        return updatedGlobalData;
      });
    },
    [setGlobalData] // Ensure dependencies are included
  );

  const getRowPriceStyle = useCallback((params) => {
    // console.log("getRowPriceStyle", params?.data);
    if (params?.data?.name?.includes("Rent")) {
      return { background: "#DD761C" };
    } else if (forceToNumber(params?.data?.totalPrice) > 0) {
      return {
        background: params?.data?.isAddon === true ? "#000B58" : "#006769",
      };
    }
  }, []);

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          { field: "name", headerName: "Name", width: 200 },
          {
            field: "floor",
            headerName: "Floor",
            valueFormatter: (params) => {
              return params.value === -2
                ? "sub-cellar"
                : params.value === -1
                ? "cellar"
                : params.value;
            },
            width: 100,
          },
          { field: "dimensions", headerName: "Dimensions", width: 200 },
          // { field: "floorHeight", headerName: "Floor Height" },
          // { field: "note", headerName: "Note" },
          // { field: "description", headerName: "Description" },
        ],
        // defaultColDef: {
        //   flex: 1,
        // },
      },
      getDetailRowData: (params) => {
        // getAddonRows(params);
        params.successCallback(getAddonRows(params) || []);
      },
    };
  }, []);

  const columnDefs = useMemo(() => {
    return getTableDataColumns({ removeRow, addRow }) || [];
  }, [removeRow, addRow]);

  const getAddonRows = useCallback(
    (params) => {
      console.log("getAddonRows", params, elevationHoist);
      if (params?.data?.isAddon === true) {
        // let rows
        let addons = [];
        elevationHoist?.items?.forEach((floor) => {
          floor?.addons?.forEach((addon) => {
            if (addon?.addonname === params?.data?.name) {
              console.log("addonledjo", { addon, floor });
              addons.push({
                // ...addon,
                name: addon?.addonname,
                floor: floor?.floor,
                dimensions: formatDimensions(addon),
                note: addon?.note,
                description: addon?.description,
                floorHeight: forceToNumber(floor?.floor_height) + "ft",
              });
            }
          });
        });
        console.log("returnedAddons", addons);
        return addons;
      }
    },
    [elevationHoist]
  );

  const savePriceSchemeAsDraft = useCallback(
    (isTrue) => {
      let TOTALITIES = {
        TOTAL_PRICE_NoAddons: 0,
        TAX_AMOUNT_NoAddons: 0,
        TOTAL_RENT_NoAddons: 0,
        TAX_RATE_NoAddons: 0,
      };
      // let additionalRental = rowData.find(
      //   (el) => el?.name === "Additional Rental for Hoist"
      // )?.ppu;
      // if (additionalRental) {
      //   TOTALITIES.TOTAL_RENT += forceToNumber(additionalRental);
      // }

      globalData.forEach((row) => {
        TOTALITIES.TOTAL_PRICE_NoAddons += forceToNumber(row?.totalPrice);
        TOTALITIES.TAX_AMOUNT_NoAddons += forceToNumber(row?.tax);
        TOTALITIES.TOTAL_RENT_NoAddons += forceToNumber(row?.rentMonthly);
        TOTALITIES.TAX_RATE_NoAddons += forceToNumber(row?.taxRate);
      });

      updateStateData(TOTALITIES, isTrue);

      console.log("savePriceSchemeAsDraft", {
        globalData,
      });
    },
    [globalData]
  );

  const rowDataFooter = useMemo(() => {
    return (
      globalData?.map((el) => {
        const totalPrice = forceToNumber(el?.totalPrice);
        const taxRate = forceToNumber(TAX_RATE);
        const taxAmount = taxRate * totalPrice;
        const totalPriceWithTax = totalPrice + taxAmount;
        const freeMonths = forceToNumber(el?.freeMonths);
        const units = forceToNumber(el?.units);
        const rentMonthly = forceToNumber(el?.rentMonthly);
        let toReturn = {
          taxRate,
          taxAmount,
          totalPriceWithTax,
          freeMonths,
          rentMonthly,
        };

        if (el?.name === "Additional Rental for Hoist") {
          toReturn = {
            ...toReturn,
            chargedMonth: units,
            totalMonths: freeMonths + units,
            additionalRentSum: totalPrice,
            rentMonth: forceToNumber(el?.ppu),
            rentMonthly: forceToNumber(el?.ppu),
          };
        } else if (el?.name === "Rental for Hoist") {
          toReturn = {
            ...toReturn,
            chargedMonth: units,
            totalMonths: freeMonths + units,
            additionalRentSum: 0,
            rentMonth: 0,
          };
        } else {
          toReturn = {
            ...toReturn,
            totalMonths: 0,
            chargedMonth: 0,
            additionalRentSum: 0,
            rentMonth: 0,
          };
        }

        return toReturn;
      }) || []
    );
  }, [gridApi, globalData, setGlobalData]);

  useEffect(() => {
    retrieveHoistValues();
  }, []);

  return (
    <>
      {visible && (
        <Modal
          open={visible}
          title="Hoist AUTO Price Calculator"
          className="table_hoist_modal"
          // width={1500}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="footer_hoist_left"
                style={{ flex: 1, display: "flex", gap: "20px" }}
              >
                <MondayButton
                  className="mondayButtonRed"
                  onClick={() => setVisible(false)}
                  Icon={<CloseIconModal />}
                >
                  Discard
                </MondayButton>
                {/* <MondayButton
                  className="mondayButtonRed"
                  Icon={<HistoryOutlined />}
                >
                  Reset Price
                </MondayButton> */}
              </div>
              <div
                className="footer_hoist_left"
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <MondayButton
                  className="mondayButtonYellow"
                  Icon={<HistoryOutlined />}
                  onClick={() => savePriceSchemeAsDraft(true)}
                >
                  Save as Draft
                </MondayButton>{" "}
                <MondayButton
                  className="mondayButtonBlue"
                  Icon={<CheckIconModal />}
                  onClick={() => savePriceSchemeAsDraft(false)}
                >
                  Approve Price
                </MondayButton>
              </div>
            </div>
          }
          onCancel={() => setVisible(false)}
        >
          <Alert message="This is a Hoist AUTO Price Calculator. You can change the values in the table below to adjust the price scheme." />
          <div
            className="dark-ag-theme ag-theme-alpine-dark"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CustomFooter
              dataSrc={rowDataFooter}
              mathAction={"sum"}
              type={"base"}
              style={{
                padding: "5px",
                backgroundColor: "#153448",
                color: "#fff",
              }}
              labelsKeys={[
                {
                  key: "totalMonths",
                  name: "Total Rent Months",
                  type: "number",
                  mathAction: "sum",
                },
                {
                  key: "freeMonths",
                  name: "Free Months",
                  type: "number",
                  mathAction: "sum",
                },
                {
                  key: "rentMonthly",
                  name: "Rent [Monthly]",
                  type: "currency",
                  mathAction: "sum",
                },
                // {
                //   key: "additionalRentSum",
                //   name: "Total Add. Rent",
                //   type: "currency",
                //   mathAction: "sum",
                // },

                {
                  key: "price",
                  name: "Price",
                  type: "currency",
                  mathAction: "sum",
                },
                {
                  key: "taxAmount",
                  name: "Tax Amount",
                  type: "currency",
                  mathAction: "sum",
                },
                {
                  key: "totalPriceWithTax",
                  name: "Total Price",
                  type: "currency",
                  mathAction: "sum",
                },
              ]}
            />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginBottom: "10px",
                gap: "20px",
              }}
            >
              {/* <TableHoistFloorAddons indexes={indexes} /> */}
              <MondayButton
                hasIcon={false}
                className="mondayButtonRed"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText:
                      "Yes, I understand that I will lose all my changes!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      getNewValues();
                      Swal.fire({
                        title: "Deleted!",
                        text: "Your price calculator has been reset.",
                        icon: "success",
                      });
                    }
                  });
                }}
              >
                RECALCULATE
              </MondayButton>
            </div>
            <AgGridReact
              key={"3434353"}
              className="dark-ag-theme ag-theme-alpine-dark"
              rowData={globalData}
              getRowNodeId={(data) => data.id}
              embedFullWidthRows={true}
              animateRows={true}
              undoRedoCellEditing={true}
              enableRangeSelection={false}
              undoRedoCellEditingLimit={20}
              rowSelection={"single"}
              enableCellChangeFlash={true}
              context={{
                hoistData: elevationHoist,
              }}
              // statusBar={statusBar}
              gridOptions={{
                defaultColDef: {
                  editable: true,
                  enableRowGroup: true,
                  enablePivot: true,
                  enableValue: true,
                  sortable: true,
                  resizable: true,
                  filter: true,
                  autoHeight: true,
                  // flex: 1,
                  minWidth: 100,
                  enablePivot: false,
                },
              }}
              sideBar={{
                toolPanels: [
                  {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                  },
                  {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                  },
                ],
                // defaultToolPanel: "customStats",
              }}
              autoSizeStrategy={{
                type: "fitCellContents",
              }}
              onFirstDataRendered={(params) => {
                let columnApi = params.columnApi;
                const allColumnIds = [];

                columnApi.getColumns()?.forEach((column) => {
                  const colId = column?.getId();
                  allColumnIds.push(colId);
                });
                try {
                  columnApi.autoSizeColumns(allColumnIds, false);
                } catch (e) {
                  console.log(e);
                }
              }}
              onComponentStateChanged={(params) => {
                let columnApi = params.columnApi;
                const allColumnIds = [];

                columnApi.getColumns()?.forEach((column) => {
                  const colId = column?.getId();
                  allColumnIds.push(colId);
                });
                try {
                  columnApi.autoSizeColumns(allColumnIds, false);
                } catch (e) {
                  console.log(e);
                }
              }}
              getRowStyle={getRowPriceStyle}
              headerHeight={50}
              onGridReady={(params) => {
                setGridApi(params.api); // Set the gridApi here
              }}
              columnDefs={columnDefs}
              suppressDragLeaveHidesColumns={true}
              allowDragFromColumnsToolPanel={true}
              onCellValueChanged={onCellValueChanged}
              rowHeight={40}
              masterDetail={true}
              isRowMaster={(dataItem) => {
                return dataItem?.isAddon === true;
              }}
              detailCellRendererParams={detailCellRendererParams}
              detailRowAutoHeight={true}
            />
          </div>
        </Modal>
      )}
    </>
  );
}

export default TableHoistCalcModal;
const formatDimensions = (pli, withDescription = false) => {
  let formattedDimensions = [];
  let { length = "", height = "", width = "", depth = "", diameter = "" } = pli;
  ["length", "height", "width", "depth", "diameter"].forEach((el) => {
    let value = pli[el];
    if (forceToNumber(value) !== 0) {
      formattedDimensions.push(`${value}'${el?.[0].toUpperCase()}`);
    }
  });
  let toReturn = formattedDimensions.join(" x ") || "";
  return withDescription
    ? toReturn + ` ${!!pli?.description ? "(" + pli?.description + ")" : ""}`
    : toReturn;
};
// function evaluateMathExpression(params) {
//   const fullExpression = params.value;
//   console.log("fullExpression", fullExpression);

//   if (fullExpression) {
//     if (fullExpression.toString().includes(">")) {
//       let expressions = fullExpression.split(">");
//       return `${evaluate(expressions[0])} - ${evaluate(expressions[1])}`;
//     } else {
//       return evaluate(fullExpression);
//     }
//   } else {
//     return "-";
//   }
// }

// helper function to evaluate math expressions
const calculateTotalPrice = (item) => {
  return {
    ...item,
    totalPrice:
      forceToNumber(item?.price) +
      forceToNumber(item?.tax) +
      forceToNumber(item?.rent),
  };
};
