import { InputComponent } from "../../../../components";

function ExcludesController({ updateDateRange = () => {}, dateRange }) {
  return (
    <div className="dispatch-excludes-controller">
      <div className="controller-section"></div>
      <div className="controller-section">
        <InputComponent
          noFormItem
          type="rangePicker"
          value={dateRange}
          onChange={updateDateRange}
          allowClear={false}
        />
      </div>
    </div>
  );
}

export default ExcludesController;
