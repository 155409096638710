import {
  XIcon,
  TrashIcon,
} from "../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../commonComponents";
import { LogsIcon } from "../../../../../SidebarPages/DynamicView/src";
import { TickIcon } from "../../../../Settings/settingsComponents/Roles/src";

export function footerButtons(
  onCancel = () => {},
  onSave = () => {},
  onShowLogs = () => {},
  onDelete = () => {},
  editMode,
  saveBtnDisable
) {
  return [
    editMode && (
      <MondayButton
        onClick={onDelete}
        Icon={<TrashIcon />}
        className="mondayButtonRed"
      >
        Delete
      </MondayButton>
    ),
    editMode && (
      <MondayButton
        Icon={<LogsIcon />}
        onClick={onShowLogs}
        className="mondayButtonBlue"
      >
        Show Logs
      </MondayButton>
    ),
    <MondayButton
      Icon={<XIcon />}
      onClick={onCancel}
      className="mondayButtonRed"
    >
      Cancel
    </MondayButton>,
    <MondayButton
      onClick={onSave}
      disabled={saveBtnDisable}
      Icon={<TickIcon width={17} height={17} />}
    >
      Save
    </MondayButton>,
  ];
}

export function getLocationOptions(schedulesForDate, projects) {
  return [
    {
      label: "Schedules",
      options: schedulesForDate
        ?.reduce(
          (acc, val) => [
            ...acc,
            !acc?.find((e) => e?.label === val?.scheduleAddress)
              ? {
                  key: val?.scheduleAddress + Math.random(),
                  value: val?.scheduleId + " ",
                  label: val?.scheduleAddress,
                }
              : null,
          ],
          []
        )
        ?.filter(Boolean),
    },
    {
      label: "Projects",
      options: projects
        ?.map((e) => ({
          key: e?.projectName + Math.random(),
          value: e?.projectId,
          label: e?.projectName,
        }))
        .sort((a, b) => a?.value.localeCompare(b?.value)),
    },
  ];
}

const payrollCategories = ["Open Shop", "Certified Payroll", "Prevailing Wage"];

export const GOOGLE_MAP_API_KEY = "AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo";

export const jobsiteFields = ({
  clients,
  projects,
  jobsites,
  accessRight,
  servicesList,
  onAddressSelect,
}) => {
  const rateAccess = accessRight?.children?.find?.(
    ({ title }) => title === "Rate"
  );

  return [
    {
      required: true,
      keepOptions: true,
      label: "Jobsite Address",
      dataTestid: "jobAddress",
      onSelect: onAddressSelect,
      type: "placesautocomplete",
      formItemName: "jobAddress",
      className: "jobsiteAddressField",
      placeholder: "Jobsite address...",
      groupedOptions: [
        {
          label: "Projects",
          options: projects.map(({ projectAddress }, key) => ({
            key,
            label: projectAddress,
            value: projectAddress,
          })),
        },
      ],
      rules: [
        {
          validator: (_, value) => {
            const addressExists = jobsites.some(
              (job) => job?.jobAddress === value
            );
            if (addressExists) {
              return Promise.reject(
                new Error("Jobsite with this address already exists")
              );
            } else {
              Promise.resolve();
            }
          },
        },
      ],
    },
    {
      type: "input",
      required: true,
      label: "Job Name",
      dataTestid: "jobName",
      formItemName: "jobName",
      className: "jobNameField",
      placeholder: "Job name...",
      rules: [
        {
          validator: (_, value) => {
            const addressExists = jobsites.some(
              (job) => job?.jobName === value
            );
            if (addressExists) {
              return Promise.reject(
                new Error("Jobsite with this name already exists")
              );
            } else {
              Promise.resolve();
            }
          },
        },
      ],
    },
    {
      type: "select",
      required: true,
      label: "Client",
      className: "accountName",
      dataTestid: "accountName",
      formItemName: "accountName",
      placeholder: "Select client...",
      getPopupContainer: document.body,
      customOptions:
        Array.isArray(clients) &&
        clients.map((client, index) => ({
          key: index,
          value: client?.accountId,
          label: client?.accountName,
        })),
    },
    {
      type: "select",
      required: true,
      className: "payrollType",
      dataTestid: "payrollType",
      label: "Payroll Category",
      formItemName: "payrollType",
      getPopupContainer: document.body,
      placeholder: "Select payroll category...",
      customOptions: payrollCategories?.map((payroll, key) => {
        return {
          key,
          value: payroll,
          label: payroll,
          disabled: payroll === "Certified Payroll" && !rateAccess?.write,
        };
      }),
    },
    {
      type: "input",
      required: true,
      typeNumber: true,
      addonAfter: "ft.",
      label: "Location Radius",
      className: "locationRadius",
      placeholder: "Add radius...",
      dataTestid: "locationRadius",
      formItemName: "locationRadius",
    },
    {
      type: "select",
      mode: "multiple",
      label: "Services",
      className: "services",
      dataTestid: "services",
      formItemName: "services",
      placeholder: "Select service...",
      getPopupContainer: document.body,
      customOptions: servicesList?.map(({ serviceName, categoryId }) => {
        return {
          key: categoryId,
          value: serviceName,
          label: serviceName,
        };
      }),
    },
  ].filter(Boolean);
};
