import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  // useReducer,
  useCallback,
} from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Dropdown, Form, Switch } from "antd";

import {
  DownloadIcon,
  DropdownIcon,
} from "../../../../SidebarPages/BasePage/src";
// import {
//   ANALYTICS_REDUCER,
//   getEmployeeAnalytics,
//   groupEntriesInShifts,
// } from "../DEG/components/modalComponents/utils";
import PayrollContext from "../../PayrollContext";
import PayrollLayout from "../../Layout/PayrollLayout";
import { MondayButton } from "../../../../commonComponents";
import { columnDefs, chartingOptions, gridFilters } from "./AgGridData";
import { getPinnedBottomRowData } from "./AgGridData/pinnedBottomRowData";
import ExportDataModal from "./components/ExportDataModal/ExportDataModal";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import AnalyticsCostDistribution from "./components/AnalyticsCostDistribution";
import AnalyticShiftView from "./components/AnalyticShiftView/AnalyticShiftView";
// import { REDUCER_INIT } from "../DEG/components/modalComponents/utils/degReducer";
import AnalyticsDeleteModal from "./components/AnalyticsDeleteModal/AnalyticsDeleteModal";
import { calculateColumnsTotalValue } from "./AgGridData/utils/calculateColumnsTotalValue";
import RemovedAnalyticsModal from "./components/RemovedAnalyticsModal/RemovedAnalyticsModal";

import "../../Payroll.scss";

function AnalyticsTab({ accessRight, isSingleEmployee = false }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    form,
    jobsites,
    analytics,
    degPayrolls,
    // employeesList,
    fetchAnalytics,
    analyticsShifts,
    removedAnalytics,
  } = useContext(PayrollContext);

  // const [analyticsData, analyticsUpdate] = useReducer(
  //   ANALYTICS_REDUCER,
  //   REDUCER_INIT
  // );

  const [gridApi, setGridApi] = useState();
  const [rowData, setRowData] = useState([]);
  // const [jobsites, setJobsites] = useState([]);
  const [crewTeams, setCrewTeams] = useState([]);
  const [dataIdKey, setDataIdKey] = useState("shiftId");
  const [exportModal, setExportModal] = useState(false);
  const [analyticView, setAnalyticView] = useState(false);
  // const [analyticsShifts, setAnalyticsShifts] = useState([]);
  const [distributionModal, setDistributionModal] = useState(false);
  const [deleteAnalyticsModal, setDeleteAnalyticsModal] = useState(false);
  const [removedAnalyticsModal, setRemovedAnalyticsModal] = useState(false);

  const paginationPageSize = Math.floor((window.innerHeight - 357) / 38);

  function onDropdownClick({ key }) {
    const keyFunctions = {
      exportData: () => setExportModal(true),
      costDistribution: () => setDistributionModal(true),
      removedAnalytics: () => setRemovedAnalyticsModal(true),
    };

    return keyFunctions[key]();
  }

  function onSwitch(checked) {
    if (checked) {
      setRowData(analyticsShifts);
      setTimeout(() => {
        setDataIdKey("shiftId");
      }, 0);
    } else {
      setDataIdKey("entryId");
      setTimeout(() => {
        setRowData(analytics);
      }, 0);
    }
  }

  const onRowDoubleClicked = useCallback((event) => {
    if (dataIdKey === "shiftId") {
      setAnalyticView(event?.node?.data);
    }
  }, [dataIdKey]);

  const columns = useMemo(() => {
    return columnDefs({
      isSingleEmployee,
      setDeleteAnalyticsModal,
      columnAccess: accessRight?.children || [],
    });
  }, [accessRight, rowData, crewTeams]);

  const [columnsTotalValue, setColumnsTotalValue] = useState({});
  const sumColumns = ["employeeRate", "regAmount", "otAmount", "total"];

  function onSelectionChanged() {
    calculateColumnsTotalValue({
      gridApi,
      rowData,
      sumColumns,
      setColumnsTotalValue,
    });
  }

  useEffect(() => {
    onSelectionChanged();
  }, [rowData]);

  const pinnedBottomRowData = useMemo(
    () => getPinnedBottomRowData(columnsTotalValue),
    [columnsTotalValue]
  );

  useEffect(() => {
    // if (!jobsites.length) {
    //   API.get("jobsites", "/jobsites")
    //     .then((jb) => {
    //       setJobsites(jb);
    //     })
    //     .catch((err) => {
    //       console.log("Error getting data: ", err);
    //     });
    // }

    if (!crewTeams?.length) {
      API.get("crewTeams", "/crewTeams")
        .then((res) => {
          setCrewTeams(res);
        })
        .catch((error) => {
          console.log("ERror getting teams: ", error);
        });
    }

    // if (analytics?.length) {
    //   getEmployeeAnalytics({
    //     employeeList: undefined,
    //     degGridApi: {},
    //     analyticsUpdate,
    //     degRows: analytics,
    //   });
    // }
  }, [jobsites, crewTeams]);

  useEffect(() => {
    if (dataIdKey === "shiftId") {
      setRowData(analyticsShifts);
    }
    if (dataIdKey === "entryId") {
      setRowData(analytics);
    }
  }, [analytics, analyticsShifts]);

  return (
    <>
      <PayrollLayout
        {...{
          rowData,
          dataIdKey,
          gridFilters,
          chartingOptions,
          title: "Analytics",
          onSelectionChanged,
          onRowDoubleClicked,
          paginationPageSize,
          columnDefs: columns,
          getGridApi: setGridApi,
          excelExport: "Analytics",
          hasNew: !accessRight?.write,
          suppressDoubleClickEdit: true,
          pinnedBottomRowData: isSingleEmployee && pinnedBottomRowData,
          context: {
            crewTeams,
            degPayrolls,
          },
          customActionButtons: [
            <Switch
              defaultChecked
              onChange={onSwitch}
              key={"table-type-switch"}
              checkedChildren="Shift View"
              unCheckedChildren="Entries View"
            />,
            <Form
              form={form}
              key={"form-container"}
              style={{ display: "flex", flexDirection: "row", gap: 10 }}
            >
              <InputComponent
                type="rangepicker"
                placeholder="Set Date Range..."
                dataTestid={"analyticsDateRange"}
                formItemName={"analyticsDateRange"}
                dropdownClassName={isDarkMode && "darkDateDropDown"}
              />
              <InputComponent
                type="select"
                dataTestid={"companySelected"}
                placeholder="Select company..."
                formItemName={"companySelected"}
                customOptions={[
                  {
                    key: 0,
                    label: "GMNY Construction",
                    value: "GMNY Construction",
                  },
                  {
                    key: 1,
                    label: "Yard Management Services",
                    value: "Yard Management Services",
                  },
                ]}
                dropdownClassName={isDarkMode && "darkDropDown"}
              />
            </Form>,
            <MondayButton
              Icon={<DownloadIcon />}
              onClick={fetchAnalytics}
              key={"fetch-analytics-btn"}
              className="mondayButtonBlue"
            >
              Fetch Analytics
            </MondayButton>,
            <Dropdown
              key={"actions-btn"}
              overlayClassName={`layout-card-dropdown${
                isDarkMode ? " layout-dropdown-dark" : ""
              }`}
              menu={{
                onClick: onDropdownClick,
                items: [
                  accessRight.children?.find(
                    ({ title }) => title === "Delete"
                  ) && { label: "Removed Analytics", key: "removedAnalytics" },
                  { label: "Cost Distribution", key: "costDistribution" },
                  { label: "Export Data", key: "exportData" },
                ].filter(Boolean),
              }}
            >
              <MondayButton
                Icon={<DropdownIcon />}
                className="mondayButtonBlue"
              >
                Actions
              </MondayButton>
            </Dropdown>,
          ],
        }}
      />
      {distributionModal && (
        <AnalyticsCostDistribution
          {...{
            gridApi,
            jobsites,
            open: distributionModal,
            onCancel() {
              setDistributionModal(false);
            },
          }}
        />
      )}
      {exportModal && (
        <ExportDataModal
          open={exportModal}
          rowData={analytics}
          onCancel={() => setExportModal(false)}
          columnDefs={columnDefs({
            isSingleEmployee,
            setDeleteAnalyticsModal,
            columnAccess: accessRight?.children || [],
          })}
        />
      )}
      {deleteAnalyticsModal &&
        accessRight.children?.find(({ title }) => title === "Delete") && (
          <AnalyticsDeleteModal
            gridApi={gridApi}
            open={deleteAnalyticsModal}
            entry={deleteAnalyticsModal}
            setRemovedRowData={setRemovedAnalyticsModal}
            onCancel={() => setDeleteAnalyticsModal(false)}
          />
        )}
      {removedAnalyticsModal &&
        accessRight.children?.find(({ title }) => title === "Delete") && (
          <RemovedAnalyticsModal
            open={removedAnalyticsModal}
            removedAnalytics={removedAnalytics}
            onCancel={() => setRemovedAnalyticsModal(false)}
          />
        )}
      {analyticView && (
        <AnalyticShiftView
          jobsites={jobsites}
          open={!!analyticView}
          selectedShift={analyticView}
          onCancel={() => setAnalyticView(false)}
        />
      )}
    </>
  );
}

AnalyticsTab.propTypes = {
  accessRight: {
    write: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.array,
  },
  isSingleEmployee: PropTypes.bool,
};

export default AnalyticsTab;
