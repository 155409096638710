import { Modal, message } from "antd";
import { AgGridReact } from "ag-grid-react";
import { ScissorOutlined } from "@ant-design/icons";
import { useState, useMemo, useCallback, useContext } from "react";

import DegModalContext from "../DegModalContext";
import { shiftsColumnDef } from "./ShiftsToSplitModalData";
import SplitShiftModal from "../SplitShiftModal/SplitShiftModal";
import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

import "./ShiftsToSplitModal.scss";

const defaultColDef = {
  flex: 1,
  filter: true,
  minWidth: 130,
  sortable: true,
  resizable: true,
  enableColResize: true,
  enableRowGroup: false,
  suppressSizeToFit: true,
  suppressDragLeaveHidesColumns: true,
};

const sideBar = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
    },
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
    },
  ],
};

function ShiftsToSplitModal({ open, onCancel }) {
  const { crews, jobsites, analytics, isDarkMode } =
    useContext(DegModalContext);

  const [gridApi, setGridApi] = useState();
  const [splitShiftModal, setSplitShiftModal] = useState(false);

  const { shiftsWithMultipleJobsites: shifts } = analytics;

  const rowData = useMemo(() => {
    return shifts.map((shift) => {
      const { jobsiteId, jobAddress, jobName, googleSheetLink } = jobsites.find(
        ({ jobsiteId }) =>
          jobsiteId === shift.inJobsiteId || jobsiteId === shift.outJobsiteId
      );
      return Object.assign(shift, {
        employeeFullName: crews.find((crew) => {
          let employeeIdSplit = crew?.employeeId.split("-");
          let modifiedEmployeeId = `${employeeIdSplit[0]}-${parseFloat(
            employeeIdSplit[1]
          )}`;
          const employeeMatch =
            shift?.crewId === crew?.crewId ||
            shift?.employeeId === crew?.employeeId ||
            shift?.employeeId === modifiedEmployeeId;

          return employeeMatch;
        })?.crewName,
        jobsiteMatch: { jobsiteId, jobAddress, jobName, googleSheetLink },
        clockOut: dayjsNY(shift?.firstClockIn)
          .add(
            shift?.workHours + shift?.breakHours + shift?.overtimeHours,
            "hour"
          )
          .valueOf(),
        sow: shift?.jobsitesInfo?.flatMap(({ sow }) => sow),
      });
    });
  }, [shifts]);

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
  }, []);

  return (
    <Modal
      open={open}
      closable={true}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      title="Shifts to split Modal"
      data-testid="shiftsToSplit-modal"
      className={`shiftsToSplitModal ${isDarkMode && "shiftsToSplitModalDark"}`}
      footer={[
        <MondayButton
          Icon={<XIcon />}
          key="close-modal"
          onClick={onCancel}
          className="mondayButtonRed"
          data-testid="close-shiftsToSplit-modal-btn"
        >
          Cancel
        </MondayButton>,
      ]}
    >
      <div className="control-panel">
        <MondayButton
          key="open-split-shift"
          className="mondayButtonBlue"
          Icon={<ScissorOutlined />}
          onClick={() =>
            !!gridApi?.getSelectedNodes()?.length
              ? setSplitShiftModal(true)
              : message.error("Please select a Shift to split first!")
          }
        >
          Split Shift
        </MondayButton>
      </div>
      <div
        className={`deg-grid-container ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          rowData={rowData}
          sideBar={sideBar}
          pagination={true}
          context={{ jobsites }}
          paginationPageSize={10}
          rowSelection="multiple"
          suppressRowClickSelection
          onGridReady={onGridReady}
          columnDefs={shiftsColumnDef}
          defaultColDef={defaultColDef}
        />
      </div>
      {splitShiftModal && (
        <SplitShiftModal
          gridApi={gridApi}
          open={splitShiftModal}
          onCancel={() => setSplitShiftModal(false)}
        />
      )}
    </Modal>
  );
}

export default ShiftsToSplitModal;
