import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BorderedTextCard,
  InputComponent,
  NormalSizedModal,
} from "../../../SidebarPages/Fleet/components";
import { useDispatch, useSelector } from "react-redux";
import {
  MondayButton,
  ProgressComponent,
  RedWarningModal,
  Stepper,
} from "../../../commonComponents";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import { Form, Select, Tooltip, Tour, message } from "antd";
import "./ClaimsModal.scss";
import { RenderDynamicComponents } from "../Components";

import { filterTables, lazyFetch } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import {
  useEditLogs,
  useProgressComponent,
  useUploadedFiles,
} from "../../../../hooks";
import { driveApi } from "../../../../integrations/DriveRequest";
import { DocUploader } from "../../../SidebarPages/Documentation/View/components/DocumentationModal/components";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import { getSubcontractors } from "../../../SidebarPages/Subcontractors/SubcontractorsBasePage";
import { useGooglePicker } from "../Scheduling/Subcomponents/ServiceDocumentation/utils/utils";
import { getNewLabelHTML } from "../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/routeFunctions";
import { filterSchedulesOnDate } from "../../../SidebarPages/Safety/SafetyModal/utils/filterSchedulesOnDate";
import CustomModalHeader from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import DynamicTeamModal from "../DynamicTeamModal/DynamicTeamModal";
import { PlusIcon } from "../../../../assets";
import ReactQuillRichText from "../../../commonComponents/ReactQuillRichText/ReactQuillRichText";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  claimDynamicInputFields,
  claimFooterButtons,
  claimGuideHelper,
  saveClaimFunction,
} from "./helpers";
import { DynamicAvatars } from "../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { SmallLoadableComp } from "../../../Sidebars/components";
import { SettingIcon } from "../../subcomponents";
import { TransferIconNew } from "../../../SidebarPages/DynamicView/src";
import SortFields from "../../../pages/Settings/settingsComponents/SafetyDynamicForms/SortFields/SortFields";
import { saveSortedFields } from "../../../SidebarPages/Safety/SafetyModal/helpers";
import { cloneDeep } from "lodash";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const dateTimeFromSafety = {
  "Property Damage": {
    time: "timeOfPropertyDamage",
    date: "dateOfPropertyDamage",
  },
  "Personal Injury": {
    time: "timeOfInjury",
    date: "dateOfInjury",
  },
  "Vehicle Damage": {
    time: "timeOfVehicleDamageOrIncident",
    date: "dateOfVehicleDamageOrIncident",
  },
  "Other Trade Incident": {
    time: "timeOfIncident",
    date: "dateOfIncident",
  },
};

function ClaimsModal({
  visible,
  setVisible,
  category = "",
  incident, //! = { id: "", name: "" },
  requestId,
  requestObject,
  requestFiles = [],
  isNextStep = false,
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
  afterSaveRequest = async () => {},
  fromHearing = false,
  proppedType,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
    hideProgress,
  } = useProgressComponent({ categoryName: "Claims", actionType: "Create" });

  const [form] = Form.useForm();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [saving, setSaving] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;
  const [changes, setChanges] = useState(false);
  const [notes, setNotes] = useState("");
  const [claimStatus, setClaimStatus] = useState("Draft");
  const [mentions, setMentions] = useState([]);
  const [mentionIds, setMentionIds] = useState([]);
  const [nameDisabled, setNameDisabled] = useState(false);
  const [divClickable, setDivClickable] = useState(true);
  const [allClaims, setAllClaims] = useState([]);
  const [projects, setProjects] = useState(null);
  const [schedulesForDate, setSchedulesForDate] = useState([]);
  const [addressSelectionInfo, setAddressSelectionInfo] = useState({});
  const [assignedTo, setAssignedTo] = useState([]);
  const [subcontractors, setSubcontractors] = useState([]);
  const [tourOpen, setTourOpen] = useState(false);
  const [selectedSafety, setSelectedSafety] = useState({});
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [claimTeam, setClaimTeam] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newSortFields, setNewSortFields] = useState([]);
  const [openSort, setOpenSort] = useState(false);
  const companyNameField = Form.useWatch(
    "isClaimantAnEmployeeOfCoreOrOfSubcontractor",
    form
  );

  // const { openGooglePicker } = useGooglePicker();

  const { programFields } = useSelector((state) => state.programFields);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { hotCredentials } = useSelector((state) => state.hotCredentials);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences } = useSelector((state) => state.preferences);
  const { loggedOnTasks } = useSelector((state) => state.loggedOnTasks);

  const { saveAddedLogs } = useEditLogs();

  const claimHotCredential = hotCredentials.find(
    (obj) => obj.credentialId === "15"
  );

  const driveFunctions = useCreateDriveFolders("Claims");

  const driveRequest = driveApi({ accessToken });

  const { getFieldsValue, resetFields } = form;

  const claimStatusHandler = (status) => {
    setClaimStatus(status);
    setChanges(true);
  };

  const dynamicFields = useMemo(() => {
    let claimsFields = [];
    if (newSortFields.length > 0) {
      return newSortFields;
    } else {
      const fieldsIndx = programFields?.findIndex(
        (el) => el.fieldName === "Safety Dynamic Fields"
      );
      if (fieldsIndx > -1) {
        claimsFields = cloneDeep(
          programFields?.[fieldsIndx]?.fieldOptions?.Claims || []
        );
      }
      return claimsFields;
    }
  }, [programFields, newSortFields]);

  //avoid me as user & get users with access to Notes
  const usersWithAccess = useMemo(
    () =>
      userConfiguration?.allUsers?.Items?.filter((person) => {
        return person.cognitoUserId !== userConfiguration.cognitoUserId;
      })
        .filter(
          (person) =>
            !!person.routeConfig?.find(
              ({ title, write }) => title === "Claims" && write === true
            )
        )
        .map((user, index) => ({
          id: user.cognitoUserId || user.identityId,
          value: user.nameOfUser,
          key: index,
        })),
    [userConfiguration]
  );

  //region - FETCH DATA
  useEffect(() => {
    setLoading(true);
    Promise.allSettled([
      API.get("claims", "/claims"),
      API.get("teams", "/teams"),
      getSubcontractors(),
      lazyFetch({
        tableName: "projects",
        listOfKeys: ["projectId", "projectName", "projectManager", "accountId"],
      }),
      lazyFetch({
        tableName: "scheduling",
        listOfKeys: ["scheduleAddress", "scheduleId", "scheduleDays"],
      }),
    ])
      .then(
        ([
          { value: claimsRes },
          { value: claimTeamRes },
          { value: subcontractorsRes },
          { value: projectsRes },
          { value: schedulesRes },
        ]) => {
          setLoading(false);
          setAllClaims(claimsRes);
          setClaimTeam(claimTeamRes);
          setProjects(projectsRes);
          setSchedulesForDate(
            filterSchedulesOnDate(schedulesRes, dayjsNY())?.schedulesForDay ||
              []
          );
          const subcontractorsArr = subcontractorsRes.map(
            ({ accountName }) => accountName
          );
          setSubcontractors(subcontractorsArr);
        }
      )
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
    if (!!incident?.id) {
      filterTables("safety", "safetyApplicationId", incident?.id)
        .then((res) => {
          const safetyRec = res?.[0];
          setSelectedSafety(safetyRec || {});
          // setSelectedTeam(res?.[0]?.teamsConfiguration || []);
          // form.setFieldValue(
          //   "claimTeam",
          //   res?.[0]?.teamsConfiguration?.map((el) => el?.value) || []
          // );
        })
        .catch((e) => console.error(e));
    }
  }, []);

  useEffect(() => {
    if (selectedSafety) {
      const time =
        selectedSafety?.safetyApplicationObject?.[
          dateTimeFromSafety[category].time
        ];
      const date =
        selectedSafety?.safetyApplicationObject?.[
          dateTimeFromSafety[category].date
        ];

      form.setFieldsValue({
        claimDate: date ? dayjs(date).format("MM/DD/YYYY") : undefined,
        time: time ? dayjs(time).format("HH:mm") : undefined,
      });
    }
  }, [selectedSafety]);

  //Function that called on submit text from rich text
  const getMentions = useCallback(
    (content, delta, editor) => {
      const mentions = editor.getContents(content);
      //this removes first mention from array

      if (!delta.ops[1]) {
        setMentions([]);
        setMentionIds([]);
      } // this removes mentions from array but not the first one

      if (!!delta.ops[1]?.delete) {
        setMentions([]);
        setMentionIds([]);
      }

      mentions.ops.forEach((text) => {
        let mention = text.insert?.mention?.value;
        let cognitoId = text.insert?.mention?.id || "";

        if (
          mention !== undefined && //it's necessary!!!
          usersWithAccess.find(
            (user) => user.id === (cognitoId || identityId)
          ) !== undefined
        ) {
          // Getting all mentions and push them into array for broadcast notification validation
          setMentions((prev) => [...new Set([...prev, mention])]);
          setMentionIds((prev) => [...new Set([...prev, cognitoId])]);
        }
      });
    },
    [usersWithAccess]
  );

  const filterUsersByAccess = useMemo(
    () =>
      userConfiguration?.allUsers?.Items.reduce(
        (acc, user) =>
          !!user?.routeConfig?.find(
            ({ title, write }) => title === "Safety" && write === true
          ) &&
          // user.cognitoUserId !== userConfiguration.cognitoUserId &&
          !user.isSuspended
            ? [
                ...acc,
                {
                  cognitoUserId: user?.cognitoUserId,
                  nameOfUser: user?.nameOfUser,
                  identityId: user?.identityId,
                  userName: user?.userName,
                },
              ]
            : acc,
        []
      ),
    [userConfiguration]
  );

  const onLocationChange = useCallback(
    (val, option) => {
      let selectedAddress = val?.trim();
      let type = option?.type;

      if (type === "schedule") {
        let selectedSchedule = schedulesForDate?.find(
          (el) => el?.scheduleAddress === selectedAddress
        );

        if (!!selectedSchedule) {
          setAddressSelectionInfo({
            scheduleId: selectedSchedule?.scheduleId,
            projectId: "",
            accountId: "",
          });
        }
      } else if (type === "project") {
        let selectedProject = projects?.find(
          (el) => el?.projectName === selectedAddress
        );

        if (!!selectedProject) {
          setAddressSelectionInfo({
            projectId: selectedProject?.projectId,
            accountId: selectedProject?.accountId,
            scheduleId: "",
          });
        }
      } else {
        setAddressSelectionInfo({
          projectId: "",
          scheduleId: "",
          accountId: "",
        });
      }
    },
    [schedulesForDate, projects]
  );

  const onTeamSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data?.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onTeamDeselect = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeam((prev) => prev?.filter((el) => el.value !== teamName));
    if (form.getFieldValue("claimTeam").length < 1) {
      setSelectedTeam([]);
    }
  };

  const openClaimTeamModal = () => {
    setOpenTeamModal(true);
  };

  const ClearOptions = () => {
    setOpenTeamModal(false);
  };

  //region - FIELDS
  const populatedInputFields = useMemo(
    () =>
      claimDynamicInputFields({
        dynamicFields,
        category,
        nameDisabled,
        incident,
        allClaims,
        projects,
        onLocationChange,
        schedulesForDate,
        form,
        subcontractors,
        claimTeam: claimTeam?.map((team) => ({
          label: team.teamName,
          value: team.teamName,
          members: team.members?.map(
            ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
              identityId,
              nameOfUser,
              cognitoUserId,
            })
          ),
        })),
        onTeamSelect,
        onTeamDeselect,
        isDarkMode,
        proppedType,
      }),
    [
      dynamicFields,
      category,
      nameDisabled,
      incident,
      allClaims,
      projects,
      onLocationChange,
      schedulesForDate,
      form,
      subcontractors,
      claimTeam,
      isDarkMode,
      companyNameField,
      proppedType,
    ]
  );

  //region SAVE
  const onClaimSave = async () => {
    await saveClaimFunction({
      getFieldsValue,
      formattedFiles,
      requestFiles,
      category,
      addressSelectionInfo,
      claimHotCredential,
      driveFunctions,
      userConfiguration,
      selectedTeam: [],
      handleSavedRecord,
      updateProgressStatus,
      setSaving,
      notes,
      mentions,
      mentionIds,
      selectedSafety,
      incident,
      assignedTo,
      preferences,
      requestId,
      dispatch,
      loggedOnTasks,
      afterSaveRequest,
      hideProgress,
      programFields,
      accessToken,
      setVisibleCreationProgress,
      claimStatus,
    });
  };

  const onCloseWarningModal = async () => {
    driveFunctions.parentFolderId && driveFunctions.delete();

    setCancelModalVisible(false);
    setVisible(false);
    resetForm();
  };

  const resetForm = () => {
    if (filesToBeDeleted?.length > 0) {
      Promise.allSettled(
        filesToBeDeleted.map(
          async (id) => await driveRequest.deleteDriveItem(id)
        )
      ).then(() => setFilesToBeDeleted([]));
    }

    resetFields();
    setVisible(false);
    setUploadedFiles([]);
  };

  useEffect(() => {
    const fieldObj =
      programFields?.find((item) => item.fieldName === "Status colors")
        .fieldOptions.Claims.claimStatus || [];
    setStatuses(fieldObj);
  }, [programFields]);

  // handle richText changes
  const getValue = useCallback(
    (content, delta, source, editor) => {
      setChanges(true);
      setNotes(content);
      getMentions(content, delta, editor);
    },
    [getMentions]
  );

  const onCancel = () => {
    setCancelModalVisible(true);
  };

  //upload button handler
  const uploaderButton = document.querySelector(".uploaderButton");
  const onContinueToUpload = () => {
    form
      .validateFields(["claimNumber"])
      .then(async () => {
        const { parentFolderId } = await driveFunctions.create({
          parentFolderName: `${form.getFieldValue(
            "claimNumber"
          )} ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`,
          parentId: claimHotCredential?.credentialValue,
        });
        setNameDisabled(true);
        setCancelModalVisible(false);
        setDivClickable(false);
        setTimeout(() => {
          uploaderButton.click();
        }, 1000);
      })
      .catch(() => {
        setCancelModalVisible(false);
      });
  };
  const warningModalYESHandler = () => {
    cancelModalVisible === "doc" ? onContinueToUpload() : onCloseWarningModal();
    setChanges(false);
  };

  const tourSteps = useMemo(
    () =>
      claimGuideHelper({
        programFields,
        isDarkMode,
      }),
    [programFields, isDarkMode]
  );

  //region - JSX
  return (
    <NormalSizedModal
      {...{
        closable: true,
        visible,
        setVisible,
        title: (
          <CustomModalHeader
            {...{
              title: ` Claims Form - ${category}`,
              onClick: () => {
                tourSteps?.length > 0
                  ? setTourOpen(true)
                  : message.info("No guide available for this form");
              },
              headerInfo: () =>
                (userConfiguration?.groupName === "Admin" ||
                  userConfiguration?.groupName === "Super Admin") && (
                  <>
                    <Tooltip title="Add New Fields">
                      <SettingIcon
                        className="settingsIcon"
                        onClick={() =>
                          window.open(
                            `${window.location.origin}/settings/claims`
                          )
                        }
                      />
                    </Tooltip>
                    <Tooltip title="Sort Fields">
                      <TransferIconNew
                        {...{
                          className: "sortFieldsIcon",
                          onClick: () => {
                            setOpenSort(true);
                          },
                        }}
                      />
                    </Tooltip>
                  </>
                ),
            }}
          />
        ),

        className: `claimsModal ${isDarkMode && "claimsModalDark"}`,
        centered: true,
        onCancel: () => {
          if (changes) {
            onCancel();
          } else {
            onCloseWarningModal();
          }
        },
        newFooterDesign: claimFooterButtons({
          onCancel: () => {
            if (changes) {
              onCancel();
            } else {
              onCloseWarningModal();
            }
          },
          onSave: () => {
            form
              .validateFields()
              .then((res) => {
                onClaimSave();
              })
              .catch((error) => {
                console.error("Validation failed:", error);
                message.error("Please fill all required fields");
              });
          },
          suffix: <TickIcon width={17} height={17} />,
          saving,
        }),
      }}
    >
      <Form
        {...{
          form,
          onFieldsChange: () => {
            setChanges(true);
          },
          initialValues: {
            safetyRecord: incident?.name,
            typeOfIncident: category,
            claimType: proppedType
              ? proppedType === "hearings"
                ? "Worker Compensation"
                : "GL"
              : undefined,
          },
        }}
      >
        <div className="modalContent">
          <Stepper
            {...{
              currentStep,
              setCurrentStep,
              stepperClassName: "formStepper",
              steps: !statuses?.length
                ? []
                : statuses?.map(({ statusName }) => ({
                    title: statusName,
                    disabled: false,
                  })),
              statusesColors: statuses,

              stepRenderer: false,
              requestStatus: claimStatusHandler,
            }}
          />
          <SmallLoadableComp {...{ loading }}>
            <div className="bodyContent">
              <div className="uploaderSection">
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      zIndex: divClickable ? 10 : 0,
                      cursor: divClickable ? "pointer" : "default",
                    }}
                    onClick={() => {
                      divClickable && setCancelModalVisible("doc");
                    }}
                  ></div>
                  <DocUploader
                    {...{
                      uploadedFiles: [
                        ...uploadedFiles,
                        ...(requestFiles || []),
                      ],
                      onPickerSuccess,
                      onDelete,
                      // disabled: !driveFunctions.parentFolderId || divClickable,
                      onChange() {
                        setChanges(true);
                      },
                      folderId: driveFunctions.parentFolderId,
                      checkWarnings: false,
                      responsiveWidth: 1600,
                    }}
                  />
                </div>
                {!!selectedSafety &&
                  selectedSafety?.googleDriveUpload?.length > 0 && (
                    <div className="safetyFilesContainer">
                      <div className="safetyFilesTitle">
                        <hr className="safetyFilesTitleLine" />
                        <span
                          style={{ color: isDarkMode ? "#fff" : "#000" }}
                          className="safetyFilesTitleText"
                        >
                          Safety Files
                        </span>
                        <hr className="safetyFilesTitleLine" />
                      </div>
                      <DocUploader
                        {...{
                          uploadedFiles: selectedSafety.googleDriveUpload,
                          onlyUploadedFiles: true,
                          checkWarnings: false,
                          responsiveWidth: 1600,
                          disabled: true,
                        }}
                      />
                    </div>
                  )}
              </div>

              <div className="inputFieldsContainer">
                <BorderedTextCard
                  {...{
                    title: "Details",
                    className: "detailsCard",
                    isDarkMode,
                  }}
                >
                  {RenderDynamicComponents(populatedInputFields, { form })}
                  <div className="claimsAvatarsDiv">
                    {!!selectedTeam?.length && (
                      <MondayButton
                        style={{
                          paddingRight: 15,
                          margin: 0,
                          alignSelf: "center",
                        }}
                        {...{ Icon: <PlusIcon /> }}
                        onClick={openClaimTeamModal}
                      >
                        Members
                      </MondayButton>
                    )}

                    <div className="claimAvatars"></div>
                    <DynamicAvatars
                      {...{
                        title: `Claim Team - ${category}`,
                        isDarkMode: isDarkMode,
                        members: selectedTeam.reduce(
                          (acc, { members }) => [...acc, ...members],
                          []
                        ),
                      }}
                    />
                  </div>
                  <InputComponent
                    mode="multiple"
                    required
                    showSearch
                    label="Assign To"
                    placeholder="Select some users"
                    type="select"
                    dropdownClassName={isDarkMode && "darkDropDown"}
                    formItemName="assignedTo"
                    onChange={(value, option) =>
                      setAssignedTo(
                        option.map((el) => ({
                          nameOfUser: el.label,
                          identityId: el.identityId,
                          cognitoUserId: el.cognitoUserId,
                          userName: el.userName,
                        }))
                      )
                    }
                    options={filterUsersByAccess?.map(
                      (
                        { cognitoUserId, nameOfUser, identityId, userName },
                        i
                      ) => (
                        <Select.Option
                          key={i}
                          label={nameOfUser}
                          value={nameOfUser}
                          identityId={identityId}
                          cognitoUserId={cognitoUserId}
                          userName={userName}
                        />
                      )
                    )}
                    className="assignedToSelect"
                    maxTagCount={2}
                    maxTagPlaceholder={(e) => {
                      return (
                        <Tooltip title={e.map(({ label }) => label).join(", ")}>
                          <span>{`+ ${e.length} more`}</span>
                        </Tooltip>
                      );
                    }}
                  />
                </BorderedTextCard>

                <BorderedTextCard
                  title={"Notes"}
                  className={`notesCard`}
                  isDarkMode={isDarkMode}
                >
                  <ReactQuillRichText
                    {...{
                      placeholder: "Type here...",
                      name: "notes",
                      wrapperClassName: "ClaimsNotes",
                      value: notes,
                      className: "claims_input",
                      disabled: false,
                      userConfiguration,
                      getValue,
                      defaultUsers: usersWithAccess,
                    }}
                  />
                </BorderedTextCard>
              </div>
            </div>
          </SmallLoadableComp>
        </div>
      </Form>
      {!!cancelModalVisible && (
        <RedWarningModal
          {...{
            visible: !!cancelModalVisible,
            onCancel: () => setCancelModalVisible(false),
            titleText:
              cancelModalVisible === "doc"
                ? "Create Claim Folder"
                : "Cancel Claim",
            confirmModal: cancelModalVisible === "doc",
            footerProps: {
              onConfirm: warningModalYESHandler,
            },
          }}
        >
          {cancelModalVisible === "doc" ? (
            <p>
              If you want to continue uploading Claim Number can not be changed
              anymore!
            </p>
          ) : (
            <p>Are you sure you want to cancel?</p>
          )}
        </RedWarningModal>
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {openSort && (
        <SortFields
          {...{
            visible: openSort,
            setVisible: setOpenSort,
            rowData: dynamicFields,
            onFinish: (res, newLog) => {
              saveSortedFields({
                res,
                newLog,
                setNewSortFields,
                programFields,
                category: "Claims",
                saveAddedLogs,
                fieldName: "Safety Dynamic Fields",
              });
            },
            isDarkMode,
            fieldKey: "claimCategory",
            title: "Claims",
            proppedCategory: category,
          }}
        />
      )}
      {openTeamModal && (
        <DynamicTeamModal
          {...{
            open: openTeamModal,
            setOpen: setOpenTeamModal,
            selectedTeam,
            ClearOptions,
            setSelectedTeam,
            proppedAllTeams: claimTeam,
            onSave: (data) => {
              from.setFieldValue("claimTeam", data || []);
            },
          }}
        />
      )}
      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Claims",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              const { claimId } = visibleCreationProgress;
              resetForm();
              if (claimStatus !== "Draft") nextStepHandler(true, category);
              else
                !!claimId &&
                  (!!!isNextStep || !!!fromHearing) &&
                  navigate(`/claims/${claimId}`);
            },
          }}
        />
      )}
    </NormalSizedModal>
  );
}

export default ClaimsModal;
