import { ClearOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Input, Modal, Tooltip, message } from "antd";
import { API } from "aws-amplify";
import moment from "moment";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TimerIcon } from "src/components/Header/subcomponents";
import { endLoggedTasks } from "../../../../../actions/loggedOnTasks";
import { StopIcon } from "../../../../../assets";
import { companyDetails } from "../../../../../helpers/constants";
import { InformationIcon, WarningIcon } from "../../../../../icons";
import { ReactComponent as GoTasksIcon } from "../../../../../icons/go_tasks.svg";
import DetailedTimeRemaining from "../../../../../utils/DetailedTimeRemaining";
import {
  SearchIcon,
  XIcon,
} from "../../../../SidebarPages/Communication/assets";
import {
  ConfirmationModal,
  ExportOrEmailDropdown,
  MondayButton,
  WarningModal,
} from "../../../../commonComponents";
import {
  Tick,
  TickIcon,
} from "../../../../pages/Settings/settingsComponents/Roles/src";
import "./LoggedOnTasksDrawer.scss";
import { preferences as preferencesDispatch } from "../../../../../actions/preferences";
import {
  exportToExcel,
  onGeneratePDF,
  uploadExcelToDrive,
} from "../utils/exportPdfExcel";
import useExportHeadersModal from "../../../../../hooks/useExportHeadersModal";
import PdfHeadersModal from "../../../../commonComponents/PdfHeadersModal/PdfHeadersModal";
import { tableHeaders } from "../constants/tableHeaders";

const { companyName } = companyDetails;

const TasksDrawer = ({ drawerOpen = false, setDrawerOpen = false }) => {
  const { loggedOnTasks = [] } = useSelector((state) => state.loggedOnTasks);
  const { preferences: myPrefs } = useSelector((state) => state.preferences);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { base64 } = useSelector((state) => state.base64);

  const [selectedTask, setSelectedTask] = useState(null);
  const [updatedValues, setUpdatedValues] = useState([]);
  const [visible, setVisible] = useState(false);
  const [searchTask, setSearchTask] = useState([]);
  const [draggableTasks, setDraggableTasks] = useState();
  const [activeFilter, setActiveFilter] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const finalLoggedTasks = (
    !!draggableTasks ? draggableTasks : loggedOnTasks
  )?.filter(({ taskTimerDisabled }) => !!taskTimerDisabled);

  //Only tasks which you are assigned to. If you are admin or super admin you can see all tasks

  useEffect(() => {
    if (activeFilter) {
      setDraggableTasks(searchTask);
    } else {
      setDraggableTasks(loggedOnTasks);
    }
  }, [activeFilter]);

  //  Function that end logged task
  const stopTimer = () => {
    dispatch(endLoggedTasks(updatedValues, selectedTask, setDraggableTasks));
    setVisible((prevState) => !prevState);
  };

  //Task log object to be created
  const handleLoggedOnTaskShow = (task) => {
    const totalTimeLogged = {
      timeStarted: task.totalTimeLogged.timeStarted,
      timeFinished: moment().valueOf(),
      startedBy: task.totalTimeLogged.startedBy,
      finishedBy: userConfiguration.nameOfUser,
    };
    {console.log('totalTimeLogged',totalTimeLogged)}
    //Keys that need to be updated
    const body = {
      totalTimeLogged,
      timerFinished: true,
      taskTimerDisabled: false,
    };

    setSelectedTask({ ...task, ...body });
    setUpdatedValues(body);
    setVisible((prevState) => !prevState);
  };

  //Handle task topic for redirecting to current topic view
  const handleTaskTopic = (topic) => {
    if (topic.toLowerCase() === "clients") {
      return topic.toLowerCase().replace("clients", "accounts");
    } else if (topic.toLowerCase() === "subcontractors") {
      return topic.toLowerCase().replace("subcontractors", "accounts");
    } else return topic.toLowerCase().split(" ").join("");
  };

  //Handle inspection topic becouse it has different url from other topics
  const handleTopicId = (task) => {
    if (task.taskTopic.toLowerCase() === "inspections") {
      return `view?id=${task.taskTopicId}&&companyName=${companyName}`;
    } else {
      return task.taskTopicId;
    }
  };

  const onSearchTask = (e) => {
    let substring = e.target.value;
    let filteredData = finalLoggedTasks?.filter((item) =>
      item?.taskTitle.toLowerCase().includes(substring.toLowerCase())
    );
    setSearchTask(filteredData);
    substring !== "" ? setActiveFilter(true) : setActiveFilter(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      setVisible(false);
    }
  };

  const dragEndHandler = (result) => {
    if (!result.destination) return;
    const items = Array.from(finalLoggedTasks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDraggableTasks(() => [...items]);
    setDisabled(false);
  };

  const handleSave = () => {
    const updatedPreferences = {
      ...(myPrefs?.preferences || {}),
      tasksPreferences: finalLoggedTasks
        .map(({ taskId }) => taskId)
        .filter(Boolean),
    };
    setDisabled(true);
    message.loading({
      content: "Saving changes...",
      duration: 0,
    });
    API.patch("preferences", "/preferences", {
      body: {
        preferences: updatedPreferences,
      },
    })
      .then(() => {
        message.destroy();
        dispatch(
          preferencesDispatch({
            ...myPrefs,
            preferences: updatedPreferences,
          })
        );
        message.success("Changes saved successfully!");
      })
      .catch((err) => {
        console.log("Error saving preferences: ", err);
        message.destroy();
        message.error("There was an error while saving this");
      });
  };

  const handleClearOrderPreference = () => {
    if (!myPrefs?.preferences?.tasksPreferences) {
      message.info("There is no preference saved before...");
      return;
    }
    setOpenConfirmModal(true);
  };

  const handleOnConfirmModal = () => {
    const { tasksPreferences, ...updatedPreferences } = myPrefs?.preferences;

    API.patch("preferences", "/preferences", {
      body: { preferences: updatedPreferences },
    })
      .then(() => {
        message.destroy();
        setDraggableTasks(loggedOnTasks);
        message.success("Changes Saved In Your Preferences!");
        dispatch(
          preferencesDispatch({
            ...myPrefs,
            preferences: updatedPreferences,
          })
        );
      })
      ?.catch((err) => console.error("Error saving to preferences", err));
  };

  const additionalData = {
    tasks: finalLoggedTasks,
    base64,
  };

  const { openModal, getHeadersModal, handleCloseModal, handleFinishModal } =
    useExportHeadersModal({
      additionalData,
      onGeneratePDF,
      exportToExcel,
    });

  return (
    <>
      <Drawer
        open={drawerOpen}
        title="Logged tasks"
        placement="right"
        onClose={() => setDrawerOpen(false)}
        rootClassName={`tasksDrawer ${isDarkMode && "tasksDrawerDark"}`}
        width={480}
        extra={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip title="Clear order preference">
              <MondayButton
                data-testid="clear-preference"
                className="mondayButtonBlue"
                Icon={<ClearOutlined />}
                onClick={handleClearOrderPreference}
              />
            </Tooltip>
            <ExportOrEmailDropdown
              {...{
                rowData: finalLoggedTasks,
                onGeneratePDF: (action, getDocDefinition) =>
                  getDocDefinition
                    ? onGeneratePDF(action, additionalData, getDocDefinition)
                    : getHeadersModal(action, getDocDefinition),
                exportGridToExcel: (getDocDefinition) =>
                  getDocDefinition
                    ? exportToExcel(additionalData, getDocDefinition)
                    : getHeadersModal(false, getDocDefinition, "excel"),
                uploadExcelToDrive: (driveRequest, driveFolderId) =>
                  uploadExcelToDrive(
                    filteredEvents,
                    driveRequest,
                    driveFolderId
                  ),
                defaultDocDefinition: true,
                title: "Logged_Tasks",
              }}
            />
          </div>
        }
      >
        <Modal
          {...{
            open: showUnsavedChangesModal,
            closeIcon: <XIcon />,
            onCancel: () => {
              setShowUnsavedChangesModal(false);
            },
            title: "You have unsaved changes",
            footer: [
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <MondayButton
                  onClick={() => {
                    setDrawerOpen(false);
                    setShowUnsavedChangesModal(false);
                    setDisabled(true);
                  }}
                  Icon={<XIcon />}
                  className="mondayButtonRed"
                >
                  Cancel Changes
                </MondayButton>
                <MondayButton
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                    handleSave();
                    setDrawerOpen(false);
                  }}
                  Icon={<TickIcon />}
                >
                  Save Changes
                </MondayButton>
              </div>,
            ],
          }}
        ></Modal>
        <div className="loggedTaskHeader">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p className="informationText">
              <span className="informationIcon">i</span> Here you can see all
              the started tasks.
            </p>
            <MondayButton
              onClick={handleSave}
              Icon={<TickIcon />}
              className={disabled ? "mondayButtonGray" : "mondayButtonGreen"}
              disabled={disabled}
            >
              Save
            </MondayButton>
          </div>
          <div className="headerSearch">
            <Input
              className="searchInput"
              placeholder="Search task"
              allowClear
              onChange={onSearchTask}
              prefix={<SearchIcon width={15} height={15} />}
            />
          </div>
        </div>

        {finalLoggedTasks?.length > 0 ? (
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {finalLoggedTasks?.map((task, i) => {
                    return (
                      <Draggable
                        draggableId={`draggable-${i}`}
                        index={i}
                        key={i}
                      >
                        {(provided) => {
                          return (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              className="loggedIn_task"
                              key={i}
                            >
                              <Tooltip placement="top" title={task.taskTitle}>
                                <h6
                                  onClick={() =>
                                    navigate(
                                      `/${handleTaskTopic(
                                        task.taskTopic
                                      )}/${handleTopicId(task)}`,
                                      {
                                        data: {
                                          tab: "Tasks",
                                          selectedId: task.taskId,
                                          inspectionType:
                                            task.taskTopic === "Inspections"
                                              ? task.taskRelatedTo
                                                  .split(",")
                                                  .pop()
                                              : "",
                                          taskTopicId: task.taskTopicId,
                                          rowSelected: task,
                                        },
                                      }
                                    )
                                  }
                                >
                                  {task.taskTitle}
                                </h6>
                              </Tooltip>

                              <div className="timer">
                                <DetailedTimeRemaining
                                  show={true}
                                  start={task.totalTimeLogged.timeStarted}
                                  end={
                                    task?.totalTimeLogged?.timeFinished || false
                                  }
                                  includeDays={true}
                                  includeHours={true}
                                  includeMinutes={true}
                                  includeSeconds={true}
                                />
                                <TimerIcon />
                              </div>

                              <MondayButton
                                className="mondayButtonRed"
                                onClick={() => handleLoggedOnTaskShow(task)}
                                Icon={<StopIcon />}
                                style={{ marginLeft: "5px" }}
                              >
                                Stop
                              </MondayButton>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className="no_logged_tasks">
            <span>
              <InformationIcon style={{ fill: "#fe4c4a" }} />
            </span>
            <h2>No information to show</h2>
            <h5 style={{ background: "transparent" }}>
              Please start a timer first
            </h5>
            <MondayButton
              onClick={() => {
                navigate("/tasksManager");
                setDrawerOpen(false);
              }}
              Icon={<GoTasksIcon style={{ height: 15 }} />}
              className="go-tasks-btn"
            >
              Go to tasks
            </MondayButton>
          </div>
        )}
      </Drawer>
      <WarningModal
        visible={visible}
        setVisible={() => setVisible(true)}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        onCancel={() => setVisible((prevState) => !prevState)}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to stop logging this task?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setVisible(false)}
              Icon={<CloseOutlined style={{ fontSize: "14px" }} />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={stopTimer} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      {openConfirmModal && (
        <ConfirmationModal
          onConfirm={handleOnConfirmModal}
          visible={openConfirmModal}
          setVisible={setOpenConfirmModal}
          title="Confirm"
          text="Are you sure you want to clear order preference"
        />
      )}
      {openModal && (
        <PdfHeadersModal
          {...{
            isOpen: openModal,
            onClose: () => handleCloseModal(),
            onFinish: (data) => handleFinishModal(data),
            headers: tableHeaders,
          }}
        />
      )}
    </>
  );
};

export default TasksDrawer;
