import { Dayjs } from "dayjs";
import { formatNumber } from "src/components/SidebarPages/utils";
import { formatDuration } from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { parseInTz } from "../../utils/dateFunctions";
import { MARKER_ICON_URL } from "src/components/SidebarPages/Fleet/fleetsLive/data";

/** @typedef {{lat: number, lng: number}} LatLng */

/** @typedef {"Yard"|"Schedule"|"Off Project"|"Off Schedule"|"Vendor"} DestinationType */

/**
 * @typedef StepObject
 * @property {string} pickUpLocation
 * @property {string} dropOffLocation
 * @property {Dayjs|null} departAt
 * @property {Dayjs|null} arriveBy
 * @property {number|null} routeLength
 * @property {number|null} duration
 * @property {number} index
 * @property {string} formattedDistance
 * @property {string} formattedDuration
 * @property {google.maps.TravelMode} travelMode
 * @property {DestinationType} destinationType
 * @property {string|null} destinationId
 * @property {string|null} destinationName
 * @property {google.maps.DirectionsWaypoint[]} waypoints
 * @property {string|null} overview_polyline
 * @property {LatLng|null} pickUpCoordinates
 * @property {LatLng|null} dropOffCoordinates
 */

/**
 * @typedef NewListType
 * @property {string|null} [startAddress]
 * @property {string|null} [endAddress]
 * @property {string|number|Dayjs|null} [startTime]
 * @property {string|number|Dayjs|null} [endTime]
 * @property {google.maps.Map} [map]
 */

/** @typedef {Partial<StepObject> & {index: number} & NewListType} StepConstructor */

export const POLYLINE_OPTIONS = {
  strokeWeight: 5,
  stokeOpacity: 1,
  strokeColor: "#1264A3",
  icons: undefined,
};

export const TRANSIT_OPTIONS = {
  strokeOpacity: 0,
  strokeColor: "#E9C466",
  icons: [
    {
      icon: {
        path: "M 0, 0 m 5, 0 a 5,5 0 1,0 -10,0 a 5,5 0 1,0  10,0",
        strokeOpacity: 1,
        scale: 1,
        fillColor: "#E9C466",
        fillOpacity: 1,
      },
      offset: 0,
      repeat: "20px",
    },
  ],
};

export class ItineraryStep {
  /** @type {string|null} */
  #startAddress = null;

  /** @type {string|null} */
  #endAddress = null;

  /** @type {Dayjs|null} */
  #startTime = null;

  /** @type {Dayjs|null} */
  #endTime = null;

  /** @type {number|null} */
  #duration = null;

  /** @type {number|null} */
  #distance = null;

  /** @type {google.maps.TravelMode} */
  #travelMode = google.maps.TravelMode.DRIVING;

  /** @type {(step: StepObject) => any} */
  onChange = function () {};

  /** @type {number} */
  #index = undefined;

  /** @type {"DEPART"|"ARRIVE"} */
  #lastChanged = "DEPART";

  /** @type {DestinationType} */
  #destinationType = "Off Project";

  /** @type {string|null} */
  #destinationName = null;

  /** @type {string|null} */
  #destinationId = null;

  #directionService = new google.maps.DirectionsService();

  #directionsRenderer = new google.maps.DirectionsRenderer({
    draggable: true,
    markerOptions: {
      clickable: false,
      draggable: false,
      visible: true,
      icon: {
        url: MARKER_ICON_URL,
      },
    },
    suppressInfoWindows: true,
    polylineOptions: {
      ...POLYLINE_OPTIONS,
    },
  });

  /** @type {google.maps.Map} */
  map = undefined;

  /** @type {google.maps.DirectionsWaypoint[]} */
  #waypoints = [];

  /** @type {string|null} */
  #overview_polyline = null;

  /** @type {LatLng|null} */
  #pickUpCoordinates = null;

  /** @type {LatLng|null} */
  #dropOffCoordinates = null;

  /**
   * @param {StepConstructor} param
   */
  constructor(param) {
    //#region CONSTRUCTOR
    for (const p in param) {
      /** @type {keyof StepConstructor} */
      const key = p;

      switch (key) {
        case "arriveBy":
          this.#endTime = param[key] ? parseInTz(param[key]) : null;
          break;
        case "departAt":
          this.#startTime = param[key] ? parseInTz(param[key]) : null;
          break;
        case "destinationName":
          this.#destinationName = param[key] ?? null;
          break;
        case "destinationId":
          this.#destinationId = param[key] ?? null;
          break;
        case "destinationType":
          this.#destinationType = param[key] ?? null;
          break;
        case "dropOffLocation":
          this.#endAddress = param[key] ?? null;
          break;
        case "duration":
          this.#duration = param[key] ?? null;
          break;
        case "index":
          this.#index = param[key];
          break;
        case "map":
          this.#directionsRenderer.setMap(param[key]);
          this.map = param[key];
          break;
        case "pickUpLocation":
          this.#startAddress = param[key] ?? null;
          break;
        case "routeLength":
          this.#distance = param[key] ?? null;
          break;
        case "travelMode":
          if (param[key] === "WALKING") {
            this.#directionsRenderer.setOptions({
              polylineOptions: {
                ...POLYLINE_OPTIONS,
                ...TRANSIT_OPTIONS,
              },
            });
          }
          this.#travelMode = param[key] ?? null;
          break;
        case "waypoints":
          this.#waypoints = (param[key] ?? []).map(
            ({ location, stopover }) => ({
              location: new google.maps.LatLng(location),
              stopover,
            })
          );
          break;
        case "endAddress":
          this.#endAddress = param[key] ?? null;
          break;
        case "endTime":
          this.#endTime = param[key] ? parseInTz(param[key]) : null;
          break;
        case "startAddress":
          this.#startAddress = param[key] ?? null;
          break;
        case "startTime":
          this.#startTime = param[key] ? parseInTz(param[key]) : null;
          break;
        case "overview_polyline":
          this.#overview_polyline = param[key] ?? null;
          break;
        case "pickUpCoordinates":
          this.#pickUpCoordinates = param[key] ?? null;
          break;
        case "dropOffCoordinates":
          this.#dropOffCoordinates = param[key] ?? null;
          break;
        default:
          break;
      }
    }

    this.#calculateDistance(this.#waypoints, true);

    google.maps.event.addListener(
      this.#directionsRenderer,
      "directions_changed",
      () => {
        //#region DIRECTIONS LISTENER
        const newDirections = this.#directionsRenderer.getDirections();
        if (!newDirections) {
          return;
        }

        this.#waypoints = newDirections.request.waypoints || [];
        this.#overview_polyline = newDirections.routes[0].overview_polyline;

        const diff = this.#calcDistanceAndDuration(newDirections);

        this.#dropOffCoordinates =
          newDirections.routes[0].legs[0].end_location.toJSON();

        this.#pickUpCoordinates =
          newDirections.routes[0].legs[0].start_location.toJSON();

        if (this.#distance === diff.distance) {
          return;
        }

        this.#updateDiffData(diff);

        const updateDepart = this.#lastChanged === "ARRIVE" && !!this.#endTime;

        if (updateDepart || !this.#startTime) {
          this.#startTime = this.#endTime.subtract(
            diff.duration,
            "milliseconds"
          );
          this.onChange(this.toObject());
        } else {
          this.#endTime = this.#startTime.add(diff.duration, "milliseconds");
          this.onChange(this.toObject());
        }
      }
    );

    return new Proxy(this, {
      set(target, key, value) {
        if (key === "map") {
          target.#directionsRenderer.setMap(value);
        }

        return Reflect.set(target, key, value);
      },
    });
  }

  //#region GETTERS
  #getFormattedDuration() {
    if (!this.#duration) {
      return null;
    }

    return formatDuration(this.#duration, "milliseconds").text;
  }

  #getFormattedDistance() {
    if (!this.#distance) {
      return null;
    }

    return formatNumber(this.#distance / 1609, { unit: "mile" });
  }

  getIndex() {
    return this.#index;
  }

  getDepart() {
    return this.#startAddress;
  }

  getDestination() {
    return this.#endAddress;
  }

  getArriveTime() {
    return this.#endTime;
  }

  getDepartTime() {
    return this.#startTime;
  }

  /**
   * @returns {StepObject}
   */
  toObject() {
    //#region TO OBJECT
    return {
      pickUpLocation: this.#startAddress,
      dropOffLocation: this.#endAddress,
      departAt: this.#startTime,
      arriveBy: this.#endTime,
      routeLength: this.#distance,
      duration: this.#duration,
      formattedDistance: this.#getFormattedDistance(),
      formattedDuration: this.#getFormattedDuration(),
      index: this.#index,
      travelMode: this.#travelMode,
      destinationId: this.#destinationId,
      destinationName: this.#destinationName,
      destinationType: this.#destinationType,
      waypoints: JSON.parse(JSON.stringify(this.#waypoints)),
      overview_polyline: this.#overview_polyline,
      pickUpCoordinates: this.#pickUpCoordinates,
      dropOffCoordinates: this.#dropOffCoordinates,
    };
  }

  /**
   * Changes the depart address without recalculating the distance
   * @param {string|null} address
   */
  changeDepartAddress(address) {
    //#region CHANGE DEPART ADDRESS
    this.#startAddress = address;
    this.#updateDiffData();
    this.#endTime = null;

    this.onChange(this.toObject());
  }

  /**
   * Changes the destination. The calculation gets triggered only when
   * the address is not null and is different from the depart address
   * @param {string|null} address
   * @return {Promise<StepObject>}
   */
  async changeDestinationAddress(address) {
    //#region CHANGE DESTINATION
    if (this.#startAddress === address && !!address) {
      this.#endAddress = null;
      this.changeDestinationData({
        destinationId: null,
        destinationName: null,
        destinationType: "Off Project",
      });
      this.onChange(this.toObject());
      return Promise.reject("Depart and arrive can not be the same");
    }

    this.#endAddress = address;

    if (!address) {
      //it is left like this in case of a manual clear by the user
      this.changeDestinationData({
        destinationId: null,
        destinationName: null,
        destinationType: "Off Project",
      });

      this.onChange(this.toObject());
      return Promise.resolve();
    }

    return await this.#calculateDistance();
  }

  /**
   * Changes the data related to the destination address
   * @param {Object} data
   * @param {DestinationType} [data.destinationType]
   * @param {string|null} [data.destinationId]
   * @param {string|null} [data.destinationName]
   */
  changeDestinationData(data) {
    //#region CHANGE DESTINATION DATA
    if ("destinationId" in data) {
      this.#destinationId = data.destinationId ?? null;
    }

    if ("destinationName" in data) {
      this.#destinationName = data.destinationName ?? null;
    }

    if ("destinationType" in data) {
      this.#destinationType = data.destinationType ?? "Off Project";
    }
  }

  /**
   * Changes the depart time, triggers recalculation
   * automatically, unless specified not to
   * @param {Dayjs} newTime
   * @param {boolean} [calcDistance=true]
   */
  async changeDepartTime(newTime, calcDistance = true) {
    //#region CHANGE START TIME
    this.#startTime = newTime;
    this.#updateDiffData();
    this.#endTime = null;
    this.#lastChanged = "DEPART";

    this.onChange(this.toObject());
    if (calcDistance) {
      return await this.#calculateDistance(this.#waypoints);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Changes the arrive time, triggers recalculation
   * automatically, unless specified not to
   * @param {Dayjs} newTime
   * @param {boolean} [calcDistance=true]
   */
  async changeArriveTime(newTime, calcDistance = true) {
    //#region CHANGE END TIME
    this.#endTime = newTime;
    this.#updateDiffData();
    this.#startTime = null;
    this.#lastChanged = "ARRIVE";

    this.onChange(this.toObject());
    if (calcDistance) {
      return await this.#calculateDistance(this.#waypoints);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Increases the step's times by the specified milliseconds
   * @param {number} milliseconds
   */
  shiftTimes(milliseconds) {
    //#region SHIFT TIMES
    if (this.#startTime) {
      this.#startTime = this.#startTime.add(milliseconds, "milliseconds");
    }

    if (this.#endTime) {
      this.#endTime = this.#endTime.add(milliseconds, "milliseconds");
    }

    this.onChange(this.toObject());
  }

  /**
   * Decreases the step's times by the specified milliseconds
   * @param {number} milliseconds
   */
  unshiftTimes(milliseconds) {
    //#region UNSHIFT TIMES
    if (this.#startTime) {
      this.#startTime = this.#startTime.subtract(milliseconds, "milliseconds");
    }

    if (this.#endTime) {
      this.#endTime = this.#endTime.subtract(milliseconds, "milliseconds");
    }

    this.onChange(this.toObject());
  }

  /**
   * Changes the travel mode
   * @param {google.maps.TravelMode} mode
   */
  async changeTravelMode(mode) {
    //#region CHANGE TRAVEL MODE
    this.#travelMode = mode;
    this.onChange(this.toObject());

    return await this.#calculateDistance();
  }

  /**
   * Calculates the directions between two places
   * @param {google.maps.DirectionsWaypoint[]} [waypoints]
   * @param {boolean} [creationCall=false]
   * @returns
   */
  async #calculateDistance(waypoints = undefined, creationCall = false) {
    //#region CALCULATE DISTANCE
    if (!this.#startAddress) {
      return Promise.reject("Missing departure address");
    }

    if (!this.#endAddress) {
      return Promise.reject("Missing destination address");
    }

    if (!this.#startTime && !this.#endTime) {
      return Promise.reject("Missing start and end times");
    }

    const diff = await this.#directionService
      .route({
        destination: this.#endAddress,
        origin: this.#startAddress,
        travelMode: this.#travelMode,
        avoidFerries: false,
        avoidHighways: false,
        avoidTolls: false,
        waypoints,
      })
      .catch(async () => {
        return this.#directionService.route({
          destination: this.#endAddress,
          origin: this.#startAddress,
          travelMode: this.#travelMode === "DRIVING" ? "WALKING" : "DRIVING",
          avoidFerries: false,
          avoidHighways: false,
          avoidTolls: false,
          waypoints,
        });
      })
      .then((res) => {
        this.#travelMode = res.request.travelMode;

        this.#directionsRenderer.setOptions({
          directions: res,
          polylineOptions: {
            ...POLYLINE_OPTIONS,
            ...(res.request.travelMode === "WALKING" ? TRANSIT_OPTIONS : {}),
          },
        });

        return this.#calcDistanceAndDuration(res);
      })
      .catch((err) => {
        console.log("Error getting directions data: ", err);
        this.#directionsRenderer.setDirections(null);

        return { duration: NaN, distance: NaN };
      });

    if (isNaN(diff?.duration)) {
      if (this.#lastChanged === "ARRIVE") {
        this.#startTime = null;
      } else {
        this.#endTime = null;
      }

      this.onChange(this.toObject());
      return Promise.reject("Could not get time difference");
    }

    this.#updateDiffData(diff);
    const updateDepart = this.#lastChanged === "ARRIVE" && !!this.#endTime;

    if (creationCall) {
      return;
    }

    if (updateDepart || !this.#startTime) {
      this.#startTime = this.#endTime.subtract(diff.duration, "milliseconds");
      this.onChange(this.toObject());
      return Promise.resolve();
    } else {
      this.#endTime = this.#startTime.add(diff.duration, "milliseconds");
      this.onChange(this.toObject());
      return Promise.resolve();
    }
  }

  /** @param {{duration: number, distance: number}} [param]  */
  #updateDiffData(param) {
    //#region UPDATE DIFF DATA
    if (isNaN(param?.distance)) {
      this.#distance = null;
      this.#duration = null;
    } else {
      this.#distance = param.distance;
      this.#duration = param.duration;
    }
  }

  /**
   * Calculates teh total distance based on the direction service result
   * @param {google.maps.DirectionsResult} directions
   */
  #calcDistanceAndDuration(directions) {
    if (!directions) {
      return { distance: NaN, duration: NaN };
    }

    let distance = 0,
      duration = 0;
    const route = directions?.routes?.[0];

    if (!route) {
      return { distance: NaN, duration: NaN };
    }

    for (const leg of route.legs) {
      distance += leg.distance.value;
      duration += leg.duration.value;
    }

    return { distance, duration: duration * 1000 };
  }

  /**
   * Clears the times and duration of a step
   * @param {boolean} [triggerChange=false]
   */
  clearTimes(triggerChange = false) {
    //#region CLEAR TIMES
    this.#startTime = null;
    this.#endTime = null;
    this.#duration = null;

    if (triggerChange) {
      this.onChange(this.toObject());
    }
  }

  /**
   * Increases the step index by one
   */
  shiftIndex() {
    //#region SHIFT INDEX
    this.#index = this.#index + 1;
    this.clearTimes();
  }

  /**
   * Decreases the step index by one
   */
  unshiftIndex() {
    //#region UNSHIFT INDEX
    this.#index = this.#index - 1;
    this.clearTimes();
  }

  /**
   * Removes all elements that are used to draw on the map
   */
  disconnectMapElements() {
    this.#directionsRenderer.setMap(null);
  }
}
