import { useEffect, useMemo, useReducer, useState } from "react";
import { apiRoutes, fetchData } from "../../Fleet/utils";
import { LoadableComp } from "../../XComponents";
import { formatObjectSamples } from "../utils";
import { categoriesActions } from "./actions/categoriesActions";
import { categoriesReducer } from "./reducers/categoriesReducers";
import { ReportsConfigContext } from "./ReportsConfigContext";
import { views } from "./reportsConfigurationData";
import { groupBy } from "lodash";
import ViewComponents from "./views";
import { useProgramFields } from "../../../../hooks";
import { fetchAllData } from "../../Fleet/utils/fetchAllData";
import { v4 as uuidv4 } from "uuid";

/**
 ** For better comment readability install "Better Comments" to VSCode
 *  extension id: aaron-bond.better-comments
 *
 *
 ** This is the parent component of Reports Configuration, it manages the state,
 ** inner routing, etc.
 */

const ReportsConfiguration = () => {
  const programFields = useProgramFields();

  //* This is the main state containing all categories and reports
  const [allCategories, dispatchCategories] = useReducer(categoriesReducer, []);

  //* Object Samples will act as datasources for ARJS
  const [objectSamples, setObjectSamples] = useState([]);

  //used for routing inside reportsConfiguration
  const [activeView, setActiveView] = useState(views.CATEGORIES_VIEW);
  const [selectedCategoryName, setSelectedCategoryName] = useState({});
  const [selectedReport, setSelectedReport] = useState({});
  const [loading, setLoading] = useState(true);

  //Object containing grouped categories by their department
  const reportsCategorization = programFields?.["Reports Categorization"] || {};

  //selectedCategory object
  const selectedCategory = useMemo(() => {
    return allCategories.find(
      ({ categoryName }) => categoryName === selectedCategoryName
    );
  }, [allCategories, selectedCategoryName]);

  const allCategoriesWithTableId = useMemo(() => {
    return allCategories.map((el) => Object.assign(el, { tableId: uuidv4() }));
  }, [allCategories]);

  //component of the active view
  const ViewToRender = ViewComponents[activeView];

  //redux-like dispatch
  const curryDispatch = (fn) => fn(dispatchCategories);

  //* Fetches data from the db
  useEffect(() => {
    Promise.allSettled([
      fetchData("reportConfiguration"),
      fetchData(apiRoutes.sampleObjects),
      fetchAllData("reportsAvailable", "reports", "reportId"),
    ]).then(
      ([
        { value: reportsConfigRes },
        { value: sampleObjects },
        { value: reportsAvailableRes },
      ]) => {
        /*
         * * DynamoDB allows only 400kB in 1 key, so we had to split
         * * reports configuration into 2 tables, "reportsConfiguration" that
         * * contains categories and "reportsAvailable" that contains all
         * * reports.
         * * The code below merges them back together.
         */
        //groups reports by their category { [categoryName]: [...reports] }
        const reportsAvailableObj = groupBy(
          reportsAvailableRes,
          "categoryName"
        );

        const formattedReportsConfig = reportsConfigRes.map((item) => ({
          ...item,
          reportsAvailable: reportsAvailableObj[item.categoryName] || [],
        }));

        dispatchCategories({
          type: categoriesActions.SET_CATEGORIES,
          payLoad: formattedReportsConfig,
        });

        setObjectSamples(formatObjectSamples(sampleObjects).map((obj) => obj));

        setLoading(false);
      }
    );
  }, []);

  return (
    <LoadableComp {...{ loading }}>
      <ReportsConfigContext.Provider
        value={{
          activeView,
          objectSamples,
          allCategories: allCategoriesWithTableId,
          selectedReport,
          selectedCategory,
          reportsCategorization,
          curryDispatch,
          setActiveView,
          setSelectedReport,
          dispatchCategories,
          setSelectedCategoryName,
        }}
      >
        <ViewToRender />
      </ReportsConfigContext.Provider>
    </LoadableComp>
  );
};

export default ReportsConfiguration;
