const initialState = {
  approvals: [],
  requests: [],
};

function approvalsReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_REQUESTS":
      return {
        ...state,
        requests: action.payload.requests,
      };

    case "ADD_APPROVALS":
      return {
        ...state,
        approvals: action.payload.approvals,
      };

    case "ADD_APPROVAL":
      const approvalExists = state?.approvals?.some(
        (item) => item?.requestId === action?.payload.requestId
      );
      if (!approvalExists) {
        return {
          ...state,
          approvals: [...state?.approvals, action.payload],
        };
      } else {
        return {
          ...state,

          approvals: state.approvals?.map((item) => {
            if (item?.requestId === action.payload?.requestId) {
              return action.payload;
            } else {
              return item;
            }
          }),
        };
      }

    case "ADD_REQUEST":
      const requestExists = state.requests.some(
        (item) => item.requestId === action.payload.requestId
      );
      if (!requestExists) {
        return {
          ...state,
          requests: [...state.requests, action.payload],
        };
      } else {
        return {
          ...state,

          requests: state.requests?.map((item) => {
            if (item?.requestId === action.payload?.requestId) {
              return action.payload;
            } else {
              return item;
            }
          }),
        };
      }

    case "UPDATE_APPROVAL":
      const updatedApprovals = state.approvals.map((item) => {
        if (item.requestId === action.payload.requestId) {
          const { requestId, responseFrom, ...rest } = action.payload;

          return {
            ...item,
            ...action.payload,
            responseFrom,
            requestObject: {
              ...item.requestObject,
              ...rest,
            },
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        approvals: updatedApprovals,
      };

    case "UPDATE_REQUEST":
      const updatedRequests = state.requests.map((item) => {
        if (item.requestId === action.payload.requestId) {
          const { requestId, responseFrom, ...rest } = action.payload;

          return {
            ...item,
            ...action.payload,
            responseFrom,
            requestObject: {
              ...item.requestObject,
              ...rest,
            },
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        requests: updatedRequests,
      };

    case "DELETE_APPROVAL_BY_ID":
      // Filter out the deleted item based on its id or some identifier
      const filteredApprovals = state.approvals.filter(
        (approval) => approval.requestId !== action.payload.id
      );

      return {
        ...state,
        approvals: filteredApprovals,
      };

    case "DELETE_REQUEST_BY_ID":
      const filteredRequests = state.requests.filter(
        (approval) => approval.requestId !== action.payload.id
      );

      return {
        ...state,
        requests: filteredRequests,
      };

    default:
      return state;
  }
}

export default approvalsReducer;
