import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form } from "antd";
import { v4 as uuid } from "uuid";

import { MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";

import "./NewExcludeModal.scss";

function NewExcludeModal({
  open,
  onCancel,
  editData,
  onConfirm,
  drivers,
  excludeType,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [form] = Form.useForm();

  useEffect(() => {
    //#region FORM EFFECT
    if (!editData) {
      return;
    }

    form.setFieldsValue({
      excludeType: editData.excludeType,
      excludeDate: dayjsNY(editData.excludeDate),
      driverId: editData.driverId,
      excludeComments: editData?.excludeComments,
    });
  }, [editData]);

  async function onSave() {
    //#region ON SAVE
    const values = await form.validateFields().catch(() => {
      return;
    });

    if (!values) {
      return;
    }

    const driverName = drivers.find(
      (e) => e.driverId === values.driverId
    )?.driverName;

    const excludeId = editData?.excludeId || uuid();
    onConfirm({
      ...values,
      excludeDate: values.excludeDate.format(),
      excludeId,
      driverName,
    });
    onCancel();
  }

  //#region JSX
  return (
    <Modal
      {...{
        open,
        onCancel,
        title: editData ? "Edit Exclude" : "New Exclude",
        centered: true,
        "data-testid": "new-exclude-modal",
        className: `new-exclude-modal ${
          isDarkMode ? "new-exclude-modal-dark" : ""
        }`,
        closeIcon: <XIcon />,
        footer: [
          <MondayButton
            {...{
              Icon: <XIcon />,
              className: "mondayButtonRed",
              onClick: onCancel,
              "data-testid": "exclude-cancel",
            }}
            key="cancel"
          >
            Cancel
          </MondayButton>,
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: onSave,
              "data-testid": "exclude-confirm",
            }}
            key="confirm"
          >
            Confirm
          </MondayButton>,
        ],
      }}
    >
      <Form form={form}>
        <InputComponent
          label="Exclude Type"
          placeholder="Exclude Type..."
          disabled
          value={excludeType}
          required
          formItemName="excludeType"
          initialValue={excludeType}
          dataTestid="excludeType"
        />
        <InputComponent
          type="datePicker"
          allowClear={false}
          placeholder="Exclude Date..."
          label="Exclude Date"
          required
          dataTestid="excludeDate"
          formItemName="excludeDate"
          initialValue={dayjsNY().startOf("D")}
          defaultValue={dayjsNY().startOf("D")}
        />
        <InputComponent
          type="select"
          formItemName="driverId"
          dataTestid="driverId"
          label="Driver"
          customOptions={drivers
            .map((e) => ({
              label: e.driverName,
              value: e.driverId,
            }))
            .sort((a, b) =>
              a.label
                .toLocaleLowerCase()
                .localeCompare(b.label.toLocaleLowerCase())
            )}
          placeholder="Driver..."
          required
          allowClear={false}
        />
        <InputComponent
          type="textarea"
          placeholder="Comments..."
          dataTestid="excludeComments"
          label="Comments"
          required={false}
          formItemName="excludeComments"
          style={{
            height: "120px",
          }}
        />
      </Form>
    </Modal>
  );
}

export default NewExcludeModal;
