import { Grid } from "antd";

import "./CustomTooltip.scss";

export default function CustomTooltip(props) {
  const {
    content,
    arrow = false,
    position = "bottom",
    className = "",
    containerClassName = "",
    style = {},
    children,
    tooltipApi,
    ...otherProps
  } = props;

  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();
  const { handleMouseEnter, handleMouseLeave } = tooltipApi;

  const isDesktop = screens["lg"];
  const isTablet = screens["md"];

  return (
    <div
      className={`tooltip-container ${containerClassName}`}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testId="tooltip-container"
    >
      {children}
      {content ? (
        <div
          className={`tooltip-content tooltip-${position} ${className}`}
          data-testId="tooltip-content"
          style={{
            maxWidth: isDesktop ? 500 : isTablet ? 400 : "auto",
            ...style,
          }}
          {...otherProps}
        >
          {content}
          {arrow && <span className="arrow"></span>}
        </div>
      ) : null}
    </div>
  );
}
