async function safetyReportFormatter(data = [], driveRequest) {
  return Promise.all(
    data.map(async (report) => {
      const { googleDriveUpload = [], safetyApplicationObject } = report;
      const driveImages =
        googleDriveUpload?.filter(
          ({ type = "", mimeType = "" }) =>
            type === "photo" || mimeType?.includes("image")
        ) || [];

      let images = [];
      if (driveImages?.length) {
        images = await Promise.all(
          driveImages.map(async (file) => {
            const { id } = file;
            const { src } = await driveRequest?.getImageSrc(id);
            return { ...file, driveImagesSrc: src.split(",")[1] };
          })
        );
      }
      let visualizationImages = [];
      if ((safetyApplicationObject?.visualizationImages || [])?.length > 0) {
        visualizationImages = await Promise.all(
          safetyApplicationObject?.visualizationImages.map(async (file) => {
            const { id } = file;
            const { src } = await driveRequest.getImageSrc(id);
            return { ...file, visualizationSrc: src.split(",")[1] };
          })
        );
      }
      return {
        ...report,
        googleDriveUpload: images,
        safetyApplicationObject: {
          ...safetyApplicationObject,
          visualizationImages,
        },
      };
    })
  );
}
export default safetyReportFormatter;
