import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import styles from "./responsiveInfo.module.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import { useResponsive } from "../../../hooks";

const ResponsiveInfo = ({ text, tooltipOnMobile = false, style }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { mobile } = useResponsive();

  return (
    <div
      data-testid="container"
      className={`${styles.container} ${isDarkMode && styles.containerDark}`}
      style={style}
    >
      {tooltipOnMobile && mobile ? (
        <Tooltip title={text}>
          <InfoCircleFilled
            data-testid="info-icon"
            style={{ color: "#1264A3", fontSize: 20 }}
          />
        </Tooltip>
      ) : (
        <>
          <InfoCircleFilled
            data-testid="info-icon"
            style={{ color: "#1264A3", fontSize: 20 }}
          />
          <p className={styles.text}>{text}</p>
        </>
      )}
    </div>
  );
};

export default ResponsiveInfo;
