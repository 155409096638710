import { ForwardedRef, forwardRef } from "react";
import { Card } from "antd";

import { hexToRgba } from "src/utils";
import { SmallInfoCardType } from "../../types";

import "./SmallDashboardCard.scss";


type SmallDashboardCardProps = {
  data: SmallInfoCardType;
  className?: string;
  style?: { [key: string]: string };
  onMouseUp?: () => any;
  onTouchEnd?: () => any;
  onMouseDown?: () => any;
  children?: JSX.Element;
  cardKey?: string;
  isDarkMode: boolean;
};

const SmallDashboardCard = forwardRef(
  (
    {
      data,
      style,
      cardKey,
      children,
      onMouseUp,
      className,
      onTouchEnd,
      isDarkMode,
      onMouseDown,
    }: SmallDashboardCardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const DataIcon = data.icon;

    return (
      <div
        {...{
          ref,
          style,
          onMouseUp,
          onTouchEnd,
          onMouseDown,
          className: `dashboard-small-card-container ${
            isDarkMode ? "small-card-container-dark" : ""
          } ${className} drag-handle`,
        }}
        key={cardKey}
      >
        <Card.Meta
          {...{
            avatar: (
              <div
                className="data-icon-container"
                style={{
                  color: data.color,
                  borderColor: data.color,
                  backgroundColor: hexToRgba(data.color, 0.4),
                }}
              >
                <DataIcon />
              </div>
            ),
            className: "small-dashboard-meta",
            title: data.title,
            description: (
              <span style={{ color: data.color }}>{data.content}</span>
            ),
          }}
        />
        {children}
      </div>
    );
  }
);

export default SmallDashboardCard;
