import { getDispatchLocationOptions } from "../../RequestFormModal/requestFormModalData";
import { MaxTagTooltip } from "../../../../commonComponents";

const hearingDynamicInputFields = ({
  dynamicFields,
  nameDisabled,
  projects,
  onLocationChange,
  schedulesForDate,
  category,
  claimDate,
  hearingTeam,
  onTeamSelect,
  onTeamDeselect,
  form,
  isDarkMode,
  modalTitle,
  selectedTeam = [],
  projectExecutives = [],
  subcontractors,
  employerField,
  hearingAddressField,
  crews,
}) => {
  const foremen = crews
    ?.filter((e) => !e.foreman)
    ?.map(({ crewId, crewName, members }) => ({
      label: crewName,
      value: crewId,
      members,
    }));

  const projectExecutivesOpt =
    projectExecutives?.map((executive) => ({
      id: executive.peId,
      label: `${executive.firstName} ${executive.lastName}`,
      value: `${executive.firstName} ${executive.lastName}`,
    })) || [];

  const isWCHearing = modalTitle === "Hearing";
  let teamOptions = hearingTeam || [];
  if (selectedTeam?.some((team) => team?.value === "Team")) {
    teamOptions.push({
      label: "Team",
      value: "Team",
      members: [],
    });
  }
  const teamField = {
    label: "Team",
    formItemName: "hearingTeam",
    placeholder: "Select team",
    type: "select",
    customOptions: teamOptions,
    mode: "multiple",
    onSelect: onTeamSelect,
    onDeselect: onTeamDeselect,
    // style: { width: "100%", maxWidth: 378.5 },
    required: true,
    allowClear: false,
    maxTagCount: 2,
    maxTagPlaceholder: (e) => {
      return (
        <MaxTagTooltip
          {...{
            array: e,
            onDeselect: onTeamDeselect,
          }}
        />
      );
    },
  };

  const newInputFields = dynamicFields
    ?.filter((el) => el?.claimCategory === category)
    ?.filter((field) => {
      if (isWCHearing && field.label === "Attorney for Plaintiff") {
        return false;
      }
      if (!isWCHearing && field.label === "Injury Description in Summons") {
        return false;
      }
      return true;
    })
    ?.map((el) => {
      if (el?.formItemName === "claimNumber") {
        el.disabled = true;
        el.label = isWCHearing ? "WCB number" : "Claim Number";
        el.placeholder = isWCHearing
          ? "Enter WCB number"
          : "Enter Claim Number";
      } else if (el.formItemName === "claimant") {
        el.label = isWCHearing ? "Claimant" : "Plaintiff";
        el.placeholder = isWCHearing ? "Enter Claimant" : "Enter Plaintiff";
      } else if (el.formItemName === "employer") {
        el.type = "radio";
      } else if (el.label === "representative") {
        el.disabled = nameDisabled;
        el.label = isWCHearing
          ? "Hearing Representative"
          : "Defense Counsel Information";
        el.placeholder = isWCHearing
          ? "Enter Hearing Representative"
          : "Enter Defense Counsel Information";
        el.rules = [
          { min: 3, message: "Name must be at least 3 characters!" },
          {
            validator: (_, value) => {
              const regex = /^[a-zA-Z0-9\s]+$/; // Allow spaces
              if (regex.test(value.trim())) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  "Hearing Representative must contain only letters, numbers"
                );
              }
            },
          },
        ];
      } else if (el.label === "Foreman/Supervisor") {
        el.type = "select";
        el.customOptions = foremen;
        el.showSearch = true;
        el.allowClear = true;
      } else if (el.formItemName === "hearingDate") {
        el = {
          ...el,
          label: `${modalTitle} Date`,
          form,
          validTime: "future",
          validationDate: claimDate,
          isDarkMode,
        };
      } else if (el.label === "Attending Hearings on Core's behalf") {
        el.formItemName = "hearingAddress";
        el.label = isWCHearing
          ? "Attending Hearings on Core's behalf"
          : "Attending Deposition on Core's behalf";
        el.placeholder = isWCHearing
          ? "Enter Hearing Address"
          : "Enter Deposition Address";
      } else if (el.formItemName === "time") {
        el = {
          ...el,
          form,
          label: `${modalTitle} Time`,
          placeholder: "Select Time",
          validTime: "future",
          isDarkMode,
          dateField: "hearingDate",
        };
      } else if (el.formItemName === "projectExecutive") {
        el.type = "select";
        el.customOptions = projectExecutivesOpt;
        el.showSearch = true;
        el.allowClear = true;
      } else if (el.formItemName === "jobSiteAddress") {
        el.groupedOptions = getDispatchLocationOptions(
          schedulesForDate,
          projects
        );
        el.onSelect = onLocationChange;
        el.showSearch = true;
      }
      return el;
    });

  if (newInputFields?.some((el) => el?.index)) {
    newInputFields.sort((a, b) => a.index - b.index);
  }
  if (employerField === "Subcontractor") {
    let checkboxIndex = newInputFields?.findIndex(
      ({ formItemName }) => formItemName === "employer"
    );
    let conditionalInputField = {
      type: "select",
      options: subcontractors,
      label: "Company Name",
      formItemName: "companyName",
      showSearch: true,
      required: true,
    };
    if (checkboxIndex > -1) {
      newInputFields.splice(checkboxIndex + 1, 0, conditionalInputField);
    } else {
      newInputFields.push(conditionalInputField);
    }
  } else if (employerField === "Other") {
    let checkboxIndex = newInputFields?.findIndex(
      ({ formItemName }) => formItemName === "employer"
    );
    let conditionalInputField = {
      type: "input",
      label: "Other Employer Name",
      formItemName: "otherEmployerName",
      // required: true,
    };
    if (checkboxIndex > -1) {
      newInputFields.splice(checkboxIndex + 1, 0, conditionalInputField);
    } else {
      newInputFields.push(conditionalInputField);
    }
  }
  if (hearingAddressField === "Other") {
    let checkboxIndex = newInputFields?.findIndex(
      ({ formItemName }) => formItemName === "hearingAddress"
    );
    let conditionalInputField = {
      type: "input",
      label: "Other Hearing Address",
      formItemName: "otherHearingAddress",
      // required: true,
    };
    if (checkboxIndex > -1) {
      newInputFields.splice(checkboxIndex + 1, 0, conditionalInputField);
    } else {
      newInputFields.push(conditionalInputField);
    }
  }
  return [
    ...newInputFields,
    //  teamField
  ];
};

export default hearingDynamicInputFields;
