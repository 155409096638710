import { useState } from "react";
import { Button, Col, Divider, Flex, Row, Splitter } from "antd";

import Headline from "../../../../commonComponents/Base/Headline";
import Text from "../../../../commonComponents/Base/Text";
import ImageViewer from "./ImageViewer";

export default function List({ services = [] }) {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [open, setOpen] = useState(false);

  const handleViewImages = (questions) => {
    const filteredQuestions = questions?.filter(
      (question) => !!question?.fileIds.length
    );

    setSelectedQuestions(filteredQuestions);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getImagesLength = (category) => {
    return category?.questions?.flatMap(({ fileIds }) => fileIds)?.length;
  };

  return (
    <Row gutter={[0, 40]} className="classNamePerOmerin">
      {services?.map((service, index) => (
        <Col key={index} style={{ width: "100%" }}>
          <Headline>{service?.serviceName}</Headline>

          <Divider style={{ margin: 0 }} />

          {service?.serviceQuestions?.map((category, i) => (
            <Row style={{ marginTop: 10 }} key={i}>
              <Col xs={10} md={12}>
                <Flex
                  justify="space-between"
                  align="center"
                  style={{ height: "100%" }}
                >
                  <Text>{category?.categoryName}</Text>
                  <Divider type="vertical" />
                </Flex>
              </Col>
              <Col xs={6} md={6}>
                <Flex
                  justify="space-between"
                  align="center"
                  style={{ height: "100%" }}
                >
                  <Text>{getImagesLength(category)} Images</Text>
                  {!!getImagesLength(category) && <Divider type="vertical" />}
                </Flex>
              </Col>
              {!!getImagesLength(category) && (
                <Col xs={8} md={6}>
                  <Flex
                    justify="center"
                    align="center"
                    style={{ height: "100%" }}
                  >
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => handleViewImages(category?.questions)}
                    >
                      View Images
                    </Button>
                  </Flex>
                </Col>
              )}
            </Row>
          ))}
        </Col>
      ))}

      {open && (
        <ImageViewer
          open={open}
          questions={selectedQuestions}
          onClose={handleClose}
        />
      )}
    </Row>
  );
}
