import { Badge, Drawer, message } from "antd";
import { useState } from "react";
import { XIcon } from "../../../SidebarPages/Communication/assets";
import { InfoText } from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import SessionUserList from "./components/SessionUserList";
import "./SessionsDrawer.scss";
import { UsersIcon } from "../../../../icons";
import { useQuery, useSessionSocket } from "../../../../hooks";
import { WithTooltip } from "../../../commonComponents";
import { UserSessionModal } from "../../../pages/Settings/settingsComponents/AllUsers/components";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import SessionLogsDrawer from "./SessionLogsDrawer/SessionLogsDrawer";
import { useDispatch } from "react-redux";
import { updateReduxSession } from "../../../../reducers/commonsStates/sessionNotificationsReducer";
import SessionLogsModal from "./SessionLogsModal/SessionLogsModal";

function SessionCheck({ isDarkMode }) {
  const [visible, setVisible] = useState(false);
  const { online } = useSessionSocket();
  const { sessionNotifications } = useSelector(
    (state) => state.sessionNotifications
  );
  const { newCount } = sessionNotifications || {};
  const dispatch = useDispatch();
  const [userSessionModalVisible, setUserSessionModalVisible] = useState(false);
  const [openSessionLogs, setOpenSessionLogs] = useState(false);
  let query = useQuery();
  let sessionUserId = query.get("sessionUserId");

  async function getSessionLogs() {
    message.loading({ content: "Loading logs...", key: "loadingLogs" });
    await API.get("userSessions", `/userSessions`)
      .then((response) => {
        message.success({
          content: "Logs loaded successfully!",
          key: "loadingLogs",
          duration: 2,
        });
        const result = Array.isArray(response)
          ? response
          : response?.userSessions || [];
        dispatch(updateReduxSession("set", result));
        setOpenSessionLogs(true);
      })
      .catch((error) => {
        message.error({
          content: "Failed to load logs!",
          key: "loadingLogs",
          duration: 2,
        });
        console.log("SessionCheck ~ getSessionLogs ~ error", error);
      });
  }

  return (
    <div className="icon-container">
      <WithTooltip tooltipCategory="HEADER" tooltipKey="userSessions">
        <Badge className="ignore-onclickoutside" count={newCount || 0}>
          <UsersIcon
            onClick={() => {
              setVisible((prev) => !prev);
            }}
            className="header-icon"
          />
        </Badge>
      </WithTooltip>
      {visible && (
        <Drawer
          {...{
            open: visible,
            onClose: () => {
              setVisible(false);
            },
            width: 700,
            closeIcon: <XIcon />,
            className: `session-drawer ${
              isDarkMode ? "session-drawer-dark" : ""
            }`,
            title: (
              <div className="session-drawer-title">
                <span>Live User Sessions</span>
                <span className="logsButton" onClick={getSessionLogs}>
                  <Badge
                    className="ignore-onclickoutside"
                    count={newCount || 0}
                    offset={[5, -3]}
                  >
                    Session Logs
                  </Badge>
                </span>
              </div>
            ),
          }}
        >
          <InfoText
            {...{
              text: online
                ? "View the current status of all users, including those who are online, offline, or logged off to monitor user activity effectively."
                : "Currently could not connect to the server. Please try again later.",
              isDarkMode,
              style: {
                maxWidth: "100%",
                padding: "20px 20px 0 20px",
              },
            }}
          />
          <SessionUserList
            {...{
              isDarkMode,
              online,
            }}
          />
        </Drawer>
      )}
      {!!openSessionLogs && (
        <SessionLogsModal
          {...{
            visible: openSessionLogs,
            setVisible: setOpenSessionLogs,
          }}
        />
      )}
      {/* don't wrap with conditional rendering */}
      <UserSessionModal
        {...{
          visible: userSessionModalVisible,
          setVisible: setUserSessionModalVisible,
          user: {},
          ws: true,
          queryParam: sessionUserId,
        }}
      />
    </div>
  );
}

export default SessionCheck;
