import { Collapse, Input, Modal } from "antd";
import { useState } from "react";
import { antdModalProps } from "../../../../../../../../utils";
import { MondayButton } from "../../../../../../../commonComponents";
import { DropdownIcon } from "../../../../../../BasePage/src";
import {
  Add,
  HandIcon,
} from "../../../../../../DynamicView/components/FilterView/FilterIcons";
import { DeleteIcon, EditIcon } from "../../../../../../DynamicView/src";
import { useJumpsModalContext } from "../../context";
import RemainingFloorList from "../RemainingFloorList/RemainingFloorList";
import "./JumpsList.scss";

const JumpsList = () => {
  const {
    elevation,
    setElevation,
    remainingFloors = [],
  } = useJumpsModalContext();

  const [isEditJumpNameModalVisible, setIsEditJumpNameModalVisible] =
    useState(false);
  const [isAddFloorsModalVisible, setIsAddFloorsModalVisible] = useState();

  const [activeJump, setActiveJump] = useState(null);
  const [activeJumpIndex, setActiveJumpIndex] = useState(null);
  const [jumpName, setJumpName] = useState("");

  // Open modal to edit jump name
  const handleOpenEditJumpNameModal = (jumpName, jumpIndex) => (e) => {
    e.stopPropagation();
    setIsEditJumpNameModalVisible(true);
    setActiveJump(jumpName);
    setJumpName(jumpName);
    setActiveJumpIndex(jumpIndex);
  };

  // Deletes a jump
  const handleDeleteJump = (jumpName) => (e) => {
    e.stopPropagation();

    setElevation(({ jumps, ...rest }) => ({
      ...rest,
      jumps: jumps.filter(({ name }) => name !== jumpName),
    }));
  };

  // Deletes a floor from a jump
  const handleDeleteFloorsForJump = (jumpName, floorToDelete) => {
    setElevation(({ jumps, ...rest }) => ({
      ...rest,
      jumps: jumps.map((jump) =>
        jump.name === jumpName
          ? {
              ...jump,
              floors: jump.floors.filter(
                ({ floor }) => floor !== floorToDelete
              ),
            }
          : jump
      ),
    }));
  };

  // Open modal to add floors
  const handleAddFloorsModal = (jumpName) => {
    setIsAddFloorsModalVisible(true);
    setActiveJump(jumpName);
  };

  // Updates jump name
  const handleEditJumpName = () => {
    setElevation(({ jumps, ...rest }) => ({
      ...rest,
      jumps: jumps.map((jump, idx) =>
        idx === activeJumpIndex ? { ...jump, name: jumpName } : jump
      ),
    }));
    closeModalForJumpName();
  };

  // Close modal for jump name
  const closeModalForJumpName = () => {
    setIsEditJumpNameModalVisible(false);
    setActiveJump(null);
    setActiveJumpIndex(null);
    setJumpName("");
  };

  // Close modal for floors
  const closeModal = () => {
    setIsAddFloorsModalVisible(false);
    setActiveJump(null);
  };

  // Adds floors to jump
  const addFloorsToJump = (selectedFloors = []) => {
    setElevation(({ jumps, ...rest }) => ({
      ...rest,
      jumps: jumps.map((jump) =>
        jump.name === activeJump
          ? {
              ...jump,
              floors: [
                ...jump.floors,
                ...selectedFloors.map((floor) => ({ floor })),
              ],
            }
          : jump
      ),
    }));
    closeModal();
  };

  console.log("jump", elevation?.jumps);

  return (
    <div className="hoist-jumps-list">
      {!!elevation?.jumps?.length ? (
        <>
          <div className="hoist-jumps-list-title">
            Configure Jumps for Hoist
          </div>
          <Collapse
            expandIcon={({ isActive }) => (
              <div className="dropdownIconContainer">
                <DropdownIcon rotate={isActive ? 90 : 0} />
              </div>
            )}
          >
            {elevation?.jumps?.map((jump, jumpIndex) => (
              <Collapse.Panel
                header={<div className="jumpHeaderName">{jump.name}</div>}
                key={`${elevation?.elevationId}/${jump.name}`}
                extra={
                  <>
                    <div
                      className="editIconButton"
                      onClick={handleOpenEditJumpNameModal(
                        jump.name,
                        jumpIndex
                      )}
                    >
                      <EditIcon />
                    </div>
                    <div
                      className="deleteIconButton"
                      onClick={handleDeleteJump(jump.name)}
                    >
                      <DeleteIcon />
                    </div>
                  </>
                }
              >
                {jump?.floors
                  ?.filter((el) => el?.floor !== 0)
                  ?.map((floor) => (
                    <div className="hoist-jumps-list-jump">
                      <div className="hoist-jumps-list-jump-name">
                        <div className="hoist-jumps-list-jump-name-container">
                          <div> Floor {floor.floor}</div>
                          <div className="stop-icons-container">
                            <div
                              className="stopIcon"
                              style={{
                                fill: elevation?.items?.find(
                                  ({ floor: arrFloor }) =>
                                    arrFloor === floor.floor
                                )?.stop
                                  ? "#71CF48"
                                  : "#FE4C4A",
                              }}
                            >
                              <HandIcon />
                            </div>
                            {elevation?.type === "dual" && (
                              <div
                                className="stopIcon"
                                style={{
                                  fill: elevation.items.find(
                                    ({ floor: arrFloor }) =>
                                      arrFloor === floor.floor
                                  )?.dual
                                    ? "#71CF48"
                                    : "#FE4C4A",
                                }}
                              >
                                <HandIcon />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="deleteIconButtonFloor"
                        onClick={() =>
                          handleDeleteFloorsForJump(jump.name, floor.floor)
                        }
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  ))}
                {!!remainingFloors?.length && (
                  <div className="hoist-jumps-list-add-floors">
                    {/* <Button onClick={() => handleAddFloorsModal(jump.name)}>Add floors</Button> */}

                    <MondayButton
                      className="mondayButtonBlue"
                      Icon={<Add />}
                      onClick={() => handleAddFloorsModal(jump.name)}
                    >
                      Add floors
                    </MondayButton>
                  </div>
                )}
              </Collapse.Panel>
            ))}
          </Collapse>
        </>
      ) : (
        <div className="no-jumps-for-elevation">
          <img
            className="landi"
            src={require("../../../../../../BasePage/src/hoistPhoto.webp")}
            alt="Logo"
            style={{
              width: "100%",
              maxHeight: "400px",
            }}
          />
          <div className="noJumpsForElevationText">
            No floor selected for any jump.
          </div>
        </div>
      )}
      <Modal
        {...antdModalProps}
        open={isAddFloorsModalVisible}
        title="Add more floors for this jump"
        onCancel={closeModal}
      >
        <RemainingFloorList onAddFloors={addFloorsToJump} />
      </Modal>
      <Modal
        {...{ ...antdModalProps, footer: undefined }}
        open={isEditJumpNameModalVisible}
        title="Edit jump name"
        onCancel={closeModalForJumpName}
        onOk={handleEditJumpName}
      >
        <Input
          placeholder="Enter new name for jump"
          value={jumpName}
          onChange={(e) => setJumpName(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default JumpsList;
