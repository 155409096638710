import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { ValidateEmail } from "../../utils/validations";
import "./AddEventModal.scss";
import { API } from "aws-amplify";
import { DropDownArrow } from "../../../../../SidebarPages/Fleet/components/InputComponent/assets";

const ParticipantsInput = ({ selectedTags }) => {
  const [users, setUsers] = useState([]);
  const [emails, setEmails] = useState([]);

  const children = users.map((el) => (
    <Select.Option key={el}>{el}</Select.Option>
  ));

  useEffect(() => {
    API.get("userConfiguration", "/userConfiguration").then((res) => {
      setUsers(
        res.allUsers.Items.filter(
          (user) => user.userName && user.userName !== ""
        ).map((user) => user.userName)
      );
    });
  }, []);

  const handleChange = (inputValue) => {
    const emailsArr = inputValue;
    const validatedEmails = emailsArr.filter((email) => ValidateEmail(email));
    setEmails(validatedEmails);
  };

  useEffect(() => {
    if (emails.length !== 0 && emails !== undefined) {
      selectedTags(emails);
    }
  }, [emails]);

  return (
    <div>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%", background: "#f5f5f7" }}
        placeholder="Please select"
        suffixIcon={<DropDownArrow />}
        value={emails}
        onChange={handleChange}
      >
        {children}
      </Select>
    </div>
  );
};

export default ParticipantsInput;
