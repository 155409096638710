import { sortDates } from "../../../../ToDos/AgGridData/columnDefs";
import { formatCurrency } from "../../../../utils";
import InvoiceHistoryReportWrapper from "../InvoiceHistoryReport/InvoiceHistoryReportWrapper";

const cellStyles = () => ({
  fontSize: "18px",
  alignItems: "center",
  justifyContent: "center",
  borderRight: "solid",
  borderRightWidth: "0.7px",
  borderRightColor: "#999999",
});
export const openBalanceColumnDefs = ({ isDarkMode }) => [
  {
    headerName: "No.",
    field: "key",
  },
  {
    headerName: "Client",
    field: "accountName",
  },
  {
    headerName: "Project",
    field: "projectName",
  },
  {
    headerName: "InvoiceId",
    field: "invoiceId",
    hide: true,
  },
  {
    headerName: "QuickBooks Number",
    field: "quickBooksNumber",
  },
  {
    headerName: "Memo/s",
    field: "memos",
    cellClass: "link-cell",
    valueGetter: (params) => {
      return params?.data?.memos
        ?.map((el, idx) => {
          return el?.memoValue;
        })
        .join(", ");
    },
    cellRendererFramework: (params) => {
      const value = params?.value || "";

      if (typeof value !== "string" || value.length === 0) {
        return <></>;
      }

      const memos = params?.data?.memos || [];
      const memoValues = memos.map((el) => el?.memoValue).join(", ");

      return <>{memoValues}</>;
    },
  },
  {
    headerName: "Num",
    field: "num",
    cellClass: "link-cell",
    cellRendererFramework: (params) => {
      return (
        <a
          href={`/invoices/${params.data?.invoiceId}`}
          target="_blank"
          rel="noreferrer"
          className="invoice-link"
        >
          Invoice {params.value}
        </a>
      );
    },
  },
  {
    headerName: "Invoice Original Amount",
    field: "totalInvoiceAmount",
    cellStyle: cellStyles,
    cellClass: `invoice-amount ${isDarkMode && "cell-amount-dark"}`,
    cellRendererFramework: (params) => formatCurrency(params.value),
  },
  {
    headerName: "Open Balance",
    field: "openBalance",
    cellStyle: cellStyles,
    cellClass: `balance-amount ${isDarkMode && "cell-amount-dark"}`,
    pdfExportOptions: {
      modifyCellValue: (params) => {
        return formatCurrency(params);
      },
    },
    cellRendererFramework: (params) => formatCurrency(params.value),
  },
  {
    headerName: "Received Payment",
    field: "receivedPayment",
    cellStyle: cellStyles,
    cellClass: `payment-amount ${isDarkMode && "cell-amount-dark"}`,
    pdfExportOptions: {
      modifyCellValue: (params) => {
        return formatCurrency(params);
      },
    },
    cellRendererFramework: (params) => formatCurrency(params.value),
  },
  {
    headerName: "Invoice Date",
    field: "invoiceDate",
    filter: "agDateColumnFilter",
    sort: "desc",
    comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY"),
  },
  {
    headerName: "To Date",
    field: "toDate",
  },
  {
    headerName: "Aging",
    field: "aging",
  },
  {
    headerName: "Invoice Report",
    cellRenderer: (params) => {
      return <InvoiceHistoryReportWrapper params={params} />;
    },
  },
];
