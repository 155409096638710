import React, { useEffect } from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebars/Sidebar";
import { useMediaQuery } from "react-responsive";
import Header from "./Header/Header";
import AuthenticatedRouteController from "./AuthenticatedRouteController";
import { useSelector } from "react-redux";
import { updateDocumentTitle } from "../utils";

const LayoutConfig = ({ props, pref, routesElements }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const isMobileView = useMediaQuery({
    query: "(min-width: 570px)",
  });

  useEffect(() => {
    const title = routesElements[window.location?.pathname]?.title?.replace(
      /\/view/gi,
      ""
    );
    title && updateDocumentTitle({ newTitle: title }); // set Title of Tab
    return () => {
      title && updateDocumentTitle(); // reset document title to "Lead Manager";
    };
  }, [window.location?.pathname]);

  return (
    <Layout
      style={{ width: "100vw", height: "100vh", fontFamily: "Open Sans" }}
    >
      {!!isMobileView && <Sidebar {...props} />}
      <Layout style={{ height: "100vh", overflow: "hidden" }}>
        <Header props={props} />
        <Layout.Content
          style={{
            background: isDarkMode ? "#20212D" : "#F4F5F6",
            display: "flex",
          }}
        >
          <AuthenticatedRouteController
            {...{ pref, childProps: props, routesElements }}
          />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default LayoutConfig;
