import React from "react";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../../integrations/DriveRequest";
import { Member, Users } from "../helpers/helpers";
import "./PreviewAvatar.scss";
import { useNavigate } from "react-router-dom";

interface Props {
  member: Member;
  googleDriveFileId: string;
  users?: Array<Users>;
}

interface Avatar {
  src?: string;
}

const PreviewAvatar = (props: Props) => {
  const { member, googleDriveFileId, users } = props;

  const { accessToken } = useSelector((state) => state["accessToken"]);

  const driveRequest = driveApi({ accessToken });
  const [currentFile, setCurrentFile] = React.useState<Avatar>();
  const userOfMember = React.useMemo<Users>(() => {
    return users?.find((user) => user.identityId === member.identityId);
  }, [users, member]);

  const image = async () => {
    if (!!googleDriveFileId && googleDriveFileId !== "") {
      await driveRequest
        .getImageSrc(googleDriveFileId)
        .then((image) => setCurrentFile({ ...image }));
    }
  };

  React.useEffect(() => {
    let isMounted = true; // this flag indicates whether the component is still mounted
    if (googleDriveFileId && accessToken) image();
    return () => {
      isMounted = false; // change the flag when the component unmounts
    };
  }, [googleDriveFileId, accessToken]);

  return (
    <div className="previewMember">
      <div className="previewAvatar">
        {currentFile?.src ? (
          <img
            src={currentFile?.src || ""}
            alt="avatar"
            width={70}
            height={70}
            className="previewAvatar__img"
          />
        ) : (
          <AvatarCreator
            {...{
              fullName: member?.nameOfUser,
              identityId: userOfMember?.identityId,
              departmentName: userOfMember?.departmentName,
              groupName: userOfMember?.groupName,
            }}
          />
        )}
      </div>
      <div className={`previewMember__text`}>
        <label
          className={`previewMember__textParagraph previewMember__textBold ${
            userOfMember?.isSuspended
              ? "previewMember__suspended suspended__textBold"
              : ""
          }`}
        >
          {member.nameOfUser +
            (userOfMember?.isSuspended ? " (Suspended)" : "")}
        </label>
        <label
          className={`previewMember__textParagraph unSelectable ${
            userOfMember?.isSuspended ? "previewMember__suspended" : ""
          }`}
        >
          {member.groupName}
        </label>
        <label
          className={`previewMember__textParagraph ${
            userOfMember?.isSuspended ? "previewMember__suspended" : ""
          }`}
        >
          {member.userName}
        </label>
      </div>
    </div>
  );
};

export default PreviewAvatar;

export const AvatarCreator = React.memo(
  ({
    fullName,
    customStyle = {},
    customParagraphStyle = {},
    identityId,
    departmentName,
    groupName,
  }: {
    fullName: string;
    customStyle?: React.CSSProperties;
    customParagraphStyle?: React.CSSProperties;
    identityId: string;
    departmentName: string;
    groupName: string;
  }) => {
    const navigate = useNavigate();
    const { userConfiguration } = useSelector((state) => state["userConfig"]);
    const { preferences } = useSelector((state) => state["preferences"]);

    const [initials] = React.useState(getInitials(fullName));
    return (
      <div
        style={{
          backgroundColor:
            userConfiguration.nameOfUser === fullName
              ? preferences.preferences?.profileColor || "#4895ef"
              : "#F8F8FA",
          height: 70,
          width: 70,
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...customStyle,
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(
            `/settings/allUsers/${departmentName}/${groupName}/${identityId}`
          );
        }}
      >
        <p
          style={{
            fontFamily: "Open Sans",
            fontWeight: 600,
            fontSize: 28,
            padding: 0,
            margin: 0,
            color:
              userConfiguration.nameOfUser === fullName ? "#fff" : "#323338",
            ...customParagraphStyle,
          }}
        >
          {initials}
        </p>
      </div>
    );
  }
);

export const getInitials = (name: string) => {
  if (!name) {
    return "";
  }

  // Unicode property escape for letters (matches alphabet characters)
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/u, "gu");

  // Try-catch to handle any unexpected errors in regex matching
  let initials: string[] = [];
  try {
    // Convert matchAll iterator to an array and ensure it's not empty
    const matches = [...name.matchAll(rgx)];
    initials = matches.length > 0 ? [matches[0][1], matches[matches.length - 1][1]] : [];
  } catch (error) {
    console.error("Error in matching initials:", error);
  }

  // Combine first and last initials, ensuring they are capitalized
  return (initials[0]?.toUpperCase() + initials[1]?.toUpperCase()) || "";
};
