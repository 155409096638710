import { loadChartsPreferences } from "../../utils";

const chartTypeOptions = [
  {
    key: 0,
    label: "Line Chart",
    value: "Line",
  },
  {
    key: 1,
    label: "Vertical Bar",
    value: "Vertical",
  },
  {
    key: 2,
    label: "Pie Chart",
    value: "Pie",
  },
  {
    key: 3,
    label: "Doughnut Chart",
    value: "Doughnut",
  },
];

export function chartFields({
  setTitle,
  onShowDataForSelect,
  onChartModeChange,
}) {
  return [
    {
      label: "Title",
      placeholder: "Enter Title...",
      formItemName: "title",
      dataTestid: "title",
      required: true,
      onChange: _.debounce((e) => {
        setTitle(e.target.value);
      }, 400),
      rules: [
        {
          validator: (_, value) =>
            loadChartsPreferences("analyticsChartsLayout")
              .map(({ title }) => title)
              .some(
                (title) => (title || "").toLowerCase() === value.toLowerCase()
              )
              ? Promise.reject(
                  new Error("Chart with this Title already exists!")
                )
              : Promise.resolve(),
        },
      ],
    },
    {
      label: "Show Data For",
      type: "select",
      formItemName: "showDataFor",
      placeholder: "Show data for...",
      dataTestid: "data-for-select",
      onSelect: onShowDataForSelect,
      required: true,
      options: ["Employees", "Jobsites", "Teams"],
    },
    {
      label: "Chart Mode",
      type: "select",
      initialValue: "Table",
      formItemName: "chartMode",
      dataTestid: "chart-mode",
      placeholder: "Show data for...",
      onSelect: onChartModeChange,
      required: true,
      options: ["Table", "Round"],
    },
  ];
}

export function datasetFormFields({
  showDataFor,
  field,
  chartMode,
  form,
  employeePaymentRateAccess,
  jobsiteRateAccess,
}) {
  const selectedDataOptions = form
    .getFieldsValue()
    ?.datasets.map((el) => el.data);

  const dataOptions =
    showDataFor === "Employees" || showDataFor === "Teams"
      ? [
          "Regular Hours",
          employeePaymentRateAccess?.read && "Regular Amount",
          "Overtime Total",
          "Overtime Hours",
          "Overhead Hours",
          employeePaymentRateAccess?.read && "Overhead Amount",
          "Ovh + Reg Hours",
          employeePaymentRateAccess?.read && "Ovh + Reg Amount",
        ].filter(Boolean)
      : [
          "Regular Hours",
          jobsiteRateAccess?.read && "Regular Amount",
          jobsiteRateAccess?.read && "Overtime Amount",
          "Overtime Hours",
          "Ovh + Reg Hours",
          jobsiteRateAccess?.read && "Ovh + Reg Amount",
        ].filter(Boolean);

  return [
    {
      label: "Data Set",
      type: "select",
      placeholder: "Select Data...",
      formItemName: [field.name, `data`],
      dataTestid: "data-set-select",
      required: true,
      customOptions: dataOptions.map((opt, key) => ({
        key,
        label: opt,
        value: opt,
        disabled: selectedDataOptions?.some?.((el) => el === opt),
      })),
    },
    {
      label: "Chart Type",
      type: "select",
      initialValue: chartMode === "Table" ? "Line" : "Pie",
      formItemName: [field.name, "chartType"],
      dataTestid: "chart-type-select",
      required: true,
      allowClear: false,
      placeholder: "Select Type",
      customOptions:
        chartMode === "Table"
          ? chartTypeOptions.slice(0, 2)
          : chartTypeOptions.slice(2),
    },
  ];
}
