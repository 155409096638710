import React from "react";
import {
  Button,
  Checkbox,
  Input,
  message,
  Popover,
  Radio,
  Select,
  Tooltip,
  InputNumber,
} from "antd";
import { AgGridReact } from "ag-grid-react";
import columnDefinitions from "../../tools/columnDefiners";
import ReactQuill from "react-quill";
import { quillComponents } from "../../tools/constants";
import _ from "lodash";
import { rtfEditor } from "../index";
import { rtfEditorAddon } from "../index";
import CheckboxRenderer from "../cellRenderers/CheckboxRenderer";
// import SidewalkShedAddonsRenderer from "../SidewalkShed/SidewalkShedAddonsDetail/SidewalkShedAddonsRenderer";
import SidewalkShedAddonsDetail from "../SidewalkShed/SidewalkShedAddonsDetail";
import { createEmptyPLI } from "../../tools/polyfillers/PLICreator";
import { darkModeRowStyleRules } from "../../tools/formatters/GridStyles";
import Force from "../../../../../../icons/Data_Grid_Force 32px.webp";
import { generateRedColorByAmount } from "../../../../../pages/Settings/settingsComponents/Pricing/utils/colorGenerator";
import HoistCheckBox from "../cellRenderers/CheckboxRenderer/HoistCheckBox";
import SidewalkShedAddonsRenderer from "../SidewalkShed/SidewalkShedAddonsDetail/SidewalkShedAddonsRendererFunc";
import { useRedux } from "../../../../hooks";
import AddServiceNote from "../ServiceHeader/ServiceActions/AddServiceNote/AddServiceNote";
// import { calculateElevationOptimalHeight } from "../elevationPanel/ElevationContent";

/**
 * Elevation content for hoist
 * */
class HoistForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFocusedInitialValue: "",
      from: 0,
      to: 0,
      floors: {}, // {floorNumber: {floor object}}
      floorHeightPopoverVisible: false,
    };
  }

  key = 0;
  gridApi = undefined;

  frameworkComponents = {
    rtfEditor: rtfEditor,
    rtfEditorAddon: rtfEditorAddon,
    CheckboxRenderer: (params) => (
      <HoistCheckBox
        {...{
          ...params,
          handleCheckboxElementChange: this.handleCheckboxElementChange,
        }}
      />
    ),
    SidewalkShedAddonsRenderer,
    SidewalkShedAddonsDetail: SidewalkShedAddonsDetail,
  };

  defaultColDef = {
    // minWidth: 150,
    width: "auto",
    editable: true,
    filter: true,
    sortable: true,
    resizable: true,
    autoHeight: true,
    detailRowAutoHeight: true,
    enablePivot: true,
    enableRowGroup: true,
    cellClass: "cell-wrap-text",
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.gridApi?.sizeColumnsToFit();
    if (prevProps.isWritable !== this.props.isWritable) {
      // this.gridApi.refreshCells({force: true, suppressFlash: true});
      this.key++; //TODO solve it in a normal way
    }
    if (prevProps.gridData !== this.props.gridData && !!this.gridApi) {
      // this.key++
    }
  }

  onGridReady = (params, data) => {
    this.gridApi = params.api;
    this.gridApi.setRowData(data);
    this.gridApi.sizeColumnsToFit();
    const defaultSortModel = [{ colId: "id", sort: "asc" }];
    // columnDefinitions[this.props.serviceId]["test"].find(c => c.field === "stop").cellRendererParams =
    params.columnApi.applyColumnState({ state: defaultSortModel }); //sort by default by id (which is the floor number)

    params.api.closeToolPanel();
  };

  componentWillUnmount() {
    // window.removeEventListener("resize", this.sizeColumnsToFit);
  }

  sizeColumnsToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };

  cellEditingStarted = () => {
    if (!this.props.isWritable) {
      this.gridApi.stopEditing(true);
      message.error("Please enable write mode");
    }
  };

  cellValueChanged = (params) => {
    if (this.props.isWritable) {
      if (params.colDef.field === "floor_height") {
        //if floor height is modified, set floor to locked status. its height can be only reset by "force calculate button"
        const { serviceIndex, optionIndex, elevationIndex } =
          this.props.indexes;
        let newGridData = _.cloneDeep(this.props.gridData);
        let elevation =
          newGridData?.[serviceIndex].serviceOptions[optionIndex][
            elevationIndex
          ];
        const currentFloor = params?.data?.floor || 0;
        let floorHeightSum = 0;
        for (const floor of elevation.items) {
          floorHeightSum += parseFloat(floor.floor_height);
        }
        floorHeightSum = Math.round(floorHeightSum * 100) / 100;
        let building_height = Math.round(elevation.totalHeight * 100) / 100;

        let difference =
          Math.round(Math.abs(floorHeightSum - building_height) * 100) / 100;
        let countNum = elevation?.items?.filter(
          (floor) => floor.floor > currentFloor
        ).length;
        const tempAm = difference / countNum;
        console.log("checking", {
          currentFloor,
          floorHeightSum,
          building_height,
          difference,
          countNum,
          tempAm,
          elevation,
        });

        elevation?.items?.forEach((floor) => {
          if (floor.floor > currentFloor) {
            floor.floor_height += tempAm;
          }
        });
        console.log("cell value changed", {
          params,
          tempAm,
          elevation,
          difference,
          newGridData,
        });
        newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex] =
          elevation;
        params.data.lock = true;
        console.log("newGridData", elevation);
        this.props.updateStateAndSave(newGridData);
        // this.gridApi.redrawRows({
        //   rowNodes: [params.api.getRowNode(params.data.id)],
        // });
        this.gridApi.applyTransaction({ update: elevation.items }); //reset rows
        this.gridApi.redrawRows();
      }
      this.props.handleSave(false);
    }
  };

  // called after input element is selected
  handleInputElementFocus = (e) => {
    this.setState({
      currentFocusedInitialValue: e.target.value,
    });
  };

  // called after input element is deselected
  handleInputElementBlur = (e) => {
    if (
      e.target.value !== this.state.currentFocusedInitialValue &&
      this.props.isWritable
    ) {
      this.props.handleSave(false);
    }
  };

  // handles input change
  handleInputElementChange = (
    e,
    itemReference,
    biggerThanZeo,
    saveInDatabase,
    skipInitialize = false
  ) => {
    if (!this.props.isWritable) {
      //if not in write mode just warn user and don't update anything
      message.error("Please enable write mode to start making changes");
      return;
    }

    const { serviceIndex, optionIndex, elevationIndex } = this.props.indexes;
    let gridData = _.cloneDeep(this.props.gridData);
    const elevation =
      gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];

    let value = e.target.value;

    //check what type of value we are getting
    if (biggerThanZeo && !skipInitialize)
      value = parseFloat(value) > 0 ? value : 1;

    elevation[itemReference] = value;

    switch (itemReference) {
      case "undergroundHeight":
      case "building_height":
        elevation.totalHeight =
          +elevation.undergroundHeight + +elevation.building_height;
        elevation.mastHeight = elevation.totalHeight + 20;

        // const affectedFloors = elevation.items.filter(({ floor }) =>
        //   itemReference === "undergroundHeight" ? floor < 0 : floor > 0
        // )

        // elevation.items = elevation.items.map((item) =>
        //   affectedFloors.some(({ id }) => item.id === id)
        //     ? {
        //         ...item,
        //         floor_height: +(
        //           +elevation[itemReference] / affectedFloors.length
        //         ).toFixed(2),
        //       }
        //     : item
        // )

        // this.calculateFloorHeights(elevation.items, elevation.totalHeight)

        this.calculateFloorHeightsByType(elevation);

        break;
      case "mastHeight":
        const initialTotalHeight = elevation.totalHeight;
        elevation.totalHeight = +elevation.mastHeight - 20;
        const difference = elevation.totalHeight - initialTotalHeight;

        elevation.building_height = (
          (+elevation.building_height / +elevation.totalHeight) * difference +
          +elevation.building_height
        ).toFixed(2);
        elevation.undergroundHeight = (
          (+elevation.undergroundHeight / +elevation.totalHeight) * difference +
          +elevation.undergroundHeight
        ).toFixed(2);

        // this.calculateFloorHeights(elevation.items, elevation.totalHeight)
        // ;[elevation.undergroundHeight, elevation.building_height] =
        //   elevation.items.reduce(
        //     (acc, curr) => ((acc[+(curr.floor > 0)] += curr.floor_height), acc),
        //     [0, 0]
        //   )
        this.calculateFloorHeightsByType(elevation);

        break;
      case "totalHeight":
        const diff = +elevation.totalHeight - (elevation.mastHeight - 20);
        elevation.mastHeight = +elevation.totalHeight + 20;
        // this.calculateFloorHeights(elevation.items, elevation.totalHeight)
        // ;[elevation.undergroundHeight, elevation.building_height] =
        //   elevation.items.reduce(
        //     (acc, curr) => ((acc[+(curr.floor > 0)] += curr.floor_height), acc),
        //     [0, 0]
        //   )

        elevation.building_height = (
          (+elevation.building_height / +elevation.totalHeight) * diff +
          +elevation.building_height
        ).toFixed(2);

        elevation.undergroundHeight = (
          ((+elevation.undergroundHeight || 0) / +elevation.totalHeight) *
            diff +
          (+elevation.undergroundHeight || 0)
        ).toFixed(2);

        this.calculateFloorHeightsByType(elevation);

        break;

      default:
        break;
    }

    this.gridApi.applyTransaction({ update: elevation.items }); //reset rows
    this.gridApi.redrawRows();

    // if (itemReference === "building_height") {
    //   this.calculateFloorHeights(elevation.items, elevation.building_height)
    //   elevation.lift_height = this.get_lift_height_FROM_building_height(
    //     elevation.building_height
    //   )

    //   this.gridApi.applyTransaction({ update: elevation.items }) //reset rows
    //   this.gridApi.redrawRows()
    // }
    if (saveInDatabase) this.props.updateStateAndSave(gridData);
    else this.props.setState({ gridData });

    // const {serviceIndex, optionIndex, elevationIndex} = this.props.indexes;
    // this.props.handleInputElementChange(e, serviceIndex, optionIndex, elevationIndex, itemReference);
  };

  // handles radio button change
  handleRadioButtonsGroupElementChange = (e, itemReference) => {
    if (!this.props.isWritable) {
      //if not in write mode just warn user and don't update anything
      message.error("Please enable write mode to start making changes");
      return;
    }
    const { serviceIndex, optionIndex, elevationIndex } = this.props.indexes;
    let gridData = _.cloneDeep(this.props.gridData);
    _.update(
      gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex],
      itemReference,
      () => e.target.value
    );
    this.props.updateStateAndSave(gridData);
  };

  // handles checkbox change for all floors selections
  handleSelectAllFloors = (e, itemReference) => {
    console.log("item ref", itemReference);
    if (!this.props.isWritable) {
      //if not in write mode just warn user and don't update anything
      message.error("Please enable write mode to start making changes");
      return;
    }
    const { serviceIndex, optionIndex, elevationIndex } = this.props.indexes;
    let gridData = _.cloneDeep(this.props.gridData);
    gridData[serviceIndex].serviceOptions[optionIndex][
      elevationIndex
    ].items.forEach((item) => {
      // item.stop = e.target.checked;
      // item.dual = e.target.checked;
      item[itemReference] = e.target.checked;
    });
    let test = gridData;
    const elevation =
      gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];
    const floors = elevation.items;
    console.log("elevation floor", elevation);
    // const floors = this.generateFloors(typedValue, elevation.to, elevation.items || []); //receive floors object (some data are taken from state)

    const { building_height, undergroundHeight } = elevation;
    const [undergroundFloors, aboveGroundFloors] = floors?.reduce(
      (acc, curr) => (acc[+(curr.floor > 0)].push(curr), acc),
      [[], []]
    );

    const calculatedFloors = [
      ...undergroundFloors.map((floor) => ({
        ...floor,
        stop: itemReference === "stop" ? e.target.checked : floor.stop,
        dual: itemReference === "dual" ? e.target.checked : floor.dual,

        floor_height: +(undergroundHeight / undergroundFloors.length).toFixed(
          2
        ),
      })),
      ...aboveGroundFloors.map((floor) => ({
        ...floor,
        stop: itemReference === "stop" ? e.target.checked : floor.stop,
        dual: itemReference === "dual" ? e.target.checked : floor.dual,

        floor_height: +(building_height / aboveGroundFloors.length).toFixed(2),
      })),
    ];

    this.gridApi.setRowData(calculatedFloors); //reset rows
    this.gridApi.redrawRows();
    // console.log("grid data", gridData);
    // _.update(
    // 	gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex],
    // 	itemReference,
    // 	() => e.target.value
    // );
    // this.gridApi = params.api;

    // this.gridApi.setRowData(test);
    // this.gridApi.sizeColumnsToFit();
    // console.log("grid", this.gridApi);
    // console.log("floor dta", test);
    this.props.updateStateAndSave(gridData);
    this.props.setState({ gridData: gridData });

    // this.gridApi.redrawRows();
  };

  // handles with shoring checkbox change in hoist ramps
  handleCheckboxElementChange = (e, itemReference) => {
    // if (!this.props.isWritable) {
    //   //if not in write mode just warn user and don't update anything
    //   message.error("Please enable write mode to start making changes");
    //   return;
    // }
    const { serviceIndex, optionIndex, elevationIndex } = this.props.indexes;
    let gridData = _.cloneDeep(this.props.gridData);
    _.update(
      gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex],
      itemReference,
      () => e.target.checked
    );
    this.props.updateStateAndSave(gridData);
    this.props.handleSave(false);

    // if (this.props.isWritable) {
    //   this.props.handleSave(false);
    // }
  };

  /**
   * This function will handle changes of "From" and "To". From will determine the lowest floor and To the highest.
   * This will show rows in ag-grid table in the selected range. Existing rows will be removed if the lower boundary is higher than the old lower boundary.
   * This function can update in state from, to and floors.
   * @example having from -2 and to 6, will render 7 rows, one for each intermediate floors.
   * @param e {SyncEvent}
   * @param e.target.value {String}
   * @param FromTo {'from' | 'to'}
   * */
  handleBoundariesChange = (e, FromTo) => {
    if (!this.props.isWritable) {
      //if not in write mode just warn user and don't update anything
      message.error("Please enable write mode to start making changes");
      return;
    }
    const { serviceIndex, optionIndex, elevationIndex } = this.props.indexes;
    const typedValue = parseInt(e); //the number typed in from or to input
    const gridData = _.cloneDeep(this.props.gridData);
    const elevation =
      gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];

    if (FromTo === "from") {
      //if user has typed in from input
      if (typedValue < -4) {
        //the lowest floor this software is supporting is -4
        message.warning("The lowest floor is -4 (sub-sub-sub-cellar)");
        return;
      }
      if (elevation.to !== undefined) {
        //if there is a defined upper boundary from before
        const floors = this.generateFloors(
          typedValue,
          elevation.to,
          elevation.items || []
        ); //receive floors object (some data are taken from state)

        // this.calculateFloorHeights(floors, elevation.building_height)
        const { building_height, undergroundHeight } = elevation;
        const [undergroundFloors, aboveGroundFloors] = floors?.reduce(
          (acc, curr) => (acc[+(curr.floor > 0)].push(curr), acc),
          [[], []]
        );
        const calculatedFloors = [
          ...undergroundFloors.map((floor) => ({
            ...floor,
            floor_height: +(
              undergroundHeight / undergroundFloors.length
            ).toFixed(2),
          })),
          ...aboveGroundFloors.map((floor) => ({
            ...floor,
            floor_height: +(building_height / aboveGroundFloors.length).toFixed(
              2
            ),
          })),
        ];

        this.gridApi.setRowData(calculatedFloors); //reset rows
        this.gridApi.redrawRows();

        // this.gridApi.refreshCells({columns: ["floor_height", "description", "note"]})
        this.setState({ floors: calculatedFloors }); //set the typed value and floors in state

        elevation.items = calculatedFloors;
        elevation.from = typedValue;
        this.props.setState({ gridData: gridData });
      } else {
        //if the upper boundary is not defined before
        elevation.from = typedValue;
        this.props.setState({ gridData: gridData }); //just update in state from. There is no row to set
      }
    } else if (FromTo === "to") {
      //if user has typed in to input
      if (elevation.from !== undefined) {
        //if there is a defined upper boundary from before
        const floors = this.generateFloors(
          elevation.from,
          typedValue,
          elevation.items || []
        ); //receive floors object (some data are taken from state)

        // this.calculateFloorHeights(floors, elevation.building_height)

        const { building_height, undergroundHeight } = elevation;
        const [undergroundFloors, aboveGroundFloors] = floors.reduce(
          (acc, curr) => (acc[+(curr.floor > 0)].push(curr), acc),
          [[], []]
        );
        const calculatedFloors = [
          ...undergroundFloors.map((floor) => ({
            ...floor,
            floor_height: undergroundHeight / undergroundFloors.length,
          })),
          ...aboveGroundFloors.map((floor) => ({
            ...floor,
            floor_height: building_height / aboveGroundFloors.length,
          })),
        ];

        this.gridApi.setRowData(calculatedFloors); //reset rows
        this.gridApi.redrawRows();
        // this.gridApi.refreshCells({columns: ["floor_height", "description", "note"]})

        this.setState({ floors: calculatedFloors }); //set the typed value and floors in state

        elevation.items = calculatedFloors;
        elevation.to = typedValue;
        this.props.setState({ gridData: gridData });
        // this.key++
      } else {
        //if the upper boundary is not defined before
        elevation.to = typedValue;
        this.props.setState({ gridData: gridData }); //just update in state from. There is no row to set
      }
    }
  };

  /**
   * This function will generate floor, to then be used by ag-grid table rows. This function takes existing floors from this.state.floors (but does not modify values).
   * New floors will be generated with floor number as id and other data will be empty.
   * @param from {number} The lowest floor
   * @param to {number} the highest floor
   * @param existingFloors {{id: number, floor: number, stop: boolean, addons: []}[]}
   * @return {Object} A map like {1: {floor data}, 2: {floor data}}
   * */
  generateFloors(from, to, existingFloors) {
    let floors = []; //create a temporary floors object, it will be same as the one in state

    if (from === 0 || to === 0) return existingFloors;

    for (let floorNumber = from; floorNumber <= to; floorNumber++) {
      if (floorNumber === 0) continue; //floor 0 does not exist

      let floor = existingFloors.find((f) => f.floor === floorNumber);
      // let id = floorNumber - from + 1
      let id = (_.max(floors.map(({ id }) => id)) || 0) + 1;
      if (floor) {
        //if value exist
        floor.id = id;
        // floors[i] = this.state.floors[i]
      } else {
        //if value does not exist
        floor = createEmptyPLI(3, id);
        floor.floor = floorNumber;
      }
      floors.push(floor);
    }

    return floors;
  }

  calculateFloorHeights(floors, building_height, forced, reference) {
    let floor_height;
    if (forced) {
      //if it is a forced calculation does not count which floor is locked or not
      floor_height = Math.round((building_height / floors.length) * 100) / 100;
    } else {
      const unlockedFloorsCount = floors.filter((f) => f.lock !== true).length;
      let remainingBuildingHeight = building_height; //subtract floors height of locked floors from total height
      for (const floor of floors) {
        if (floor.lock) remainingBuildingHeight -= floor.floor_height;
      }
      floor_height =
        Math.round((remainingBuildingHeight / unlockedFloorsCount) * 100) / 100;
    }

    // floor_height = floor_height > 0 ? floor_height : "INVALID";
    floor_height = floor_height > 0 ? floor_height : "-";

    if (forced) {
      for (const floor of floors) {
        floor.floor_height = floor_height;
      }
    } else {
      for (const floor of floors) {
        if (floor.lock !== true) floor.floor_height = floor_height;
      }
    }

    return floors;
  }

  calculateFloorHeightsByType = (elevation) => {
    const { items, undergroundHeight, building_height } = elevation;

    const lockedFloors = items.filter(({ lock }) => !!lock);

    const [
      [unlockedUndergroundFloors, unlockedAboveGroundFloors],
      [lockedUndergroundFloors, lockedAboveGroundFloors],
    ] = items.reduce(
      (acc, curr) => (acc[+!!curr.lock][+(curr.floor > 0)].push(curr), acc),
      [
        [[], []],
        [[], []],
      ]
    );

    const [lockedUndergroundFloorsHeight, lockedAboveGroundFloorsHeight] =
      lockedFloors.reduce(
        (acc, curr) => ((acc[+(curr.floor > 0)] += curr.floor_height), acc),
        [0, 0]
      );

    const remainingUndergroundHeight =
      undergroundHeight - lockedUndergroundFloorsHeight;
    const remainingBuildingHeight =
      building_height - lockedAboveGroundFloorsHeight;

    const calculatedFloors = [
      ...lockedUndergroundFloors,
      ...lockedAboveGroundFloors,
      ...unlockedUndergroundFloors.map((floor) => ({
        ...floor,
        floor_height: +(
          remainingUndergroundHeight / unlockedUndergroundFloors.length
        ).toFixed(2),
      })),

      ...unlockedAboveGroundFloors.map((floor) => ({
        ...floor,
        floor_height: +(
          remainingBuildingHeight / unlockedAboveGroundFloors.length
        ).toFixed(2),
      })),
    ];

    elevation.items = calculatedFloors;
    return calculatedFloors;
  };

  /**
   * @param style {'unchanged'|'all'}
   * */
  setFloorHeights = (style) => {
    if (!this.props.isWritable) {
      //if not in write mode just warn user and don't update anything
      message.error("Please enable write mode to start making changes");
      return;
    }

    const gridData = _.cloneDeep(this.props.gridData);
    const { serviceIndex, optionIndex, elevationIndex } = this.props.indexes;
    const elevation =
      gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];

    const { undergroundHeight, building_height, items } = elevation;

    // if (style === "unchanged")
    //   this.calculateFloorHeights(
    //     elevation.items,
    //     elevation.building_height,
    //     false
    //   )
    // else
    //   this.calculateFloorHeights(
    //     elevation.items,
    //     elevation.building_height,
    //     true
    //   )

    if (style === "unchanged") {
      this.calculateFloorHeights(
        elevation.items,
        elevation.building_height,
        false
      );
    } else {
      const sortedHeights = [undergroundHeight, building_height];
      const sortedFloors = items.reduce(
        (acc, curr) => (acc[+(curr.floor > 0)].push(curr), acc),
        [[], []]
      );

      elevation.items = sortedFloors.flatMap((floorGroup, idx) =>
        floorGroup.map((floor) => ({
          ...floor,
          floor_height: +(sortedHeights[idx] / floorGroup.length).toFixed(2),
          lock: false,
        }))
      );
    }

    this.gridApi.applyTransaction({ update: elevation.items }); //reset rows
    // this.gridApi.refreshCells({columns: ["floor_height", "description", "note"]})
    this.gridApi.redrawRows();

    this.setState({
      floors: elevation.items,
      floorHeightPopoverVisible: false,
    }); //set the typed value and floors in state
    this.props.updateStateAndSave(gridData);
  };

  get_lift_height_FROM_building_height(building_height) {
    const remainder = building_height % 5;
    if (remainder === 0) {
      return building_height;
    } else {
      return parseFloat(building_height) + (5 - remainder);
    }
  }

  saveDataFromRTDEditor = (
    items,
    serviceIndex,
    optionIndex,
    elevationIndex
  ) => {
    message.info("update", 10);
    console.log(items, "items");
    console.log(serviceIndex, "serviceIndex");
    console.log(optionIndex, "optionIndex");
    console.log(elevationIndex, "elevationIndex");
    const { gridData, updateStateAndSave } = this.props;
    const gridDataCopy = _.cloneDeep(gridData);
    gridDataCopy[serviceIndex].serviceOptions[optionIndex][
      elevationIndex
    ].items = items;

    updateStateAndSave(gridDataCopy);
  };

  getColumnDefs = () => {
    const { gridData, isWritable, agGridTheme, saveSidewalkShedAddonsData } =
      this.props;
    const { serviceIndex, optionIndex, elevationIndex } = this.props.indexes;

    const elevation =
      gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];

    console.log("elevation", elevation);

    const columnDefs = columnDefinitions[this.props.serviceId];

    columnDefs.find((d) => d.field === "stop").cellRendererParams = {
      isWritable,
    };

    columnDefs.find((d) => d.field === "dual").cellRendererParams = {
      isWritable,
    };

    columnDefs.find((d) => d.field === "id").cellClass = "id-column";

    const rtfEditorColumns = columnDefs.filter(
      (cd) => cd.cellRenderer === "rtfEditor"
    );
    for (const rtfEditorColumn of rtfEditorColumns) {
      rtfEditorColumn.editable = false;
      rtfEditorColumn.cellRendererParams = {
        ...rtfEditorColumn.cellRendererParams,
        gridData,
        isWritable,
        serviceIndex,
        optionIndex,
        elevationIndex,
        saveDataFromRTDEditor: this.saveDataFromRTDEditor,
        from: "pli",
        agGridTheme,
      };
    }

    const addonsField = columnDefs.find((d) => d.field === "addons");
    addonsField.cellRendererParams = {
      ...addonsField.cellRendererParams,
      ...{
        gridData,
        isWritable,
        serviceIndex,
        optionIndex,
        elevationIndex,
        saveSidewalkShedAddonsData,
      },
    };

    const floor_height = columnDefs.find((d) => d.field === "floor_height");
    floor_height.cellStyle = (params) => {
      //find min and max from floor_height
      let min = params.api.getRowNode(1)?.data?.floor_height || 0,
        max = 0;

      params.api.forEachNode((node) => {
        const v = parseFloat(node.data.floor_height);
        if (v < min) min = v;
        if (v > max) max = v;
      });

      if (params.data.lock === true)
        return { color: generateRedColorByAmount(min, max, params.value) };
      return null;
    };

    return elevation.type === "single"
      ? columnDefs.flatMap((columnDef) =>
          columnDef.field === "dual"
            ? {
                ...columnDef,
                hide: true,
              }
            : columnDef
        )
      : columnDefs;
  };

  getStopsCount(items) {
    let count = 0;
    for (let i = 0; i < items.length; i++) {
      if (items[i]?.stop === true) ++count;
    }
    return count;
  }

  render() {
    const { serviceIndex, optionIndex, elevationIndex } = this.props.indexes;
    const elevation =
      this.props.gridData[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ];

    const { access } = elevation;
    console.log("elevation", elevation);
    console.log("this.elevation", this);
    // if(!item.test) {
    //   item.test = {items: []}
    // }
    const stopsCount = this.getStopsCount(elevation.items);

    let floorHeightSum = 0;
    for (const floor of elevation.items) {
      floorHeightSum += parseFloat(floor.floor_height);
    }

    let displayMessage = (
      <p className="total-floor-height">
        Total Floor Height: {floorHeightSum}.
      </p>
    );
    floorHeightSum = Math.round(floorHeightSum * 100) / 100;
    let building_height = Math.round(elevation.totalHeight * 100) / 100;

    let difference =
      Math.round(Math.abs(floorHeightSum - building_height) * 100) / 100;

    if (difference >= 0.01) {
      if (floorHeightSum > building_height) {
        displayMessage = (
          <p className="floor-height-message">
            Total Floor Height: {floorHeightSum}.{" "}
            <b>
              Your total floor height is{" "}
              <b className="floor-height-message-difference">{difference} ft</b>{" "}
              higher than total height!
            </b>{" "}
          </p>
        );
      } else if (floorHeightSum < building_height) {
        displayMessage = (
          <p className="floor-height-message">
            Total Floor Height: {floorHeightSum}.{" "}
            <b>
              Your total floor height is{" "}
              <b className="floor-height-message-difference">{difference} ft</b>{" "}
              lower than total height!
            </b>{" "}
          </p>
        );
      }
    }

    let higherThan5Feet = elevation.lift_height - building_height >= 5;
    let lowerThanBuildingHeight = elevation.lift_height < building_height;

    let message2 = "";
    if (higherThan5Feet)
      message2 = (
        <span className="lift-height-warning-message">
          Lift height is more than 5 ft higher than building
        </span>
      );
    if (lowerThanBuildingHeight)
      message2 = (
        <span className="lift-height-warning-message">
          Lift height is lower than Building Height
        </span>
      );

    const UIFeatureForThisElevation =
      this.props.UIFeaturesForEachElevation[serviceIndex]?.[optionIndex]?.[
        elevationIndex
      ];

    console.log("elevation.undergroundHeight", elevation.undergroundHeight);

    return (
      <div className="hoistForm">
        <div className="hoistFormSectionParent">
          <div className="hoistFormSection">
            <div className="hoistFormLabel">Type:</div>
            <Radio.Group
              defaultValue="dual"
              buttonStyle="solid"
              value={elevation.type}
              onChange={(e) => {
                this.handleRadioButtonsGroupElementChange(e, "type");
              }}
            >
              <Radio.Button value="single">Single Hoist</Radio.Button>
              <Radio.Button value="dual">Dual Hoist</Radio.Button>
            </Radio.Group>
          </div>
          <div className="hoistFormSection">
            <div className="hoistFormLabel">Select All Floors:</div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <Checkbox
                className="hoistFormLabel"
                onChange={(e) => this.handleSelectAllFloors(e, "stop")}
              >
                Single
              </Checkbox>
              {elevation?.type === "dual" && (
                <Checkbox
                  className="hoistFormLabel"
                  onChange={(e) => this.handleSelectAllFloors(e, "dual")}
                >
                  Double
                </Checkbox>
              )}
            </div>

            {/* <Radio.Group
							defaultValue="single"
							buttonStyle="solid"
							value={elevation.type}
							onChange={(e) => {
								this.handleRadioButtonsGroupElementChange(e, "selectAllFloors");
							}}
						>
							<Radio.Button value="single">Single Hoist</Radio.Button>
							<Radio.Button value="dual">Dual Hoist</Radio.Button>
						</Radio.Group> */}
          </div>
          <div className="hoistFormSection">
            <div className="hoistFormLabel">Location:</div>
            <Select
              defaultValue="front"
              value={elevation.location}
              onChange={(value) => {
                this.handleRadioButtonsGroupElementChange(
                  { target: { value } },
                  "location"
                );
              }}
            >
              <Select.Option value="front">Front</Select.Option>
              <Select.Option value="rear">Rear</Select.Option>
              <Select.Option value="side">Side</Select.Option>
              <Select.Option value="courtyard">Courtyard</Select.Option>
            </Select>
          </div>
          <div className="hoistFormSection">
            <div className="hoistFormLabel">Access:</div>
            <Radio.Group
              defaultValue="direct"
              buttonStyle="solid"
              value={elevation.access}
              onChange={(e) => {
                this.handleRadioButtonsGroupElementChange(e, "access");
              }}
            >
              <Radio.Button value="direct">Direct</Radio.Button>
              <Radio.Button value="nonDirect">Non-direct</Radio.Button>
            </Radio.Group>
          </div>

          {access === "nonDirect" && (
            <div className="hoistFormSection">
              <AddServiceNote
                {...{
                  isWritable: this.props.isWritable,
                  themeType: "dark",
                  addNoteForService: (note) => {
                    this.handleInputElementChange(
                      { target: { value: note } },
                      "accessNote"
                    );
                  },
                  service: {
                    label: "Non-direct Access Note",
                    note: elevation?.accessNote || "",
                  },
                }}
              />
              {/* <ReactQuill
                className={"darkModeQuill "}
                readOnly={!this.props.isWritable}
                theme="snow"
                {...quillComponents}
                defaultValue={elevation.accessNote}
                onFocus={(range, source, editor) => {
                  this.handleInputElementFocus({
                    target: { value: editor.getHTML() },
                  });
                }}
                onChange={(content, delta, source, editor) => {
                  this.handleInputElementChange(
                    { target: { value: editor.getHTML() } },
                    "accessNote"
                  );
                }}
                onBlur={(range, source, editor) => {
                  this.handleInputElementBlur({
                    target: { value: editor.getHTML() },
                  });
                }}
              /> */}
            </div>
          )}

          <div className="hoistFormSection">
            <div className="hoistFormLabel">Hoist Info</div>
            <div className="hoistFormSectionChild tagSelect leftPartial">
              <div className="addonBefore">Serial Number:</div>
              <Select
                mode="tags"
                className="selectComponent"
                value={elevation.serialNumbers}
                placeholder="Type it here..."
                onChange={(e) => {
                  this.handleInputElementChange(
                    { target: { value: e } },
                    "serialNumbers",
                    false,
                    true
                  );
                }}
                onBlur={() => {
                  this.props.isWritable && this.props.handleSave(false);
                }}
              />
            </div>

            <div className="hoistFormSectionChild">
              {/* <div className="hoistFormLabel">Type:</div> */}
              <Input
                style={{ width: 200 }}
                addonBefore="Speed:"
                type="number"
                min={1}
                defaultValue={178}
                placeholder="Speed"
                value={!!elevation.speed ? elevation.speed : 178}
                onFocus={this.handleInputElementFocus}
                onChange={(e) => {
                  this.handleInputElementChange(e, "speed", true);
                }}
                onBlur={this.handleInputElementBlur}
                suffix="ft/min"
              />
            </div>
          </div>
        </div>

        <div className="hoistFormSectionParent">
          <div className="hoistFormSection">
            <div className="hoistFormLabel">Heights</div>
            <div className="hoistFormSectionChild" style={{ width: "300px" }}>
              {console.log("Underground Height", elevation.undergroundHeight)}
              <Input
                addonBefore="Underground Height:"
                defaultValue={
                  elevation.undergroundHeight === "" ||
                  elevation.undergroundHeight === undefined
                    ? 0
                    : elevation.undergroundHeight
                }
                type="number"
                value={elevation.undergroundHeight}
                onFocus={this.handleInputElementFocus}
                onChange={(e) => {
                  this.handleInputElementChange(e, "undergroundHeight");
                }}
                onBlur={this.handleInputElementBlur}
                suffix={"ft"}
                min={0}
              />
            </div>
            <div className="hoistFormSectionChild" style={{ width: "220px" }}>
              <Input
                addonBefore="Building Height:"
                type="number"
                value={elevation.building_height}
                onFocus={this.handleInputElementFocus}
                onChange={(e) => {
                  this.handleInputElementChange(e, "building_height", true);
                }}
                onBlur={this.handleInputElementBlur}
                suffix={"ft"}
                min={0}
              />
            </div>
            <div className="hoistFormSectionChild" style={{ width: "220px" }}>
              <Input
                addonBefore="Total Height:"
                // disabled={true}
                type="number"
                value={elevation.totalHeight}
                onFocus={this.handleInputElementFocus}
                onChange={(e) => {
                  this.handleInputElementChange(e, "totalHeight", true);
                }}
                onBlur={this.handleInputElementBlur}
                suffix={"ft"}
                min={0}
              />
            </div>

            <div className="hoistFormSectionChild" style={{ width: "220px" }}>
              <Input
                addonBefore="Mast Height:"
                type="number"
                value={elevation.mastHeight}
                onFocus={this.handleInputElementFocus}
                onChange={(e) => {
                  this.handleInputElementChange(e, "mastHeight", true);
                }}
                onBlur={this.handleInputElementBlur}
                suffix={"ft"}
                min={0}
              />
            </div>
            <div className="hoistFormSectionChild">
              <Input
                addonBefore="Lift Height:"
                type="number"
                value={elevation.lift_height}
                onFocus={this.handleInputElementFocus}
                onChange={(e) => {
                  this.handleInputElementChange(e, "lift_height", true);
                }}
                onBlur={this.handleInputElementBlur}
                suffix={"ft"}
                style={{ width: "180px" }}
                min={0}
              />
              {message2}
            </div>
            <div className="hoistFormSectionChild">
              <Tooltip
                title="Set floor heights for all floors"
                placement={"bottom"}
              >
                <Popover
                  title={"How do you want to reset floors height?"}
                  trigger={"click"}
                  visible={this.state.floorHeightPopoverVisible}
                  onVisibleChange={(visible) => {
                    this.setState({ floorHeightPopoverVisible: visible });
                  }}
                  content={
                    <div className={"floor-height-popover-content"}>
                      <Button
                        onClick={(e) => {
                          this.setState({ floorHeightPopoverVisible: false });
                        }}
                      >
                        Cancel
                      </Button>
                      {/* <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.setFloorHeights("unchanged")
                      }}
                    >
                      Apply only to unchanged floors
                    </Button> */}
                      <Button
                        type={"primary"}
                        onClick={(e) => {
                          this.setFloorHeights("all");
                        }}
                      >
                        Redistribute
                      </Button>
                    </div>
                  }
                >
                  <Button>
                    {" "}
                    <img
                      className="data-grid-icon-button-img"
                      alt="force apply"
                      src={Force}
                    />{" "}
                  </Button>
                </Popover>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="hoistFormSectionParent">
          <div className="hoistFormSection">
            <div className="hoistFormLabel">Stops:</div>
            <div className="hoistFormSectionChild">
              <InputNumber
                controls={false}
                style={{ width: "150px" }}
                addonBefore="From:"
                className="fromHoist"
                type="number"
                value={elevation.from}
                onFocus={this.handleInputElementFocus}
                onChange={(p) => {
                  this.handleBoundariesChange(p, "from");
                }}
                onBlur={this.handleInputElementBlur}
              />
            </div>
            <div className="hoistFormSectionChild">
              <Input
                style={{ width: "150px" }}
                addonBefore="To:"
                type="number"
                value={elevation.to}
                onFocus={this.handleInputElementFocus}
                onChange={(e) => {
                  this.handleBoundariesChange(e.target.value, "to");
                }}
                onBlur={this.handleInputElementBlur}
              />
            </div>
          </div>

          <div className="hoistFormSection">
            <div className="hoistFormLabel">Stops Info</div>
            <div className="hoistFormSectionChild tagSelect leftPartial">
              <div className="addonBefore">Including:</div>
              <Select
                mode="tags"
                className="selectComponent"
                value={elevation.stops_including}
                onChange={(e) => {
                  this.handleInputElementChange(
                    { target: { value: e } },
                    "stops_including",
                    false,
                    true
                  );
                }}
                onBlur={() => {
                  this.props.isWritable && this.props.handleSave(false);
                }}
              >
                <Select.Option value="Ground Stop">Ground Stop</Select.Option>
                <Select.Option value="Roof Stop">Roof Stop</Select.Option>
              </Select>
            </div>
            <div className="hoistFormSectionChild tagSelect rightPartial">
              <span className="addonBefore">Excluding:</span>
              <Select
                mode="tags"
                className="selectComponent"
                value={elevation.stops_excluding}
                onChange={(e) => {
                  this.handleInputElementChange(
                    { target: { value: e } },
                    "stops_excluding",
                    false,
                    true
                  );
                }}
                onBlur={() => {
                  this.props.isWritable && this.props.handleSave(false);
                }}
              />
            </div>
          </div>

          <div className="hoistFormSection">
            <div className="hoistFormLabel">Dimensions:</div>
            <div className="hoistFormSectionChild">
              <Input
                style={{ width: "150px" }}
                addonBefore="Length:"
                // type="number"
                // min={1}
                value={elevation.length}
                onFocus={this.handleInputElementFocus}
                onChange={(e) => {
                  this.handleInputElementChange(e, "length", true, false, true);
                }}
                onBlur={(e) => {
                  const val = e.target.value;
                  if (regex.test(val)) {
                    this.handleInputElementChange(
                      e,
                      "length",
                      true,
                      false,
                      true
                    );
                  } else {
                    message.info(
                      "Please enter the length in the format e.g 10'11 "
                    );
                    this.handleInputElementChange(
                      {
                        target: { value: "" },
                      },
                      "length",
                      true,
                      false,
                      true
                    );
                  }
                }}
                // suffix={"' ''"}
              />
            </div>
            <div className="hoistFormSectionChild">
              <Input
                style={{ width: "150px" }}
                addonBefore="Width:"
                // type="number"
                // min={1}
                value={elevation.width}
                onFocus={this.handleInputElementFocus}
                onChange={(e) => {
                  this.handleInputElementChange(e, "width", true, false, true);
                }}
                onBlur={(e) => {
                  const val = e.target.value;
                  if (regex.test(val)) {
                    this.handleInputElementChange(
                      e,
                      "width",
                      true,
                      false,
                      true
                    );
                  } else {
                    message.info(
                      "Please enter the width in the format e.g 10'11 "
                    );
                    this.handleInputElementChange(
                      {
                        target: { value: "" },
                      },
                      "width",
                      true,
                      false,
                      true
                    );
                  }
                }}
              />
            </div>
            <div className="hoistFormSectionChild">
              <Input
                style={{ width: "150px" }}
                addonBefore="Height:"
                // type="number"
                // min={1}
                value={elevation?.height}
                onFocus={this.handleInputElementFocus}
                onChange={(e) => {
                  console.log("e", e);
                  this.handleInputElementChange(e, "height", true, false, true);
                }}
                onBlur={(e) => {
                  const val = e.target.value;
                  if (regex.test(val)) {
                    this.handleInputElementChange(
                      e,
                      "height",
                      true,
                      false,
                      true
                    );
                  } else {
                    message.info(
                      "Please enter the height in the format e.g 10'11 "
                    );
                    this.handleInputElementChange(
                      {
                        target: { value: "" },
                      },
                      "height",
                      true,
                      false,
                      true
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="hoistFormSectionParent" style={{ height: "100%" }}>
          <div
            className={`elevationContainer ${
              this.props.darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"
            }`}
            style={{ height: "100%" }}
          >
            {/* <ResizableBox
              className={"dataEntryGrid"}
              minConstraints={[0, 100]}
              maxConstraints={[0, 1500]}
              width={"auto"}
              height={calculateElevationOptimalHeight(
                elevation.items,
                UIFeatureForThisElevation,
                40
              )}
              axis="y"
              handle={
                <div className="gridResizer">
                  <div className="gridResizerInnerLeft" />
                  <div className="gridResizerHandler">
                    <div className="gridResizerHandlerGrip" />
                    <div className="gridResizerHandlerGrip" />
                    <div className="gridResizerHandlerGrip" />
                  </div>
                  <div className="gridResizerInnerRight" />
                </div>
              }
            > */}
            <AgGridReact
              key={this.key}
              immutableData={true}
              domLayout={"autoHeight"}
              sideBar="columns"
              rowSelection={"single"}
              embedFullWidthRows={true}
              context={{
                isWritable: this.props.isWritable,
              }}
              enableCellChangeFlash={true}
              detailRowAutoHeight={true}
              detailRowHeight={200}
              undoRedoCellEditingLimit={50}
              onGridReady={(params) => {
                params.api.sizeColumnsToFit();

                this.onGridReady(params, elevation.items);
              }}
              columnDefs={this.getColumnDefs()}
              suppressDragLeaveHidesColumns={true}
              onCellEditingStarted={this.cellEditingStarted}
              onCellEditingStopped={(params) => {
                this.props.resizeGridRows(params.api);
              }}
              onColumnResized={(params) => {
                this.props.resizeGridRows(params.api);
              }}
              onCellValueChanged={this.cellValueChanged}
              defaultColDef={this.defaultColDef}
              frameworkComponents={this.frameworkComponents}
              getRowNodeId={(data) => data.id}
              masterDetail={true}
              detailCellRenderer="SidewalkShedAddonsDetail"
              detailCellRendererParams={{
                ServicesIndex: this.props.ServicesIndex,
                handleSave: this.props.handleSave,
                setState: this.props.setState,
                isWritable: this.props.isWritable,
                indexes: this.props.indexes,
                saveSidewalkShedAddonsData:
                  this.props.saveSidewalkShedAddonsData,
                onAddonRichTextChange: this.props.onAddonRichTextChange,
                pricingData: this.props.pricingData,
                service: this.props.service,
                selectOptions: this.props.selectOptions,
              }}
              rowClassRules={darkModeRowStyleRules}
            />
            {/* </ResizableBox> */}
          </div>

          {/*< className="dataEntryGrid" style={{margin: '15px 0 0 0'}}>*/}

          <div className={"stops-count-message"}>
            <StopCounts
              {...{
                // elevation,
                indexes: this.props.indexes,
              }}
            />
          </div>
          {displayMessage}
        </div>

        <div className="hoistFormSectionParent" style={{ marginBottom: 0 }}>
          <div className="hoistFormSection" style={{ width: "100%" }}>
            <div className="hoistFormLabel">Note:</div>
            <ReactQuill
              className={"darkModeQuill " + this.props.agGridTheme}
              readOnly={!this.props.isWritable}
              theme="snow"
              {...quillComponents}
              defaultValue={elevation.note}
              onFocus={(range, source, editor) => {
                this.handleInputElementFocus({
                  target: { value: editor.getHTML() },
                });
              }}
              onChange={(content, delta, source, editor) => {
                this.handleInputElementChange(
                  { target: { value: editor.getHTML() } },
                  "note"
                );
              }}
              onBlur={(range, source, editor) => {
                this.handleInputElementBlur({
                  target: { value: editor.getHTML() },
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default HoistForm;

const regex = /^[1-9]\d*'\d+$/;

const StopCounts = ({ indexes = {} }) => {
  const [gridData] = useRedux("takeOffTableData");
  const { serviceIndex = "", optionIndex = "", elevationIndex = "" } = indexes;
  const elevation =
    gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];
  const items = elevation?.items || [];

  console.log("el items", elevation);
  return (
    <div>
      Number of stops: Cabin A:{" "}
      {items?.filter((r) => r?.stop === true)?.length || 0} Cabin B: 5
    </div>
  );
};

const applicableDimensions = ({ pli, dimension }) => {
  let value = 0;

  if (
    dimension === "length" ||
    dimension === "width" ||
    dimension === "height"
  ) {
    Object.keys(pli).find((key) => {
      if (key.toLowerCase().includes(dimension)) {
        value = pli[key];
        return true;
      }
    });
  }
  return value;
};
