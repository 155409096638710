import { message } from "antd";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { getMaxNoFeet } from "./getMax";

export const getRawElevationTotal = (serviceId, elevationItems) => {
  let unitTotal = 0,
    suffix = "",
    ppuCount = 0,
    averagePPU;
  // console.log("elevationItems", elevationItems, serviceId);
  // let pricePerUnit = this.state.pricePerUnitInfo[serviceId];
  let prices = { priceSubtotal: 0, rentTotal: 0, totalPPU: 0 };
  if (elevationItems)
    switch (serviceId) {
      // length x height
      case 2: {
        for (const item of elevationItems) {
          // console.log("item item ", item);
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) *
              getMaxNoFeet(item["height"] ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;
      }
      case 1: {
        for (const item of elevationItems) {
          if (item.approved) {
            let sqft = getMaxNoFeet(item.length ?? 0);
            unitTotal += sqft;
            if (item.addons && item.addons.length > 0) {
              for (const addon of item.addons) {
                // console.log("my addon", addon);
                // if (addon.price)
                //   prices.priceSubtotal += positivePrice(addon.price);
                // if (addon?.spanPrice && addon?.approved) {
                //   prices.priceSubtotal += positivePrice(
                //     Number(addon?.spanPrice || 0)
                //   );
                // }
              }
            }
            prices = addToPPURentPrice(prices, item);
            // console.log("final prices", prices);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Lnf.";
        }
        break;
      }
      // linear length
      case 4:
      case 6:
      case 7:
      case 8: {
        for (const item of elevationItems) {
          if (item.approved) {
            let sqft = getMaxNoFeet(item.length ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Lnf.";
        }
        break;
      }
      case 9:
      case 15: {
        for (const item of elevationItems) {
          if (item.approved) {
            let sqft = getMaxNoFeet(item.length ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Lnf.";
        }
        break;
      }
      // length x height
      case 3:
      case 11: {
        for (const item of elevationItems) {
          // console.log("item item ", item);
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) *
              getMaxNoFeet(item["height"] ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;
      }
      case 14:
      case 17: //we don't know its sizes for sure
        for (const item of elevationItems) {
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.height ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;

      case 18:
      case 19: {
        for (const item of elevationItems) {
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.height ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;
      }
      // length x width
      case 5: {
        for (const item of elevationItems) {
          ppuCount = 0;
          // console.log("item", item);
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.width ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;
      }
      case 44: {
        for (const item of elevationItems) {
          ppuCount = 0;
          // console.log("item", item);
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.width ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;
      }
      case 8:
      case 10:
      case 12: {
        for (const item of elevationItems) {
          ppuCount = 0;
          // console.log("item", item);
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.height ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;
      }
      case 13: {
        for (const item of elevationItems) {
          ppuCount = 0;
          // console.log("item", item);
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.width ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;
      }

      case 20: {
        for (const item of elevationItems) {
          ppuCount = 0;
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.width ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);

            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;
      }

      case 69: {
        for (const item of elevationItems) {
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.height ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;
      }
      case 79: {
        for (const item of elevationItems) {
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.width ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;
      }
      case 73: {
        for (const item of elevationItems) {
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.height ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (!item?.approved && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;
      }
      case 80: {
        for (const item of elevationItems) {
          if (item.approved) {
            let sqft =
              getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.height ?? 0);
            unitTotal += sqft;
            prices = addToPPURentPrice(prices, item);
            ppuCount++;
          }
          if (item?.approved === true && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }
        break;
      }
      default:
        for (const item of elevationItems) {
          // if (item.approved) {
          //   let sqft =
          //     getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.height ?? 0);
          //   unitTotal += sqft;
          //   prices = addToPPURentPrice(prices, item);
          //   ppuCount++;
          // }
          if (item?.approved === true && !!item?.rent) {
            prices.rentTotal += Number(item?.rent);
          }
          suffix = "Sqf";
        }

        break;
    }
  const { priceSubtotal, rentTotal, totalPPU } = prices;
  averagePPU = Math.round((totalPPU / ppuCount) * 100) / 100;
  // console.log("getRawElevationTotal", {
  //   unitTotal,
  //   suffix,
  //   priceSubtotal,
  //   totalPPU,
  //   ppuCount,
  //   averagePPU,
  //   rentTotal,
  // });
  console.log(priceSubtotal, rentTotal);
  return { unitTotal, suffix, priceSubtotal, averagePPU, rentTotal };
};

//depending on service type not all statistics are returned since some of them does not make sense
export const getElevationTotal = (serviceId, elevationItems) => {
  let { unitTotal, suffix, priceSubtotal, averagePPU, rentTotal } =
    getRawElevationTotal(serviceId, elevationItems);
  // console.log("getRawElevationTotal", {
  //   unitTotal,
  //   suffix,
  //   priceSubtotal,
  //   averagePPU,
  //   rentTotal,
  // });
  switch (serviceId) {
    case 3:
      return {};
    case 38:
      return {};
    // case 11:
    // case 13:
    case 16:
    case 17:
    case 19:
    case 5: {
      return { unitTotal, suffix, priceSubtotal, rentTotal };
    }
    // case 20: {
    //   return { unitTotal, suffix, priceSubtotal, rentTotal };
    // }
    default: {
      return { unitTotal, suffix, priceSubtotal, averagePPU, rentTotal };
    }
  }
};

export const getRawOptionTotal = (serviceId, option) => {
  let unitTotal = 0,
    suffix = "",
    priceSubtotal = 0,
    totalPPU = 0,
    rentTotal = 0;

  for (const elevation of option) {
    let rawElevationTotal = getRawElevationTotal(serviceId, elevation.items);
    unitTotal += rawElevationTotal.unitTotal;
    suffix = rawElevationTotal.suffix;
    //if elevation does not have a calculated rent/price, it means it has rent and price in head
    // console.log("myService Id", option, serviceId, elevation);
    switch (serviceId) {
      case 3:
        rentTotal += parseFloat(elevation?.rentalsTotal ?? 0);
        priceSubtotal += rawElevationTotal.priceSubtotal;
        totalPPU += rawElevationTotal.averagePPU;
        break;
      case 38:
        rentTotal += parseFloat(elevation?.rentalsTotal ?? 0);
        priceSubtotal += rawElevationTotal.priceSubtotal;
        totalPPU += rawElevationTotal.averagePPU;
        break;
      case 11:
      case 13: {
        priceSubtotal += rawElevationTotal.priceSubtotal;
        totalPPU += rawElevationTotal.averagePPU;
        rentTotal += rawElevationTotal.rentTotal;
        break;
      }
      case 16:
      case 17:
      case 19:
      case 20: {
        priceSubtotal += parseFloat(elevation.price);
        rentTotal += parseFloat(elevation.rent);
        break;
      }
      default: {
        priceSubtotal += rawElevationTotal.priceSubtotal;
        totalPPU += rawElevationTotal.averagePPU;
        rentTotal += rawElevationTotal.rentTotal;
        break;
      }
    }
  }

  let averagePPU = Math.round((totalPPU / option.length) * 100) / 100;
  let averageTotal = Math.round((priceSubtotal / option.length) * 100) / 100;
  let averageRent = Math.round((rentTotal / option.length) * 100) / 100;

  switch (serviceId) {
    case 3:
      return { priceSubtotal, rentTotal };
    case 38:
      return { priceSubtotal, rentTotal };
    case 11:
    case 13:
    case 16:
    case 17:
    case 19:
    case 20: {
      return {
        unitTotal,
        suffix,
        priceSubtotal,
        rentTotal,
        averageTotal,
        averageRent,
      };
    }
    default: {
      return {
        unitTotal,
        suffix,
        priceSubtotal,
        averagePPU,
        rentTotal,
        averageTotal,
        averageRent,
      };
    }
  }
};

export const getRawOptionTotalUnits = (serviceId, option) => {
  let unitTotal = 0,
    suffix = "";

  for (const elevation of option) {
    let rawElevationTotal = getRawElevationTotal(serviceId, elevation.items);
    unitTotal += rawElevationTotal.unitTotal;
    suffix = rawElevationTotal.suffix;
  }

  return { unitTotal, suffix };
};
export const getRawServiceTotals = (service, allOptions) => {
  let priceSubtotal_forAll = 0,
    rentTotal_forAll = 0;

  if (allOptions === true) {
    //     for (const option of service.serviceOptions || []) {
    let allElevationTypes = service.serviceOptions.reduce(
      (a, b) => a.concat(b),
      []
    );
    //  console.log(service.serviceOptions)
    let { priceSubtotal, rentTotal } = getRawOptionTotal(
      service.serviceId,
      allElevationTypes || []
    );
    priceSubtotal_forAll += priceSubtotal;
    rentTotal_forAll += rentTotal;
    //     }
  } else {
    let { priceSubtotal, rentTotal } = getRawOptionTotal(
      service.serviceId,
      service.serviceOptions?.[0] || []
    );
    priceSubtotal_forAll = priceSubtotal;
    rentTotal_forAll = rentTotal;
  }

  if (service.serviceAddons)
    for (const addon of service.serviceAddons) {
      priceSubtotal_forAll += parseFloat(addon.price ?? "0");
      rentTotal_forAll += parseFloat(addon.rent ?? "0");
    }

  // console.log("rentTotalForAll", {
  //   priceSubtotal_forAll,
  //   rentTotal_forAll,
  //   service,
  // });

  return {
    priceSubtotal: priceSubtotal_forAll,
    rentTotal: rentTotal_forAll,
  };
};

/**
 * @param services {Service[]}
 * @param allOptions {boolean|undefined}
 * */
export const getRawAllServicesTotals = (services, allOptions) => {
  let priceSubtotal_forAll = 0,
    rentTotal_forAll = 0;

  for (const service of services) {
    const { priceSubtotal, rentTotal } = getRawServiceTotals(
      service,
      allOptions
    );
    priceSubtotal_forAll += priceSubtotal;
    rentTotal_forAll += rentTotal;
  }
  return { priceSubtotal: priceSubtotal_forAll, rentTotal: rentTotal_forAll };
};

export const getRawElevationTotalUnits = (serviceId, elevationItems) => {
  let unitTotal = 0,
    suffix = "";
  // let pricePerUnit = this.state.pricePerUnitInfo[serviceId];
  // console.log("serviceId", serviceId);

  switch (serviceId) {
    // length x height
    case 2: {
      elevationItems.forEach((item) => {
        if (item.approved) {
          let sqft =
            getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item["height"] ?? 0);
          unitTotal += sqft;
        }
        suffix = "Sqf";
      });
      break;
    }
    case 1: {
      elevationItems.forEach((item) => {
        if (item.approved) {
          let sqft = getMaxNoFeet(item.length ?? 0);
          unitTotal += sqft;
        }
        suffix = "Lnf.";
      });
      break;
    }
    // linear length
    case 4: {
      elevationItems.forEach((item) => {
        if (item.approved) {
          let sqft = getMaxNoFeet(item.length ?? 0);
          unitTotal += sqft;
        }
        suffix = "Lnf.";
      });
      break;
    }
    case 6:
    case 7:
    case 9:
    case 11: {
      elevationItems.forEach((item) => {
        if (item.approved) {
          let sqft =
            getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item["height"] ?? 0);
          unitTotal += sqft;
        }
        suffix = "Sqf";
      });
      break;
    }
    case 15: {
      elevationItems.forEach((item) => {
        if (item.approved) {
          let sqft = getMaxNoFeet(item.length ?? 0);
          unitTotal += sqft;
        }
        suffix = "Lnf.";
      });
      break;
    }
    // length x height
    // case 11:
    case 14:
    case 18:
    case 20: {
      for (const item of elevationItems) {
        // console.log("item item ", item);
        if (item.approved) {
          let sqft =
            getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item["height"] ?? 0);
          unitTotal += sqft;
          // prices = addToPPURentPrice(prices, item);
          // ppuCount++;
        }
        // if (!item?.approved && !!item?.rent) {
        //   prices.rentTotal += Number(item?.rent);
        // }
        suffix = "Sqf";
      }
      break;
    }
    case 19: {
      elevationItems.forEach((item) => {
        if (item.approved) {
          let sqft =
            getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item.height ?? 0);
          unitTotal += sqft;
        }
        suffix = "Sqf";
      });
      break;
    }
    // length x width
    case 5:
    case 44:
    case 8:
    case 10:
    case 12:
    case 13:
    case 3:
    case 38:
    case 69: {
      for (const item of elevationItems) {
        // console.log("item item ", item);
        if (item.approved) {
          let sqft =
            getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item["height"] ?? 0);
          unitTotal += sqft;
          // prices = addToPPURentPrice(prices, item);
          // ppuCount++;
        }
        // if (!item?.approved && !!item?.rent) {
        //   prices.rentTotal += Number(item?.rent);
        // }
        suffix = "Sqf";
      }
      break;
    }
    case 80: {
      for (const item of elevationItems) {
        // console.log("item item ", item);
        if (item.approved) {
          let sqft =
            getMaxNoFeet(item.length ?? 0) * getMaxNoFeet(item?.height ?? 0);
          unitTotal += sqft;
          // prices = addToPPURentPrice(prices, item);
          // ppuCount++;
        }
        // if (!item?.approved && !!item?.rent) {
        //   prices.rentTotal += Number(item?.rent);
        // }
        suffix = "Sqf";
      }
      break;
    }
    default:
      break;
  }

  return { unitTotal, suffix };
};

//helper functions
const positivePrice = (price) => {
  const priceNumber = parseFloat(price);
  if (priceNumber <= 0) {
    return 0;
  } else {
    return priceNumber;
  }
};

const nonNegativeNumber = (price) => {
  const priceNumber = parseFloat(price);
  if (priceNumber < 0) {
    return 0;
  } else {
    return priceNumber;
  }
};

const addToPPURentPrice = ({ priceSubtotal, rentTotal, totalPPU }, item) => {
  // console.log("item", item);
  priceSubtotal += positivePrice(item?.price ?? 0);
  rentTotal += nonNegativeNumber(item?.rent ?? 0);
  totalPPU += positivePrice(item?.ppu ?? 0);
  return { priceSubtotal, rentTotal, totalPPU };
};
