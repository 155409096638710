import React from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Menu,
  message,
  Modal,
  Select,
  Tooltip,
} from "antd";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import { checkIfServiceIsHoist, Service } from "../../models/Service";
import { compareIncluding, formatCurrency } from "../../../../utils";
import "./ServiceMenuContainer.scss";
import { MondayButton } from "../../../../../commonComponents/index";
import { PlusIcon } from "../../../../../../assets/index";
import "./ServiceMenuContainer.scss";
import { DollarIcon } from "../../../components/DataEntryGridModal/assets/index";
import { otherScopeServiceTotalPriceMapper } from "../ServiceHeader/ServicePrices/utils";
import { ScopeIcon } from "../../../../../../icons/index";
import { getServiceLegacyPrice } from "../../../../Projects/Accounting/calculations/servicePrices";
import { attachPriceSchemes } from "../../tools/priceSchemes/defaultApply";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { ClockComponent } from "../Header/ImportantUpdates/Clock";
import {
  dayjsNYDate,
  dayjsNY,
} from "../../../../../DateComponents/contants/DayjsNY";

const ReactGridLayout = WidthProvider(RGL);

class ServiceMenuContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedServiceList: [],
      selectedColor: "#116d6e",
      hasntShownAllServices: this.props?.gridData?.some(
        (el) => el?.visible === false
      ),
    };
  }

  // confirm window that lets you add a service
  handleAddService = () => {
    const { serviceDefinitions, gridData, isWritable } = this.props;

    const existingServiceIds = gridData.map(({ serviceId }) =>
      String(serviceId)
    );
    const availableScopes = serviceDefinitions.filter(
      ({ serviceId, isScope }) =>
        !!isScope && !existingServiceIds.includes(serviceId)
    );

    if (isWritable) {
      let availableServices = [...this.props.serviceDefs];
      console.log("availableServices", availableServices);
      gridData.forEach((service) => {
        availableServices = availableServices.filter(
          (serviceDef) => parseInt(serviceDef["workId"]) !== service.serviceId
        );
      });
      Modal.confirm({
        title: "Select services:",
        className: `addServiceModal__${this.props?.agGridTheme || ""}`,
        content: (
          <Select
            showSearch
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Type in service name..."
            optionFilterProp="children"
            onChange={(value) => {
              this.setState({ selectedServiceList: value });
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {[
              ...new Set([
                ...availableServices
                  ?.filter((el) => el?.isScope !== true)
                  ?.map((service) => (
                    <Select.Option
                      value={service["workId"]}
                      key={service["workId"]}
                    >
                      {service["workName"]}
                    </Select.Option>
                  )),
                ...availableScopes.map(({ serviceId, serviceName }) => (
                  <Select.Option key={serviceId} value={serviceId}>
                    {serviceName}
                  </Select.Option>
                )),
              ]),
            ]}
          </Select>
        ),
        okText: "Add",
        cancelText: "Cancel",
        centered: true,
        // footer: (
        //   <div>
        //     <MondayButton>Cancel</MondayButton>{" "}
        //     <MondayButton>Save</MondayButton>
        //   </div>
        // ),
        onOk: (close) => {
          if (this.state.selectedServiceList) {
            this.appendService(this.state.selectedServiceList, close);
          } else {
            message.error("Please choose a service first.", 3);
          }
        },
        onCancel: () => {
          this.setState({ selectedServiceList: [] });
        },
      });
    } else {
      message.error("Please enable write mode.", 4);
    }
  };

  // adds a service to coreservices, estimations and the view
  appendService = (serviceIdList, closeModal) => {
    const { record, serviceDefinitions } = this.props;
    const { taxRate = 0, taxExempt } = record || {};

    const scopeById = serviceDefinitions.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.serviceId]: curr,
      }),
      {}
    );

    let newServices = [];
    for (const serviceId of serviceIdList) {
      const {
        isScope,
        serviceName,
        columnDefinitions = [],
        chainedService = false,
      } = scopeById?.[serviceId] || {};

      let particulate =
        this.props.serviceDefs.find(
          (serviceDef) => serviceDef?.["workId"] === serviceId
        ) || scopeById[serviceId];
      console.log("addingcolumnDefinitions", columnDefinitions);
      let service = new Service(
        parseInt(serviceId),
        particulate?.["workName"] || scopeById[serviceId]?.serviceName,
        false,
        taxRate,
        !compareIncluding("yes", taxExempt),
        !!isScope,
        particulate?.isHoist,
        columnDefinitions
      );
      // This makes the connection with other Scope Services
      if (chainedService) {
        service.chainedService = chainedService;
      }

      newServices.push({
        ...service,
        rent: getDefaultRentForService(serviceId), // Added default Rent value
        ...(isScope
          ? {
              serviceOptions: [
                [
                  {
                    elevationId: serviceId,
                    elevationLabel: serviceName,
                    items: [
                      columnDefinitions?.reduce(
                        (acc, { field, initialValue }) => ({
                          ...acc,
                          [field]: initialValue,
                        }),
                        { id: 1 }
                      ),
                    ],
                  },
                ],
              ],
            }
          : {}),
      });
    }

    this.props.setState({
      gridData: this.props.gridData.concat(
        attachPriceSchemes(newServices, this.props?.pricingData)
      ),
    });
    // }
    this.setState(
      {
        selectedServiceList: [],
      },
      () => {
        closeModal();
      }
    );
  };

  handleModifyServices = (visibility) => {
    if (visibility) {
      this.props.setIsWritable(false);
    }
    let gridData = _.cloneDeep(this.props.gridData);
    gridData.forEach((service) => {
      service.visible = visibility;
    });
    this.props.setState({ gridData: gridData });
    this.setState({
      hasntShownAllServices: !visibility,
    });
    if (this.props.isWritable) {
      this.props.handleSave(false);
    }
  };

  handleLayoutChange = (newLayout) => {
    // console.log("new layout ", newLayout);
    let sortedNewLayout = newLayout.sort((a, b) => a.y - b.y);
    const gridData = _.cloneDeep(this.props.gridData);
    const activePanelsProps = _.cloneDeep(this.props.activePanels);

    let newGrid = sortedNewLayout.map((item) =>
      gridData.find((s) => s.label === item.i)
    );
    let activePanels = sortedNewLayout.map(
      (item) => activePanelsProps[this.props.ServicesIndex[item.i]]
    );
    this.props.setState({
      // serviceGridKey: this.props.serviceGridKey + 1, //check if there will be any render delay from that
      gridData: newGrid,
      activePanels: activePanels,
      agSelectedCells: {},
    });
    if (this.props.isWritable) {
      this.props.handleSave(false);
    }
  };

  // removes a service and its options
  handleServiceRemoval = (service, addingServiceAccess) => {
    if (this.props.isWritable) {
      if (addingServiceAccess) {
        Modal.confirm({
          title: (
            <div>
              <p style={{ fontSize: 16 }}>
                Are you sure you want to delete this service?{" "}
                <strong style={{ color: "red", fontWeight: "bold" }}>
                  All of its options and their data will PERMANENTLY be deleted
                  with it.
                </strong>
              </p>
            </div>
          ),
          okText: "Delete",
          cancelText: "Cancel",
          centered: true,
          className: "removeServicesModal",
          onOk: () => {
            let gridData = _.cloneDeep(this.props.gridData);
            let activePanels = _.cloneDeep(this.props.activePanels);
            gridData.forEach((serviceData, index) => {
              if (serviceData.serviceId === service.serviceId) {
                gridData.splice(index, 1);
                activePanels.splice(index, 1);
              }
            });
            this.props
              .setState({ gridData: gridData, activePanels: activePanels })
              .then(() => {
                this.props.handleSave(false);
              })
              .catch((e) => console.error(e));
          },
          onCancel: () => {},
        });
      } else {
        message.error("You dont have access to remove service.", 4);
      }
    } else {
      message.error("Please enable write mode.", 4);
    }
  };

  handleServiceSelected = (serviceIndex, checked, color) => {
    // this.props.setViewActivePanels([[[]]]);
    this.props.setLoading(true);
    this.props.setViewActivePanels([[[]]]);
    this.setState({ selectedColor: color });
    try {
      let gridData = _.cloneDeep(this.props.gridData);
      if (gridData?.filter((el) => el?.visible).length > 1) {
        gridData?.forEach((service, index) => {
          if (index !== serviceIndex) {
            service.visible = false;
          }
        });
      } else {
        gridData?.forEach((service, index) => {
          if (index !== serviceIndex) {
            service.visible = false;
          } else {
            service.visible = checked;
          }
        });
      }
      // gridData[serviceIndex].visible = checked;
      this.props.setState({ gridData: gridData });

      if (this.props.isWritable) {
        this.props.handleSave(false);
      }
      // this.props.setState({ loading: false });

      // let gridData = _.cloneDeep(this.props.gridData);
      // gridData[serviceIndex].visible = checked;
      // this.props.setState({ gridData: gridData });
      // if (this.props.isWritable) {
      //   this.props.handleSave(false);
      // }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        this.props.setLoading(false);
      }, 900);
    }
  };

  render() {
    console.log("savedData", this.props.savedData);

    let dueDate = this.props.savedData?.estimation?.dueDate;

    //     console.log(this.props.accessRights?.children);
    let addingServiceAccess = this.props.canAddOrRemoveServices;
    let canViewPrice = this.props.canViewPrice;
    let selectedService = this?.props?.gridData.find((el) => el?.visible);
    const color =
      this?.props?.serviceDefs?.find(
        (sd) => sd.workId.toString() === selectedService?.serviceId?.toString()
      )?.colorCode || "#000000";
    // this.setState({
    //   selectedColor: color,
    // });
    return (
      <div
        className="serviceMenuContainer"
        style={{
          boxShadow: `0px 0px 10px 3px ${color}`,
          marginTop: "13px",
          // animation: "slideFromLeft 2s",
        }}
      >
        {/* <ClockComponent /> */}
        {!!dueDate && (
          <div
            style={{
              background: dayjsNY().isAfter(dueDate) ? "#E75651" : "#D3F1C0",
              fontSize: "25px",
              lineHeight: "34px",
              padding: "5px",
              fontWeight: "700",
              font: "Open Sans",
              color: dayjsNY().isAfter(dueDate) ? "#fff" : "#445B35",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <h3>{dayjsNY(dueDate).format("MM/DD/YYYY")}</h3>
          </div>
        )}
        <br />
        <div className="add-service-btn-cont">
          <MondayButton
            className={
              addingServiceAccess ? "mondayButtonBlue" : "mondayButtonRed"
            }
            Icon={<PlusIcon />}
            buttonStyle={{
              width: "220px",
            }}
            onClick={this.handleAddService}
            disabled={!addingServiceAccess}
          >
            {addingServiceAccess ? "Add Service" : "Not Clearance to Add"}
          </MondayButton>
        </div>

        {/* <Button
          className="appendServiceButton"
          onClick={this.handleAddService}
          disabled={!addingServiceAccess}
        >
          <AddOutlined /> Add Service
        </Button> */}

        <div className="hide-toggle-container">
          {this.props?.gridData?.some((el) => el?.visible === false) ? (
            <MondayButton
              className="mondayButtonBlue"
              Icon={<EyeFilled />}
              onClick={() => {
                this.handleModifyServices(true);
              }}
            >
              Preview
            </MondayButton>
          ) : (
            <MondayButton
              className="mondayButtonBlue"
              Icon={<EyeInvisibleFilled />}
              onClick={() => {
                this.handleModifyServices(false);
              }}
            >
              Hide All
            </MondayButton>
          )}
          {/* <Button
            className="hideShowAllServicesButton"
            onClick={() => {
              this.handleModifyServices(false);
            }}
          >
            <VisibilityOff /> Hide All
          </Button> */}
          {/* <Button
            className="hideShowAllServicesButton"
            onClick={() => {
              this.handleModifyServices(true);
            }}
          >
            <Visibility /> Show All
          </Button>{" "} */}
        </div>

        <div
          style={{
            // height: "100%",
            width: "100%",
          }}
        >
          <ReactGridLayout
            key={this.props.serviceGridKey}
            className="serviceMenu"
            isResizable={false}
            cols={1}
            rowHeight={36}
            // width={100}
            containerPadding={[0, 0]}
            // margin={[0, 3]}
            onDragStop={this.handleLayoutChange}
            draggableCancel=".serviceCheckbox"
          >
            {this?.props?.gridData.map((service, serviceIndex) => {
              const color =
                this?.props?.serviceDefs?.find(
                  (sd) => sd.workId.toString() === service.serviceId.toString()
                )?.colorCode || "#000000";
              return (
                <div
                  key={service.label}
                  data-grid={{ x: 0, y: serviceIndex, w: 1, h: 1 }}
                  style={{
                    minWidth: "220px",
                    // padding: "20px",
                  }}
                >
                  <Dropdown
                    trigger={["contextMenu"]}
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: `Remove ${service.label}`,
                          onClick: () => {
                            this.handleServiceRemoval(
                              service,
                              addingServiceAccess
                            );
                          },
                        },
                      ],
                    }}
                  >
                    <div
                      className="checkboxContainer"
                      style={{
                        ...(service.visible && {
                          borderRight: "solid 5px " + color,
                          backgroundColor: color + "1F",
                          // backgroundColor: "rgba(255, 255, 255, 0.1)",
                        }),
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      <Checkbox
                        style={{
                          display: "flex",
                          alignItems: "center",
                          transform: "none",
                          // marginTop: "-5px",
                        }}
                        className="serviceCheckbox"
                        checked={service.visible}
                        onChange={(e) => {
                          // console.log("onchange ", e);
                          this.handleServiceSelected(
                            serviceIndex,
                            e.target.checked,
                            color
                          );
                        }}
                      />{" "}
                      <label
                        className="serviceLabel"
                        style={{
                          zIndex: 10000,
                          // lineHeight: "36px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "220px",
                          // paddingTop: "7px",
                          fontWeight: 400,
                          fontSize: "14px",
                          cursor: "grab",
                          fontFamily: "Open Sans",
                          //  .estimation-services-text {
                          //   text-overflow: ellipsis;
                          //   white-space: nowrap;
                          //   overflow: hidden;
                          //   text-overflow: ellipsis;
                          // }
                        }}
                      >
                        {" "}
                        <Tooltip title={service?.label || ""}>
                          {service.label}
                        </Tooltip>
                        {/* {servicePriceTakeOff(service)} */}
                      </label>{" "}
                      {service?.isScope === true && (
                        <Tooltip title="This Service is a Scope">
                          <span
                            className="scopeIconContainer"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                            }}
                          >
                            <ScopeIcon />
                          </span>
                        </Tooltip>
                      )}
                      <Tooltip
                        title={() => servicePriceTakeOff(service, canViewPrice)}
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",

                            // paddingBottom: "10px",
                            // marginLeft: "5px",
                          }}
                        >
                          <DollarIcon />
                        </span>{" "}
                      </Tooltip>
                    </div>
                  </Dropdown>
                </div>
              );
            })}
          </ReactGridLayout>
        </div>
      </div>
    );
  }
}

export default React.memo(ServiceMenuContainer);

const servicePriceTakeOff = (service, canViewPrice) => {
  if (!canViewPrice) return "Price is hidden - You don't have clearance";
  const totalPriceMapper = (el) =>
    !isNaN(+el?.totalPrice) ? +el?.totalPrice : 0;
  const hoistTotalPriceMapper = ({ totalPrice }) => totalPrice;
  console.log("service", service);
  let tempPrice = 0;
  if (service?.isScope === true) {
    tempPrice = otherScopeServiceTotalPriceMapper(service);
  } else if (checkIfServiceIsHoist(service)) {
    tempPrice =
      [
        ...service?.serviceOptions?.[0]?.flatMap?.(
          ({ items }) =>
            items
              ?.filter?.(({ approved }) => !!approved)
              ?.map?.(totalPriceMapper) || [0]
        ),
        ...(service?.serviceAddons?.map?.(totalPriceMapper) || [0]),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0)
        ?.toFixed?.(2) || 0;
  } else {
    tempPrice =
      [
        ...service?.serviceOptions?.[0]?.map(hoistTotalPriceMapper),
        ...service?.serviceAddons.map(totalPriceMapper),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0)
        ?.toFixed?.(2) || 0;
  }

  tempPrice = getServiceLegacyPrice(service);

  return formatCurrency(tempPrice || 0);
};
export const getDefaultRentForService = (serviceId) => {
  console.log("serviceId", serviceId);
  switch (serviceId?.toString()) {
    case "18":
      return 0;
      break;
    default:
      return 7;
  }
};
