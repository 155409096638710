import {
  formatCurrency,
  roundToDecimals,
} from "../../../../../../../../../../utils";
import {
  Expend,
  Info,
  Retrackt,
} from "../../../../../../../../../../BasePage/src";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { Cancel, Close } from "../../../../../../../../../../DynamicView/src";
import { addonsColumnDefs, columnDefs, defaultColDef } from "./GridData";
import "./service-details.scss";
import {
  primaryServiceElevationsExtractor,
  serviceAddonsExtractor,
  totalPriceOfElevationCalculator,
} from "./serviceDetailsFunctions";

import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import { Modal, Collapse } from "antd";
import { useState } from "react";

const ServiceDetails = ({ service, borderColor, darkMode }) => {
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [activePanel, setActivePanel] = useState([
    service?.serviceOptions?.[0]?.[0]?.elevationId,
  ]);
  const { programFields } = useSelector((state) => state.programFields);

  const panels = [
    ...(primaryServiceElevationsExtractor({ service }) || []),
    ...(serviceAddonsExtractor({ service }) || []),
  ];

  // Close Modal
  const onCancel = () => setIsDetailsModalVisible(false);

  // Collapse & expand panels
  const collapseAll = () => setActivePanel([]);
  const expandAll = () =>
    setActivePanel(
      panels?.map((panel) =>
        panel?.panelType === "elevation" ? panel?.elevationId : panel?.id
      )
    );

  // Calculate total price of elevation
  const totalPriceOfElevation = (elevation) =>
    totalPriceOfElevationCalculator({ service, elevation });

  return (
    <>
      <div className="serviceDetailsButtonContainer">
        <MondayButton
          className="serviceDetailsButton"
          onClick={() => setIsDetailsModalVisible(true)}
          Icon={<Info />}
        >
          Details
        </MondayButton>
      </div>
      <Modal
        wrapClassName={
          darkMode
            ? "serviceHeaderDetailsModalDarkMode"
            : "serviceHeaderDetailsModalLightMode"
        }
        open={isDetailsModalVisible}
        onCancel={onCancel}
        footer={null}
        closeIcon={darkMode ? <Close /> : <Cancel />}
      >
        <div className="service-details-card-header">
          <h3 className="service-details-card-header-title">
            <span
              className="service-details-card-header-title-color"
              style={{ background: borderColor }}
            />
            {service.label}
          </h3>
          <div className="service-details-card-header-options">
            <MondayButton
              className="service-details-card-header-option"
              onClick={collapseAll}
              Icon={<Retrackt />}
            >
              Collapse All
            </MondayButton>
            <MondayButton
              className="service-details-card-header-option"
              onClick={expandAll}
              Icon={<Expend />}
            >
              Expand All
            </MondayButton>
          </div>
        </div>
        <div className="service-details-card">
          <Collapse activeKey={activePanel} onChange={(e) => setActivePanel(e)}>
            {!!panels.length &&
              panels?.map((panel) => (
                <Collapse.Panel
                  key={
                    panel?.panelType === "elevation"
                      ? panel.elevationId
                      : panel.id
                  }
                  header={
                    <div className="service-details-card-collapse-panel">
                      {`  ${
                        panel?.panelType === "elevation"
                          ? panel.elevationId
                          : ""
                      } ${
                        panel?.panelType === "elevation"
                          ? panel.elevationLabel
                          : panel?.name
                      }
                     - Price total: ${formatCurrency(
                       panel?.panelType === "elevation"
                         ? totalPriceOfElevation(panel)
                         : panel?.totalPrice,
                       "USD"
                     )}`}
                    </div>
                  }
                >
                  <div
                    className={
                      darkMode
                        ? "agGridRentalsDetailsStyleDark ag-theme-alpine-dark"
                        : "agGridRentalsDetailsStyleLight ag-theme-alpine"
                    }
                    style={{
                      height:
                        panel?.panelType === "elevation"
                          ? `${panel?.items?.length * 50 + 150}px`
                          : "200px",
                    }}
                  >
                    <AgGridReact
                      rowHeight={50}
                      defaultColDef={defaultColDef}
                      columnDefs={
                        panel?.panelType === "elevation"
                          ? columnDefs(service, panel?.items, programFields)
                          : addonsColumnDefs(service, panel, programFields)
                      }
                      rowData={
                        panel?.panelType === "elevation"
                          ? panel?.items
                          : [panel]
                      }
                      suppressDragLeaveHidesColumns={true}
                    />
                  </div>
                </Collapse.Panel>
              ))}
          </Collapse>
        </div>
      </Modal>
    </>
  );
};

export default ServiceDetails;
