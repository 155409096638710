import React, {
  useRef,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import { message, Select } from "antd";
import { useSelector } from "react-redux";

import DegModalContext from "../DegModalContext";
import findShiftEntries from "./findShiftEntries";
import { DropdownIcon } from "../../../../../../../../icons";
import { useEntriesApi } from "../../../../../../PayrollLive/utils";

const ServiceGridSelect = forwardRef(
  ({ value, stopEditing, api, data }, ref) => {
    const { isDarkMode } = useSelector((state) => state.darkMode);
    const {
      crews,
      rowData,
      jobsites,
      analytics,
      setRowData,
      jobsiteServices,
      addActionToRegister,
      defaultServicesPerProject,
    } = useContext(DegModalContext);

    const services = useRef(value || []);

    const { updateEntries } = useEntriesApi();

    let entriesOfShift = data?.entryId
      ? findShiftEntries({
          crews,
          rowData,
          jobsites,
          analytics,
          entryId: data?.entryId,
        })
      : rowData.filter(({ entryId }) => data?.entries.includes(entryId));

    useImperativeHandle(
      ref,
      () => {
        return {
          getValue() {
            return services.current;
          },
          isCancelBeforeStart() {
            return false;
          },
        };
      },
      []
    );

    function onSelect(event) {
      services.current = services.current?.concat(event);
      if (data?.shiftId) {
        const currentRow = Object.assign(data, {
          sow: services.current,
        });
        api.applyTransaction({
          update: [currentRow],
        });
        for (let entry of entriesOfShift) {
          Object.assign(entry, {
            sow: services.current,
          });
        }
      } else {
        for (let entry of entriesOfShift) {
          Object.assign(entry, {
            sow: services.current,
          });
        }
        api.applyTransaction({
          update: entriesOfShift,
        });
      }
    }

    function onDeselect(e) {
      services.current = services.current?.filter((service) => service !== e);
      if (data?.shiftId) {
        const currentRow = Object.assign(data, {
          sow: services.current,
        });
        api.applyTransaction({
          update: [currentRow],
        });
      } else {
        for (let entry of entriesOfShift) {
          Object.assign(entry, {
            sow: services.current,
          });
        }
        api.applyTransaction({
          update: entriesOfShift,
        });
      }
    }

    const serviceOptions =
      jobsiteServices?.[data?.jobsiteId]?.find?.(
        ({ dateRange }) =>
          dateRange[0] <=
            (data?.entryId ? data?.punchTimeStamp : data?.firstClockIn) &&
          (data?.entryId ? data?.punchTimeStamp : data?.firstClockIn) <=
            dateRange[1]
      )?.scheduleServices ||
      defaultServicesPerProject?.[
        data?.jobsiteId ? data?.jobsiteId : data?.jobSiteId
      ] ||
      [];

    return (
      <Select
        {...{
          onSelect,
          onDeselect,
          size: "large",
          mode: "multiple",
          defaultOpen: true,
          defaultValue: value || [],
          suffixIcon: <DropdownIcon />,
          open: serviceOptions?.length,
          getPopupContainer: () => document.body,
          popupClassName: isDarkMode && "darkDropDown",
          className: `gridDropdownSelect ${
            isDarkMode && "gridSelectDark"
          } servicesSelect`,
          style: {
            width: "100%",
            height: "100%",
            borderRadius: "0px",
          },
          options: serviceOptions.map((service, key) => ({
            key,
            label: service,
            value: service,
          })),
          onDropdownVisibleChange: (dropdownVisible) => {
            if (!dropdownVisible) {
              message.loading({
                duration: 0,
                key: "onServiceSelect",
                content: "Saving...",
              });
              if (data?.shiftId) {
                let updatedData = [];
                let editActions = [];
                const updatedRowData = rowData.map((activity) => {
                  if (data?.entries.includes(activity?.entryId)) {
                    const editedData = Object.assign(activity, {
                      sow: services.current,
                    });
                    updatedData.push(editedData);
                    editActions.push({ curr: editedData, prev: activity });
                    return editedData;
                  } else {
                    return activity;
                  }
                });

                updateEntries({
                  entries: updatedData,
                  onSuccessCallback: () => {
                    message.success({
                      duration: 3,
                      key: "onServiceSelect",
                      content: "Services updated successfully",
                    });
                    setRowData(updatedRowData);
                    addActionToRegister({ type: "edit", editActions });
                  },
                  onErrorCallback: (err) => {
                    console.log("err: ", err);
                    message.error({
                      duration: 3,
                      key: "onServiceSelect",
                      content: "There was a problem updating services",
                    });
                  },
                });
              }
              stopEditing();
            }
          },
        }}
      />
    );
  }
);

ServiceGridSelect.displayName = "ServiceGridSelect";
ServiceGridSelect.propTypes = {
  api: PropTypes.object,
  data: PropTypes.object,
  value: PropTypes.object,
  stopEditing: PropTypes.func,
};

export default ServiceGridSelect;
