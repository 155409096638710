import dayjs from "dayjs";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { Modal, Form, message } from "antd";
import { useState, useEffect, useMemo, useRef, useContext } from "react";

import {
  lazyFetch,
  fetchAllData,
  updateDocumentTitle,
} from "../../../../../../utils";
import {
  MondayButton,
  WarningModal,
  MultiLevelTreeLogs,
} from "../../../../../commonComponents";
import PayrollContext from "../../../PayrollContext";
import { useEditLogs } from "../../../../../../hooks";
import { jobsiteFields, footerButtons } from "./jobsiteModalData";
import JobsiteRatesGrid from "./JobsiteRatesGrid/JobsiteRatesGrid";
import { DownloadIcon } from "../../../../../SidebarPages/BasePage/src";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../Settings/settingsComponents/Roles/src";
import { WarningTriangle } from "../../../../../SidebarPages/DynamicView/src";
import { getCoordinatesAndZip } from "../../../../../SidebarPages/Fleet/utils";
import { RenderDynamicComponents } from "../../../../../Header/forms/Components";
import { getChangedData } from "src/components/SidebarPages/Accounting/components/utilities";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import PayrollActivityMap from "../../Activity/components/PayrollActivityMap/PayrollActivityMap";
import { ReportCheck } from "../../../../../SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";

import "./JobsiteModal.scss";

function JobsiteModal({
  open,
  onCancel,
  editMode,
  rowToEdit,
  refreshTable,
  rowData: jobsites,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { allProjects } = useContext(PayrollContext);

  const [roles, setRoles] = useState([]);
  const [clients, setClients] = useState([]);
  const [editLogs, setEditLogs] = useState([]);
  const [changes, setChanges] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [accessRight, setAccessRight] = useState(false);
  const [onCancelModal, setOnCancelModal] = useState(false);
  const [addressSelected, setAddressSelected] = useState(null);
  const [connectedJobsites, setConnectedJobsites] = useState([]);
  const [saveBtnDisable, setSaveButtonDisable] = useState(false);
  const [logsModalVisible, setLogsModalVisible] = useState(false);
  const [projectConnected, setProjectConnected] = useState(false);

  const [form] = Form.useForm();
  const { saveAddedLogs } = useEditLogs();
  const category = Form.useWatch("payrollType", form);
  const radius = Form.useWatch("locationRadius", form);
  const selectedClient = Form.useWatch("accountName", form);
  const reimbursement = Form.useWatch("reimbursement", form);

  const modalMapRef = useRef();
  const jobsiteGridRef = useRef();

  function ratesGridApi() {
    if (jobsiteGridRef?.current) {
      return jobsiteGridRef.current.getRatesGridApi();
    } else {
      return null;
    }
  }

  function geofenceInfo() {
    if (modalMapRef?.current) {
      return modalMapRef?.current.getGeofenceInfo();
    } else {
      return [];
    }
  }

  function setGeofenceInfo(newGeofenceInfo) {
    if (modalMapRef?.current) {
      return modalMapRef?.current.updateGeofenceInfo(newGeofenceInfo);
    }
  }

  const projectOptions = useMemo(() => {
    let unconnectedProjects = [];
    if (allProjects?.length) {
      for (const project of allProjects) {
        {
          const unconnected = connectedJobsites.some(
            ({ projectId }) => projectId === project?.projectId
          );
          if (!unconnected) {
            unconnectedProjects.push(project);
          }
        }
      }
    }

    return unconnectedProjects;
  }, [allProjects, connectedJobsites]);

  const newEditLog = {
    recordId: rowToEdit?.jobsiteId,
    recordName: rowToEdit?.jobAddress,
    category: "Jobsite Logs",
    actionType: "Edit",
    currentData: {},
    previousData: {},
    updatedKeys: [],
  };

  function bodyObj() {
    const formFields = form.getFieldsValue();
    let ratesArr = [];
    let ratesObj = {};

    if (ratesGridApi()) {
      ratesGridApi().forEachNode(({ data }) => {
        ratesArr.push(data);
      });
      ratesObj = ratesArr.reduce(
        (acc, val) => ({
          ...acc,
          [val?.role]: category === "Prevailing Wage" ? Number(val?.rate) : 0,
        }),
        {}
      );
    }

    const addressPosition = projectConnected
      ? {
          lat: projectConnected?.projectLatitude,
          lng: projectConnected?.projectLongitude,
        }
      : addressSelected?.coordinates;

    return {
      ...formFields,
      rates: ratesObj,
      accountName: clients.find(
        ({ accountId }) => accountId === formFields?.accountName
      ),
      addressPosition,
      geoFenceInfo: geofenceInfo() || [],
      projectId: projectConnected?.projectId,
    };
  }

  async function onAddressSelect(event) {
    const projectMatch = projectOptions.find(
      ({ projectAddress }) => projectAddress === event
    );

    const getGeoButton = document.getElementById("getGeoButton");
    if (projectMatch) {
      const projectRadius = projectMatch?.geoFenceInfo?.find(
        ({ type }) => type === "Circle"
      )?.geoFenceInfo?.[0]?.circleRadius;

      form.setFieldValue("jobAddress", projectMatch?.projectAddress);
      form.setFieldValue("jobName", projectMatch?.projectAddress);
      form.setFieldValue("accountName", projectMatch?.accountId);
      form.setFieldValue(
        "locationRadius",
        parseFloat(projectRadius * 3.281) || 300
      );
      setGeofenceInfo(projectMatch?.geoFenceInfo);
      setProjectConnected(projectMatch);
      setAddressSelected(null);
      getGeoButton.disabled = false;
    } else {
      const locInfo = await getCoordinatesAndZip(event);
      form.setFieldValue("jobAddress", event);
      form.setFieldValue("jobName", event);
      form.setFieldValue("accountName", null);
      setAddressSelected(locInfo);
      setProjectConnected(false);
      setGeofenceInfo([]);
    }
  }

  const fieldsJSON = useMemo(() => {
    return jobsiteFields({
      clients,
      jobsites,
      accessRight,
      servicesList,
      onAddressSelect,
      projects: projectOptions,
    });
  }, [clients, jobsites, accessRight, servicesList, projectOptions]);

  function onSave() {
    setSaveButtonDisable(true);
    form
      .validateFields()
      .then(() => {
        if (Array.isArray(geofenceInfo()) && !!geofenceInfo()?.length) {
          message.warning("There is no Geo fence for this job site!");
        }
        message.loading("Saving...");
        if (rowToEdit?.jobsiteId) {
          API.put("jobsites", `/jobsites/${rowToEdit.jobsiteId}`, {
            body: bodyObj(),
          })
            .then(() => {
              if (refreshTable) {
                refreshTable({ ...rowToEdit, ...bodyObj() }, "edit");
              }
              const updatedJobsite = bodyObj();
              let currentObject = {};
              let previousObject = {};
              let updatedKeys = [];
              for (let key in updatedJobsite) {
                let result;
                if (
                  key.includes("Id") ||
                  key === "createdAt" ||
                  key === "geofenceInfo" ||
                  (key === "rates" && category !== "Prevailing Wage")
                ) {
                  continue;
                } else if (key === "accountName") {
                  let newAccountName = updatedJobsite?.[key]?.accountName;
                  let oldAccountName = rowToEdit?.[key]?.accountName;

                  result = getChangedData(newAccountName, oldAccountName);
                  if (result) {
                    Object.assign(currentObject, { [key]: result.curr });
                    Object.assign(previousObject, {
                      [key]: result.prev || "Does not exist",
                    });
                    updatedKeys.push(key);
                  }
                } else {
                  result = getChangedData(
                    updatedJobsite?.[key],
                    rowToEdit?.[key]
                  );
                  if (result) {
                    Object.assign(currentObject, { [key]: result.curr });
                    Object.assign(previousObject, {
                      [key]: result.prev || "Does not exist",
                    });
                    updatedKeys.push(key);
                  }
                }
              }

              newEditLog.currentData = currentObject;
              newEditLog.previousData = previousObject;
              newEditLog.updatedKeys = updatedKeys;

              broadcastNotification("112", "onJobsiteUpdate", [
                {
                  common: userConfiguration?.nameOfUser,
                },
                {
                  jobName: updatedJobsite?.jobName,
                  userName: userConfiguration?.nameOfUser,
                  currentUser: userConfiguration?.cognitoUserId,
                },
              ]);

              saveAddedLogs(newEditLog).then(() => {
                message.success(
                  `${rowToEdit.jobAddress} have been saves successfully!`
                );
                handleCancel();
              });
            })
            .catch((error) => {
              setSaveButtonDisable(false);
              message.error(
                `There was a problem editing ${rowToEdit?.jobAddress}`
              );
              console.log("Error: ", error);
            });
        } else {
          API.post("jobsites", "/jobsites", {
            body: bodyObj(),
          })
            .then((res) => {
              if (refreshTable) {
                refreshTable(res);
              }
              const results = getChangedData(res, {});
              newEditLog.currentData = results.curr;
              newEditLog.previousData = results.prev;
              newEditLog.actionType = "Create";

              broadcastNotification("112", "onJobsiteCreation", [
                {
                  common: userConfiguration?.nameOfUser,
                },
                {
                  jobName: res?.jobName,
                  userName: userConfiguration?.nameOfUser,
                  currentUser: userConfiguration?.cognitoUserId,
                },
              ]);

              saveAddedLogs(newEditLog).then(() => {
                message.destroy();
                message.success(
                  `${res.jobAddress} have been created successfully!`
                );
                handleCancel();
              });
            })
            .catch((err) => {
              message.destroy();
              setSaveButtonDisable(false);
              console.log("Error: ", err);
              message.error("Something went wrong on creating this jobsite!");
            });
        }
        // } else {
        //   // message.error(`Please draw a geo fence for ${addressSelected}!`);
        //   message.error(
        //     `Please draw a geo fence for ${form.getFieldValue("jobAddress")}!`
        //   );
        // }
      })
      .catch((err) => {
        console.log("error: ", err);
        setSaveButtonDisable(false);
      });
  }

  function onDelete() {
    API.del("jobsites", `/jobsites/${rowToEdit.jobsiteId}`)
      .then(() => {
        if (refreshTable) {
          refreshTable(rowToEdit, "delete");
        }
        let previousObject = {};
        for (let key in rowToEdit) {
          if (key.includes("Id") || key === "createdBy") {
            continue;
          } else if (key === "createdAt") {
            Object.assign(previousObject, {
              [key]: dayjs(rowToEdit?.[key]).format("MM/DD/YYYY hh:mm A"),
            });
          } else {
            Object.assign(previousObject, { [key]: rowToEdit?.[key] });
          }
        }
        const results = getChangedData({}, previousObject);
        newEditLog.currentData = results.curr;
        newEditLog.previousData = results.prev;
        newEditLog.actionType = "Delete";

        saveAddedLogs(newEditLog);

        broadcastNotification("112", "onJobsiteDelete", [
          {
            common: userConfiguration?.nameOfUser,
          },
          {
            jobName: rowToEdit?.jobName,
            userName: userConfiguration?.nameOfUser,
            currentUser: userConfiguration?.cognitoUserId,
          },
        ]);

        message.success(`${rowToEdit.jobAddress} deleted successfully!`);
        handleCancel();
      })
      .catch((error) => {
        message.error("There was a problem deleting this job site!");
        console.log("Error: ", error);
      });
  }

  function handleCancel() {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    onCancel();
  }

  useEffect(() => {
    message.loading({
      content: "Getting Data...",
      duration: 0,
      key: "fetchMessage",
    });
    Promise.all([
      lazyFetch({
        tableName: "accounts",
        listOfKeys: ["accountName", "accountId", "accountRecordType"],
        // filterKey: "accountRecordType",
        // filterValue: "Subcontractors",
      }),
      API.get("dynamicQuestions", "/dynamicQuestions"),
      // lazyFetch({
      //   tableName: "dynamicQuestions",
      //   listOfKeys: ["serviceName"],
      // }),
      lazyFetch({
        tableName: "crews",
        listOfKeys: ["crewId", "crewName", "crewPosition", "accountName"],
        filterKey: "crewStatus",
        filterValue: "Active",
      }),
      lazyFetch({ tableName: "jobsites", listOfKeys: ["projectId"] }),
    ])
      .then(([clients, services, crews, jobsites]) => {
        setClients(clients);
        setServicesList(services.filter((service) => !!service?.serviceName));
        setEmployees(crews);
        setConnectedJobsites(jobsites);
        message.success({
          content: "Data retrieved successfully!",
          key: "fetchMessage",
        });
      })
      .catch((err) => {
        console.log("Error getting promise data: ", err);
        message.error({
          content: "There was a problem getting data!",
          key: "fetchMessage",
        });
      });
    if (editMode) {
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "recordId",
                  value: rowToEdit?.jobsiteId,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      }).then((r) => {
        setEditLogs(r);
      });
      form.setFieldsValue(rowToEdit);
      if (typeof rowToEdit?.accountName === "string") {
        const account = clients.find(
          ({ accountName }) => accountName === rowToEdit?.accountName
        )?.accountId;
        form.setFieldValue("accountName", account);
      } else {
        form.setFieldValue("accountName", rowToEdit?.accountName?.accountId);
      }
      setGeofenceInfo(rowToEdit.geoFenceInfo || []);
      // setAddressSelected(rowToEdit.jobAddress);
      // setRadius(rowToEdit.locationRadius);
      // form.setFieldValue("locationRadius", rowToEdit.locationRadius);

      let tmpObj = {};
      if (rowToEdit["rates"]) {
        for (const key in rowToEdit["rates"]) {
          tmpObj[`RATE:${key}`] = rowToEdit["rates"][key];
        }
      }
      form.setFieldsValue(tmpObj);
    }
    const access = userConfiguration?.routeConfig
      ?.find(({ title }) => title === "Project Cost")
      ?.children?.find(({ title }) => title === "Jobsites");
    setAccessRight(access);

    const projectIndex =
      allProjects?.length &&
      allProjects.findIndex(
        ({ projectId }) => projectId === rowToEdit?.projectId
      );
    const projectMatch = allProjects?.length && allProjects[projectIndex];

    if (projectMatch) {
      if (!projectMatch?.projectLatitude) {
        getCoordinatesAndZip(projectMatch?.projectAddress).then((res) => {
          setProjectConnected({
            ...projectMatch,
            projectLatitude: res?.coordinates?.lat,
            projectLongitude: res?.coordinates?.lng,
          });
        });
      } else {
        setProjectConnected(projectMatch);
      }
      if (
        !!projectMatch?.geoFenceInfo?.[0]?.length &&
        JSON.stringify(rowToEdit.geoFenceInfo) ===
          JSON.stringify(projectMatch.geoFenceInfo)
      ) {
        const getGeoButton = document.getElementById("getGeoButton");
        if (getGeoButton) {
          getGeoButton.disabled = true;
        }
      }
    } else if (!rowToEdit?.addressPosition?.lat && !!rowToEdit?.jobAddress) {
      getCoordinatesAndZip(rowToEdit.jobAddress).then((res) => {
        setAddressSelected(res);
      });
    }
  }, [editMode, rowToEdit]);

  useEffect(() => {
    if (programFields?.length) {
      const tmpRoles = programFields.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        })
      )?.["Crew Position"];
      setRoles(tmpRoles);
    }
  }, [programFields]);

  return (
    <Modal
      {...{
        open,
        closable: true,
        centered: true,
        destroyOnClose: true,
        closeIcon: <XIcon />,
        ["data-testid"]: "new-jobsite-modal",
        title: rowToEdit?.jobsiteId ? "Edit Jobsite" : "New Jobsite",
        className: `jobsiteModal ${isDarkMode && "jobsiteModalDark"}`,
        onCancel: changes ? () => setOnCancelModal(true) : handleCancel,
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "New Jobsite" });
        },
        footer: footerButtons(
          changes ? () => setOnCancelModal(true) : handleCancel,
          onSave,
          () => setLogsModalVisible(true),
          () => setDeleteModal(true),
          !!rowToEdit?.jobsiteId,
          saveBtnDisable
        ),
      }}
    >
      <Form
        form={form}
        onFieldsChange={() => {
          setChanges(true);
        }}
      >
        {RenderDynamicComponents(fieldsJSON, { form })}
        <Form.Item name="reimbursement" className="reimbursement">
          <ReportCheck
            id="reimbursement"
            label={"Reimbursement"}
            checked={!!reimbursement}
            defaultChecked={!!reimbursement}
          />
        </Form.Item>
        <div
          className="labeledInputComponent"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MondayButton
            className={`mondayButtonBlue`}
            Icon={<DownloadIcon />}
            disabled={!projectConnected.projectId}
            id="getGeoButton"
            onClick={() => {
              const getGeoButton = document.getElementById("getGeoButton");
              setGeofenceInfo(projectConnected.geoFenceInfo);
              getGeoButton.disabled = true;
            }}
          >
            Get Project Geofence
          </MondayButton>
        </div>
        <a onClick={() => window.open(rowToEdit?.googleSheetLink)}>
          {rowToEdit?.googleSheetLink}
        </a>
        <div
          className="ratesTable"
          style={{
            ...(category !== "Prevailing Wage"
              ? { visibility: "hidden", height: 0, overflow: "hidden" }
              : {}),
          }}
        >
          <JobsiteRatesGrid
            clients={clients}
            ref={jobsiteGridRef}
            employees={employees}
            isDarkMode={isDarkMode}
            selectedClient={selectedClient}
            ratesForRole={rowToEdit?.rates || undefined}
          />
        </div>
        {!!projectConnected || !!addressSelected || !!rowToEdit ? (
          <PayrollActivityMap
            ref={modalMapRef}
            defaultMarker={
              projectConnected
                ? {
                    lat: projectConnected?.projectLatitude,
                    lng: projectConnected?.projectLongitude,
                  }
                : addressSelected
                ? addressSelected?.coordinates
                : rowToEdit?.addressPosition
            }
            defaultAddress={
              projectConnected
                ? projectConnected?.projectAddress
                : rowToEdit?.jobAddress
            }
            editGeofence={true}
            radius={Number(radius)}
          />
        ) : (
          <div className="modalInfo">Add Jobsite Address to proceed.</div>
        )}
      </Form>
      {deleteModal && (
        <WarningModal
          visible={deleteModal}
          setVisible={setDeleteModal}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          onKeyPress={(e) => onEnterPress(e)}
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>Do you want to delete {rowToEdit?.jobAddress}?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setDeleteModal(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={onDelete}
                Icon={<TickIcon width={17} height={17} />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {onCancelModal && (
        <WarningModal
          visible={onCancelModal}
          setVisible={setOnCancelModal}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          onKeyPress={(e) => onEnterPress(e)}
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>You have unsaved changes. Are you sure you want to cancel?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setOnCancelModal(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={handleCancel}
                Icon={<TickIcon width={17} height={17} />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {logsModalVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsModalVisible,
            setVisible: setLogsModalVisible,
            logsData: editLogs || [],
            title: "Jobsite Logs",
          }}
        />
      )}
    </Modal>
  );
}

export default JobsiteModal;
