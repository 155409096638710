import { v4 as uuid } from "uuid";

import { EMPTY_ROW } from "../utils/degParser";
import getNotificationForDates from "../utils/dateNotification";
import { MondayButton } from "../../../../../../../commonComponents";
import { DEG_TIME_FORMAT, DEG_DATE_FORMAT } from "../utils/cellFunctions";
import { findTeamForEmployee } from "../ShiftEditModal/editShiftModalData";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { TickIcon } from "../../../../../../Settings/settingsComponents/Roles/src";
import { getCoordinatesAndZip } from "../../../../../../../SidebarPages/Fleet/utils";
import { withinRadius } from "../../../../Activity/components/payrollActivityModalData";
import { DynamicAvatars } from "../../../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { parseInTz } from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

export function teamShiftFooter({ onSave, onCancel }) {
  return [
    <MondayButton
      Icon={<XIcon />}
      onClick={onCancel}
      className="mondayButtonRed"
    >
      Cancel
    </MondayButton>,
    <MondayButton onClick={onSave} Icon={<TickIcon />}>
      Create
    </MondayButton>,
  ];
}

export const teamShiftColumns = [
  {
    field: "crewForeman",
    headerName: "Foreman",
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    cellRenderer: ({ value }) => {
      return <div>{value?.crewName}</div>;
    },
  },
  {
    field: "crewTeamName",
    headerName: "Team Name",
  },
  {
    field: "company",
    headerName: "Company",
  },
  {
    field: "crewMembers",
    headerName: "Members",
    cellRenderer: ({ value, data }) => {
      return (
        <DynamicAvatars
          {...{
            members: value,
            idKey: "crewId",
            nameKey: "crewName",
            title: `${data?.crewTeamName} - Team`,
            crew: { ...data?.crewForeman, createdBy: data?.createdBy },
          }}
        />
      );
    },
  },
];

export function shiftDetailsFields({
  form,
  crews,
  launch,
  sowList,
  jobsites,
  setLaunch,
  toNextDay,
  setToNextDay,
  disabledHours,
  disabledMinutes,
  onJobsiteSelect,
}) {
  const SHIFT_STATUSES = ["Draft", "Pending", "Completed"];
  return [
    {
      type: "select",
      required: true,
      mode: "multiple",
      showSearch: true,
      optionFilterProp: "label",
      label: "Selected Employees",
      formItemName: "selectedMembers",
      placeholder: "Select members...",
      getPopUpContainer: document.body,
      customOptions: crews?.map(({ crewId, crewName, employeeId }) => ({
        value: crewId,
        label: `${crewName} (${employeeId})`,
      })),
    },
    {
      label: "Jobsite",
      type: "select",
      placeholder: "Select a Jobsite...",
      formItemName: "selectedJobsite",
      showSearch: true,
      optionFilterProp: "label",
      onSelect: onJobsiteSelect,
      customOptions: jobsites.map(({ jobsiteId, jobAddress }, key) => ({
        key,
        value: jobsiteId,
        label: jobAddress,
      })),
      required: true,
      getPopUpContainer: document.body,
    },
    {
      label: "SOW",
      placeholder: "Select services...",
      type: "select",
      mode: "multiple",
      getPopUpContainer: document.body,
      required: true,
      formItemName: "services",
      disabled: !sowList?.length,
      customOptions: sowList.map((opt, index) => ({
        key: index,
        value: opt,
        label: opt,
      })),
    },
    {
      label: "Shift Date",
      placeholder: "Select a Date...",
      type: "datepicker",
      getPopUpContainer: document.body,
      required: true,
      formItemName: "selectedDate",
      allowClear: false,
    },
    {
      label: "Shift Start Time",
      placeholder: "Select a clock in time...",
      type: "timepicker",
      getPopUpContainer: document.body,
      required: true,
      formItemName: "selectedTime",
      allowClear: false,
      onChange(e) {
        if (e.get("hour") > 12) {
          if (!toNextDay) {
            getNotificationForDates({
              clockInTime: e.format(DEG_TIME_FORMAT),
              acceptHandler: () => setToNextDay(true),
            });
          }
        } else {
          setToNextDay(false);
        }
        form.setFieldValue("selectedTime", parseInTz(e));
      },
      disabledHours() {
        if (toNextDay) {
          return [];
        } else {
          return disabledHours("SHIFT_START");
        }
      },
      disabledMinutes(hour) {
        if (toNextDay) {
          return [];
        } else {
          return disabledMinutes(hour, "SHIFT_START");
        }
      },
    },
    {
      label: "Shift End Time",
      placeholder: "Select a clock in time...",
      type: "timepicker",
      getPopUpContainer: document.body,
      required: true,
      formItemName: "selectedEndTime",
      allowClear: false,
      onChange(e) {
        form.setFieldValue("selectedEndTime", parseInTz(e));
      },
      disabledHours() {
        if (toNextDay) {
          return [];
        } else {
          return disabledHours("SHIFT_END");
        }
      },
      disabledMinutes(hour) {
        if (toNextDay) {
          return [];
        } else {
          return disabledMinutes(hour, "SHIFT_END");
        }
      },
    },
    {
      label: "Add Launch Time",
      type: "checkbox",
      onChange: (e) => setLaunch(e?.target?.checked),
    },
    launch && {
      label: "Launch Start Time",
      placeholder: "Select a launch in time...",
      type: "timepicker",
      getPopUpContainer: document.body,
      required: true,
      formItemName: "launchStartTime",
      allowClear: false,
      onChange(e) {
        form.setFieldValue("launchStartTime", parseInTz(e));
      },
      disabledHours() {
        if (toNextDay) {
          return [];
        } else {
          return disabledHours("LUNCH_START");
        }
      },
      disabledMinutes(hour) {
        if (toNextDay) {
          return [];
        } else {
          return disabledMinutes(hour, "LUNCH_START");
        }
      },
    },
    launch && {
      label: "Launch End Time",
      placeholder: "Select a launch in time...",
      type: "timepicker",
      getPopUpContainer: document.body,
      required: true,
      formItemName: "launchEndTime",
      allowClear: false,
      onChange(e) {
        form.setFieldValue("launchEndTime", parseInTz(e));
      },
      disabledHours() {
        if (toNextDay) {
          return [];
        } else {
          return disabledHours("LUNCH_END");
        }
      },
      disabledMinutes(hour) {
        if (toNextDay) {
          return [];
        } else {
          return disabledMinutes(hour, "LUNCH_END");
        }
      },
    },
    {
      label: "Status",
      placeholder: "Select status...",
      type: "select",
      getPopUpContainer: document.body,
      formItemName: "shiftStatus",
      customOptions: SHIFT_STATUSES.map((opt, key) => ({
        key,
        value: opt,
        label: opt,
      })),
    },
  ].filter(Boolean);
}

export const existingShiftsColumns = [
  {
    title: "Employee Full Name",
    dataIndex: "employeeFullName",
    key: "employeeFullName",
  },
  {
    title: "Employee Id",
    dataIndex: "employeeId",
    key: "employeeId",
  },
  {
    title: "Punch Date",
    dataIndex: "punchDate",
    key: "punchDate",
    render: (data) => dayjsNY(data).format(DEG_DATE_FORMAT),
  },
  {
    title: "Clock In",
    dataIndex: "firstClockIn",
    key: "firstClockIn",
    render: (data) => dayjsNY(data).format(DEG_TIME_FORMAT),
  },
  {
    title: "Clock Out",
    dataIndex: "firstClockIn",
    key: "firstClockIn",
    render: (text, record) => {
      return dayjsNY(text)
        .add(
          record?.workHours + record?.breakHours + record?.overtimeHours,
          "hour"
        )
        .format(DEG_TIME_FORMAT);
    },
  },
  {
    title: "Jobsite Match",
    dataIndex: "jobsiteMatch",
    key: "jobsiteMatch",
  },
];

export async function createShiftsForCrews({
  form,
  crews,
  launch,
  jobsites,
  crewTeams,
  // toNextDay,
}) {
  let newShifts = [];
  let selectedEmployeeIds = [];

  const {
    reason,
    duration,
    services,
    shiftType,
    shiftStatus,
    selectedDate,
    selectedTime,
    launchEndTime,
    distanceFromJob,
    selectedJobsite,
    selectedMembers,
    selectedEndTime,
    launchStartTime,
    employeeRate: formRate = undefined,
    punchCoordinates: initialPunchCoordinates,
  } = form?.hasOwnProperty("selectedJobsite") ? form : form?.getFieldsValue();

  const punchTypes = {
    ["Regular Shift"]: "ID",
    ["HR Shift"]: "HR",
  };

  let jobsiteMatch = {
    jobName: "",
    jobsiteId: "",
    jobAddress: "",
    services: [],
    reimbursement: false,
  };
  let geofence = "";
  let ratesForRole = {};
  let punchTime = undefined;
  let punchDate = undefined;
  let newDuration = duration;
  let payrollType = "Open Shop";
  let punchTimeStamp = undefined;
  let punchCoordinates = undefined;
  let newDistanceFromJob = distanceFromJob;

  if (selectedJobsite) {
    let job = jobsites?.find((site) => site.jobsiteId === selectedJobsite);
    if (job) {
      newDistanceFromJob = withinRadius(
        job?.addressPosition,
        initialPunchCoordinates?.lat
          ? initialPunchCoordinates
          : job?.addressPosition
      ).distanceInFeet;

      newDuration = Math.round(newDistanceFromJob / 3.5);

      punchCoordinates = initialPunchCoordinates?.lat
        ? initialPunchCoordinates
        : job?.addressPosition;
      payrollType = job?.payrollType;
      ratesForRole = job?.rates;
      geofence = job?.jobName;
      jobsiteMatch = {
        jobName: job?.jobName,
        services: job?.services,
        jobsiteId: job?.jobsiteId,
        jobAddress: job?.jobAddress,
        reimbursement: job?.reimbursement,
        googleSheetLink: job?.googleSheetLink,
      };
    } else {
      try {
        punchCoordinates = initialPunchCoordinates?.lat
          ? initialPunchCoordinates
          : await getCoordinatesAndZip(job?.jobAddress).then(
              ({ coordinates }) => coordinates
            );
      } catch (err) {
        throw new Error(err);
      }
    }
  }

  punchDate = dayjsNY(selectedDate);
  punchTime = selectedTime;

  punchTimeStamp = punchTime.valueOf();

  let punchOut = dayjsNY(selectedEndTime);
  let punchOutTimeStamp = punchOut.valueOf();

  let punchLaunchStart = undefined;
  let punchLaunchEnd = undefined;
  let launchStartTimeStamp = undefined;
  let launchEndTimeStamp = undefined;

  if (!!launch) {
    punchLaunchStart = dayjsNY(launchStartTime);
    punchLaunchEnd = dayjsNY(launchEndTime);
    launchStartTimeStamp = punchLaunchStart.valueOf();
    launchEndTimeStamp = punchLaunchEnd.valueOf();
  }

  for (const crewId of selectedMembers) {
    let crewObj = crews?.find((e) => e.crewId === crewId);
    if (crewObj) {
      const {
        crewId,
        crewName,
        employeeId,
        salaryType,
        accountName,
        employeeRate,
        crewPosition,
      } = crewObj;

      const crewTeam = findTeamForEmployee(crewTeams, crewId, employeeId);

      let tmpRate = employeeRate;
      if (!!ratesForRole?.[crewPosition]) {
        tmpRate = ratesForRole?.[crewPosition];
      }

      let newEntry = {
        ...EMPTY_ROW,
        entryId: uuid(),
        jobsiteId: jobsiteMatch?.jobsiteId,
        jobsiteMatch,
        punchLocation: jobsiteMatch?.jobAddress,
        payrollType,
        punchDate,
        punchTime,
        punchTimeStamp,
        punchCoordinates,
        employeeId,
        crewId,
        geofence,
        sow: services,
        salaryType: salaryType || "Hourly",
        activityStatus: shiftStatus || "Pending",
        employeeFullName: crewName,
        company: accountName,
        companyName: accountName,
        employeeRate: formRate ? formRate : Number(tmpRate),
        punchType: punchTypes?.[shiftType],
        employeeRole: crewPosition,
        crewTeamName: crewTeam?.crewTeamName,
        crewTeamId: crewTeam?.crewTeamId,
        reason: reason || "",
        duration: newDuration,
        distanceFromJob: newDistanceFromJob,
      };

      selectedEmployeeIds.push(employeeId);
      newShifts.push(newEntry);
      if (!!launch) {
        newShifts.push({
          ...newEntry,
          entryId: uuid(),
          punchTime: punchLaunchStart,
          punchTimeStamp: launchStartTimeStamp,
          punchType: "OL",
        });
        newShifts.push({
          ...newEntry,
          entryId: uuid(),
          punchTime: punchLaunchEnd,
          punchTimeStamp: launchEndTimeStamp,
          punchType: "IL",
        });
      }
      if (shiftType === "Regular Shift") {
        newShifts.push({
          ...newEntry,
          entryId: uuid(),
          punchTime: punchOut,
          punchTimeStamp: punchOutTimeStamp,
          punchType: "OD",
        });
      }
    }
  }

  return {
    newShifts,
    selectedEmployeeIds: selectedEmployeeIds.filter(Boolean),
  };
}
