import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../../../Settings/settingsComponents/Roles/src";

export function getSelectionFooter({
  onSave = () => {},
  onCancel = () => {},
  onClearSelection,
}) {
  return [
    <MondayButton
      {...{
        Icon: <XIcon />,
        onClick: onCancel,
        className: "mondayButtonRed",
        ["data-testid"]: "close-entries-selection-modal-btn",
      }}
    >
      Cancel
    </MondayButton>,
    <MondayButton
      {...{
        Icon: <XIcon />,
        onClick: onClearSelection,
        className: "mondayButtonBlue",
      }}
    >
      Clear
    </MondayButton>,
    <MondayButton
      {...{
        onClick: onSave,
        Icon: <TickIcon width={17} height={17} />,
      }}
    >
      Select
    </MondayButton>,
  ];
}

export function selectionFields({
  crews,
  jobsites,
  currentStep,
  handleChange,
}) {
  return [
    {
      required: true,
      allowClear: false,
      type: "datePicker",
      formItemName: "dateRange",
      label: currentStep === 1 ? "Entry Date" : "Shift Date",
    },
    {
      type: "select",
      required: false,
      label: "Jobsite",
      allowClear: true,
      showSearch: true,
      formItemName: "selectedJobsite",
      placeholder: "Select a jobsite...",
      customOptions: jobsites?.map(({ jobsiteId, jobAddress }) => ({
        value: jobsiteId,
        label: jobAddress,
      })),
    },
    {
      required: false,
      showSearch: true,
      label: "Employees",
      mode: "multiselect",
      onChange: handleChange,
      type: "styledmultiselect",
      optionFilterProp: "label",
      formItemName: "selectedEmployees",
      placeholder: "Select employees...",
      customOptions: crews?.map(({ crewName, employeeId }, key) => ({
        key,
        value: employeeId,
        label: `${crewName} (${employeeId})`,
      })),
    },
  ];
}
