import { groupBy } from "lodash";
import PropTypes from "prop-types";
import { Modal, Form, message } from "antd";
import React, { useState, useContext, useRef, useMemo } from "react";

import DegModalContext from "../DegModalContext";
import { shiftFields } from "./SplitShiftModalData";
import { InfoIcon } from "../../../../../../../../assets";
import {
  XIcon,
  TrashIcon,
} from "../../../../../../../SidebarPages/Communication/assets";
import {
  filterTables,
  updateDocumentTitle,
} from "../../../../../../../../utils";
import { useEntriesApi } from "../../../../../../PayrollLive/utils";
import { MondayButton } from "../../../../../../../commonComponents";
import { DEG_DATE_FORMAT, DEG_TIME_FORMAT } from "../utils/cellFunctions";
import { createShiftsForCrews } from "../TeamShiftsModal/teamShiftModalData";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { TickIcon } from "../../../../../../Settings/settingsComponents/Roles/src";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { RenderDynamicComponents } from "../../../../../../../Header/forms/Components";
import { formatDuration } from "../../../../../../../SidebarPages/Fleet/fleetsLive/utils";

import "./SplitShiftModal.scss";

/**
 *
 * @typedef field
 * @property {number} key
 * @property {number} fieldKey
 * @property {number|string} name
 * @property {boolean} isListField
 */

function SplitShiftModal({ open, gridApi, onCancel }) {
  const [form] = Form.useForm();
  const { loading, postEntries, removeEntries } = useEntriesApi();

  const shifts = useMemo(() => {
    return gridApi?.getSelectedNodes().map(({ data }) => data);
  }, [gridApi]);

  const shift = useMemo(() => {
    return shifts[0];
  }, [shifts]);

  const [launch, setLaunch] = useState([]);
  const [sowList, setSowList] = useState({});
  const [saveDisabled, setSaveDisabled] = useState(false);

  const indexRef = useRef(0);
  const {
    crews,
    rowData,
    jobsites,
    crewTeams,
    rowToEdit,
    // degGridApi,
    setRowData,
    isDarkMode,
    // entriesActions,
    // addEntryAction,
    jobsiteServices,
    scheduleMatching,
    allServiceOptions,
    addActionToRegister,
    setScheduleMatching,
    defaultServicesPerProject,
  } = useContext(DegModalContext);

  const shiftStatus = Form.useWatch("shiftStatus", form);

  const startShiftDate = useMemo(() => {
    return shifts?.length > 1
      ? dayjsNY(
          shifts.find(
            ({ firstClockIn }) =>
              firstClockIn ===
              Math.min(...shifts.map(({ firstClockIn }) => firstClockIn))
          )?.firstClockIn
        )
      : dayjsNY(shift?.firstClockIn);
  }, [shift]);

  const endShiftDate = useMemo(() => {
    return shifts?.length > 1
      ? dayjsNY(
          shifts.find(
            ({ clockOut }) =>
              clockOut === Math.max(...shifts.map(({ clockOut }) => clockOut))
          )?.clockOut
        )
      : dayjsNY(shift?.clockOut);
  }, [shift]);

  const shiftEntries = useMemo(() => {
    if (!shift) {
      return {};
    }

    const tmpEntries = rowData.filter((entry) =>
      (shift?.entries || []).includes(entry.entryId)
    );
    const separateEntries = groupBy(tmpEntries, ({ punchType }) => punchType);

    return separateEntries;
  }, [shift]);

  /**
   * Updates the job site and Scope of work list in the specific form element
   *
   * @param {string} id Selected job site ID
   * @param {number} index Index of form element where the job site will be updated in Form.List
   */
  function onJobsiteSelect(id, index) {
    const servicesInTimeStamp = jobsiteServices?.[id]?.find?.(
      ({ dateRange }) =>
        dateRange?.[0] <= shift?.firstClockIn &&
        shift?.firstClockIn <= dateRange?.[1]
    )?.scheduleServices;

    const newSowList = {
      ...sowList,
      [`services${index}`]:
        servicesInTimeStamp || defaultServicesPerProject?.[id] || [],
      // ? selectedJobsite?.services
      // : [],
    };

    if (!scheduleMatching?.[id]) {
      const jobIndex = jobsites.findIndex((job) => job.jobsiteId === id);
      if (jobsites?.[jobIndex]?.projectId) {
        filterTables("scheduling", "projectId", jobsites?.[jobIndex]?.projectId)
          .then((res) => {
            if (res?.length) {
              setScheduleMatching((prev) => ({ ...prev, [id]: res[0] }));
            }
          })
          .catch((error) =>
            console.log(
              "Error getting schedule match for this jobsite: ",
              error
            )
          );
      }
    }

    setSowList((prev) => ({ ...prev, ...newSowList }));
    updateFormValue(`services${index}`, index, []);
    updateFormValue(`selectedJobsite`, index, id);
  }

  /**
   * Shows all Scope of work options
   *
   * @param {number} index Index of form element in Form.List
   */
  function showAllServices({ index }) {
    const newSowList = {
      ...sowList,
      [`services${index}`]: allServiceOptions.map(
        ({ serviceName }) => serviceName
      ),
    };

    setSowList((prev) => ({ ...prev, ...newSowList }));
  }

  /**
   * Removes break time from the form element on index of Form.List
   * @param {number} index
   */
  function onRemoveBreak({ index }) {
    updateFormValue("launchStartTime", index, null);
    updateFormValue("launchEndTime", index, null);
    setLaunch(launch.filter((cardIndex) => cardIndex !== index));

    // Recalculate for time conflicts when removing break time from Form.List
    getTimeConflict({ index, formData: form.getFieldValue("shifts") });
  }

  /**
   * Function that checks for time conflicts for each element in Form.List
   *
   * @param {number} index of shift object
   * @param {Array} formData Form.List values containing each form element data
   * @param {boolean} startTimeLunchValidation When Shift Start Time is inside lunch time range
   * @param {boolean} endTimeLunchValidation When Shift End Time is inside lunch time range
   * @param {boolean} negativeLunchHours When Lunch Hours are 0 or a negative value
   * @param {boolean} negativeShiftHours When Shift Hours are 0 or a negative value
   *
   */
  function getTimeConflict({ index, formData }) {
    const formObj = { ...formData[index] };
    const { launchStartTime, launchEndTime, selectedTime, selectedEndTime } =
      formObj;
    const timeStampStart = selectedTime?.valueOf();
    const timeStampEnd = selectedEndTime?.valueOf();
    const timeStampLunchStart = launchStartTime?.valueOf();
    const timeStampLunchEnd = launchEndTime?.valueOf();

    const startTimeLunchValidation =
      timeStampStart >= timeStampLunchStart ||
      timeStampStart >= timeStampLunchEnd;

    const endTimeLunchValidation =
      timeStampEnd <= timeStampLunchStart || timeStampEnd <= timeStampLunchEnd;

    const negativeLunchHours = timeStampLunchStart >= timeStampLunchEnd;
    const negativeShiftHours = timeStampStart >= timeStampEnd;

    if (startTimeLunchValidation || endTimeLunchValidation) {
      message.error({
        content: "Lunch time must be inside shift Time!",
        duration: 2,
        key: "splitWarning",
      });
    }

    if (negativeLunchHours) {
      message.error({
        content: "Negative lunch hours are not allowed!",
        duration: 2,
        key: "splitLunchWarning",
      });
    }

    if (negativeShiftHours) {
      message.error({
        content: "Negative shift hours are not allowed!",
        duration: 2,
        key: "splitShiftWarning",
      });
    }

    if (
      startTimeLunchValidation ||
      endTimeLunchValidation ||
      negativeLunchHours ||
      negativeShiftHours
    ) {
      setSaveDisabled(index + 1);
    } else {
      setSaveDisabled((prev) => {
        if (prev === index + 1) {
          return false;
        } else {
          return prev;
        }
      });
    }
  }

  /**
   *Updates the field of a specific form element in Form.List
   *
   * @param {string} key formFieldName
   * @param {number} index in form list
   * @param {any} value to pass in field
   * @param {boolean} completeObject adds all keys with value null in adding new shift
   */
  function updateFormValue(key, index, value, completeObject) {
    const data = form.getFieldValue("shifts");
    data[index] = {
      ...data[index],
      [`${key}`]: value,
    };
    if (completeObject) {
      const missingFields = [
        "services",
        "selectedTime",
        "launchEndTime",
        "selectedJobsite",
        "selectedEndTime",
        "launchStartTime",
      ].filter((field) => field !== key);
      missingFields.forEach((field) => {
        if (field === "services") {
          Object.assign(data[index], {
            [`${field}${index}`]: [],
          });
        } else {
          Object.assign(data[index], {
            [`${field}`]: null,
          });
        }
      });
    }
    form.setFieldValue("shifts", data);
  }

  /**
   * This function adds a form element in Form.List.
   * On the first time of calling this function it will add 2 form elements where the shift start time will go to the first form element and
   * shift end time to the second form element.
   * Each other add after the first one will redirect the shift end time to the last form element.
   *
   * @param {function} add Adds a Form.List element
   * @param {field[]} fields List of actual fields in Form.List
   */
  function addShift(add, fields) {
    if (fields?.length === 0) {
      add();
      add();
      indexRef.current = 1;
      const shiftClockIn = dayjsNY(shift?.firstClockIn);
      updateFormValue("selectedTime", 0, shiftClockIn, true);
      updateFormValue(
        "selectedEndTime",
        1,
        dayjsNY(shifts[shifts.length - 1]?.clockOut),
        true
      );
      updateFormValue(`selectedJobsite`, 0, shift?.jobsiteMatch?.jobsiteId);

      if (shift?.inJobsiteId) {
        onJobsiteSelect(shift?.inJobsiteId, 0);
      }
      if (shift?.outJobsiteId) {
        onJobsiteSelect(shift?.outJobsiteId, 1);
      }
      if (!shift?.inJobsiteId && !shift.outJobsiteId) {
        onJobsiteSelect(shift?.jobsiteMatch?.jobsiteId, 0);
      }
      if (
        shiftEntries?.IL?.[0]?.punchTimeStamp &&
        shiftEntries?.OL?.[0]?.punchTimeStamp
      ) {
        const shiftDate = shiftClockIn.get("D");
        const lunchStartDate = dayjsNY(
          shiftEntries?.OL?.[0]?.punchTimeStamp
        ).get("D");
        const lunchEndDate = dayjsNY(shiftEntries?.IL?.[0]?.punchTimeStamp).get(
          "D"
        );

        setLaunch((prev) => prev.concat(0));
        if (shiftDate !== lunchStartDate || shiftDate !== lunchEndDate) {
          setLaunch((prev) => prev.concat(1));
        }

        updateFormValue(
          `launchStartTime`,
          shiftDate < lunchStartDate ? 1 : 0, // if lunchStartDate has a bigger date than shiftDate than set field to next split
          dayjsNY(shiftEntries?.OL?.[0]?.punchTimeStamp)
        );

        updateFormValue(
          `launchEndTime`,
          shiftDate < lunchEndDate ? 1 : 0, // if lunchEndDate has a bigger date than shiftDate than set field to next split
          dayjsNY(shiftEntries?.IL?.[0]?.punchTimeStamp)
        );
      }
      updateFormValue(`services${0}`, 0, shift?.sow);
      updateFormValue(`services${1}`, 1, shift?.sow);
    } else {
      add();
      indexRef.current += 1;
      const index = indexRef.current;
      const previousFieldEndTime =
        form.getFieldValue("shifts")?.[index - 1]?.[`selectedEndTime`];
      if (
        previousFieldEndTime?.format(DEG_TIME_FORMAT) !==
        dayjsNY(shifts[shifts.length - 1].clockOut)?.format(DEG_TIME_FORMAT)
      ) {
        updateFormValue(
          "selectedTime",
          index,
          previousFieldEndTime.add(1, "m"),
          true
        );
        updateFormValue(
          "selectedEndTime",
          index,
          dayjsNY(shifts[shifts.length - 1]?.clockOut)
        );
      } else if (
        previousFieldEndTime?.format(DEG_TIME_FORMAT) ===
        dayjsNY(shifts[shifts.length - 1].clockOut)?.format(DEG_TIME_FORMAT)
      ) {
        updateFormValue("selectedEndTime", index - 1, null, true);
        updateFormValue(
          "selectedEndTime",
          index,
          dayjsNY(shifts[shifts.length - 1]?.clockOut)
        );
      }
      if (fields.length >= 3) {
        setTimeout(() => {
          form.scrollToField("lastField", { behavior: "smooth" });
        }, 200);
      }
    }
  }

  /**
   * This function removes a shift from the Form.List
   *
   * @param {function} remove  Function from Form.List that removes a form element from Form.List
   * @param {number} index  Index of form in Form.List
   * @param {field}  field - Selected field that will be removed
   *
   * @param {function} updateFormValue - Is used to update the selectedEndTime to be set in the last element of Form.List
   */
  function removeShift(remove, field, index) {
    const formCardToRemove = document.getElementById(`shiftFormCard${index}`);
    const shifts = form.getFieldValue("shifts");

    if (shifts?.length - 1 === index) {
      updateFormValue(
        "selectedEndTime",
        index - 1,
        shifts[index]?.selectedEndTime
      );
    }
    formCardToRemove.classList.add("removeShiftAnimation");
    setTimeout(() => {
      remove(field?.name);
      indexRef.current -= 1;
    }, 500);
  }

  function handleCancel() {
    onCancel();
    updateDocumentTitle(); // reset document title to "Lead Manager";
  }

  /**
   * Removes all entries that create the selected shift to split than
   * creates entries for each form element in Form.List
   */
  async function onSaveShifts() {
    const employeeNotFound = !shifts.some((shift) => {
      return crews.some(({ crewId, employeeId }) => {
        let employeeIdSplit = employeeId.split("-");
        let modifiedEmployeeId = `${employeeIdSplit[0]}-${parseFloat(
          employeeIdSplit[1]
        )}`;

        const employeeMatch =
          shift.crewId === crewId ||
          shift.employeeId === employeeId ||
          shift.employeeId === modifiedEmployeeId;

        return employeeMatch;
      });
    });

    if (employeeNotFound) {
      setSaveDisabled(true);
      message.error("This employee was not found!");
      return;
    }
    let newShifts = [];
    const formList = await form.validateFields();

    // .then(async (formList) => {
    for (const shiftData of shifts) {
      let newEntries = [];
      // let launchTimeTotal = 0;
      let rowsToRemove = [];

      rowData.forEach((data) => {
        if ((shiftData?.entries || [])?.includes(data?.entryId)) {
          rowsToRemove.push(data);
        }
      });

      // formList?.shifts?.forEach((formData) => {
      //   const selectedDate = dayjsNY(formData.selectedTime);

      //   if (formData?.launchStartTime && formData?.launchEndTime) {
      //     let startLaunch = formData?.launchStartTime?.valueOf();
      //     let endLaunch = formData?.launchEndTime?.valueOf();

      //     if (
      //       formData.launchStartTime?.valueOf() >
      //       formData?.launchEndTime.valueOf()
      //     ) {
      //       endLaunch = setDateMonth(
      //         formData.launchEndTime,
      //         selectedDate.add(1, "day")
      //       ).valueOf();
      //     }
      //     const launchTime = parseFloat(
      //       (findTimeDiff(startLaunch, endLaunch, "minute") / 60).toFixed(1)
      //     );
      //     launchTimeTotal += launchTime;
      //   }
      // });

      for (const formData of formList.shifts) {
        const selectedDate = dayjsNY(formData.selectedTime);
        const member = crews.find(({ crewId, employeeId }) => {
          let employeeIdSplit = employeeId.split("-");
          let modifiedEmployeeId = `${employeeIdSplit[0]}-${parseFloat(
            employeeIdSplit[1]
          )}`;

          const employeeMatch =
            shiftData.crewId === crewId ||
            shiftData.employeeId === employeeId ||
            shiftData.employeeId === modifiedEmployeeId;

          return employeeMatch;
        });

        const newFormData = Object.assign(formData, {
          selectedDate,
          shiftType: "Regular Shift",
          salaryType: member?.salaryType,
          selectedMembers: [member?.crewId],
          shiftStatus: shiftStatus || "Pending",
          services:
            formData?.[
              Object.keys(formData).find((key) => key.includes("services"))
            ],
        });

        newShifts = newShifts.concat(newFormData);
        await createShiftsForCrews({
          crews,
          jobsites,
          crewTeams,
          form: newFormData,
          launch: newFormData?.launchStartTime,
        }).then(({ newShifts }) => {
          const tmpNewShifts = newShifts.map((entry) => {
            const scheduleMatch = scheduleMatching?.[entry?.jobsiteId];
            if (scheduleMatch) {
              const scheduleDay = (scheduleMatch?.scheduleDays || []).find(
                (day) =>
                  dayjsNY(day?.startData).format(DEG_DATE_FORMAT) ===
                  dayjsNY(entry?.punchDate).format(DEG_DATE_FORMAT)
              );

              return {
                ...entry,
                degId: rowToEdit?.degId,
                scheduleDayId: scheduleDay?.id,
                scheduleId: scheduleMatch?.scheduleId,
                scheduleAddress: scheduleMatch?.scheduleAddress,
              };
            } else {
              return { ...entry, degId: rowToEdit?.degId };
            }
          });
          newEntries = newEntries.concat(tmpNewShifts);
        });
      }

      const idToRemove = rowsToRemove.map(({ entryId }) => entryId);

      message.loading({
        duration: 0,
        key: "splitShift",
        content: "Splitting Shift...",
      });

      Promise.allSettled([
        postEntries({ entries: newEntries }),
        removeEntries({ entries: rowsToRemove }),
      ])
        .then(() => {
          message.success({
            duration: 3,
            key: "splitShift",
            content: "Shift splitted successfully!",
          });
          gridApi.applyTransaction({
            remove: [shiftData],
          });
          addActionToRegister({
            type: "massChanges",
            massChangeActions: {
              editedEntries: {},
              newEntries: newEntries,
              removedEntries: rowsToRemove,
            },
          });
          setRowData(
            newEntries.concat(
              rowData.filter(
                ({ entryId }) => !(idToRemove || []).includes(entryId)
              )
            )
          );
        })
        .catch((err) => {
          console.log("Error splitting shifts: ", err);
          message.error({
            duration: 3,
            key: "splitShift",
            content: "There was a problem splitting this shift",
          });
          return;
        });
    }
    handleCancel();
  }

  return (
    <Modal
      centered
      open={open}
      closable={true}
      title="Split Shift"
      closeIcon={<XIcon />}
      onCancel={handleCancel}
      data-testid="split-shift-modal"
      className={`splitShiftModal ${isDarkMode && "splitShiftModalDark"}`}
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Split Shift" });
      }}
      footer={[
        <div key={"footerContainer"} className="footerContainer">
          <MondayButton
            Icon={<XIcon />}
            key={"close-btn"}
            disabled={loading}
            onClick={handleCancel}
            className="mondayButtonRed"
            data-testid="close-split-shift-modal-btn"
          >
            Cancel
          </MondayButton>
          <MondayButton
            key={"save-btn"}
            onClick={onSaveShifts}
            disabled={saveDisabled || loading}
            Icon={<TickIcon width={18} height={18} />}
          >
            Save
          </MondayButton>
        </div>,
      ]}
    >
      <div className="informationText">
        <InfoIcon /> Here you can split{" "}
        {`${shift?.employeeFullName || "This Employee"}'s shift.`}
      </div>
      <div className="shiftToSplit">
        <div className="shiftData">
          <label className="shiftLabel">Employee:</label>
          <div className="data" data-testid="data-employee-name">
            {shift?.employeeFullName}
          </div>
        </div>

        <div className="shiftData">
          <label className="shiftLabel">Date:</label>
          <div className="data" data-testid="data-shift-date">
            {dayjsNY(shift?.firstClockIn).format(DEG_DATE_FORMAT)}
          </div>
        </div>

        <div className="shiftData">
          <label className="shiftLabel">Clock In:</label>
          <div className="data" data-testid="data-shift-clockIn">
            {dayjsNY(shift?.firstClockIn).format(DEG_TIME_FORMAT)}
          </div>
        </div>

        <div className="shiftData">
          <label className="shiftLabel">Clock Out:</label>
          <div className="data" data-testid="data-shift-clockOut">
            {dayjsNY(shift?.clockOut).format(DEG_TIME_FORMAT)}
          </div>
        </div>

        <div className="shiftData">
          <label className="shiftLabel">Break Hours:</label>
          <div className="data" data-testid="data-shift-break">
            {formatDuration(shift?.breakHours, "hour").text}
          </div>
        </div>

        <div className="shiftData">
          <label className="shiftLabel">Shifts Status:</label>
          <Form
            form={form}
            className="data"
            initialValues={{ shiftStatus: "Pending" }}
          >
            <InputComponent
              form={form}
              type="select"
              formItemName={"shiftStatus"}
              dataTestid="data-shift-status"
              customOptions={[
                { key: 0, label: "Draft", value: "Draft" },
                { key: 1, label: "Pending", value: "Pending" },
                { key: 2, label: "Completed", value: "Completed" },
              ]}
              placeholder="Select status..."
              dropdownClassName={isDarkMode && "darkDropDown"}
            />
          </Form>
        </div>

        <div className="shiftData">
          <label className="shiftLabel">Jobsite:</label>
          <div className="data" data-testid="data-shift-jobsite">
            {shift?.jobsiteMatch?.jobName}
          </div>
        </div>
      </div>
      <Form form={form} className="formCardsContainer">
        <Form.List
          name="shifts"
          data-testid="shift-splits-container"
          list={[
            "selectedTime",
            "selectedEndTime",
            "launchStartTime",
            "launchEndTime",
          ]}
        >
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <div
                      className={`shiftFormCard`}
                      id={`shiftFormCard${index}`}
                      key={`${field.key}-container`}
                      data-testid={`shift-form-card`}
                    >
                      <div key={field?.key} className="fieldWrapper">
                        <div className="jobsiteContainer">
                          {RenderDynamicComponents(
                            shiftFields({
                              form,
                              index,
                              shifts,
                              launch,
                              sowList,
                              jobsites,
                              endShiftDate,
                              startShiftDate,
                              showAllServices,
                              onJobsiteSelect,
                              updateFormValue,
                              getTimeConflict,
                            }).slice(0, 2),
                            form
                          )}
                        </div>
                        <div
                          className={`timeContainer ${
                            !launch.includes(index) && "timeContainerTwoSplits"
                          }`}
                        >
                          {RenderDynamicComponents(
                            shiftFields({
                              form,
                              index,
                              shifts,
                              launch,
                              sowList,
                              jobsites,
                              endShiftDate,
                              startShiftDate,
                              onJobsiteSelect,
                              updateFormValue,
                              getTimeConflict,
                              showAllServices,
                            }).slice(2),
                            form
                          )}
                        </div>
                      </div>
                      <div className="cardActions">
                        {!launch.includes(index) ? (
                          <MondayButton
                            onClick={() => {
                              if (launch?.length) {
                                setLaunch(launch.concat(index));
                              } else {
                                setLaunch(launch.concat(index));
                                updateFormValue(
                                  `launchStartTime`,
                                  index,
                                  dayjsNY(shiftEntries?.OL?.[0]?.punchTimeStamp)
                                );

                                updateFormValue(
                                  `launchEndTime`,
                                  index,
                                  dayjsNY(shiftEntries?.IL?.[0]?.punchTimeStamp)
                                );

                                getTimeConflict({
                                  index,
                                  formData: form.getFieldValue("shifts"),
                                });
                              }
                            }}
                            className="mondayButtonBlue"
                            data-testid={`add-break-btn-${index}`}
                            Icon={<XIcon style={{ rotate: "45deg" }} />}
                            disabled={
                              shifts.length === 1 && shift?.breakHours === 0
                            }
                          >
                            Add Break
                          </MondayButton>
                        ) : (
                          <MondayButton
                            className="mondayButtonBlue"
                            onClick={() => onRemoveBreak({ index })}
                            data-testid={`remove-break-btn-${index}`}
                            Icon={<XIcon />}
                          >
                            Remove Break
                          </MondayButton>
                        )}
                        <MondayButton
                          className="mondayButtonRed"
                          data-testid={`remove-shift-btn-${index}`}
                          onClick={() => removeShift(remove, field, index)}
                          Icon={<TrashIcon />}
                        >
                          Remove
                        </MondayButton>
                      </div>
                    </div>
                  );
                })}
                <div>
                  <MondayButton
                    data-testid={`add-shift-btn`}
                    onClick={() => addShift(add, fields)}
                    className="addShiftButton mondayButtonBlue"
                    Icon={<XIcon style={{ rotate: "45deg" }} />}
                  >
                    Add Shift
                  </MondayButton>
                </div>
              </>
            );
          }}
        </Form.List>
        <Form.Item name="lastField" fieldId="lastField">
          <div></div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

SplitShiftModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  gridApi: {
    getSelectedNodes: PropTypes.func,
    applyTransaction: PropTypes.func,
  },
};

export default SplitShiftModal;
