import { forwardRef, useState } from "react";
import { Button, Divider, Input, Select, Space } from "antd";

import useCellEditor from "../../../../../hooks/useCellEditor";
import "./event-cell-editor.css";
import { PlusOutlined } from "@ant-design/icons";

const EventCellEditor = forwardRef(({ api, value, events }, ref) => {
  const [selectedEvent, setSelectedEvent] = useState(value);
  const [name, setName] = useState(null);
  const alreadyEnteredEvents = api
    .getModel()
    .rowsToDisplay?.map((row) => row.data.name);
  const availableEvents = Array.isArray(events)
    ? events?.filter((event) => alreadyEnteredEvents.indexOf(event) < 0)
    : [];

  const handleAdd = (whatToAdd) => {};

  const inputRef = useCellEditor(ref, selectedEvent);

  return (
    <Select
      ref={inputRef}
      showSearch
      className="event-cell-editor"
      style={{ width: "300px" }}
      placeholder="Select event"
      value={selectedEvent}
      onChange={(value) => setSelectedEvent(value)}
      dropdownRender={(menu) => (
        <>
          {menu}
          {name && <Select.Option value={name}>{name}</Select.Option>}
          <Divider style={{ margin: "8px 0" }} />
          <Space style={{ padding: "0 8px 4px" }}>
            <Input
              placeholder="Please enter item"
              // ref={inputRef}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={() => {
                if (name) {
                  setSelectedEvent(name);
                  setName(null);
                }
              }}
            >
              Add item
            </Button>
          </Space>
        </>
      )}
      options={
        !value // If we dont have value, happens only when adding new event
          ? !!availableEvents.length &&
            availableEvents?.map((event, idx) => {
              return {
                label: event,
                value: event,
                key: `available-${event}-${idx}`,
              };
            }) // Show only available options
          : events.map((event, idx) => {
              return {
                label: event,
                value: event,
                key: `available-${event}-${idx}`,
              };
            }) // Otherwise show all options in case user wants to change
      }
    />
  );
});

export default EventCellEditor;
