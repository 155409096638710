import { useState, useEffect, useContext } from "react";
import { Modal, Form, Input, Select, Divider, message } from "antd";

import { isLoggedIn } from "../../googleClientAPI/authenticate";
import {
  checkUserEventPreferences,
  executeCalendarList,
  executeEventAdder,
} from "../../googleClientAPI/execute";
import { InfoIcon } from "../../assets/index";
import RichTextBox from "./RichTextBox";
import TimeDate from "./TimeDate";
import { compareStartEndRange } from "../../utils/validations";
import ParticipantsInput from "./ParticipantsInput";
import NewCalendar from "./NewCalendar";
import { DropDownArrow } from "../../../../../SidebarPages/Fleet/components/InputComponent/assets";
import { updateDocumentTitle } from "../../../../../../utils";
import {
  GPlacesAutocompleteInput,
  SimplePlacesInput,
} from "../../../../../SidebarPages/Fleet/components";
import "./AddEventModal.scss";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { ReportCheck } from "../../../../../SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";
import CustomModalHeader from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import insertEventTemp from "./insertEvent.json";
import { EventContext } from "../previewEvent/EventsDrawer";

function AddEventModal({ handleModalVisible, openEventModal }) {
  const { calendars, setCalendars, handleSelect } = useContext(EventContext);
  console.log("AddEventModal ~ calendars:", { calendars });
  const preferences = checkUserEventPreferences() || null;
  const [form] = Form.useForm();
  const user = calendars?.find((el) => el?.primary)?.id || null;

  const [tags, setTags] = useState([]);
  const [calendar, setCalendar] = useState(null);
  const [refreshCalendarList, setRefreshCalendarList] = useState(false);
  const [isoTime, setIsoTime] = useState({ start: "", end: "" });
  const [createdRichText, setCreatedRichText] = useState("");

  const selectedTags = (tags) => {
    const participants = [];
    tags.map((tag) => participants.push({ email: tag }));
    setTags(participants);
    form.setFieldsValue({ participants: participants });
  };

  const filledAddress = (filledAdd) => {
    form.setFieldsValue({
      location: filledAdd,
    });
  };
  const processTimeDate = (timeDateObj) => {
    if (timeDateObj.startFull !== "" && timeDateObj.endFull !== "") {
      if (compareStartEndRange(timeDateObj) !== false) {
        try {
          const fullTime = {
            start: timeDateObj.startFull,
            end: timeDateObj.endFull,
          };
          setIsoTime(fullTime);
          form.setFieldsValue({
            fullTime,
          });
        } catch (e) {
          console.error(
            "there was an error with retrieving the ISO time format",
            e
          );
        }
      } else {
        form.setFieldsValue({
          fullTime: null,
        });
      }
    } else {
      form.setFieldsValue({
        fullTime: null,
      });
    }
  };

  // get new calendar list when is created ew calendar or modal is just opened
  useEffect(() => {
    try {
      if (refreshCalendarList || !calendars) {
        executeCalendarList()
          .then((val) => {
            setCalendars(val.result.items);
          })
          .then(
            () => {},
            () => console.log("Connection to Google account failed")
          )
          .then(() => setRefreshCalendarList(false));
      }
    } catch {
      console.log("There was an error connecting to Google account.");
    }
  }, [refreshCalendarList]);

  //reset states and form when add modal is closed
  const handleCancel = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager"
    form.resetFields();
    handleModalVisible(false);
  };

  //when form is submited
  const onFinish = (createdEvent) => {
    const eventBody = {
      ...insertEventTemp,
      summary: createdEvent.summary,
      description: createdRichText,
      location: createdEvent.location,
      start: {
        dateTime: isoTime.start,
        timeZone: preferences ? preferences.timezone : "America/New_York",
      },
      end: {
        dateTime: isoTime.end,
        timeZone: preferences ? preferences.timezone : "America/New_York",
      },
      attendees: createdEvent.participants,
      visibility: preferences ? preferences.visibility : "default",
      reminders: {
        ...insertEventTemp.reminders,
        overrides: [
          {
            method: "email",
            minutes: preferences ? preferences.notificationMinute : 1440,
          },
          { method: "popup", minutes: 10 },
        ],
      },
    };
    if (eventBody.attendees[0].email !== "") {
      executeEventAdder(
        eventBody,
        createdEvent.sendNotifications,
        createdEvent.calendar
      );
      handleSelect(createdEvent.calendar);
    }

    form.resetFields();
    updateDocumentTitle(); // reset document title to "Lead Manager"
    message.success("The Event was submitted.");
    handleModalVisible(false);
  };

  //when form has an error
  // const onFinishFailed = (errorInfo) => {
  //   console.log("Failed (Form Submission):", errorInfo);
  //   message.error("There was a problem with the form submission!");
  //   handleModalVisible(false);
  // };

  return (
    <Modal
      className="create-event-modal"
      title={
        <CustomModalHeader
          {...{
            hasButton: false,
            title: "You are creating a New Event",
            headerInfo: () => {
              return (
                !!user && (
                  <>
                    <InfoIcon
                      style={{ marginRight: "5px", fontSize: "10px" }}
                    />
                    <label style={{ color: "#323338", fontSize: 14 }}>
                      Logged in as: {user}
                    </label>
                  </>
                )
              );
            },
          }}
        />
      }
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Create Event" });
      }}
      open={openEventModal}
      styles={{
        pointerEvents: "all",
      }}
      centered={true}
      maskClosable={false}
      width={"1102px"}
      closeIcon={<XIcon />}
      onCancel={handleCancel}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <MondayButton
            {...{
              onClick: handleCancel,
              Icon: <XIcon />,
              className: "mondayButtonRed",
            }}
          >
            Close
          </MondayButton>
          <MondayButton
            {...{
              onClick: form.submit,
              Icon: <TickIcon />,
            }}
          >
            Save {"&"} Close
          </MondayButton>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={() => form.validateFields().then(onFinish)}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          sendNotifications: true,
        }}
      >
        <div className="add-event-form">
          <div className="left-form">
            <Form.Item
              label={"Title"}
              name="summary"
              rules={[
                {
                  required: true,
                  message: "Please describe the title of your event!",
                },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
            <Form.Item
              label={"Location"}
              name="location"
              rules={[
                {
                  required: true,
                  message: "Please add location!",
                },
              ]}
            >
              <SimplePlacesInput
                // initialValue={locationValue}
                onSelect={filledAddress}
                className="GPlaceAutocomplete"
                form={form}
                formItemName="location"
                style={{ minWidth: 250 }}
              />
              {/* <PlacesComplete filledAddress={filledAddress} /> */}
            </Form.Item>
            <div className="timeDate">
              <Form.Item
                name="fullTime"
                className="fullTime"
                rules={[
                  {
                    required: true,
                    message: "Please pick the correct time",
                  },
                ]}
              >
                <TimeDate processTimeDate={processTimeDate} />
              </Form.Item>
            </div>
            <div className="participantsCalendar">
              <Form.Item
                label={" Participants"}
                name="participants"
                valuePropName={tags}
                rules={[
                  {
                    required: true,
                    message: "Please choose at least 1 (one) participant!",
                  },
                ]}
              >
                <ParticipantsInput selectedTags={selectedTags} />
              </Form.Item>
              <Form.Item
                label={"Calendar"}
                name="calendar"
                rules={[
                  {
                    required: true,
                    message: "Please select your calendar!",
                  },
                ]}
              >
                <Select
                  popupClassName="calendar-selector"
                  className="calendar-select-ant"
                  onClick={() => setCalendar(calendar)}
                  suffixIcon={<DropDownArrow />}
                  placeholder="Select Calendar"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <NewCalendar
                        setRefreshCalendarList={setRefreshCalendarList}
                      />
                    </div>
                  )}
                >
                  {calendars?.map((element) => (
                    <Select.Option value={element.id} key={element.id}>
                      {element.primary ? "primary" : element.summary}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            ></Form.Item>
          </div>
          <div className="right-form">
            <Form.Item name="description">
              <>
                <label>Event Description</label>
                <RichTextBox
                  {...{
                    setCreatedRichText,
                    createdRichText,
                  }}
                />
              </>
            </Form.Item>
            <Form.Item name="sendNotifications">
              <ReportCheck
                {...{
                  id: "sendNotificationsCheck",
                  label: "Notify Now",
                  defaultChecked: true,
                  className: "notifyNow",
                }}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default AddEventModal;
