import { Alert, Modal, Select } from "antd";
import { InputComponent } from "../../../SidebarPages/Fleet/components";
import { MondayButton } from "../../../commonComponents";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import HearingsModal from "./HearingsModal";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { antdModalProps, updateDocumentTitle } from "../../../../utils";
import IndividualLoader from "../../../IndividualLoader";
import { API } from "aws-amplify";
import PreClaimsModal from "../ClaimsModal/PreClaimsModal";
import { PlusIcon } from "../../../../assets";
import "./PreHearingModal.scss";

function PreHearingsModal({
  visible,
  setVisible,
  projectId,
  claimCategory,
  claimId,
  claimNumber,
  claimType,
  isNextStep,
  type,
}) {
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [hearingModalVisible, setHearingModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(claimCategory);
  const [fieldsOfCategory, setFieldsOfCategory] = useState([]);
  const [selectedField, setSelectedField] = useState(
    claimId
      ? {
          id: claimId,
          name: claimNumber,
          type: claimType,
        }
      : null
  );

  const [loading, setLoading] = useState(false);
  const [claimModalVisible, setClaimModalVisible] = useState(false);

  const claimCategories = programFields.find(
    ({ fieldName }) => fieldName === "Claim Categories"
  ).fieldOptions;

  const onSelectCategory = (e) => {
    setSelectedCategory(e);
    setSelectedField(null);
  };

  //keys=[id,name],arr=[{},{},...]
  function filterByKeys(arr, keys) {
    return arr.map((obj) => {
      const newObj = {};
      keys.forEach((key) => {
        try {
          const value = eval(`obj.${key}`);
          if (value !== undefined) {
            newObj[key] = value;
          }
        } catch (e) {
          console.log("error", e);
        }
      });
      const oldKeys = Object.keys(newObj);
      const renamedObj = newObj;
      renamedObj["id"] = renamedObj[oldKeys[0]];
      delete renamedObj[oldKeys[0]];
      renamedObj["name"] = renamedObj[oldKeys[1]];
      delete renamedObj[oldKeys[1]];
      renamedObj["type"] = renamedObj[oldKeys[2]];
      delete renamedObj[oldKeys[2]];
      return renamedObj;
    });
  }

  useEffect(() => {
    setFieldsOfCategory([]);
    (async () => {
      try {
        setLoading(true);
        const res = await API.get("claims", `/claims`);
        let result = res;
        if (projectId) {
          result = res.filter((claim) => claim.projectId === projectId);
        }
        const filtered = result.filter(
          (claim) => claim.claimCategory === selectedCategory
        );

        const filteredByType = !type
          ? filtered
          : filtered.filter((claim) => {
              return type === "depositions"
                ? claim.claimType !== "Worker Compensation"
                : type === "hearings"
                ? claim.claimType === "Worker Compensation"
                : claim;
            });
        console.log("filtered:", { filteredByType, type });
        const claims = filterByKeys(filteredByType, [
          "claimId",
          "claimNumber",
          "claimType",
        ]);
        setFieldsOfCategory(claims);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [selectedCategory]);

  const pageTitle = useMemo(() => {
    if (!!selectedField && selectedField?.type !== "Worker Compensation") {
      return "Deposition";
    } else {
      return "Hearing";
    }
  }, [selectedField]);

  return (
    <>
      <Modal
        {...{
          ...antdModalProps,
          title: "New " + pageTitle + " Modal",
          afterOpenChange: (event) => {
            event &&
              updateDocumentTitle({ newTitle: "New " + pageTitle + " Modal" });
          },
          className: `preHearingModal ${isDarkMode && "pre-claim-modal-dark"}`,
          open: visible,
          onCancel: () => {
            setVisible(false);
            updateDocumentTitle(); // reset document title to "Lead Manager";
            setSelectedCategory(null);
          },
        }}
      >
        <div className="note-modal-content">
          <InputComponent
            required
            showSearch
            label="Category"
            placeholder="Select a category"
            dropdownClassName={isDarkMode && "darkDropDown"}
            type="select"
            options={claimCategories?.map(({ categoryName, categoryId }) => (
              <Select.Option key={categoryId} value={categoryName}>
                {categoryName}
              </Select.Option>
            ))}
            defaultValue={claimCategory}
            // disabled={!!claimCategory}
            onSelect={(e) => onSelectCategory(e)}
            onClear={() => {
              setSelectedCategory(null);
              setSelectedField(null);
            }}
            disabled={!!claimId}
          />
          {selectedCategory ? (
            fieldsOfCategory?.length ? (
              <InputComponent
                required
                showSearch
                label="Claims"
                placeholder="Select an Claim"
                dropdownClassName={isDarkMode && "darkDropDown"}
                type="select"
                customOptions={fieldsOfCategory?.map(({ id, name, type }) => ({
                  value: id,
                  label: name,
                  allData: { id, name, type },
                }))}
                disabled={!!claimId}
                initialValue={selectedField?.id}
                onSelect={(e, data) => {
                  setSelectedField(data.allData);
                }}
                onClear={() => {
                  setSelectedField(null);
                }}
                dropdownRender={(menu) => (
                  <div className="safetyDropDown">
                    {menu}
                    <div
                      className="addSafety"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 10,
                        paddingBottom: 5,
                      }}
                    >
                      <MondayButton
                        onClick={() => {
                          setClaimModalVisible(true);
                        }}
                        Icon={<PlusIcon />}
                      >
                        Create New Claim
                      </MondayButton>
                    </div>
                  </div>
                )}
              />
            ) : loading ? (
              <div className="loader">
                <IndividualLoader />
              </div>
            ) : (
              <div
                className="noClaimsDiv"
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Alert
                  style={{ height: "32px", width: "100%" }}
                  message={`No Claims for this category!`}
                  type="error"
                  showIcon
                  // closable
                />
                <MondayButton
                  onClick={() => {
                    setClaimModalVisible(true);
                  }}
                  Icon={<PlusIcon />}
                >
                  Create New Claim
                </MondayButton>
              </div>
            )
          ) : (
            ""
          )}
          <div className="modalFooter">
            <MondayButton
              {...{
                Icon: <TickIcon width={17} height={17} />,
                onClick: () => {
                  setHearingModalVisible(true);
                },
                disabled: selectedField === null,
                tooltipCategory: "Hearings",
                tooltipKey: "open",
              }}
            >
              Open {pageTitle} Modal
            </MondayButton>
          </div>
        </div>
      </Modal>
      {hearingModalVisible && selectedField && (
        <HearingsModal
          {...{
            visible: hearingModalVisible,
            setVisible: setHearingModalVisible,
            category: selectedCategory,
            selectedClaim: selectedField,
            isNextStep,
            setPreModalVisible: setVisible,
          }}
        />
      )}
      {claimModalVisible && (
        <PreClaimsModal
          {...{
            visible: claimModalVisible,
            setVisible: setClaimModalVisible,
            category: selectedCategory,
            projectId,
            isNextStep,
            fromHearing: true,
            proppedType: type,
            handleSavedRecord: (res) => {
              const newClaim = {
                id: res.claimId,
                name: res.claimNumber,
                type: res.claimType,
              };
              setFieldsOfCategory((prev) => [...prev, newClaim]);
              setSelectedField(newClaim);
              setClaimModalVisible(false);
            },
          }}
        />
      )}
    </>
  );
}

export default PreHearingsModal;
