import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, message, Radio, Select, Tooltip, Tour } from "antd";
import {
  title,
  subtitle,
  footerButtons,
  checkboxCardsTitle,
  inspectionTypes,
} from "./vehicleInspectionModalData";
import { DownOutlined } from "@ant-design/icons";
import "./VehicleInspectionModal.scss";
import {
  FullScreenModal,
  InputComponent,
  BorderedTextCard,
} from "../../../../../../components";
import dayjs from "dayjs";
import { filterTables } from "../../../../../../../../../utils/filterTables";
import { apiRoutes, fetchData, validateForm } from "../../../../../../utils";
import { compareIncluding } from "../../../../../../../utils";
import API from "@aws-amplify/api";
import { MaintenanceRequestModal } from "./components";
import { SignatureModal } from "../../../../../../../XComponents";
import { MaintenanceModal } from "../../../SingleTruckMaintenance/modals";
import {
  MondayButton,
  MultiLevelTreeLogs,
  ProgressComponent,
} from "../../../../../../../../commonComponents";
import { RenderDynamicComponents } from "../../../../../../../../Header/forms/Components";
import { useSelector } from "react-redux";
import { assignDisabledDate } from "../../../../../../../../Header/forms/utils";
import WarningModal from "../../../../../../../../commonComponents/WarningModal/WarningModal";
import { getChangedData } from "../../../../../../../Accounting/components/utilities";
import { TickIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { WarningTriangle } from "../../../../../../../DynamicView/src";
import { showSuccessMsg } from "../../../../../../../../../utils/windowMessages";
import broadcastNotification from "../../../../../../../../../helpers/controllers/broadcastNotification";
import { Editor } from "@tinymce/tinymce-react";
import { RightArrow } from "../../../../../../../BasePage/src/index";
import { v4 as uuidv4 } from "uuid";
import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import { useCreateDriveFolders } from "../../../../../../../../../hooks/useCreateDriveFolders";
import CheckboxItem from "./components/checkboxItem/CheckboxItem";
import SignFieldComponent from "./components/SignControllerButtons/SignFieldComponent";
import CustomModalHeader, {
  PlayVideoTutorial,
} from "../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { VideoTutorialIcon } from "../../../../../../../../Header/components/ModalVideoTutorials/assets/svg";
import { AddIcon } from "../../../../../../../../Header/components/GoogleEvents/assets";
import DynamicTeamModal from "../../../../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import { DynamicAvatars } from "../../../../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { FilePreviewModal } from "../../../../../../../Documentation/View/components";
import { XIcon } from "../../../../../../../Communication/assets";
import ToDoModal from "src/components/SidebarPages/ToDos/ToDoModal";
import { getInspectionHtmlTemplate } from "./utils/getInspectionHtmlTemplate";
import { useProgressComponent } from "src/hooks";
import { useEditLogs } from "../../../../../../../../../hooks";
import { deleteAllRelatedToDos } from "../../../../../../../ToDos/forms/helpers";
import { fetchAllData } from "../../../../../../../../../utils";

// get users from userConfiguration with given role
const getUserConfigurationUsersByRole = (userConfiguration, role) => {
  return userConfiguration?.allUsers?.Items?.filter(
    (user) => user.groupName.toLowerCase().replace(/\s+/g, "") === role
  );
};

const VehicleInspectionModal = ({
  visible,
  setVisible,
  rowObject = {},
  refreshTable,
  deleteRow,
  globalDisabled = false,
  isNextStep,
}) => {
  const {
    inspectionId,
    inspectionReport = [],
    inspectedBy,
    mechanicInfo = {},
    driverInfo = {},
    adminInfo = {},
    inspectionType,
    inspectionNotes,
    fleetId: predefinedId,
    inspectionFolderId,
    //these are passed from the activity modal/dispatch page
    activityId,
    pickUpLocation,
    dropOffLocation,
    fleetName,
    departAt,
    dispatchId,
    teamsConfiguration = [],
  } = rowObject;
  const { driverSignature: existingInspDriverSign, driver } = driverInfo;
  const { mechanicSignature: existingInspMechanicSign, mechanic } =
    mechanicInfo;
  const { adminSignature: existingInspReviewerSign, admin } = adminInfo;

  const [form] = Form.useForm();

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({
    categoryName: "Fleet Inspections",
    actionType: inspectionId ? "Update" : "Create",
  });
  const { saveAddedLogs } = useEditLogs();

  const driveFunctions = useCreateDriveFolders("Fleet Inspections");
  const [operatorItems, setOperatorItems] = useState();
  const [details, setDetails] = useState([]);
  const [booleans, setBooleans] = useState([]);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [users, setUsers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(
    driver ?? driverInfo?.driver
  );
  const [selectedReviewingDriver, setSelectedReviewingDriver] = useState(
    adminInfo?.admin
  );
  const [selectedMechanic, setSelectedMechanic] = useState(
    mechanicInfo?.mechanic
  );
  const [accessRight] = useState(
    userConfiguration?.routeConfig
      ?.find(({ title }) => title === "Fleets Inspections")
      ?.children?.find(({ title }) => title === "Overview")
  );
  const [signatureModalVisible, setSignatureModalVisible] = useState(false);
  const [deleteWarningModalVisible, setDeleteWarningModalVisible] =
    useState(false);
  const [whichSignToChange, setWhichSignToChange] = useState();
  const [maintenanceModalVisible, setMaintenanceModalVisible] = useState(false);
  const [maintenanceRequestModalVisible, setMaintenanceRequestModalVisible] =
    useState(false);
  const [maintenanceModalData, setMaintenanceModalData] = useState();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const { fleetFields } = useSelector((state) => state.fleetFields);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { programFields } = useSelector((state) => state.programFields);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [logsVisible, setLogsVisible] = useState(false);
  const [existingLogs, setExistingLogs] = useState([]);
  const [editorBody, setEditorBody] = useState("");
  const [defectList, setDefectList] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [gDriveFolderId, setGDriveFolderId] = useState();
  const [inspectionItems, setInspectionItems] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(teamsConfiguration);
  const [teamsModalVisible, setTeamsModalVisible] = useState(false);
  const [toDoVisible, setToDoVisible] = useState(false);
  const createdAt = useRef();
  const navigate = useNavigate();

  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );
  const driveRequest = driveApi({ accessToken });

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "Fleet Management")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Inspection"
    )?.videoLink;

  const userIsDriver = userConfiguration.groupName === "Driver";
  const userIsMechanic = userConfiguration.groupName === "Mechanic";
  const userIsFleetAdmin =
    userConfiguration.groupName === "Fleet Administrator";

  const datesOpenCondition = !!departAt
    ? dayjsNY().startOf("D").valueOf() >
      dayjsNY(departAt).startOf("D").valueOf()
    : false;

  //Gets the fleetId by URL and then gets the vehicle by FleetId
  let location = useLocation();

  let fleetId = predefinedId ?? "";
  if (
    window.location.pathname !== "/fleets/overview" &&
    window.location.pathname !== "/fleetInspections"
  ) {
    fleetId =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
  }
  const selectedMechanicExistingSign =
    !!selectedMechanic &&
    !!users.find(({ name }) => compareIncluding(name, selectedMechanic))
      ?.officialSignature
      ? `data:image/jpeg;base64,${
          users?.find(({ name }) => compareIncluding(name, selectedMechanic))
            ?.officialSignature
        }`
      : "";

  const [selectedDriversSign, setSelectedDriversSign] = useState(
    existingInspDriverSign
  );
  const [selectedReviewingDriversSign, setSelectedReviewingDriversSign] =
    useState(existingInspReviewerSign);
  const [selectedMechanicsSign, setSelectedMechanicsSign] = useState(
    existingInspMechanicSign || selectedMechanicExistingSign
  );

  //creates an array of objects of the input fields values ready to be posted to the database
  const formatQuestions = (arr = []) =>
    arr.reduce(
      (acc, { label, type, id }) => [
        ...acc,
        type === "radio"
          ? {
              label,
              id,
              value:
                compareIncluding(type, "datepicker") ||
                compareIncluding(type, "timepicker")
                  ? dayjs(form.getFieldValue(id)).unix() * 1000
                  : form.getFieldValue(id),
            }
          : {
              type,
              label,
              id,
              value:
                compareIncluding(type, "datepicker") ||
                compareIncluding(type, "timepicker")
                  ? dayjs(form.getFieldValue(id)).unix() * 1000
                  : form.getFieldValue(id),
            },
      ],
      []
    );

  const resetFields = () => {
    // resets all the fields except of the fleet name one when we are creating a inspection inside a fleet
    form.resetFields([
      ...details?.map?.(({ id }) => id)?.filter?.((i) => i !== 8),
    ]);
    form.resetFields([
      ...booleans?.map(({ id }) => id),
      ...operatorItems?.map(({ id }) => id),
      "fleetAdministratorSignature",
      "mechanicSignSelector",
      "driverSignSelector",
      "selectedDriver",
      "selectedMechanic",
      "selectedReviewingDriver",
    ]);

    setSelectedDriversSign("");
    setSelectedMechanicsSign("");
    setSelectedReviewingDriversSign("");
  };
  // gets all values from conditions so we can set a status for each inspection created
  let inspStatus = formatQuestions(operatorItems);

  function getAccessRights(childTitle) {
    return (accessRight?.children || [])?.find(
      ({ title }) => title === childTitle
    );
  }

  // Items to be saved to database
  const bodyObject = () => ({
    fleetId: fleetId,
    fleetName: fleetName,
    createdAt: useRef.current,
    dispatchId,
    mechanicInfo: {
      mechanic: selectedMechanic,
      mechanicId: userConfiguration?.allUsers?.Items?.find(
        (el) => el?.nameOfUser === selectedMechanic
      )?.cognitoUserId,
      mechanicSignature: selectedMechanicsSign,
    },
    driver: !!selectedDriver ? selectedDriver : driver,
    adminInfo: {
      admin: selectedReviewingDriver,
      adminId: userConfiguration?.allUsers?.Items?.find(
        (el) => el?.nameOfUser === selectedReviewingDriver
      )?.cognitoUserId,
      adminSignature: selectedReviewingDriversSign,
    },
    driverInfo: {
      driver: selectedDriver,
      driverId: drivers?.find((el) => el?.driverName === selectedDriver)
        ?.driverId,
      driverSignature: selectedDriversSign,
    },
    inspectionType: form.getFieldValue("inspectionType"),
    inspectionReport: formatQuestions(booleans),
    driver: selectedDriver,
    inspectedBy: selectedReviewingDriver,
    mechanic: selectedMechanic,
    inspectionStatus: inspStatus?.every?.(({ value }) => value === "Acceptable")
      ? "Passed"
      : "Failed",
    inspectionDate: dayjsNY(form.getFieldValue(3)).format("MM/DD/YYYY"),
    inspectionFolderId: gDriveFolderId,
    inspectionNotes: editorBody,
    inspectionSignature: {
      driver: selectedDriversSign,
      mechanic: selectedMechanicsSign,
      inspectedBy: selectedReviewingDriversSign,
    },
    inspectionDetails: [
      { label: "Location", location: form.getFieldValue("pickUpLocation") },
      {
        label: "Inspection Date",
        inspectionDate: dayjsNY(form.getFieldValue(3)).unix() * 1000,
      },
      { label: "Time", time: dayjsNY(form.getFieldValue(4)).unix() },
      { label: "Odometer Reading", odometerReading: form.getFieldValue(5) },
    ],
    activityId: activityId,
    mechanicId: userConfiguration?.allUsers?.Items?.find(
      (el) => el?.nameOfUser === selectedMechanic
    )?.cognitoUserId,
  });

  const onSave = async () => {
    const key = "savingMsg";
    message.loading({ content: "Saving...", key });
    setSaving(true);

    let currentData = bodyObject();

    setVisibleCreationProgress(rowObject);
    updateProgressStatus({ updatingRecord: "executing" });
    if (!inspectionId) {
      await API.post(
        apiRoutes.fleetInspections,
        `/${apiRoutes.fleetInspections}`,
        { body: { ...bodyObject() } }
      )
        .then((r) => {
          setVisibleCreationProgress(r); // save datas to generate logs in case of any fail
          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          }); //set progress of creating recaord as 100%

          r.inspectionReport.forEach((item) => {
            if (item.value !== "Acceptable") {
              API.post("fleetDefects", "/fleetDefects", {
                body: {
                  defectId: uuidv4(),
                  defectName: item.label,
                  defectPriority: "Undefined",
                  defectStatus: "New",
                  driverComment: editorBody,
                  driverId: r.driverInfo.driverId,
                  driverName: driver,
                  odometerReading: r.inspectionDetails.find(
                    ({ label }) => label === "Odometer Reading"
                  ).odometerReading,
                  fleetId: r.fleetId,
                  fleetName: r.fleetName,
                  inspectionId: r.inspectionId,
                  inspectionName: rowObject.inspectionName,
                  googleDriveFolderId: fileNames.find(
                    (file) => file.uploaderId === item.label
                  ).files,
                  lastModifiedBy: {},
                  userId: userConfiguration.userId,
                  workOrder: "",
                },
              });
            }
          });

          let tmpKey =
            r?.inspectionType === "Pre-trip" ? "preTripDone" : "postTripDone";
          API.put("fleetActivity", `/fleetActivity/${activityId}`, {
            body: {
              [tmpKey]: {
                inspectionId: r?.inspectionId,
                inspectionStatus: r?.inspectionStatus,
                value: true,
              },
            },
          }).catch((err) => {
            console.log("Could not update activity object: ", err);
          });

          saveAddedLogs({
            recordId: r?.inspectionId,
            recordName: r?.fleetName,
            category: "Fleet Inspections",
          });

          broadcastNotification(
            "11",
            "onInspectionCreation",
            [
              {
                common: userConfiguration?.nameOfUser,
                statusName: form.getFieldValue("inspectionType"),
                topicName: form.getFieldValue(8),
                category: dayjs(form.getFieldValue(3) || dayjs.now()).format(
                  "ll"
                ),
              },
              {
                userName: userConfiguration?.nameOfUser,
                tab: "inspections",
                currentUser: userConfiguration?.cognitoUserId,
              },
            ],
            `${r?.inspectionId}?inspectionId=${r?.inspectionId}`
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });

          refreshTable([r]);
          message.success({ content: "Saved!", key, duration: 3 });
          setSaving(false);
          resetFields();
          if (
            r?.inspectionReport.some?.(({ value }) => value === "No") &&
            inspStatus?.some?.(({ value }) => value === ("Defective" || "Poor"))
          ) {
            setMaintenanceRequestModalVisible(true);
            setMaintenanceModalData(r);
          }
        })
        .catch((error) => {
          console.log({ error });
          updateProgressStatus({ updatingRecord: "hasError" });
        });
    } else {
      let newEditLog = {
        recordId: inspectionId,
        recordName: rowObject?.fleetName,
        category: "Fleet Inspections",
        actionType: "Edit",
        topic: "",
        label: "",
        currentData: {},
        nameOfUser: `${authUser.given_name} ${authUser.family_name}`,
        cognitoUserId: authUser?.sub,
        previousData: {},
        updatedAt: dayjs().valueOf(),
        updatedKeys: [],
      };

      let tmpNewObj = JSON.parse(JSON.stringify(currentData)),
        tmpOldObj = JSON.parse(JSON.stringify(rowObject));

      for (let key in tmpNewObj) {
        let result;
        if (!!tmpNewObj[key] && !!tmpOldObj[key]) {
          result = getChangedData(tmpNewObj[key], tmpOldObj[key]);
        } else {
          continue;
        }
        if (result !== false) {
          newEditLog.currentData[key] = result.curr;
          newEditLog.previousData[key] = result.prev;
          newEditLog.updatedKeys.push(key);
        }
      }

      saveAddedLogs(newEditLog);

      API.put(
        apiRoutes.fleetInspections,
        `/${apiRoutes.fleetInspections}/${inspectionId}`,
        { body: { ...currentData } }
      )
        ?.then((r) => {
          //set progress of updating record as 100%
          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          });

          let tmpKey =
            r?.inspectionType === "Pre-trip" ? "preTripDone" : "postTripDone";
          API.put("fleetActivity", `/fleetActivity/${activityId}`, {
            body: {
              [tmpKey]: {
                inspectionId: r?.inspectionId,
                inspectionStatus: r?.inspectionStatus,
                value: true,
              },
            },
          }).catch((err) => {
            console.log("Could not update activity object: ", err);
          });

          broadcastNotification(
            "11",
            "onInspectionEdit",
            [
              {
                statusName: form.getFieldValue("inspectionType"),
                common: userConfiguration?.nameOfUser,
                category: dayjs(form.getFieldValue(3)).format("ll"),
                topicName: form.getFieldValue(8),
              },
              {
                userName: userConfiguration?.nameOfUser,
                tab: "inspections",
                currentUser: userConfiguration?.cognitoUserId,
              },
            ],
            inspectionId
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });

          Object.keys(rowObject).forEach((e) => (rowObject[e] = r?.[e]));
          refreshTable({ update: [rowObject] });
          message.success({ content: "Saved!", key, duration: 3 });
          setSaving(false);
          resetFields();
        })
        ?.catch((e) => {
          updateProgressStatus({ updatingRecord: "hasError" });
          console.error("Error:", e);
        });
    }
  };

  function getSignatureValidation() {
    if (userIsDriver) {
      return !!selectedDriversSign;
    }
    if (userIsMechanic) {
      return !!selectedMechanicsSign;
    }
    if (userIsFleetAdmin) {
      return !!selectedReviewingDriversSign;
    }
    return true;
  }

  //Validates all input fields and signatures, then calls the function "onSuccessFunc"
  const validateInspectionForm = (onSuccessFunc) => {
    const filesUploadedPerDefect = defectList.find(({ label }) =>
      fileNames.includes(({ uploaderId }) => uploaderId !== label)
    );
    if (!!filesUploadedPerDefect) {
      message.warning("Please upload files for all defects");
    } else if (getSignatureValidation()) {
      validateForm(form, onSuccessFunc);
    } else {
      message.warning("Please add your signatures!");
    }
  };

  //formats the inspection report category values, so they can populate the input fields
  const formatExistingInspCategory = (arr = []) => {
    return arr?.reduce(
      (acc, { id, value }) => ({
        ...acc,
        [id]: value,
      }),
      {}
    );
  };

  const onCancel = () => {
    if (!inspectionId) {
      driveFunctions.delete();
      driveFunctions.reset();
    }
    !!!isNextStep && navigate("/fleetInspections", { redirect: true });
    setCancelModalVisible(false);
    setVisible(false);
    resetFields();
  };

  const onDelete = () => {
    setDeleteWarningModalVisible(false);
    API.del(
      apiRoutes.fleetInspections,
      `/${apiRoutes.fleetInspections}/${inspectionId}`
    )
      .then((r) => {
        deleteAllRelatedToDos({ recordId: inspectionId });
        let tmpKey =
          rowObject?.inspectionType === "Pre-trip"
            ? "preTripDone"
            : "postTripDone";
        API.put("fleetActivity", `/fleetActivity/${activityId}`, {
          body: {
            [tmpKey]: false,
          },
        }).catch((err) => {
          console.log("Could not update activity object: ", err);
        });

        saveAddedLogs({
          recordId: inspectionId,
          recordName: rowObject?.fleetName,
          category: "Fleet Inspections",
          actionType: "Delete",
        });

        broadcastNotification(
          "11",
          "onInspectionDelete",
          [
            {
              statusName: form.getFieldValue("inspectionType"),
              common: userConfiguration?.nameOfUser,
              category: dayjs(form.getFieldValue(3)).format("ll"),
              topicName: form.getFieldValue(8),
            },
            {
              userName: userConfiguration?.nameOfUser,
              tab: "inspections",
              currentUser: userConfiguration?.cognitoUserId,
            },
          ],
          null
        );
        showSuccessMsg({ content: "Deleted!", duration: 3 });
        deleteRow(rowObject);
        setVisible(false);
        navigate("/fleetInspections", { redirect: true });
        filterTables("fleetDefects", "inspectionId", inspectionId).then((res) =>
          res.forEach((defect) =>
            API.del("fleetDefects", `/fleetDefects/${defect.defectId}`)
          )
        );
      })
      .catch((e) => console.error(e));
  };

  const onFinish = () => {
    onSave();
    // setVisible(false);
    // !!!isNextStep && navigate("/fleetInspections"), { redirect: true };
  };

  useEffect(() => {
    Promise.allSettled([
      fetchData("drivers"),
      fetchData("dynamicQuestions"),
    ]).then(([{ value: drivers }, { value: questions }]) => {
      setDrivers(drivers);

      const vehicleInspectionFields = questions?.find(
        ({ nameOfQuestions }) =>
          nameOfQuestions === "Driver's Daily Vehicle Inspection Report"
      )?.questions;

      let inspectionTime = undefined;
      try {
        inspectionTime = rowObject?.inspectionDetails?.find(
          (el) => el?.label === "Time"
        )?.time;
      } catch {}

      setDetails(
        vehicleInspectionFields?.details?.map((item) => {
          let dateCondition =
            item?.label?.includes("Date") || item?.label?.includes("Time");
          let timeDisabled = dateCondition && !datesOpenCondition;
          return {
            ...item,
            name: item?.id,
            formItemName: item.id,
            disabled:
              item?.id === 8 ||
              item?.id === 6 ||
              timeDisabled ||
              globalDisabled,
            initialValue: dateCondition
              ? !!inspectionTime
                ? dayjsNY(inspectionTime)
                : dayjsNY()
              : "",
            type: item?.type,
            options: null,
            rules: [
              {
                validator(_, value) {
                  if (item?.id === 5) {
                    const rg = /^[0-9]*$/;
                    if (rg.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error("Only numbers are allowed!")
                      );
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ],
            disabledDate: assignDisabledDate(item, "Inspection Date"),
          };
        })
      );

      setBooleans(
        vehicleInspectionFields?.booleans?.map((item) => ({
          ...item,
          formItemName: item?.id,
        }))
      );
    });
  }, []);

  useEffect(() => {
    const {
      fieldOptions = [],
      options = [],
      type,
    } = fleetFields?.find(
      ({ fieldName }) => fieldName === "Driver's Vehicle Inspection Items"
    ) || {};
    const formattedFields = fieldOptions?.reduce(
      (acc, input) => [
        ...acc,
        { ...input, formItemName: input?.id, options, type },
      ],
      []
    );

    setOperatorItems(formattedFields);
  }, [fleetFields]);

  useEffect(() => {
    if (!inspectionId) {
      API.get("fleet", `/fleet/${rowObject?.fleetId}`).then(async (res) => {
        const fleetFolderId = res?.googleDriveFolderIds?.inspectionObject;
        const folderName = dayjs().format("MM/DD/YYYY, hh:mm:ss");
        createdAt.current = folderName;
        await driveFunctions.create({
          parentFolderName: folderName,
          parentId: fleetFolderId,
        });
      });
    }

    userConfiguration &&
      setUsers(
        userConfiguration?.allUsers?.Items?.reduce(
          (acc, { groupName, nameOfUser, officialSignature = "" }) => [
            ...acc,
            {
              department: groupName,
              name: `${nameOfUser?.split(" ")[0]} ${nameOfUser?.split(" ")[1]}`,
              officialSignature,
            },
          ],
          []
        )
      );
  }, [userConfiguration]);

  const onRadioSelect = (e, item) => {
    const selectedVal = e.target.value;
    const defectExists = defectList.some(
      (defect) => defect.label === item.label
    );
    setInspectionItems(
      inspectionItems.concat({
        id: item.id,
        label: item.label,
        value: selectedVal,
      })
    );
    if (!defectExists && selectedVal !== "Acceptable") {
      const defectObj = {
        ...item,
        defectAttachments: [],
      };
      setDefectList(defectList.concat(defectObj));
    } else if (defectExists && selectedVal === "Acceptable") {
      fileNames
        .find((file) => file.uploaderId === item.label)
        ?.files.map(async ({ id }) => await driveRequest.deleteDriveItem(id));

      const filteredFileNames = fileNames.filter(
        (file) => file.uploaderId !== item.label
      );
      const filteredDefectList = defectList.filter(
        (defect) => defect.label !== item.label
      );
      setDefectList(filteredDefectList);
      setFileNames(filteredFileNames);
    }
  };

  function onMassRadioSelect(e) {
    const selectedVal = e.target.value;
    let tmpInspectionItems = [];
    let tmpDefectList = defectList;
    let tmpFileNames = fileNames;
    booleans.map((item) => {
      const defectExists = defectList.some(
        (defect) => defect.label === item.label
      );
      tmpInspectionItems.push({
        id: item.id,
        label: item.label,
        value: selectedVal,
      });
      if (!defectExists && selectedVal !== "Acceptable") {
        const defectObj = {
          ...item,
          defectAttachments: [],
        };
        if (!tmpDefectList.some((defect) => defect?.label === item.label)) {
          tmpDefectList.push(defectObj);
        }
      } else if (defectExists && selectedVal === "Acceptable") {
        fileNames
          .find((file) => file.uploaderId === item.label)
          ?.files.map(async ({ id }) => await driveRequest.deleteDriveItem(id));

        tmpFileNames = tmpFileNames.filter(
          (file) => file.uploaderId !== item.label
        );
        tmpDefectList = tmpDefectList.filter(
          (defect) => defect.label !== item.label
        );
      }
      form.setFieldValue(`${item?.id}`, selectedVal);
      form.setFieldValue("allItems", selectedVal);
    });
    setDefectList(tmpDefectList);
    setFileNames(tmpFileNames);
    setInspectionItems(tmpInspectionItems);
  }

  const onPickerSuccess = ({ uploadedFiles, uploaderId }) => {
    const uploadedFileNames = uploadedFiles.map(({ name, id }) => ({
      name: `${uploaderId}/${name}`,
      id,
      uploadedAt: dayjs().valueOf(),
    }));
    const fileNamesCopy = [...fileNames];

    const matchingItem = fileNamesCopy.find((item) =>
      compareIncluding(item.uploaderId, uploaderId)
    );

    if (matchingItem) {
      matchingItem.files.push(...uploadedFileNames);
      setFileNames(fileNamesCopy);
    } else {
      const newItem = {
        uploaderId,
        files: uploadedFileNames,
      };
      setFileNames(fileNamesCopy.concat(newItem));
    }
  };

  // Populates fields with the info of the existing inspection
  useEffect(() => {
    if (!!inspectionId && !!operatorItems?.length) {
      const inspectionReportObjects =
        formatExistingInspCategory(inspectionReport);

      form.setFieldsValue({
        ...inspectionReportObjects,
        driverSignSelector: driver,
        mechanicSignSelector: mechanic,
        fleetAdministratorSignature: admin,
        inspectionType,
        pickUpLocation,
        dropOffLocation,
        inspectionType,
        8: fleetName,
        6: inspectionType === "Pre-trip" ? pickUpLocation : dropOffLocation,
        5: rowObject.inspectionDetails[3].odometerReading,
      });

      setEditorBody(inspectionNotes);
      setSelectedDriver(driver);
      setSelectedReviewingDriver(inspectedBy);
      setSelectedMechanic(mechanic);
      setSelectedDriversSign(existingInspDriverSign);
      setSelectedReviewingDriversSign(existingInspReviewerSign);
      setSelectedMechanicsSign(existingInspMechanicSign);
    } else if (!!pickUpLocation) {
      form.setFieldsValue({
        pickUpLocation,
        dropOffLocation,
        inspectionType,
        driverSignSelector: driver,
        fleetAdministratorSignature: userIsFleetAdmin
          ? userConfiguration.nameOfUser
          : null,
        8: fleetName,
        6: inspectionType === "Pre-trip" ? pickUpLocation : dropOffLocation,
      });
    }

    if (!!inspectionId) {
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "recordId",
                  value: inspectionId,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      })
        .then((res) => {
          setExistingLogs(res);
        })
        .catch((err) => console.log("Could not fetch logs: ", err));
    }

    //case in which the modal is opened in the grid
    if (!!activityId && !pickUpLocation) {
      fetchAllData({
        endpoint: "fleetActivity",
        resultId: "activityId",
        resultPosition: "fleetActivity",
      })
        .then((res) => {
          form.setFieldsValue({
            pickUpLocation: res?.pickUpLocation,
            dropOffLocation: res?.dropOffLocation,
            6:
              inspectionType === "Pre-trip"
                ? res?.pickUpLocation
                : res?.dropOffLocation,
          });
        })
        .catch((err) => {
          console.log("Could not get activity: ", err);
        });
    }
  }, [JSON.stringify(rowObject), operatorItems?.length, visible]);

  useEffect(() => {
    if (!!booleans.length && inspectionId) {
      filterTables("fleetDefects", "inspectionId", inspectionId).then((res) => {
        const listOfFiles = res.map((defect) => ({
          uploaderId: defect.defectName,
          files: defect.googleDriveFolderId,
        }));
        const listOfDefects = res.map((defect) => {
          const defectCard = booleans?.find(
            (bool) => bool.label === defect.defectName
          );
          return {
            ...defectCard,
            defectAttachments: defect.googleDriveFolderId,
          };
        });

        setDefectList(listOfDefects);
        setFileNames(listOfFiles);
      });
    }
  }, [booleans]);

  useEffect(() => {
    if (!!driveFunctions.parentFolderId) {
      setGDriveFolderId(driveFunctions.parentFolderId);
    } else if (!!rowObject?.inspectionFolderId?.length) {
      setGDriveFolderId(rowObject.inspectionFolderId);
    }
  }, [driveFunctions.parentFolderId]);

  const fleetAdministrators = getUserConfigurationUsersByRole(
    userConfiguration,
    "fleetadministrator"
  );

  const tourSteps = [
    {
      title: "Inspection Tutorial",
      description: (
        <div>
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              onClick: () => {
                setShowVideoTutorial(true);
                setTourOpen(false);
              },
              style: { display: "flex", justifyContent: " center" },
              Icon: <VideoTutorialIcon />,
            }}
          >
            Watch Video
          </MondayButton>
        </div>
      ),
      className: "custom-tour-light",
      placement: "center",
    },
  ];

  const [pdfUrl, setPdfUrl] = useState();

  const exportToPdf = () => {
    const html = getInspectionHtmlTemplate();
    // axios
    //   .post("http://localhost:8080/api/htmltoPdf", { html })
    //   .then((res) => {
    //     console.log({ res });
    //     setPdfUrl(res.data);
    //   })
    //   .catch((err) => console.log({ err }));
  };

  /**
   * @param {string} formName
   */
  function findLocationWidth(formName) {
    const value = form.getFieldValue(formName);
    if (value) {
      const testSpan = document.createElement("span");
      testSpan.textContent = value;

      testSpan.style.position = "absolute";
      testSpan.style.top = "-100vh";
      testSpan.style.left = "-100vw";

      document.body.appendChild(testSpan);
      const width = testSpan.clientWidth;
      document.body.removeChild(testSpan);

      return width;
    }

    return undefined;
  }

  return (
    <>
      <FullScreenModal
        visible={visible}
        className={`vehicleInspectionModal ${
          isDarkMode && " vehicleInspectionModalDark"
        }`}
        // title={title}
        title={
          <CustomModalHeader
            {...{
              title,
              onClick: () => {
                setTourOpen(true);
              },
            }}
          />
        }
        subtitle={subtitle}
        onCancel={() => {
          if (formFieldsChanged) {
            setCancelModalVisible(true);
          } else {
            onCancel();
            setVisible(false);
          }
        }}
        destroyOnClose
        footer={
          !inspectionId
            ? footerButtons({
                saving,
                onCancel: () => {
                  if (formFieldsChanged) {
                    setCancelModalVisible(true);
                  } else {
                    onCancel();
                    setVisible(false);
                  }
                },
                logsAccess: getAccessRights("Logs"),
                onSaveAndClose: () => validateInspectionForm(onFinish),
                // onOptionClick: () => validateInspectionForm(onSave),
                suffix: <DownOutlined />,
              })?.filter(Boolean)
            : footerButtons({
                saving,
                exportToPdf,
                onCancel: () => {
                  if (formFieldsChanged) {
                    setCancelModalVisible(true);
                  } else {
                    onCancel();
                    setVisible(false);
                  }
                },
                logsAccess: getAccessRights("Logs"),
                onLogClick: () => {
                  setLogsVisible(true);
                },
                onSaveAndClose: () => validateInspectionForm(onFinish),
                onDelete: () => setDeleteWarningModalVisible(true),
                openToDo: () => setToDoVisible(true),
              })?.map(({ text, cancel, primary, disabled, onClick }) => ({
                text,
                cancel,
                primary,
                disabled,
                onClick,
              }))
        }
        style={{ minWidth: "90vw", maxWidth: "100vw", height: "85vh" }}
      >
        <div className="vehicleInspectionFormContainer">
          <Form
            form={form}
            onFieldsChange={() => {
              setFormFieldsChanged(true);
            }}
          >
            <div className="noFormCardTitle">
              <span className="title">Select the inspection type:</span>
              <InputComponent
                formItemName="inspectionType"
                className="inspectionType"
                type="radio"
                disabled={true}
                options={inspectionTypes?.map((type, i) => (
                  <Tooltip title="This field is view only">
                    <Radio key={i} value={type}>
                      {type}
                    </Radio>
                  </Tooltip>
                ))}
                required
              />
            </div>
            <BorderedTextCard
              title="Details"
              childrenStyle={{ alignItems: "flex-end" }}
              className="detailsFormCard"
              style={{ marginTop: "15px" }}
              isDarkMode={isDarkMode}
            >
              {RenderDynamicComponents(details, { form })}
              <InputComponent
                {...{
                  formItemName: "pickUpLocation",
                  type: "input",
                  required: true,
                  disabled: true,
                  label: "Pickup Location",
                  style: {
                    width: findLocationWidth("pickUpLocation"),
                    maxWidth: "95%",
                    minWidth: "250px",
                  },
                }}
              />

              <div
                style={{
                  height: "100%",
                  display: "grid",
                  placeContent: "center",
                  transform: "translateY(-35px)",
                  marginTop: "65px",
                }}
              >
                <RightArrow
                  fill={"#000"}
                  height={8}
                  width={13}
                  style={{ marginRight: "1rem" }}
                />
              </div>

              <InputComponent
                {...{
                  onChange: () => {},
                  formItemName: "dropOffLocation",
                  type: "input",
                  required: true,
                  disabled: true,
                  label: "Drop Off Location",
                  style: {
                    width: findLocationWidth("dropOffLocation"),
                    maxWidth: "95%",
                    minWidth: "250px",
                  },
                }}
              />
              <InputComponent
                label={"All Items"}
                required={false}
                type={"radio"}
                form={form}
                formItemName={"allItems"}
                disabled={globalDisabled}
                onChange={(e) => onMassRadioSelect(e)}
                options={booleans?.[0]?.options?.map((opt, i) => (
                  <Radio key={i} value={opt}>
                    {opt}
                  </Radio>
                ))}
              />
              {getAccessRights("Teams") ? (
                <MondayButton
                  onClick={() => {
                    setTeamsModalVisible(true);
                  }}
                  Icon={<AddIcon />}
                >
                  See Members
                </MondayButton>
              ) : (
                <></>
              )}

              <DynamicAvatars
                title={"Team"}
                isDarkMode={isDarkMode}
                members={selectedTeam?.reduce(
                  (acc, { members }) => [...acc, ...members],
                  []
                )}
              />
            </BorderedTextCard>
            <div className="noFormCardTitle">{checkboxCardsTitle}</div>
            <BorderedTextCard
              childrenStyle={{
                flexDirection: "column",
                paddingTop: 10,
              }}
              isDarkMode={isDarkMode}
            >
              <div className="checkboxItemsContainer">
                {booleans.map((item, index) => (
                  <CheckboxItem
                    {...{
                      item,
                      form,
                      globalDisabled,
                      onRadioSelect,
                      fileNames,
                      rowObject,
                      inspectionReport,
                      inspectionItems,
                      defectList,
                      gDriveFolderId,
                      accessToken,
                      onPickerSuccess,
                      key: `checkbox-item-${index}`,
                    }}
                  />
                ))}
              </div>
              <BorderedTextCard
                title="Additional comments"
                isDarkMode={isDarkMode}
              >
                <Editor
                  disabled
                  {...{
                    // this shows the text in the right direction
                    onChange: (content, editor) => {
                      setEditorBody(content);
                    },
                    // onEditorChange: (content, editor) => {
                    //   console.log({ content, editor });
                    //   setEditorBody(content);
                    // },
                    initialValue: inspectionId ? editorBody : "",
                    init: {
                      min_height: 150,
                      block_unsupported_drop: false,
                      plugins: "link image code table lists",
                      width: "100%",
                      menubar:
                        "file edit view format tools help fields newFields",
                      lists_indent_on_tab: false,
                      toolbar:
                        "undo redo | " +
                        "bold italic backcolor forecolor fontsizeselect fontselect  | alignleft aligncenter | " +
                        "alignright alignjustify | " +
                        "removeformat | numlist bullist",
                    },
                  }}
                />
              </BorderedTextCard>

              <div className="signaturesContainer">
                {(userIsDriver || userIsMechanic || userIsFleetAdmin) && (
                  <SignFieldComponent
                    {...{
                      label: "Driver's Signature:  ",
                      formItemName: "driverSignSelector",
                      value: rowObject?.driverInfo?.driver,
                      inputType: "input",
                      disabled: true,
                      src: selectedDriversSign,
                      setSignatureModalVisible,
                      sign: selectedDriversSign,
                      setSign: setSelectedDriversSign,
                      setWhichSignToChange,
                      existingSignature: existingInspDriverSign,
                      signDisabled:
                        !userIsDriver ||
                        form.getFieldValue("fleetAdministratorSignature") !==
                          userConfiguration.nameOfUser,
                      removeDisabled:
                        form.getFieldValue("fleetAdministratorSignature") !==
                        userConfiguration.nameOfUser,
                    }}
                  />
                )}

                {(userIsMechanic || userIsFleetAdmin) && (
                  <SignFieldComponent
                    {...{
                      label: "Mechanic's Signature:  ",
                      formItemName: "mechanicSignSelector",
                      inputType: "select",
                      onSelect: (value) => setSelectedMechanic(value),
                      options: users
                        ?.filter(({ department }) =>
                          compareIncluding(department, "Mechanic")
                        )
                        ?.map(({ name }, i) => (
                          <Select.Option key={i} value={name}>
                            {name}
                          </Select.Option>
                        )),
                      src: selectedMechanicsSign,
                      setSignatureModalVisible,
                      sign: selectedMechanicsSign,
                      setSign: setSelectedMechanicsSign,
                      setWhichSignToChange,
                      existingSignature: selectedMechanicExistingSign,
                      signDisabled:
                        !userIsMechanic ||
                        form.getFieldValue("mechanicSignSelector") !==
                          userConfiguration.nameOfUser,
                      removeDisabled:
                        form.getFieldValue("mechanicSignSelector") !==
                        userConfiguration.nameOfUser,
                    }}
                  />
                )}

                {userIsFleetAdmin && (
                  <SignFieldComponent
                    {...{
                      label: "Fleet Administrator's Signature:  ",
                      formItemName: "fleetAdministratorSignature",
                      inputType: "select",
                      onSelect: (value) => setSelectedReviewingDriver(value),
                      options: fleetAdministrators?.map(({ nameOfUser }, i) => (
                        <Select.Option key={i} value={nameOfUser}>
                          {nameOfUser}
                        </Select.Option>
                      )),
                      src: selectedReviewingDriversSign,
                      setSignatureModalVisible,
                      sign: selectedReviewingDriversSign,
                      setSign: setSelectedReviewingDriversSign,
                      setWhichSignToChange,
                      existingSignature: existingInspReviewerSign,
                      signDisabled:
                        !userIsFleetAdmin ||
                        form.getFieldValue("fleetAdministratorSignature") ===
                          userConfiguration.nameOfUser,
                      removeDisabled:
                        form.getFieldValue("fleetAdministratorSignature") !==
                        userConfiguration.nameOfUser,
                    }}
                  />
                )}
              </div>
            </BorderedTextCard>
          </Form>
        </div>
        <SignatureModal
          visible={signatureModalVisible}
          setVisible={setSignatureModalVisible}
          setSign={whichSignToChange?.setFunc}
          isDarkMode={isDarkMode}
        />
      </FullScreenModal>
      <MaintenanceRequestModal
        visible={maintenanceRequestModalVisible}
        setVisible={setMaintenanceRequestModalVisible}
        setMaintenanceModalVisible={setMaintenanceModalVisible}
        span="Would you like to request maintenance for the selected defective and
        poor items?"
        isDarkMode={isDarkMode}
      />
      {pdfUrl && (
        <FilePreviewModal
          {...{
            visible: pdfUrl,
            setVisible: setPdfUrl,
            file: {
              mimeType: "application/pdf",
              src: `data:application/pdf;base64,${pdfUrl}`,
            },
          }}
        />
      )}
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              onClick={() => setCancelModalVisible(false)}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={onCancel}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      <WarningModal
        visible={deleteWarningModalVisible}
        setVisible={setDeleteWarningModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure you want to delete?</p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              onClick={() => setDeleteWarningModalVisible(false)}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={onDelete}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      {!!maintenanceModalVisible && (
        <MaintenanceModal
          visible={maintenanceModalVisible}
          setVisible={setMaintenanceModalVisible}
          predefinedData={maintenanceModalData}
        />
      )}
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: existingLogs || [],
            title: "Inspection Logs",
          }}
        />
      )}
      {teamsModalVisible && (
        <DynamicTeamModal
          {...{
            open: teamsModalVisible,
            setOpen: setTeamsModalVisible,
            selectedTeam,
            setSelectedTeam,
            ClearOptions() {
              setTeamsModalVisible(false);
            },
            footerDisabled: true,
          }}
        />
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Inspection Tutorial",
          }}
        />
      )}
      {toDoVisible && (
        <ToDoModal
          {...{
            toDoVisible,
            setToDoVisible,
            recordId: inspectionId,
            recordName: fleetName,
            isDarkMode,
          }}
        />
      )}
      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Fleet Inspections",
            actionType: inspectionId ? "Update" : "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisible(false);
              !!!isNextStep &&
                navigate("/fleetInspections", { redirect: true });
            },
          }}
        />
      )}
    </>
  );
};

export default VehicleInspectionModal;
