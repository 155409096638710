import { Tree } from "antd";
import { DownArrow } from "../UserAccessWrites/components/FoldersAccess";
import AccessCheckboxGroup from "../AccessCheckboxGroup/AccessCheckboxGroup";
import { useMemo } from "react";

function AccessTree({
  onReadChange,
  onWriteChange,
  children = [],
  parent,
  disabled,
  isDarkMode,
  onExpand,
  autoExpandParent,
  expandedKeys = [],

  searchRouteConfigs,
}) {
  const treeData = useMemo(() => {
    return createAccessTree({
      children,
      parent,
      disabled,
      onReadChange,
      onWriteChange,
      searchRouteConfigs,
    });
  }, [children, parent, disabled, searchRouteConfigs]);
  return (
    <Tree
      {...{
        expandedKeys,
        switcherIcon: ({ expanded }) => {
          return (
            <DownArrow
              style={{ transform: `rotate(${expanded ? "0deg" : "-90deg"})` }}
            />
          );
        },
        autoExpandParent,
        treeData: treeData,
        onExpand,
        showLine: true,
        className: `access-tree ${isDarkMode && "access-tree-dark"}`,
      }}
    />
  );
}

export default AccessTree;

function createAccessTree({
  children,
  parent,
  disabled,
  onReadChange,
  onWriteChange,
  searchRouteConfigs,
}) {
  return (
    children.map((child) => {
      const { title, children: currChildren } = child;

      return {
        key: title + parent.title,
        title: (
          <AccessCheckboxGroup
            {...{
              onReadChange,
              onWriteChange,
              parent,
              className: "access-tree-radio-group",
              selectedRoute: { ...child, children: null },
              disabled,
              searchRouteConfigs,
            }}
          />
        ),
        children:
          currChildren &&
          createAccessTree({
            children: currChildren,
            onReadChange,
            onWriteChange,
            parent: child,
            disabled,
            searchRouteConfigs,
          }),
      };
    }) || []
  );
}
