import { Core, PdfExport } from "@grapecity/activereports";
import { blobToBase64 } from "../../../../utils";

async function reportToBase64(reportData, pdfExportSettings) {
  let toReturn = {};
  try {
    const openReport = new Core.PageReport();
    await openReport.load(reportData.definition);
    const doc = await openReport.run();
    const result = await PdfExport.exportDocument(doc, pdfExportSettings);

    // URL.createObjectURL(result.data);
    const base = await blobToBase64(result.data);
    toReturn = { res: base, size: result?.data.size, blob: result.data };
  } catch (error) {
    console.error("Error saving report", error);
  }
  return toReturn;
}

export default reportToBase64;
