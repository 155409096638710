import { PlusIcon } from "../../../../../../../../../assets";
import { MondayButton } from "../../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../../Roles/src";

function FolderAccessFooter({
  disabled,
  onClose,
  onSave,
  saveText = "Confirm",
  saveClassName = "mondayButtonGreen",
  Icon = <TickIcon />,
  checkRecords = {},
}) {
  const { visible, onClick, checkDisabled, loading } = checkRecords;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 20,
      }}
    >
      {visible ? (
        <MondayButton
          {...{
            onClick,
            disabled: checkDisabled,
            className: "mondayButtonBlue",
            Icon: <PlusIcon />,
            loading,
          }}
        >
          Check Records
        </MondayButton>
      ) : (
        <>
          <MondayButton
            {...{
              className: "mondayButtonRed",
              Icon: <XIcon />,
              onClick: onClose,
            }}
          >
            Close
          </MondayButton>
          <MondayButton
            {...{
              onClick: onSave,
              Icon,
              disabled,
              className: saveClassName,
            }}
          >
            {saveText}
          </MondayButton>
        </>
      )}
    </div>
  );
}

export default FolderAccessFooter;
