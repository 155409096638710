import { API } from "aws-amplify";
import {
  getCognitosForNotification,
  showLoadingMsg,
  showSuccessMsg,
  updateTeamsConfiguration,
} from "../../../../../utils";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { message } from "antd";
import dayjs from "dayjs";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { v4 as uuidv4 } from "uuid";
import createNewTask from "../../../../SidebarPages/Incidents/Modals/utils/createNewTask";

function initials(nameOfUser) {
  return (
    nameOfUser
      ?.split(" ")
      ?.map((n) => n[0])
      ?.join("")
      ?.toUpperCase() || "AA"
  );
}

const saveHearingFunction = async ({
  getFieldsValue,
  formattedFiles,
  addressSelectionInfo,
  userConfiguration,
  selectedTeam,
  updateProgressStatus,
  setSaving,
  notes,
  mentions,
  mentionIds,
  assignedTo,
  preferences,
  loggedOnTasks,
  driveFunctions,
  selectedClaim,
  selectedClaimData,
  setCancelModalVisible,
  hearingStatus,
  category,
  setVisibleCreationProgress,
  programFields,
  dispatch,
  hideProgress,
  hasCompletedHearing,
  modalTitle,
  crews,
}) => {
  const data = { ...getFieldsValue() };
  const {
    representative,
    hearingTeam,
    time,
    hearingDate,
    foremanSupervisor,
    ...otherValuesWithoutName
  } = data;

  const selectedForeman = !foremanSupervisor
    ? null
    : {
        foremanName: crews?.find((crew) => crew?.crewId === foremanSupervisor)
          ?.crewName,
        foremanId: data?.foremanSupervisor,
      };

  const filteredOther = Object.fromEntries(
    Object.entries(otherValuesWithoutName).filter(
      ([key]) => !key.includes("datePicker")
    )
  );

  // check if is allowed in automations configuration to create task for
  const postCreationFieldOptions = programFields?.find(
    (prog) => prog.fieldName === "Automated Tasks"
  )?.fieldOptions;
  !postCreationFieldOptions.Hearings && hideProgress("creatingTask");
  setVisibleCreationProgress(true);

  const dateAndTime = dayjs
    .tz(`${hearingDate} ${time}`, "MM/DD/YYYY hh:mm A", "America/New_York")
    .format();

  const teamsConfiguration = updateTeamsConfiguration(
    userConfiguration,
    selectedTeam
  );

  if (
    hearingStatus === "Completed" &&
    hasCompletedHearing(selectedClaim.id) === true
  ) {
    setCancelModalVisible("completedHearing");
    return;
  }
  let allFolders = null;
  async function createFolders(parentFolderId) {
    const { folders } = await driveFunctions.create({
      parentFolderName: `${representative} ${dayjsNY().format(
        "DD/MM/YYYY HH:mm:ss"
      )}`,
      parentId: parentFolderId,
    });
    allFolders = folders;
  }
  try {
    if (!driveFunctions.parentFolderId) {
      if (selectedClaimData?.googleDriveFolderIds?.hearingObject) {
        await createFolders(
          selectedClaimData?.googleDriveFolderIds?.hearingObject
        );
      } else {
        await driveRequest
          .getFolderIdOrCreate({
            name: "Hearing",
            parents: [selectedClaimData?.googleDriveFolderIds?.claimObject],
          })
          .then(async (res) => {
            await API.put("claims", `/claims/${selectedClaim.id}`, {
              body: {
                googleDriveFolderIds: {
                  ...selectedClaimData?.googleDriveFolderIds,
                  hearingObject: res,
                },
              },
            }).then(async () => {
              message.success({
                content: "Claim Updated",
                key: "claimUpdate",
              });
              await createFolders(res);
            });
          });
      }
    }
  } catch (error) {
    console.log(error);
  }
  let notesObj = {};
  if (notes !== "" && notes !== "<p><br></p>") {
    notesObj = {
      author: userConfiguration?.nameOfUser,
      cognitoUserId: userConfiguration?.cognitoUserId,
      noteComment: notes,
      createdAt: Date.now(),
      noteId: uuidv4(),
      commentId: uuidv4(),
      category: "General",
      mentions: mentions,
      topicCategory: "Hearings",
      logo: initials(userConfiguration?.nameOfUser),
      published: Date.now(),
      fromSettings: false,
      replies: [],
      repliesVisibility: false,
    };
  } else {
    notesObj = {};
  }

  const newHearingObject = {
    ...filteredOther,
    claimId: selectedClaim.id,
    claimNumber: selectedClaim.name,
    googleDriveUpload: formattedFiles,
    assignedTo,
    time: dateAndTime,
    hearingDate: dateAndTime,
    isDeposition: modalTitle === "Hearing" ? false : true,
    foremanSupervisor: selectedForeman,
  };

  const hearingsObject = {
    ...addressSelectionInfo,
    hearingStatus,
    hearingName: representative,
    claimId: selectedClaim.id || "",
    hearingCategory: category,
    createdBy: {
      name: userConfiguration?.nameOfUser,
      id: userConfiguration?.cognitoUserId,
    },
    hearingObject: newHearingObject,
    googleDriveFolderIds: driveFunctions?.parentFolderId
      ? driveFunctions?.allFolders
      : allFolders,
    teamsConfiguration: [],
  };

  showLoadingMsg();
  setSaving(true);
  updateProgressStatus({ updatingRecord: "executing" });
  await API.post("hearings", "/hearings", { body: hearingsObject })
    .then(async (r) => {
      setVisibleCreationProgress(r); //save hearingId in state to navigate to this id & to generate logs in case of any fail
      updateProgressStatus({
        updatingRecord: "finished",
        sendingNotification: "executing",
      });

      if (postCreationFieldOptions.Hearings) {
        updateProgressStatus({ creatingTask: "executing" });
        await createNewTask({
          category: "Hearings",
          taskAssignedTo: assignedTo?.map((user) => ({
            identityId: user?.identityId,
            cognitoUserId: user.cognitoUserId,
            nameOfUser: user.label,
            userName: user?.userName,
          })),
          taskRelatedTo: r?.hearingName,
          taskTopicId: r?.hearingId,
          taskSubTopic: category,
          userConfiguration,
          calendarPreference: preferences?.calendarPreference,
          loggedOnTasks,
          dispatch,
        })
          .then(() => {
            updateProgressStatus({ creatingTask: "finished" });
          })
          .catch((err) => {
            updateProgressStatus({ creatingTask: "hasError" });
          });
      }

      if (r?.hearingStatus === "Completed") {
        API.put("claims", `/claims/${r?.claimId}`, {
          body: { claimStatus: "Completed" },
        })
          .then((res) => {
            showSuccessMsg({
              content: `${r?.hearingObject?.claimNumber} claim status has been updated to Completed`,
            });
          })
          .catch((e) => console.error(e));
      }

      if (Object.keys(notesObj)?.length > 0) {
        API.post("notes", `/notes`, {
          body: {
            ...notesObj,
            linkTo: `/hearings/${r.hearingId}`,
            recordId: `${r.hearingId}`,
          },
        })
          .then((res) => {
            console.log("notesss", res);
          })
          .catch((e) => console.error(e));
      }

      const filteredAssignedTo =
        (assignedTo || [])?.filter(
          (el) => el.cognitoUserId !== userConfiguration?.cognitoUserId
        ) || [];
      if ((filteredAssignedTo || [])?.length > 0) {
        broadcastNotification(
          "25",
          "onHearingAssignation",
          [
            {
              common: userConfiguration?.nameOfUser,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration.cognitoUserId,
              cognitos:
                filteredAssignedTo?.map((user) => user?.cognitoUserId) || [],
            },
          ],
          r.hearingId
        );
      }
      if (mentions?.length !== 0) {
        broadcastNotification(
          "25",
          "onHearingMention",
          [
            {
              user: userConfiguration?.nameOfUser,
              common: r.hearingName,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration.cognitoUserId,
              cognitos: mentionIds || [],
            },
          ],
          r.hearingId
        );
      }

      showSuccessMsg();
      setSaving(false);

      // await broadcastNotification(
      broadcastNotification(
        "25",
        "onCreation",
        [
          { common: userConfiguration?.nameOfUser },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: userConfiguration.cognitoUserId,
            cognitos:
              getCognitosForNotification(userConfiguration, [], "safety") || [],
          },
        ],
        r.hearingId
      )
        .then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        })
        .catch((error) => {
          console.error("Error sending notification:", error);
        });
    })
    .catch((e) => {
      updateProgressStatus({ updatingRecord: "hasError" });
      console.error(e);
    });
  message.destroy("projectDriveFolders");
  message.destroy("windowMessages");
};

export default saveHearingFunction;
