import { Modal } from "antd";
import { AgGridReact } from "ag-grid-react";
import { useState, useContext } from "react";

import { getCostDispersion } from "../utils";
import DegModalContext from "../DegModalContext";
import { mainColDefs, detailColDefs } from "./costDispersionData";
import { updateDocumentTitle } from "../../../../../../../../utils";
import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";

import "./CostDispersionModal.scss";

const gridOptions = {
  alwaysShowVerticalScroll: true,
  alwaysShowHorizontalScroll: true,
};

const defaultColDef = {
  flex: 1,
  filter: true,
  minWidth: 130,
  sortable: true,
  resizable: true,
  enableRowGroup: false,
  enableColResize: true,
  suppressSizeToFit: true,
  suppressDragLeaveHidesColumns: true,
};

function CostDispersionModal({ open, onCancel }) {
  const { analytics, jobsites, isDarkMode } = useContext(DegModalContext);
  const [rowData] = useState(getCostDispersion({ analytics, jobsites }));

  return (
    <Modal
      centered
      open={open}
      closable={true}
      closeIcon={<XIcon />}
      title={"Cost Distribution"}
      data-testid="cost-distribution-modal"
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Cost Distribution" });
      }}
      onCancel={() => {
        updateDocumentTitle(); // reset document title to "Lead Manager";
        onCancel();
      }}
      className={`costDispersionModal ${
        isDarkMode && "costDispersionModalDark"
      }`}
      footer={
        <MondayButton
          key={"1"}
          Icon={<XIcon />}
          className="mondayButtonRed"
          data-testid="close-cost-distribution-modal-btn"
          onClick={() => {
            updateDocumentTitle(); // reset document title to "Lead Manager";
            onCancel();
          }}
        >
          Close
        </MondayButton>
      }
    >
      <div
        className={`cost-dispersion-grid ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            rowData,
            gridOptions,
            defaultColDef,
            pagination: false,
            animateRows: true,
            masterDetail: true,
            detailRowHeight: 500,
            rowSelection: "multiple",
            columnDefs: mainColDefs(),
            suppressRowClickSelection: true,
            detailCellRendererParams: detailColDefs(),
          }}
        />
      </div>
    </Modal>
  );
}
export default CostDispersionModal;
