import React from "react";
import { message } from "antd";
import { Select } from "antd";
import _ from "lodash";
import { AddonType } from "../../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";

export default class GeneralAddonEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "none",
      visible: false,
      selectOpen: false,
      addonsList: this.props.addonsList,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.data.name) !==
      JSON.stringify(this.props.data.name)
    ) {
      this.setState({ value: this.props.data.name });
    }
  }

  getValue = () => {
    return this.state.value;
  };

  handleSelectChange = (value) => {
    if (this.props.isWritable) {
      this.setState({ value: value });
      //   const copiedAddonsList = _.cloneDeep(this.state.addonsList)
      //   const selectedIndex = copiedAddonsList.findIndex(addon => addon.id === JSON.parse(value).id)
      //   var addonsListWithoutSelected = copiedAddonsList.filter((data, index) => index !== selectedIndex );
      //   this.setState({addonsList: addonsListWithoutSelected})

      if (
        this.props.addonType === AddonType.serviceAddon ||
        this.props.addonType === "documentation"
      ) {
        //if it is an addon for service, save it to parent
        this.props.onAddonChange(
          this.props.data.id,
          this.props.api,
          JSON.parse(value)
        );
      } else {
        message.info("Addon saved to PLI");
        console.log("value-editor", this.props);
        this.props.onAddonChange(this.props, JSON.parse(value));
      }
    } else {
      message.error("Please enable write mode to start editing");
    }

    this.stopEditing(true);
  };

  handleSelectBlur = () => {
    this.setState({
      selectOpen: false,
    });
  };

  stopEditing = () => {
    setTimeout(() => {
      this.props.api.stopEditing();
    }, 100);
  };

  handleClose = () => {
    this.setState({ selectOpen: false });
  };

  handleOpen = () => {
    this.setState({ selectOpen: true });
  };

  render() {
    //     console.log(this.state.addonsList)
    console.log("GeneralAddonEditor -> render -> this.props", this.props);

    const selectId = Math.random().toString();
    console.log("aValues", this.props);

    // if(!this.props.isWritable) message.error("Please enable write mode to start editing.")
    return (
      <div
        style={{
          width: "100%",
          minWidth: "280px",
          height: "100%",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        {console.log(this)}
        <Select
          // variant="filled"
          disabled={!this.props.isWritable}
          id={selectId}
          className="dataEntryGrid-cell-select"
          // open={this.state.selectOpen}
          style={{ color: "#37474F", width: "100%", height: "100%" }}
          value={
            this.props.data.name ? JSON.stringify(this.props.data.name) : "none"
          }
          dropdownStyle={{ minWidth: "auto" }}
          onChange={this.handleSelectChange}
          onClose={this.handleClose}
          // onOpen={this.handleOpen}
        >
          <Select.Option value="none" disabled>
            Choose addon:
          </Select.Option>
          {this.state.addonsList
            ?.filter((el) => el?.addonType !== "service addon")
            .map((a, i) => {
              let addonName =
                a.values.find(
                  (addonRow) => addonRow.name.toLowerCase() === "name"
                )?.value || "unnamed";
              console.log("addonName", {
                addonName,
                a,
              });
              return (
                <Select.Option
                  key={i}
                  value={JSON.stringify(a)}
                  style={{
                    width: "100%",
                  }}
                >
                  {addonName}
                </Select.Option>
              );
            })}
        </Select>
      </div>
    );
  }
}
