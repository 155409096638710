import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, message } from "antd";
import dayjs from "dayjs";

import { useRedux } from "../../../hooks";
import WarningAmount from "./WarningAmount";
import { ReceiptIcon } from "../../../../../assets";
import { FormCard } from "../../../Fleet/components";
import { debounceSearch } from "../../../../../utils";
import { useProgramFields } from "../../../../../hooks";
import { ToggleSwitch } from "../../../../commonComponents";
import { fieldsCreditJSON, fieldsJSON } from "../utils/fieldsJSON";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useBillCreditContext } from "../context/BillCreditProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";
import { RenderDynamicComponents } from "../../../../Header/forms/Components";
import { forceToNumber } from "../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { addBillCategory } from "../../../../pages/Settings/settingsComponents/BillsConfiguration/BillItems/utils/addBillCategory";

const FIELD_NAME = "Bill Categories";

const BillForm = () => {
  const [isWritable] = useRedux("billIsWritable");

  const { isBill, setIsBill } = useBillCreditContext();

  const { programFields = [] } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [name, setName] = useState("");
  const inputRef = useRef(null);

  const billCategories = programFields.find(
    (prog) => prog.fieldName === FIELD_NAME
  )?.fieldOptions;

  const dispatch = useDispatch();

  const {
    rowData,
    projects,
    setRowData,
    onSearchChangeHandler,
    setSelectedProject,
    selectedProject,
    isProjectLoading,
    setSelectedServices,
  } = useCreateBillContext();

  const { form, billData, vendors, vendor, project, services, setVendor } =
    useInitialBillContext();

  const { ["Bill Terms"]: terms, ["Bill Categories"]: categories } =
    useProgramFields();

  const onTermChange = (value) => {
    const selectedTerm = terms.find((term) => term.name === value);

    if (selectedTerm) {
      const billDate =
        form.getFieldValue("billDate") ||
        dayjs(new Date()).format("MM/DD/YYYY");
      const billDueDate = dayjs(billDate)
        .add({ days: selectedTerm.days })
        .format("MM/DD/YYYY");

      form.setFieldsValue({
        billDate,
        billDueDate,
      });
    }
  };

  const onProjectSearch = debounceSearch(onSearchChangeHandler, 300);

  const onProjectSelect = () => {
    const projectName = form.getFieldValue("projectName");
    const project = projects.find(
      (project) => project.projectName === projectName
    );

    setSelectedProject(project);

    setRowData((prev) =>
      prev.map((row) => {
        if (!!!row.projectName)
          return {
            ...row,
            projectName: project.projectName,
            projectId: project.projectId,
          };

        return row;
      })
    );
  };

  const onServiceSelection = (_, data, isAdded) => {
    const { estimationId, serviceId, label: serviceName } = data;

    if (isAdded) {
      const newService = {
        estimationId,
        serviceId,
        serviceName,
      };
      setSelectedServices((prev) => [...prev, newService]);
      return;
    }

    setSelectedServices((prev) =>
      prev.filter(
        (service) =>
          !(
            service.serviceId === data.serviceId &&
            service.estimationId === data.estimationId
          )
      )
    );
  };

  const onAmountChange = (event) => {
    const value = forceToNumber(event.target.value);
    const appliedAmount = rowData.reduce((acc, row) => (acc += row.total), 0);
    const remainingValue = value - appliedAmount;

    if (remainingValue < 0) {
      message.error("You have applied more amount than bill amount.");
      form.setFieldValue("billAmount", value - remainingValue);
      return;
    }

    setRowData((prev) => {
      const totalRowsLength = prev.filter((row) => row.total === 0).length;
      const taxReverse = vendor?.vendorTaxRate ? 1 + vendor?.vendorTaxRate : 0;
      const totalValue = remainingValue / totalRowsLength;

      return prev.map((row) => {
        if (row.total > 0) return row;

        const amount = taxReverse > 0 ? totalValue / taxReverse : totalValue;
        const taxAmount = amount * (vendor?.vendorTaxRate || 0);

        return {
          ...row,
          amount,
          taxAmount,
          total: amount + taxAmount,
          billable: taxAmount > 0,
          tax: taxAmount > 0,
        };
      });
    });
  };

  const onVendorSelection = (_, option) => {
    setVendor(option);
    form.setFieldValue("vendorTaxRate", option?.vendorTaxRate * 100 || 0);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  // Add new Bill Category
  const addItem = async (e) => {
    e.preventDefault();

    await addBillCategory({
      billCategories,
      categories: categories.map((item) => item.name),
      name,
      setName,
      userConfiguration,
      dispatch,
    });
  };

  const inputBillFields = fieldsJSON({
    terms,
    vendor,
    vendors,
    project,
    services,
    projects,
    billData,
    categories,
    isWritable,
    onTermChange,
    onProjectSearch,
    onProjectSelect,
    selectedProject,
    isProjectLoading,
    onServiceSelection,
    onAmountChange,
    onVendorSelection,
    form,
    inputRef,
    name,
    onNameChange,
    addItem,
  });

  const inputCreditFields = fieldsCreditJSON({
    form,
    billData,
    isWritable,
    vendors,
    rowData,
    isBill,
  });
  const onToggleSwitchHandler = () => setIsBill((prev) => !prev);
  const toggleLabel = isBill ? "Bill" : "Credit";

  return (
    <>
      <Form form={form} className="new-bill-form-container">
        <FormCard
          childrenClassName="new-bill-form-card"
          title={
            <div className="new-bill-form-title">
              {toggleLabel} Information
              <ToggleSwitch
                checked={isBill}
                onChange={onToggleSwitchHandler}
                variant="light"
                icon={<ReceiptIcon />}
                label={toggleLabel}
                disabled={!isWritable && billData}
              />
            </div>
          }
        >
          {RenderDynamicComponents(
            isBill ? inputBillFields : inputCreditFields
          )}
        </FormCard>
        <WarningAmount />
      </Form>
    </>
  );
};

export default BillForm;
