import { useSelector } from "react-redux";
import { Form, message, Modal } from "antd";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { PlusIcon } from "../../../../../../../assets";
import { getDateRange } from "../modalComponents/utils";
import { formFieldsList } from "./multipleHrShiftsModalData";
import {
  teamShiftColumns,
  createShiftsForCrews,
} from "../modalComponents/TeamShiftsModal/teamShiftModalData";
import DegModalContext from "../modalComponents/DegModalContext";
import { useEntriesApi } from "../../../../../PayrollLive/utils";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { MondayButton, WarningModal } from "../../../../../../commonComponents";
import { TickIcon } from "../../../../../Settings/settingsComponents/Roles/src";
import { WarningTriangle } from "../../../../../../SidebarPages/DynamicView/src";
import { RenderDynamicComponents } from "../../../../../../Header/forms/Components";
import { setHourMinute } from "../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./MultipleHrShiftsModal.scss";

function MultipleHrShiftsModal({ open, onCancel }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const {
    crews,
    jobsites,
    crewTeams,
    setRowData,
    // degGridApi,
    // currentStep,
    // addEntryAction,
  } = useContext(DegModalContext);

  const [gridApi, setGridApi] = useState(false);
  const [warningOnClose, setWarningOnClose] = useState(false);

  const [form] = Form.useForm();
  const { loading, postEntries } = useEntriesApi();

  const onGridReady = useCallback(({ api }) => {
    setGridApi(api);
  }, []);

  const fieldsList = useMemo(() => {
    return formFieldsList({ crews, form, onRegularShift });
  }, [crews]);

  function onRegularShift(e) {
    const checked = e.target.checked;

    const clockIn = dayjsNY().set("hour", 7).set("minutes", 0);
    const clockOut = dayjsNY().set("hour", 15).set("minutes", 0);

    if (checked) {
      form.setFieldValue("selectedTime", clockIn);
      form.setFieldValue("selectedEndTime", clockOut);
    } else {
      form.setFieldValue("selectedTime", null);
      form.setFieldValue("selectedEndTime", null);
    }
  }

  /**
   * Creates HR shifts for all the dates inside the date range
   * for each selected employee
   */
  function onSave() {
    form.validateFields().then(async (formValue) => {
      message.loading({
        duration: 0,
        key: "multipleHr",
        content: "Adding entries...",
      });
      const startDate = formValue.selectedDates[0];
      const endDate = formValue.selectedDates[1];

      const selectedTime = formValue?.selectedTime;
      const selectedEndTime = formValue?.selectedEndTime;

      const allDatesInRange = getDateRange(startDate, endDate);
      let newHrShifts = [];

      for (const date of allDatesInRange) {
        const formData = {
          ...formValue,
          selectedDate: date,
          shiftType: "HR Shift",
          selectedJobsite: undefined,
          selectedTime: setHourMinute(date, selectedTime),
          selectedEndTime: setHourMinute(date, selectedEndTime),
        };

        await createShiftsForCrews({
          crews,
          jobsites,
          form: formData,
        }).then(({ newShifts }) => {
          newHrShifts = newHrShifts.concat(newShifts);
        });
      }

      // if (currentStep === 1) {
      //   degGridApi.applyTransaction({ addIndex: 0, add: newHrShifts });
      //   addEntryAction({ type: "new", entry: newHrShifts });
      // } else {
      const newEntries = newHrShifts.map((el) => ({
        ...el,
        degId: rowToEdit.degId,
      }));
      postEntries({
        entries: newEntries,
        onSuccessCallback: () => {
          message.success({
            duration: 3,
            key: "multipleHr",
            content: "Entries added successfully!",
          });
          setRowData((prev) => [...newHrShifts, ...prev]);
          onCancel();
        },
        onErrorCallback: (err) => {
          console.log("Error adding hr entries: ", err);
          message.error({
            duration: 3,
            key: "multipleHr",
            content: "There was a problem adding entries!",
          });
        },
      });
      // addEntryAction({ type: "new", entry: newHrShifts });
      // }
    });
  }

  useEffect(() => {
    function handleSelectionToggle(param) {
      if ((param?.source || "").includes("checkbox")) {
        let isSelected = param.node.isSelected();
        let selectedEmployees = form.getFieldValue("selectedMembers") || [];

        let employeesToChange = [];

        if (param.data?.["crewForeman"]?.["crewId"]) {
          const foreman = crews.find(
            ({ crewId }) => crewId === param.data?.crewForeman?.crewId
          );
          if (!!foreman?.crewId) {
            employeesToChange.push(foreman?.crewId);
          }
        }
        for (const crew of param.data.crewMembers) {
          if (crew?.["crewId"]) {
            employeesToChange.push(crew?.["crewId"]);
          }
        }

        if (isSelected) {
          for (const crewId of employeesToChange) {
            if (!selectedEmployees.includes(crewId)) {
              selectedEmployees = [...selectedEmployees, crewId];
            }
          }

          form.setFieldValue("selectedMembers", selectedEmployees);
        } else {
          form.setFieldValue(
            "selectedMembers",
            selectedEmployees.filter(
              (crewId) => !employeesToChange.includes(crewId)
            )
          );
        }
      }
    }

    if (gridApi) {
      gridApi.addEventListener("rowSelected", handleSelectionToggle);
    }

    return () => {
      if (gridApi) {
        gridApi.removeEventListener("rowSelected", handleSelectionToggle);
      }
    };
  }, [gridApi, form]);

  return (
    <Modal
      open={open}
      onCancel={() =>
        form.isFieldsTouched() ? setWarningOnClose(true) : onCancel()
      }
      closable={true}
      centered={true}
      closeIcon={<XIcon />}
      destroyOnClose={true}
      title={"Add Multiple Hr Shifts"}
      className={`multipleHrShiftsModal ${
        isDarkMode && "multipleHrShiftsModalDark"
      }`}
      footer={[
        <MondayButton
          Icon={<XIcon />}
          disabled={loading}
          className="mondayButtonRed"
          onClick={() =>
            form.isFieldsTouched() ? setWarningOnClose(true) : onCancel()
          }
        >
          Cancel
        </MondayButton>,
        <MondayButton disabled={loading} Icon={<PlusIcon />} onClick={onSave}>
          Add Shifts
        </MondayButton>,
      ]}
    >
      <Form form={form}>
        <section className="employeeSelection">
          {RenderDynamicComponents(fieldsList["Employees"], form)}
        </section>
        <section className="shiftDetails">
          <section className="detailsSection">
            {RenderDynamicComponents(
              fieldsList["Shift Details"].slice(0, 2),
              form
            )}
          </section>
          <section className="detailsSection">
            {RenderDynamicComponents(
              fieldsList["Shift Details"].slice(2, 4),
              form
            )}
          </section>
          <section className="detailsSection">
            {RenderDynamicComponents(
              fieldsList["Shift Details"].slice(4, 6),
              form
            )}
          </section>
        </section>
      </Form>
      <div
        className={`selection-grid-container ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            gridApi,
            onGridReady,
            pagination: false,
            rowData: crewTeams,
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            columnDefs: teamShiftColumns
              .slice(0, 2)
              .concat(teamShiftColumns.slice(3)),
            defaultColDef: {
              flex: 1,
              filter: true,
              minWidth: 130,
              sortable: true,
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              suppressSizeToFit: true,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
      {warningOnClose && (
        <WarningModal
          closable={true}
          title="Warning Message"
          visible={warningOnClose}
          setVisible={setWarningOnClose}
          className="logout-warning-modal shiftsBreakWarning"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>
              Closing this window will result in any unsaved changes being lost.
              Are you sure you want to proceed?
            </p>
            <div className="buttons" style={{ gap: 10 }}>
              <MondayButton
                onClick={() => {
                  setWarningOnClose(false);
                }}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={onCancel}
                Icon={<TickIcon width={17} height={17} />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </Modal>
  );
}

export default MultipleHrShiftsModal;
