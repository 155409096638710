import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Space } from "antd";

export const bodyObject = ({ form }) => ({
  itemName: form.getFieldValue("itemName"),
  itemCategory: form.getFieldValue("itemCategory"),
  itemDescription: form.getFieldValue("itemDescription"),
  itemService: form.getFieldValue("itemService"),
});

export const fieldsJson = (
  billCategoriesOptions,
  services,
  inputRef,
  name,
  onNameChange,
  addItem
) => {
  const allServices = Array.from(
    new Set(services.map((service) => service.serviceName))
  ).map((service) => ({
    label: service,
    value: service,
  }));

  return [
    {
      label: "Name",
      formItemName: "itemName",
      placeholder: "Name",
      type: "input",
      required: true,
    },
    {
      label: "Category",
      formItemName: "itemCategory",
      placeholder: "Category",
      type: "select",
      required: true,
      customOptions: billCategoriesOptions,
      dropdownRender: (menu) => {
        return (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <Space
              style={{
                padding: "0 8px 4px",
              }}
            >
              <Input
                placeholder="Enter Category"
                ref={inputRef}
                value={name}
                onChange={onNameChange}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button
                type="text"
                icon={<PlusOutlined />}
                onClick={addItem}
                disabled={name.trimStart().length < 1}
              >
                Add Category
              </Button>
            </Space>
          </>
        );
      },
    },
    {
      label: "Service",
      formItemName: "itemService",
      placeholder: "Service",
      type: "select",
      required: true,
      customOptions: allServices,
    },
    {
      label: "Description",
      formItemName: "itemDescription",
      placeholder: "Description",
      type: "textarea",
      required: true,
      rows: 5,
    },
  ];
};
