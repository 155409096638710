import { generateRouteImage } from "../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dispatchPdfData";

/** @type {google.maps.places.PlacesService} */
let placesServices = null;
async function loadService() {
  if (
    !window?.google?.maps ||
    !google.maps.importLibrary ||
    "function" !== typeof google.maps.importLibrary
  ) {
    return;
  }

  try {
    /** @type {google.maps.PlacesLibrary} */
    const lib = await google.maps.importLibrary("places");

    placesServices = new lib.PlacesService(document.createElement("div"));
  } catch (err) {
    console.error("Error loading places library: ", err);
  }
}

loadService();

/**
 * Retrieves nearby places based on the given coordinates, radius, and type.
 *
 * @param {Object} coordinates - The coordinates of the location.
 * @param {number} radius - The radius within which to search for nearby places.
 * @param {string} type - The type of places to search for.
 * @returns {Promise<Array>} - A promise that resolves to an array of nearby places.
 */
async function getNearbyPlaces(coordinates, radius, type) {
  if (!placesServices) {
    await loadService();
  }

  try {
    let nextPageToken = null;
    if (!placesServices) {
      return [];
    }

    return await new Promise((resolve, reject) => {
      placesServices?.nearbySearch(
        {
          location: coordinates,
          radius,
          type,
          pageToken: nextPageToken,
        },
        (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(results);
          } else {
            reject(`Error fetching places: ${status}`);
          }
        }
      );
    });
  } catch (error) {
    console.error("getNearbyPlaces", error);
    return [];
  }
}

async function getPlaceDetails(place) {
  try {
    const placesServices = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    if (!placesServices) {
      return {};
    }
    if (!placesServices?.getDetails) {
      return {};
    }
    return new Promise((resolve, reject) => {
      try {
        placesServices?.getDetails(
          { placeId: place.place_id },
          (details, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              resolve(details);
            } else {
              reject(`Error fetching place details: ${status}`);
            }
          }
        );
      } catch (error) {
        console.error("getPlaceDetails", error);
        return {};
      }
    });
  } catch (error) {
    console.error("getPlaceDetails", error);
    return {};
  }
}
async function getDistance(origin, destination) {
  try {
    const service = new google.maps.DistanceMatrixService();
    if (!service) {
      return {};
    }
    if (!service?.getDistanceMatrix) {
      return {};
    }

    return await new Promise((resolve, reject) => {
      try {
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.IMPERIAL,
          },
          (response, status) => {
            if (status === google.maps.DistanceMatrixStatus.OK) {
              const distance = response.rows[0].elements[0];
              resolve(distance);
            } else {
              reject(`Error fetching distance: ${status}`);
            }
          }
        );
      } catch (error) {
        console.error("getDistance", error);
        return {};
      }
    });
  } catch (error) {
    console.error("getDistance", error);
    return {};
  }
}

async function getWalkingDistance(origin, destination) {
  try {
    const service = new google.maps.DistanceMatrixService();
    if (!service) {
      return {};
    }
    if (!service?.getDistanceMatrix) {
      return {};
    }

    return await new Promise((resolve, reject) => {
      try {
        service?.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: google.maps.TravelMode.WALKING,
            unitSystem: google.maps.UnitSystem.IMPERIAL,
          },
          (response, status) => {
            if (status === google.maps.DistanceMatrixStatus.OK) {
              const distance = response.rows[0].elements[0];
              resolve(distance);
            } else {
              reject(`Error fetching distance: ${status}`);
            }
          }
        );
      } catch (error) {
        console.error("getWalkingDistance", error);
        return {};
      }
    });
  } catch (error) {
    console.error("getWalkingDistance", error);
    return {};
  }
}

/**
 * Retrieves nearby places with additional details.
 *
 * @param {Object} coordinates - The coordinates of the location.
 * @param {number} radius - The radius within which to search for nearby places.
 * @param {string} type - The type of places to search for.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of nearby places with details.
 */
async function getNearbyPlacesWithDetails(coordinates, radius, type) {
  try {
    const places = await getNearbyPlaces(coordinates, radius, type).catch(
      (err) => {
        console.error("getNearbyPlacesWithDetails ~ err:", { err });
        return [];
      }
    );
    const placesWithDetails = await Promise.all(
      places
        ?.filter((el) => el?.business_status === "OPERATIONAL")
        ?.map(async (place) => {
          const {
            photos,
            geometry,
            html_attributions,
            reference,
            opening_hours,
            ...rest
          } = place;
          const placeLocation = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };

          const [details, drivingDistance, walkingDistance, directionImage] =
            await Promise.all([
              getPlaceDetails(place),
              getDistance(coordinates, placeLocation),
              getWalkingDistance(coordinates, placeLocation),
              generateRouteImage({
                origin: coordinates,
                destination: placeLocation,
              }),
            ]);

          return {
            ...rest,
            name: details.name,
            address: details.formatted_address,
            phone: details.international_phone_number,
            hours: details.opening_hours
              ? details.opening_hours.weekday_text
              : null,
            distance: {
              walking: walkingDistance,
              driving: drivingDistance,
            },
            directionImage,
          };
        })
    );
    return placesWithDetails.sort(
      (a, b) =>
        a.distance.driving?.distance?.value -
        b.distance.driving?.distance?.value
    );
  } catch (error) {
    console.log("general error handling", error);
    return [];
  }
}

export default getNearbyPlacesWithDetails;
