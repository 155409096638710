import { useSelector } from "react-redux";
import { convertMinutesToSeconds } from "../../utils/convertMinutesToSeconds";
import "./Transcription.scss";

const Transcription = ({ video, handleTextClick, currentTime }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const timeDuration = convertMinutesToSeconds(video.lengthVideoInMin);
  const newText = video.transcribedText;

  const splitText = newText
    ?.split(/[\.]/)
    .filter((item) => item.trimStart().length > 0);

  const segmentDuration = timeDuration / splitText.length;

  return (
    <div
      className={`video-transcription ${
        isDarkMode && "video-transcriptionDark"
      }`}
    >
      {splitText?.map((item, index) => {
        const startTime = index * segmentDuration;
        const endTime = (index + 1) * segmentDuration;
        const isActive = currentTime >= startTime && currentTime <= endTime;

        return (
          <div
            key={index}
            className={`section ${isActive && "active-section"}`}
          >
            <span className="text" onClick={() => handleTextClick(startTime)}>
              {item.trimStart()}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Transcription;
