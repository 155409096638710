import { Dayjs } from "dayjs";
import { groupBy } from "lodash";
import { utils, write } from "xlsx";
import { useSelector } from "react-redux";
import { Form, FormInstance, message, Tooltip } from "antd";
import { UIEvent, useMemo, useState, useContext } from "react";

import { ExcelIcon } from "src/assets";
import { convertSecondsToTime } from "../../utils";
import { EntryType } from "../../payrollLiveTypes";
import PayrollLiveContext from "../../PayrollLiveContext";
import { MondayButton } from "../../../../commonComponents";
import {
  StoreType,
  JobsiteType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import CardComponent from "src/components/CardComponent/CardComponent";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { withinRadius } from "../../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./UnmatchedEntries.scss";

interface Props {
  form: FormInstance<{ searchField: string; reportDate?: Array<Dayjs> }>;
}

const initialIndex = {
  start: 0,
  end: 15,
};

const punchTypeOptions = [
  { label: "ID", value: "ID", key: 0 },
  { label: "OL", value: "OL", key: 1 },
  { label: "IL", value: "IL", key: 2 },
  { label: "OD", value: "OD", key: 3 },
];

function UnMatchedEntries(props: Props) {
  const { form } = props;
  const {
    mapRef,
    jobsites,
    schedules,
    crewTeams,
    degEntries,
    setFilters,
    onPageSelect,
    setEmployeesInfo,
  } = useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [punchTypeFilter, setPunchTypeFilter] = useState<Array<string>>([]);
  const [sliceIndex, setSliceIndex] = useState<{ start: number; end: number }>(
    initialIndex
  );

  const punchEnd = Form.useWatch("punchEnd", form);
  const punchStart = Form.useWatch("punchStart", form);
  const searchField = Form.useWatch("searchField", form);

  function onScroll(e: UIEvent<HTMLElement>): void {
    const scrollStep = 10;
    const maxStartIndex = unmatchedEntries.length - scrollStep;
    const maxEndIndex = unmatchedEntries.length - 1;
    let indexPosition = e.currentTarget.scrollTop / 140;

    if (indexPosition >= 6.5 && sliceIndex.end === scrollStep) {
      setSliceIndex(() => ({
        start: 0,
        end: 15 + scrollStep,
      }));
    }

    if (indexPosition >= 18 && sliceIndex.end !== maxEndIndex) {
      setSliceIndex((prev) => {
        let startIndex = prev.start + scrollStep;
        let endIndex = prev.end + scrollStep;
        return {
          start: startIndex > maxStartIndex ? maxStartIndex : startIndex,
          end: endIndex > maxEndIndex ? maxEndIndex : endIndex,
        };
      });
    }

    if (indexPosition <= 0.5) {
      setSliceIndex((prev) => {
        return {
          start: prev.start >= scrollStep ? prev.start - scrollStep : 0,
          end: prev.end > scrollStep ? prev.end - scrollStep : scrollStep,
        };
      });
    }
  }

  function onEmpClick(entry: EntryType) {
    onPageSelect("map");
    setFilters((prev) => ({
      ...prev,
      schedules: [],
      liveStatus: [],
      employeeSearch: "",
      employeeNumber: [],
    }));
    const employeeNumber = Number((entry?.employeeId || "").split("-")[1]);
    setTimeout(() => {
      if (employeeNumber) {
        setEmployeesInfo(`${employeeNumber}`);
      }
      if (mapRef?.current?.fitBoundsToMarkers) {
        mapRef.current.fitBoundsToMarkers([entry?.punchCoordinates]);
      }
    }, 800);
  }

  function onExcelExport() {
    setExportDisabled(true);
    message.loading({ key: "excelExport", content: "Loading", duration: 0 });
    if (!unmatchedData?.length) {
      message.warning({
        key: "excelExport",
        content: "There are no data to export.",
        duration: 2,
      });
      setExportDisabled(false);
      return;
    }

    try {
      const workbook = utils.book_new();
      const groupedData = groupBy(unmatchedData, "punchType");
      for (const punchType in groupedData) {
        const sheetData = (groupedData?.[punchType] || []).map((el) => {
          const teamIndex = crewTeams.findIndex(
            (team) => team?.crewTeamName === el?.crewTeamName
          );

          const employeeId = Number((el?.employeeId || "").split("-")?.[1]);
          return {
            "Employee Id": employeeId || "",
            Foreman: crewTeams?.[teamIndex]?.crewForeman?.crewName || "",
            "Employee Name": el?.employeeFullName || "",
            Crew: el?.crewTeamName || "",
            "Punch Time": el?.punchTime
              ? dayjsNY(el?.punchTime).format("hh:mm A")
              : "",
            "Punch Location": el?.punchLocation || "",
            "Job Assigned": el?.jobsiteMatch.jobAddress || "",
            Distance: el?.distanceFromJob ? `${el?.distance} ft` : "",
            Duration: Number(el?.duration)
              ? convertSecondsToTime(Math.round(el?.duration))
              : el?.duration || "",
            Notes: "",
          };
        });

        const workSheet = utils.json_to_sheet(sheetData);
        utils.book_append_sheet(workbook, workSheet, punchType);
      }
      const fileTitle = `Unmatched Punches ${parseInTz().format("MM-DD-YYYY")}`;

      const excelBufferNoJob = write(workbook, {
        bookType: "xls",
        type: "base64",
      });

      const file = {
        name: fileTitle + ".xls",
        blob: `base64,${excelBufferNoJob}`,
        type: "application/vnd.ms-excel",
      };

      const url = `data:${file.type};${file.blob}`;
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setExportDisabled(false);
      message.success({
        key: "excelExport",
        content: "File exported successfully!",
        duration: 2,
      });
    } catch (error) {
      setExportDisabled(false);
      message.error({
        key: "excelExport",
        content: "Could not create excel file.",
        duration: 2,
      });
    }
  }

  const unmatchedData = useMemo(() => {
    let returnData = [];
    for (let i = 0; i < degEntries?.length; i++) {
      ``;
      const entry = degEntries[i];
      let pass = false;

      if (entry?.activityStatus === "Completed") {
        continue;
      }

      if (entry?.distanceFromJob <= 300 || entry?.punchType === "HR") {
        if (
          (!entry?.distanceFromJob && entry?.punchType !== "HR") ||
          (!entry?.jobsiteMatch?.jobsiteId && entry?.punchType !== "HR")
        ) {
          pass = true;
        } else {
          continue;
        }
      } else {
        pass = true;
      }

      if (
        punchTypeFilter?.length &&
        !punchTypeFilter.includes(entry?.punchType)
      ) {
        continue;
      }

      const jobIndex = jobsites.findIndex(
        (job) => job.jobsiteId === entry?.jobsiteId
      );
      let jobMatch: JobsiteType = undefined;
      if (entry?.jobsiteId) {
        jobMatch = jobsites?.[jobIndex];
      }

      const unMatchEntry = { ...entry };
      if (jobMatch) {
        const distanceData = withinRadius(
          entry?.punchCoordinates,
          jobMatch?.addressPosition
        );
        const duration = Math.round(distanceData?.distanceInFeet / 4.7);

        Object.assign(unMatchEntry, {
          jobsiteAddress: jobMatch.jobAddress,
          distance:
            (entry?.punchCoordinates?.lat &&
              jobMatch.addressPosition?.lat &&
              distanceData?.distanceInFeet) ||
            0,
          duration:
            entry?.punchCoordinates?.lat &&
            jobMatch.addressPosition?.lat &&
            convertSecondsToTime(duration),
        });
      }

      if (
        searchField?.length &&
        !unMatchEntry?.employeeFullName
          ?.toLowerCase()
          ?.includes(searchField.toLowerCase())
      ) {
        if (sliceIndex.start) {
          setSliceIndex(initialIndex);
        }
        pass = false;
      }

      if (punchStart) {
        pass =
          parseInTz(unMatchEntry?.punchTime).valueOf() >=
          punchStart?.startOf("m")?.valueOf?.();
      }

      if (punchEnd) {
        pass =
          parseInTz(unMatchEntry?.punchTime).valueOf() <=
          punchEnd?.startOf("m")?.valueOf?.();
      }

      if (pass) {
        returnData.push(unMatchEntry);
      }
    }

    return returnData;
  }, [
    jobsites,
    punchEnd,
    schedules,
    punchStart,
    degEntries,
    searchField,
    punchTypeFilter,
  ]);

  const unmatchedEntries = useMemo(() => {
    return unmatchedData.map((entry: EntryType) => {
      const distance =
        entry?.distanceFromJob >= 5280
          ? `${Number(entry?.distanceFromJob / 5280).toFixed(2)} miles`
          : `${Number(entry?.distanceFromJob).toFixed(2)} ft`;
      return (
        <div
          key={entry?.entryId}
          className={`unmatched-card ${
            entry?.jobsiteId ? "no-schedule-match" : ""
          }`}
        >
          <div className="unmatched-header">
            <Tooltip title="Open entry">
              <label onClick={() => onEmpClick(entry)}>
                {entry?.employeeFullName} - {entry?.punchType}
              </label>
            </Tooltip>
          </div>
          <div className="unmatched-body">
            <div className="main-data-container">
              <div className="data-row">
                <span>Punch Time: </span>
                <div>{dayjsNY(entry?.punchTime).format("hh:mm A")}</div>
              </div>
              <div className="data-row">
                <span>Punch Location:</span>
                <div>{entry?.punchLocation}</div>
              </div>
              <div className="data-row">
                <span>Designated Job:</span>
                <div>{entry?.jobsiteMatch?.jobAddress}</div>
              </div>
            </div>
            <div className="distance-data">
              <div className="data-row">
                <span>Distance:</span>
                <div>{distance}</div>
              </div>
              <div className="data-row">
                <span>Duration:</span>
                <div>{entry?.duration}</div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }, [unmatchedData]);

  return (
    <CardComponent
      className="unmatched-entries-card"
      title={
        <div className="custom-header">
          <div style={{ display: "flex", gap: 20 }}>
            <label>Jobsite Unmatched Entries</label>
            <InputComponent
              type="select"
              mode="multiple"
              value={punchTypeFilter}
              formItemName="groupStatus"
              placeholder="Filter punch type"
              customOptions={punchTypeOptions}
              onChange={(e) => setPunchTypeFilter(e)}
            />
          </div>
          <Tooltip title="Export Unmatched Entries">
            <MondayButton
              onClick={onExcelExport}
              disabled={exportDisabled}
              Icon={<ExcelIcon height={23} width={23} />}
              className={darkMode ? "mondayButtonBlue" : "mondayButtonWhite"}
            />
          </Tooltip>
        </div>
      }
    >
      <>
        <section className="card-content" onScroll={onScroll}>
          {unmatchedEntries?.length ? (
            unmatchedEntries.slice(sliceIndex.start, sliceIndex.end)
          ) : (
            <div className="empty-unmatched-entries">
              No Jobsite Unmatched Entries
            </div>
          )}
        </section>
        <div className="unmatched-count">
          {unmatchedEntries?.length || 0} Total unmatched entries
        </div>
      </>
    </CardComponent>
  );
}

export default UnMatchedEntries;
