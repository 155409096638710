import { useEffect, useState } from "react";
import { ConfigContent, Header, HoistPriceItemModal } from "./components";
import { HoistPricingContext } from "./context";
import { hoistTypeBySwitchState } from "./data";
import "./HoistPricingConfig.scss";
import { useSelector } from "react-redux";
import { MondayButton, WarningModal } from "../../../../../../commonComponents";
import { WarningTriangle } from "../../../../../../SidebarPages/DynamicView/src";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../Roles/src";
import { usePricingContext } from "../../../PricingV2/context";

const HoistPricingConfig = ({ hoistPricingData = {} }) => {
  const {
    setPricingObject,
    setSelectedScheme,
    getUsedIn,
    setEstimationModal,
    label,
  } = usePricingContext();
  const { base64 } = useSelector((state) => state.base64);

  const { priceSchemes = [] } = hoistPricingData || {};
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [layout, setLayout] = useState([]);
  const [editable, setEditable] = useState(false);
  const [saveGridChanges, setSaveGridChanges] = useState(false);

  // State of the priceSchemeSelector
  const [selectedPriceSchemeID, setSelectedPriceSchemeID] = useState(
    hoistPricingData?.defaultPriceSchemeId
      ? priceSchemes?.find((item) => {
          return item.id === hoistPricingData?.defaultPriceSchemeId;
        }).id
      : priceSchemes[0]?.id
  );

  useEffect(() => {
    setSelectedScheme((prev) => ({ ...prev, selectedPriceSchemeID }));
  }, [selectedPriceSchemeID]);

  // State of the toggle switch
  const [hoistTypeSwitchChecked, setHoistTypeSwitchChecked] = useState(false);
  const [modalVisible, setModalVisible] = useState(false); // State of the new item modal

  // single | dual
  const hoistType = hoistTypeBySwitchState[+hoistTypeSwitchChecked];

  // Destructures the selected price scheme
  const { configurationByType = {} } =
    priceSchemes.find(({ id }) => id === selectedPriceSchemeID) || {};

  const configuration = configurationByType[hoistType];

  const handleSaveLayout = () => {
    setPricingObject((prev) => ({
      ...prev,
      priceSchemes: prev.priceSchemes.map((scheme) =>
        scheme.id !== selectedPriceSchemeID ? scheme : { ...scheme, layout }
      ),
    }));

    setSaveGridChanges(false);
  };

  return (
    <HoistPricingContext.Provider
      value={{
        label,
        editable,
        hoistType,
        setEditable,
        priceSchemes,
        configuration,
        setPricingObject,
        hoistPricingData,
        configurationByType,
        selectedPriceSchemeID,
        hoistTypeSwitchChecked,
        setSelectedPriceSchemeID,
        setHoistTypeSwitchChecked,
      }}
    >
      <div className="hoist-pricing-config-card">
        <Header
          setSaveGridChanges={setSaveGridChanges}
          isDarkMode={isDarkMode}
          getUsedIn={getUsedIn}
          setEstimationModal={setEstimationModal}
        />
        <ConfigContent
          layout={layout}
          setLayout={setLayout}
          setModalVisible={setModalVisible}
          selectedPriceSchemeID={selectedPriceSchemeID}
          pricingObject={hoistPricingData}
          isDarkMode={isDarkMode}
        />

        <WarningModal
          visible={!!saveGridChanges}
          setVisible={setSaveGridChanges}
          title="Edit Layout"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>Are you sure you want to save the layout?</p>
            <div className="buttons">
              <MondayButton
                Icon={<XIcon />}
                onClick={() => setSaveGridChanges(false)}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => handleSaveLayout()}
                Icon={<TickIcon width={17} height={17} />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      </div>

      <HoistPriceItemModal
        visible={modalVisible}
        setVisible={setModalVisible}
        isDarkMode={isDarkMode}
      />
    </HoistPricingContext.Provider>
  );
};

export default HoistPricingConfig;
