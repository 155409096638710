import { message } from "antd";
import { API } from "aws-amplify";
import { v4 } from "uuid";
import { showErrorMsg } from "../../../../../../../utils";
import { updateProgramFieldByName } from "../../../../../../../actions/programFields";

export const addBillCategory = async ({
  name,
  setName,
  dispatch,
  categories,
  billCategories,
  userConfiguration,
}) => {
  // show loading message
  const hideLoading = message.loading("Adding Category...", 0);

  // check if the selected category exists
  const exists = categories.find(
    (item) => item.toLowerCase() === name.toLocaleLowerCase()
  );

  // if exists return error message
  if (exists) {
    hideLoading();
    return message.error(`${name} already exists`);
  }

  // create new category
  const newCategory = {
    id: v4(),
    createdAt: new Date().getTime(),
    createdBy: userConfiguration.nameOfUser,
    name,
    description: "",
  };

  const newCategories = [...billCategories, newCategory];

  // add new category
  await API.patch(
    "programFields",
    "/programFields/97d0d7ee-32b4-4b71-87b8-a4f5f29d06c6",
    {
      body: {
        fieldOptions: newCategories,
      },
    }
  )
    .then(() => {
      dispatch(
        updateProgramFieldByName({
          fieldName: "Bill Categories",
          fieldOptions: newCategories,
        })
      );
      setName("");
      hideLoading();
    })
    .catch((error) => {
      console.log({ error });
      hideLoading();
      showErrorMsg({
        content: "Failed to save the Category.",
      });
    });
};
