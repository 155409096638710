//@ts-check
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  filterTables,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
  showWarningMsg,
  fetchSearchRecords,
  sendNotification,
} from "../../../../../utils";
import { apiRoutes } from "../../../Fleet/utils";
import { useEditLogs } from "../../../../../hooks";
import { defaultGridRow } from "../utils/constants";
import { bodyCreditObject, bodyObject } from "../utils/fieldsJSON";
import { navigateToRoute } from "../components/utils/generateRoute";
import { useInitialBillContext } from "../context/InitialBillProvider";
import { getChangedData } from "../../../Accounting/components/utilities";
import { useBillCreditContext } from "../context/BillCreditProvider";
import { useProgressContext } from "../../../../commonComponents/ProgressComponent/context/ProgressContext";

export const useCreateBill = () => {
  const { form, billData, vendor, vendors, setVisible, refreshTable, project } =
    useInitialBillContext();

  const { isBill } = useBillCreditContext();

  const { setVisibleCreationProgress, updateProgressStatus } =
    useProgressContext();

  const [rowData, setRowData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const [selectedServices, setSelectedServices] = useState([]);
  const [isProjectLoading, setIsProjectLoading] = useState(false);

  const navigate = useNavigate();
  const { saveAddedLogs } = useEditLogs();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);

  const getProject = async () => {
    const projectId = billData?.projectId || project?.projectId;
    if (!selectedProject && projectId) {
      const result = await filterTables("projects", "projectId", projectId);
      setSelectedProject(result?.[0]);
      form.setFieldsValue({ projectName: result?.[0]?.projectName });
    }
  };

  useEffect(() => {
    setRowData(structuredClone(billData?.billItems) || [defaultGridRow]);
    setSelectedServices(billData?.billServices || []);

    getProject();
  }, [billData]);

  const onSearchChangeHandler = async (search) => {
    if (search) {
      setProjects([]);
      setIsProjectLoading(true);
      await fetchSearchRecords("projects", "projectName", search)
        .then((result) => {
          setProjects(result);
        })
        .catch((error) => {
          showErrorMsg({
            content:
              "Sorry, we couldn't retrieve projects data at the moment. Please try again later.",
          });
          console.error("Error Fetching Projects", error);
        })
        .finally(() => setIsProjectLoading(false));
    }
  };

  const handleSave = async () => {
    try {
      await form.validateFields();

      const billAmount = rowData.reduce((sum, row) => sum + row.total, 0);
      if (billAmount <= 0) {
        showWarningMsg({
          content: "The total bill amount must be greater than 0. ",
        });
        return;
      }

      showLoadingMsg({ content: `Saving Bill. Please wait.` });

      const formData = bodyObject({ form });
      const formCreditData = bodyCreditObject({ form });

      const projectData = {
        projectId: selectedProject?.projectId || billData?.projectId,
        projectName: selectedProject?.projectName || billData?.projectName,
      };

      const newVendor = vendors.find(
        (vendor) => vendor.vendorName === formData.vendorName
      );

      const newBillData = {
        ...formData,
        ...(!isBill && formCreditData),
        ...projectData,
        vendorId: billData?.vendorId || vendor?.vendorId || newVendor?.vendorId,
        isAccount: newVendor?.isAccount || billData?.isAccount || false,
        billServices: selectedServices,
        billItems: rowData,
        billAmount: parseFloat(billAmount),
        billAmountDue: billData?.billAmountDue ?? parseFloat(billAmount),
      };

      if (!newBillData.vendorId && !billData?.vendorId) {
        showErrorMsg({
          content: "There was a problem saving bill.",
        });
        return;
      }

      setVisibleCreationProgress(billData || true);
      updateProgressStatus({ updatingRecord: "executing" });

      await API[billData ? "put" : "post"](
        apiRoutes.bills,
        `/${apiRoutes.bills}${billData ? `/${billData?.billId}` : ""}`,
        {
          body: newBillData,
        }
      )
        .then(async (result) => {
          setVisibleCreationProgress(result);
          const changes = billData && getChangedData(billData, result);
          await saveAddedLogs({
            recordId: result?.billId,
            recordName: result?.billNumber.toString(),
            category: "Bills",
            topic: "Bill",
            label: "Bill",
            cognitoUserId: userConfiguration?.cognitoUserId,
            updatedKeys: [],
            updatedAt: Date.now(),
            ...(billData
              ? {
                  actionType: "Edit",
                  currentData: changes?.curr,
                  previousData: changes?.prev,
                }
              : {}),
          }).catch((err) => console.error("Error creating logs:", err));

          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          });

          const hasPriceChanged =
            billData && billAmount !== billData.billAmount;

          await sendNotification({
            id: "113",
            action: billData
              ? hasPriceChanged
                ? "onBillEditPriceChange"
                : "onBillEdit"
              : "onBillCreation",
            otherKeys: {
              vendorName: newBillData.vendorName,
              ...(billData && { billNumber: billData.billNumber }),
            },
            recordId: billData ? billData.billId : result?.billId,
            authenticatedUser,
            userConfiguration,
          }).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });
          showSuccessMsg({ content: `Bill saved successfully` });
          if (setVisible) {
            setVisible(false);
            refreshTable((prev) => [...prev, result]);
            return;
          }

          navigateToRoute(result, navigate);
        })
        .catch((error) => {
          console.log({ error });
          updateProgressStatus({ updatingRecord: "hasError" });
        });
    } catch (error) {
      showErrorMsg({
        content: "All required fields must be filled in before submitting.",
      });
      console.error("Validation failed:", error);
    }
  };

  return {
    form,
    rowData,
    setRowData,
    handleSave,
    projects,
    onSearchChangeHandler,
    selectedProject,
    setSelectedProject,
    isProjectLoading,
    setSelectedServices,
  };
};
