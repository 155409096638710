import { getTotalServicesPrice } from "../Accounting/calculations/servicePrices";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import {
  calcData,
  preventDuplicate,
} from "../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";

export const generateProjects = ({ projects, schedules }) => {
  return (
    projects.map((row) => {
      const label =
        row?.services &&
        (typeof row?.services === "object"
          ? Object.keys(row.services)
              .flatMap((e) => row?.services[e])
              .filter((serv) => !serv.isScope)
              .map(({ label }) => label)
          : row.services);

      const calculateServiceTotal = (estimations) => {
        let estTotals = 0;
        estimations?.length &&
          estimations?.map((estimation) => {
            if (
              Array.isArray(estimation?.services) &&
              estimation?.services?.length > 0
            ) {
              estTotals += getTotalServicesPrice(estimation?.services);
            }
          });
        return estTotals;
      };

      const getEstimationArray = (estimation) => {
        return Object.keys(estimation)?.map((key, index) => {
          return {
            services: estimation[key],
          };
        });
      };

      const EST = getEstimationArray(row?.services || []);

      const projectSchedule =
        schedules?.find((schedule) => schedule?.projectId === row?.projectId) ||
        [];

      const scheduleDays = projectSchedule?.scheduleDays?.length || 0;

      const services = EST[0]?.services || [];

      const forecastingDays = calcData(services, "plannedNrOfDays");
      const forecastingCrews = calcData(services, "plannedNrOfCrews");

      let scheduleCrews = [];
      if (projectSchedule.length > 0) {
        projectSchedule?.forEach((schedule) => {
          const crews = schedule?.scheduleCrews || [];
          if (crews) {
            scheduleCrews.push(...crews);
          }
        });
      }

      scheduleCrews = preventDuplicate(scheduleCrews, "crewId");

      return {
        ...row,
        projectEstimations: row.services,
        services:
          row?.services &&
          Object.keys(row.services)
            .flatMap((e) => row?.services[e])
            .filter((serv) => !serv.isScope),
        label,
        linkToProject: `${window?.location?.pathname}/${row.projectId}`,
        price: calculateServiceTotal(EST),
        crews:
          forecastingCrews === 0
            ? "Not planned yet"
            : scheduleCrews.length === forecastingCrews
            ? "As Forecasted"
            : scheduleCrews.length < forecastingCrews
            ? "Below Forecast"
            : "Over Forecast",
        timePlanned:
          forecastingDays === 0
            ? "Not planned yet"
            : scheduleDays < forecastingCrews
            ? "Below Forecast"
            : scheduleDays === forecastingDays
            ? "As Forecasted"
            : "Over Forecast",
        potentialStartDate: services[0]?.potentialStartDate
          ? dayjsNY(services[0]?.potentialStartDate).format("MM/DD/YYYY")
          : "Not Set",
      };
    }) || []
  );
};
