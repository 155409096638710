import { message } from "antd";
import { API } from "aws-amplify";
import { sortBy } from "lodash";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import {
  appliedAmountGenerator,
  totalitiesTotalTaxGen,
} from "../components/SidebarPages/Projects/Accounting/Rentals/RentalsView/components/Header/Components/ControlPanel/ControlPanelFunctions";
import { totalitiesForService } from "../components/SidebarPages/Projects/Accounting/Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalHeader/Components/ServiceRentHeader/utils";

import types from "../types";
import { filterTables } from "../utils";
import { filterTablesPagination } from "../utils/paginationFetchData";

// Get all rentals
export const getRentals = (projectId) => async (dispatch) => {
  console.log("TREST", projectId);
  Swal.showLoading();
  // try {
  dispatch({ type: types.LOADING_RENTAL });
  try {
    const rentals = await filterTables("rentals", "projectId", projectId);
    const applicablesReqs = await filterTables(
      "applications",
      "projectId",
      projectId
    );

    // const test = totalitiesForService(rentals);
    const sortedRentals = sortBy(rentals, ["rentalNumber"]).map((item) => {
      const includedReqs = item?.includedTo || false;
      const reqsIncluded = includedReqs
        ? applicablesReqs.find((reqs) => reqs.applicationId === includedReqs)
        : undefined;

      const appliedAmount = appliedAmountGenerator({ fakeRentals: item });
      const {
        totalTax = 0,
        totalPrice = 0,
        retainageAmount = 0,
      } = totalitiesTotalTaxGen({ fakeRentals: item });

      return {
        ...item,
        totalPrice,
        totalTax,
        retainageAmount,
        reqsIncluded,
        totalAppliedRent: appliedAmount,
      };
    });
    console.log("sortedRentals", sortedRentals);
    dispatch({
      type: types.GET_RENTALS,
      payload: sortedRentals,
    });
  } catch (error) {
    console.log("error", error);
    // dispatch({
    //   type: types.GET_RENTALS,
    //   payload: [],
    // });
  } finally {
    Swal.close();
  }
};

// Get specific rental
export const getRental = (rentalId) => async (dispatch) => {
  try {
    // Clear previous loaded rental of value
    dispatch({ type: types.CLEAR_RENTAL });
    dispatch({ type: types.LOADING_RENTAL });

    // const rental =
    await API.get("rentals", `/rentals/${rentalId}`)
      .then((res) => {
        dispatch({
          type: types.GET_RENTAL,
          payload: res,
        });
      })
      .catch((e) => {
        dispatch({
          type: types.ERROR_RENTAL,
          payload: e?.response?.data,
        });
      });

    // dispatch({
    //   type: types.GET_RENTAL,
    //   payload: rental,
    // });
  } catch (err) {
    dispatch({
      type: types.ERROR_RENTAL,
      payload: err,
    });
  }
};

// Create rental
export const createRental =
  (
    rental,
    redirectToCreatedRental,
    navigate,
    isNextStep,
    getRental,
    saveAddedLogs
  ) =>
  async (dispatch, getState) => {
    const authUser = getState().authenticatedUser.authenticatedUser;

    try {
      Swal.showLoading();

      // Clear previous created Rental
      dispatch({ type: types.CLEAR_NEWLY_CREATED_RENTAL });
      dispatch({ type: types.LOADING_RENTAL });

      const newRental = await API.post("rentals", `/rentals`, {
        body: rental,
      });
      saveAddedLogs({
        recordId: newRental?.rentalId,
        recordName: `Rental ${newRental?.rentalNumber}`,
        category: "Rentals",
        topic: newRental?.projectAddress,
      });

      if (!!redirectToCreatedRental && !!!isNextStep) {
        navigate(`/rentals/${newRental.rentalId}`);
      }
      dispatch({
        type: types.CREATE_RENTAL,
        payload: newRental,
      });

      if (isNextStep) getRental(newRental);

      Swal.fire({
        title: "Successfully created",
        text: "Created new Rental",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      dispatch({
        type: types.ERROR_RENTAL,
        payload: err,
      });

      Swal.fire({
        title: "Something went wrong!",
        text: "Cannot create new Rental",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

//Update Rental
export const updateRental = (rental) => async (dispatch, getState) => {
  const { rentalId, userId, lastModifiedBy, ...rest } = rental;
  // const authUser = getState().authenticatedUser.authenticatedUser;

  const fakeRentalsWithPrice = {
    ...rest,
    totalPrice: rental?.services
      ?.flatMap(({ serviceOptions }) =>
        serviceOptions?.flatMap(({ items }) => items)
      )
      ?.reduce((acc, curr) => acc + curr?.appliedAmount, 0),
  };

  try {
    dispatch({ type: types?.LOADING_RENTAL });
    await API.put("rentals", `/rentals/${rentalId}`, {
      body: fakeRentalsWithPrice,
    });

    dispatch({
      type: types.UPDATE_RENTAL,
      payload: {
        rentalId,
        rental,
      },
    });
  } catch (err) {
    dispatch({
      type: types.ERROR_RENTAL,
      payload: err,
    });
  }
};

// Delete rental
export const deleteRental =
  (rentalId, selectedRental, saveAddedLogs) => async (dispatch, getState) => {
    const authUser = getState().authenticatedUser.authenticatedUser;
    try {
      message.info("Deleting Rental...", 0);
      dispatch({ type: types.LOADING_RENTAL });

      await API.del("rentals", `/rentals/${rentalId}`);

      saveAddedLogs({
        recordId: selectedRental?.rentalId,
        recordName: `Rental ${selectedRental?.rentalNumber}`,
        actionType: "Delete",
        category: "Rentals",
        topic: selectedRental?.projectAddress,
      });

      dispatch({
        type: types.DELETE_RENTAL,
        payload: rentalId,
      });
      message.destroy();
      message.success("Successfully deleted Rental");
    } catch (err) {
      dispatch({
        type: types.ERROR_RENTAL,
        payload: err,
      });
    }
  };

// Reset rental
export const resetRental = () => ({
  type: types.RESET_RENTAL,
});
