import {
  onFilter,
  onDateFilter,
  onTimeFilter,
  onNumberRangeFilter,
} from "../../../../../../../SidebarPages/utils";
import { gridFilterData } from "../DegStep/degStepColumns";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { keys } from "../../../../../../Settings/settingsComponents/Roles/RolesData";

function degFilterHandler({ gridApi, filtersData, propFilterData }) {
  if (gridApi) {
    let activeFilters;
    let newFiltersObject = [];
    const filterDataList = propFilterData?.length
      ? propFilterData
      : gridFilterData;

    if (keys(filtersData).find((el) => filtersData[el] !== null)) {
      keys(filtersData)
        .map((e) => {
          if (filtersData[e] !== null) {
            if (e.includes("end")) {
              return { [`${e}end`]: filtersData[e] };
            } else if (e.includes("start")) {
              return { [`${e}start`]: filtersData[e] };
            } else if (e.includes("range1")) {
              return { [`${e}range1`]: filtersData[e] };
            } else if (e.includes("range2")) {
              return { [`${e}range2`]: filtersData[e] };
            } else {
              return { [e]: filtersData[e] };
            }
          }
        })
        .filter((e) => e !== undefined)
        .filter((e) => {
          return filterDataList?.find((el) => {
            if (keys(e)[0].includes("end")) {
              return el.key === keys(e)[0].split("end")[0];
            } else if (keys(e)[0].includes("start")) {
              return el.key === keys(e)[0].split("start")[0];
            } else if (keys(e)[0].includes("range1")) {
              return el.key === keys(e)[0].split("range1")[0];
            } else if (keys(e)[0].includes("range2")) {
              return el.key === keys(e)[0].split("range2")[0];
            } else {
              return Array.isArray(el.key) ? el.key[0] : el.key === keys(e)[0];
            }
          });
        })
        .map((e) => {
          if (keys(e)[0].includes("start")) {
            newFiltersObject = [
              ...newFiltersObject,
              {
                ...filterDataList.find(
                  ({ key }) => key === keys(e)[0].split("start")[0]
                ),
                value: [
                  dayjsNY(e[keys(e)[0]] || ""),
                  ...((newFiltersObject || []).find(
                    ({ key }) => key === keys(e)[0].split("start")[0]
                  )?.value || []),
                ],
              },
            ];
          } else if (keys(e)[0].includes("end")) {
            newFiltersObject = [
              ...newFiltersObject,
              {
                ...filterDataList.find(
                  ({ key }) => key === keys(e)[0].split("end")[0]
                ),
                value: [
                  ...((newFiltersObject || []).find(
                    ({ key }) => key === keys(e)[0].split("end")[0]
                  )?.value || []),
                  dayjsNY(e[keys(e)[0]] || ""),
                ],
              },
            ];
          } else if (keys(e)[0].includes("range1")) {
            newFiltersObject = [
              ...newFiltersObject,
              {
                ...filterDataList.find(
                  ({ key }) => key === keys(e)[0].split("range1")[0]
                ),
                value: [e[keys(e)[0]]],
              },
            ];
          } else if (keys(e)[0].includes("range2")) {
            newFiltersObject = [
              ...newFiltersObject,
              {
                ...filterDataList.find(
                  ({ key }) => key === keys(e)[0].split("range2")[0]
                ),
                value: [
                  ...(newFiltersObject.find(
                    ({ key }) => key === keys(e)[0].split("range2")[0]
                  )?.value || {}),
                  e[keys(e)[0]],
                ],
              },
            ];
          } else {
            newFiltersObject = [
              ...newFiltersObject,
              {
                ...filterDataList.find(({ key }) =>
                  Array.isArray(key)
                    ? key[0] === keys(e)[0].split("start")[0]
                    : key === keys(e)[0].split("start")[0]
                ),
                value: e[keys(e)[0]],
              },
            ];
          }
          return propFilterData?.length ? propFilterData : gridFilterData;
        });

      activeFilters = [
        ...new Set(
          newFiltersObject?.map((item) => {
            return {
              key: Array.isArray(item.key) ? item.key[0] : item.key,
              type: item.type,
              value: item.value,
              masterDetail: !!item?.masterDetail,
            };
          })
        ),
      ].filter(({ value, key }) => value !== undefined && !!key);
    } else {
      activeFilters = undefined;
    }

    if (activeFilters) {
      activeFilters?.map?.(({ key, type, value, masterDetail }) => {
        type === "Dropdown"
          ? onFilter({
              gridApi,
              type: type,
              masterDetail,
              current: value,
              column: Array.isArray(key) ? key[0] : key,
            })
          : type === "DateRange"
          ? onDateFilter?.({
              gridApi,
              column: key,
              masterDetail,
              dateArray: value,
            })
          : type === "NumberRange" || type === "SliderRange"
          ? onNumberRangeFilter?.({
              gridApi,
              column: key,
              numberArray: value,
              masterDetail,
            })
          : type === "TimeRange"
          ? onTimeFilter?.({
              gridApi,
              column: key,
              dateArray: value,
              activeFilters,
              gridFilterData,
            })
          : onFilter?.({
              column: key,
              gridApi,
              type: "equal",
              current: dayjsNY(value).startOf("day"),
            });
      });
    } else {
      gridApi?.setFilterModel(null);
    }
  }
}

export default degFilterHandler;
